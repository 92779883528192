import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {Checkbox, Col, Form, Input, InputNumber, message, Row} from "antd";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchPlaneTypes,
} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneTypes";
import {
  fetchAirports,
} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import styled from "styled-components";
import {CustomDatePicker} from '../../../../../components/UI/AntCustom/CustomDatePicker';
import {UploadOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {UploadFile} from "antd/es/upload";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {clearCreatePlane, createPlane} from "../../../../../store/reducers/fleetReducer/fleetReducer";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {Dayjs} from "dayjs";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {FileAcceptTypes} from "../../../../../utils/constans/files/fileAcceptTypes";
import {CustomUpload} from "../../../../../components/UI/AntCustom/CustomUpload";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {
  fetchCurrencies,
} from "../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {
  FlyHourPriceContext,
} from './flyHourPriceCalculator/context/FlyHourPriceContextProvider';
import {PlaneCalculatorSettingEntity} from "../../../../../proto/generated/api_entities_pb";
import {FormInstance} from "antd/es";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {WarningShield} from "../../../../../components/UI/WarningShield/WarningShield";


const FormItemsWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-upload-list-item-name {
    cursor: pointer;
  }
`;

const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  button {
    flex-grow: 1;
  }
`;


const PlaneCreateFormMessages = defineMessages({
  success: {
    id: "planeCreateForm.title",
    defaultMessage: "The new aircraft has been successfully added to your aircraft fleet.",
  },
  createPlane: {
    id: "planeCreateForm.createPlane",
    defaultMessage: "Create plane",
  },
  openCalculator: {
    id: "planeCreateForm.openCalculator",
    defaultMessage: "Calculate your flight cost",
  },
  openCalculatorMobile: {
    id: "planeCreateForm.openCalculatorMobile",
    defaultMessage: "Flight hour cost calculator is available only in web-version",
  }
})

interface ICreatePlaneFormData {
  planeTypeId: number;
  baseAirportId: number;
  regNumber: string;
  yearOfMake: Dayjs;
  refurbishedDate?: Dayjs;
  maxPax: number;
  maxDistance: number;
  animals: boolean;
  cargo: boolean;
  smoking: boolean;
  ambulance: boolean;
  description?: string;
  flyHourPrice: number;
  flyHourCurrencyId: number;
  settingEntities: PlaneCalculatorSettingEntity[];
}

interface IPlaneCreateForm {
  form: FormInstance;
  closeDrawer: () => void;
  imageFiles: UploadFile[];
  setIsCalculatorOpen: Dispatch<SetStateAction<boolean>>;
}

export const PlaneCreateForm: FC<IPlaneCreateForm> = ({form, closeDrawer, imageFiles, setIsCalculatorOpen}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {isLoading, isSuccess, errorMessage} = useAppSelector(state => state.fleet.create);
  const [insuranceFile, setInsuranceFile] = useState<UploadFile | undefined>();
  const [certificateFile, setCertificateFile] = useState<UploadFile | undefined>();
  const {savedFlyHourPrice, savedCurrency, savedSettingEntities} = useContext(FlyHourPriceContext);

  useEffect(() => {
    return () => {
      dispatch(clearCreatePlane());
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      'flyHourPrice': savedFlyHourPrice,
      'flyHourCurrencyId': savedCurrency?.id
    })
  }, [savedFlyHourPrice, savedCurrency, savedSettingEntities])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(PlaneCreateFormMessages.success), closeDrawer);
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: ICreatePlaneFormData) => {
    if (!insuranceFile) {
      message.error(intl.formatMessage(CommonFormMessages.pdfInsuranceNotLoaded))
      return;
    }

    if (!certificateFile) {
      message.error(intl.formatMessage(CommonFormMessages.pdfRegistrationCertificateNotLoaded))
      return;
    }

    dispatch(createPlane({
      planeTypeId: values.planeTypeId!,
      baseAirportId: values.baseAirportId!,
      registrationNumber: values.regNumber!,
      manufactureDate: values.yearOfMake.toDate(),
      renovationDate: values.refurbishedDate?.toDate(),
      maxPassengers: values.maxPax!,
      maxDistance: values.maxDistance!,
      isAnimals: values.animals,
      isCargo: values.cargo,
      isSmoking: values.smoking,
      isAmbulance: values.ambulance,
      insuranceFile: insuranceFile,
      certificateFile: certificateFile,
      imageFiles: imageFiles,
      description: values.description,
      flyHourPrice: values.flyHourPrice,
      flyHourCurrencyId: values.flyHourCurrencyId,
      settingEntities: savedSettingEntities,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: Partial<ICreatePlaneFormData> = {
    planeTypeId: undefined,
    baseAirportId: undefined,
    regNumber: undefined,
    yearOfMake: undefined,
    refurbishedDate: undefined,
    maxPax: undefined,
    maxDistance: undefined,
    animals: false,
    cargo: false,
    smoking: false,
    ambulance: false,
    description: undefined,
    flyHourPrice: undefined,
    flyHourCurrencyId: undefined,
    settingEntities: [],
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout={'vertical'}
      initialValues={initialValues}
    >
      <FormItemsWrapper>
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeLabel)}
            name={'planeTypeId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
              showSearch={true}
              optionFilterProp="children"
              fetchOptions={fetchPlaneTypes}
            />
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.baseAirportLabel)}
            name={'baseAirportId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              placeholder={intl.formatMessage(CommonFormMessages.baseAirportPlaceholder)}
              showSearch={true}
              optionFilterProp="children"
              fetchOptions={fetchAirports}
            />
          </CustomFieldInput>
        </Col>

        <Row gutter={8}>
          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.registrationNumberLabel)}
              name={'regNumber'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.registrationNumberPlaceholder)}
                     style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>

          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.manufactureDateLabel)}
              name={'yearOfMake'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomDatePicker picker={"year"} placeholder={"2010"} style={{width: "100%"}}
                                inputReadOnly={true} placement={"bottomLeft"}
                                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>

          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.refurbishDateLabel)}
              name={'refurbishedDate'}
            >
              <CustomDatePicker picker={"year"} placeholder={"2021"} style={{width: "100%"}}
                                inputReadOnly={true} placement={"bottomLeft"}
                                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.paxLabel)}
              name={'maxPax'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomInputNumber controls={true} placeholder={"12"} style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.distanceLabel)}
              name={'maxDistance'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomInputNumber controls={true} placeholder={'2300'} style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Col flex={1}>
          <Form.Item
            label={intl.formatMessage(CommonFormMessages.planeInsuranceFile)}
            valuePropName="fileList"
          >
            <CustomUpload
              accept={FileAcceptTypes.DOCUMENT}
              beforeUpload={(file) => setInsuranceFile(file)}
              fileList={insuranceFile ? [insuranceFile] : []}
              maxCount={1}
              onRemove={() => setInsuranceFile(undefined)}
            >
              <CustomButton icon={<UploadOutlined/>} style={{background: "transparent"}}
                            type={"dashed"}>{intl.formatMessage(CommonButtonMessages.loadPdf)}</CustomButton>
            </CustomUpload>
          </Form.Item>
        </Col>

        <Col flex={1} style={{marginBottom: 24}}>
          <Form.Item
            label={intl.formatMessage(CommonFormMessages.planeRegistrationCertificateFile)}
            valuePropName="fileList"
          >
            <CustomUpload
              accept={FileAcceptTypes.DOCUMENT}
              beforeUpload={(file) => setCertificateFile(file)}
              fileList={certificateFile ? [certificateFile] : []}
              maxCount={1}
              onRemove={() => setCertificateFile(undefined)}
            >
              <CustomButton icon={<UploadOutlined/>} style={{background: "transparent"}}
                            type={"dashed"}>{intl.formatMessage(CommonButtonMessages.loadPdf)}</CustomButton>
            </CustomUpload>
          </Form.Item>
        </Col>

        <Row style={{marginBottom: 16}}>
          <Col span={12}>
            <CustomFieldInput
              name={'animals'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.animalsCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'cargo'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.cargoCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'smoking'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.smokingCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'ambulance'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.ambulanceCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>
        </Row>

        <Col span={24}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeDescriptionLabel)}
            name={'description'}
          >
            <TextArea autoSize={true} placeholder={intl.formatMessage(CommonFormMessages.planeDescriptionPlaceholder)}
                      style={{width: "100%"}}/>
          </CustomFieldInput>
        </Col>
      </FormItemsWrapper>

      <Row gutter={[8, 8]}>
        <Col span={16}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.flyHourPriceLabel)}
            name={'flyHourPrice'}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
              {max: 1000000000, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)}
            ]}
            style={{marginBottom: 0}}
          >
            <CustomInputNumber controls={false}
                               placeholder={intl.formatMessage(CommonFormMessages.flyHourPricePlaceholder)}
                               style={{width: '100%'}}/>
          </CustomFieldInput>
        </Col>
        <Col span={8}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.currencyLabel)}
            name={'flyHourCurrencyId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            style={{marginBottom: 0}}
          >
            <DebounceSelect
              defaultOptionsFetchCallback={fetchCurrencies}
              placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
            />
          </CustomFieldInput>
        </Col>

        <Col span={24}>
          {isMobile
            ? <WarningShield warningText={intl.formatMessage(PlaneCreateFormMessages.openCalculatorMobile)}
                             style={{marginBottom: 32}}/>
            : <CustomButton type={'primary'} ghost={true} style={{width: '100%', marginBottom: 32}}
                            onClick={() => setIsCalculatorOpen(true)}>
              {intl.formatMessage(PlaneCreateFormMessages.openCalculator)}
            </CustomButton>
          }
        </Col>
      </Row>

      <FormButtons>
        <CustomButton danger type={'link'}
                      onClick={closeDrawer}>{intl.formatMessage(CommonButtonMessages.cancel)}</CustomButton>
        <CustomButton type={'primary'}
                      htmlType={'submit'}>{intl.formatMessage(PlaneCreateFormMessages.createPlane)}</CustomButton>
      </FormButtons>
    </Form>
  );
};
