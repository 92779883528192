import {RoleDescriptor} from "../RoleDescriptor";
import {Route} from "../../routes/Route";
import {Role} from "../Role";
import {defineMessage} from "react-intl";

const GuestMessage = defineMessage({
  id: "guest.name",
  defaultMessage: "Guest",
})

export const guest: RoleDescriptor<Role.guest> = {
  role: Role.guest,
  name: GuestMessage,
  backendIdentifier: null,
  homeRoute: Route.home,
  navigation: {
    profile: [
    ],
    sidebar: [
    ]
  },
}