import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Col, message, Row} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {IManager} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducerTypes";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {
  clearDeleteManager, deleteManager
} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducer";
import {roles} from "../../../../../../config/acl/roles";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";


const DeleteManagerModalMessages = defineMessages({
  title: {
    id: "deleteManagerModal.title",
    defaultMessage: "Employee deletion",
  },
  success: {
    id: "deleteManagerModal.success",
    defaultMessage: "The employee has been successfully deleted.",
  },
})

interface IDeleteManagerModal {
  manager: IManager;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteManagerModal: FC<IDeleteManagerModal> = ({manager, isModalVisible, setIsModalVisible,}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.personnelManagement.delete);

  useEffect(() => {
    return () => {
      dispatch(clearDeleteManager());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(DeleteManagerModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  return (
    <CustomModal
      title={intl.formatMessage(DeleteManagerModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      centered
    >
      <Row gutter={8}>
        <Col span={24}>{intl.formatMessage(CommonDetailsMessages.employee)}: {manager.lastName} {manager.name} {manager.middleName}</Col>
        <Col span={24}>{intl.formatMessage(CommonDetailsMessages.role)}: {intl.formatMessage(roles[manager.role].name)}</Col>
      </Row>

      <Row gutter={8} style={{marginTop: 24}}>
        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            onClick={() => setIsModalVisible(false)}
          >
            {intl.formatMessage(CommonButtonMessages.cancel)}
          </CustomButton>
        </Col>
        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            type={'primary'}
            icon={<DeleteOutlined />}
            danger={true}
            loading={isLoading}
            onClick={() => dispatch(deleteManager({itemId: manager.id}))}
          >
            {intl.formatMessage(CommonButtonMessages.delete)}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};
