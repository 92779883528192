export const DisplayTimezoneOffset = (offset: number) => {
  const isZeroOrPositive = offset >= 0;
  const offsetAbs = Math.abs(offset);
  const hours = Math.floor(offsetAbs / 60);
  const minutes = offsetAbs % 60;

  const hh = Math.floor(hours / 10) === 0 ? `0${hours}` : hours.toString();
  const mm = Math.floor(minutes / 10) === 0 ? `0${minutes}` : minutes.toString();

  return `${isZeroOrPositive ? "+" : "-"}${hh}:${mm}`;
}