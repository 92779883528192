import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, message, Radio, Row,} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {clearCreateEmptyLeg, createEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {Dayjs} from "dayjs";
import {fetchAirports} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {fetchCurrencies} from "../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {fetchOwnPlanes} from "../../../../../utils/helpers/selectDataFetchers/fetchOwnPlanes";
import {EmptyLegsTabKeysEnum} from "../MyEmptyLegsPage";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {RenderConfirmModal, renderSuccessModal,} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {EmptyLegDateTypeEnum, useEmptyLegDateTypeName} from "../../../../../utils/constans/business/emptyLegDateType";
import {CalculateFlight} from "../../../../../utils/helpers/formHelpers/CalculateFlight";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CalculateSwitcher} from "../../../../../components/businessCommon/calculateButton/CalculateSwitcher";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";


const AddEmptyLegModalMessages = defineMessages({
  title: {
    id: "addEmptyLegModal.title",
    defaultMessage: "Create Empty Leg",
  },
  createWarn: {
    id: "addEmptyLegModal.createWarn",
    defaultMessage: "Flight duration and flying range were filled out manually. Do you want to create an Empty Leg?",
  },
  success: {
    id: "addEmptyLegModal.success",
    defaultMessage: "New Empty Leg has been successfully created",
  }
})

interface ICreateEmptyLegFormData {
  dateTypeRadio: EmptyLegDateTypeEnum;
  availableDates?: [Dayjs, Dayjs];
  departureDate?: Dayjs;
  airportFromId: number;
  airportToId: number;
  fleetId: number;
  priceAmount: number;
  priceCurrencyId: number;
  hours: number;
  minutes: number;
  distance: number;
  comment: string;
}

interface IAddEmptyLegModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  setActiveTabKey: Dispatch<SetStateAction<EmptyLegsTabKeysEnum>>;
}

export const AddEmptyLegModal: FC<IAddEmptyLegModal> = ({isModalVisible, setIsModalVisible, setActiveTabKey}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.emptyLegs.create);
  const [dateTypeRadioValue, setDateTypeRadioValue] = useState<EmptyLegDateTypeEnum>(EmptyLegDateTypeEnum.RANGE);
  const [isAutoFlyRouteDisabled, setIsAutoFlyRouteDisabled] = useState<boolean>(true);
  const [isAutoFlyRouteCalcApplied, setIsAutoFlyRouteApplied] = useState<boolean>(false);
  const emptyLegDateTypeNameRange = useEmptyLegDateTypeName(EmptyLegDateTypeEnum.RANGE);
  const emptyLegDateTypeNameSpecific = useEmptyLegDateTypeName(EmptyLegDateTypeEnum.SPECIFIC);

  useEffect(() => {
    return () => {
      dispatch(clearCreateEmptyLeg());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        renderSuccessModal(intl.formatMessage(AddEmptyLegModalMessages.success), () => {
          setActiveTabKey(EmptyLegsTabKeysEnum.ACTIVE);
          setIsModalVisible(false);
        });
      }
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: ICreateEmptyLegFormData) => {
    if (values.minutes === 0 && values.hours === 0) {
      message.error(intl.formatMessage(CommonFormMessages.ruleIncorrectFlightTime));
      return;
    }

    const createEmptyLegCallback = () => dispatch(createEmptyLeg({
      availableFromDate: values.availableDates ? values.availableDates[0].toDate() : undefined,
      availableToDate: values.availableDates ? values.availableDates[1].toDate() : undefined,
      departureDate: values.departureDate?.toDate(),
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
      fleetId: values.fleetId,
      priceAmount: values.priceAmount,
      priceCurrencyId: values.priceCurrencyId,
      hours: values.hours,
      minutes: values.minutes,
      distance: values.distance,
      comment: values.comment,
    }))

    if (!isAutoFlyRouteCalcApplied)
      RenderConfirmModal(
        intl.formatMessage(AddEmptyLegModalMessages.createWarn),
        createEmptyLegCallback,
        intl);
    else
      createEmptyLegCallback();
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const handleFormValuesChange = async (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "dateTypeRadio" && changedValues.hasOwnProperty(fieldName)) {
      const value = changedValues[fieldName];
      setDateTypeRadioValue(value)
    } else if (["hours", "minutes", "distance"].includes(fieldName)) {
      setIsAutoFlyRouteApplied(false);

      const planeId: number | undefined = form.getFieldValue('fleetId');
      const airportFromId: number | undefined = form.getFieldValue('airportFromId');
      const airportToId: number | undefined = form.getFieldValue('airportToId');
      if (planeId && airportFromId && airportToId)
        setIsAutoFlyRouteDisabled(false);
      else
        setIsAutoFlyRouteDisabled(true);
    } else if (["fleetId", "airportFromId", "airportToId"].includes(fieldName)) {
      await onCalculateFlight();
    }
  }

  const onCalculateFlight = async () => {
    const planeId: number | undefined = form.getFieldValue('fleetId');
    const airportFromId: number | undefined = form.getFieldValue('airportFromId');
    const airportToId: number | undefined = form.getFieldValue('airportToId');

    if (planeId && airportFromId && airportToId) {
      if (isAutoFlyRouteCalcApplied) {
        setIsAutoFlyRouteApplied(false);
      } else {
        const {
          hours,
          minutes,
          distance,
          isAutoCalculationApplied,
        } = await CalculateFlight(airportFromId, airportToId, planeId, intl)

        form.setFieldsValue({
          'hours': hours,
          'minutes': minutes,
          'distance': distance,
        });
        setIsAutoFlyRouteApplied(isAutoCalculationApplied);
      }
    } else {
      setIsAutoFlyRouteDisabled(true);
      setIsAutoFlyRouteApplied(false);
    }
  }

  const initialValues: Partial<ICreateEmptyLegFormData> = {
    dateTypeRadio: EmptyLegDateTypeEnum.RANGE,
    availableDates: undefined,
    departureDate: undefined,
    airportFromId: undefined,
    airportToId: undefined,
    fleetId: undefined,
    priceAmount: undefined,
    priceCurrencyId: undefined,
    hours: undefined,
    minutes: undefined,
    distance: undefined,
    comment: undefined,
  }

  return (
    <CustomModal
      title={intl.formatMessage(AddEmptyLegModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={handleFormValuesChange}
      >
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeLabel)}
            name={'fleetId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              fetchOptions={fetchOwnPlanes}
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
            />
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
            name={'airportFromId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
              showSearch={true}
            />
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
            name={'airportToId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
              showSearch={true}
            />
          </CustomFieldInput>
        </Col>
        <Col flex={1}>
          <CustomFieldInput
            name="dateTypeRadio"
            style={{marginBottom: 8}}
          >
            <Radio.Group>
              <Radio value={EmptyLegDateTypeEnum.RANGE}>{emptyLegDateTypeNameRange}</Radio>
              <Radio
                value={EmptyLegDateTypeEnum.SPECIFIC}>{emptyLegDateTypeNameSpecific}</Radio>
            </Radio.Group>
          </CustomFieldInput>

          {dateTypeRadioValue === EmptyLegDateTypeEnum.RANGE &&
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.availableDatesLabel)}
              name={'availableDates'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomDatePicker.RangePicker showTime format={"DD.MM.YYYY HH:mm"}
                                            disabledDate={isDateLessTanOneMinuteAgo}
                                            placeholder={[intl.formatMessage(CommonUnitMessages.from), intl.formatMessage(CommonUnitMessages.to)]}
                                            inputReadOnly={true} placement={"bottomLeft"}
                                            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          }

          {dateTypeRadioValue === EmptyLegDateTypeEnum.SPECIFIC &&
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.departureDateLabel)}
              name={'departureDate'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
              <CustomDatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm"
                placeholder={intl.formatMessage(CommonFormMessages.departureDatePlaceholder)}
                disabledDate={isDateLessTanOneMinuteAgo}
                inputReadOnly={true} placement={"bottomLeft"}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          }
        </Col>

        <Row justify={"end"} style={{marginBottom: 8}}>
          <CalculateSwitcher
            disabled={isAutoFlyRouteDisabled}
            isAutoCalculationApplied={isAutoFlyRouteCalcApplied}
            setIsAutoCalculationApplied={setIsAutoFlyRouteApplied}
            onClick={onCalculateFlight}/>
        </Row>

        <Row gutter={8}>
          <Col span={isMobile ? 12 : 8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.flyHoursLabel)}
              name={'hours'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
                {max: 99, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
              ]}
            >
              <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.hour)}
                                 maxLength={2}
                                 precision={0}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 12 : 8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.flyMinutesLabel)}
              name={'minutes'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
                {max: 59, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
              ]}
            >
              <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.minute)}
                                 maxLength={2}
                                 precision={0}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.distanceLabel)}
              name={'distance'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
              ]}
            >
              <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.km)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.priceLabel)}
              name={'priceAmount'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  min: 0,
                  type: "number",
                  message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)
                },
                {
                  max: 1000000000,
                  type: "number",
                  message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)
                }
              ]}
            >
              <CustomInputNumber controls={false} placeholder={intl.formatMessage(CommonFormMessages.pricePlaceholder)}
                                 style={{width: '100%'}}/>
            </CustomFieldInput>
          </Col>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.currencyLabel)}
              name={'priceCurrencyId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchCurrencies}
                placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Form.Item>
          <CustomButton
            block
            heightSize={"l"}
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(CommonButtonMessages.create)}
          </CustomButton>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
