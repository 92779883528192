const MUTE = 0;

export const Logger = {
  log: (title: string, data: any, color: string = 'green') => {
    if (process.env.NODE_ENV === 'production' || MUTE) return;
    console.log(`%c${title}`, `color: ${color}; font-size: 12px`);
    console.log(`%c${(new Date).toTimeString()}`, `color: ${color}; font-size: 11px`);
    console.log(data);
    console.log(`%c[------end------]`, `color: ${color}; font-size: 12px`);

  },
  info: (title: string, color: string = 'yellow') => {
    if (process.env.NODE_ENV === 'production' || MUTE) return;
    console.log(`%c[${title}]`, `color: ${color}; font-size: 12px`);

  },
  error: (errorMessage: string) => {
    if (process.env.NODE_ENV === 'production' || MUTE) return;
    console.log(`%c[${errorMessage}]`, "color: red; font-size: 12px");
  },
  collapsedLog: (title: string, data: any, color: string = '#aaa') => {
    console.groupCollapsed(`%c${title}`, `color: ${color}`);
    console.dir(data);
    console.groupEnd();
  }
}

export interface IWebsocketLoggerData {
  messageId: number,
  accessToken?: string;
  messageType: string,
  data: object | string,
}

