export enum FlyHourPriceCalculatorSettingTypeEnum {
  FLAT = 1,
  PERCENT = 2,
}


export enum FlyHourPriceDefaultCategoryEnum {
  CATEGORY_CONSTANT = 'category_constant',
  CATEGORY_CHANGEABLE = 'category_changeable',
  CATEGORY_MARGIN = 'category_margin',
  CATEGORY_COEFFICIENT = 'category_coefficient',
  CATEGORY_BASE = 'category_base',
}


export enum FlyHourPriceDefaultSubcategoryEnum {
  SUB_CATEGORY_CONSTANT_CREW = 'sub_category_crue',
  SUB_CATEGORY_CONSTANT_DEFAULT = 'sub_category_constant_default',
  SUB_CATEGORY_CONSTANT_SHIP_OPERATION = 'sub_category_contstant_ship_operation',
  SUB_CATEGORY_CONSTANT_SHIP_MAINTENANCE = 'sub_category_sub_category_constant_ship_maintenance',
  SUB_CATEGORY_CONSTANT_ADMINISTRATIVE_SPENDING = 'sub_category_administrative_spending',
  SUB_CATEGORY_CHANGEABLE_DEFAULT = 'sub_category_changeable_default',
  SUB_CATEGORY_CHANGEABLE_SHIP_MAINTENANCE = 'sub_category_changeable_ship_maintenance',
  SUB_CATEGORY_CHANGEABLE_FLY = 'sub_category_changeable_fly',
  SUB_CATEGORY_MARGIN_COMPANY_MARGIN = 'sub_category_company_margin',
  SUB_CATEGORY_MARGIN_TAXES = 'sub_category_margin_taxes',
  SUB_CATEGORY_COEFFICIENT_DEFAULT = 'sub_category_coefficient_default',
  SUB_CATEGORY_BASE = 'sub_category_base',
}


export enum FlyHourPriceDefaultSettingTypeEnum {
  PARAMETER_BASE_FLY_HOURS = 'parameter_base_fly_hours',
  PARAMETER_CONSTANT_SPENDING = 'parameter_constant_spending',
  PARAMETER_CONSTANT_CREW_LEARNING = 'parameter_constant_crue_learning',
  PARAMETER_CONSTANT_KVS = 'parameter_constant_kvs',
  PARAMETER_CONSTANT_SECOND_PILOT = 'parameter_constant_second_pilot',
  PARAMETER_CONSTANT_FLIGHT_ATTENDANT = 'parameter_constant_flight_attendant',
  PARAMETER_CONSTANT_PLG = 'parameter_constant_plg',
  PARAMETER_CONSTANT_PRODUCER_DOCUMENTATION = 'parameter_constant_producer_documentation',
  PARAMETER_CONSTANT_JEPPESEN = 'parameter_constant_jeppesen',
  PARAMETER_CONSTANT_ARINC = 'parameter_constant_arinc',
  PARAMETER_CONSTANT_FMS = 'parameter_constant_fms',
  PARAMETER_CONSTANT_EGPWS = 'parameter_constant_egpws',
  PARAMETER_CONSTANT_CAMP = 'parameter_constant_camp',
  PARAMETER_CONSTANT_CAMO = 'parameter_constant_camo',
  PARAMETER_CONSTANT_INSURANCE = 'parameter_constant_insurance',
  PARAMETER_CONSTANT_CREW_INSURANCE = 'parameter_constant_crue_insurance',
  PARAMETER_CONSTANT_DRY_CLEANING_AND_STORAGE = 'parameter_constant_dry_cleaning_storage',
  PARAMETER_CONSTANT_LINEAR_MAINTENANCE = 'parameter_constant_linear_maintenance',
  PARAMETER_CONSTANT_BASING = 'parameter_constant_basing',
  PARAMETER_CONSTANT_MANAGE = 'parameter_constant_manage',
  PARAMETER_CHANGEABLE_SPENDING = 'parameter_changeable_spending',
  PARAMETER_CHANGEABLE_FUEL = 'parameter_changeable_fuel',
  PARAMETER_CHANGEABLE_AIRPORT_TAXES = 'parameter_changeable_airport_taxes',
  PARAMETER_CHANGEABLE_NAVIGATION_TAXES = 'parameter_changeable_navigation_taxes',
  PARAMETER_CHANGEABLE_HOUSEHOLD_EXPENSES = 'parameter_changeable_household_expenses',
  PARAMETER_CHANGEABLE_HOTEL_EXPENSES = 'parameter_changeable_hotel_expenses',
  PARAMETER_CHANGEABLE_ENGINE_TAXES = 'parameter_changeable_engine_taxes',
  PARAMETER_CHANGEABLE_SPARE_PARTS_TAXES = 'parameter_changeable_spare_parts_taxes',
  PARAMETER_CHANGEABLE_VSU_TAXES = 'parameter_changeable_vsu_taxes',
  PARAMETER_MARGIN_AMOUNT = 'parameter_margin_amount',
  PARAMETER_MARGIN_TAXES = 'parameter_margin_income_taxes',
  PARAMETER_COEFFICIENT_NDS = 'parameter_coefficient_nds',
  PARAMETER_COEFFICIENT_HIGH_SEASON = 'parameter_coefficient_high_season',
  PARAMETER_COEFFICIENT_LOW_DATES = 'parameter_coefficient_low_dates',
  PARAMETER_COEFFICIENT_URGENT_FLIGHT = 'parameter_coefficient_urgent_flight',
  PARAMETER_COEFFICIENT_PASSENGER_SERVICE = 'parameter_coefficient_passenger_service',
  PARAMETER_COEFFICIENT_EAT = 'parameter_coefficient_eat',
}