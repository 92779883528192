import React, {useEffect, useState} from 'react';
import {Col, Form, message, Row} from "antd";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {useIntl} from "react-intl";
import {NewTripRequestPageMessages} from "../NewTripRequestPage";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {fetchAirports} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {fetchPlaneCategories} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneCategories";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import TextArea from "antd/es/input/TextArea";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import styled from "styled-components";
import {CompanyMultiSelector, CompanyMultiSelectorMessages, CompanySelectorOption} from "./CompanyMultiSelector";
import {CustomSwitch} from "../../../../../components/UI/AntCustom/CustomSwitch";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {pathFromRoute} from "../../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../../config/acl/routes/Route";
import {clearOneWayRequest, oneWayRequest} from "../../../../../store/reducers/newRequestReducer/newRequestReducer";
import {useNavigate} from "react-router";
import {getChatList} from "../../../../../store/reducers/chatReducer/chatReducer";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";


const OneWayContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 14px;
`;


interface IOneWayFormData {
  airportFromId: number;
  airportToId: number;
  departureDate: Dayjs;
  pax: number;
  planeCategoryIds: number[],
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

export const OneWayForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const [form] = Form.useForm();
  const [companyList, setCompanyList] = useState<CompanySelectorOption[]>([]);
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.newRequest.oneWayRequest);

  useEffect(() => {
    return () => {
      dispatch(clearOneWayRequest())
    }
  })

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        dispatch(getChatList());
        renderSuccessModal(intl.formatMessage(NewTripRequestPageMessages.publishSuccess), () => navigate(pathFromRoute(Route.requests), {replace: true}));
      }
      if (errorMessage)
        message.error(errorMessage)
    }
  }, [isSuccess, isLoading, errorMessage])

  const onFinish = (values: IOneWayFormData) => {
    if (companyList.length === 0) {
      message.error(intl.formatMessage(CompanyMultiSelectorMessages.error));
      return;
    }

    dispatch(oneWayRequest({
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
      departureDate: values.departureDate.toDate(),
      pax: values.pax,
      planeCategoryIds: values.planeCategoryIds,
      companionsAllowed: values.companionsAllowed,
      comment: values.comment,
      companyIds: companyList.map(c => c.id),
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: Partial<IOneWayFormData> = {
    airportFromId: undefined,
    airportToId: undefined,
    departureDate: undefined,
    pax: undefined,
    planeCategoryIds: [],
    companionsAllowed: false,
    comment: undefined,
    companyIds: [],
  }

  const companyMultiSelector = <CompanyMultiSelector selectedCompanies={companyList}
                                                     setSelectedCompanies={setCompanyList}/>

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      autoComplete="off"
      layout={'vertical'}
      form={form}
    >
      <OneWayContentWrapper>
        <div style={{flexGrow: 1}}>
          <Row gutter={8}>
            <Col span={isMobile ? 24 : 7}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
                name={'airportFromId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 24 : 7}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
                name={'airportToId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 16 : 7}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.dateLabel)}
                name={'departureDate'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <CustomDatePicker
                  showTime={true}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={intl.formatMessage(CommonFormMessages.datePlaceholder)}
                  disabledDate={isDateLessTanOneMinuteAgo}
                  inputReadOnly={true} placement={"bottomLeft"}
                  getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 8 : 3}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.paxLabel)}
                name={'pax'}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinPax)},
                  {max: 100, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMaxPax)}
                ]}>
                <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                   style={{width: "100%"}}/>
              </CustomFieldInput>
            </Col>
          </Row>

          {isMobile && companyMultiSelector}

          <Row gutter={8}>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.planeCategoryLabel)}
                name={'planeCategoryIds'}
              >
                <DebounceSelect
                  fetchOptions={fetchPlaneCategories}
                  placeholder={intl.formatMessage(CommonFormMessages.allPlaceholder)}
                  mode={'multiple'}
                  brightPlaceholder={true}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.commentLabel)}
                name={'comment'}>
                <TextArea
                  allowClear={true}
                  autoSize={{minRows: isMobile ? 1 : 3}}
                  placeholder={intl.formatMessage(CommonFormMessages.commentPlaceholder)}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col>
              <CustomFieldInput
                name={'companionsAllowed'}
                valuePropName={'checked'}
              >
                <CustomSwitch label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)}/>
              </CustomFieldInput>
            </Col>
          </Row>
        </div>

        {!isMobile && companyMultiSelector}
      </OneWayContentWrapper>

      <Col span={24}>
        <CustomFieldInput>
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={false}
            style={{width: "100%"}}
          >
            {intl.formatMessage(NewTripRequestPageMessages.createRequest)}
          </CustomButton>
        </CustomFieldInput>
      </Col>
    </Form>
  );
};
