import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {Checkbox, Col, Form, Input, InputNumber, message, Row} from "antd";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchPlaneTypes, getInitialPlaneTypeForSelect,
} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneTypes";
import {fetchAirports, getInitialAirportForSelect} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import styled from "styled-components";
import {CustomDatePicker} from '../../../../../components/UI/AntCustom/CustomDatePicker';
import {UploadOutlined} from "@ant-design/icons";
import Button from "antd/es/button/button";
import TextArea from "antd/es/input/TextArea";
import {UploadFile} from "antd/es/upload";
import {onPdfFilePreview} from "../../../../../utils/helpers/fileHelpers/onPdfFilePreview";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {
  clearDeletePlane,
  clearEditPlane,
  deletePlane,
  editPlane
} from "../../../../../store/reducers/fleetReducer/fleetReducer";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {IPlane} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import dayjs, {Dayjs} from "dayjs";
import {FileAcceptTypes} from "../../../../../utils/constans/files/fileAcceptTypes";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CustomUpload} from "../../../../../components/UI/AntCustom/CustomUpload";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {fetchCurrencies} from "../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {FlyHourPriceContext} from "./flyHourPriceCalculator/context/FlyHourPriceContextProvider";
import {FormInstance} from "antd/es";
import {CustomInputNumber} from '../../../../../components/UI/AntCustom/CustomInputNumber';
import {WarningShield} from "../../../../../components/UI/WarningShield/WarningShield";
import {useIsMobile} from "../../../../../hooks/useWindowSize";


const FormItemsWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-upload-list-item-name {
    cursor: pointer;
  }
`;

const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  button {
    flex-grow: 1;
  }
`;


const PlaneEditFormMessages = defineMessages({
  editSuccess: {
    id: "planeEditForm.editSuccess",
    defaultMessage: "Information about the aircraft has been successfully updated.",
  },
  deleteSuccess: {
    id: "planeEditForm.deleteSuccess",
    defaultMessage: "The aircraft has been successfully deleted from your aircraft fleet.",
  },
  deletePlane: {
    id: "planeEditForm.deletePlane",
    defaultMessage: "Delete aircraft",
  },
  openCalculator: {
    id: "planeEditForm.openCalculator",
    defaultMessage: "Calculate the flight cost",
  },
  openCalculatorMobile: {
    id: "planeEditForm.openCalculatorMobile",
    defaultMessage: "Flight hour cost calculator is available only in web-version",
  }
})

interface IEditPlaneFormData {
  planeTypeId: number;
  baseAirportId: number;
  regNumber: string;
  yearOfMake: Dayjs;
  refurbishedDate?: Dayjs;
  maxPax: number;
  maxDistance: number;
  animals: boolean;
  cargo: boolean;
  smoking: boolean;
  ambulance: boolean;
  description?: string;
  flyHourPrice: number;
  flyHourCurrencyId: number;
}

interface IPlaneEditForm {
  form: FormInstance;
  closeDrawer: () => void;
  imageFiles: UploadFile[];
  plane: IPlane;
  setIsCalculatorOpen: Dispatch<SetStateAction<boolean>>;
}

export const PlaneEditForm: FC<IPlaneEditForm> = ({form, closeDrawer, imageFiles, plane, setIsCalculatorOpen}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [insuranceFile, setInsuranceFile] = useState<UploadFile | undefined>(plane.insuranceFile);
  const [certificateFile, setCertificateFile] = useState<UploadFile | undefined>(plane.certificateFile);
  const {
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
    errorMessage: editErrorMessage
  } = useAppSelector(state => state.fleet.edit);
  const {
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
    errorMessage: deleteErrorMessage
  } = useAppSelector(state => state.fleet.delete);
  const {savedFlyHourPrice, savedCurrency, savedSettingEntities} = useContext(FlyHourPriceContext);

  useEffect(() => {
    return () => {
      dispatch(clearEditPlane());
      dispatch(clearDeletePlane());
    }
  }, [])

  useEffect(() => {
    if (!isEditLoading) {
      if (isEditSuccess)
        renderSuccessModal(intl.formatMessage(PlaneEditFormMessages.editSuccess), closeDrawer);
      if (editErrorMessage)
        message.error(editErrorMessage);
    }
  }, [isEditSuccess, editErrorMessage, isEditLoading])

  useEffect(() => {
    if (!isDeleteLoading) {
      if (isDeleteSuccess)
        renderSuccessModal(intl.formatMessage(PlaneEditFormMessages.deleteSuccess), closeDrawer);
      if (deleteErrorMessage)
        message.error(deleteErrorMessage);
    }
  }, [isDeleteSuccess, deleteErrorMessage, isDeleteLoading])

  useEffect(() => {
    form.setFieldsValue({
      ['flyHourPrice']: savedFlyHourPrice,
      ['flyHourCurrencyId']: savedCurrency?.id
    })
  }, [savedFlyHourPrice, savedCurrency, savedSettingEntities])

  const onFinish = (values: IEditPlaneFormData) => {
    if (!insuranceFile) {
      message.error(intl.formatMessage(CommonFormMessages.pdfInsuranceNotLoaded))
      return;
    }

    if (!certificateFile) {
      message.error(intl.formatMessage(CommonFormMessages.pdfRegistrationCertificateNotLoaded))
      return;
    }

    dispatch(editPlane({
      id: plane.id,
      planeTypeId: values.planeTypeId!,
      baseAirportId: values.baseAirportId!,
      registrationNumber: values.regNumber!,
      manufactureDate: values.yearOfMake.toDate(),
      renovationDate: values.refurbishedDate?.toDate(),
      maxPassengers: values.maxPax!,
      maxDistance: values.maxDistance!,
      isAnimals: values.animals,
      isCargo: values.cargo,
      isSmoking: values.smoking,
      isAmbulance: values.ambulance,
      insuranceFile: insuranceFile,
      certificateFile: certificateFile,
      imageFiles: imageFiles,
      description: values.description,
      flyHourPrice: values.flyHourPrice,
      flyHourCurrencyId: values.flyHourCurrencyId,
      settingEntities: savedSettingEntities,
    }))
  }

  const onPlaneDelete = () => {
    dispatch(deletePlane({
      itemId: plane.id
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IEditPlaneFormData = {
    planeTypeId: plane.planeType.id,
    baseAirportId: plane.baseAirport.id,
    regNumber: plane.registrationNumber,
    yearOfMake: dayjs(plane.manufactureDate),
    refurbishedDate: plane.renovationDate ? dayjs(plane.renovationDate) : undefined,
    maxPax: plane.maxPassengers,
    maxDistance: plane.maxDistance,
    animals: plane.isAnimals,
    cargo: plane.isCargo,
    smoking: plane.isSmoking,
    ambulance: plane.isAmbulance,
    description: plane.description,
    flyHourPrice: plane.flyHourPrice,
    flyHourCurrencyId: plane.flyHourCurrency.id,
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout={'vertical'}
      initialValues={initialValues}
    >
      <FormItemsWrapper>
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeLabel)}
            name={'planeTypeId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
              showSearch={true}
              optionFilterProp="children"
              fetchOptions={fetchPlaneTypes}
              defaultOptions={[getInitialPlaneTypeForSelect(plane.planeType)]}
            />
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.baseAirportLabel)}
            name={'baseAirportId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              placeholder={intl.formatMessage(CommonFormMessages.baseAirportPlaceholder)}
              showSearch={true}
              optionFilterProp="children"
              fetchOptions={fetchAirports}
              defaultOptions={[getInitialAirportForSelect(plane.baseAirport)]}
            />
          </CustomFieldInput>
        </Col>

        <Row gutter={8}>
          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.registrationNumberLabel)}
              name={'regNumber'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.registrationNumberPlaceholder)}
                     style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>

          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.manufactureDateLabel)}
              name={'yearOfMake'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomDatePicker picker={"year"} placeholder={'2010'} style={{width: "100%"}}
                                inputReadOnly={true} placement={"bottomLeft"}
                                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>

          <Col span={8}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.refurbishDateLabel)}
              name={'refurbishedDate'}
            >
              <CustomDatePicker picker={"year"} placeholder={'2021'} style={{width: "100%"}}
                                inputReadOnly={true} placement={"bottomLeft"}
                                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.paxLabel)}
              name={'maxPax'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomInputNumber controls={true} placeholder={'12'} style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.distanceLabel)}
              name={'maxDistance'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomInputNumber controls={true} placeholder={'2300'} style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Col flex={1}>
          <Form.Item
            label={intl.formatMessage(CommonFormMessages.planeInsuranceFile)}
            valuePropName="fileList"
          >
            <CustomUpload
              accept={FileAcceptTypes.DOCUMENT}
              beforeUpload={(file) => setInsuranceFile(file)}
              fileList={insuranceFile ? [insuranceFile] : []}
              maxCount={1}
              onPreview={onPdfFilePreview}
              onRemove={() => setInsuranceFile(undefined)}
            >
              <CustomButton icon={<UploadOutlined/>} style={{background: "transparent"}}
                            type={"dashed"}>{intl.formatMessage(CommonButtonMessages.loadPdf)}</CustomButton>
            </CustomUpload>
          </Form.Item>
        </Col>

        <Col flex={1} style={{marginBottom: 24}}>
          <Form.Item
            label={intl.formatMessage(CommonFormMessages.planeRegistrationCertificateFile)}
            valuePropName="fileList"
          >
            <CustomUpload
              accept={FileAcceptTypes.DOCUMENT}
              beforeUpload={(file) => setCertificateFile(file)}
              fileList={certificateFile ? [certificateFile] : []}
              maxCount={1}
              onPreview={onPdfFilePreview}
              onRemove={() => setCertificateFile(undefined)}
            >
              <CustomButton icon={<UploadOutlined/>} style={{background: "transparent"}}
                            type={"dashed"}>{intl.formatMessage(CommonButtonMessages.loadPdf)}</CustomButton>
            </CustomUpload>
          </Form.Item>
        </Col>

        <Row style={{marginBottom: 16}}>
          <Col span={12}>
            <CustomFieldInput
              name={'animals'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.animalsCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'cargo'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.cargoCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'smoking'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.smokingCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>

          <Col span={12}>
            <CustomFieldInput
              name={'ambulance'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonFormMessages.ambulanceCheckbox)}</Checkbox>
            </CustomFieldInput>
          </Col>
        </Row>

        <Col span={24}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeDescriptionLabel)}
            name={'description'}
          >
            <TextArea autoSize={true} placeholder={intl.formatMessage(CommonFormMessages.planeDescriptionPlaceholder)}
                      style={{width: "100%"}}/>
          </CustomFieldInput>
        </Col>
      </FormItemsWrapper>

      <Row gutter={[8, 8]}>
        <Col span={16}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.flyHourPriceLabel)}
            name={'flyHourPrice'}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
              {max: 1000000000, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)}
            ]}
            style={{marginBottom: 0}}
          >
            <CustomInputNumber controls={false}
                               placeholder={intl.formatMessage(CommonFormMessages.flyHourPricePlaceholder)}
                               style={{width: '100%'}}/>
          </CustomFieldInput>
        </Col>
        <Col span={8}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.currencyLabel)}
            name={'flyHourCurrencyId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            style={{marginBottom: 0}}
          >
            <DebounceSelect
              defaultOptionsFetchCallback={fetchCurrencies}
              placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
            />
          </CustomFieldInput>
        </Col>

        <Col span={24}>
          {isMobile
            ? <WarningShield warningText={intl.formatMessage(PlaneEditFormMessages.openCalculatorMobile)} style={{marginBottom: 32}} />
            : <CustomButton type={'primary'} ghost={true} style={{width: '100%', marginBottom: 32}}
                            onClick={() => setIsCalculatorOpen(true)}>
              {intl.formatMessage(PlaneEditFormMessages.openCalculator)}
            </CustomButton>
          }
        </Col>
      </Row>

      <FormButtons>
        <CustomButton danger onClick={onPlaneDelete}
                      style={{background: "transparent"}}>{intl.formatMessage(PlaneEditFormMessages.deletePlane)}</CustomButton>
        <CustomButton type={'primary'}
                      htmlType={'submit'}>{intl.formatMessage(CommonButtonMessages.edit)}</CustomButton>
      </FormButtons>
    </Form>
  );
};
