import React from 'react';
import {useAppSelector} from "../../../../../store/store";
import {OrderCharterCard} from "./charter/OrderCharterCard";
import {OrderEmptyLegCard} from "./emptyLeg/OrderEmptyLegCard";
import {Loader} from "../../../../../components/UI/Loader/Loader";
import styled from "styled-components";
import {CustomResult} from "../../../../../components/UI/AntCustom/CustomResult";
import {useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {OrderTypeEnum} from "../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {useSearchParams} from "react-router-dom";
import isNumeric from "antd/es/_util/isNumeric";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;


export const ChartererOrderList = () => {
  const intl = useIntl();
  const {
    items,
    isLoading,
  } = useAppSelector(state => state.chartererRequests.itemsObject)

  return (
    <ListWrapper>
      {items.map(order => {
        if ([OrderTypeEnum.ONE_WAY, OrderTypeEnum.ROUND_TRIP, OrderTypeEnum.MULTI_LEG].includes(order.type))
          return <OrderCharterCard key={order.id} order={order} />
        else if (order.type === OrderTypeEnum.EMPTY_LEG)
          return <OrderEmptyLegCard key={order.id} order={order} />
        else
          return null;
      })}

      {items.length === 0 && (isLoading ? <Loader /> : <CustomResult title={intl.formatMessage(CommonFormMessages.noData)}/>)}
    </ListWrapper>
  );
};
