import {
  UserAccountApiServiceClient
} from "../../proto/generated/api_entities_grpc_web_pb";
import {
  EmptyRequest,
  ResultResponse,
  UserAccountApiAgentListRequest,
  UserAccountApiAgentListResponse, UserAccountApiChangePasswordRequest,
  UserAccountApiChangeUserStatusRequest,
  UserAccountApiCompanyRequest,
  UserAccountApiCompanyResponse,
  UserAccountApiCreateManagerRequest, UserAccountApiFlyCompanyListRequest, UserAccountApiFlyCompanyListResponse,
  UserAccountApiManagerListRequest,
  UserAccountApiManagerListResponse,
  UserAccountApiRemoveManagerRequest,
  UserAccountApiUserListRequest,
  UserAccountApiUserListResponse,
  UserAccountApiUserResponse,
  UserAccountApiUserUpdateRequest,
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";
import {getProxyUrl} from "../helpers/getProxyUrl";

const client = new UserAccountApiServiceClient(getProxyUrl(), null, null);

export class UserAccountApi {
  static async getUserList(req: UserAccountApiUserListRequest): Promise<UserAccountApiUserListResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiUserListRequest, UserAccountApiUserListResponse>(client, client.userList, req, resolve, reject);
    });
  }

  static async changeUserStatus(req: UserAccountApiChangeUserStatusRequest): Promise<UserAccountApiUserResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiChangeUserStatusRequest, UserAccountApiUserResponse>(client, client.userChangeStatus, req, resolve, reject);
    });
  }

  static async getUserInfo(req: EmptyRequest): Promise<UserAccountApiUserResponse> {
    return new Promise((resolve, reject) => {
      API.call<EmptyRequest, UserAccountApiUserResponse>(client, client.userInfo, req, resolve, reject);
    });
  }

  static async changePassword(req: UserAccountApiChangePasswordRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiChangePasswordRequest, ResultResponse>(client, client.userChangePassword, req, resolve, reject);
    });
  }

  static async editUser(req: UserAccountApiUserUpdateRequest): Promise<UserAccountApiUserResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiUserUpdateRequest, UserAccountApiUserResponse>(client, client.userUpdate, req, resolve, reject);
    });
  }

  static async editCompany(req: UserAccountApiCompanyRequest): Promise<UserAccountApiCompanyResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiCompanyRequest, UserAccountApiCompanyResponse>(client, client.updateCompany, req, resolve, reject);
    });
  }

  static async getChartererManagerList(req: UserAccountApiManagerListRequest): Promise<UserAccountApiManagerListResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiManagerListRequest, UserAccountApiManagerListResponse>(client, client.getAgencyManagerList, req, resolve, reject);
    });
  }

  static async getFreighterManagerList(req: UserAccountApiManagerListRequest): Promise<UserAccountApiManagerListResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiManagerListRequest, UserAccountApiManagerListResponse>(client, client.getFreighterManagerList, req, resolve, reject);
    });
  }

  static async createManager(req: UserAccountApiCreateManagerRequest): Promise<UserAccountApiUserResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiCreateManagerRequest, UserAccountApiUserResponse>(client, client.createManager, req, resolve, reject);
    });
  }

  static async deleteManager(req: UserAccountApiRemoveManagerRequest): Promise<UserAccountApiUserResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiRemoveManagerRequest, UserAccountApiUserResponse>(client, client.removeManager, req, resolve, reject);
    });
  }

  static async getAgencyList(req: UserAccountApiAgentListRequest): Promise<UserAccountApiAgentListResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiAgentListRequest, UserAccountApiAgentListResponse>(client, client.getAgencyList, req, resolve, reject);
    });
  }

  static async getFlyCompanyList(req: UserAccountApiFlyCompanyListRequest): Promise<UserAccountApiFlyCompanyListResponse> {
    return new Promise((resolve, reject) => {
      API.call<UserAccountApiFlyCompanyListRequest, UserAccountApiFlyCompanyListResponse>(client, client.getFlyCompanyList, req, resolve, reject);
    });
  }
}