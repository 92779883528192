import {IAirport} from "../../../store/reducers/fleetReducer/fleetReducerTypes";

export const calculateArrivalDate = (airportFrom: IAirport, airportTo: IAirport, departureDate: Date, durationInMinutes: number) => {
  return new Date(departureDate.getTime() + durationInMinutes * 60000);
}

export const getRecentUTCMondayWithWeekOffset = (offset: number = 0, boundaryDate: boolean = false): Date => {
  const dateNow = new Date();
  return new Date(
    dateNow.getUTCFullYear(),
    dateNow.getUTCMonth(),
    dateNow.getUTCDate() + (7 * offset) - dateNow.getUTCDay() + 1,
    0,
    0,
    0,
    boundaryDate ? -1 : 0);
}


export const getUTCDateWithDayOffset = (offset: number = 0, boundaryDate: boolean = false): Date => {
  const dateNow = new Date();
  return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate() + offset, 0, 0, 0, boundaryDate ? -1 : 0);
}