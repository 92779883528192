import React, {FC} from 'react';
import styled from "styled-components";
import {defineMessages, useIntl} from "react-intl";
import {DisplayFlyPrice} from "../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {ICurrency} from "../../../../store/reducers/fleetReducer/fleetReducerTypes";

const Wrapper = styled.div`
  display: flex;
  padding: 15px 20px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  & > div {
    color: #000000;
    font-weight: 600;
    line-height: 22px;
    
    :nth-child(1) {
      font-size: 14px;
    }
    
    :nth-child(2) {
      font-size: 18px;
    }
  }
`;


const FlightDrawerPriceMessages = defineMessages({
  title: {
    id: "flightDrawerPrice.title",
    defaultMessage: "Price",
  }
})

interface IFlightDrawerPrice {
  price: number;
  currency: ICurrency;
}

export const FlightDrawerPrice: FC<IFlightDrawerPrice> = ({price, currency}) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <div>{intl.formatMessage(FlightDrawerPriceMessages.title)}</div>
      <div>{DisplayFlyPrice(price, currency)}</div>
    </Wrapper>
  );
};
