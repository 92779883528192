import {IUser} from "../userReducer/userReducerTypes";
import {CommonState, ItemListState} from "../highOrderReducers/commonReducer";
import {NotificationEntity} from "../../../proto/generated/api_entities_pb";

// *** DO NOT CHANGE WITHOUT BACKEND DEVELOPERS AGREEMENT ***
export enum NotificationType {
  TYPE_ORDER_REQUEST_CREATED = 4,
  TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN = 6,
  TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED,
  TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED,
  TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN,
  TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED,
  TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED,
  TYPE_EMPTY_LEG_ARCHIVED_EXPIRED,
  TYPE_EMPTY_LEG_UPDATED,
  TYPE_INCOME_MESSAGE,
  TYPE_ORDER_ROUTE_ARCHIVED,
  TYPE_ORDER_REQUEST_ARCHIVED,
  TYPE_ORDER_REQUEST_EXPIRED,
  TYPE_ORDER_REQUEST_OFFERED,
}

export interface INotificationAirport {
  id: number;
  icaoCode: string;
  iataCode: string;
}

export interface INotificationCompany {
  id: number;
  name: string;
}

export interface INotificationMetadata {
  airports?: INotificationAirport[];
  airportFrom?: INotificationAirport;
  airportTo?: INotificationAirport;
  company?: INotificationCompany;
  orderId?: number;
  emptyLegId?: number;
  emptyLegOrderRouteId?: number;
  orderRouteId?: number;
  orderRequestId?: number;
}

export interface INotification {
  id: number;
  type: NotificationType;
  entityId: string;
  isRead: boolean;
  sender?: IUser;
  receiver: IUser;
  dateCreated: Date;
  metadata: INotificationMetadata;
}

export interface IGetNotificationListPayload {
  page: number;
}

export interface ISetNotificationListResultPayload {
  items: NotificationEntity[];
  totalCount: number;
}

export interface IReadNotificationsPayload {
  notificationIds?: number[];
}

export interface ISetLastNotificationPayload {
  items: NotificationEntity[];
}

export interface INotificationUnreadOnlyInitialState extends CommonState<INotification> {
  itemsObject: ItemListState<INotification>;
}

export interface INotificationInitialState extends CommonState<INotification> {
  itemsObject: ItemListState<INotification>;
  newPushesReceived: boolean;
  lastReceivedPushesCount: number;
  unreadOnlyNotifications: INotification[];
}