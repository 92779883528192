import {PayloadAction} from "@reduxjs/toolkit";
import {
  PageModel,
  UserAccountApiAgentListRequest,
  UserAccountApiAgentListResponse
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {API} from "../../../API/API";
import {IGetAgencyListPayload} from "../../reducers/agencyReducer/agencyReducerTypes";
import {getAgencyList, setAgencyListResult} from "../../reducers/agencyReducer/agencyReducer";

function* getAgencyListSaga({payload}: PayloadAction<IGetAgencyListPayload>) {
  const getAgencyListReq = new UserAccountApiAgentListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.AgencyList)
    )
    .setQuery(payload.query);

  try {
    const getAgencyListRes: UserAccountApiAgentListResponse = yield call(API.getAgencyList, getAgencyListReq);

    yield put(setAgencyListResult({
      items: getAgencyListRes.getAgenciesList(),
      totalCount: getAgencyListRes.getTotalcount(),
    }));
  } catch {}
}


export function* agencySaga() {
  yield all([
    takeLatest(getAgencyList.type, getAgencyListSaga),
  ])
}