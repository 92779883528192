import React, {FC, useState} from 'react';
import {Card} from "antd";
import {PhoneOutlined} from "@ant-design/icons";
import {RequestCallbackModal} from "./RequestCallbackModal";
import {IAgency} from "../../../../store/reducers/agencyReducer/agencyReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {CommonUserMessages} from "../../../../intl/commonMessages/CommonUserMessages";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import defaultAvatarSrc from "../../../../media/images/default_avatar.png";
import styled from "styled-components";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";

const CompanyImage = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 100%;
`;


const AgencyCardMessages = defineMessages({
  adminFullName: {
    id: "agencyCard.adminFullName",
    defaultMessage: "Administrator's name"
  },
  contact: {
    id: "agencyCard.contact",
    defaultMessage: "Call me"
  },
})

interface IAgencyCard {
  agency: IAgency;
}

export const AgencyCard: FC<IAgencyCard> = ({agency}) => {
  const intl = useIntl();
  const [isRequestCallbackModalOpen, setIsRequestCallbackModalOpen] = useState<boolean>(false);
  let fio = `${agency.admin.lastName} ${agency.admin.firstName} ${agency.admin.middleName}`;

  return (
    <>
      <CustomCard>
        <Card.Meta
          avatar={<CompanyImage src={agency.avatar?.url ?? defaultAvatarSrc}
                                alt={`Company ${agency.companyName} avatar`}/>}
          title={fio}
          description={<div>
            <div>{intl.formatMessage(CommonUserMessages.company)}: {agency.companyName}</div>
            <div>{intl.formatMessage(AgencyCardMessages.adminFullName)}: {fio}</div>
          </div>}
        />
        <CustomButton
          block
          type={"primary"}
          onClick={() => setIsRequestCallbackModalOpen(true)}
        >
          {intl.formatMessage(AgencyCardMessages.contact)}
          <PhoneOutlined/>
        </CustomButton>
      </CustomCard>
      {isRequestCallbackModalOpen &&
        <RequestCallbackModal agency={agency} isModalVisible={isRequestCallbackModalOpen}
                              setIsModalVisible={setIsRequestCallbackModalOpen}/>}
    </>
  );
};

