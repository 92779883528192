import React, {useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../../../media/icons/logo.svg";
import menuMobileImage from "../../../../media/icons/menu_mobile.png";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../config/acl/routes/Route";
import {useGenerateMenu} from "../../../../hooks/useGenerateMenu";
import {useAppSelector} from "../../../../store/store";
import {NotificationBell} from "../../../notifications/notificationsCentre/NotificationBell";
import {HeaderMobileMenu} from "./HeaderMobileMenu";
import {CustomButton} from "../../AntCustom/CustomButton";

const HeaderMobileStyled = styled.div`
  display: flex;
  height: 68px;
  max-width: 100vw;
  background: #F3F5F7;
  padding: 24px 10px 14px 10px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #D9D9D9;
  //box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.22) inset;
  position: fixed;
  z-index: 999;

  a {
    display: flex;
  }
`;

const HeaderMobileRight = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;


export const HeaderMobile = () => {
    const {menuItems} = useGenerateMenu("mobileBurger", false);
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    return (
      <HeaderMobileStyled>
        <Link to={pathFromRoute(Route.home)}>
          <Logo/>
        </Link>

        <HeaderMobileRight>
          {isAuthenticated && <NotificationBell/>}
          <CustomButton type={'text'} onClick={() => setIsMenuOpened(true)}><img src={menuMobileImage} alt={'Mobile menu'}/></CustomButton>
        </HeaderMobileRight>

        <HeaderMobileMenu menuItems={menuItems} isMenuOpened={isMenuOpened} setIsMenuOpened={setIsMenuOpened}/>
      </HeaderMobileStyled>
    );
  }
;

