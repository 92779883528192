import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  IChangePasswordPayload,
  IEditUserPayload,
  ISetEditUserResultPayload,
  ISetUserResultPayload,
  IUserInitialState
} from "./userReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearEditOne,
  initCommonClearOne,
  initCommonEditOne,
  initCommonGetOne, initCommonSetEditOneResult,
  initCommonSetOneResult
} from "../highOrderReducers/commonReducer";
import {mapUserEntityToIUser} from "./userMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";
import {generateEmptyUser} from "./userReducerEmptyData";


const userInitialState: IUserInitialState = {
  oneItemObject: {
    item: generateEmptyUser(),
    isLoading: true,
  },
  edit: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  changePassword: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    getUser(state) {
      initCommonGetOne(state);
    },
    clearUser(state) {
      initCommonClearOne(state, generateEmptyUser);
    },
    setUserResult(state, {payload}: PayloadAction<ISetUserResultPayload>) {
      initCommonSetOneResult(state, payload, mapUserEntityToIUser)
    },

    editUser(state, {payload}: PayloadAction<IEditUserPayload>) {
      initCommonEditOne(state);
    },
    clearEditUser(state) {
      initCommonClearEditOne(state);
    },
    setEditUserResult(state, {payload}: PayloadAction<ISetEditUserResultPayload>) {
      initCommonSetEditOneResult(state, payload, mapUserEntityToIUser)
    },

    changePassword(state, {payload}: PayloadAction<IChangePasswordPayload>) {
      initCommonAction(state, nameof<IUserInitialState>("changePassword"))
    },
    clearChangePassword(state) {
      initCommonClearAction(state, nameof<IUserInitialState>("changePassword"))
    },
    setChangePasswordResult(state, {payload}: PayloadAction<{errorMessage?: string}>) {
      initCommonActionResult(state, payload, nameof<IUserInitialState>("changePassword"))
    }
  },
});

export const {
  getUser,
  clearUser,
  setUserResult,

  editUser,
  clearEditUser,
  setEditUserResult,

  changePassword,
  clearChangePassword,
  setChangePasswordResult,
} = userSlice.actions;
export default userSlice.reducer;