import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {
  TimezoneApiTimezoneListRequest, TimezoneApiTimezoneListResponse
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {ITimezone} from "../../../store/reducers/userReducer/userReducerTypes";
import {DisplayTimezoneOffset} from "../textDisplayHelpers/DisplayTimezoneOffset";


export async function fetchTimezones(search: string): Promise<DebounceSelectOptionProps[]> {
  const req = new TimezoneApiTimezoneListRequest().setQuery(search);

  const res: TimezoneApiTimezoneListResponse = await API.getTimezoneList(req);

  return res.getTimezonesList().map(tz => {
    const tzName = tz.getName();
    const label = `${tzName} ${DisplayTimezoneOffset(tz.getOffset())}`;

    return {
      key: tz.getTimezoneid(),
      label: label,
      value: tz.getTimezoneid(),
    }
  })
}

export const getInitialTimezoneForSelect = (timezone: ITimezone): DebounceSelectOptionProps => {
  return {
    key: timezone.id,
    value: timezone.id,
    label: timezone.name,
  }
}