import React, {useMemo} from 'react';
import {Route as ReactRoute, Routes, Navigate} from "react-router-dom";
import {routes} from "../config/acl/routes";
import {ACL} from "./ACL";
import {Route} from "../config/acl/routes/Route";
import {lk} from "../config/acl/routes/lk/lk";

export const RootRoutes = () => {
  const appRoutes = useMemo(() => Object
    .values(routes)
    .filter(r => !r.path.startsWith('/lk') || r.path === lk.path)
    .map(route => (
      <ReactRoute key={route.route} path={route.path} element={<ACL route={route}/>}/>
    )), []);

  return (
    <Routes>
      {appRoutes}
      <ReactRoute path={'*'} element={<Navigate to={routes[Route.notFound].path} replace={true}/>}/>
    </Routes>
  );
};