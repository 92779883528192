import {defineMessages} from "react-intl";

export const CommonButtonMessages = defineMessages({
  add: {
    id: "app.button.add",
    defaultMessage: "Add"
  },
  archive: {
    id: "app.button.archive",
    defaultMessage: "Archive"
  },
  accept: {
    id: "app.button.accept",
    defaultMessage: "Accept"
  },
  withdraw: {
    id: "app.button.withdraw",
    defaultMessage: "Decline"
  },
  return: {
    id: "app.button.return",
    defaultMessage: "Return"
  },
  back: {
    id: "app.button.back",
    defaultMessage: "Back",
  },
  confirm: {
    id: "app.button.confirm",
    defaultMessage: "Confirm"
  },
  reject: {
    id: "app.button.reject",
    defaultMessage: "Decline"
  },
  cancel: {
    id: "app.button.cancel",
    defaultMessage: "Cancel"
  },
  close: {
    id: "app.button.close",
    defaultMessage: "Close"
  },
  delete: {
    id: "app.button.delete",
    defaultMessage: "Delete"
  },
  save: {
    id: "app.button.save",
    defaultMessage: "Apply"
  },
  edit: {
    id: "app.button.edit",
    defaultMessage: "Edit"
  },
  create: {
    id: "app.button.create",
    defaultMessage: "Create"
  },
  more: {
    id: "app.button.more",
    defaultMessage: "Show more"
  },
  hide: {
    id: "app.button.hide",
    defaultMessage: "Show less"
  },
  apply: {
    id: "app.button.apply",
    defaultMessage: "Apply"
  },
  resetFilters: {
    id: "app.button.resetFilters",
    defaultMessage: "Reset filters"
  },
  reset: {
    id: "app.button.reset",
    defaultMessage: "Reset"
  },
  resetAllValues: {
    id: "app.button.resetAllValues",
    defaultMessage: "Reset all values"
  },
  loadPdf: {
    id: "app.button.loadPdf",
    defaultMessage: "Upload PDF"
  },
  returnForReview: {
    id: "app.button.returnForReview",
    defaultMessage: "Return for review"
  },
  doRespond: {
    id: "app.button.doRespond",
    defaultMessage: "Make offer"
  },
  find: {
    id: "app.button.find",
    defaultMessage: "Find",
  },
  look: {
    id: "app.button.look",
    defaultMessage: "Details",
  },
  change: {
    id: "app.button.change",
    defaultMessage: "Edit",
  },
  upload: {
    id: "app.button.upload",
    defaultMessage: "Upload",
  },
  replace: {
    id: "app.button.replace",
    defaultMessage: "Replace",
  },
  saveAndExit: {
    id: "app.button.saveAndExit",
    defaultMessage: "Save and exit",
  },
  allDetails: {
    id: "app.button.allDetails",
    defaultMessage: "All details",
  },
})