import React, {FC} from 'react';
import {OrderTypeEnum} from "../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import styled from "styled-components";

type OrderTypeShieldStyleType = 'outline' | 'fill';

const OrderTypeShieldStyled = styled.div<{$orderType: OrderTypeEnum; $typeColor: string; $styleType: OrderTypeShieldStyleType}>`
  padding: 2px 13px;
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  border: 1px solid ${props => props.$typeColor};
  color: ${props => props.$styleType === 'outline' ? props.$typeColor : (props.$orderType === OrderTypeEnum.ONE_WAY ? "#FFFFFF" :"#000000")};
  border-radius: ${props => props.$styleType === 'outline' ? '5px' : '23px'};
  background: ${props => props.$styleType === 'outline' ? 'transparent' : props.$typeColor};
`;


interface IOrderTypeShield {
  type: OrderTypeEnum;
  styleType?: OrderTypeShieldStyleType;
  [key: string]: any;
}

export const OrderTypeShield: FC<IOrderTypeShield> = ({type, styleType = 'outline', ...props}) => {
  const name = useOrderTypeName(type);
  const color = useOrderTypeColor(type, styleType);

  return (
    <OrderTypeShieldStyled $orderType={type} $typeColor={color} $styleType={styleType} {...props}>{name}</OrderTypeShieldStyled>
  );
};


const OrderTypeNameMessages = defineMessages({
  emptyLeg: {
    id: "OrderTypeName.emptyLeg",
    defaultMessage: "Empty Leg"
  },
  oneWay: {
    id: "OrderTypeName.oneWay",
    defaultMessage: "One Way"
  },
  roundTrip: {
    id: "OrderTypeName.roundTrip",
    defaultMessage: "Round Trip"
  },
  multiLeg: {
    id: "OrderTypeName.multiLeg",
    defaultMessage: "Multi Leg"
  },
  unknown: {
    id: "OrderTypeName.unknown",
    defaultMessage: "Unknown"
  }
})

const useOrderTypeName = (type: OrderTypeEnum) => {
  const intl = useIntl()

  switch (type) {
    case OrderTypeEnum.EMPTY_LEG:
      return intl.formatMessage(OrderTypeNameMessages.emptyLeg);
    case OrderTypeEnum.ONE_WAY:
      return intl.formatMessage(OrderTypeNameMessages.oneWay);
    case OrderTypeEnum.ROUND_TRIP:
      return intl.formatMessage(OrderTypeNameMessages.roundTrip);
    case OrderTypeEnum.MULTI_LEG:
      return intl.formatMessage(OrderTypeNameMessages.multiLeg);
    default:
      return intl.formatMessage(OrderTypeNameMessages.unknown);
  }
}

const useOrderTypeColor = (type: OrderTypeEnum, styleType: OrderTypeShieldStyleType) => {
  switch (type) {
    case OrderTypeEnum.EMPTY_LEG:
      return styleType === 'outline' ? "#497C61" : "#AACDBA";
    case OrderTypeEnum.ONE_WAY:
      return styleType === 'outline' ? "#1890FF" : "#1890FF";
    case OrderTypeEnum.ROUND_TRIP:
      return styleType === 'outline' ? "#DC9208" : "#F9D99B";
    case OrderTypeEnum.MULTI_LEG:
      return styleType === 'outline' ? "#9F71C4" : "#E3D5EE";
    default:
      return styleType === 'outline' ? "#808080" : "#808080";
  }
}
