import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, message, Radio, Row} from "antd";
import {Role} from "../../../../../../config/acl/roles/Role";
import {IManager} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducerTypes";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {
  clearEditManager,
  editManager
} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducer";
import {roles} from "../../../../../../config/acl/roles";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {PhoneRegex} from "../../../../../../utils/helpers/regexes/phoneRegex";
import {DeleteOutlined} from "@ant-design/icons";
import {DeleteManagerModal} from "./DeleteManagerModal";
import {DisplayManagerFullName} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const EditManagerModalMessages = defineMessages({
  success: {
    id: "editManagerModal.success",
    defaultMessage: "Information about employee has been successfully updated.",
  }
})

interface IEditManagerFormData {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: number;
  companyRole: Role;
}

interface IEditManagerModal {
  manager: IManager;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const EditManagerModal: FC<IEditManagerModal> = ({manager, isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.personnelManagement.edit)
  const {role} = useAppSelector(state => state.user.oneItemObject.item)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearEditManager());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(EditManagerModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IEditManagerFormData) => {
    dispatch(editManager({
      id: manager.id,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      phone: values.phone,
      companyRole: values.companyRole,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IEditManagerFormData = {
    firstName: manager.name,
    lastName: manager.lastName,
    middleName: manager.middleName,
    phone: manager.phone,
    companyRole: manager.role,
  }

  const RadioForRole: FC<{ role: Role }> = ({role}) => <Radio value={role}>{intl.formatMessage(roles[role].name)}</Radio>;

  return (
    <CustomModal
      title={DisplayManagerFullName(manager)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.lastNameLabel)}
              name={'lastName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.lastNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.nameLabel)}
              name={'firstName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.namePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.middleNameLabel)}
              name={'middleName'}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.middleNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.phoneLabel)}
              name={'phone'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                pattern: PhoneRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
              }]}>
              <CustomInputNumber addonBefore={"+"} placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)} controls={false} maxLength={12}/>
            </CustomFieldInput>
          </Col>
        </Row>


        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.roleLabel)}
              name={'companyRole'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Radio.Group>
                {role === Role.chartererAdmin && <RadioForRole role={Role.chartererManager}/>}

                {role === Role.freighterAdmin && <>
                  <RadioForRole role={Role.freighterClientManager}/>
                  <RadioForRole role={Role.freighterServiceManager}/>
                </>}
              </Radio.Group>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput>
              <CustomButton
                block
                type={'primary'}
                htmlType={'submit'}
                loading={isLoading}
              >
                {intl.formatMessage(CommonButtonMessages.save)}
              </CustomButton>
            </CustomFieldInput>
          </Col>

          <Col span={24}>
            <CustomButton
              block
              ghost={true}
              type={"primary"}
              danger={true}
              icon={<DeleteOutlined/>}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              {intl.formatMessage(CommonButtonMessages.delete)}
            </CustomButton>
          </Col>
        </Row>
      </Form>

      {isDeleteModalOpen && <DeleteManagerModal manager={manager} isModalVisible={isDeleteModalOpen}
                                                setIsModalVisible={setIsDeleteModalOpen}/>}
    </CustomModal>
  );
};
