import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Col, Form, Input, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {PhoneRegex} from "../../../../../../utils/helpers/regexes/phoneRegex";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {clearEditUser, editUser} from "../../../../../../store/reducers/userReducer/userReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const EditUserModalMessages = defineMessages({
  success: {
    id: "editUserModal.success",
    defaultMessage: "The profile data has been successfully applied.",
  },
  title: {
    id: "editUserModal.title",
    defaultMessage: "Profile information",
  },
})

interface IEditUserFormData {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: number;
}

interface IEditUserModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const EditUserModal: FC<IEditUserModal> = ({isModalVisible, setIsModalVisible}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const user = useAppSelector(state => state.user.oneItemObject.item)
  const [form] = Form.useForm();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.user.edit)

  useEffect(() => {
    return () => {
      dispatch(clearEditUser());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(EditUserModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])


  const onFinish = (values: IEditUserFormData) => {
    dispatch(editUser({
      userId: user.id,
      role: user.role,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      phone: values.phone,
      timezoneId: user.timezone.id,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IEditUserFormData = {
    firstName: user.firstName,
    lastName: user.lastName,
    middleName: user.middleName,
    phone: user.phone,
  }

  return (
    <CustomModal
      title={intl.formatMessage(EditUserModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.lastNameLabel)}
              name={'lastName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.lastNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.nameLabel)}
              name={'firstName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.namePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.middleNameLabel)}
              name={'middleName'}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.middleNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.phoneLabel)}
              name={'phone'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                pattern: PhoneRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
              }]}>
              <CustomInputNumber addonBefore={"+"} placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)}
                                 controls={false} maxLength={12}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput>
              <CustomButton
                block
                type={'primary'}
                htmlType={'submit'}
                loading={isLoading}
              >
                {intl.formatMessage(CommonButtonMessages.save)}
              </CustomButton>
            </CustomFieldInput>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};