import {useAppSelector} from "../../../../../store/store";
import {useEffect, useState} from "react";
import {Role} from "../../../../../config/acl/roles/Role";
import {groupNotApproved} from "../../../../../config/acl/roles/groups";

export enum CompanyWarningStatusEnum {
  WARNING_LOAD_DOCUMENTS,
  WARNING_ON_MODERATION,
  OK,
}

export const isCompanyOnLoadDocuments = (status: CompanyWarningStatusEnum) => status === CompanyWarningStatusEnum.WARNING_LOAD_DOCUMENTS;
export const isCompanyOnModeration = (status: CompanyWarningStatusEnum) => status === CompanyWarningStatusEnum.WARNING_ON_MODERATION;

export const useCompanyWarningStatus = () => {
  const {role} = useAppSelector(state => state.user.oneItemObject.item);
  const company = useAppSelector(state => state.company.oneItemObject.item);
  const [companyWarningStatus, setCompanyWarningStatus] = useState<CompanyWarningStatusEnum>(CompanyWarningStatusEnum.OK);
  const [companyWarningStatusColor, setCompanyWarningStatusColor] = useState<string>("#31CA91");

  const hasEmptyDocuments = () => (role === Role.freighterAdminNotApproved
    && (company.registrationCertificate === undefined || company.operatorCard === undefined))
  || (role === Role.chartererAdminNotApproved && (company.registrationCertificate === undefined));

  useEffect(() => {
    setNewStatus()
  }, [role, company.registrationCertificate, company.operatorCard])

  const setNewStatus = () => {
    if (groupNotApproved.includes(role)) {
      if (hasEmptyDocuments()) {
        setCompanyWarningStatus(CompanyWarningStatusEnum.WARNING_LOAD_DOCUMENTS);
        setCompanyWarningStatusColor("#C90000")
        return;
      }

      setCompanyWarningStatus(CompanyWarningStatusEnum.WARNING_ON_MODERATION)
      setCompanyWarningStatusColor("#F4B237")
      return;
    }

    setCompanyWarningStatus(CompanyWarningStatusEnum.OK);
    setCompanyWarningStatusColor("#31CA91")
  }

  return {companyWarningStatus, companyWarningStatusColor};
}