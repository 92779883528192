import styled from "styled-components";
import {Tabs} from "antd";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    @media(max-width: ${mediaScreenSizes.mobile}) {
      margin-bottom: 0;
    }
  }
`;