import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {RegisterPage} from "../../../../../pages/General/Register/RegisterPage";
import {Role} from "../../../roles/Role";
import {groupNotGuest} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const RegisterRouteTitleMessage = defineMessage({
  id: "registerRoute.title",
  defaultMessage: "Sign up",
})

export const register: RouteDescriptor<Route.register> = {
  route: Route.register,
  render: RegisterPage,
  title: RegisterRouteTitleMessage,
  path: '/register',
  allowRoles: [
    Role.guest,
  ],
  redirectToHomeRoles: [
    ...groupNotGuest,
  ],
  showInSidebar: false,
}