import React, {FC} from 'react';
import styled from "styled-components";
import {CustomButton, CustomButtonSizeType} from "../AntCustom/CustomButton";

const NoDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 35px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  max-width: 529px;
  text-align: center;
`;


interface INoData {
  title: string;
  actionButton?: {
    title: string;
    action: () => void
    heightSize?: CustomButtonSizeType;
  }
}

export const NoData: FC<INoData> = ({title, actionButton}) => {
  return (
    <NoDataStyled>
      <Title>{title}</Title>

      {actionButton &&
        <CustomButton heightSize={actionButton.heightSize ?? 's'} onClick={actionButton.action} type={'primary'}>
          {actionButton.title}
        </CustomButton>
      }
    </NoDataStyled>
  );
};
