import {createCrudSlice} from "./highOrderReducers/crudReducer";

export interface ILicense {
    id: string
    name: string
    path: string
    bytes: Uint8Array | null
}

const licensesSlice = createCrudSlice<ILicense>('licenses', {filter: []});

export const licensesActions = licensesSlice.actions;

export default licensesSlice.reducer;