import {UploadFile} from "antd/es/upload";
import {Logger} from "../../logger/Logger";
import {getErrorMessage} from "../../errorHelpers/getCommonErrorMessage";
import {getNginxHost} from "../../../API/helpers/getProxyUrl";

export const onPdfFilePreview = async (file: UploadFile) => {
  try {
    const arrayBuffer = await (file as unknown as File)?.arrayBuffer();
    const pdf = new Blob([arrayBuffer], {type: 'application/pdf'});
    const pdfURL = URL.createObjectURL(pdf);
    window.open(pdfURL, "_blank");
  } catch (e) {
    window.open(getNginxHost() + file.url, "_blank");
    Logger.error(getErrorMessage(e))
  }
}