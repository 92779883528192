import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const ChartererManagerMessage = defineMessage({
  id: "chartererManager.name",
  defaultMessage: "Operator, manager",
})

export const chartererManager: RoleDescriptor<Role.chartererManager> = {
  role: Role.chartererManager,
  name: ChartererManagerMessage,
  backendIdentifier: 'charterer_client',
  homeRoute: Route.profile,
  navigation: {
    profile: [
      Route.requests,
      Route.requestsFromClients,
    ],
    sidebar: [
      Route.newTripRequest,
      Route.requests,
      Route.searchEmptyLegs,
      Route.profile,
      Route.requestsFromClients,
    ]
  },
}