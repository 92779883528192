import React from 'react';
import styled from "styled-components";
import {PROJECT_NAME} from "../../../utils/constans/common/projectName";
import {routes} from "../../../config/acl/routes";
import {Route} from "../../../config/acl/routes/Route";
import {LegalDocumentEnum} from "../../../pages/General/LegalDocuments/LegalDocumentsPage";
import {defineMessage, defineMessages, useIntl} from "react-intl";
import {useNavigate} from "react-router";

const FooterStyled = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 100vw;
  background: white;
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  color: #000000;
  padding: 0 90px;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;

  & > ul {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    padding: 0;
    margin: 0;

    li {
      //display: inline-flex;
      margin-left: 16px;
      cursor: pointer;
    }
  }
`;


const FooterMessages = defineMessages({
  generalTerms: {
    id: "footer.generalTerms",
    defaultMessage: "General Terms & Conditions"
  },
  privacyPolicy: {
    id: "footer.privacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  privacyPrinciples: {
    id: "footer.privacyPrinciples",
    defaultMessage: "Privacy Principles",
  },
  cookiePolicy: {
    id: "footer.cookiePolicy",
    defaultMessage: "Cookie Policy",
  },
  subprocesses: {
    id: "footer.subprocesses",
    defaultMessage: "Subprocesses",
  },
})

export const Footer = () => {
  const date = new Date();
  const intl = useIntl();
  const navigate = useNavigate();

  const legalDocNavigation = {
    [LegalDocumentEnum.GeneralTerms]: {
      key: LegalDocumentEnum.GeneralTerms,
      name: intl.formatMessage(FooterMessages.generalTerms),
      path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.GeneralTerms),
    },
    //TODO add other docs
    // [LegalDocumentEnum.PrivacyPolicy]: {
    //   key: LegalDocumentEnum.PrivacyPolicy,
    //   name: intl.formatMessage(FooterMessages.privacyPolicy),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.PrivacyPolicy),
    // },
    // [LegalDocumentEnum.PrivacyPrinciples]: {
    //   key: LegalDocumentEnum.PrivacyPrinciples,
    //   name: intl.formatMessage(FooterMessages.privacyPrinciples),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.PrivacyPrinciples),
    // },
    // [LegalDocumentEnum.CookiePolicy]: {
    //   key: LegalDocumentEnum.CookiePolicy,
    //   name: intl.formatMessage(FooterMessages.cookiePolicy),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.CookiePolicy),
    // },
    // [LegalDocumentEnum.Subprocesses]: {
    //   key: LegalDocumentEnum.Subprocesses,
    //   name: intl.formatMessage(FooterMessages.subprocesses),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.Subprocesses),
    // },
  }

  return (
    <FooterStyled>
      <div>© {PROJECT_NAME} {date.getFullYear()}</div>

      <ul>
        {Object.values(legalDocNavigation).map(item =>
          <li key={item.key} onClick={() => navigate(item.path, {replace: true})}>{item.name}</li>
        )}
      </ul>
    </FooterStyled>
  );
};

