import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import styled from "styled-components";
import {PlanePhotos} from "./PlanePhotos";
import {PlaneCreateForm} from "./PlaneCreateForm";
import {UploadFile} from "antd/es/upload";
import { CustomDrawer } from '../../../../../components/UI/AntCustom/CustomDrawer';
import {defineMessages, useIntl} from "react-intl";
import {FlyHourPriceCalculatorDrawer} from "./flyHourPriceCalculator/FlyHourPriceCalculatorDrawer";
import {
  FlyHourPriceContextProvider
} from "./flyHourPriceCalculator/context/FlyHourPriceContextProvider";
import {Form} from "antd";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const DrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ImageSection = styled.div`
  width: 468px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
  }
`;

const FormSection = styled.div`
  width: 380px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
  }
`;


const PlaneCreateDrawerMessages = defineMessages({
  title: {
    id: "planeCreateDrawer.title",
    defaultMessage: "Add a new aircraft by filling out the mandatory fields below",
  },
})

interface IPlaneCreateDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const PlaneCreateDrawer: FC<IPlaneCreateDrawer> = ({isOpen, setIsOpen}) => {
  const intl = useIntl();
  const [isCalculatorOpen, setIsCalculatorOpen] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<UploadFile[]>([])
  const [planeCreateForm] = Form.useForm();

  return (
    <FlyHourPriceContextProvider isCalculatorOpen={isCalculatorOpen}>
      <CustomDrawer visible={isOpen && !isCalculatorOpen} onClose={() => setIsOpen(false)}>
        <DrawerContent>
          <ImageSection>
            <PlanePhotos imageFiles={imageFiles} edit={{setImageFiles: setImageFiles}} />
          </ImageSection>

          <FormSection>
            <Title>{intl.formatMessage(PlaneCreateDrawerMessages.title)}</Title>
            <PlaneCreateForm form={planeCreateForm} closeDrawer={() => setIsOpen(false)} imageFiles={imageFiles} setIsCalculatorOpen={setIsCalculatorOpen} />
          </FormSection>
        </DrawerContent>
      </CustomDrawer>

      {isCalculatorOpen && <FlyHourPriceCalculatorDrawer isOpen={isCalculatorOpen} setIsOpen={setIsCalculatorOpen} />}
    </FlyHourPriceContextProvider>
  );
};
