import React, {FC} from 'react';
import styled from "styled-components";
import {Typography} from "antd";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppSelector} from "../../../store/store";

interface IChatMyMessage {
  messageText: string;
  sendDate?: Date;
}

const ChatMyMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: 16px;
  box-sizing: border-box;
`;

const ChatMyMessageStyled = styled.div`
  border-radius: 5px;
  padding: 5px 10px;
  background: #1890ff;
  width: fit-content;
  text-align: left;
`;

export const ChatMyMessage: FC<IChatMyMessage> = ({messageText, sendDate}) => {
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);

  return (
    <ChatMyMessageWrapper>
      <ChatMyMessageStyled>
        <Typography style={{color: "#fff"}}>{messageText}</Typography>
      </ChatMyMessageStyled>
      <div style={{fontSize: 9}}>{sendDate && formatDate(sendDate, offset)}</div>
    </ChatMyMessageWrapper>
  );
};
