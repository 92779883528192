import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {NotFoundPage} from "../../../../../pages/General/NotFound/NotFoundPage";
import {groupAll} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const NotFoundRouteTitleMessage = defineMessage({
  id: "notFoundRoute.title",
  defaultMessage: "The requested page is not found",
})

export const notFound: RouteDescriptor<Route.notFound> = {
  route: Route.notFound,
  render: NotFoundPage,
  title: NotFoundRouteTitleMessage,
  path: '/404',
  allowRoles: [
    ...groupAll
  ],
  redirectToHomeRoles: [],
  showInSidebar: false,
}