import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Input, message, Radio} from "antd";
import {ScheduleType} from "../../../../../proto/generated/api_entities_pb";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {clearCreateEvent, createEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducer";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchAirports,
  fetchInitialAirportForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {fetchOwnPlanes} from "../../../../../utils/helpers/selectDataFetchers/fetchOwnPlanes";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {API} from "../../../../../API/API";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const ScheduleCreateModalMessages = defineMessages({
  success: {
    id: "scheduleCreateModal.success",
    defaultMessage: "The new event has been successfully added to schedule",
  },
  title: {
    id: "scheduleCreateModal.title",
    defaultMessage: "Add to schedule",
  }
})

interface IScheduleCreateFormData {
  planeId: number;
  eventType: ScheduleType;
  dateRange: [Dayjs, Dayjs];
  airportFromId: number;
  airportToId?: number;
  eventName?: string;
}

interface IScheduleCreateModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ScheduleCreateModal: FC<IScheduleCreateModal> = ({isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [eventTypeRadioValue, setEventTypeRadioValue] = useState<ScheduleType>(ScheduleType.ORDER_REQUEST);
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.schedule.create);

  useEffect(() => {
    return () => {
      dispatch(clearCreateEvent());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ScheduleCreateModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const handleFormValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "eventType") {
      const value = changedValues[fieldName];
      setEventTypeRadioValue(value)
    }
  }

  const onFinish = (values: IScheduleCreateFormData) => {
    dispatch(createEvent({
      eventType: values.eventType,
      eventName: values.eventName,
      planeId: values.planeId,
      dateFrom: values.dateRange[0].toDate(),
      dateTo: values.dateRange[1].toDate(),
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const getInitialAirportFromCallback = () => {
    const currentAirportFromId = form.getFieldValue('airportFromId');
    return fetchInitialAirportForSelect(currentAirportFromId);
  }

  const getInitialAirportToCallback = () => {
    const currentAirportToId = form.getFieldValue('airportToId');
    return fetchInitialAirportForSelect(currentAirportToId);
  }

  const initialValues: Partial<IScheduleCreateFormData> = {
    planeId: undefined,
    eventType: ScheduleType.ORDER_REQUEST,
    dateRange: undefined,
    airportFromId: undefined,
    airportToId: undefined,
    eventName: undefined,
  }

  return (
    <CustomModal
      title={intl.formatMessage(ScheduleCreateModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={handleFormValuesChange}
      >
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeLabel)}
            name={'planeId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              fetchOptions={fetchOwnPlanes}
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
            />
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.eventTypeLabel)}
            name={'eventType'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <Radio.Group>
              <Radio
                value={ScheduleType.ORDER_REQUEST}>{intl.formatMessage(CommonFormMessages.eventTypeOrderRequestRadio)}</Radio>;
              <Radio
                value={ScheduleType.EMPTY_LEG}>{intl.formatMessage(CommonFormMessages.eventTypeEmptyLegRadio)}</Radio>;
              <Radio value={ScheduleType.CUSTOM}>{intl.formatMessage(CommonFormMessages.eventTypeCustomRadio)}</Radio>;
            </Radio.Group>
          </CustomFieldInput>
        </Col>

        {eventTypeRadioValue === ScheduleType.CUSTOM &&
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.eventNameLabel)}
              name={'eventName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.eventNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        }

        <Col flex={1}>
          <CustomFieldInput
            label={`${intl.formatMessage(CommonFormMessages.dateRangeLabel)} (UTC)`}
            name={'dateRange'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <CustomDatePicker.RangePicker showTime format={"DD.MM.YYYY HH:mm"}
                                          placeholder={[intl.formatMessage(CommonUnitMessages.from), intl.formatMessage(CommonUnitMessages.to)]}
                                          inputReadOnly={true} placement={"bottomLeft"}
                                          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </CustomFieldInput>
        </Col>

        {eventTypeRadioValue !== ScheduleType.CUSTOM &&
          <>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
                name={'airportFromId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                  showSearch={true}
                  defaultOptionsFetchCallback={getInitialAirportFromCallback}
                />
              </CustomFieldInput>
            </Col>

            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
                name={'airportToId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                  showSearch={true}
                  defaultOptionsFetchCallback={getInitialAirportToCallback}
                />
              </CustomFieldInput>
            </Col>
          </>
        }

        {eventTypeRadioValue === ScheduleType.CUSTOM &&
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.stayAirportLabel)}
              name={'airportFromId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchAirports}
                placeholder={intl.formatMessage(CommonFormMessages.stayAirportPlaceholder)}
                showSearch={true}
                defaultOptionsFetchCallback={getInitialAirportFromCallback}
              />
            </CustomFieldInput>
          </Col>
        }

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(CommonButtonMessages.create)}
          </CustomButton>
        </CustomFieldInput>
      </Form>
    </CustomModal>
  );
};
