import {useContext} from "react";
import {FlyHourPriceContext} from "../context/FlyHourPriceContextProvider";

export const useFlyPriceCategorySetter = (categoryKeyName: string) => {
  const {
    lastSavedConstantCategory, setLastSavedConstantCategory,
    lastSavedChangeableCategory, setLastSavedChangeableCategory,
    lastSavedMarginCategory, setLastSavedMarginCategory,
    lastSavedCoefficientCategory, setLastSavedCoefficientCategory
  } = useContext(FlyHourPriceContext)

  switch (categoryKeyName) {
    case lastSavedConstantCategory.keyName:
      return setLastSavedConstantCategory;
    case lastSavedChangeableCategory.keyName:
      return setLastSavedChangeableCategory;
    case lastSavedMarginCategory.keyName:
      return setLastSavedMarginCategory;
    case lastSavedCoefficientCategory.keyName:
      return setLastSavedCoefficientCategory;
  }
}