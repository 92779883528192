import React, {FC} from 'react';
import styled from "styled-components";
import {NotificationEvent} from "./NotificationEvent";
import {INotification} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {readNotifications} from "../../../store/reducers/notificationsReducer/notificationsReducer";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const NotificationGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const NotificationsGroupUnreadHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 999;
  padding-bottom: 8px;
  
  
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    
    :last-child {
      color: #1890FF;
      cursor: pointer;
      transition: opacity 0.2s ease;
      :hover {
        opacity: 0.7;
      }
    }
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    background: #F3F5F7;
  }
`;

const NotificationsGroupDate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
  background: #FFFFFF;
  position: sticky;
  top: -1px;
  z-index: 999;
  padding-bottom: 8px;
  
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    background: #F3F5F7;
  }
`;


const NotificationGroupMessages = defineMessages({
  new: {
    id: "notificationGroup.new",
    defaultMessage: "New"
  },
  readAll: {
    id: "notificationGroup.readAll",
    defaultMessage: "Read all"
  },
})

interface INotificationGroup {
  notifications: INotification[];
  unread: boolean;
  dateText?: string;
}

export const NotificationGroup: FC<INotificationGroup> = ({notifications, unread, dateText}) => {
  const intl = useIntl();
  const unreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications);
  const dispatch = useAppDispatch();

  const readAll = () => {
    dispatch(readNotifications({notificationIds: unreadNotifications.map(n => n.id)}))
  }

  return (
    <NotificationGroupWrapper>
      {unread && <NotificationsGroupUnreadHeading>
        <div>{intl.formatMessage(NotificationGroupMessages.new)}</div>
        <div onClick={readAll}>{intl.formatMessage(NotificationGroupMessages.readAll)}</div>
      </NotificationsGroupUnreadHeading>}
      {!unread && dateText && <NotificationsGroupDate>{dateText}</NotificationsGroupDate>}

      {notifications.map(n => <NotificationEvent key={n.id} notification={n} />)}
    </NotificationGroupWrapper>
  );
};
