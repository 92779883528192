import {ScheduleType} from "../../../../../proto/generated/api_entities_pb";
import {Logger} from "../../../../../utils/logger/Logger";
import {IPlaneEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducerTypes";
import {IntlShape} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";

const getChartSegmentByPlaneEvent = (eventTypeName: string, planeEvents: IPlaneEvent[]): any => {
  return {
    name: eventTypeName,
    data: planeEvents.map(e => {
      return {
        x: e.plane.registrationNumber,
        y: [e.dateFrom.getTime(), e.dateTo.getTime()],
        meta: {
          planeEvent: e,
        }
      }
    }),
  }
}

export const GenerateScheduleChartSeries = (events: IPlaneEvent[], intl: IntlShape): ApexAxisChartSeries => {
  const emptyLegEvents: IPlaneEvent[] = [];
  const orderRequestEvents: IPlaneEvent[] = [];
  const customEvents: IPlaneEvent[] = [];

  events.forEach(e => {
    switch (e.eventType) {
      case ScheduleType.EMPTY_LEG:
        emptyLegEvents.push(e);
        break;
      case ScheduleType.ORDER_REQUEST:
        orderRequestEvents.push(e);
        break;
      case ScheduleType.CUSTOM:
        customEvents.push(e);
        break;
      default:
        Logger.error("Unknown event type in plane schedule.")
        break;
    }
  });

  return [
    getChartSegmentByPlaneEvent(intl.formatMessage(CommonFormMessages.eventTypeEmptyLegRadio), emptyLegEvents),
    getChartSegmentByPlaneEvent(intl.formatMessage(CommonFormMessages.eventTypeOrderRequestRadio), orderRequestEvents),
    getChartSegmentByPlaneEvent(intl.formatMessage(CommonFormMessages.eventTypeCustomRadio), customEvents),
  ];
}

export const getPlanesWithEventsCount = (events: IPlaneEvent[]): number => {
  const uniquePlanes = new Set();
  events.forEach(e => uniquePlanes.add(e.plane.id));
  return uniquePlanes.size;
}