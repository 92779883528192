import React, {FC} from 'react';
import {ExtendedMenuItem} from "../../../../hooks/useGenerateMenu";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";
import {HeaderMobileSecondLevelItem} from "./HeaderMobileSecondLevelItem";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

const HeaderMobileFirstLevelItemWrapper = styled.div<{isActive: boolean, isGroup: boolean}>`
  position: relative;
  width: 100%;
  background: ${props => props.isActive && !props.isGroup ? "#E9F6FE" : "#fff"};
  border-left: ${props => props.isActive ? "3px solid #1890FF" : "none"};
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  border-bottom: 1px solid #D9D9D9;
`;

const HeaderMobileFirstLevelItemContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-grow: 1;

    & > div {
      :last-child {
        width: 100%;
      }
    }
  }
`;


interface IHeaderMobileFirstLevelItem {
  menuItem: ExtendedMenuItem;
  closeMenu: () => void;
}

export const HeaderMobileFirstLevelItem: FC<IHeaderMobileFirstLevelItem> = ({menuItem, closeMenu}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isActive = menuItem.key === pathname;
  const isGroup = menuItem.type === 'group';

  const onClick = () => {
    if (!isGroup) {
      navigate(menuItem.key as string)
      closeMenu()
    }
  }

  return (
    <HeaderMobileFirstLevelItemWrapper isActive={isActive} isGroup={isGroup} onClick={onClick}>
      <HeaderMobileFirstLevelItemContent>
        <div>{menuItem.icon}</div>
        <div>{menuItem.label}</div>
      </HeaderMobileFirstLevelItemContent>
      {menuItem.children && <div>
        {menuItem.children.map(m => <HeaderMobileSecondLevelItem key={m.key} menuItem={m} closeMenu={closeMenu} />)}
      </div>}
    </HeaderMobileFirstLevelItemWrapper>
  );
};
