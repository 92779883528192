import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  IGetChartererRoutesListPayload, IRespondChartererPayload,
  ISetChartererRoutesListResultPayload, ISetRespondChartererResultPayload,
  ITripBoardInitialState
} from "./tripBoardReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
// import {mapOrderRouteEntityToIChartererRoute} from "./tripBoardMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";

const tripBoardInitialState: ITripBoardInitialState = {
  itemsObject: {
    items: [],
    totalCount: 0,
    isLoading: true,
  },
  respondCharterer: {
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  }
}

const tripBoardSlice = createSlice({
    name: 'tripBoard',
    initialState: tripBoardInitialState,
    reducers: {
      getChartererRoutes(state, {payload}: PayloadAction<IGetChartererRoutesListPayload>) {
        initCommonGetList(state, payload);
      },
      clearChartererRoutes(state) {
        initCommonClearList(state);
      },
      setChartererRoutesResult(state, {payload}: PayloadAction<ISetChartererRoutesListResultPayload>) {
        // initCommonSetListResult(state, payload, mapOrderRouteEntityToIChartererRoute);
      },

      respondCharterer(state, {payload}: PayloadAction<IRespondChartererPayload>) {
        initCommonAction(state, nameof<ITripBoardInitialState>("respondCharterer"));
      },
      clearRespondCharterer(state) {
        initCommonClearAction(state, nameof<ITripBoardInitialState>("respondCharterer"))
      },
      setRespondChartererResult(state, {payload}: PayloadAction<ISetRespondChartererResultPayload>) {
        state.itemsObject.items = state.itemsObject.items.filter(route => route.orderRouteId !== payload.orderRouteId);
        state.itemsObject.totalCount--;
        initCommonActionResult(state, payload, nameof<ITripBoardInitialState>("respondCharterer"))
      }
    }
  }
);

export const {
  getChartererRoutes,
  clearChartererRoutes,
  setChartererRoutesResult,

  respondCharterer,
  clearRespondCharterer,
  setRespondChartererResult,
} =
  tripBoardSlice.actions;
export default tripBoardSlice.reducer;