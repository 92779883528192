import {NavigationDescriptor, NavigationDescriptorOverwrite, RoleDescriptor} from "../../../config/acl/roles/RoleDescriptor";
import {routes} from "../../../config/acl/routes";
import {Route} from "../../../config/acl/routes/Route";
import {Role} from "../../../config/acl/roles/Role";
import {roles} from "../../../config/acl/roles";
import {MessageDescriptor} from "react-intl";

export const guardIsOverwriteNavigationDescriptor = (navigationDescriptor: NavigationDescriptor): navigationDescriptor is NavigationDescriptorOverwrite => {
  return typeof navigationDescriptor === 'object';
}

export interface NavigationItem {
  path: string
  title: MessageDescriptor
}

const navigationDescriptorToHeaderLink = (navigationDescriptor: NavigationDescriptor): NavigationItem => {
  let path: string
  let title: MessageDescriptor;
  let route: Route;

  if (guardIsOverwriteNavigationDescriptor(navigationDescriptor)) {
    ({route, overwrite: {title}} = navigationDescriptor);
    ({path} = routes[route]);
  } else {
    ({path, title} = routes[navigationDescriptor]);
  }

  return {title, path};
}

const getCommonNavigationFromRole = (role: Role, key: keyof RoleDescriptor<any>['navigation']) => {
  return roles[role].navigation[key].map(navigationDescriptorToHeaderLink);
}

export const getHomeNavigationFromRole = (role: Role): NavigationItem[] => getCommonNavigationFromRole(role, 'profile');