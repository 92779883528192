import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useAppSelector} from "../../../store/store";

type NotificationsContextType = {
  isWindowOpen: boolean;
  setIsWindowOpen: React.Dispatch<React.SetStateAction<boolean>>,
  hasUnread: boolean;
  setHasUnread: React.Dispatch<React.SetStateAction<boolean>>;

  isChatFromNotificationEventOpened: boolean;
  setIsChatFromNotificationEventOpened: React.Dispatch<React.SetStateAction<boolean>>;
  chatFromNotificationEvent: ReactNode;
  setChatFromNotificationEvent: React.Dispatch<React.SetStateAction<ReactNode>>;
}

const notificationsContextState = {
  isWindowOpen: false,
  setIsWindowOpen: () => {
  },
  hasUnread: false,
  setHasUnread: () => {
  },

  isChatFromNotificationEventOpened: false,
  setIsChatFromNotificationEventOpened: () => {
  },
  chatFromNotificationEvent: null,
  setChatFromNotificationEvent: () => {
  },
}

export const NotificationsContext = React.createContext<NotificationsContextType>(notificationsContextState);

export const NotificationsProvider: FC<{ children: ReactNode }> = ({children}) => {
  const [isWindowOpen, setIsWindowOpen] = useState<boolean>(false);
  const [hasUnread, setHasUnread] = useState<boolean>(false);
  const [isChatFromNotificationEventOpened, setIsChatFromNotificationEventOpened] = useState<boolean>(false);
  const [chatFromNotificationEvent, setChatFromNotificationEvent] = useState<ReactNode>(null);
  const notifications = useAppSelector(state => state.notifications.itemsObject.items)

  useEffect(() => {
    setHasUnread(notifications.some(n => !n.isRead));
  }, [notifications])

  return (
    <NotificationsContext.Provider value={{
      isWindowOpen,
      setIsWindowOpen,
      hasUnread,
      setHasUnread,
      isChatFromNotificationEventOpened,
      setIsChatFromNotificationEventOpened,
      chatFromNotificationEvent,
      setChatFromNotificationEvent
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};
