import React, {FC, ReactNode, useEffect, useState} from "react";
import {
  ICurrency,
  IFlyHourCalculatorCategory
} from "../../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {PlaneCalculatorSettingEntity} from "../../../../../../../proto/generated/api_entities_pb";
import {useFlyHourPriceDefaultFields} from "../hooks/useFlyHourPriceDefaultFields";
import {
  mapIFlyHourCalculatorCategoriesToPlaneCalculatorSettingEntities
} from "../../../../../../../store/reducers/fleetReducer/fleetMappers";
import {FlyHourPriceDefaultSubcategoryEnum} from "../types/flyHourPriceSettingEnums";

type FlyHourPriceContextType = {
  // related to calculator
  flyHoursCount: number | undefined;
  setFlyHoursCount: React.Dispatch<React.SetStateAction<number | undefined>>;

  currency: undefined | ICurrency;
  setCurrency: React.Dispatch<React.SetStateAction<undefined | ICurrency>>;

  flyHourPrice: number | undefined;
  setFlyHourPrice: React.Dispatch<React.SetStateAction<number | undefined>>;

  settingEntities: PlaneCalculatorSettingEntity[];
  setSettingEntities: React.Dispatch<React.SetStateAction<PlaneCalculatorSettingEntity[]>>;

  categoriesSaving: { categoryName: string; isSaved: boolean }[];
  setCategoriesSaving: React.Dispatch<React.SetStateAction<{ categoryName: string; isSaved: boolean }[]>>;

  isCalculateLoading: boolean;
  setIsCalculateLoading: React.Dispatch<React.SetStateAction<boolean>>;

  lastSavedConstantCategory: IFlyHourCalculatorCategory;
  setLastSavedConstantCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;

  lastSavedChangeableCategory: IFlyHourCalculatorCategory;
  setLastSavedChangeableCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;

  lastSavedMarginCategory: IFlyHourCalculatorCategory;
  setLastSavedMarginCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;

  lastSavedCoefficientCategory: IFlyHourCalculatorCategory;
  setLastSavedCoefficientCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;

  isMarginTaxesDisabled: boolean;
  setIsMarginTaxesDisabled: React.Dispatch<React.SetStateAction<boolean>>;

  // related to total window
  setSavedConstantCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;
  setSavedChangeableCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;
  setSavedMarginCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;
  setSavedCoefficientCategory: React.Dispatch<React.SetStateAction<IFlyHourCalculatorCategory>>;

  savedFlyHoursCount: number | undefined;
  setSavedFlyHoursCount: React.Dispatch<React.SetStateAction<number | undefined>>;

  savedCurrency: undefined | ICurrency;
  setSavedCurrency: React.Dispatch<React.SetStateAction<undefined | ICurrency>>;

  savedFlyHourPrice: number | undefined;
  setSavedFlyHourPrice: React.Dispatch<React.SetStateAction<number | undefined>>;

  savedSettingEntities: PlaneCalculatorSettingEntity[];
  setSavedSettingEntities: React.Dispatch<React.SetStateAction<PlaneCalculatorSettingEntity[]>>;
}

// @ts-ignore
export const FlyHourPriceContext = React.createContext<FlyHourPriceContextType>(null);

interface FlyHourPriceContextProviderProps {
  initialFlyHoursCount?: number;
  initialCurrency?: ICurrency;
  initialFlyHourPrice?: number;
  initialConstantCategory?: IFlyHourCalculatorCategory;
  initialChangeableCategory?: IFlyHourCalculatorCategory;
  initialMarginCategory?: IFlyHourCalculatorCategory;
  initialCoefficientCategory?: IFlyHourCalculatorCategory;
  isCalculatorOpen: boolean;
  children: ReactNode;
}

export const FlyHourPriceContextProvider: FC<FlyHourPriceContextProviderProps> = ({
                                                                                    children,
                                                                                    initialFlyHoursCount,
                                                                                    initialCurrency,
                                                                                    initialFlyHourPrice,
                                                                                    initialConstantCategory,
                                                                                    initialChangeableCategory,
                                                                                    initialMarginCategory,
                                                                                    initialCoefficientCategory,
                                                                                    isCalculatorOpen,
                                                                                  }) => {
  let initialCategories = [];
  initialConstantCategory && initialCategories.push(initialConstantCategory);
  initialChangeableCategory && initialCategories.push(initialChangeableCategory);
  initialMarginCategory && initialCategories.push(initialMarginCategory);
  initialCoefficientCategory && initialCategories.push(initialCoefficientCategory);

  const {
    constantCategory,
    changeableCategory,
    marginCategory,
    coefficientCategory,
  } = useFlyHourPriceDefaultFields(initialCategories);


  const [flyHoursCount, setFlyHoursCount] = useState<number | undefined>(initialFlyHoursCount);
  const [currency, setCurrency] = useState<ICurrency | undefined>(initialFlyHoursCount ? initialCurrency : undefined);
  const [flyHourPrice, setFlyHourPrice] = useState<number | undefined>(initialFlyHoursCount);
  const [settingEntities, setSettingEntities] = useState<PlaneCalculatorSettingEntity[]>([]);
  const [categoriesSaving, setCategoriesSaving] = useState<{ categoryName: string; isSaved: boolean }[]>(
    [constantCategory, changeableCategory, marginCategory, coefficientCategory].map(c => {
      return {
        categoryName: c.keyName,
        isSaved: true,
      }
    })
  );
  const [isCalculateLoading, setIsCalculateLoading] = useState<boolean>(false);
  const [lastSavedConstantCategory, setLastSavedConstantCategory] = useState<IFlyHourCalculatorCategory>(constantCategory);
  const [lastSavedChangeableCategory, setLastSavedChangeableCategory] = useState<IFlyHourCalculatorCategory>(changeableCategory);
  const [lastSavedMarginCategory, setLastSavedMarginCategory] = useState<IFlyHourCalculatorCategory>(marginCategory);
  const [lastSavedCoefficientCategory, setLastSavedCoefficientCategory] = useState<IFlyHourCalculatorCategory>(coefficientCategory);
  const [isMarginTaxesDisabled, setIsMarginTaxesDisabled] = useState<boolean>(!marginCategory.subcategories.find(sc => sc.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_COMPANY_MARGIN)?.settingTypes.find(st => st)?.setting.value);

  const [savedFlyHoursCount, setSavedFlyHoursCount] = useState<number | undefined>(initialFlyHoursCount);
  const [savedCurrency, setSavedCurrency] = useState<ICurrency | undefined>(initialCurrency);
  const [savedFlyHourPrice, setSavedFlyHourPrice] = useState<number | undefined>(initialFlyHourPrice);
  const [savedSettingEntities, setSavedSettingEntities] = useState<PlaneCalculatorSettingEntity[]>(
    (initialFlyHoursCount && initialConstantCategory && initialChangeableCategory && initialMarginCategory && initialCoefficientCategory)
      ? mapIFlyHourCalculatorCategoriesToPlaneCalculatorSettingEntities(initialFlyHoursCount,
        [initialConstantCategory, initialChangeableCategory, initialMarginCategory, initialCoefficientCategory])
      : []
  );
  const [savedConstantCategory, setSavedConstantCategory] = useState<IFlyHourCalculatorCategory>(constantCategory);
  const [savedChangeableCategory, setSavedChangeableCategory] = useState<IFlyHourCalculatorCategory>(changeableCategory);
  const [savedMarginCategory, setSavedMarginCategory] = useState<IFlyHourCalculatorCategory>(marginCategory);
  const [savedCoefficientCategory, setSavedCoefficientCategory] = useState<IFlyHourCalculatorCategory>(coefficientCategory);

  useEffect(() => {
    if (!isCalculatorOpen) {
      setFlyHoursCount(savedFlyHoursCount);
      setCurrency(savedCurrency);
      setFlyHourPrice(savedFlyHourPrice);
      setSettingEntities(savedSettingEntities);
      setCategoriesSaving(
        [constantCategory, changeableCategory, marginCategory, coefficientCategory].map(c => {
          return {
            categoryName: c.keyName,
            isSaved: true,
          }
        })
      );
      setIsCalculateLoading(false);
      setLastSavedConstantCategory(savedConstantCategory);
      setLastSavedChangeableCategory(savedChangeableCategory);
      setLastSavedMarginCategory(savedMarginCategory);
      setLastSavedCoefficientCategory(savedCoefficientCategory);
    }
  }, [isCalculatorOpen])

  return (
    <FlyHourPriceContext.Provider value={{
      flyHoursCount,
      setFlyHoursCount,
      currency,
      setCurrency,
      flyHourPrice,
      setFlyHourPrice,
      settingEntities,
      setSettingEntities,
      categoriesSaving,
      setCategoriesSaving,

      isCalculateLoading,
      setIsCalculateLoading,
      lastSavedConstantCategory,
      setLastSavedConstantCategory,
      lastSavedChangeableCategory,
      setLastSavedChangeableCategory,
      lastSavedMarginCategory,
      setLastSavedMarginCategory,
      lastSavedCoefficientCategory,
      setLastSavedCoefficientCategory,
      isMarginTaxesDisabled,
      setIsMarginTaxesDisabled,

      savedFlyHoursCount,
      setSavedFlyHoursCount,
      savedFlyHourPrice,
      setSavedFlyHourPrice,
      savedSettingEntities,
      setSavedSettingEntities,
      savedCurrency,
      setSavedCurrency,

      setSavedConstantCategory,
      setSavedChangeableCategory,
      setSavedMarginCategory,
      setSavedCoefficientCategory,
    }}>
      {children}
    </FlyHourPriceContext.Provider>
  );
};