import React, {FC} from 'react';
import {
  IChartererOrder,
  IChartererOrderRequestFromOrder,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {FlyRoute} from "../../flyRoute/FlyRoute";
import {
  FlightDrawerDetailsHeader,
  FlightDrawerDetailsTitle,
  FlightDrawerDetailsTitleWrapper,
  FlightDrawerDetailsWrapper,
} from "./FlightDrawerDetailsStyledComponents";
import {defineMessages, useIntl} from "react-intl";
import {OrderTypeShield} from "../../orderType/OrderTypeShield";
import {Logger} from "../../../../utils/logger/Logger";


const FlightDrawerOrderOrderRequestDetailsMessages = defineMessages({
  title: {
    id: "flightDrawerOrderRouteOrderRequestDetails.title",
    defaultMessage: "Route details",
  },
})

interface IFlightDrawerOrderOrderRequestDetails {
  order: IChartererOrder;
  orderRequest: IChartererOrderRequestFromOrder;
}

export const FlightDrawerOrderOrderRequestDetails: FC<IFlightDrawerOrderOrderRequestDetails> = ({
                                                                                                  order,
                                                                                                  orderRequest
                                                                                                }) => {
  const intl = useIntl();

  return (
    <FlightDrawerDetailsWrapper>
      <FlightDrawerDetailsHeader>
        <FlightDrawerDetailsTitleWrapper>
          <FlightDrawerDetailsTitle>{intl.formatMessage(FlightDrawerOrderOrderRequestDetailsMessages.title)}</FlightDrawerDetailsTitle>
        </FlightDrawerDetailsTitleWrapper>
        <OrderTypeShield type={order.type} styleType={'fill'}/>
      </FlightDrawerDetailsHeader>

      {orderRequest.orderRouteOrderRequests.map((orderRouteOrderRequest, index) => {
        const {arrivalDate, flyHours, flyMinutes, flyDistance, technicalStopDuration = 0} = orderRouteOrderRequest;
        if ([arrivalDate, flyHours, flyMinutes, flyDistance].some(p => p === undefined)) {
          Logger.error("No arrivalDate || flyHours || flyMinutes || flyDistance for orderRouteOrderRequest")
          return null;
        }

        return <FlyRoute key={orderRouteOrderRequest.orderRouteOrderRequestId}
                         index={index}
                         airportFrom={orderRouteOrderRequest.orderRoute.airportFrom}
                         airportTo={orderRouteOrderRequest.orderRoute.airportTo}
                         dateFrom={orderRouteOrderRequest.orderRoute.departureDate}
                         dateTo={arrivalDate}
                         flyHoursDuration={flyHours}
                         flyMinutesDuration={flyMinutes}
                         distance={flyDistance}
                         technicalStopHoursDuration={Math.floor(technicalStopDuration / 60)}
                         technicalStopMinutesDuration={technicalStopDuration % 60}
                         pax={orderRouteOrderRequest.orderRoute.pax}/>
      })}
    </FlightDrawerDetailsWrapper>
  );
};
