import React, {FC, useMemo, useState} from 'react';
import {
  IChartererOrder,
  IChartererOrderRequestFromOrder,
} from "../../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import styled from "styled-components";
import {formatDate} from "../../../../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {ChatModal} from "../../../../../../components/businessCommon/chat/ChatModal";
import {useChat} from "../../../../../../components/businessCommon/chat/useChat";
import {
  OrderOrderRequestFlightDrawer
} from "../../../../../../components/businessCommon/flightDrawer/wrappers/OrderOrderRequestFlightDrawer";
import {PlaneImage} from "../../../../../../components/businessCommon/planeImage/PlaneImage";
import {ReactComponent as ClockIcon} from "../../../../../../media/icons/clock.svg";
import {Col, Row} from "antd";
import {DetailItem} from "../../../../../../components/businessCommon/detailItem/DetailItem";
import {StatusShield} from "../../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";
import {
  OrderRequestStatusColorMap, useOrderRequestStatusName,
} from "../../../../../../utils/constans/statuses/orderRequestStatuses";
import {ReactComponent as CaretRightIcon} from "../../../../../../media/icons/caret_right.svg";
import {DisplayFlyPrice} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {DisplayFlyDuration} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../../intl/commonMessages/CommonDetailsMessages";
import {Logger} from "../../../../../../utils/logger/Logger";
import {ChatButton} from "../../../../../../components/businessCommon/chat/ChatButton";
import {readNotifications} from "../../../../../../store/reducers/notificationsReducer/notificationsReducer";
import {NotificationType} from "../../../../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";


const RespondCard = styled.div<{ hasNotifications: boolean }>`
  border: 2px solid ${props => props.hasNotifications ? "#1890FF" : "#D9D9D9"};
  background: #FFFFFF;
  border-radius: 10px;
  padding: 16px 16px 24px 16px;
`;

const RespondHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  column-gap: 16px;
`;

const RespondHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  img {
    border-radius: 100%;
  }
`;

const RespondHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;
  justify-content: center;

  div {
    :nth-child(1) {
      font-weight: 600;
    }

    :nth-child(2) {
      font-weight: 400;
    }
  }
`;

const RespondBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const RespondBodyTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    :first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    :last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 3px;

      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #040404;
    }
`;

const RespondFooter = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RespondStatusLeft = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const ActionButton = styled.button<{ buttonType: 'approve' | 'decline' | 'default' }>`
  border: none;
  border-radius: 10px;
  outline: none;
  background: ${props => (props.buttonType === 'approve' && "#63A070")
          || (props.buttonType === 'decline' && "#C05353")
          || (props.buttonType === 'default' && "#1890FF")};
  //width: 35px;
  height: 35px;
  display: flex;
  padding: 0 22px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  column-gap: 3px;
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;


const OrderRouteCharterRespondCardMessages = defineMessages({
  details: {
    id: "orderRouteCharterRespondCard.details",
    defaultMessage: "Details",
  }
});

interface IOrderRouteCharterRespondCard {
  order: IChartererOrder;
  orderRequest: IChartererOrderRequestFromOrder;
}


export const OrderCharterRespondCard: FC<IOrderRouteCharterRespondCard> = ({
                                                                             order,
                                                                             orderRequest,
                                                                           }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isChatOpened, setIsChatOpened] = useState<boolean>(false);
  const [isCharterDrawerOpen, setIsCharterDrawerOpen] = useState<boolean>(false);
  const userId = useAppSelector(state => state.user.oneItemObject.item.id);
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const orderRequestStatusName = useOrderRequestStatusName(orderRequest.status);
  const chat = useChat(userId, orderRequest.freighterUser.id, order.id)
  const allUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)

  const hasNotifications = useMemo(() => {
    const unreadNotifications = allUnreadNotifications.filter(n => n.metadata.orderId === order.id
      && n.metadata.orderRequestId === orderRequest.orderRequestId);
    if (unreadNotifications.length > 0) {
      dispatch(readNotifications({
        notificationIds: unreadNotifications.filter(n => n.type !== NotificationType.TYPE_INCOME_MESSAGE).map(n => n.id)
      }));
    }
    return unreadNotifications.length > 0;
  }, []);

  const unreadChatNotifications = useMemo(() => allUnreadNotifications.filter(n => n.entityId === chat?.chatId
    && n.metadata.orderId === order.id
    && n.metadata.orderRequestId === orderRequest.orderRequestId), [allUnreadNotifications]);

  if (!chat)
    Logger.error(`No chat found for: userId (account owner): ${userId}, freighterUserId: ${orderRequest.freighterUser.id}, orderId: ${order.id}`)

  const onChatOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsChatOpened(true);
  }

  const chatModal = isChatOpened && chat &&
    <ChatModal chat={chat}
               isVisible={isChatOpened}
               setIsVisible={setIsChatOpened}
    />

  const {totalFlyHours, totalFlyMinutes} = useMemo(() => orderRequest.orderRouteOrderRequests.reduce((acc, cur) => {
    acc.totalFlyHours += cur.flyHours;
    acc.totalFlyMinutes += cur.flyMinutes;
    return acc;
  }, {totalFlyHours: 0, totalFlyMinutes: 0}), [orderRequest.orderRouteOrderRequests])

  if (!orderRequest.plane) {
    return <RespondCard hasNotifications={hasNotifications}>
      <RespondHeader>
        <RespondHeaderLeft>
          <PlaneImage files={[]} width={"44px"} height={"44px"}/>
          <RespondHeaderTitle>
            <div>{orderRequest.freighterCompany.name}</div>
          </RespondHeaderTitle>
        </RespondHeaderLeft>
        <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen}/>
      </RespondHeader>

      <RespondBody>
        <RespondBodyTitle>
          <div>Отклик №{orderRequest.orderRequestId}</div>
          <div>
            <div>
              <ClockIcon/>
              <div>{formatDate(orderRequest.createdAtDate, offset, "datetime_short_year")}</div>
            </div>
          </div>
        </RespondBodyTitle>

        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.duration)}
                        value={"—"}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.distance)}
                        value={"—"}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.price)}
                        value={"—"}/>
          </Col>
        </Row>
      </RespondBody>

      <RespondFooter>
        <RespondStatusLeft>
          <StatusShield backgroundColor={OrderRequestStatusColorMap[orderRequest.status]}>
            {orderRequestStatusName}
          </StatusShield>
        </RespondStatusLeft>
      </RespondFooter>

      {chatModal}
    </RespondCard>
  }

  return (
    <RespondCard hasNotifications={hasNotifications}>
      <RespondHeader>
        <RespondHeaderLeft>
          <PlaneImage files={orderRequest.plane.imageFiles} width={"44px"} height={"44px"}/>
          <RespondHeaderTitle>
            <div>{orderRequest.plane.planeType.name}</div>
            <div>{orderRequest.freighterCompany.name}</div>
          </RespondHeaderTitle>
        </RespondHeaderLeft>
        <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen}/>
      </RespondHeader>

      <RespondBody>
        <RespondBodyTitle>
          <div>Отклик №{orderRequest.orderRequestId}</div>
          <div>
            <div>
              <ClockIcon/>
              <div>{formatDate(orderRequest.createdAtDate, offset, "datetime_short_year")}</div>
            </div>
          </div>
        </RespondBodyTitle>

        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.duration)}
                        value={DisplayFlyDuration(totalFlyHours, totalFlyMinutes)}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.pax)}
                        value={orderRequest.plane.maxPassengers.toString()}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.price)}
                        value={DisplayFlyPrice(orderRequest.price, orderRequest.currency)}/>
          </Col>
        </Row>
      </RespondBody>

      <RespondFooter>
        <RespondStatusLeft>
          <StatusShield backgroundColor={OrderRequestStatusColorMap[orderRequest.status]}>
            {orderRequestStatusName}
          </StatusShield>
        </RespondStatusLeft>

        <ActionButton buttonType={"default"}
                      onClick={() => setIsCharterDrawerOpen(true)}>
          <div>{intl.formatMessage(OrderRouteCharterRespondCardMessages.details)}</div>
          <CaretRightIcon/>
        </ActionButton>
      </RespondFooter>

      {isCharterDrawerOpen &&
        <OrderOrderRequestFlightDrawer isOpen={isCharterDrawerOpen} setIsOpen={setIsCharterDrawerOpen}
                                       order={order} orderRequest={orderRequest}
                                       openChat={() => setIsChatOpened(true)}/>}

      {chatModal}
    </RespondCard>
  );
};
