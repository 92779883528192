import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {
  BookingApiPlaneCategoryListRequest,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {InitialPage} from "../../constans/pagination/pagination";
import {PageModelLimits} from "../../constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {IPlaneCategory} from "../../../store/reducers/fleetReducer/fleetReducerTypes";

export async function fetchPlaneCategories(search: string): Promise<DebounceSelectOptionProps[]> {
  const req = new BookingApiPlaneCategoryListRequest()
    // .setQuery(search)
    .setPagemodel(
      new PageModel().setPage(InitialPage).setLimit(PageModelLimits.Select)
    )

  const planeCategoryList = await API.getPlaneCategoryList(req);

  return planeCategoryList.getPlanecategoriesList().map(pc => {
    return {
      key: pc.getPlanecategoryid(),
      label: pc.getName(),
      value: pc.getPlanecategoryid(),
    }
  })
}

export const getInitialPlaneCategoryForSelect = (planeCategory: IPlaneCategory): DebounceSelectOptionProps => {
  return {
    key: planeCategory.id,
    value: planeCategory.id,
    label: planeCategory.name,
  }
}