import {OrderEntity} from "../../../proto/generated/api_entities_pb";
import {
  IChartererEmptyLegFromOrder,
  IChartererOrder, IChartererOrderRequestFromOrder, IChartererOrderRouteOrderRequest,
  OrderTypeEnum
} from "./chartererRequestsReducerTypes";
import {isValidDate, parseStringAsUTCDate,} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {
  mapAirportEntityToIAirport,
  mapPlaneCategoryEntityToIPlaneCategory,
  mapPlaneEntityToIPlane
} from "../fleetReducer/fleetMappers";
import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {mapUserEntityToIUser} from "../userReducer/userMappers";
import {mapCompanyEntityToICompany} from "../companyReducer/companyMappers";
import {calculateArrivalDate} from "../../../utils/helpers/dateHelpers/calculateArrivalDate";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";


export const mapOrderEntityToIChartererOrder = (item: OrderEntity): IChartererOrder => {
  const type = item.getType() as OrderTypeEnum;

  const planeCategories = item.getPlanecategoriesList().map(mapPlaneCategoryEntityToIPlaneCategory);

  const user = item.getUser();
  if (!user)
    throw new Error("Null UserEntity (user) in received OrderEntity");

  const company = user.getCompany();
  if (!company)
    throw new Error("Null CompanyEntity (company) in received OrderEntity");

  const createdAtDate = parseStringAsUTCDate(item.getCreatedat());
  if (!isValidDate(createdAtDate))
    throw new Error(`Invalid createdAtDate in received OrderRouteEntity, ${item.getCreatedat()}`);

  const nestedEmptyLegs: IChartererEmptyLegFromOrder[] = [];

  const orderRoutes = item.getOrderroutesList().map(orderRoute => {
    const airportFrom = orderRoute.getAirportfrom();
    if (!airportFrom)
      throw new Error("Null AirportEntity (airportFrom) in received OrderRouteEntity");
    const airportFromMapped = mapAirportEntityToIAirport(airportFrom);

    const airportTo = orderRoute.getAirportto();
    if (!airportTo)
      throw new Error("Null AirportEntity (airportTo) in received OrderRouteEntity");
    const airportToMapped = mapAirportEntityToIAirport(airportTo);

    const departureDate = parseStringAsUTCDate(orderRoute.getDeparturedate());
    if (!isValidDate(departureDate))
      throw new Error(`Invalid departureDate in received OrderRouteEntity, ${orderRoute.getDeparturedate()}`);

    if (type === OrderTypeEnum.EMPTY_LEG) {
      const emptyLegList: IChartererEmptyLegFromOrder[] = orderRoute.getEmptylegsList().map(el => {
        const freighterCompany = el.getFlycompany();
        if (!freighterCompany)
          throw new Error("Null CompanyEntity (flyCompany) in received OrderRouteEntity > EmptyLegEntity");

        const freighterUser = el.getFreighter();
        if (!freighterUser)
          throw new Error("Null UserEntity (freighter) in received OrderRouteEntity > EmptyLegEntity");

        const emptyLegAirportFrom = el.getAirportfrom();
        if (!emptyLegAirportFrom)
          throw new Error("Null AirportEntity (airportFrom) in received OrderRouteEntity > EmptyLegEntity");

        const emptyLegAirportTo = el.getAirportto();
        if (!emptyLegAirportTo)
          throw new Error("Null AirportEntity (airportTo) in received OrderRouteEntity > EmptyLegEntity");

        const plane = el.getPlane();
        if (!plane)
          throw new Error("Null PlaneEntity (plane) in received OrderRouteEntity > EmptyLegEntity");

        const availableFromDate = el.getAvailablefromdate();
        if (availableFromDate && !isValidDate(parseStringAsUTCDate(availableFromDate)))
          throw new Error(`Invalid availableFromDate in received OrderRouteEntity > EmptyLegEntity, ${el.getAvailablefromdate()}`);

        const availableToDate = el.getAvailabletodate();
        if (availableToDate && !isValidDate(parseStringAsUTCDate(availableToDate)))
          throw new Error(`Invalid availableToDate in received OrderRouteEntity > EmptyLegEntity, ${el.getAvailabletodate()}`);

        const departureDate = el.getDeparturetime();
        if (departureDate && !isValidDate(parseStringAsUTCDate(departureDate)))
          throw new Error(`Invalid departureDate in received OrderRouteEntity > EmptyLegEntity, ${el.getAvailabletodate()}`);

        const arrivalDate = (departureDate && el.getFlyduration())
          ? new Date(parseStringAsUTCDate(departureDate).getTime() + 60000 * el.getFlyduration())
          : undefined;

        const currency = el.getCurrencyentity();
        if (!currency)
          throw new Error("Null CurrencyEntity (currencyEntity) in received OrderRouteEntity > EmptyLegEntity");

        const emptyLegOrderRouteEntity = orderRoute.getEmptylegorderroutesList().find(
          elOr => elOr.getEmptylegid() === el.getEmptylegid() && elOr.getOrderrouteid() === orderRoute.getOrderrouteid()
        );
        if (!emptyLegOrderRouteEntity)
          throw new Error("Null EmptyLegOrderRouteEntity (emptyLegOrderRouteEntity) in received OrderRouteEntity related to nested EmptyLegEntity");

        const flyHours = Math.floor(el.getFlyduration() / 60);
        const flyMinutes = el.getFlyduration() % 60;

        const dateModified = parseStringAsUTCDate(el.getUpdatedat());
        if (dateModified && !isValidDate(dateModified))
          throw new Error(`Invalid availableFromDate in received OrderRouteEntity > EmptyLegEntity, ${el.getUpdatedat()}`);

        return {
          emptyLegId: el.getEmptylegid(),
          orderRouteId: orderRoute.getOrderrouteid(),
          emptyLegOrderRouteId: emptyLegOrderRouteEntity.getEmptylegorderrouteid(),
          freighterUser: mapUserEntityToIUser(freighterUser),
          freighterCompany: mapCompanyEntityToICompany(freighterCompany),
          airportFrom: mapAirportEntityToIAirport(emptyLegAirportFrom),
          airportTo: mapAirportEntityToIAirport(emptyLegAirportTo),
          plane: mapPlaneEntityToIPlane(plane),
          availableFromDate: availableFromDate ? parseStringAsUTCDate(availableFromDate) : undefined,
          availableToDate: availableToDate ? parseStringAsUTCDate(availableToDate) : undefined,
          departureDate: departureDate ? parseStringAsUTCDate(departureDate) : undefined,
          arrivalDate: arrivalDate,
          price: el.getPrice(),
          currency: {
            id: currency.getCurrencyid(),
            name: currency.getName(),
          },
          flyHours: flyHours,
          flyMinutes: flyMinutes,
          flyDistance: el.getFlydistance(),
          comment: el.getComment(),
          dateModified: dateModified,
          status: emptyLegOrderRouteEntity.getStatus() as EmptyLegOrderRouteStatusEnum || EmptyLegOrderRouteStatusEnum.ACTIVE,
          isArchived: el.getArchived(),
        }
      });

      nestedEmptyLegs.push(...emptyLegList);
    }

    return {
      id: orderRoute.getOrderrouteid(),
      airportFrom: airportFromMapped,
      airportTo: airportToMapped,
      departureDate: departureDate,
      pax: orderRoute.getPax(),
    }
  })

  const nestedOrderRequests: IChartererOrderRequestFromOrder[] = item.getOrderrequestsList()
    //TODO ask backend to not send plane if there is no offer
    // .filter(orderRequest => orderRequest.getPlane() && orderRequest.getPlane()?.getPlaneid() !== 0)
    .map(orderOrderRequest => {
      const orderRouteOrderRequests: IChartererOrderRouteOrderRequest[] = orderOrderRequest.getOrderrouteorderrequestsList().map(orderRouteOrderRequest => {
        const orderRouteFromOrderRequest = item.getOrderroutesList().find(or => or.getOrderrouteid() === orderRouteOrderRequest.getOrderroute()?.getOrderrouteid());

        if (!orderRouteFromOrderRequest)
          throw new Error(`OrderRoute for OrderRequest (id: ${orderOrderRequest.getOrderrequestid()}) not found`);

        const airportFrom = orderRouteFromOrderRequest?.getAirportfrom();
        if (!airportFrom)
          throw new Error("Null AirportEntity (airportFrom) in received OrderRouteEntity");
        const airportFromMapped = mapAirportEntityToIAirport(airportFrom);

        const airportTo = orderRouteFromOrderRequest?.getAirportto();
        if (!airportTo)
          throw new Error("Null AirportEntity (airportTo) in received OrderRouteEntity");
        const airportToMapped = mapAirportEntityToIAirport(airportTo);

        const departureDate = orderRouteFromOrderRequest && parseStringAsUTCDate(orderRouteFromOrderRequest.getDeparturedate());
        if (!departureDate && !isValidDate(departureDate))
          throw new Error(`Invalid departureDate in received OrderRouteEntity, ${orderRouteFromOrderRequest?.getDeparturedate()}`);

        const arrivalDate = calculateArrivalDate(airportFromMapped, airportToMapped, departureDate, orderRouteOrderRequest.getFlyduration() + orderRouteOrderRequest.getTechnicalstopduration());
        if (!isValidDate(arrivalDate))
          throw new Error(`Invalid arrivalDate`);

        const flyHours = Math.floor(orderRouteOrderRequest.getFlyduration() / 60);
        const flyMinutes = orderRouteOrderRequest.getFlyduration() % 60;

        return {
          orderRouteOrderRequestId: orderRouteOrderRequest.getOrderrouteorderrequestid(),
          flyHours: flyHours,
          flyMinutes: flyMinutes,
          flyDistance: orderRouteOrderRequest.getFlydistance(),
          arrivalDate: arrivalDate,
          technicalStopDuration: orderRouteOrderRequest.getTechnicalstopduration(),
          technicalStopCount: orderRouteOrderRequest.getTechnicalstopcount(),
          orderRoute: {
            id: orderRouteFromOrderRequest.getOrderrouteid(),
            airportFrom: airportFromMapped,
            airportTo: airportToMapped,
            departureDate: departureDate,
            pax: orderRouteFromOrderRequest.getPax(),
          },
        }
      })

      //TODO discuss about timezones
      const createdAtDate = parseStringAsUTCDate(orderOrderRequest.getCreatedat());
      if (!isValidDate(createdAtDate))
        throw new Error(`Invalid createdAtDate in received OrderRouteEntity > OrderRequestEntity, ${orderOrderRequest.getCreatedat()}`);

      const freighterCompany = orderOrderRequest.getFlycompany();
      if (!freighterCompany)
        throw new Error("Null CompanyEntity (flyCompany) in received OrderRouteEntity > OrderRequestEntity");

      const freighterUser = orderOrderRequest.getFreighter();
      if (!freighterUser)
        throw new Error("Null UserEntity (freighter) in received OrderRouteEntity > OrderRequestEntity");

      const currency = orderOrderRequest.getCurrency();
      if (!currency)
        throw new Error("Null CurrencyEntity (currency) in received OrderRouteEntity > OrderRequestEntity");

      const plane = orderOrderRequest.getPlane();
      const isPlaneOffered = !!plane && plane.getPlaneid() !== 0;

      return {
        orderRequestId: orderOrderRequest.getOrderrequestid(),
        createdAtDate: createdAtDate,
        freighterUser: mapUserEntityToIUser(freighterUser),
        freighterCompany: mapCompanyEntityToICompany(freighterCompany),
        plane: isPlaneOffered ? mapPlaneEntityToIPlane(plane) : undefined,
        price: orderOrderRequest.getPrice(),
        currency: {
          id: currency.getCurrencyid(),
          name: currency.getName(),
        },
        orderRouteOrderRequests: orderRouteOrderRequests,
        status: orderOrderRequest.getOrderrequeststatusid() as OrderRequestStatusEnum,
        isArchived: orderOrderRequest.getArchived(),
      }
    })


  return {
    id: item.getOrderid(),
    type: type,
    user: mapUserEntityToIUser(user),
    company: mapCompanyEntityToICompany(company),
    createdDate: createdAtDate,
    planeCategories: planeCategories,
    companionsAllowed: item.getCompanionsallowed(),
    comment: item.getComment(),
    orderRoutes: orderRoutes,
    nestedEmptyLegs: nestedEmptyLegs,
    nestedOrderRequests: nestedOrderRequests,
    isArchived: item.getArchived() || false,
  }
}