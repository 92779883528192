import React, {FC, ReactNode} from 'react';
import {Result, ResultProps} from "antd";
import styled from "styled-components";

const ResultStyled = styled(Result)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

interface ICustomResult extends ResultProps {
  children?: ReactNode;
}

export const CustomResult: FC<ICustomResult> = ({children, ...props}) => {
  return (
    <ResultStyled {...props}>
      {children}
    </ResultStyled>
  );
};
