import dayjs from "dayjs";

export const isValidDate = (date: any) => {
  // @ts-ignore
  return date instanceof Date && !isNaN(date);
}

export type DateFormat = 'date' | 'time' | 'datetime' | 'year' | 'datetime_short_year';

export const formatDate = (date: Date, timezoneOffset: number, format: DateFormat = 'datetime'): string => {
  const newDate = new Date(date.getTime() + timezoneOffset * 60000);
  let dd: number | string = newDate.getUTCDate();
  if (dd < 10) dd = '0' + dd;

  let mm: number | string = newDate.getUTCMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  let yyyy: number = newDate.getUTCFullYear();

  let hh: number | string = newDate.getUTCHours();
  if (hh < 10) hh = '0' + hh;

  let minutes: number | string = newDate.getUTCMinutes();
  if (minutes < 10) minutes = '0' + minutes;

  switch (format) {
    case "year":
      return yyyy.toString();
    case "date":
      return dd + '.' + mm + '.' + yyyy;
    case "time":
      return hh + ':' + minutes;
    case "datetime_short_year":
      return dd + '.' + mm + '.' + (yyyy % 100) + ' ' + hh + ':' + minutes;
    default:
      return dd + '.' + mm + '.' + yyyy + ' ' + hh + ':' + minutes;
  }
}

export const parseStringAsUTCDate = (dateString: string) => {
  //for safari support
  const dateAndTime = dateString.split(' ');
  const formattedDateTimeString = dateAndTime[0] + "T" + dateAndTime[1] + ".000Z";
  return  new Date(Date.parse(formattedDateTimeString));
}

// Converts local date Thu Dec 08 2022 13:00:00 GMT+0300 to 2022-12-08T13:00:00.000Z
export const getLocalDateAsISOString = (date: Date) => {
  const pad = (num: number) => {
    return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    '.000Z'
}

export const dayjsWithOffset = (date: Date, offset: number) => {
  // first offset - is our local offset, second is custom
  const dateWithOffset = new Date(date.getTime() + date.getTimezoneOffset() * 60000 + offset * 60000);
  return dayjs(dateWithOffset)
}
