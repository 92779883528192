import {Role} from "./Role";

export const groupAll = [
  Role.guest,
  Role.admin,
  Role.freighterAdmin,
  Role.freighterAdminNotApproved,
  Role.freighterClientManager,
  Role.freighterServiceManager,
  Role.chartererAdmin,
  Role.chartererAdminNotApproved,
  Role.chartererManager,
];

export const groupNotGuest = [
  Role.admin,
  Role.freighterAdmin,
  Role.freighterAdminNotApproved,
  Role.freighterClientManager,
  Role.freighterServiceManager,
  Role.chartererAdmin,
  Role.chartererAdminNotApproved,
  Role.chartererManager,
];

export const groupProfile = [
  Role.admin,
  Role.freighterAdmin,
  Role.freighterAdminNotApproved,
  Role.freighterClientManager,
  Role.freighterServiceManager,
  Role.chartererAdmin,
  Role.chartererAdminNotApproved,
  Role.chartererManager,
];

export const groupCharterer = [
  Role.chartererAdminNotApproved,
  Role.chartererAdmin,
  Role.chartererManager,
];

export const groupFreighter = [
  Role.freighterAdminNotApproved,
  Role.freighterAdmin,
  Role.freighterClientManager,
  Role.freighterServiceManager,
];

export const groupUserAdmin = [
  Role.freighterAdmin,
  Role.chartererAdmin,
];

export const groupNotApproved = [
  Role.freighterAdminNotApproved,
  Role.chartererAdminNotApproved,
];

export const groupApproved = [
  Role.freighterAdmin,
  Role.freighterClientManager,
  Role.freighterServiceManager,
  Role.chartererAdmin,
  Role.chartererManager
];