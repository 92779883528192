import React, {Dispatch, FC, SetStateAction} from 'react';
import {
  IEmptyLeg,
  IEmptyLegOrder,
} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {BrokerDrawerManager} from "../manager/BrokerDrawerManager";
import {ReactComponent as ChatIcon} from "../../../../media/icons/chat_white.svg";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {BrokerDrawerContentWrapper} from "./BrokerDrawerStyledComponents";
import {BrokerDrawerCompany} from "../company/BrokerDrawerCompany";
import {BrokerDrawerEmptyLegRespondDetails} from "../details/BrokerDrawerEmptyLegRespondDetails";
import {BrokerDrawerEmptyLegRespondFooter} from "../footers/BrokerDrawerEmptyLegRespondFooter";
import {defineMessages, useIntl} from "react-intl";


const EmptyLegRespondBrokerDrawerMessages = defineMessages({
  writeToChat: {
    id: "emptyLegRespondBrokerDrawer.writeToChat",
    defaultMessage: "Send message"
  },
})

interface IEmptyLegRespondBrokerDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  emptyLeg: IEmptyLeg;
  emptyLegOrder: IEmptyLegOrder;
  openChat: () => void;
}

export const EmptyLegRespondBrokerDrawer: FC<IEmptyLegRespondBrokerDrawer> = ({isOpen, setIsOpen, emptyLeg, emptyLegOrder, openChat}) => {
  const intl = useIntl();

  const footer = <BrokerDrawerEmptyLegRespondFooter emptyLeg={emptyLeg} emptyLegOrder={emptyLegOrder} />

  return (
    <CustomDrawer
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      footer={footer}
    >
      <BrokerDrawerContentWrapper>
        <BrokerDrawerManager manager={emptyLegOrder.manager} />

        <CustomButton type={"primary"} onClick={openChat} style={{borderRadius: 10, marginBottom: 10}}>
          <ChatIcon/>
          <div>{intl.formatMessage(EmptyLegRespondBrokerDrawerMessages.writeToChat)}</div>
        </CustomButton>

        <BrokerDrawerCompany company={emptyLegOrder.company} />

        <BrokerDrawerEmptyLegRespondDetails emptyLegOrder={emptyLegOrder} />
      </BrokerDrawerContentWrapper>
    </CustomDrawer>
  );
};
