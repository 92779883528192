import React from 'react';
import styled from "styled-components";

export const CustomLink = styled.span`
  color: #1890ff;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  transition: opacity 0.2s ease;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
  
  :hover {
    opacity: 0.8;
  }
`;
