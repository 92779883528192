import {Route} from "./Route";
import {RouteDescriptor} from "./RouteDescriptor";
import {home} from "./all/common/home";
import {price} from "./all/common/price";
import {agents} from "./all/common/agents";
import {login} from "./all/auth/login";
import {register} from "./all/auth/register";
import {setNewPassword} from "./all/auth/setNewPassword";
import {profile} from "./lk/lkCommon/profile";
import {newTripRequest} from "./lk/lkCharterer/newTripRequest";
import {searchEmptyLegs} from "./lk/lkCharterer/searchEmptyLegs";
import {requests} from "./lk/lkCharterer/requests";
import {requestsFromClients} from "./lk/lkCharterer/requestsFromClients";
import {fleet} from "./lk/lkFreighter/fleet";
import {requestsFromAgents} from "./lk/lkFreighter/requestsFromAgents";
import {emptyLegs} from "./lk/lkFreighter/emptyLegs";
import {adminRegistrations} from "./lk/lkAdmin/adminRegistrations";
import {notFound} from "./all/common/notFound";
import {schedule} from "./lk/lkFreighter/schedule";
import {lk} from "./lk/lk";
import {legalDocuments} from "./all/common/legalDocuments";

export const routes: {[key in Route]: RouteDescriptor<key>} = {
  [Route.home]: home,
  [Route.notFound]: notFound,

  // Роуты гостя
  [Route.price]: price,
  [Route.agents]: agents,
  [Route.legalDocuments]: legalDocuments,

  // Роуты авторизации и входа
  [Route.login]: login,
  [Route.register]: register,
  [Route.setNewPassword]: setNewPassword,

  [Route.lk]: lk,

  // Общие роуты фрахтователя и фрахтовщика
  [Route.profile]: profile,

  // Роуты фрахтователя
  [Route.newTripRequest]: newTripRequest,
  [Route.searchEmptyLegs]: searchEmptyLegs,
  [Route.requests]: requests,
  [Route.requestsFromClients]: requestsFromClients,

  // Роуты фрахтовщика
  [Route.requestsFromAgents]: requestsFromAgents,
  [Route.emptyLegs]: emptyLegs,
  // [Route.tripBoard]: tripBoard,
  [Route.schedule]: schedule,
  [Route.fleet]: fleet,

  // Роуты администратора сайта
  [Route.adminRegistrations]: adminRegistrations,
}