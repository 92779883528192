import {IAirport} from "../../../store/reducers/fleetReducer/fleetReducerTypes";
import {INotificationAirport} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";

export const DisplayAirport = (airport: IAirport, type: "allInfo" | "name" | "location_and_name" | "codes" = "codes") => {
  const icao = airport.icaoCode ? `${airport.icaoCode}` : "";
  const iata = airport.iataCode ? `${airport.iataCode}` : "";
  const name = airport.name ? `${airport.name}` : "";

  if (type === "location_and_name")
    return [airport.countryName, airport.name].join(', ')

  if (type === "name")
    return name;

  if (type === "codes")
    return [icao, iata].join(', ');

  return [icao, iata, name].join(', ');
}

export const DisplayRoute = (airports: IAirport[], type: "allInfo" | "name" | "codes" = "codes") => {
  const formattedAirports = airports.map(a => DisplayAirport(a, type));

  return formattedAirports.join(" → ");
}

export const DisplayNotificationRoute = (airports: INotificationAirport[]) => {
  const formattedAirports = airports.map(a => [a.icaoCode, a.iataCode].join(', '));

  return formattedAirports.join(" → ");
}