import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {
  BillingApiCurrencyListRequest,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {InitialPage} from "../../constans/pagination/pagination";
import {PageModelLimits} from "../../constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {ICurrency} from "../../../store/reducers/fleetReducer/fleetReducerTypes";

export async function fetchCurrencies(search?: string): Promise<DebounceSelectOptionProps[]> {
  const req = new BillingApiCurrencyListRequest()
    .setPagemodel(
      new PageModel().setPage(InitialPage).setLimit(PageModelLimits.Select)
    );

  search && req.setQuery(search)

  const currencyList = await API.getCurrencyList(req);

  return currencyList.getCurrenciesList().map(currency => {
    return {
      key: currency.getCurrencyid(),
      label: currency.getName(),
      value: currency.getCurrencyid(),
    }
  })
}

export const getInitialCurrencyForSelect = (currency: ICurrency): DebounceSelectOptionProps => {
  return {
    key: currency.id,
    value: currency.id,
    label: currency.name,
  }
}

export async function getCurrencyById(id: number): Promise<ICurrency | undefined> {
  const req = new BillingApiCurrencyListRequest()
    .setPagemodel(
      new PageModel().setPage(InitialPage).setLimit(PageModelLimits.Select)
    )

  const currencyList = await API.getCurrencyList(req);

  const currency = currencyList.getCurrenciesList().find(c => c.getCurrencyid() === id);

  if (!currency)
    throw new Error("No currency found by given id: " + id)

  return {
    id: currency.getCurrencyid(),
    name: currency.getName(),
  }
}