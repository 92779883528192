import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import React, {Dispatch, FC, SetStateAction} from "react";
import {Col, Row} from "antd";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {archiveOrderRequest} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";


const ArchiveOrderRequestModalMessages = defineMessages({
  title: {
    id: "archiveOrderRequestModal.title",
    defaultMessage: "Respond archiving",
  },
  warn: {
    id: "archiveOrderRequestModal.warn",
    defaultMessage: "Are you sure you want to archive the respond? You won't be able to revert this action.",
  },
})

interface IArchiveOrderRequestModal {
  orderRequestId: number;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ArchiveOrderRequestModal: FC<IArchiveOrderRequestModal> = ({orderRequestId, isModalVisible, setIsModalVisible}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading: isArchiveLoading,
  } = useAppSelector(state => state.freighterRequests.archiveOrderRequest);

  return <CustomModal
    title={intl.formatMessage(ArchiveOrderRequestModalMessages.title)}
    visible={isModalVisible}
    onCancel={() => setIsModalVisible(false)}
    centered
    footer={null}
  >
    <Row gutter={8}>
      <Col span={24}>
        <div>{intl.formatMessage(ArchiveOrderRequestModalMessages.warn)}</div>
      </Col>
    </Row>

    <Row gutter={8} style={{marginTop: 24}}>
      <Col span={12}>
        <CustomButton
          style={{width: "100%"}}
          heightSize={'m'}
          onClick={() => setIsModalVisible(false)}
        >
          {intl.formatMessage(CommonButtonMessages.cancel)}
        </CustomButton>
      </Col>

      <Col span={12}>
        <CustomButton
          style={{width: "100%"}}
          heightSize={'m'}
          type={'primary'}
          icon={<DeleteOutlined />}
          danger
          loading={isArchiveLoading}
          onClick={() => dispatch(archiveOrderRequest({orderRequestId: orderRequestId}))}
        >
          {intl.formatMessage(CommonButtonMessages.archive)}
        </CustomButton>
      </Col>
    </Row>
  </CustomModal>
}