import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  IArchiveOrderRequestPayload,
  IChangeOrderRequestWithdrawPayload,
  IFreighterRequestsInitialState,
  IGetFreighterOrderRequestsPayload,
  IMakeCharterOfferPayload,
  ISetArchiveOrderRequestResultPayload,
  ISetChangeOrderRequestWithdrawResultPayload,
  ISetFreighterOrderRequestsResultPayload,
  ISetMakeCharterOfferResultPayload,
} from "./freighterRequestsReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {
  mapOrderRequestEntityToIFreighterOrderRequest,
} from "./freighterRequestsMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";
import {Logger} from "../../../utils/logger/Logger";
import {getErrorMessage} from "../../../utils/errorHelpers/getCommonErrorMessage";


const freighterRequestsInitialState: IFreighterRequestsInitialState = {
  itemsObject: {
    items: [],
    totalCount: 0,
    isLoading: true,
  },
  changeOrderRequestWithdraw: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  archiveOrderRequest: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  makeCharterOffer: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
}

const freighterRequestsSlice = createSlice({
    name: 'freighterRequests',
    initialState: freighterRequestsInitialState,
    reducers: {
      getFreighterOrderRequests(state, {payload}: PayloadAction<IGetFreighterOrderRequestsPayload>) {
        initCommonGetList(state, payload);
      },
      clearFreighterOrderRequests(state) {
        initCommonClearList(state);
      },
      setFreighterOrderRequestsResult(state, {payload}: PayloadAction<ISetFreighterOrderRequestsResultPayload>) {
        initCommonSetListResult(state, payload, mapOrderRequestEntityToIFreighterOrderRequest);
      },

      changeOrderRequestWithdraw(state, {payload}: PayloadAction<IChangeOrderRequestWithdrawPayload>) {
        initCommonAction(state, nameof<IFreighterRequestsInitialState>("changeOrderRequestWithdraw"));
      },
      clearChangeOrderRequestWithdraw(state) {
        initCommonClearAction(state, nameof<IFreighterRequestsInitialState>("changeOrderRequestWithdraw"));
      },
      setChangeOrderRequestWithdrawResult(state, {payload}: PayloadAction<ISetChangeOrderRequestWithdrawResultPayload>) {
        if (payload.item) {
          const updatedOrderRequest = payload.item;
          const updatedOrderRequestId = updatedOrderRequest.getOrderrequestid();
          const updatedOrderRequestStatus = updatedOrderRequest.getOrderrequeststatusid() as OrderRequestStatusEnum;
          state.itemsObject.items = state.itemsObject.items.map(or => or.id !== updatedOrderRequestId ? or : {
            ...or, orderRequestStatus: updatedOrderRequestStatus
          });
        }

        initCommonActionResult(state, payload, nameof<IFreighterRequestsInitialState>("changeOrderRequestWithdraw"));
      },

      archiveOrderRequest(state, {payload}: PayloadAction<IArchiveOrderRequestPayload>) {
        initCommonAction(state, nameof<IFreighterRequestsInitialState>("archiveOrderRequest"));
      },
      clearArchiveOrderRequest(state) {
        initCommonClearAction(state, nameof<IFreighterRequestsInitialState>("archiveOrderRequest"));
      },
      setArchiveOrderRequestResult(state, {payload}: PayloadAction<ISetArchiveOrderRequestResultPayload>) {
        state.itemsObject.items = state.itemsObject.items.filter(or => or.id !== payload.itemId);
        state.itemsObject.totalCount--;
        initCommonActionResult(state, payload, nameof<IFreighterRequestsInitialState>("archiveOrderRequest"));
      },

      makeCharterOffer(state, {payload}: PayloadAction<IMakeCharterOfferPayload>) {
        initCommonAction(state, nameof<IFreighterRequestsInitialState>("makeCharterOffer"));
      },
      clearMakeCharterOffer(state) {
        initCommonClearAction(state, nameof<IFreighterRequestsInitialState>("makeCharterOffer"));
      },
      setMakeCharterOfferResult(state, {payload}: PayloadAction<ISetMakeCharterOfferResultPayload>) {
        try {
          if (payload.item) {
            const updatedOrderRequest = payload.item;
            const updatedOrderRequestId = updatedOrderRequest.getOrderrequestid();
            state.itemsObject.items = state.itemsObject.items.map(or => {
              if (or.id !== updatedOrderRequestId)
                return or
              return mapOrderRequestEntityToIFreighterOrderRequest(updatedOrderRequest);
            });
          }

          initCommonActionResult(state, payload, nameof<IFreighterRequestsInitialState>("makeCharterOffer"));
        } catch (e) {
          Logger.error(getErrorMessage(e));
        }
      },
    }
  }
);

export const {
  getFreighterOrderRequests,
  clearFreighterOrderRequests,
  setFreighterOrderRequestsResult,

  changeOrderRequestWithdraw,
  clearChangeOrderRequestWithdraw,
  setChangeOrderRequestWithdrawResult,

  archiveOrderRequest,
  clearArchiveOrderRequest,
  setArchiveOrderRequestResult,

  makeCharterOffer,
  clearMakeCharterOffer,
  setMakeCharterOfferResult,
} =
  freighterRequestsSlice.actions;
export default freighterRequestsSlice.reducer;