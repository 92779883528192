import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {groupAll} from "../../../roles/groups";
import {PricePage} from "../../../../../pages/General/Price/PricePage";
import {defineMessage} from "react-intl";

const PriceRouteTitleMessage = defineMessage({
  id: "priceRoute.title",
  defaultMessage: "Subscriptions",
})

export const price: RouteDescriptor<Route.price> = {
  route: Route.price,
  render: PricePage,
  title: PriceRouteTitleMessage,
  path: '/price',
  allowRoles: [
    ...groupAll,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: false,
}