import React from 'react';
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import styled from "styled-components";
import {ProfileUser} from "./components/ProfileUser/ProfileUser";
import {ProfileCompany} from "./components/ProfileCompany/ProfileCompany";
import {ProfilePersonnel} from "./components/ProfilePersonnel/ProfilePersonnel";
import {useAppSelector} from "../../../../store/store";
import {groupUserAdmin} from "../../../../config/acl/roles/groups";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

const CompanyAndUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: 95%;

  @media(max-width: ${mediaScreenSizes.tablet}) {
    width: 100%;
    flex-direction: row;
    column-gap: 16px;
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const ProfilePage = () => {
  const {role} = useAppSelector(state => state.user.oneItemObject.item);

  return (
    <PageWrapper>
      <CompanyAndUserWrapper>
        <ProfileCompany />
        <ProfileUser />
      </CompanyAndUserWrapper>
      {groupUserAdmin.includes(role) && <ProfilePersonnel />}
    </PageWrapper>
  );
};

