import {
  FlightDrawerFooterContent, FlightDrawerFooterContentWrapper,
  FlightDrawerFooterPrice, FlightDrawerFooterPriceLabel, FlightDrawerFooterPriceValue, FlightDrawerFooterStatus
} from "../../flightDrawer/footers/FlightDrawerFooterStyledComponents";
import React, {FC} from "react";
import {
  useOrderRequestStatusName,
} from "../../../../utils/constans/statuses/orderRequestStatuses";
import {defineMessages, useIntl} from "react-intl";
import {
  IFreighterOrderRequest
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {ReactComponent as TrashIcon} from "../../../../media/icons/delete.svg";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";
import {DisplayFlyPrice} from "../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";

const BrokerDrawerOrderRequestFooterMessages = defineMessages({
  price: {
    id: "brokerDrawerOrderRequestFooter.price",
    defaultMessage: "Price",
  },
  makeOffer: {
    id: "brokerDrawerOrderRequestFooter.makeOffer",
    defaultMessage: "Make offer",
  }
})

interface IBrokerDrawerOrderRequestFooter {
  orderRequest: IFreighterOrderRequest;
  closeBrokerModal: () => void;
  openMakeOffer: () => void;
  openArchive: () => void;
}

export const BrokerDrawerOrderRequestFooter: FC<IBrokerDrawerOrderRequestFooter> = ({
                                                                                      orderRequest,
                                                                                      closeBrokerModal,
                                                                                      openMakeOffer,
                                                                                      openArchive
                                                                                    }) => {
  const intl = useIntl();
  const orderRequestStatusName = useOrderRequestStatusName(orderRequest.status);

  const onAction = (action: () => void) => {
    action();
    closeBrokerModal();
  }

  const footerContent = orderRequest.isPlaneOffered
    ? <>
      <FlightDrawerFooterPrice>
        <FlightDrawerFooterPriceLabel>{intl.formatMessage(BrokerDrawerOrderRequestFooterMessages.price)}</FlightDrawerFooterPriceLabel>
        {orderRequest.price && orderRequest.currency &&
          <FlightDrawerFooterPriceValue>{DisplayFlyPrice(orderRequest.price, orderRequest.currency)}</FlightDrawerFooterPriceValue>}
      </FlightDrawerFooterPrice>

      <FlightDrawerFooterStatus status={orderRequest.status}>
        {orderRequestStatusName}
      </FlightDrawerFooterStatus>
    </>
    : <>
      <CustomButton icon={<TrashIcon/>}
                    onClick={() => onAction(openArchive)}>{intl.formatMessage(CommonButtonMessages.archive)}</CustomButton>
      <CustomButton type={'primary'} onClick={() => onAction(openMakeOffer)}
                    style={{flexGrow: 1}}>{intl.formatMessage(BrokerDrawerOrderRequestFooterMessages.makeOffer)}</CustomButton>
    </>

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      {footerContent}
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
}