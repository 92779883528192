import {INotification, NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {Logger} from "../../../utils/logger/Logger";
import {DisplayNotificationRoute} from "../../../utils/helpers/textDisplayHelpers/DisplayAirport";

export const getNotificationMetadata = (notification: INotification): string => {
  if ([
    NotificationType.TYPE_EMPTY_LEG_ARCHIVED_EXPIRED,
    NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED,
    NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED,
    NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED,
    NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN,
    NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN,
    NotificationType.TYPE_EMPTY_LEG_UPDATED,

    NotificationType.TYPE_ORDER_ROUTE_ARCHIVED,
    NotificationType.TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED,

    NotificationType.TYPE_ORDER_REQUEST_CREATED,
    NotificationType.TYPE_ORDER_REQUEST_ARCHIVED,
    NotificationType.TYPE_ORDER_REQUEST_EXPIRED,
    NotificationType.TYPE_ORDER_REQUEST_OFFERED,
  ].includes(notification.type)) {
    const airports = notification.metadata.airports;

    if (airports) {
      return DisplayNotificationRoute(airports);
    } else {
      Logger.error("No metadata matches given notification type was found")
      return "";
    }
  }

  return "";
}