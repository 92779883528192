import {ReactComponent as NavProfileIcon} from "../../../media/icons/nav_profile.svg"
import {ReactComponent as NavClientsIcon} from "../../../media/icons/nav_clients.svg"
import {ReactComponent as NavEmptyLegsIcon} from "../../../media/icons/nav_empty_legs.svg"
import {ReactComponent as NavFindRequestIcon} from "../../../media/icons/nav_find_request.svg"
import {ReactComponent as NavMyRequestsIcon} from "../../../media/icons/nav_my_requests.svg"
import {ReactComponent as NavMyRespondsIcon} from "../../../media/icons/nav_my_responds.svg"
import {ReactComponent as NavFleetIcon} from "../../../media/icons/nav_fleet.svg"
import {ReactComponent as NavCreateRequestIcon} from "../../../media/icons/nav_create_request.svg"
import {ReactNode} from "react";
import {newTripRequest} from "./lk/lkCharterer/newTripRequest";
import {requests} from "./lk/lkCharterer/requests";
import {requestsFromClients} from "./lk/lkCharterer/requestsFromClients";
import {requestsFromAgents} from "./lk/lkFreighter/requestsFromAgents";
import {emptyLegs} from "./lk/lkFreighter/emptyLegs";
import {profile} from "./lk/lkCommon/profile";
import {searchEmptyLegs} from "./lk/lkCharterer/searchEmptyLegs";
import {defineMessages} from "react-intl";

export const PageGroupMessages = defineMessages({
  fleet: {
    id: "pageGroup.fleet.title",
    defaultMessage: "My aircraft fleet",
  },
})

export enum PageGroups {
  FLEET,
}

export const PageGroupIconByPageGroup = new Map<PageGroups, ReactNode>()
  .set(PageGroups.FLEET, <NavFleetIcon />)

export const PageIconByPagePath = new Map<string, ReactNode>()
  .set(newTripRequest.path, <NavCreateRequestIcon />)
  .set(requests.path, <NavMyRequestsIcon />)
  .set(requestsFromClients.path, <NavClientsIcon />)
  // .set(tripBoard.path, <NavFindRequestIcon />)
  .set(emptyLegs.path, <NavEmptyLegsIcon />)
  .set(requestsFromAgents.path, <NavMyRespondsIcon />)
  .set(profile.path, <NavProfileIcon />)
  .set(searchEmptyLegs.path, <NavEmptyLegsIcon />);