import {defineMessages} from "react-intl";

export const CommonFormMessages = defineMessages({
  searchPlaceholder: {
    id: "app.form.search.placeholder",
    defaultMessage: "Search",
  },
  noData: {
    id: "app.form.search.noData",
    defaultMessage: "No requests",
  },
  showMore: {
    id: "app.form.search.showMore",
    defaultMessage: "Show more",
  },
  incorrect: {
    id: "app.form.incorrect",
    defaultMessage: "Form is filled out incorrectly",
  },
  passwordsMismatch: {
    id: "app.form.passwordsMismatch",
    defaultMessage: "Passwords don't match",
  },
  pdfInsuranceNotLoaded: {
    id: "app.form.pdfInsuranceNotLoaded",
    defaultMessage: "Please, upload the aircraft, crew, and third parties insurance document as a PDF file.",
  },
  pdfRegistrationCertificateNotLoaded: {
    id: "app.form.pdfRegistrationCertificateNotLoaded",
    defaultMessage: "Please, upload the aircraft registration certificate as a PDF file.",
  },
  showAll: {
    id: "app.form.showAll",
    defaultMessage: "Show all",
  },

  //FILE UPLOAD
  planeInsuranceFile: {
    id: "app.form.file.planeInsurance",
    defaultMessage: "Aircraft, crew, and third parties insurance",
  },
  planeRegistrationCertificateFile: {
    id: "app.form.file.planeRegistrationCertificate",
    defaultMessage: "Aircraft registration certificate",
  },

  //RADIO BUTTONS
  eventTypeOrderRequestRadio: {
    id: "app.form.radio.eventTypeOrderRequest",
    defaultMessage: "Charter",
  },
  eventTypeEmptyLegRadio: {
    id: "app.form.radio.eventTypeEmptyLeg",
    defaultMessage: "Empty Leg",
  },
  eventTypeCustomRadio: {
    id: "app.form.radio.eventTypeCustom",
    defaultMessage: "Other",
  },

  //LABELS
  companyLabel: {
    id: "app.form.labels.company",
    defaultMessage: "Company",
  },
  companyDescriptionLabel: {
    id: "app.form.labels.companyDescription",
    defaultMessage: "About",
  },
  companyAddressLabel: {
    id: "app.form.labels.companyAddress",
    defaultMessage: "Company address",
  },
  companyWebsiteLabel: {
    id: "app.form.labels.companyWebsite",
    defaultMessage: "Company website",
  },
  nameLabel: {
    id: "app.form.labels.name",
    defaultMessage: "Name",
  },
  lastNameLabel: {
    id: "app.form.labels.lastName",
    defaultMessage: "Last name",
  },
  middleNameLabel: {
    id: "app.form.labels.middleName",
    defaultMessage: "Middle name",
  },
  emailLabel: {
    id: "app.form.labels.email",
    defaultMessage: "Email",
  },
  phoneLabel: {
    id: "app.form.labels.phone",
    defaultMessage: "Phone",
  },
  commentLabel: {
    id: "app.form.labels.comment",
    defaultMessage: "Comment",
  },
  passwordLabel: {
    id: "app.form.labels.password",
    defaultMessage: "Password",
  },
  repeatPasswordLabel: {
    id: "app.form.labels.repeatPassword",
    defaultMessage: "Repeat new password",
  },
  oldPasswordLabel: {
    id: "app.form.labels.oldPassword",
    defaultMessage: "Current password",
  },
  newPasswordLabel: {
    id: "app.form.labels.newPassword",
    defaultMessage: "New password",
  },
  roleLabel: {
    id: "app.form.labels.role",
    defaultMessage: "Role",
  },
  baseAirportLabel: {
    id: "app.form.labels.baseAirport",
    defaultMessage: "Base airport",
  },
  stayAirportLabel: {
    id: "app.form.labels.stayAirport",
    defaultMessage: "Airport of stay",
  },
  fromAirportLabel: {
    id: "app.form.labels.fromAirport",
    defaultMessage: "From",
  },
  toAirportLabel: {
    id: "app.form.labels.toAirport",
    defaultMessage: "To",
  },
  dateLabel: {
    id: "app.form.labels.date",
    defaultMessage: "Date",
  },
  dateThereLabel: {
    id: "app.form.labels.dateThere",
    defaultMessage: "Date there",
  },
  dateBackLabel: {
    id: "app.form.labels.dateBack",
    defaultMessage: "Date back",
  },
  dateRangeLabel: {
    id: "app.form.labels.dateRange",
    defaultMessage: "Date range",
  },
  departureDateLabel: {
    id: "app.form.labels.departureDate",
    defaultMessage: "Departure date",
  },
  availableDatesLabel: {
    id: "app.form.labels.availableDates",
    defaultMessage: "Available",
  },
  paxLabel: {
    id: "app.form.labels.pax",
    defaultMessage: "PAX",
  },
  paxThereLabel: {
    id: "app.form.labels.paxThere",
    defaultMessage: "PAX there",
  },
  paxBackLabel: {
    id: "app.form.labels.paxBack",
    defaultMessage: "PAX back",
  },
  minPaxLabel: {
    id: "app.form.labels.minPax",
    defaultMessage: "Min. PAX",
  },
  maxPaxLabel: {
    id: "app.form.labels.maxPax",
    defaultMessage: "Max. PAX",
  },
  planeLabel: {
    id: "app.form.labels.plane",
    defaultMessage: "Model",
  },
  planeTypeLabel: {
    id: "app.form.labels.planeType",
    defaultMessage: "Aircraft type",
  },
  planeCategoryLabel: {
    id: "app.form.labels.planeCategory",
    defaultMessage: "Aircraft category",
  },
  registrationNumberLabel: {
    id: "app.form.labels.registrationNumber",
    defaultMessage: "Registration No.",
  },
  manufactureDateLabel: {
    id: "app.form.labels.manufactureDate",
    defaultMessage: "Manufactured",
  },
  refurbishDateLabel: {
    id: "app.form.labels.refurbishDate",
    defaultMessage: "Refurbished",
  },
  welcomeMessageLabel: {
    id: "app.form.labels.welcomeMessage",
    defaultMessage: "Welcome message",
  },
  planeIsNotOlderDateLabel: {
    id: "app.form.labels.planeIsNotOlderDate",
    defaultMessage: "Manufactured after",
  },
  planeDescriptionLabel: {
    id: "app.form.labels.planeDescription",
    defaultMessage: "Additional information for broker",
  },
  capacityLabel: {
    id: "app.form.labels.capacity",
    defaultMessage: "Seat capacity",
  },
  distanceLabel: {
    id: "app.form.labels.distance",
    defaultMessage: "Range, km",
  },
  flyHoursLabel: {
    id: "app.form.labels.flyHours",
    defaultMessage: "Flight, h",
  },
  flyMinutesLabel: {
    id: "app.form.labels.flyMinutes",
    defaultMessage: "Flight, min",
  },
  priceLabel: {
    id: "app.form.labels.price",
    defaultMessage: "Price",
  },
  currencyLabel: {
    id: "app.form.labels.currency",
    defaultMessage: "Currency",
  },
  flyHourPriceLabel: {
    id: "app.form.labels.flyHourPrice",
    defaultMessage: "Flight hour cost",
  },
  eventTypeLabel: {
    id: "app.form.labels.eventType",
    defaultMessage: "Choose event type",
  },
  eventNameLabel: {
    id: "app.form.labels.eventName",
    defaultMessage: "Event name",
  },
  timezoneLabel: {
    id: "app.form.labels.timezonePlaceholder",
    defaultMessage: "Timezone",
  },
  calcFlyHoursLabel: {
    id: "app.form.labels.calcFlyHours",
    defaultMessage: "Aircraft year schedule",
  },
  hoursLabel: {
    id: "app.form.labels.hours",
    defaultMessage: "Hours",
  },
  minutesLabel: {
    id: "app.form.labels.minutes",
    defaultMessage: "Minutes",
  },

  //PLACEHOLDERS
  commonPlaceholder: {
    id: "app.form.placeholders.common",
    defaultMessage: "Enter value",
  },
  allPlaceholder: {
    id: "app.form.placeholders.all",
    defaultMessage: "All",
  },
  companyPlaceholder: {
    id: "app.form.placeholders.company",
    defaultMessage: "Company name",
  },
  companyDescriptionPlaceholder: {
    id: "app.form.placeholders.companyDescription",
    defaultMessage: "Add description",
  },
  companyAddressPlaceholder: {
    id: "app.form.placeholders.companyAddress",
    defaultMessage: "Company address",
  },
  companyWebsitePlaceholder: {
    id: "app.form.placeholders.companyWebsite",
    defaultMessage: "Company website address",
  },
  namePlaceholder: {
    id: "app.form.placeholders.name",
    defaultMessage: "Name",
  },
  lastNamePlaceholder: {
    id: "app.form.placeholders.lastName",
    defaultMessage: "Last name",
  },
  middleNamePlaceholder: {
    id: "app.form.placeholders.middleName",
    defaultMessage: "Middle name",
  },
  emailPlaceholder: {
    id: "app.form.placeholders.email",
    defaultMessage: "example@example.com",
  },
  phonePlaceholder: {
    id: "app.form.placeholders.phone",
    defaultMessage: "Enter phone number",
  },
  commentPlaceholder: {
    id: "app.form.placeholders.comment",
    defaultMessage: "Comment",
  },
  passwordPlaceholder: {
    id: "app.form.placeholders.password",
    defaultMessage: "Enter password",
  },
  repeatPasswordPlaceholder: {
    id: "app.form.placeholders.repeatPassword",
    defaultMessage: "Repeat new password",
  },
  oldPasswordPlaceholder: {
    id: "app.form.placeholders.oldPassword",
    defaultMessage: "Current password",
  },
  newPasswordPlaceholder: {
    id: "app.form.placeholders.newPassword",
    defaultMessage: "New password",
  },
  baseAirportPlaceholder: {
    id: "app.form.placeholders.baseAirport",
    defaultMessage: "Choose airport",
  },
  stayAirportPlaceholder: {
    id: "app.form.placeholders.stayAirport",
    defaultMessage: "Choose airport",
  },
  fromAirportPlaceholder: {
    id: "app.form.placeholders.fromAirport",
    defaultMessage: "Choose airport",
  },
  toAirportPlaceholder: {
    id: "app.form.placeholders.toAirport",
    defaultMessage: "Choose airport",
  },
  datePlaceholder: {
    id: "app.form.placeholders.date",
    defaultMessage: "Choose date",
  },
  departureDatePlaceholder: {
    id: "app.form.placeholders.departureDate",
    defaultMessage: "Choose date",
  },
  paxPlaceholder: {
    id: "app.form.placeholders.pax",
    defaultMessage: "Enter number",
  },
  planePlaceholder: {
    id: "app.form.placeholders.plane",
    defaultMessage: "Choose aircraft model",
  },
  planeTypePlaceholder: {
    id: "app.form.placeholders.planeType",
    defaultMessage: "Choose...",
  },
  planeCategoryPlaceholder: {
    id: "app.form.placeholders.planeCategory",
    defaultMessage: "Choose...",
  },
  registrationNumberPlaceholder: {
    id: "app.form.placeholders.registrationNumber",
    defaultMessage: "LY-BGH",
  },
  manufactureDatePlaceholder: {
    id: "app.form.placeholders.manufactureDate",
    defaultMessage: "Specify year",
  },
  refurbishDatePlaceholder: {
    id: "app.form.placeholders.refurbishDate",
    defaultMessage: "Year",
  },
  welcomeMessagePlaceholder: {
    id: "app.form.placeholders.welcomeMessage",
    defaultMessage: "The welcome message will be sent to the chat automatically",
  },
  planeIsNotOlderDatePlaceholder: {
    id: "app.form.placeholders.planeIsNotOlderDate",
    defaultMessage: "Year",
  },
  planeDescriptionPlaceholder: {
    id: "app.form.placeholders.planeDescription",
    defaultMessage: "Describe the advantages and facilities of your aircraft",
  },
  capacityPlaceholder: {
    id: "app.form.placeholders.capacity",
    defaultMessage: "0",
  },
  distancePlaceholder: {
    id: "app.form.placeholders.distance",
    defaultMessage: "0",
  },
  pricePlaceholder: {
    id: "app.form.placeholders.price",
    defaultMessage: "Add price",
  },
  flyHourPricePlaceholder: {
    id: "app.form.placeholders.flyHourPrice",
    defaultMessage: "Enter value",
  },
  currencyPlaceholder: {
    id: "app.form.placeholders.currency",
    defaultMessage: "Select currency",
  },
  eventNamePlaceholder: {
    id: "app.form.placeholders.eventType",
    defaultMessage: "Name",
  },
  timezonePlaceholder: {
    id: "app.form.placeholders.timezonePlaceholder",
    defaultMessage: "Choose timezone",
  },

  //CHECKBOXES
  chooseDateRangeCheckbox: {
    id: "app.form.checkboxes.chooseDateRange",
    defaultMessage: "Date range",
  },
  ambulanceCheckbox: {
    id: "app.form.checkboxes.ambulance",
    defaultMessage: "Air ambulance",
  },
  animalsCheckbox: {
    id: "app.form.checkboxes.animals",
    defaultMessage: "Animals aboard",
  },
  smokingCheckbox: {
    id: "app.form.checkboxes.smoking",
    defaultMessage: "Smoking aboard",
  },
  cargoCheckbox: {
    id: "app.form.checkboxes.cargo",
    defaultMessage: "Air cargo freight",
  },

  // RULES
  ruleRequired: {
    id: "app.form.rules.required",
    defaultMessage: "Required field",
  },
  ruleIncorrectEmail: {
    id: "app.form.rules.incorrectEmail",
    defaultMessage: "Incorrect email",
  },
  ruleIncorrectPhone: {
    id: "app.form.rules.incorrectPhone",
    defaultMessage: "10 to 12 digits without spaces",
  },
  ruleIncorrectNumber: {
    id: "app.form.rules.incorrectNumber",
    defaultMessage: "Number",
  },
  ruleMinPax: {
    id: "app.form.rules.minPax",
    defaultMessage: "Minimum 1 passenger",
  },
  ruleMaxPax: {
    id: "app.form.rules.maxPax",
    defaultMessage: "Maximum 100 passengers",
  },
  rulePasswordValidationTitle: {
    id: "app.form.rules.passwordValidation.title",
    defaultMessage: "Password requirements:",
  },
  rulePasswordValidationRule1: {
    id: "app.form.rules.passwordValidation.rule1",
    defaultMessage: "The password must contain minimum 8 symbols",
  },
  rulePasswordValidationRule2: {
    id: "app.form.rules.passwordValidation.rule2",
    defaultMessage: "The password must contain at least one upper-case letter",
  },
  rulePasswordValidationRule3: {
    id: "app.form.rules.passwordValidation.rule3",
    defaultMessage: "The password must contain at least one lower-case letter",
  },
  ruleMinimumZero: {
    id: "app.form.rules.minimumZero",
    defaultMessage: "Minimum 0",
  },
  ruleMustBePositive: {
    id: "app.form.rules.mustBePositive",
    defaultMessage: "Value must be a positive number",
  },
  ruleHugeNumber: {
    id: "app.form.rules.hugeNumber",
    defaultMessage: "Incorrect value",
  },
  ruleIncorrectFlightTime: {
    id: "app.form.rules.ruleIncorrectFlightTime",
    defaultMessage: "Incorrect flight time",
  }
})