import React, {useEffect, useState} from 'react';
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import Button from "antd/es/button/button";
import {PlusOutlined} from "@ant-design/icons";
import {PlaneList} from "./components/PlaneList";
import {PlaneCreateDrawer} from "./components/PlaneCreateDrawer";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {clearPlaneList, getPlaneList} from "../../../../store/reducers/fleetReducer/fleetReducer";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import Search from "antd/es/input/Search";
import {Col, Row} from "antd";
import {Loader} from "../../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../../components/UI/AntCustom/CustomResult";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {useIsMobile} from "../../../../hooks/useWindowSize";
import {CustomFieldInput} from "../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomSearch} from "../../../../components/UI/AntCustom/CustomSearch";


const FleetPageMessages = defineMessages({
  title: {
    id: "fleetPage.title",
    defaultMessage: "Aircraft fleet",
  },
  search: {
    id: "fleetPage.search",
    defaultMessage: "Search by an aircraft registration number",
  },
  addPlane: {
    id: "fleetPage.addPlane",
    defaultMessage: "Add aircraft",
  },
  noPlanes: {
    id: "fleetPage.noPlanes",
    defaultMessage: "No aircraft",
  },
})

export const FleetPage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {items, isLoading, totalCount} = useAppSelector(state => state.fleet.itemsObject);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    dispatch(getPlaneList({
      query: searchQuery,
      page: currentPage
    }))

    return () => {
      dispatch(clearPlaneList());
    }
  }, [])

  const loadMore = () => {
    dispatch(getPlaneList({
      query: searchQuery,
      page: currentPage + 1,
    }));

    setCurrentPage(prev => prev + 1);
  }

  const search = () => {
    dispatch(getPlaneList({
      query: searchQuery,
      page: InitialPage,
    }));

    setCurrentPage(InitialPage);
  }

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(FleetPageMessages.title)} width={'max'} isContainerCard={true}
                  extra={isMobile && <CustomButton type={'primary'} heightSize={'s'}
                                                   onClick={() => setIsCreateOpen(true)}>+{intl.formatMessage(FleetPageMessages.addPlane)}
                  </CustomButton>}>
        <Row gutter={8} align={"middle"}>
          <Col flex={1}>
            <CustomSearch placeholder={intl.formatMessage(FleetPageMessages.search)}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onSearch={search}/>
          </Col>
          {!isMobile && <Col>
            <CustomButton type={'primary'} heightSize={'l'}
                          onClick={() => setIsCreateOpen(true)}>+{intl.formatMessage(FleetPageMessages.addPlane)}
            </CustomButton>
          </Col>}
        </Row>
        {items.length === 0
          ? (isLoading ? <Loader/> : <CustomResult title={intl.formatMessage(FleetPageMessages.noPlanes)}/>)
          : <PlaneList/>
        }

        {items.length > 0 && totalCount > items.length &&
          <CustomButton
            loading={isLoading}
            type={'primary'}
            onClick={loadMore}
          >
            {intl.formatMessage(CommonFormMessages.showMore)}
          </CustomButton>}
      </CustomCard>

      {isCreateOpen && <PlaneCreateDrawer isOpen={isCreateOpen} setIsOpen={setIsCreateOpen}/>}
    </PageWrapper>
  );
};
