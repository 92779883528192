import {defineMessages, useIntl} from "react-intl";

const DisplayYesOrNoMessages = defineMessages({
  yes: {
    id: "displayHelpers.displayYesOrNo.yes",
    defaultMessage: "Yes"
  },
  no: {
    id: "displayHelpers.displayYesOrNo.no",
    defaultMessage: "No"
  },
})

export const DisplayYesOrNo = (answer: boolean) => {
  const intl = useIntl();

  return answer
    ? intl.formatMessage(DisplayYesOrNoMessages.yes)
    : intl.formatMessage(DisplayYesOrNoMessages.no);
}