import {IPlaneCategory} from "../../../store/reducers/fleetReducer/fleetReducerTypes";
import {defineMessages, useIntl} from "react-intl";

const DisplayPlaneCategoriesMessages = defineMessages({
  notIndicated: {
    id: "displayHelpers.displayPlaneCategories.notIndicated",
    defaultMessage: "Not specified"
  },
})


export const DisplayPlaneCategories = (planeCategories: IPlaneCategory[]): string => {
  const intl = useIntl();

  if (planeCategories.length === 0)
    return intl.formatMessage(DisplayPlaneCategoriesMessages.notIndicated);

  return planeCategories.map(category => category.name).join(', ')
}