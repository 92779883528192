import {CommunicationWebsocketMessage, WebsocketMessageType} from "../proto/generated/api_entities_pb";
import {Logger} from "../utils/logger/Logger";
import {handleNotificationPush} from "./handleNotificationPush";
import {websocketUrl} from "./websocketUrl";


type SocketMessage = { id: number, type: WebsocketMessageType, bytes: Uint8Array }


const LOG_CORE_MESSAGES = false;


let queueOfMessages: SocketMessage[] = [];
let onFlyMessages: SocketMessage[] = [];

let connected = false;
let socket: WebSocket;
let pingTimeoutQueue: NodeJS.Timeout[] = [];


const sendQueue = () => {
  let message: SocketMessage | undefined;

  while (message = queueOfMessages.shift()) {
    socket.readyState === WebSocket.OPEN && socket.send(message.bytes);
    onFlyMessages.push(message);
  }

}


const onopen = () => {
  Logger.info('WEBSOCKET CONNECTED', 'lime')
  connected = true;
  sendQueue();
};


const onclose = () => {
  Logger.error('WEBSOCKET DISCONNECTED')
  connected = false;
  queueOfMessages = onFlyMessages;
  onFlyMessages = [];

  setTimeout(() => {
    connect();
  }, 3000);
}


export const disconnect = () => {
  pingTimeoutQueue = [];

  if (socket) {
    socket.onopen = null;
    socket.onclose = () => {
      Logger.error('WS DISCONNECTED. REASON: user is not authorized')
    };
    socket.onmessage = null;
    socket.close();
  }
}


const onmessage = (e: MessageEvent) => {
  try {
    const coreMessage = CommunicationWebsocketMessage.deserializeBinary(new Uint8Array(e.data));
    const m = onFlyMessages.find(fly => fly.id === coreMessage.getMessageid());

    if (m) {
      onFlyMessages = onFlyMessages.filter(fly => fly.id !== coreMessage.getMessageid());
    } else {
      if (coreMessage.getMessagetype() === WebsocketMessageType.PING_MESSAGE)
        sendCoreMessage(WebsocketMessageType.PONG_MESSAGE, undefined, coreMessage.getMessageid());
      else if (coreMessage.getMessagetype() === WebsocketMessageType.PUSH_NOTIFICATION_MESSAGE)
        handleNotificationPush(coreMessage);
    }
  } catch (err) {
    LOG_CORE_MESSAGES && console.error('[socket] << decode error', e.data, err);
  }
}


export const connect = () => {
  socket = new WebSocket(websocketUrl);
  socket.binaryType = 'arraybuffer';
  socket.onopen = onopen;
  socket.onclose = onclose;
  socket.onmessage = onmessage;
};


const series = ((i = -1) => () => (i--))();


export const sendCoreMessage = (type: WebsocketMessageType, data?: Uint8Array | undefined, forceId?: number): void => {
  const id = forceId ?? series();
  const accessToken = localStorage.getItem('accessToken') ?? "";

  const coreMessage = new CommunicationWebsocketMessage()
    .setMessageid(id)
    .setMessagetype(type)
    .setAccesstoken(accessToken)

  if (data)
    coreMessage.setData(data);

  queueOfMessages.push({
    id,
    type,
    bytes: coreMessage.serializeBinary(),
  });

  while (queueOfMessages.length > 32)
    queueOfMessages.shift();

  if (connected)
    sendQueue();
};


export const sendPing = () => {
  sendCoreMessage(WebsocketMessageType.PING_MESSAGE);

  const timeout = setTimeout(() => {
    sendPing();
    pingTimeoutQueue.shift()
  }, 5000);

  pingTimeoutQueue.push(timeout)
}
