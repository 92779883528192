import styled from "styled-components";
import {Select} from "antd";

export const CustomSelect = styled(Select)`
  height: 34px;
  border-radius: 5px !important;
  
  .ant-select-selector {
    border-radius: 5px !important;
  }
  
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;