import React, {FC} from 'react';
import {UploadFile} from "antd/es/upload";
import planeDefaultImageUrl from "../../../media/images/no_photo.jpg"
import styled from "styled-components";

const ImageStyled = styled.img<{width?: string, height?: string}>`
  width: ${props => props.width ? `${props.width}` : "157px"};
  height: ${props => props.height ? `${props.height}` : "122px"};
  object-fit: cover;
`;

interface IPlaneImage {
  files: UploadFile[];
  width?: string;
  height?: string;
}

export const PlaneImage: FC<IPlaneImage> = ({files, width, height}) => {
  return (
    <ImageStyled src={files.length > 0 ? files[0].url : planeDefaultImageUrl} alt={"Plane image"} width={width} height={height} />
  );
};
