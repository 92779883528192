import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {IOrderRequestFilters} from "../RequestFromAgentsPage";
import {Checkbox, Col, Form, Row} from "antd";
import {
  fetchAirports,
  fetchInitialAirportForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {DeleteOutlined} from "@ant-design/icons";
import {fetchPlaneCategories} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneCategories";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {paxRulesNotRequired} from "../../../../../utils/rules/paxRules";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const OrderRequestsFilterModalMessages = defineMessages({
  title: {
    id: "orderRequestsFilterModal.title",
    defaultMessage: "Filters",
  },
})

interface IOrderRequestsFilterModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  filters: IOrderRequestFilters;
  setFilters: Dispatch<SetStateAction<IOrderRequestFilters>>;
}

export const OrderRequestsFilterModal: FC<IOrderRequestsFilterModal> = ({
                                                                          isModalVisible,
                                                                          setIsModalVisible,
                                                                          filters,
                                                                          setFilters
                                                                        }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const isResetDisabled = useMemo(() => !Object.values(filters).some(v => v !== undefined), [filters]);
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(!Object.values(filters).some(v => v !== undefined));

  const onFinish = (values: IOrderRequestFilters) => {
    setFilters({...values});
    setIsModalVisible(false);
  }

  const onResetFilters = () => {
    setFilters({});
    setIsModalVisible(false);
  }

  const onValuesChange = () => {
    const fields = form.getFieldsValue();
    setIsApplyDisabled(!Object.values(fields).some(v => v !== undefined));
  }

  const initialValues: IOrderRequestFilters = {
    airportFromId: filters.airportFromId,
    airportToId: filters.airportToId,
    minPax: filters.minPax,
    maxPax: filters.maxPax,
    companionsAllowed: filters.companionsAllowed,
    isEmptyLegUsable: filters.isEmptyLegUsable,
    planeCategoryIds: filters.planeCategoryIds,
  }

  return (
    <CustomModal
      title={intl.formatMessage(OrderRequestsFilterModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
            name={'airportFromId'}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
              showSearch={true}
              defaultOptionsFetchCallback={() => fetchInitialAirportForSelect(filters.airportFromId)}
            />
          </CustomFieldInput>
        </Col>
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
            name={'airportToId'}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
              showSearch={true}
              defaultOptionsFetchCallback={() => fetchInitialAirportForSelect(filters.airportToId)}
            />
          </CustomFieldInput>
        </Col>
        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.minPaxLabel)}
              name={'minPax'}
              rules={paxRulesNotRequired}
            >
              <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                 style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.maxPaxLabel)}
              name={'maxPax'}
              rules={paxRulesNotRequired}
            >
              <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                 style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col>
            <CustomFieldInput
              name={'companionsAllowed'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonDetailsMessages.companionsAllowed)}</Checkbox>
            </CustomFieldInput>
          </Col>
          <Col>
            <CustomFieldInput
              name={'isEmptyLegUsable'}
              valuePropName={'checked'}
            >
              <Checkbox>{intl.formatMessage(CommonDetailsMessages.isEmptyLegUsable)}</Checkbox>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.planeCategoryLabel)}
              name={'planeCategoryIds'}
            >
              <DebounceSelect
                fetchOptions={fetchPlaneCategories}
                placeholder={intl.formatMessage(CommonFormMessages.planeCategoryPlaceholder)}
                mode={'multiple'}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            disabled={isApplyDisabled}
          >
            {intl.formatMessage(CommonButtonMessages.apply)}
          </CustomButton>
        </CustomFieldInput>

        {!isResetDisabled && <CustomButton block danger onClick={onResetFilters} icon={<DeleteOutlined/>}>
          {intl.formatMessage(CommonButtonMessages.resetFilters)}
        </CustomButton>}
      </Form>
    </CustomModal>
  );
};
