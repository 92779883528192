import React, {Dispatch, FC, SetStateAction} from "react";
import {Switch, SwitchProps} from "antd";
import styled from "styled-components";


const CalculateSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const SwitcherLabel = styled.div<{ isDisabled?: boolean; }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  opacity: ${props => props.isDisabled ? 0.3 : 1};
`;

interface ICalculateSwitcher extends SwitchProps {
  onClick: () => void
  isAutoCalculationApplied: boolean;
  setIsAutoCalculationApplied: Dispatch<SetStateAction<boolean>>;
}

export const CalculateSwitcher: FC<ICalculateSwitcher> = ({
                                                            onClick,
                                                            isAutoCalculationApplied,
                                                            setIsAutoCalculationApplied,
                                                            ...props
                                                          }) => {
  return (
    <CalculateSwitcherWrapper>
      <SwitcherLabel isDisabled={props.disabled}>Auto</SwitcherLabel>
      <Switch title={"Auto"} checked={isAutoCalculationApplied} onClick={async () => {
        await onClick();
      }} {...props} />
    </CalculateSwitcherWrapper>
  );
};