import {all, call, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  ResultResponse, TimezoneEntity,
  UserAccountApiChangePasswordRequest,
  UserAccountApiUserResponse,
  UserAccountApiUserUpdateRequest
} from "../../../proto/generated/api_entities_pb";
import {backendIdentifierFromRole} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {sha256} from "js-sha256";
import {
  changePassword,
  editUser,
  setChangePasswordResult,
  setEditUserResult
} from "../../reducers/userReducer/userReducer";
import {IChangePasswordPayload, IEditUserPayload} from "../../reducers/userReducer/userReducerTypes";


function* editUserSaga({payload}: PayloadAction<IEditUserPayload>) {
  const req = new UserAccountApiUserUpdateRequest()
    .setUserid(payload.userId)
    .setRole(backendIdentifierFromRole(payload.role))
    .setFirstname(payload.firstName)
    .setLastname(payload.lastName)
    .setPhone(payload.phone)
    .setTimezone(new TimezoneEntity()
      .setTimezoneid(payload.timezoneId)
    )

  if (payload.middleName)
    req.setMiddlename(payload.middleName);

  try {
    const res: UserAccountApiUserResponse
      = yield call(API.editUser, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setEditUserResult({
        item: res.getUser(),
      }));
    } else {
      yield put(setEditUserResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* changePasswordSaga({payload}: PayloadAction<IChangePasswordPayload>) {
  const hashedOldPassword: string = sha256(payload.oldPassword);
  const hashedNewPassword: string = sha256(payload.newPassword);
  const hashedRepeatNewPassword: string = sha256(payload.repeatNewPassword);

  const req = new UserAccountApiChangePasswordRequest()
    .setUserid(payload.userId)
    .setOldpassword(hashedOldPassword)
    .setNewpassword(hashedNewPassword)
    .setRepeatnewpassword(hashedRepeatNewPassword);

  try {
    const res: ResultResponse = yield call(API.changePassword, req);

    if (res.getIssuccessful()) {
      yield put(setChangePasswordResult({}));
    } else {
      yield put(setChangePasswordResult({errorMessage: getErrorMessageByCode(res.getErrormessage())}));
    }
  } catch {
  }
}


export function* userSaga() {
  yield all([
    takeLatest(editUser.type, editUserSaga),
    takeLatest(changePassword.type, changePasswordSaga),
  ])
}