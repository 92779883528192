import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const FreighterAdminNotApprovedMessage = defineMessage({
  id: "freighterAdminNotApproved.name",
  defaultMessage: "Operator (upload licenses)",
})

export const freighterAdminNotApproved: RoleDescriptor<Role.freighterAdminNotApproved> = {
  role: Role.freighterAdminNotApproved,
  name: FreighterAdminNotApprovedMessage,
  backendIdentifier: 'non_approved_freighter_admin',
  homeRoute: Route.profile,
  navigation: {
    profile: [
    ],
    sidebar: [
      Route.profile,
    ]
  },
}