import React, {useEffect} from 'react';
import styled from "styled-components";
import companyAvatarDefaultImage from "../../../../../../media/images/default_avatar.png";
import {Image} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import Upload from "antd/es/upload/Upload";
import {RcFile} from "antd/es/upload";
import {
  clearLoadCompanyAvatar,
  loadCompanyAvatar,
} from "../../../../../../store/reducers/companyReducer/companyReducer";
import {FileAcceptTypes} from "../../../../../../utils/constans/files/fileAcceptTypes";
import {useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";

const UpdateInfoBlock = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 74px;
  max-width: 74px;
  min-width: 74px;
  height: 74px;
  max-height: 74px;
  min-height: 74px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  border-radius: 100%;
  background: transparent;
  color: transparent;
  z-index: 99;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 52px;
    max-width: 52px;
    min-width: 52px;
    height: 52px;
    max-height: 52px;
    min-height: 52px;
  }
`;

const ProfileCompanyAvatarWrapper = styled.div`
  position: relative;
  width: 74px;
  max-width: 74px;
  min-width: 74px;
  height: 74px;
  max-height: 74px;
  min-height: 74px;
  border-radius: 100%;
  overflow: hidden;
  transition: 0.2s ease;
  
  .ant-image-mask {
    border-radius: 100%;
  }
  
  .ant-upload, .ant-upload-select, .ant-upload-select-text {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    cursor: pointer;
  }
  
  .ant-image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  .ant-image-img {
    width: 74px;
    max-width: 74px;
    min-width: 74px;
    height: 74px;
    max-height: 74px;
    min-height: 74px;
    border-radius: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
  
  :hover {
    ${UpdateInfoBlock} {
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.42);
    } 
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 52px;
    max-width: 52px;
    min-width: 52px;
    height: 52px;
    max-height: 52px;
    min-height: 52px;

    .ant-image-img {
      width: 52px;
      max-width: 52px;
      min-width: 52px;
      height: 52px;
      max-height: 52px;
      min-height: 52px;
    }
  }
`;

export const ProfileCompanyAvatar = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const company = useAppSelector(state => state.company.oneItemObject.item)

  useEffect(() => {
    return () => {
      dispatch(clearLoadCompanyAvatar())
    }
  }, [])

  const onFileLoad = (file: RcFile) => {
      dispatch(loadCompanyAvatar({company: company, file: file}));
  }

  return (
    <ProfileCompanyAvatarWrapper>
      <UpdateInfoBlock>
        {intl.formatMessage(CommonButtonMessages.change)}
      </UpdateInfoBlock>
      <Upload accept={FileAcceptTypes.IMAGE} beforeUpload={onFileLoad} fileList={[]}>
        <Image src={company.avatar?.url ?? companyAvatarDefaultImage} alt={"Company photo"} preview={false}/>
      </Upload>
    </ProfileCompanyAvatarWrapper>
  );
};
