import React, {FC, useMemo, useState} from 'react';
import {
  IChartererEmptyLegFromOrder, IChartererOrder,
  IChartererOrderRoute,
} from "../../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {ChatModal} from "../../../../../../components/businessCommon/chat/ChatModal";
import {
  EmptyLegOrderRouteStatusColorMap, EmptyLegOrderRouteStatusEnum, useEmptyLegOrderRouteStatusName,
} from "../../../../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {useChat} from "../../../../../../components/businessCommon/chat/useChat";
import {
  OrderRouteEmptyLegFlightDrawer
} from "../../../../../../components/businessCommon/flightDrawer/wrappers/OrderRouteEmptyLegFlightDrawer";
import {Col, Row} from "antd";
import {DetailItem} from "../../../../../../components/businessCommon/detailItem/DetailItem";
import {
  StatusShield
} from "../../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";
import {ReactComponent as CaretRightIcon} from "../../../../../../media/icons/caret_right.svg";
import {PlaneImage} from "../../../../../../components/businessCommon/planeImage/PlaneImage";
import {CustomLinkButton} from "../../../../../../components/UI/Buttons/CustomLinkButton";
import {ReactComponent as TrashIcon} from "../../../../../../media/icons/delete.svg";
import {ReactComponent as ReturnIcon} from "../../../../../../media/icons/return.svg";
import {
  changeEmptyLegOrderRouteWithdraw
} from "../../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducer";
import {DisplayEmptyLegDates} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayEmptyLegDates";
import {DisplayFlyPrice} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {DisplayFlyDistance} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDistance";
import {DisplayFlyDuration} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import {useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {Logger} from "../../../../../../utils/logger/Logger";
import {ChatButton} from "../../../../../../components/businessCommon/chat/ChatButton";
import {readNotifications} from "../../../../../../store/reducers/notificationsReducer/notificationsReducer";
import {NotificationType} from "../../../../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";


const RespondCard = styled.div<{ hasNotifications: boolean }>`
  border: 2px solid ${props => props.hasNotifications ? "#1890FF" : "#D9D9D9"};
  background: #FFFFFF;
  border-radius: 10px;
  padding: 16px 16px 24px 16px;
`;

const RespondHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  column-gap: 16px;
`;

const RespondHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  img {
    border-radius: 100%;
  }
`;

const RespondHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;

  div {
    :first-child {
      font-weight: 600;
    }

    :last-child {
      font-weight: 400;
    }
  }
`;

const RespondCardDetailItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const RespondFooter = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    :first-child {
      flex-grow: 1;
    }
  }
`;

const ActionButton = styled.button`
  border: none;
  border-radius: 10px;
  outline: none;
  background: #1890FF;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;


interface IOrderRouteEmptyLegItemCard {
  order: IChartererOrder;
  emptyLegFromOrderRoute: IChartererEmptyLegFromOrder;
}

export const OrderEmptyLegItemCard: FC<IOrderRouteEmptyLegItemCard> = ({
                                                                         order,
                                                                         emptyLegFromOrderRoute,
                                                                       }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const emptyLegOrderRouteStatusName = useEmptyLegOrderRouteStatusName(emptyLegFromOrderRoute.status);
  const [isChatOpened, setIsChatOpened] = useState<boolean>(false);
  const [isEmptyLegDrawerOpen, setIsEmptyLegDrawerOpen] = useState<boolean>(false);
  const userId = useAppSelector(state => state.user.oneItemObject.item.id)
  const chat = useChat(userId, emptyLegFromOrderRoute.freighterUser.id, order.id)
  const allUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)

  const hasNotifications = useMemo(() => {
    const unreadNotifications = allUnreadNotifications.filter(n => n.metadata.orderId === order.id
      && n.metadata.emptyLegOrderRouteId === emptyLegFromOrderRoute.emptyLegOrderRouteId);
    if (unreadNotifications.length > 0) {
      dispatch(readNotifications({
        notificationIds: unreadNotifications.filter(n => n.type !== NotificationType.TYPE_INCOME_MESSAGE).map(n => n.id)
      }));
    }
    return unreadNotifications.length > 0;
  }, []);

  const unreadChatNotifications = useMemo(() => allUnreadNotifications.filter(n => n.entityId === chat?.chatId
    && n.metadata.orderId === order.id
    && n.metadata.emptyLegOrderRouteId === emptyLegFromOrderRoute.emptyLegOrderRouteId), [allUnreadNotifications]);

  if (!chat)
    Logger.error(`No chat found for: userId (account owner): ${userId}, freighterUserId: ${emptyLegFromOrderRoute.freighterUser.id}, orderId: ${order.id}`)

  const onChatOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsChatOpened(true);
  }

  const onEmptyLegOrderRouteStatusChange = (status: EmptyLegOrderRouteStatusEnum) => {
    dispatch(changeEmptyLegOrderRouteWithdraw({
      orderRouteId: emptyLegFromOrderRoute.orderRouteId,
      emptyLegId: emptyLegFromOrderRoute.emptyLegId,
      emptyLegOrderRouteStatus: status,
    }))
  }

  return (
    <RespondCard hasNotifications={hasNotifications}>
      <RespondHeader>
        <RespondHeaderLeft>
          <PlaneImage files={emptyLegFromOrderRoute.plane.imageFiles} width={"44px"} height={"44px"}/>
          <RespondHeaderTitle>
            <div>{emptyLegFromOrderRoute.plane.planeType.name}</div>
            <div>{emptyLegFromOrderRoute.freighterCompany.name}</div>
          </RespondHeaderTitle>
        </RespondHeaderLeft>
        <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen}/>
      </RespondHeader>

      <RespondCardDetailItems>
        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.availableRange)}
                        value={DisplayEmptyLegDates(emptyLegFromOrderRoute.airportFrom.timezone.offset, emptyLegFromOrderRoute.departureDate, emptyLegFromOrderRoute.availableFromDate, emptyLegFromOrderRoute.availableToDate, "datetime_short_year")}/>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.duration)}
                        value={DisplayFlyDuration(emptyLegFromOrderRoute.flyHours, emptyLegFromOrderRoute.flyMinutes)}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.distance)}
                        value={DisplayFlyDistance(emptyLegFromOrderRoute.flyDistance)}/>
          </Col>
          <Col flex={1}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.price)}
                        value={DisplayFlyPrice(emptyLegFromOrderRoute.price, emptyLegFromOrderRoute.currency)}/>
          </Col>
        </Row>
      </RespondCardDetailItems>

      <RespondFooter>
        <div>
          <StatusShield backgroundColor={EmptyLegOrderRouteStatusColorMap[emptyLegFromOrderRoute.status]}>
            {emptyLegOrderRouteStatusName}
          </StatusShield>

          {!isMobile && !order.isArchived && [EmptyLegOrderRouteStatusEnum.ACTIVE, EmptyLegOrderRouteStatusEnum.APPROVED].includes(emptyLegFromOrderRoute.status) &&
            <CustomLinkButton onClick={() => onEmptyLegOrderRouteStatusChange(EmptyLegOrderRouteStatusEnum.WITHDRAWN)}
                              icon={
                                <TrashIcon/>}>{intl.formatMessage(CommonButtonMessages.withdraw)}</CustomLinkButton>}

          {!isMobile && !order.isArchived && [EmptyLegOrderRouteStatusEnum.WITHDRAWN].includes(emptyLegFromOrderRoute.status) &&
            <CustomLinkButton onClick={() => onEmptyLegOrderRouteStatusChange(EmptyLegOrderRouteStatusEnum.ACTIVE)}
                              icon={<ReturnIcon/>}>{intl.formatMessage(CommonButtonMessages.return)}</CustomLinkButton>}
        </div>


        <ActionButton onClick={() => setIsEmptyLegDrawerOpen(true)}><CaretRightIcon/></ActionButton>
      </RespondFooter>

      {isEmptyLegDrawerOpen &&
        <OrderRouteEmptyLegFlightDrawer isOpen={isEmptyLegDrawerOpen} setIsOpen={setIsEmptyLegDrawerOpen}
                                        order={order} emptyLeg={emptyLegFromOrderRoute}
                                        openChat={() => setIsChatOpened(true)}/>}

      {isChatOpened && chat &&
        <ChatModal chat={chat}
                   isVisible={isChatOpened}
                   setIsVisible={setIsChatOpened}
        />}
    </RespondCard>
  );
};
