import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {Col, Form, message, Row} from "antd";
import {CompanyMultiSelector, CompanyMultiSelectorMessages, CompanySelectorOption} from "./CompanyMultiSelector";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {fetchAirports} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {fetchPlaneCategories} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneCategories";
import TextArea from "antd/es/input/TextArea";
import {CustomSwitch} from "../../../../../components/UI/AntCustom/CustomSwitch";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {NewTripRequestPageMessages} from "../NewTripRequestPage";
import styled from "styled-components";
import {Dayjs} from "dayjs";
import {
  clearRoundTripRequest,
  roundTripRequest
} from "../../../../../store/reducers/newRequestReducer/newRequestReducer";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {pathFromRoute} from "../../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../../config/acl/routes/Route";
import {useNavigate} from "react-router";
import {getChatList} from "../../../../../store/reducers/chatReducer/chatReducer";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const RoundTripContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 14px;
`;


interface IRoundTripFormData {
  airportFromId: number;
  airportToId: number;
  departureDateFrom: Dayjs;
  paxFrom: number;
  departureDateTo: Dayjs;
  paxTo: number;
  planeCategoryIds: number[],
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

export const RoundTripForm = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [companyList, setCompanyList] = useState<CompanySelectorOption[]>([]);
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.newRequest.roundTripRequest);

  useEffect(() => {
    return () => {
      dispatch(clearRoundTripRequest())
    }
  })

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        dispatch(getChatList());
        renderSuccessModal(intl.formatMessage(NewTripRequestPageMessages.publishSuccess), () => navigate(pathFromRoute(Route.requests), {replace: true}));
      }
      if (errorMessage)
        message.error(errorMessage)
    }
  }, [isSuccess, isLoading, errorMessage])

  const onFinish = (values: IRoundTripFormData) => {
    if (companyList.length === 0) {
      message.error(intl.formatMessage(CompanyMultiSelectorMessages.error));
      return;
    }

    dispatch(roundTripRequest({
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
      departureDateFrom: values.departureDateFrom.toDate(),
      paxFrom: values.paxFrom,
      departureDateTo: values.departureDateTo.toDate(),
      paxTo: values.paxTo,
      planeCategoryIds: values.planeCategoryIds,
      companionsAllowed: values.companionsAllowed,
      comment: values.comment,
      companyIds: companyList.map(c => c.id),
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: Partial<IRoundTripFormData> = {
    airportFromId: undefined,
    airportToId: undefined,
    departureDateFrom: undefined,
    paxFrom: undefined,
    departureDateTo: undefined,
    paxTo: undefined,
    planeCategoryIds: [],
    companionsAllowed: false,
    comment: undefined,
    companyIds: [],
  }

  const companyMultiSelector = <CompanyMultiSelector selectedCompanies={companyList}
                                                     setSelectedCompanies={setCompanyList}/>

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      autoComplete="off"
      layout={'vertical'}
      form={form}
    >
      <RoundTripContentWrapper>
        <div style={{flexGrow: 1}}>
          <Row gutter={8}>
            <Col span={isMobile ? 24 : 12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
                name={'airportFromId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
                name={'airportToId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={isMobile ? 16 : 9}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.dateThereLabel)}
                name={'departureDateFrom'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <CustomDatePicker
                  showTime={true}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={intl.formatMessage(CommonFormMessages.datePlaceholder)}
                  disabledDate={(date) => isDateLessTanOneMinuteAgo(date) || date && date > (form.getFieldValue('departureDateTo'))}
                  inputReadOnly={true} placement={"bottomLeft"}
                  getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 8 : 3}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.paxThereLabel)}
                name={'paxFrom'}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinPax)},
                  {max: 100, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMaxPax)}
                ]}>
                <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                   style={{width: "100%"}}/>
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 16 : 9}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.dateBackLabel)}
                name={'departureDateTo'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <CustomDatePicker
                  showTime={true}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={intl.formatMessage(CommonFormMessages.datePlaceholder)}
                  disabledDate={(date) => isDateLessTanOneMinuteAgo(date) || date && date < (form.getFieldValue('departureDateFrom'))}
                  inputReadOnly={true} placement={"bottomLeft"}
                  getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 8 : 3}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.paxBackLabel)}
                name={'paxTo'}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinPax)},
                  {max: 100, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMaxPax)}
                ]}>
                <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                   style={{width: "100%"}}/>
              </CustomFieldInput>
            </Col>
          </Row>

          {isMobile && companyMultiSelector}

          <Row gutter={8}>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.planeCategoryLabel)}
                name={'planeCategoryIds'}
              >
                <DebounceSelect
                  fetchOptions={fetchPlaneCategories}
                  placeholder={intl.formatMessage(CommonFormMessages.allPlaceholder)}
                  mode={'multiple'}
                  brightPlaceholder={true}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.commentLabel)}
                name={'comment'}>
                <TextArea
                  allowClear={true}
                  autoSize={{minRows: isMobile ? 1 : 3}}
                  placeholder={intl.formatMessage(CommonFormMessages.commentPlaceholder)}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col>
              <CustomFieldInput
                name={'companionsAllowed'}
                valuePropName={'checked'}
              >
                <CustomSwitch label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)}/>
              </CustomFieldInput>
            </Col>
          </Row>
        </div>

        {!isMobile && companyMultiSelector}
      </RoundTripContentWrapper>

      <Col span={24}>
        <CustomFieldInput>
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={false}
            style={{width: "100%"}}
          >
            {intl.formatMessage(NewTripRequestPageMessages.createRequest)}
          </CustomButton>
        </CustomFieldInput>
      </Col>
    </Form>
  );
};
