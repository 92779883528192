import React, {Dispatch, FC, SetStateAction} from 'react';
import styled from "styled-components";
import {ISearchedEmptyLeg} from "../../../../../store/reducers/searchReducer/searchReducerTypes";
import {PlaneImage} from "../../../../../components/businessCommon/planeImage/PlaneImage";
import {CustomLinkButton} from "../../../../../components/UI/Buttons/CustomLinkButton";
import {ReactComponent as TrashIcon} from ".././../../../../media/icons/delete.svg";
import {Col, Row} from "antd";
import {DetailItem} from "../../../../../components/businessCommon/detailItem/DetailItem";
import {DisplayFlyPrice} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {DisplayFlyDistance} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDistance";
import {DisplayFlyDuration} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import {DisplayEmptyLegDates} from "../../../../../utils/helpers/textDisplayHelpers/DisplayEmptyLegDates";
import {useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  padding: 12px 16px 24px 16px;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  flex-direction: column;
  row-gap: 16px;
`;

const ContentTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const ContentTopLeft = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
`;

const ContentImage = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  div {
    :first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    :last-child {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
`;

const DetailItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;


interface ICreateOrderEmptyLegCard {
  selectedEmptyLeg: ISearchedEmptyLeg;
  setSelectedEmptyLegs: Dispatch<SetStateAction<ISearchedEmptyLeg[]>>
}

export const CreateOrderEmptyLegCard: FC<ICreateOrderEmptyLegCard> = ({selectedEmptyLeg, setSelectedEmptyLegs}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const removeEmptyLegFromOrder = () => {
    setSelectedEmptyLegs(prev => prev.filter(el => el.emptyLegId !== selectedEmptyLeg.emptyLegId));
  }

  return (
    <Wrapper>
      <ContentTop>
        <ContentTopLeft>
          <ContentImage>
            <PlaneImage files={selectedEmptyLeg.plane.imageFiles} width={"104px"} height={"80px"}/>
          </ContentImage>
          <ContentTitle>
            <div>{selectedEmptyLeg.freighterCompany.name}</div>
            <div>{selectedEmptyLeg.plane.planeType.name}</div>
          </ContentTitle>
        </ContentTopLeft>
        <CustomLinkButton icon={<TrashIcon/>} onClick={removeEmptyLegFromOrder} />
      </ContentTop>

      <DetailItemsWrapper>
        <Row gutter={8}>
          <Col span={24}>
            <DetailItem
              label={intl.formatMessage(CommonDetailsMessages.departureDate)}
              value={DisplayEmptyLegDates(selectedEmptyLeg.airportFrom.timezone.offset,
                selectedEmptyLeg.departureDate,
                selectedEmptyLeg.availableFromDate,
                selectedEmptyLeg.availableToDate,
                "datetime_short_year")}
              size={"small"}
              background={"#FFFFFF"}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={isMobile ? 12 : 8}>
            <DetailItem
              label={intl.formatMessage(CommonDetailsMessages.duration)}
              value={DisplayFlyDuration(selectedEmptyLeg.flyHours, selectedEmptyLeg.flyMinutes)}
              size={"small"}
              background={"#FFFFFF"}
            />
          </Col>
          <Col span={isMobile ? 12 : 8}>
            <DetailItem
              label={intl.formatMessage(CommonDetailsMessages.distance)}
              value={DisplayFlyDistance(selectedEmptyLeg.flyDistance)}
              size={"small"}
              background={"#FFFFFF"}
            />
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <DetailItem
              label={intl.formatMessage(CommonDetailsMessages.price)}
              value={DisplayFlyPrice(selectedEmptyLeg.price, selectedEmptyLeg.currency)}
              size={"small"}
              background={"#FFFFFF"}
            />
          </Col>
        </Row>
      </DetailItemsWrapper>
    </Wrapper>
  );
};
