import React, {FC, useEffect, useState} from 'react';
import {
  IChartererOrder
} from "../../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import styled from "styled-components";
import {CustomPanel} from "../../../../../../components/UI/AntCustom/CustomPanel";
import {
  defaultEmptyLegRespondStatusesInfo,
  generateEmptyLegRespondStatusesInfo,
  IEmptyLegRespondStatusesInfo,
} from "../../../../../../components/businessCommon/respondsInfo/generateRespondInfo";
import {OrderEmptyLegItemCard} from "./OrderEmptyLegItemCard";
import {CustomCollapse} from "../../../../../../components/UI/AntCustom/CustomCollapse";
import {OrderHeader} from "../OrderHeader";
import {useAppSelector} from "../../../../../../store/store";
import {CardFieldTitle} from "../../../../../../components/UI/CardFieldTitle/CardFieldTItle";
import {NotificationDot} from '../../../../../../components/notifications/components/NotificationDot';
import {EmptyLegRespondsInfoBlock} from "../../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";


const EmptyLegsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(351px, 1fr));
  gap: 10px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
    gap: 8px;
  }
`;

interface IOrderRouteEmptyLegCard {
  order: IChartererOrder;
}


export const OrderEmptyLegCard: FC<IOrderRouteEmptyLegCard> = ({order}) => {
  const [respondInfo, setRespondInfo] = useState<IEmptyLegRespondStatusesInfo>(defaultEmptyLegRespondStatusesInfo);

  const hasUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)
    .some(n => n.metadata.orderId === order.id)

  useEffect(() => {
    const newRespondInfo = generateEmptyLegRespondStatusesInfo(
      order.nestedEmptyLegs
        .map(el => el.status)
    );

    setRespondInfo(newRespondInfo);
  }, [...order.nestedEmptyLegs.map(el => el.status)])

  const respondsHeader =
    <CardFieldTitle>
      <div>Empty Legs ({order.nestedEmptyLegs.length})</div>
      {hasUnreadNotifications && <NotificationDot/>}
    </CardFieldTitle>

  return (
    <div>
      <OrderHeader order={order}/>
      <CustomCollapse expandIconPosition={'end'}>
        <CustomPanel
          key={order.id + order.nestedEmptyLegs.map(el => el.emptyLegId).join(',')}
          header={respondsHeader}
          extra={<EmptyLegRespondsInfoBlock emptyLegRespondStatusesInfo={respondInfo}/>}
        >
          <EmptyLegsWrapper>
            {order.nestedEmptyLegs.map(
              emptyLeg => <OrderEmptyLegItemCard key={emptyLeg.emptyLegOrderRouteId}
                                                      order={order}
                                                      emptyLegFromOrderRoute={emptyLeg} />
            )}
          </EmptyLegsWrapper>
        </CustomPanel>
      </CustomCollapse>
    </div>
  );
};
