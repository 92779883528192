import styled from "styled-components";
import {OrderRequestStatusEnum} from "../../../../utils/constans/statuses/orderRequestStatuses";
import {EmptyLegOrderRouteStatusEnum} from "../../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

export const FlightDrawerFooterContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

export const FlightDrawerFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 380px;
  column-gap: 8px;
  align-items: center;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-grow: 1;
    width: unset;
  }
`;

export const FlightDrawerFooterPrice = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  flex-wrap: wrap;
`;

export const FlightDrawerFooterPriceLabel = styled.div`
  height: 22px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
`;

export const FlightDrawerFooterPriceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

export const FlightDrawerFooterProcessButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  button {
    border: none;
    color: #FFFFFF;

    :nth-child(1) {
      background: #C05353;
    }

    :nth-child(2) {
      background: #63A070;
    }

    :hover, :active, :focus {
      color: #FFFFFF;
      opacity: 0.8;
    }
  }
`;

export const FlightDrawerFooterStatusAndAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 16px;
`;

export const FlightDrawerFooterStatus = styled.div<{ status: OrderRequestStatusEnum | EmptyLegOrderRouteStatusEnum }>`
  padding: 2px 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: ${props => (props.status === OrderRequestStatusEnum.OFFERED && "#D6E6CD")
  || (props.status === OrderRequestStatusEnum.DECLINED && "#E7C4C4")
  || (props.status === OrderRequestStatusEnum.NOT_OFFERED && "#CDDDE6")
  || (props.status === EmptyLegOrderRouteStatusEnum.ACTIVE && "#CDDDE6")
  || (props.status === EmptyLegOrderRouteStatusEnum.APPROVED && "#D6E6CD")
  || (props.status === EmptyLegOrderRouteStatusEnum.WITHDRAWN && "#F9D99B")
  || (props.status === EmptyLegOrderRouteStatusEnum.DECLINED && "#E7C4C4")};
`;