import React, {FC} from 'react';
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import Button from "antd/es/button/button";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import {defineMessages, useIntl} from "react-intl";

const ManagingSubscriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  flex-grow: 1;
`;

const SubscriptionsCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const SubscriptionsPrivilege = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  padding: 20px;
`;

const PrivilegeItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: flex-end;
  @media (max-width: ${mediaScreenSizes.mobile}) {
    justify-content: center;
  }
`;

const TitleValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 7px;
`;

const TitleValue = styled.div`
  color: #1890FF;
`;

const PersonalSales = styled.div`
  @media (max-width: ${mediaScreenSizes.mobile}) {
    max-width: 160px;
  }
  @media (max-width: ${mediaScreenSizes.tablet}) {
    max-width: 160px;
  }
`;


const SubscriptionsCardsMessages = defineMessages({
  benefit: {
    id: "subscriptionsCards.benefit",
    defaultMessage: "Benefit",
  },
  personalSales: {
    id: "subscriptionsCards.personalSales",
    defaultMessage: "Personal discounts and offers",
  },
  support: {
    id: "subscriptionsCards.support",
    defaultMessage: "Support",
  },
  choose: {
    id: "subscriptionsCards.choose",
    defaultMessage: "Choose",
  },
})

interface ISubscriptionsCards {
  title: string;
  personalSalesValue: string;
  supportServiceValue: string;
  benefitInterest?: string;
}

export const SubscriptionsCards: FC<ISubscriptionsCards> = ({
                                                                     title,
                                                                     personalSalesValue,
                                                                     supportServiceValue,
                                                                     benefitInterest,
                                                                   }) => {
  const intl = useIntl();

  return (
    <ManagingSubscriptionCard>
      <SubscriptionsCardTitle>
        <div>
          {title}
        </div>
        <TitleValueWrapper>
          {benefitInterest &&
            <div>
              {intl.formatMessage(SubscriptionsCardsMessages.benefit)}
            </div>
          }
          <TitleValue>
            {benefitInterest}
          </TitleValue>
        </TitleValueWrapper>
      </SubscriptionsCardTitle>
      <SubscriptionsPrivilege>
        <PrivilegeItem>
          <PersonalSales>
            {intl.formatMessage(SubscriptionsCardsMessages.personalSales)}
          </PersonalSales>
          <div>{personalSalesValue}</div>
        </PrivilegeItem>
        <PrivilegeItem>
          <div>{intl.formatMessage(SubscriptionsCardsMessages.support)}</div>
          <div>{supportServiceValue}</div>
        </PrivilegeItem>
      </SubscriptionsPrivilege>
      <ButtonWrapper>
        <Button
          type={'primary'}
          disabled={true}
          style={{backgroundColor: '#1890FF', color: '#FFFFFF'}}
          icon={<PlusOutlined/>}
        >
          {intl.formatMessage(SubscriptionsCardsMessages.choose)}
        </Button>
      </ButtonWrapper>
    </ManagingSubscriptionCard>
  );
};