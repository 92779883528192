import {DateFormat, formatDate} from "../dateHelpers/dateHelpers";
import {Logger} from "../../logger/Logger";

export const DisplayEmptyLegDates = (timezone: number, departureDate?: Date, availableFromDate?: Date, availableToDate?: Date, format: DateFormat = 'datetime'): string => {
  if (departureDate)
    return formatDate(departureDate, timezone, format);
  else if (availableFromDate && availableToDate)
    return `${formatDate(availableFromDate, timezone, format)} — ${formatDate(availableToDate, timezone, format)}`;
  else {
    Logger.error('No both empty leg date types');
    return '';
  }
}