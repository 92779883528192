import React, {FC, ReactNode} from 'react';
import styled from "styled-components";
import {ReactComponent as PhoneIcon} from "../../../../media/icons/phone.svg";
import {ReactComponent as EmailIcon} from "../../../../media/icons/email.svg";
import {ReactComponent as LocationIcon} from "../../../../media/icons/location.svg";
import {ReactComponent as WebsiteIcon} from "../../../../media/icons/website.svg";
import {ICompany} from "../../../../store/reducers/companyReducer/companyReducerTypes";
import {IUser} from "../../../../store/reducers/userReducer/userReducerTypes";
import {CustomPanel} from "../../../UI/AntCustom/CustomPanel";
import {CustomLoadedFileWrapper, CustomLoadedFileWrapperTitle, CustomUpload} from "../../../UI/AntCustom/CustomUpload";
import {CustomCollapse} from "../../../UI/AntCustom/CustomCollapse";
import {DisplayFullName} from "../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";


const Wrapper = styled.div`
  .ant-collapse, .ant-collapse-icon-position-end {
    border-radius: 10px;
  }

  .ant-collapse-header {
    background: #FFFFFF;
    border-radius: 10px !important;
    padding: 16px 20px;
  }

  .ant-collapse-item, .ant-collapse-item-active {
    border-radius: 10px !important;
  }

  .ant-collapse-content, .ant-collapse-content-active {
    background: #FFFFFF;
    border-radius: 0 0 10px 10px !important;
    border-top: 0;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  div {
    :first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    :last-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 6px 20px 24px 20px;
`;

const ManagerInfoWrapper = styled.div`
  display: flex;
  padding: 10px 16px 8px 16px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  flex-direction: column;
`;

const ManagerInfoTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
`;

const ManagerInfoTitleValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 1px;
  margin-bottom: 16px;
`;

const ManagerInfoContactItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 8px;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
`

const CompanyInfoContactItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 16px;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  svg {
    display: flex;
    height: 18px;
    min-width: 18px;
    align-items: center;
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1890FF;
    transition: 0.2s ease;
    text-decoration: underline;

    :hover {
      opacity: 0.8;
    }
  }
`;

const SectionTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const AboutCompanyDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6B6B6B;
`;

const CertificateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 16px;
`;


const FlightDrawerOperatorMessages = defineMessages({
  title: {
    id: "flightDrawerOperator.title",
    defaultMessage: "Operator",
  },
  managerTitle: {
    id: "flightDrawerOperator.managerTitle",
    defaultMessage: "Client manager",
  },
  companyAboutTitle: {
    id: "flightDrawerOperator.companyAboutTitle",
    defaultMessage: "About the company",
  },
  operatorCardTitle: {
    id: "flightDrawerOperator.operatorCardTitle",
    defaultMessage: "AOC",
  },
  registrationCertificate: {
    id: "flightDrawerOperator.registrationCertificate",
    defaultMessage: "Certificate of incorporation",
  },
})

interface IContactItem {
  icon: ReactNode;
  value: string | ReactNode;
}

interface IOrderRouteFlightDrawerOperator {
  company: ICompany;
  managerUser: IUser;
}

export const FlightDrawerOperator: FC<IOrderRouteFlightDrawerOperator> = ({
                                                                            company,
                                                                            managerUser
                                                                          }) => {
  const intl = useIntl();

  const managerContactItems: IContactItem[] = [
    {icon: <PhoneIcon/>, value: managerUser.phone ? `+${managerUser.phone}` : undefined},
    {icon: <EmailIcon/>, value: managerUser.email},
  ];
  const companyContactItems: IContactItem[] = [
    {icon: <PhoneIcon/>, value: company.phone ? `+${company.phone}` : undefined},
    {icon: <EmailIcon/>, value: company.email},
    {icon: <LocationIcon/>, value: company.address},
    {
      icon: <WebsiteIcon/>,
      value: company.website ? <a href={`//${company.website}`} rel={'noreferrer'} target={'_blank'}>{company.website}</a> : undefined
    },
  ];

  return (
    <Wrapper>
      <CustomCollapse>
        <CustomPanel
          key={company.id}
          noPaddings={true}
          background={"#FFF"}
          header={<Title>
            <div>{intl.formatMessage(FlightDrawerOperatorMessages.title)}</div>
            <div>{company.name}</div>
          </Title>}
        >
          <ContentWrapper>
            <ManagerInfoWrapper>
              <ManagerInfoTitle>{intl.formatMessage(FlightDrawerOperatorMessages.managerTitle)}</ManagerInfoTitle>
              <ManagerInfoTitleValue>{DisplayFullName(managerUser)}</ManagerInfoTitleValue>
              {managerContactItems.filter(item => !!item.value).map((manager, idx) => <ManagerInfoContactItem key={idx}>
                {manager.icon}
                <div>{manager.value}</div>
              </ManagerInfoContactItem>)}
            </ManagerInfoWrapper>

            {companyContactItems.filter(item => !!item.value).map((company, idx) => <CompanyInfoContactItem key={idx}>
              {company.icon}
              <div>{company.value}</div>
            </CompanyInfoContactItem>)}

            {company.description && <>
              <SectionTitle>{intl.formatMessage(FlightDrawerOperatorMessages.companyAboutTitle)}</SectionTitle>
              <AboutCompanyDescription>{company.description}</AboutCompanyDescription>
            </>}

            {company.operatorCard && <CertificateWrapper>
              <CustomLoadedFileWrapperTitle>{intl.formatMessage(FlightDrawerOperatorMessages.operatorCardTitle)}</CustomLoadedFileWrapperTitle>
              <CustomLoadedFileWrapper>
                <CustomUpload fileList={[company.operatorCard]}/>
              </CustomLoadedFileWrapper>
            </CertificateWrapper>}

            {company.registrationCertificate && <CertificateWrapper>
              <CustomLoadedFileWrapperTitle>{intl.formatMessage(FlightDrawerOperatorMessages.registrationCertificate)}</CustomLoadedFileWrapperTitle>
              <CustomLoadedFileWrapper>
                <CustomUpload fileList={[company.registrationCertificate]}/>
              </CustomLoadedFileWrapper>
            </CertificateWrapper>}

          </ContentWrapper>
        </CustomPanel>
      </CustomCollapse>
    </Wrapper>
  );
};
