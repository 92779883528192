import {RoleDescriptor} from "../RoleDescriptor";
import {Route} from "../../routes/Route";
import {Role} from "../Role";
import {defineMessage} from "react-intl";

const AdminMessage = defineMessage({
  id: "admin.name",
  defaultMessage: "Administrator",
})

export const admin: RoleDescriptor<Role.admin> = {
  role: Role.admin,
  name: AdminMessage,
  backendIdentifier: 'admin',
  homeRoute: Route.adminRegistrations,
  navigation: {
    profile: [
      Route.adminRegistrations,
    ],
    sidebar: [
      Route.adminRegistrations,
    ]
  },
}