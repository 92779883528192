import React, {Dispatch, FC, SetStateAction} from 'react';
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {defineMessages, useIntl} from "react-intl";
import {Form, Input, message} from "antd";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {
  IFlyHourCalculatorCategory, IFlyHourCalculatorSettingType,
  IFlyHourCalculatorSubCategory
} from "../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {useFlyPriceCategorySetter} from "./hooks/useFlyPriceCategorySetter";
import {FlyHourPriceCalculatorSettingTypeEnum} from "./types/flyHourPriceSettingEnums";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const FlyHourPriceCalculatorWarnModalMessages = defineMessages({
  title: {
    id: "flyHourPriceCalculatorWarnModal.title",
    defaultMessage: "Add field"
  },
  label: {
    id: "flyHourPriceCalculatorWarnModal.label",
    defaultMessage: "Title"
  },
  placeholder: {
    id: "flyHourPriceCalculatorWarnModal.placeholder",
    defaultMessage: "Enter field title"
  }
})


interface IFlyHourPriceCalculatorWarnModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  category: IFlyHourCalculatorCategory;
  subCategory: IFlyHourCalculatorSubCategory;
}

export const FlyHourPriceCalculatorAddFieldModal: FC<IFlyHourPriceCalculatorWarnModal> = ({
                                                                                            isModalVisible,
                                                                                            setIsModalVisible,
                                                                                            category,
                                                                                            subCategory
                                                                                          }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const categorySetter = useFlyPriceCategorySetter(category.keyName);

  const onFinish = (values: { field: string }) => {
    const newSettingType: IFlyHourCalculatorSettingType = {
      keyName: values.field,
      label: values.field,
      isEnabled: true,
      isDefault: false,
      setting: {
        value: 0,
      },
      type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
    }

    if (categorySetter) {
      categorySetter(c => {
        return {
          ...c, subcategories: c.subcategories.map(sc => {
            if (sc.keyName !== subCategory.keyName)
              return sc;
            return {...sc, settingTypes: [...sc.settingTypes, newSettingType]}
          })
        }
      })
      setIsModalVisible(false);
    }
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  return (
    <CustomModal
      title={intl.formatMessage(FlyHourPriceCalculatorWarnModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered={true}
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
      >
        <CustomFieldInput
          label={intl.formatMessage(FlyHourPriceCalculatorWarnModalMessages.label)}
          name={'field'}
          rules={[
            {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
          ]}>
          <Input placeholder={intl.formatMessage(FlyHourPriceCalculatorWarnModalMessages.placeholder)}/>
        </CustomFieldInput>

        <CustomButton type={'primary'} htmlType={'submit'}
                      style={{width: "100%"}}>{intl.formatMessage(CommonButtonMessages.create)}</CustomButton>
      </Form>
    </CustomModal>
  );
};
