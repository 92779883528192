import {useEnumIntl} from "../../../intl/helpers/useEnumIntl";
import {defineMessages} from "react-intl";

// *** DO NOT CHANGE WITHOUT BACKEND DEVELOPERS AGREEMENT ***
export enum EmptyLegOrderRouteStatusEnum {
  ACTIVE = 1,
  APPROVED = 2,
  DECLINED = 3,
  WITHDRAWN = 4,
}

export const EmptyLegOrderRouteStatusColorMap = {
  [EmptyLegOrderRouteStatusEnum.ACTIVE]: "#CDDDE6",
  [EmptyLegOrderRouteStatusEnum.APPROVED]: "#D6E6CD",
  [EmptyLegOrderRouteStatusEnum.DECLINED]: "#E7C4C4",
  [EmptyLegOrderRouteStatusEnum.WITHDRAWN]: "#F9D99B",
}

const EmptyLegOrderRouteStatusNameMessages = defineMessages({
  active: {
    id: "statuses.emptyLegOrderRouteStatus.active",
    defaultMessage: "No offers",
  },
  approved: {
    id: "statuses.emptyLegOrderRouteStatus.approved",
    defaultMessage: "Accepted",
  },
  declined: {
    id: "statuses.emptyLegOrderRouteStatus.declined",
    defaultMessage: "Declined",
  },
  withdrawn: {
    id: "statuses.emptyLegOrderRouteStatus.withdrawn",
    defaultMessage: "Withdrawn",
  },
})

export const useEmptyLegOrderRouteStatusName = (status: EmptyLegOrderRouteStatusEnum) => {
  return useEnumIntl(status, {
    [EmptyLegOrderRouteStatusEnum.ACTIVE]: EmptyLegOrderRouteStatusNameMessages.active,
    [EmptyLegOrderRouteStatusEnum.APPROVED]: EmptyLegOrderRouteStatusNameMessages.approved,
    [EmptyLegOrderRouteStatusEnum.DECLINED]: EmptyLegOrderRouteStatusNameMessages.declined,
    [EmptyLegOrderRouteStatusEnum.WITHDRAWN]: EmptyLegOrderRouteStatusNameMessages.withdrawn,
  });
}