import React, {FC, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {getNotificationIconByType} from "../helpers/getNotificationIconByType";
import {getNotificationText} from "../helpers/getNotificationText";
import {useIntl} from "react-intl";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {ReactComponent as CloseIcon} from "../../../media/icons/close.svg";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {NotificationsContext} from "../context/NotificationsProvider";
import {getNotificationMetadata} from "../helpers/getNotificationMetadata";
import {getChatList} from "../../../store/reducers/chatReducer/chatReducer";
import {INotification, NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const NotificationPushWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 33px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  position: fixed;
  top: 75px;
  right: 17px;
  padding: 16px 34px 16px 16px;
  z-index: 1000;
  animation: 0.5s ease showPush forwards, 0.5s ease 7.5s hidePush forwards;

  @keyframes showPush {
    from {
      opacity: 0;
      right: -100px;
    }
    to {
      opacity: 1;
      right: 17px;
    }
  }

  @keyframes hidePush {
    from {
      opacity: 1;
      right: 17px;
    }
    to {
      opacity: 0;
      right: -100px;
      display: none;
    }
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    max-width: calc(100vw - 34px);
  }
`;

const NotificationPushContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
`;

const NotificationPushIcon = styled.img`
  display: flex;
  height: 20px;
  width: 20px;
  object-fit: contain;
`;

const NotificationPushDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const NotificationPushTime = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
`;

const NotificationPushText = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const NotificationPushMetadataText = styled.div`
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

const NotificationCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 14px;
  width: 14px;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.7;
  }
`;


interface INotificationPush {
}

export const NotificationPush: FC<INotificationPush> = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {isWindowOpen} = useContext(NotificationsContext);
  const timezoneOffset = useAppSelector(state => state.user.oneItemObject.item.timezone.offset);
  const {newPushesReceived} = useAppSelector(state => state.notifications);
  const lastUnreadNotification = useAppSelector(state => state.notifications.itemsObject.items.find(n => !n.isRead))
  const [isShow, setIsShow] = useState(false);
  const [pushTimeout, setPushTimeout] = useState<NodeJS.Timeout>()

  useEffect(() => {
    lastUnreadNotification && getNewChatList(lastUnreadNotification);

    clearTimeout(pushTimeout);

    const newTimeout = setTimeout(() => {
      setIsShow(false);
    }, 8000);

    setIsShow(true);
    setPushTimeout(newTimeout);
  }, [lastUnreadNotification])

  useEffect(() => {
    if (isWindowOpen) {
      setIsShow(false);

      if (pushTimeout)
        clearTimeout(pushTimeout);
    }
  }, [isWindowOpen])

  const getNewChatList = (notification: INotification) => {
    if ([
      NotificationType.TYPE_ORDER_REQUEST_CREATED,
      NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED,
    ].includes(notification.type)) {
      dispatch(getChatList());
    }
  }

  if (!newPushesReceived || !lastUnreadNotification || isWindowOpen || !isShow)
    return null;

  return (
    <NotificationPushWrapper>
      <NotificationCloseButton onClick={() => setIsShow(false)}>
        <CloseIcon/>
      </NotificationCloseButton>

      <NotificationPushContent>
        <NotificationPushIcon src={getNotificationIconByType(lastUnreadNotification.type)}/>

        <NotificationPushDataWrapper>
          <NotificationPushTime>
            {formatDate(lastUnreadNotification.dateCreated, timezoneOffset, "time")}
          </NotificationPushTime>

          <NotificationPushText>
            {getNotificationText(lastUnreadNotification, intl)}
          </NotificationPushText>

          <NotificationPushMetadataText>
            {getNotificationMetadata(lastUnreadNotification)}
          </NotificationPushMetadataText>
        </NotificationPushDataWrapper>
      </NotificationPushContent>
    </NotificationPushWrapper>
  );
};
