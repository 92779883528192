import React, {FC, ReactNode} from 'react';
import Upload, {UploadProps} from "antd/es/upload/Upload";
import styled from "styled-components";
import {onPdfFilePreview} from "../../../utils/helpers/fileHelpers/onPdfFilePreview";
import {ReactComponent as PdfIcon} from "../../../media/icons/pdf.svg"
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const UploadStyled = styled(Upload)`
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  
  .ant-upload, .ant-upload-select, .ant-upload-select-text {
    display: flex;
    flex-grow: 1;
  }
  
  .ant-btn, .ant-btn-dashed {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    padding: 12px;
    box-sizing: content-box;
    height: 22px;
    color: #1890FF;
  }
`;

export const CustomLoadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width: 235px;

  .ant-upload-list-item, .ant-upload-list-item-undefined, .ant-upload-list-item-list-type-text {
    height: max-content;
  }

  .ant-upload-list-item-info {
    height: max-content;
    cursor: pointer;
  }

  .ant-upload-span {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    line-height: 22px;
    box-sizing: content-box;

    .ant-upload-text-icon {
      display: none;
    }

    .ant-upload-list-item-name {
      color: #1890FF;
      font-weight: 500;
      font-size: 14px;
      padding: 8px;
      text-align: center;
    }

    .ant-upload-list-item-card-actions {
      display: none;
    }
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 5px;
  }
`;

const CustomUploadFileItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  cursor: pointer;
  border: 1px solid #D9DAD9;
  border-radius: 5px;
  padding: 4px 10px;
  color: #1890FF;
  align-items: center;
  transition: border 0.2s ease;
  
  svg {
    min-width: 16px;
    min-height: 16px;
  }
  
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  :hover {
    border: 1px solid #1890FF;
  }
`;

export const CustomLoadedFileWrapperTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
`;

interface ICustomUpload extends UploadProps {
  children?: ReactNode;
}

export const CustomUpload: FC<ICustomUpload> = ({children, ...props}) => {
  return (
    <UploadStyled {...props} itemRender={(originNode, file) => <CustomUploadFileItem onClick={async () => {
      await onPdfFilePreview(file)
    }}>
      <PdfIcon />
      <div>{file.name}</div>
    </CustomUploadFileItem>}>
      {children}
    </UploadStyled>
  );
};
