import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChatEntity, ChatMessageEntity} from "../../../proto/generated/api_entities_pb";
import {mapChatEntityToIChat} from "./chatMappers";
import {parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";

interface IChatCompany {
  companyId: number;
  companyName: string;
}

interface IChatUser {
  userId: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  company: IChatCompany;
}

export interface IChat {
  chatId: string;
  orderId: number;
  fromUser: IChatUser;
  toUser: IChatUser;
}

interface ISetChatListResult {
  chats: ChatEntity[];
}

export interface IMessage {
  chatId: string;
  chatMessageId: string;
  messageText: string;
  sendDate: Date;
  fromUser: IChatUser;
  toUser: IChatUser;
}

interface IReceiveMessagePayload {
  messages: ChatMessageEntity[];
}

export interface IGetChatMessagesPayload {
  chatId: string;
}

interface ISetChatMessagesResult {
  messages: ChatMessageEntity[];
  totalCount: number;
}

export interface ISendMessagePayload {
  chatId: string;
  orderId: number;
  toUserId: number;
  messageText: string;
}

interface IChatInitialState {
  chatList: IChat[];
  currentChatId?: string;
  chatMessages: IMessage[];
  chatMessagesTotalCount: number;
  isChatMessagesLoading: boolean;
  isMessageSendLoading: boolean;
}

const chatInitialState: IChatInitialState = {
  chatList: [],
  currentChatId: undefined,
  chatMessages: [],
  chatMessagesTotalCount: 0,
  isChatMessagesLoading: true,
  isMessageSendLoading: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    getChatList(state) {
    },
    setChatListResult(state, {payload}: PayloadAction<ISetChatListResult>) {
      state.chatList = payload.chats.map(mapChatEntityToIChat);
    },
    getChatMessages(state, {payload}: PayloadAction<IGetChatMessagesPayload>) {
      state.isChatMessagesLoading = true;
      state.currentChatId = payload.chatId;
    },
    clearChatMessages(state) {
      state.chatMessages = [];
      state.currentChatId = undefined;
      state.isMessageSendLoading = false;
      state.isChatMessagesLoading = true;
    },
    setChatMessagesResult(state, {payload}: PayloadAction<ISetChatMessagesResult>) {
      const messages = payload.messages.map<IMessage>(m => {
        const fromUser = m.getFromuser()!;
        const toUser = m.getTouser()!;
        const fromCompany = fromUser.getCompany()!;
        const toCompany = toUser.getCompany()!;

        return {
          chatId: m.getChat()?.getChatid() || "",
          chatMessageId: m.getChatmessageid(),
          messageText: m.getMessage(),
          sendDate: parseStringAsUTCDate(m.getSenddate()),
          fromUser: {
            userId: fromUser.getUserid(),
            firstName: fromUser.getFirstname(),
            lastName: fromUser.getLastname(),
            middleName: fromUser.getMiddlename(),
            company: {
              companyId: fromCompany.getCompanyid(),
              companyName: fromCompany.getCompanyname(),
            }
          },
          toUser: {
            userId: toUser.getUserid(),
            firstName: toUser.getFirstname(),
            lastName: toUser.getLastname(),
            middleName: toUser.getMiddlename(),
            company: {
              companyId: toCompany.getCompanyid(),
              companyName: toCompany.getCompanyname(),
            }
          },
        }
      });

      state.chatMessages.push(...messages);
      state.chatMessagesTotalCount = payload.totalCount;
      state.isChatMessagesLoading = false;
    },
    sendMessage(state, {payload}: PayloadAction<ISendMessagePayload>) {
      state.isMessageSendLoading = true;
    },
    receiveMessages(state, {payload}: PayloadAction<IReceiveMessagePayload>) {
      const messages = payload.messages;

      messages.forEach(message => {
        if (message && state.currentChatId === message.getChat()?.getChatid()) {
          const fromUser = message.getFromuser()!;
          const toUser = message.getTouser()!;
          const fromCompany = fromUser.getCompany()!;
          const toCompany = toUser.getCompany()!;

          const newMessage: IMessage = {
            chatId: message.getChat()?.getChatid() || "",
            chatMessageId: message.getChatmessageid(),
            messageText: message.getMessage(),
            sendDate: parseStringAsUTCDate(message.getSenddate()),
            fromUser: {
              userId: fromUser.getUserid(),
              firstName: fromUser.getFirstname(),
              lastName: fromUser.getLastname(),
              middleName: fromUser.getMiddlename() || "",
              company: {
                companyId: fromCompany.getCompanyid(),
                companyName: fromCompany.getCompanyname(),
              }
            },
            toUser: {
              userId: toUser.getUserid(),
              firstName: toUser.getFirstname(),
              lastName: toUser.getLastname(),
              middleName: toUser.getMiddlename() || "",
              company: {
                companyId: toCompany.getCompanyid(),
                companyName: toCompany.getCompanyname(),
              }
            },
          }

          state.chatMessagesTotalCount += 1;
          state.chatMessages.unshift(newMessage)
        }
      })

      state.isMessageSendLoading = false;
    }
  },
});

export const {
  getChatList,
  setChatListResult,

  getChatMessages,
  clearChatMessages,
  setChatMessagesResult,

  sendMessage,
  receiveMessages,
} =
  chatSlice.actions;
export default chatSlice.reducer;