import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {CustomDrawer} from "../../../../../../components/UI/AntCustom/CustomDrawer";
import styled from "styled-components";
import {clearEditUser, editUser} from "../../../../../../store/reducers/userReducer/userReducer";
import {Col, Form, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {DebounceSelect} from "../../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchTimezones,
  getInitialTimezoneForSelect
} from "../../../../../../utils/helpers/selectDataFetchers/fetchTimezones";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {clearEditCompany, editCompany} from "../../../../../../store/reducers/companyReducer/companyReducer";
import {
  fetchCurrencies,
  getInitialCurrencyForSelect
} from "../../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";

const UserSettingsTitle = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 32px;
`;


const UserSettingsDrawerMessages = defineMessages({
  currencyLabel: {
    id: "userSettingsDrawer.currencyLabel",
    defaultMessage: "Default currency",
  },
  success: {
    id: "userSettingsDrawer.success",
    defaultMessage: "User settings have been successfully applied.",
  },
  title: {
    id: "userSettingsDrawer.title",
    defaultMessage: "User settings",
  }
})

interface IUserSettingsFormData {
  timezoneId: number;
  currencyId: number;
}

interface IUserSettingsDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UserSettingsDrawer: FC<IUserSettingsDrawer> = ({isOpen, setIsOpen}) => {
  const user = useAppSelector(state => state.user.oneItemObject.item);
  const company = useAppSelector(state => state.company.oneItemObject.item);
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.user.edit)

  useEffect(() => {
    return () => {
      dispatch(clearEditUser());
      dispatch(clearEditCompany());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(UserSettingsDrawerMessages.success), () => setIsOpen(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IUserSettingsFormData) => {
    dispatch(editUser({
      userId: user.id,
      role: user.role,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      phone: user.phone,
      timezoneId: values.timezoneId,
    }))

    dispatch(editCompany({
      company: company,
      description: company.description,
      phone: company.phone,
      email: company.email,
      address: company.address,
      website: company.website,
      currencyId: values.currencyId,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IUserSettingsFormData = {
    timezoneId: user.timezone.id,
    currencyId: company.currency.id,
  }

  return (
    <CustomDrawer
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      footer={null}
    >
      <UserSettingsTitle>{intl.formatMessage(UserSettingsDrawerMessages.title)}</UserSettingsTitle>

      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.timezoneLabel)}
              name={'timezoneId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchTimezones}
                defaultOptions={[getInitialTimezoneForSelect(user.timezone)]}
                placeholder={intl.formatMessage(CommonFormMessages.timezonePlaceholder)}
                showSearch={true}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(UserSettingsDrawerMessages.currencyLabel)}
              name={'currencyId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchCurrencies}
                defaultOptions={[getInitialCurrencyForSelect(company.currency)]}
                placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
                showSearch={true}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput>
              <CustomButton
                block
                type={'primary'}
                htmlType={'submit'}
                loading={isLoading}
              >
                {intl.formatMessage(CommonButtonMessages.save)}
              </CustomButton>
            </CustomFieldInput>
          </Col>
        </Row>
      </Form>
    </CustomDrawer>
  );
};
