import {useEnumIntl} from "../../../intl/helpers/useEnumIntl";
import {defineMessages} from "react-intl";

// *** DO NOT CHANGE WITHOUT BACKEND DEVELOPERS AGREEMENT ***
export enum UserStatus {
  REGISTERED = 1,
  CONFIRMED = 2,
  REJECTED = 3,
  UNKNOWN = 4,
}

const UserStatusNameMessages = defineMessages({
  registered: {
    id: "statuses.userStatus.registered",
    defaultMessage: "Pending",
  },
  confirmed: {
    id: "statuses.userStatus.confirmed",
    defaultMessage: "Approved",
  },
  rejected: {
    id: "statuses.userStatus.rejected",
    defaultMessage: "Rejected",
  },
  unknown: {
    id: "statuses.userStatus.unknown",
    defaultMessage: "Not specified",
  },
})

export const useUserStatusName = (status: UserStatus) => {
  return useEnumIntl(status, {
    [UserStatus.REGISTERED]: UserStatusNameMessages.registered,
    [UserStatus.CONFIRMED]: UserStatusNameMessages.confirmed,
    [UserStatus.REJECTED]: UserStatusNameMessages.rejected,
    [UserStatus.UNKNOWN]: UserStatusNameMessages.unknown,
  });
}