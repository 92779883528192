import React, {FC, ReactNode} from 'react';
import {Card, CardProps} from "antd";
import styled from "styled-components";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

interface ICustomCardStyled {
  $width: 'fit' | 'max' | number;
  $isContainerCard: boolean;
  $titlePosition?: 'left' | 'center' | 'right' | 'space-between';
  $maxWidth?: number;
  $borderless: boolean;
  $boxShadow: boolean;
  $noBottomBorderRadius: boolean;
}

const CardStyled = styled(Card)<ICustomCardStyled>`
  width: ${props => (props.$width === 'max' && props.$noBottomBorderRadius && '100%')
    || (props.$width === 'max' && !props.$noBottomBorderRadius && '95%')
    || (props.$width === 'fit' && 'fit-content')
    || (props.$width)};
  display: flex;
  flex-direction: column;
  max-width: ${props => props.$maxWidth ? `${props.$maxWidth}px` : "none"};
  border: ${props => props.$borderless ? "1px solid #ffffff" : "1px solid #eeeeee"};
  border-radius: ${props => props.$noBottomBorderRadius ? "10px 10px 0 0" : "10px"};
  box-shadow: ${props => props.$boxShadow ? "0 4px 20px rgb(0 0 0 / 4%)" : "none"};
  
  .ant-card-head {
    border-bottom: 1px solid #f0f0f0;
    padding: 0 24px;
  }
  
  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 16px;
    padding: ${props => props.$isContainerCard ? "24px 24px 40px 24px" : "24px"};
    // overflow: scroll;
    
    :before, :after {
      display: none;
    }
  }
  
  .ant-card-head-wrapper {
    
    .ant-card-head-title {
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: ${props => (props.$titlePosition === 'left' && 'flex-start')
              || (props.$titlePosition === 'right' && 'flex-end')
              || (props.$titlePosition === 'space-between' && 'space-between')
              || (props.$titlePosition === 'center' && 'center')};
    }
  }

  @media(max-width: ${mediaScreenSizes.tablet}) {
    width: ${props => (props.$width === 'max' && props.$noBottomBorderRadius && '100%')
            || (props.$width === 'max' && !props.$noBottomBorderRadius && '100%')
            || (props.$width === 'fit' && 'fit-content')
            || (props.$width)};

    .ant-card-head {
      border-bottom: none;
      padding: 0 16px;
    }

    .ant-card-body {
      padding: ${props => props.$isContainerCard ? (props.title ? "0 16px 40px 16px" : "24px 16px 40px 16px") : "24px 16px"};
    }
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: ${props => (props.$width === 'max' && props.$noBottomBorderRadius && '100%')
            || (props.$width === 'max' && !props.$noBottomBorderRadius && '100%')
            || (props.$width === 'fit' && 'fit-content')
            || (props.$width)};

    .ant-card-head {
      border-bottom: none;
      padding: 0 16px;
    }
    
    .ant-card-body {
      padding: ${props => props.$isContainerCard ? (props.title ? "0 16px 40px 16px" : "24px 16px 40px 16px") : "24px 16px"};
    }
  }
`;

interface ICustomCard extends CardProps {
  width?: 'fit' | 'max' | number;
  isContainerCard?: boolean;
  titlePosition?: 'left' | 'center' | 'right' | 'space-between';
  maxWidth?: number;
  borderless?: boolean;
  noBottomBorderRadius?: boolean;
  boxShadow?: boolean;
  children: ReactNode;
}

export const CustomCard: FC<ICustomCard> = (
  {
    width = 'fit',
    isContainerCard = false,
    titlePosition = 'left',
    maxWidth,
    borderless = false,
    boxShadow = true,
    noBottomBorderRadius = false,
    children,
    ...props
  }) => {
  return (
    <CardStyled
      $width={width}
      $isContainerCard={isContainerCard}
      $titlePosition={titlePosition}
      $maxWidth={maxWidth}
      $borderless={borderless}
      $boxShadow={boxShadow}
      $noBottomBorderRadius={noBottomBorderRadius}
      {...props}
    >
      {children}
    </CardStyled>
  );
};
