import React, {Dispatch, FC, SetStateAction} from 'react';
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {FlightDrawerContentWrapper, FlightDrawerPhotosAndContentWrapper} from "./FlightDrawerStyledComponents";
import {PlanePhotos} from "../../../../pages/Lk/OperatorsOnly/Fleet/components/PlanePhotos";
import {FlightDrawerPlane} from "../plane/FlightDrawerPlane";
import {FlightDrawerOperator} from "../operator/FlightDrawerOperator";
import {IEmptyLeg} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {FlightDrawerEmptyLegDetails} from "../details/FlightDrawerEmptyLegDetails";
import {FlightDrawerEmptyLegFooter} from "../footers/FlightDrawerEmptyLegFooter";
import {FlightDrawerPrice} from "../price/FlightDrawerPrice";

interface IEmptyLegFlightDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  emptyLeg: IEmptyLeg;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsArchiveModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EmptyLegFlightDrawer: FC<IEmptyLegFlightDrawer> = ({
                                                                  isOpen,
                                                                  setIsOpen,
                                                                  emptyLeg,
                                                                  setIsEditModalOpen,
                                                                  setIsArchiveModalOpen,
                                                                }) => {
  const footer = !emptyLeg.isArchived
    ? <FlightDrawerEmptyLegFooter onEdit={() => setIsEditModalOpen(true)}
                                  onArchive={() => setIsArchiveModalOpen(true)}/>
    : null;

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer}>
      <FlightDrawerPhotosAndContentWrapper>
        <PlanePhotos imageFiles={emptyLeg.plane.imageFiles}/>

        <FlightDrawerContentWrapper>
          <FlightDrawerPlane plane={emptyLeg.plane}/>

          <FlightDrawerEmptyLegDetails emptyLeg={emptyLeg}/>

          <FlightDrawerPrice price={emptyLeg.price} currency={emptyLeg.currency} />

          <FlightDrawerOperator managerUser={emptyLeg.freighterUser}
                                company={emptyLeg.freighterCompany}/>
        </FlightDrawerContentWrapper>
      </FlightDrawerPhotosAndContentWrapper>
    </CustomDrawer>
  );
};