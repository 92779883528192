import React, {useContext} from 'react';
import {ReactComponent as BellIcon} from "../../../media/icons/bell.svg";
import {ReactComponent as BellNotifiedIcon} from "../../../media/icons/bell_notified.svg";
import styled from "styled-components";
import {NotificationsContext} from "../context/NotificationsProvider";
import {useAppSelector} from "../../../store/store";

const NotificationBellButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.2s ease;
  border: none;
  background: none;
  outline: none;

  :hover {
    opacity: 0.7;
  }

  :focus, :active {
    outline: none;
  }
`;

export const NotificationBell = () => {
  const hasNotifications = useAppSelector(state => state.notifications.itemsObject.items.some(n => !n.isRead));
  const {setIsWindowOpen} = useContext(NotificationsContext);

  return (
    <>
      <NotificationBellButton onClick={() => setIsWindowOpen(prevState => !prevState)}>
        {hasNotifications ? <BellNotifiedIcon/> : <BellIcon/>}
      </NotificationBellButton>
    </>
  );
};
