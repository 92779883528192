import {ScheduleEntity} from "../../../proto/generated/api_entities_pb";
import {IAirport} from "../fleetReducer/fleetReducerTypes";
import {isValidDate, parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {
  mapAirportEntityToIAirport,
  mapPlaneShortEntityToIPlaneShort
} from "../fleetReducer/fleetMappers";
import {IPlaneEvent} from "./scheduleReducerTypes";


export const mapScheduleEntityToIPlaneEvent = (item: ScheduleEntity): IPlaneEvent => {
  const airportFrom = item.getAirportfrom();
  if (!airportFrom)
    throw new Error("Null AirportEntity (airportFrom) in received ScheduleEntity")
  const airportFromMapped = mapAirportEntityToIAirport(airportFrom);

  const airportTo = item.getAirportto();
  let airportToMapped: IAirport | undefined;
  if (airportTo)
    airportToMapped = mapAirportEntityToIAirport(airportTo);

  const planeShort = item.getPlane();
  if (!planeShort)
    throw new Error("Null PlaneShortEntity (plane) in received ScheduleEntity")

  const dateFrom = parseStringAsUTCDate(item.getDatefrom());
  if (!isValidDate(dateFrom))
    throw new Error(`Invalid dateFrom in received ScheduleEntity, ${item.getDatefrom()}`);

  const dateTo = parseStringAsUTCDate(item.getDateto());
  if (!isValidDate(dateTo))
    throw new Error(`Invalid dateTo in received ScheduleEntity, ${item.getDateto()}`);


  return {
    id: item.getScheduleid(),
    eventType: item.getScheduletype(),
    eventName: item.getSchedulename(),
    plane: mapPlaneShortEntityToIPlaneShort(planeShort),
    dateFrom: dateFrom,
    dateTo: dateTo,
    airportFrom: airportFromMapped,
    airportTo: airportToMapped,
  }
};