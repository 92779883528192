import {Select, Spin} from 'antd';
import type {SelectProps} from 'antd/es/select';
import debounce from 'lodash/debounce';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";

type CustomSelectProps = SelectProps & { $brightPlaceholder: boolean };

const CustomSelect = styled(Select)<CustomSelectProps>`
  .ant-select-selection-placeholder {
    color: ${props => props.$brightPlaceholder ? "#000000" : "#bfbfbf"};
  }
`;


export interface DebounceSelectOptionProps {
  key?: number;
  label: string;
  value: string | number;
}

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions?: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  defaultOptions?: DebounceSelectOptionProps[];
  defaultOptionsFetchCallback?: (search?: string) => Promise<DebounceSelectOptionProps[] | undefined>
  brightPlaceholder?: boolean;
}

export function DebounceSelect<ValueType extends DebounceSelectOptionProps = any,
  >({
      fetchOptions,
      debounceTimeout = 1000,
      defaultOptions = [],
      defaultOptionsFetchCallback = undefined,
      brightPlaceholder = false,
      ...props
    }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [wasFetchedOnce, setWasFetchedOnce] = useState(false);
  const [options, setOptions] = useState<ValueType[]>(
    defaultOptions as ValueType[]
  );
  const fetchRef = useRef(0);

  useEffect(() => {
    fetchInitialOptions().then(res => {
      if (res) setOptions(res as ValueType[])
    })
  }, [defaultOptionsFetchCallback])

  const fetchInitialOptions = useCallback(async () => {
    if (defaultOptionsFetchCallback)
      return await defaultOptionsFetchCallback();
  }, [defaultOptionsFetchCallback])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions && fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onFocus = () => {
    if (wasFetchedOnce) {
      return;
    }
    // search all
    debounceFetcher("")
    setWasFetchedOnce(true);
  }

  return (
    <CustomSelect
      // @ts-ignore
      filterOption={false}
      onSearch={fetchOptions && debounceFetcher}
      showSearch={true}
      onFocus={fetchOptions && onFocus}
      loading={fetching}
      notFoundContent={fetching ? <Spin size="small"/> : null}
      options={options}
      allowClear={true}
      dropdownStyle={{borderRadius: 5}}
      placement={"bottomLeft"}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      $brightPlaceholder={brightPlaceholder}
      {...props}
    />
  );
}
