import React, {Dispatch, FC, SetStateAction} from 'react';
import {Button, Card, Col, Modal} from "antd";
import {IClient} from "../../../../../store/reducers/requestCallbackReducer/requestCallbackReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";


const ClientDetailedInformationModalMessages = defineMessages({
  clientFullName: {
    id: "clientDetailedInformationModal.clientFullName",
    defaultMessage: "Client full name",
  },
  phone: {
    id: "clientDetailedInformationModal.phone",
    defaultMessage: "Client phone number",
  },
  comment: {
    id: "clientDetailedInformationModal.comment",
    defaultMessage: "Comment",
  },
})

interface IClientDetailedInformationModal {
  client: IClient,
  isModalVisible: boolean,
  setIsModalVisible: Dispatch<SetStateAction<boolean>>,
}

export const ClientDetailedInformationModal: FC<IClientDetailedInformationModal> = ({
                                                                                      client,
                                                                                      isModalVisible,
                                                                                      setIsModalVisible
                                                                                    }) => {
  const intl = useIntl();
  let fio = `${client.lastName} ${client.firstName} ${client.middleName}`;
  return (
    <Modal
      title={'Заявка от клиента'}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      footer={false}
      centered={true}
    >
      <Col key={client.phone}>
        <Card>
          <Card.Meta
            title={fio}
            description={<div>
              <div>{intl.formatMessage(ClientDetailedInformationModalMessages.clientFullName)}: {fio}</div>
              <div>{intl.formatMessage(ClientDetailedInformationModalMessages.phone)}: {client.phone} </div>
              <div>{intl.formatMessage(ClientDetailedInformationModalMessages.comment)}: {client.message}</div>
            </div>}
          />
        </Card>
        <Button
          style={{marginTop: '10px'}}
          onClick={() => setIsModalVisible(false)}
          type={'primary'}
          block
        >
          {intl.formatMessage(CommonButtonMessages.close)}
        </Button>
      </Col>
    </Modal>
  );
};

