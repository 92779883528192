//calculates flight time, distance and routes
import {AirportEntity, FlyApiFlyRouteDetailRequest, PlaneEntity} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {message} from "antd/es";
import {Logger} from "../../logger/Logger";
import {getErrorMessage} from "../../errorHelpers/getCommonErrorMessage";
import {defineMessages, IntlShape} from "react-intl";

const CalculateFlightMessages = defineMessages({
  rejected: {
    id: "helpers.calculateFlight.rejected",
    defaultMessage: "Data about flight duration for the chosen airport and aircraft is not available at the moment."
  },
  success: {
    id: "helpers.calculateFlight.success",
    defaultMessage: "Data about flight duration and range were successfully received!",
  },
  rejectedTimeAndDistance: {
    id: "helpers.calculateFlight.rejectedTimeAndDistance",
    defaultMessage: "Flight duration and range can't be calculated. Please, try again or fill in the data manually"
  },
  loading: {
    id: "helpers.calculateFlight.loading",
    defaultMessage: "The flight duration and range are being calculated at the moment"
  }
})

export async function CalculateFlight(airportFromId: number,
                                      airportToId: number,
                                      planeId: number,
                                      intl: IntlShape,
) {
  let hours: number = 0;
  let minutes: number = 0;
  let distance: number = 0;
  let techStopHours: number = 0;
  let techStopMinutes: number = 0;
  let isAutoCalculationApplied: boolean = false;

  try {
    const hideLoading = message.loading(intl.formatMessage(CalculateFlightMessages.loading), 0);

    const req = new FlyApiFlyRouteDetailRequest()
      .setAirportfrom(new AirportEntity().setAirportid(airportFromId))
      .setAirportto(new AirportEntity().setAirportid(airportToId))
      .setPlane(new PlaneEntity().setPlaneid(planeId));

    const res = await API.getFlyRouteDetail(req);
    hideLoading();

    if (res.getResultresponse()?.getIssuccessful()) {
      const flyTotalMinutes = res.getFlyduration();
      const techStopTotalMinutes = res.getTechnicalstopduration();
      const isThereFlyDuration = flyTotalMinutes > 0;
      distance = res.getFlydistance();
      hours = Math.floor(flyTotalMinutes / 60);
      minutes = flyTotalMinutes % 60;
      techStopHours = Math.floor(techStopTotalMinutes / 60);
      techStopMinutes = techStopTotalMinutes % 60;
      isAutoCalculationApplied = true;

      if (!isThereFlyDuration)
        message.warn(intl.formatMessage(CalculateFlightMessages.rejected), 6);
      else
        message.success(intl.formatMessage(CalculateFlightMessages.success), 4);

    } else {
      message.error(intl.formatMessage(CalculateFlightMessages.rejectedTimeAndDistance));
      isAutoCalculationApplied = false;
    }
  } catch (e) {
    Logger.error(getErrorMessage(e));
  }

  return {hours, minutes, distance, techStopHours, techStopMinutes, isAutoCalculationApplied}
}