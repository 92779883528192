import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {rootReducer} from "./reducers/rootReducer";
import {rootSaga} from "./sagas/rootSaga";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;