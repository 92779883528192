import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {MyRequestsPage} from "../../../../../pages/Lk/BrokersOnly/MyRequests/MyRequestPage";
import {groupCharterer} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const RequestsRouteTitleMessage = defineMessage({
  id: "requestsRoute.title",
  defaultMessage: "My requests",
})

export const requests: RouteDescriptor<Route.requests> = {
  route: Route.requests,
  render: MyRequestsPage,
  title: RequestsRouteTitleMessage,
  path: '/lk/my-requests',
  allowRoles: [
    ...groupCharterer,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}