import {IWebsocketLoggerData, Logger} from "../../../utils/logger/Logger";
import {emitter} from "../../../utils/emitter/EventEmitter";
import {store} from "../../store";
import {CommunicationWebsocketMessage, WebsocketMessageType} from "../../../proto/generated/api_entities_pb";
import {ISendMessagePayloadType} from "./payloadType";
import {WsActionType} from "./WsActionType";
import {WEBSOCKET_LOGS} from "../../../config/loggerConfig";

export class WebsocketController {
  socket: WebSocket;
  API_URL: string;
  onOpen?: () => any;
  onClose?: () => any;
  onMessage?: (event: MessageEvent) => any;
  static messageIdCounter: number = 1;


  constructor(WEBSOCKET_API_URL?: string) {
    this.API_URL = WEBSOCKET_API_URL || 'ws://websocketReducer.avianode:1337/broadcast';
    this.socket = new WebSocket(this.API_URL, );
  }


  configure(onOpen: () => any, onClose: () => any, onMessage: (event: MessageEvent) => any) {
    this.onOpen = onOpen;
    this.onClose = onClose;
    this.onMessage = onMessage;

    this.socket.binaryType = "arraybuffer";

    this.socket.onopen = () => {
      onOpen();
      WEBSOCKET_LOGS && Logger.info('Websocket is connected', 'green')
    }

    this.socket.onclose = () => {
      onClose();
      WEBSOCKET_LOGS && Logger.error('Websocket is disconnected')
    }

    this.socket.onmessage = (event: MessageEvent) => {
      onMessage(event);
    }
  }


  static sendResponse(messageType: WebsocketMessageType, id: number, data: object) {
    try {
      // @ts-ignore
      const encodedData = data.serializeBinary();

      const payload: ISendMessagePayloadType = {
        messageId: id,
        messageType: messageType,
        accessToken: localStorage.getItem('accessToken') || "",
        data: encodedData
      }

      store.dispatch({
        type: WsActionType.WS_SEND_MESSAGE,
        payload: payload
      })
    } catch (e) {
      WEBSOCKET_LOGS && Logger.error(`Message send response parsing error. Method: ${WebsocketMessageType[messageType]}, data: ${JSON.stringify(data, null, 4)}`)
    }
  }


  static sendRequest(messageType: WebsocketMessageType, messageData: object) {
    return new Promise((resolve: (message: CommunicationWebsocketMessage) => void,
                        reject: (message: CommunicationWebsocketMessage) => void) => {
      let encodedData: Uint8Array | undefined;
      // With the help of Protobuf class we encode data from properties
      try {
        // @ts-ignore
        encodedData = messageData.serializeBinary();
      } catch (e) {
        WEBSOCKET_LOGS && Logger.error(`Message parsing error. Method: ${WebsocketMessageType[messageType]}, data: ${JSON.stringify(messageData, null, 4)}`)
      }

      // set id from params OR create uint32 array from counter's value
      const id = new Uint32Array([WebsocketController.messageIdCounter++])[0];

      emitter.once(id, (message: CommunicationWebsocketMessage) => {
        if (message && message.getMessagetype() === WebsocketMessageType.ERROR_MESSAGE) {
          reject(message)
        } else {
          resolve(message)
        }
      })

      const payload: ISendMessagePayloadType = {
        messageId: id,
        messageType: messageType,
        accessToken: localStorage.getItem('accessToken') || "",
        data: encodedData! //If encodedData is undefined, promise would be rejected earlier
      }

      store.dispatch({
        type: WsActionType.WS_SEND_MESSAGE,
        payload: payload
      })
    })
  }


  send(payload: ISendMessagePayloadType) {
    // create the core message and add related fields
    const coreMessage: CommunicationWebsocketMessage = new CommunicationWebsocketMessage()
      .setMessageid(payload.messageId)
      .setMessagetype(payload.messageType)
      .setAccesstoken(payload.accessToken)
      .setData(payload.data);

    // send serialized message to the server
    this.socket.send(coreMessage.serializeBinary());

    const loggerData: IWebsocketLoggerData = {
      messageId: coreMessage.getMessageid(),
      accessToken: coreMessage.getAccesstoken(),
      messageType: WebsocketMessageType[coreMessage.getMessagetype()],
      data: payload.data || "Nothing",
    }

    WEBSOCKET_LOGS && Logger.log(`[SENT] ${WebsocketMessageType[coreMessage.getMessagetype()]}`, loggerData, 'blue');
  }


  reconnect() {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.close();
    }

    this.socket = new WebSocket(this.API_URL);
    WebsocketController.messageIdCounter = 1;

    if (this.onOpen && this.onClose && this.onMessage)
      this.configure(this.onOpen, this.onClose, this.onMessage);
  }


  close() {
    this.socket.close();
  }
}
