//calculates flight cost based on plane and duration
import {
  FlyApiFlyCostRequest,
  PlaneEntity
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {message} from "antd/es";
import {Logger} from "../../logger/Logger";
import {getErrorMessage} from "../../errorHelpers/getCommonErrorMessage";
import {defineMessages, IntlShape} from "react-intl";

const CalculatePriceMessages = defineMessages({
  rejected: {
    id: "helpers.calculatePrice.rejected",
    defaultMessage: "Flight cost data is not available at the moment."
  },
  success: {
    id: "helpers.calculatePrice.success",
    defaultMessage: "Flight cost data was successfully received!",
  },
  loading: {
    id: "helpers.calculatePrice.loading",
    defaultMessage: "The flight cost is being calculated at the moment"
  }
})

export async function CalculatePrice(planeId: number,
                                     durationInMinutes: number,
                                     intl: IntlShape,
) {
  let price: number | undefined = undefined;
  let currencyId: number | undefined = undefined;
  let isAutoCalculationDisabled: boolean = false;
  let isAutoCalculationApplied: boolean = false;

  try {
      const hideLoading = message.loading(intl.formatMessage(CalculatePriceMessages.loading), 0);

      const req = new FlyApiFlyCostRequest()
        .setFlyduration(durationInMinutes)
        .setPlaneentity(new PlaneEntity().setPlaneid(planeId));

      const res = await API.getFlyCost(req);
      hideLoading();

      if (res.getResultresponse()?.getIssuccessful()) {
        price = res.getCost();
        currencyId = res.getCurrency()?.getCurrencyid();
        isAutoCalculationApplied = true;

        message.success(intl.formatMessage(CalculatePriceMessages.success), 4);
      } else {
        message.error(intl.formatMessage(CalculatePriceMessages.rejected));
      }
  } catch (e) {
    Logger.error(getErrorMessage(e));
  }

  return {price, currencyId, isAutoCalculationDisabled, isAutoCalculationApplied}
}