import {FC} from "react";
import {InputNumber, InputNumberProps} from "antd";

interface ICustomInputNumber extends InputNumberProps{

}

export const CustomInputNumber: FC<ICustomInputNumber> = (props) => {
  return <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      {...props} />
}