import {all, call, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  checkConfirmationCode,
  forgotPassword,
  login,
  register,
  setIsConfirmationCodeValid,
  setIsForgotPasswordResult,
  setLoginResult,
  setNewPassword,
  setNewPasswordResult,
  setRegisterResult
} from "../../reducers/authReducer/authReducer";
import {API} from "../../../API/API";
import {sha256} from "js-sha256";
import {
  AuthApiForgotPasswordCheckCodeRequest,
  AuthApiForgotPasswordRequest,
  AuthApiForgotPasswordSetupRequest,
  AuthApiRegistrationRequest,
  AuthApiRequest,
  AuthApiResponse,
  ResultResponse
} from "../../../proto/generated/api_entities_pb";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {Role} from "../../../config/acl/roles/Role";
import {backendIdentifierFromRole, roleFromBackendIdentifier} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {
  IAuthCheckConfirmationCode,
  IAuthForgotPasswordPayload,
  IAuthLoginPayload,
  IAuthRegisterPayload, IAuthSetNewPasswordPayload
} from "../../reducers/authReducer/authReducerTypes";
import {setUserResult} from "../../reducers/userReducer/userReducer";
import {setCompanyResult} from "../../reducers/companyReducer/companyReducer";
import {metricsGoals} from "../../../utils/constans/metrics/goals";
import {groupCharterer, groupFreighter} from "../../../config/acl/roles/groups";


function* loginSaga({payload}: PayloadAction<IAuthLoginPayload>, afterRegistration: boolean = false) {
  const hashedPassword: string = sha256(payload.password);

  const loginReq = new AuthApiRequest()
    .setLogin(payload.login)
    .setPassword(hashedPassword);

  try {
    const loginRes: AuthApiResponse = yield call(API.login, loginReq);

    const user = loginRes.getUser();
    const company = user?.getCompany();
    const isSuccess = loginRes.getResultresponse()?.getIssuccessful();
    if (isSuccess && user && company) {
      const role = roleFromBackendIdentifier(loginRes.getRole());

      yield put(setLoginResult({
        isAuthenticated: true,
        role: role,
        isLoginSuccess: true,
        loginErrorMessage: '',
        accessToken: loginRes.getAccesstoken(),
        refreshToken: loginRes.getRefreshtoken(),
      }));
      yield put(setUserResult({
        item: user,
      }));
      yield put(setCompanyResult({
        item: company,
      }));

      groupFreighter.includes(role) && metricsGoals.operatorLoggedIn();
      groupCharterer.includes(role) && metricsGoals.brokerLoggedIn();

      if (afterRegistration) {
        yield put(setRegisterResult({
          isRegisterSuccess: true,
          registerErrorMessage: '',
        }));
      }
    } else {
      yield put(setLoginResult({
        isAuthenticated: false,
        role: Role.guest,
        isLoginSuccess: false,
        loginErrorMessage: getErrorMessageByCode(loginRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {}
}


function* registerSaga({payload}: PayloadAction<IAuthRegisterPayload>) {
  const hashedPassword: string = sha256(payload.password);
  const hashedConfirmPassword: string = sha256(payload.confirmPassword);
  const role = backendIdentifierFromRole(payload.companyRole);

  const registerReq = new AuthApiRegistrationRequest()
    .setFirstname(payload.firstName)
    .setLastname(payload.lastName)
    .setMiddlename(payload.middleName)
    .setCompanyname(payload.companyName)
    .setRole(role)
    .setEmail(payload.email)
    .setPassword(hashedPassword)
    .setRepeatpassword(hashedConfirmPassword);

  payload.phone && registerReq.setPhone(payload.phone)

  try {
    const registerRes: ResultResponse = yield call(API.register, registerReq);
    metricsGoals.registerFormRequestSent()

    if (registerRes.getIssuccessful()) {
      groupFreighter.includes(payload.companyRole) && metricsGoals.operatorRegistered();
      groupCharterer.includes(payload.companyRole) && metricsGoals.brokerRegistered();
      yield call(loginSaga, {payload: {login: payload.email, password: payload.password}, type: login.type}, true);
    }
    else
      yield put(setRegisterResult({
        isRegisterSuccess: false,
        registerErrorMessage: getErrorMessageByCode(registerRes.getErrormessage()),
      }));
  } catch {}
}


function* forgotPasswordSaga({payload}: PayloadAction<IAuthForgotPasswordPayload>) {
  const forgotPasswordReq = new AuthApiForgotPasswordRequest()
    .setEmail(payload.email);

  try {
    const forgotPasswordRes: ResultResponse = yield call(API.forgotPassword, forgotPasswordReq);

    if (forgotPasswordRes.getIssuccessful())
      yield put(setIsForgotPasswordResult({
        isForgotPasswordSuccess: true,
        forgotPasswordErrorMessage: '',
      }))
    else
      yield put(setIsForgotPasswordResult({
        isForgotPasswordSuccess: false,
        forgotPasswordErrorMessage: getErrorMessageByCode(forgotPasswordRes.getErrormessage()),
      }))
  } catch {}
}


function* checkConfirmationCodeSaga({payload}: PayloadAction<IAuthCheckConfirmationCode>) {
  const confirmationCodeReq = new AuthApiForgotPasswordCheckCodeRequest()
    .setCode(payload.confirmationCode)

  try {
    const confirmationCodeRes: ResultResponse = yield call(API.checkConfirmationCode, confirmationCodeReq)

    if (confirmationCodeRes.getIssuccessful())
      yield put(setIsConfirmationCodeValid({
        isValid: true,

      }));
    else
      yield put(setIsConfirmationCodeValid({
        isValid: false,
      }));
  } catch {}
}


function* setNewPasswordSaga({payload}: PayloadAction<IAuthSetNewPasswordPayload>) {
  const hashedPassword: string = sha256(payload.password);

  const setNewPasswordReq = new AuthApiForgotPasswordSetupRequest()
    .setPassword(hashedPassword)
    .setRepeatpassword(hashedPassword)
    .setCode(payload.confirmationCode)

  try {
    const setNewPasswordRes: ResultResponse = yield call(API.setNewPassword, setNewPasswordReq);

    if (setNewPasswordRes.getIssuccessful())
      yield put(setNewPasswordResult({
        isSetNewPasswordSuccess: true,
        setNewPasswordErrorMessage: '',
      }));
    else
      yield put(setNewPasswordResult({
        isSetNewPasswordSuccess: false,
        setNewPasswordErrorMessage: getErrorMessageByCode(setNewPasswordRes.getErrormessage()),
      }));
  } catch {}
}


export function* authSaga() {
  yield all([
    takeLatest(login.type, loginSaga),
    takeLatest(register.type, registerSaga),
    takeLatest(forgotPassword.type, forgotPasswordSaga),
    takeLatest(checkConfirmationCode.type, checkConfirmationCodeSaga),
    takeLatest(setNewPassword.type, setNewPasswordSaga),
  ])
}