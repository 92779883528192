import React, {FC, useEffect} from 'react';
import styled from "styled-components";
import {OrderRequestStatusEnum} from "../../../../utils/constans/statuses/orderRequestStatuses";
import {
  EmptyLegOrderRouteStatusEnum, useEmptyLegOrderRouteStatusName,
} from "../../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {useAppDispatch} from "../../../../store/store";
import {
  changeEmptyLegRespondStatus,
  clearChangeEmptyLegRespondStatus
} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {IEmptyLeg, IEmptyLegOrder} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {CustomLinkButton} from "../../../UI/Buttons/CustomLinkButton";
import {ReactComponent as ReturnIcon} from "../../../../media/icons/return.svg";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";
import {useIntl} from "react-intl";
import {FlightDrawerFooterContent} from "../../flightDrawer/footers/FlightDrawerFooterStyledComponents";

export const BrokerDrawerFooterStatus = styled.div<{ status: OrderRequestStatusEnum | EmptyLegOrderRouteStatusEnum }>`
  padding: 2px 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: ${props => (props.status === EmptyLegOrderRouteStatusEnum.ACTIVE && "#CDDDE6")
          || (props.status === EmptyLegOrderRouteStatusEnum.APPROVED && "#D6E6CD")
          || (props.status === EmptyLegOrderRouteStatusEnum.WITHDRAWN && "#F9D99B")
          || (props.status === EmptyLegOrderRouteStatusEnum.DECLINED && "#E7C4C4")};
`;

export const BrokerDrawerFooterProcessButtons = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-direction: row;
  column-gap: 10px;

  button {
    border: none;
    color: #FFFFFF;

    :nth-child(1) {
      background: #C05353;
    }

    :nth-child(2) {
      background: #63A070;
    }

    :hover, :active, :focus {
      color: #FFFFFF;
      opacity: 0.8;
    }
  }
`;

interface IBrokerDrawerEmptyLegRespondFooter {
  emptyLeg: IEmptyLeg;
  emptyLegOrder: IEmptyLegOrder;
}

export const BrokerDrawerEmptyLegRespondFooter: FC<IBrokerDrawerEmptyLegRespondFooter> = ({emptyLeg, emptyLegOrder}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const emptyLegOrderRouteStatusName = useEmptyLegOrderRouteStatusName(emptyLegOrder.status);

  useEffect(() => {
    return () => {
      dispatch(clearChangeEmptyLegRespondStatus());
    }
  }, [])

  const onStatusChange = (newStatus: EmptyLegOrderRouteStatusEnum) => {
    dispatch(changeEmptyLegRespondStatus({
      emptyLegId: emptyLeg.id,
      orderRouteId: emptyLegOrder.orderRouteId,
      status: newStatus,
    }))
  }

  if (!emptyLeg.isArchived && emptyLegOrder.status === EmptyLegOrderRouteStatusEnum.ACTIVE)
    return <FlightDrawerFooterContent>
      <BrokerDrawerFooterProcessButtons>
        <CustomButton heightSize={'m'}
                      onClick={() => onStatusChange(EmptyLegOrderRouteStatusEnum.DECLINED)}>
          {intl.formatMessage(CommonButtonMessages.reject)}
        </CustomButton>

        <CustomButton heightSize={'m'}
                      onClick={() => onStatusChange(EmptyLegOrderRouteStatusEnum.APPROVED)}>
          {intl.formatMessage(CommonButtonMessages.accept)}
        </CustomButton>
      </BrokerDrawerFooterProcessButtons>
    </FlightDrawerFooterContent>

  return (
    <FlightDrawerFooterContent>
      {!emptyLeg.isArchived && emptyLegOrder.status !== EmptyLegOrderRouteStatusEnum.WITHDRAWN &&
        <CustomLinkButton icon={<ReturnIcon/>} onClick={() => onStatusChange(EmptyLegOrderRouteStatusEnum.ACTIVE)}>
          <div>{intl.formatMessage(CommonButtonMessages.return)}</div>
        </CustomLinkButton>}

      <BrokerDrawerFooterStatus status={emptyLegOrder.status}>
        {emptyLegOrderRouteStatusName}
      </BrokerDrawerFooterStatus>
    </FlightDrawerFooterContent>
  );
};
