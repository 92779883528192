import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initCommonClearList, initCommonGetList, initCommonSetListResult} from "../highOrderReducers/commonReducer";
import {
  IGetNotificationListPayload,
  INotificationInitialState, IReadNotificationsPayload, ISetLastNotificationPayload,
  ISetNotificationListResultPayload,
} from "./notificationsReducerTypes";
import {mapNotificationEntityToINotification} from "./notificationsMappers";


const notificationsState: INotificationInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  newPushesReceived: false,
  lastReceivedPushesCount: 0,
  unreadOnlyNotifications: [],
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsState,
  reducers: {
    getNotificationList(state, {payload}: PayloadAction<IGetNotificationListPayload>) {
      initCommonGetList(state, payload);
    },
    clearNotificationList(state) {
      initCommonClearList(state);
    },
    setNotificationListResult(state, {payload}: PayloadAction<ISetNotificationListResultPayload>) {
      initCommonSetListResult(state, payload, mapNotificationEntityToINotification);
    },
    setUnreadOnlyNotificationsListResult(state, {payload}: PayloadAction<ISetNotificationListResultPayload>) {
      state.unreadOnlyNotifications = payload.items.map(mapNotificationEntityToINotification);
    },

    readNotifications(state, {payload}: PayloadAction<IReadNotificationsPayload>) {
      const notificationIds = payload.notificationIds;
      if (notificationIds) {
        state.itemsObject.items = state.itemsObject.items.map(n => {
          if (notificationIds.includes(n.id))
            return {...n, isRead: true}
          return n;
        });

        state.unreadOnlyNotifications = state.unreadOnlyNotifications.filter(n => !notificationIds.includes(n.id));
      }
    },

    setLastNotification(state, {payload}: PayloadAction<ISetLastNotificationPayload>) {
      const notifications = payload.items.map(mapNotificationEntityToINotification);
      state.itemsObject.items.unshift(...notifications);
      state.itemsObject.totalCount++;
      state.lastReceivedPushesCount = notifications.length;
      state.newPushesReceived = true;
      state.unreadOnlyNotifications.push(...notifications);
    }
  },
});

export const {
  getNotificationList,
  clearNotificationList,
  setNotificationListResult,
  setUnreadOnlyNotificationsListResult,

  readNotifications,

  setLastNotification,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;