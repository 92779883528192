import React, {FC, ReactNode} from 'react';
import {Drawer, DrawerProps} from "antd";
import styled from "styled-components";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import {useIsMobile} from "../../../hooks/useWindowSize";
import {CustomButton} from "./CustomButton";
import {ReactComponent as CaretLeftIcon} from "../../../media/icons/caret_left.svg";
import {useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../intl/commonMessages/CommonButtonMessages";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-header, .ant-drawer-header-close-only {
    padding: 0;
  }

  .ant-drawer-header-title {
    height: 0;
    position: relative;
    justify-content: flex-end;
  }

  .ant-drawer-body {
    padding: 33px 90px 24px 51px;
  }

  .ant-drawer-close {
    position: absolute;
    margin-right: 0;
    right: 36px;
    top: 33px;
  }

  .ant-drawer-footer {
    display: flex;
    align-items: center;
    padding: 0 90px 0 50px;
    height: 83px;
    box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.07);
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    z-index: 998 !important;
    margin-top: 68px;

    .ant-drawer-content {
      background: #F3F5F7;
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    
    .ant-drawer-right.ant-drawer-open, .ant-drawer-content-wrapper {
      box-shadow: none !important;
    }

    .ant-drawer-wrapper-body {
      max-height: calc(100vh - 70px);
      max-height: calc(var(--app-height) - 70px);
    }
    
    .ant-drawer-body {
      padding: 37px 16px 40px 16px;
      max-height: calc(100vh - 70px);
      max-height: calc(var(--app-height) - 70px);
    }

    .ant-drawer-close {
      right: unset;
      left: 16px;
      top: 0;
      padding: 0;
    }

    .ant-drawer-footer {
      padding: 0 16px;
    }
  }
`;

const CaretLeftIconStyled = styled(CaretLeftIcon)`
  path {
    fill: #1890FF;
  }
`;

interface ICustomDrawer extends DrawerProps {
  contentMaxWidth?: number;
  contentMinWidth?: number;
  children: ReactNode;
}

export const CustomDrawer: FC<ICustomDrawer> = ({contentMaxWidth, contentMinWidth, children, ...props}) => {
  const isMobile = useIsMobile();
  const intl = useIntl();

  const customBackButton = <CustomButton type={'link'} heightSize={'m'} style={{
    width: "calc(100vw - 32px)",
    justifyContent: "flex-start",
    padding: 0,
    zIndex: 999,
    background: "#F3F5F7",
    borderRadius: 0,
  }}>
    <CaretLeftIconStyled/>
    <div>{intl.formatMessage(CommonButtonMessages.back)}</div>
  </CustomButton>

  return (
    <DrawerStyled {...props} contentWrapperStyle={{
      maxWidth: contentMaxWidth ?? "100%",
      minWidth: contentMinWidth ?? "0",
      width: "unset"
    }} closeIcon={isMobile ? customBackButton : undefined}>
      {children}
    </DrawerStyled>
  );
};
