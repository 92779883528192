import React, {useEffect, useState} from 'react';
import {Col, Form, Input, message, Modal} from "antd";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {useNavigate} from "react-router";
import {
  checkConfirmationCode,
  clearSetNewPassword,
  setNewPassword
} from "../../../store/reducers/authReducer/authReducer";
import {useSearchParams} from 'react-router-dom';
import {Loader} from "../../../components/UI/Loader/Loader";
import {CustomCard} from "../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../components/UI/PageWrapper/PageWrapper";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../config/acl/routes/Route";
import {renderSuccessModal} from "../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../components/UI/AntCustom/CustomButton";
import {PasswordRegex} from "../../../utils/helpers/regexes/passwordRegex";


const SetNewPasswordPageMessages = defineMessages({
  success: {
    id: "setNewPasswordPage.success",
    defaultMessage: "Your password has been successfully updated",
  },
  outdated: {
    id: "setNewPasswordPage.outdated",
    defaultMessage: "Your link is out of date",
  },
  title: {
    id: "setNewPasswordPage.title",
    defaultMessage: "Reset password",
  },
  changePassword: {
    id: "setNewPasswordPage.changePassword",
    defaultMessage: "Change password",
  },
})

interface ISetNewPasswordFormData {
  password: string,
  confirmPassword: string,
}

export const SetNewPasswordPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    isConfirmationCodeValid,
    isConfirmationCodeLoading,
    isSetNewPasswordLoading,
    isSetNewPasswordSuccess,
    setNewPasswordErrorMessage
  } = useAppSelector(state => state.auth);
  const [code, setCode] = useState<string>("");

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      setCode(code);
      dispatch(checkConfirmationCode({confirmationCode: code}))
    } else {
      renderErrorModal()
    }

    return () => {
      dispatch(clearSetNewPassword());
    }
  }, [])

  useEffect(() => {
    if (!isConfirmationCodeLoading && !isConfirmationCodeValid) {
      renderErrorModal()
    }
  }, [isConfirmationCodeLoading])

  useEffect(() => {
    if (!isSetNewPasswordLoading) {
      if (isSetNewPasswordSuccess)
        renderSuccessModal(intl.formatMessage(SetNewPasswordPageMessages.success), () => navigate(pathFromRoute(Route.login), {replace: true}));
      if (setNewPasswordErrorMessage)
        message.error(setNewPasswordErrorMessage);
    }
  }, [isSetNewPasswordSuccess, isSetNewPasswordLoading, setNewPasswordErrorMessage])

  const renderErrorModal = () => {
    Modal.error({
      content: intl.formatMessage(SetNewPasswordPageMessages.outdated),
      centered: true,
      onOk: () => navigate(pathFromRoute(Route.home), {replace: true})
    })
  }

  const onFinish = (values: ISetNewPasswordFormData) => {
    dispatch(setNewPassword({
      password: values.password,
      confirmationCode: code,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: ISetNewPasswordFormData = {
    password: '',
    confirmPassword: '',
  }

  if (isConfirmationCodeLoading || !isConfirmationCodeValid) {
    return <Loader/>
  }
  return (
    <PageWrapper justifyContent={'center'}>
      <CustomCard title={intl.formatMessage(SetNewPasswordPageMessages.title)} width={'max'} isContainerCard={true}
                  maxWidth={400}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout={'vertical'}
          initialValues={initialValues}
        >
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.passwordLabel)}
              name={'password'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  pattern: PasswordRegex.pattern,
                  message: PasswordRegex.error(
                    intl.formatMessage(CommonFormMessages.rulePasswordValidationTitle),
                    [
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule1),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule2),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule3),
                    ]
                  ),
                }]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.passwordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.repeatPasswordLabel)}
              name={'repeatPassword'}
              dependencies={['password']}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(intl.formatMessage(CommonFormMessages.passwordsMismatch)));
                  },
                })
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.repeatPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <CustomFieldInput>
            <CustomButton
              block
              heightSize={'m'}
              type={'primary'}
              htmlType={'submit'}
              loading={isSetNewPasswordLoading}
            >
              {intl.formatMessage(SetNewPasswordPageMessages.changePassword)}
            </CustomButton>
          </CustomFieldInput>

        </Form>

      </CustomCard>
    </PageWrapper>
  );
};

