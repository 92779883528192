import React, {ReactNode, useEffect, useState} from 'react';
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {OrderRequestsTabPane} from "./components/OrderRequestsTabPane";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import Button from "antd/es/button/button";
import {Badge, message} from "antd";
import {OrderRequestsFilterModal} from "./components/OrderRequestsFilterModal";
import {ReactComponent as FilterIcon} from "../../../../media/icons/filter.svg";
import {
  clearArchiveOrderRequest,
  clearChangeOrderRequestWithdraw
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducer";
import {renderSuccessModal} from "../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {readNotifications} from "../../../../store/reducers/notificationsReducer/notificationsReducer";
import {NotificationType} from "../../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {useSearchParams} from "react-router-dom";
import {OrderTypeEnum} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {CustomSelect} from "../../../../components/UI/AntCustom/CustomSelect";
import {useIsMobile} from "../../../../hooks/useWindowSize";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";


const RequestFromAgentsPageMessages = defineMessages({
  active: {
    id: "requestFromAgentsPage.active",
    defaultMessage: "Active",
  },
  archival: {
    id: "requestFromAgentsPage.archival",
    defaultMessage: "Archive",
  },
  changeRespondStatusSuccess: {
    id: "requestFromAgentsPage.changeRespondStatusSuccess",
    defaultMessage: "Respond status has been successfully updated.",
  },
  archiveRespondSuccess: {
    id: "requestFromAgentsPage.archiveRespondSuccess",
    defaultMessage: "The respond has been successfully archived.",
  },
  title: {
    id: "requestFromAgentsPage.title",
    defaultMessage: "My offers",
  },
})

export interface IOrderRequestFilters {
  airportFromId?: number;
  airportToId?: number;
  minPax?: number;
  maxPax?: number;
  companionsAllowed?: boolean;
  isEmptyLegUsable?: boolean;
  planeCategoryIds?: number[];
}

enum RequestFromAgentsTabKeysEnum {
  ACTIVE = 'active',
  ARCHIVAL = 'archival',
}

interface IContentList {
  active: ReactNode;
  archival: ReactNode;
}

export const RequestFromAgentsPage = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const [orderFilterTypes, setOrderFilterTypes] = useState<OrderTypeEnum[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<RequestFromAgentsTabKeysEnum>(searchParams.get('archived') ? RequestFromAgentsTabKeysEnum.ARCHIVAL : RequestFromAgentsTabKeysEnum.ACTIVE);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IOrderRequestFilters>({})
  const {
    isLoading: isChangeStatusLoading,
    isSuccess: isChangeStatusSuccess,
    errorMessage: changeStatusErrorMessage,
  } = useAppSelector(state => state.freighterRequests.changeOrderRequestWithdraw);
  const {
    isLoading: isArchiveLoading,
    isSuccess: isArchiveSuccess,
    errorMessage: archivesErrorMessage,
  } = useAppSelector(state => state.freighterRequests.archiveOrderRequest);
  const unreadNotifications = useAppSelector(state => state.notifications.itemsObject.items)
    .filter(n => !n.isRead)
    .filter(n => [NotificationType.TYPE_ORDER_REQUEST_CREATED, NotificationType.TYPE_ORDER_REQUEST_EXPIRED, NotificationType.TYPE_ORDER_ROUTE_ARCHIVED].includes(n.type))

  const tabList = [
    {key: RequestFromAgentsTabKeysEnum.ACTIVE, tab: intl.formatMessage(RequestFromAgentsPageMessages.active)},
    {key: RequestFromAgentsTabKeysEnum.ARCHIVAL, tab: intl.formatMessage(RequestFromAgentsPageMessages.archival)},
  ];

  useEffect(() => {
    return () => {
      dispatch(clearChangeOrderRequestWithdraw());
      dispatch(clearArchiveOrderRequest());
    }
  }, [])

  useEffect(() => {
    if (!isChangeStatusLoading) {
      if (isChangeStatusSuccess)
        renderSuccessModal(intl.formatMessage(RequestFromAgentsPageMessages.changeRespondStatusSuccess));
      if (changeStatusErrorMessage)
        message.error(changeStatusErrorMessage);
    }
  }, [isChangeStatusLoading, isChangeStatusSuccess, changeStatusErrorMessage])

  useEffect(() => {
    if (!isArchiveLoading) {
      if (isArchiveSuccess)
        renderSuccessModal(intl.formatMessage(RequestFromAgentsPageMessages.archiveRespondSuccess));
      if (archivesErrorMessage)
        message.error(changeStatusErrorMessage);
    }
  }, [isArchiveLoading, isArchiveSuccess, archivesErrorMessage])

  useEffect(() => {
    readNewNotifications()
  }, [unreadNotifications])

  const readNewNotifications = () => {
    if (unreadNotifications.length > 0)
      dispatch(readNotifications({notificationIds: unreadNotifications.map(n => n.id)}))
  }

  const contentList: IContentList = {
    active: <OrderRequestsTabPane orderTypes={orderFilterTypes} filters={filters}/>,
    archival: <OrderRequestsTabPane orderTypes={orderFilterTypes} filters={filters} isArchival={true}/>,
  };

  const extra = <>
    <CustomSelect mode={"tags"} defaultValue={[]}
                  placement={'bottomRight'}
                  style={{minWidth: isMobile ? 88 : 120, width: isMobile ? 88 : "unset"}}
                  showArrow={true}
                  allowClear={true}
                  dropdownMatchSelectWidth={false}
                  onChange={(types) => setOrderFilterTypes(types as OrderTypeEnum[])} placeholder={"All"} options={[
      {label: "One way", value: OrderTypeEnum.ONE_WAY},
      {label: "Round Trip", value: OrderTypeEnum.ROUND_TRIP},
      {label: "Multi Leg", value: OrderTypeEnum.MULTI_LEG},
    ]}/>
    <Badge count={Object.values(filters).filter(v => v).length}>
      <CustomButton onClick={() => setIsFiltersModalOpen(true)} heightSize={'m'} style={{marginLeft: 8}}>
        <FilterIcon/>
      </CustomButton>
    </Badge>
  </>

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(RequestFromAgentsPageMessages.title)} width={'max'} isContainerCard={true}
                  extra={isMobile && extra}
                  tabList={tabList}
                  tabBarExtraContent={!isMobile && extra}
                  defaultActiveTabKey={activeTabKey}
                  onTabChange={(key: string) => {
                    setActiveTabKey(key as RequestFromAgentsTabKeysEnum)
                  }}>
        {contentList[activeTabKey as keyof typeof contentList]}
      </CustomCard>
      {isFiltersModalOpen &&
        <OrderRequestsFilterModal isModalVisible={isFiltersModalOpen} setIsModalVisible={setIsFiltersModalOpen}
                                  filters={filters} setFilters={setFilters}/>}
    </PageWrapper>
  );
};

