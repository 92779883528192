import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState} from 'react';
import {CustomCollapse} from "../../../../../../components/UI/AntCustom/CustomCollapse";
import {CustomPanel} from "../../../../../../components/UI/AntCustom/CustomPanel";
import {Col, Form, Radio, Row} from "antd";
import {FlyHourPriceCalculatorSubgroup} from "./FlyHourPriceCalculatorSubgroup";
import styled from "styled-components";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {Logger} from "../../../../../../utils/logger/Logger";
import {getErrorMessage} from "../../../../../../utils/errorHelpers/getCommonErrorMessage";
import {FlyHourPriceContext} from "./context/FlyHourPriceContextProvider";
import {
  IFlyHourCalculatorCategory,
  IFlyHourCalculatorSubCategory
} from "../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {FlyHourPriceDefaultSettingTypeEnum} from "./types/flyHourPriceSettingEnums";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";

const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px 24px;
`;

const RadioButtonsWrapper = styled.div`
  margin-bottom: 24px;
`;

const SubcategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SavedText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
`;


const FlyHourPriceCalculatorGroupMessages = defineMessages({
  saved: {
    id: "flyHourPriceCalculatorGroup.saved",
    defaultMessage: "Saved",
  }
})

export enum GroupFormOptionKeys {
  TOTAL_SUM = 'total_sum',
  SEPARATELY = 'separately'
}

interface GroupFormOptions {
  key: GroupFormOptionKeys;
  title: string;
  subcategories: IFlyHourCalculatorSubCategory[];
}

interface IFlyHourPriceCalculatorGroup {
  isBlocked: boolean
  category: IFlyHourCalculatorCategory;
  setSavedCategory: Dispatch<SetStateAction<IFlyHourCalculatorCategory>>;
  groupFormOptions: GroupFormOptions[];
  isFieldsCountChangeable: boolean;
}

export const FlyHourPriceCalculatorGroup: FC<IFlyHourPriceCalculatorGroup> = ({
                                                                                isBlocked,
                                                                                category,
                                                                                setSavedCategory,
                                                                                groupFormOptions,
                                                                                isFieldsCountChangeable,
                                                                              }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [selectedFormOption, setSelectedFormOption] = useState<GroupFormOptionKeys>(groupFormOptions.find(o => !!o)?.key ?? GroupFormOptionKeys.TOTAL_SUM);
  const {categoriesSaving, setCategoriesSaving, setIsMarginTaxesDisabled} = useContext(FlyHourPriceContext);
  const [wasSavedOnce, setWasSavedOnce] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [canBeReset, setCanBeReset] = useState<boolean>(true);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const hasInitialFilledFields = useMemo(() => groupFormOptions.find(o => o.key === selectedFormOption)?.subcategories.some(sc => sc.settingTypes.some(st => st.setting.value !== 0)), [selectedFormOption, category]);

  useEffect(() => {
    const defaultValuesMap = groupFormOptions
      .map(g => g.subcategories).flat()
      .map(sc => sc.settingTypes).flat()
      .map(st => {
        return {key: st.keyName, defaultValue: st.setting.value}
      });

    let defaultValues: { [key: string]: number | undefined } = {}
    defaultValuesMap.forEach(v => {
      defaultValues[v.key] = v.defaultValue === 0 ? undefined : v.defaultValue;
    })

    form.setFieldsValue(defaultValues);
  }, [])

  useEffect(() => {
    isFirstRender
      ? setIsFirstRender(false)
      : changeIsCategorySaved(false);

    setCanBeReset(Object.values(form.getFieldsValue()).some(v => v !== undefined));
  }, [selectedFormOption])

  const changeIsCategorySaved = (isSaved: boolean) => {
    setCategoriesSaving(prev => [...prev.map(c => {return c.categoryName !== category.keyName ? c : {...c, isSaved: isSaved}})]);
  }

  const getIsCategorySaved = () => {
    return categoriesSaving.find(c => c.categoryName === category.keyName)?.isSaved;
  }

  const onFinish = (values: { [key: string]: number | undefined }) => {
    try {
      // Fill gaps
      const filledValues: { [key: string]: number } = {};
      for (const [key, value] of Object.entries(values)) {
        filledValues[key] = value ?? 0;
      }

      // Set values into categoryObject and update it for outer control
      const resultCategory: IFlyHourCalculatorCategory = {
        ...category,
        subcategories: category.subcategories.map(sc => {
          return {
            ...sc, settingTypes: sc.settingTypes.map(st => {
              return {
                ...st,
                setting: {...st.setting, value: filledValues.hasOwnProperty(st.keyName) ? filledValues[st.keyName] : 0}
              };
            })
          }
        })
      }

      setSavedCategory(resultCategory);
      setIsFormTouched(false);
      setWasSavedOnce(true);
      changeIsCategorySaved(true);
    } catch (e) {
      Logger.error(getErrorMessage(e));
    }
  }

  const onValuesChange = (values: any) => {
    const fieldName = Object.keys(values)[0];
    if ([FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_AMOUNT.toString()].includes(fieldName)) {
      const marginValue: number | undefined = form.getFieldValue(FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_AMOUNT.toString());
      setIsMarginTaxesDisabled(!marginValue);

      if (!marginValue)
        form.setFieldsValue({[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_TAXES.toString()]: undefined});
    }

    changeIsCategorySaved(false);
    if (!isFormTouched)
      setIsFormTouched(true);
  }

  const onReset = () => {
    changeIsCategorySaved(false);
    setCanBeReset(false);
    setIsFormTouched(true);

    form.resetFields();
  }

  const currentSubcategories = useMemo(() => groupFormOptions.find(o => o.key === selectedFormOption)?.subcategories, [selectedFormOption, category]);
  const isOnlyOneSubcategory = useMemo(() => currentSubcategories?.length === 1, [currentSubcategories]);

  const radioButtons = groupFormOptions.length > 1
    ? <RadioButtonsWrapper>
      {groupFormOptions.map(o => <Radio key={o.key}
                                        value={o.key}
                                        checked={selectedFormOption === o.key}
                                        onClick={() => setSelectedFormOption(o.key)}>{o.title}</Radio>)}
    </RadioButtonsWrapper>
    : undefined;

  const subcategoriesComponents = currentSubcategories && <SubcategoriesWrapper>
    {currentSubcategories?.map(sc => <FlyHourPriceCalculatorSubgroup key={sc.keyName}
                                                                     category={category}
                                                                     subcategory={sc}
                                                                     isOnlyOne={isOnlyOneSubcategory}
                                                                     isFieldsCountChangeable={isFieldsCountChangeable}/>)}
  </SubcategoriesWrapper>

  const categoryHeader = <CategoryHeader>
    <div>{category.label}</div>
    {wasSavedOnce && getIsCategorySaved() && <SavedText>{intl.formatMessage(FlyHourPriceCalculatorGroupMessages.saved)}</SavedText>}
  </CategoryHeader>

  return (
    <CustomCollapse expandIconPosition={'end'} borderRadius={'all'} size={'l'}
                    style={{background: "#fff", width: 866}} collapsible={isBlocked ? "disabled" : undefined}>
      <CustomPanel
        key={category.keyName}
        header={categoryHeader}
        background={"#fff"}
        noPaddings={true}
      >
        <GroupContent>
          {radioButtons}
          <Form
            form={form}
            layout={'vertical'}
            onFinish={(values) => onFinishTrimmed(values, onFinish)}
            onValuesChange={onValuesChange}
            onReset={onReset}
          >
            {subcategoriesComponents}

            <Row justify={'end'} gutter={10}>
              <Col>
                <CustomButton type={'primary'}
                              ghost
                              onClick={onReset}
                              disabled={!hasInitialFilledFields && !canBeReset}>
                  {intl.formatMessage(CommonButtonMessages.reset)}
                </CustomButton>
              </Col>
              <Col>
                <CustomFieldInput>
                  <CustomButton type={'primary'}
                                disabled={!isFormTouched && getIsCategorySaved()}
                                htmlType={'submit'}>
                    {intl.formatMessage(CommonButtonMessages.save)}
                  </CustomButton>
                </CustomFieldInput>
              </Col>
            </Row>
          </Form>
        </GroupContent>
      </CustomPanel>
    </CustomCollapse>
  );
};
