import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {clearEditEmptyLeg, editEmptyLeg,} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {Col, Form, message, Radio, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {Dayjs} from "dayjs";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {IEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {
  fetchCurrencies,
  getInitialCurrencyForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {dayjsWithOffset} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {EmptyLegDateTypeEnum, useEmptyLegDateTypeName} from "../../../../../utils/constans/business/emptyLegDateType";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const EditEmptyLegModalMessages = defineMessages({
  success: {
    id: "editEmptyLegModal.success",
    defaultMessage: "Empty Leg has been successfully edited for the aircraft",
  },
  title: {
    id: "editEmptyLegModal.title",
    defaultMessage: "Edit Empty Leg",
  },
})

interface IEditEmptyLegFormData {
  dateTypeRadio: EmptyLegDateTypeEnum;
  availableDates?: [Dayjs, Dayjs];
  departureDate?: Dayjs;
  airportFromId: number;
  airportToId: number;
  fleetId: number;
  priceAmount: number;
  priceCurrencyId: number;
  hours: number;
  minutes: number;
  distance: number;
  comment: string;
}

interface IEditEmptyLegModal {
  emptyLeg: IEmptyLeg;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const EditEmptyLegModal: FC<IEditEmptyLegModal> = ({emptyLeg, isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.emptyLegs.edit);
  const [dateTypeRadioValue, setDateTypeRadioValue] = useState<EmptyLegDateTypeEnum>(
    emptyLeg.departureDate ? EmptyLegDateTypeEnum.SPECIFIC : EmptyLegDateTypeEnum.RANGE
  )
  const emptyLegDateTypeNameRange = useEmptyLegDateTypeName(EmptyLegDateTypeEnum.RANGE);
  const emptyLegDateTypeNameSpecific = useEmptyLegDateTypeName(EmptyLegDateTypeEnum.SPECIFIC);

  useEffect(() => {
    return () => {
      dispatch(clearEditEmptyLeg());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(`${intl.formatMessage(EditEmptyLegModalMessages.success)} ${emptyLeg.plane.registrationNumber}`, () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IEditEmptyLegFormData) => {
    dispatch(editEmptyLeg({
      emptyLegId: emptyLeg.id,
      availableFromDate: values.availableDates ? values.availableDates[0].toDate() : undefined,
      availableToDate: values.availableDates ? values.availableDates[1].toDate() : undefined,
      departureDate: values.departureDate?.toDate(),
      airportFromId: emptyLeg.airportFrom.id,
      airportToId: emptyLeg.airportTo.id,
      fleetId: emptyLeg.plane.id,
      priceAmount: values.priceAmount,
      priceCurrencyId: values.priceCurrencyId,
      hours: emptyLeg.flyHours,
      minutes: emptyLeg.flyMinutes,
      comment: values.comment,
      distance: values.distance,
      emptyLegStatusId: emptyLeg.emptyLegStatusId,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const handleFormValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "dateTypeRadio" && changedValues.hasOwnProperty(fieldName)) {
      const value = changedValues[fieldName];
      setDateTypeRadioValue(value)
    }
  }

  const initialValues: IEditEmptyLegFormData = {
    dateTypeRadio: emptyLeg.departureDate ? EmptyLegDateTypeEnum.SPECIFIC : EmptyLegDateTypeEnum.RANGE,
    availableDates: emptyLeg.availableFromDate && emptyLeg.availableToDate
      ? [dayjsWithOffset(emptyLeg.availableFromDate, emptyLeg.airportFrom.timezone.offset),
         dayjsWithOffset(emptyLeg.availableToDate, emptyLeg.airportFrom.timezone.offset)]
      : undefined,
    departureDate: emptyLeg.departureDate ? dayjsWithOffset(emptyLeg.departureDate, emptyLeg.airportFrom.timezone.offset) : undefined,
    airportFromId: emptyLeg.airportFrom.id,
    airportToId: emptyLeg.airportTo.id,
    fleetId: emptyLeg.plane.id,
    priceAmount: emptyLeg.price,
    priceCurrencyId: emptyLeg.currency.id,
    hours: emptyLeg.flyHours,
    minutes: emptyLeg.flyMinutes,
    distance: emptyLeg.flyDistance,
    comment: emptyLeg.comment,
  }

  return (
    <CustomModal
      title={intl.formatMessage(EditEmptyLegModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={async (changedValues) => {
          handleFormValuesChange(changedValues);
        }}
      >
        <Col flex={1}>
          <CustomFieldInput
            name="dateTypeRadio"
            style={{marginBottom: 8}}
          >
            <Radio.Group>
              <Radio value={EmptyLegDateTypeEnum.RANGE}>{emptyLegDateTypeNameRange}</Radio>
              <Radio
                value={EmptyLegDateTypeEnum.SPECIFIC}>{emptyLegDateTypeNameSpecific}</Radio>
            </Radio.Group>
          </CustomFieldInput>
        </Col>

        <Col flex={1}>
          {dateTypeRadioValue === EmptyLegDateTypeEnum.RANGE &&
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.availableDatesLabel)}
              name={'availableDates'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomDatePicker.RangePicker showTime format={"DD.MM.YYYY HH:mm"}
                                            disabledDate={isDateLessTanOneMinuteAgo}
                                            placeholder={[intl.formatMessage(CommonUnitMessages.from), intl.formatMessage(CommonUnitMessages.to)]}
                                            inputReadOnly={true} placement={"bottomLeft"}
                                            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          }

          {dateTypeRadioValue === EmptyLegDateTypeEnum.SPECIFIC &&
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.departureDateLabel)}
              name={'departureDate'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
              <CustomDatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm"
                placeholder={intl.formatMessage(CommonFormMessages.departureDatePlaceholder)}
                disabledDate={isDateLessTanOneMinuteAgo}
                inputReadOnly={true} placement={"bottomLeft"}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          }
        </Col>

        <Row gutter={8}>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.priceLabel)}
              name={'priceAmount'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  min: 0,
                  type: "number",
                  message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)
                },
                {
                  max: 1000000000,
                  type: "number",
                  message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)
                }
              ]}>
              <CustomInputNumber controls={false} placeholder={intl.formatMessage(CommonFormMessages.pricePlaceholder)} style={{width: '100%'}}/>
            </CustomFieldInput>
          </Col>
          <Col span={12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.currencyLabel)}
              name={'priceCurrencyId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchCurrencies}
                placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
                defaultOptions={[getInitialCurrencyForSelect(emptyLeg.currency)]}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Form.Item>
          <CustomButton
            block
            heightSize={"l"}
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(CommonButtonMessages.edit)}
          </CustomButton>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
