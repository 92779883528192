import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  archiveOrder,
  changeEmptyLegOrderRouteWithdraw, getChartererOrderList, setArchiveOrderResult,
  setChangeEmptyLegOrderRouteWithdrawResult, setChartererOrderListResult,
} from "../../reducers/chartererRequestsReducer/chartererRequestsReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  BookingApiEmptyLegOrderRouteRequest,
  BookingApiEmptyLegOrderRouteResponse, BookingApiOrderListRequest, BookingApiOrderListResponse, BookingApiOrderRequest,
  BookingApiOrderRouteResponse,
  EmptyLegOrderRouteEntity, OrderEntity,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {
  IArchiveOrderPayload,
  IChangeEmptyLegOrderRouteWithdrawPayload, IGetChartererOrderListPayload,
} from "../../reducers/chartererRequestsReducer/chartererRequestsReducerTypes";


function* getOrderListSaga({payload}: PayloadAction<IGetChartererOrderListPayload>) {
  const req = new BookingApiOrderListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.MyRequests)
    )
    .setIsshowarchived(payload.showArchived)
    .setTypesList(payload.types);

  // TODO discuss filters logic
  // payload.airportFromId && req.setAirportfrom(new AirportEntity().setAirportid(payload.airportFromId));
  // payload.airportToId && req.setAirportto(new AirportEntity().setAirportid(payload.airportToId));
  // payload.onlyApproved && req.setShowapproved(payload.onlyApproved);

  try {
    const res: BookingApiOrderListResponse = yield call(API.getOrderList, req);

    yield put(setChartererOrderListResult({
      items: res.getOrdersList(),
      totalCount: res.getTotalcount(),
    }));
  } catch {}
}


function* changeEmptyLegOrderRouteWithdrawSaga({payload}: PayloadAction<IChangeEmptyLegOrderRouteWithdrawPayload>) {
  const req = new BookingApiEmptyLegOrderRouteRequest()
    .setEmptylegorderrouteentity(
      new EmptyLegOrderRouteEntity()
        .setOrderrouteid(payload.orderRouteId)
        .setEmptylegid(payload.emptyLegId)
        .setStatus(payload.emptyLegOrderRouteStatus)
    );

  try {
    const res: BookingApiEmptyLegOrderRouteResponse = yield call(API.emptyLegOrderRouteUpdate, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setChangeEmptyLegOrderRouteWithdrawResult({
        emptyLegOrderRoute: res.getEmptylegorderrouteentity(),
      }));
    } else {
      yield put(setChangeEmptyLegOrderRouteWithdrawResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {}
}


function* archiveOrderSaga({payload}: PayloadAction<IArchiveOrderPayload>) {
  const req = new BookingApiOrderRequest()
    .setOrder(
      new OrderEntity()
        .setOrderid(payload.itemId)
        .setArchived(true)
    );

  try {
    const res: BookingApiOrderRouteResponse = yield call(API.orderArchive, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setArchiveOrderResult({
        itemId: payload.itemId,
      }));
    } else {
      yield put(setArchiveOrderResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {}
}


export function* chartererRequestsSaga() {
  yield all([
    takeLatest(getChartererOrderList.type, getOrderListSaga),
    takeLatest(changeEmptyLegOrderRouteWithdraw.type, changeEmptyLegOrderRouteWithdrawSaga),
    takeLatest(archiveOrder.type, archiveOrderSaga),
  ])
}