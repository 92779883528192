import {RoleDescriptor} from "./RoleDescriptor";
import {Role} from "./Role";
import {guest} from "./all/guest";
import {freighterAdmin} from "./all/freighter/freighterAdmin";
import {freighterAdminNotApproved} from "./all/freighter/freighterAdminNotApproved";
import {freighterServiceManager} from "./all/freighter/freighterServiceManager";
import {freighterClientManager} from "./all/freighter/freighterClientManager";
import {chartererAdmin} from "./all/charterer/chartererAdmin";
import {chartererManager} from "./all/charterer/chartererManager";
import {admin} from "./all/admin";
import {unknown} from "./all/unknown";
import {chartererAdminNotApproved} from "./all/charterer/chartererAdminNotApproved";

export const roles: {[key in Role]: RoleDescriptor<key>} = {
  [Role.unknown]: unknown,
  [Role.guest]: guest,
  [Role.freighterAdmin]: freighterAdmin,
  [Role.freighterAdminNotApproved]: freighterAdminNotApproved,
  [Role.freighterServiceManager]: freighterServiceManager,
  [Role.freighterClientManager]: freighterClientManager,
  [Role.chartererAdmin]: chartererAdmin,
  [Role.chartererAdminNotApproved]: chartererAdminNotApproved,
  [Role.chartererManager]: chartererManager,
  [Role.admin]: admin,
}