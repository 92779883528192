import React from 'react';
import styled from "styled-components";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";
import {FONTS} from "../../../../utils/constans/styles/fonts";
import Arrow from "../../../../media/icons/Arrow.svg";
import {useNavigate} from "react-router";
import {HomeInfoCard} from "./HomeInfoCard";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../config/acl/routes/Route";
import sparePartImage from "../../../../media/images/plane_spare_part.png";
import {defineMessages, useIntl} from "react-intl";


const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 32px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    justify-content: unset;
    row-gap: 24px;
  }
`;

const SectionTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 60px;
  line-height: 56px;
  color: #000000;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 47px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 45px;
  }
`;

const SectionDescription = styled.div`
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  max-width: 673px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    max-width: 380px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    max-width: none;
  }
`;

const SectionLink = styled.div`
  font: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 17px;
  line-height: 26px;
  color: #1890FF;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;

const SectionDescriptionAndLink = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const SectionInfoCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
  justify-content: unset;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const MarketplaceSection = styled.div`
  display: flex;
  flex-direction: row;
  background: #F3F5F7;
  border: 1px solid #C6C2C0;
  padding: 50px 69px 50px 69px;
  justify-content: space-between;
  column-gap: 24px;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 55px 24px 55px 24px;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 45px 16px 41px 16px;
    flex-direction: column-reverse;
    row-gap: 36px;
  }
`;

const MarketplaceTitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const MarketplaceTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 60px;
  line-height: 56px;
  color: #000000;
  max-width: 491px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 40px;
    line-height: 37px;
    max-width: 346px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 37px;
    max-width: none;
  }
`;

const MarketplaceDescription = styled.div`
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 14px;
  line-height: 21px;
  color: #242527;
  max-width: 320px;
`;

const MarketplaceImage = styled.img`
  width: 50%;
  object-fit: contain;
  max-height: 301px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    max-height: 239px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    max-height: none;
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  max-width: 1260px;
  box-sizing: content-box;
  row-gap: 42px;
  flex-direction: column;
  padding: 0 90px 156px 90px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 0 27px 184px 27px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 0 23px 165px 23px;
    box-sizing: border-box;
  }
`;

const HomeFourthSectionStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;


const HomeFourthSectionMessages = defineMessages({
  title: {
    id: "homeFourthSection.title",
    defaultMessage: "For operators"
  },
  description: {
    id: "homeFourthSection.description",
    defaultMessage: "Once you've posted your aircraft at Jetsnode, you get access to the global network of qualified brokers. So hold your aircraft fleet in the air, where it's supposed to be."
  },
  join: {
    id: "homeFourthSection.join",
    defaultMessage: "Sign up"
  },
  infoTitle1: {
    id: "homeFourthSection.infoTitle1",
    defaultMessage: "Optimized work process"
  },
  infoDescription1: {
    id: "homeFourthSection.infoDescription1",
    defaultMessage: "Compare, accept, decline requests, ask your broker questions online, and add multiple operators to one account"
  },
  infoTitle2: {
    id: "homeFourthSection.infoTitle2",
    defaultMessage: "Aircraft presentation services"
  },
  infoDescription2: {
    id: "homeFourthSection.infoDescription2",
    defaultMessage: "Brokers can see the images and information on each aircraft, as well as the list of available services"
  },
  infoTitle3: {
    id: "homeFourthSection.infoTitle3",
    defaultMessage: "Jetsnode Mobile"
  },
  infoDescription3: {
    id: "homeFourthSection.infoDescription3",
    defaultMessage: "Check the timetable, receive notifications when getting a flight request, and answer brokers instantly on Jetsnode Mobile"
  },
  infoTitle4: {
    id: "homeFourthSection.infoTitle4",
    defaultMessage: "Flexible flight settings"
  },
  infoDescription4: {
    id: "homeFourthSection.infoDescription4",
    defaultMessage: "Use Empty Legs and Jet Sharing to gain the maximal profit and deliver on the entire potential of your aircraft fleet"
  },
  marketplaceTitle: {
    id: "homeFourthSection.marketplaceTitle",
    defaultMessage: "Marketplace with components and spare parts for your aircraft"
  },
  marketplaceDescription: {
    id: "homeFourthSection.marketplaceDescription",
    defaultMessage: "The marketplace is currently under development and will be accessible after it is implemented."
  },
})

export const HomeFourthSection = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <HomeFourthSectionStyled>
      <SectionContainer>
        <TitleAndDescription>
          <SectionTitle>{intl.formatMessage(HomeFourthSectionMessages.title)}</SectionTitle>
          <SectionDescriptionAndLink>
            <SectionDescription>
              {intl.formatMessage(HomeFourthSectionMessages.description)}
            </SectionDescription>
            <SectionLink onClick={() => navigate(pathFromRoute(Route.register))}>
              <div>{intl.formatMessage(HomeFourthSectionMessages.join)}</div>
              <img src={Arrow} alt="arrow"/>
            </SectionLink>
          </SectionDescriptionAndLink>
        </TitleAndDescription>
        <SectionInfoCards>
          <HomeInfoCard serialNumber={1} title={intl.formatMessage(HomeFourthSectionMessages.infoTitle1)}
                        description={intl.formatMessage(HomeFourthSectionMessages.infoDescription1)}
                        minHeight={"300px"} />
          <HomeInfoCard serialNumber={2} title={intl.formatMessage(HomeFourthSectionMessages.infoTitle2)}
                        description={intl.formatMessage(HomeFourthSectionMessages.infoDescription2)}
                        minHeight={"300px"} />
          <HomeInfoCard serialNumber={3} title={intl.formatMessage(HomeFourthSectionMessages.infoTitle3)}
                        description={intl.formatMessage(HomeFourthSectionMessages.infoDescription3)}
                        minHeight={"300px"} />
          <HomeInfoCard serialNumber={4} title={intl.formatMessage(HomeFourthSectionMessages.infoTitle4)}
                        description={intl.formatMessage(HomeFourthSectionMessages.infoDescription4)}
                        minHeight={"300px"} />
        </SectionInfoCards>
        <MarketplaceSection>
          <MarketplaceTitleAndDescription>
            <MarketplaceTitle>{intl.formatMessage(HomeFourthSectionMessages.marketplaceTitle)}</MarketplaceTitle>
            <MarketplaceDescription>{intl.formatMessage(HomeFourthSectionMessages.marketplaceDescription)}</MarketplaceDescription>
          </MarketplaceTitleAndDescription>
          <MarketplaceImage src={sparePartImage} alt={'Spare part photo'} />
        </MarketplaceSection>
      </SectionContainer>
    </HomeFourthSectionStyled>
  );
};
