import {ICompany} from "./companyReducerTypes";
import {CompanyStatus} from "../../../utils/constans/statuses/companyStatuses";

export const generateEmptyCompany = (): ICompany => {
  return {
    id: 0,
    name: '',
    companyStatus: CompanyStatus.UNKNOWN,
    phone: undefined,
    email: undefined,
    website: undefined,
    description: undefined,
    address: undefined,
    avatar: undefined,
    operatorCard: undefined,
    registrationCertificate: undefined,
    currency: {
      id: 0,
      name: '',
    }
  }
}