import React, {FC} from 'react';
import styled from "styled-components";
import {ReactComponent as RouteArrowIcon} from "../../../media/icons/route_arrow.svg";
import {IAirport} from "../../../store/reducers/fleetReducer/fleetReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../intl/commonMessages/CommonDetailsMessages";
import {DisplayAirport} from "../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {DetailItem} from "../detailItem/DetailItem";
import {DisplayFlyDuration} from "../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import {DisplayFlyDistance} from "../../../utils/helpers/textDisplayHelpers/DisplayFlyDistance";


const FlyRouteStyled = styled.div`
  background: #FFFFFF;
  padding: 16px 20px 16px 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D9D9D9;
  border-radius: 0 0 10px 10px;

  & > div {
    :nth-child(1) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-bottom: 8px;
    }

    :nth-child(2) {
      display: grid;
      grid-template-columns: 5fr 1fr 5fr;
      column-gap: 8px;
      margin-bottom: 16px;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;

        & > div {
          :nth-child(1), :nth-child(3) {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            opacity: 0.5;
          }

          :nth-child(2) {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: #000000;
          }
        }
      }
    }

    :last-child {
      display: flex;
      flex-direction: row;
      gap: 4px;

      div {
        flex-grow: 1;
      }
    }
  }
`;

const AvailableDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 8px;
  
  & > div {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    color: #000000;

    :nth-child(1) {
      font-weight: 600;
    }
    
    :nth-child(2) {
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      font-weight: 400;
      display: flex;
      column-gap: 8px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 11px 10px 9px 10px;
    }
  }
`


const FlyRouteMessages = defineMessages({
  title: {
    id: "flyRoute.title",
    defaultMessage: "Route"
  }
})

interface IFlyRoute {
  index?: number;
  airportFrom: IAirport;
  airportTo: IAirport;
  dateFrom?: Date;
  dateTo?: Date;
  availableFromDate?: Date;
  availableToDate?: Date;
  flyHoursDuration: number;
  flyMinutesDuration: number;
  distance: number;
  pax: number;
  technicalStopHoursDuration?: number;
  technicalStopMinutesDuration?: number;
}

export const FlyRoute: FC<IFlyRoute> = ({
                                          index = 0,
                                          airportFrom,
                                          airportTo,
                                          dateFrom,
                                          dateTo,
                                          availableFromDate,
                                          availableToDate,
                                          flyHoursDuration,
                                          flyMinutesDuration,
                                          distance,
                                          pax,
                                          technicalStopHoursDuration = 0,
                                          technicalStopMinutesDuration = 0,
                                        }) => {
  const intl = useIntl();

  const technicalStopDetailItem = technicalStopHoursDuration + technicalStopMinutesDuration > 0
    && <DetailItem label={intl.formatMessage(CommonDetailsMessages.technicalStop)} value={"1"}
                   tooltip={DisplayFlyDuration(technicalStopHoursDuration, technicalStopMinutesDuration)}/>

  const availableDatesItem = availableFromDate && availableToDate
  && <AvailableDatesWrapper>
      <div>{intl.formatMessage(CommonDetailsMessages.availableRange)}</div>
      <div>
        <div>{formatDate(availableFromDate, airportFrom.timezone.offset, "datetime_short_year")}</div>
        <RouteArrowIcon />
        <div>{formatDate(availableToDate, airportTo.timezone.offset, "datetime_short_year")}</div>
      </div>
    </AvailableDatesWrapper>

  return (
    <FlyRouteStyled>
      <div>{intl.formatMessage(FlyRouteMessages.title)} {index + 1}</div>

      <div>
        <div>
          <div>{intl.formatMessage(CommonDetailsMessages.from)}</div>
          <div>{DisplayAirport(airportFrom, "codes")}</div>
          {dateFrom && <div>{formatDate(dateFrom, airportFrom.timezone.offset, "datetime_short_year")}</div>}
        </div>
        <RouteArrowIcon/>
        <div>
          <div>{intl.formatMessage(CommonDetailsMessages.to)}</div>
          <div>{DisplayAirport(airportTo, "codes")}</div>
          {dateTo && <div>{formatDate(dateTo, airportTo.timezone.offset, "datetime_short_year")}</div>}
        </div>
      </div>

      {availableDatesItem}

      <div>
        <DetailItem label={intl.formatMessage(CommonDetailsMessages.duration)}
                    value={DisplayFlyDuration(flyHoursDuration, flyMinutesDuration)}/>
        <DetailItem label={intl.formatMessage(CommonDetailsMessages.distance)} value={DisplayFlyDistance(distance)}/>
        {technicalStopDetailItem}
        <DetailItem label={intl.formatMessage(CommonDetailsMessages.pax)} value={pax.toString()}/>
      </div>
    </FlyRouteStyled>
  );
};
