import React, {Dispatch, FC, SetStateAction, useCallback, useState} from 'react';
import styled from "styled-components";
import {ReactComponent as ImageUploadIcon} from "../../../../../media/images/image_upload_icon.svg"
import {ReactComponent as UploadIcon} from "../../../../../media/icons/upload_icon.svg";
import {Upload} from "antd";
import {RcFile, UploadFile} from "antd/es/upload";
import {PlusOutlined} from "@ant-design/icons";
import {FileAcceptTypes} from "../../../../../utils/constans/files/fileAcceptTypes";
import noPhotoImage from "../../../../../media/images/no_photo.jpg"
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {
  CarouselWrapper,
  ChangeSlideButton,
  CustomCarousel
} from "../../../../../components/UI/AntCustom/CustomCarousel";
import {CarouselRef} from "antd/es/carousel";
import {ReactComponent as CaretLeftIcon} from "../../../../../media/icons/caret_left.svg";
import {ReactComponent as CaretRightIcon} from "../../../../../media/icons/caret_right.svg";
import {defineMessages, useIntl} from "react-intl";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";

const UploadStyled = styled(Upload)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div.ant-upload {
    width: 100%;
    display: flex;
  }

  span.ant-upload {
    width: 100%;
    height: 307px;
    background: #F9F9F9;
    border-radius: 15px;
    border: 1px dashed #D9D9D9;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 32px 32px 56px 32px;
    row-gap: 49px;

    transition: 0.2s ease border-color;

    :hover {
      border-color: #1890ff;
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    span.ant-upload {
      width: 100%;
      height: 220px;
      padding: 32px 32px 28px 32px;
      row-gap: 16px;
      
      & > svg {
        height: 55px;
      }
      
      button {
        margin-top: 14px;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 16px;
  color: #A5A5A5;

  span {
    color: #1890FF;
  }
`;

interface IUploadWrapper {
  edit: boolean;
}

const UploadWrapper = styled.div<IUploadWrapper>`
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 468px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .ant-upload-list-picture-card-container {
    :first-child {
      width: 460px;
      height: 307px;
      border-radius: 15px;
    }
  }

  .ant-upload-list-item-image {
    object-fit: cover !important;
  }

  .ant-btn, .ant-btn-text, .ant-btn-sm, .ant-btn-icon-only, .ant-upload-list-item-card-actions-btn {
    :nth-child(even) {
      display: ${props => props.edit ? "initial" : "none"};
    }
  }

  .ant-upload-list-picture-card-container, .ant-upload, .ant-upload-select, .ant-upload-select-picture-card {
    width: 148px;
    height: 98px;
    background: transparent;
    border-radius: 5px;

    .ant-upload-list-item, .ant-upload-list-item-undefined, .ant-upload-list-item-list-type-picture-card {
      padding: 0;
      border-radius: 5px;

      .ant-upload-list-item-info {
        border-radius: 5px;
      }
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
    min-height: 220px;
    position: relative;

    .ant-upload-list-picture-card-container {
      :first-child {
        width: calc((100vw - 32px));
        height: calc((100vw - 32px) / 1.5);
        border-radius: 15px;
        margin-right: 0;
      }
    }

    .ant-upload-list-picture-card-container, .ant-upload, .ant-upload-select, .ant-upload-select-picture-card {
      width: calc((100vw - 56px) / 3);
      height: calc((100vw - 56px) / 4.5);
      background: transparent;
      border-radius: 5px;

      .ant-upload-list-item, .ant-upload-list-item-undefined, .ant-upload-list-item-list-type-picture-card {
        padding: 0;
        border-radius: 5px;

        .ant-upload-list-item-info {
          border-radius: 5px;
        }
      }
    }
  }
`;

const UploadButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #808080;
  font-size: 10px;
  line-height: 22px;
`;

const NoPhoto = styled.img`
  width: 468px;
  border-radius: 15px;
  object-fit: cover;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
    height: auto;
  }
`;

const CarouselImageWrapper = styled.div`
  width: 468px;
  height: 307px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: calc(100vw - 56px);
    height: auto;
  }
`;

const MiniImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

const MiniImage = styled.img<{ isActive: boolean }>`
  height: 98px;
  width: 100%;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 5px;
  border: ${props => props.isActive ? "2px solid #1890FF" : "none"};
  cursor: pointer;
`;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });


const PlanePhotosMessages = defineMessages({
  title: {
    id: "planePhotos.title",
    defaultMessage: "Add aircraft images",
  },
  description1: {
    id: "planePhotos.description1",
    defaultMessage: "Upload",
  },
  description2: {
    id: "planePhotos.description2",
    defaultMessage: "or drag-and-drop image here",
  },
  descriptionMobile: {
    id: "planePhotos.descriptionMobile",
    defaultMessage: "Upload photo",
  },
  addPhoto: {
    id: "planePhotos.addPhoto",
    defaultMessage: "Add an image",
  },
})

interface IPlanePhotos {
  imageFiles: UploadFile[];
  edit?: {
    setImageFiles: Dispatch<SetStateAction<UploadFile[]>>;
  }
}

export const PlanePhotos: FC<IPlanePhotos> = ({imageFiles, edit}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [slide, setSlide] = useState(0);
  const sliderRef = React.createRef<CarouselRef>();

  const onFileAdd = useCallback(async (file: UploadFile<File>) => {
    if (edit) {
      const arrayBuffer = await (file as unknown as File).arrayBuffer();
      file.url = URL.createObjectURL(new Blob([arrayBuffer]));
      edit.setImageFiles(prev => [...prev, file]);
    }

  }, []);


  const onFileRemove = (file: UploadFile) => {
    if (edit) {
      edit.setImageFiles(prev => prev.filter(f => f.uid !== file.uid));
    }
  }

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  return (
    <>
      <UploadWrapper edit={!!edit}>
        {imageFiles.length === 0 && (edit
          ? <UploadStyled
            accept={FileAcceptTypes.IMAGE}
            beforeUpload={(file) => onFileAdd(file)}
            onRemove={(file) => onFileRemove(file)}
            fileList={[]}
          >
            <Title>{intl.formatMessage(PlanePhotosMessages.title)}</Title>
            <ImageUploadIcon/>
            {isMobile
              ? <CustomButton heightSize={'s'} type={'primary'} style={{display: "flex"}}>
                <UploadIcon/>
                <div>{intl.formatMessage(PlanePhotosMessages.descriptionMobile)}</div>
              </CustomButton>
              :
              <Description><span>{intl.formatMessage(PlanePhotosMessages.description1)}</span> {intl.formatMessage(PlanePhotosMessages.description2)}
              </Description>}
          </UploadStyled>
          : <NoPhoto src={noPhotoImage} alt={"No photo"}/>)
        }

        {edit && imageFiles.length > 0 &&
          <Upload
            accept={FileAcceptTypes.IMAGE}
            listType="picture-card"
            beforeUpload={onFileAdd}
            fileList={imageFiles}
            onRemove={onFileRemove}
            onPreview={handlePreview}
          >
            {imageFiles.length < 10 && <UploadButtonContent>
              <PlusOutlined/>
              {intl.formatMessage(PlanePhotosMessages.addPhoto)}
            </UploadButtonContent>}
          </Upload>
        }

        {!edit && imageFiles.length > 0 &&
          <>
            <CarouselWrapper>
              <ChangeSlideButton onClick={() => sliderRef.current?.prev()}><CaretLeftIcon/></ChangeSlideButton>
              <CustomCarousel autoplay={true} infinite={true} draggable={true} dots={false} swipe={true}
                              autoplaySpeed={5000} speed={750}
                              ref={sliderRef} afterChange={(index) => setSlide(index)}>

                {imageFiles.map(image =>
                  <CarouselImageWrapper onClick={() => handlePreview(image)} key={image.uid}>
                    <img src={image.url} alt={image.name}/>
                  </CarouselImageWrapper>
                )}
              </CustomCarousel>
              <ChangeSlideButton onClick={() => sliderRef.current?.next()}><CaretRightIcon/></ChangeSlideButton>
            </CarouselWrapper>

            <MiniImages>
              {imageFiles.map((image, index) =>
                <MiniImage isActive={index === slide} src={image.url} alt={image.name} onClick={() => {
                  setSlide(index);
                  sliderRef.current?.goTo(index);
                }} key={image.uid}/>
              )}
            </MiniImages>
          </>
        }

      </UploadWrapper>
      <CustomModal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} centered={true}
                   width={"70%"}>
        <img src={previewImage} alt="Plane photo" style={{width: '100%'}}/>
      </CustomModal>
    </>
  );
};
