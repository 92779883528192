import grpcWeb from "grpc-web";
import {API} from "../API";

export type Client<TClient> = {
  [Property in keyof TClient]: Method<any, any>
}

export type Method<TReq, TRes> = (
  request: TReq,
  metadata: grpcWeb.Metadata | undefined,
  callback: (
    err: grpcWeb.Error,
    response: TRes
  ) => void
) => grpcWeb.ClientReadableStream<TRes>;

type ReqFromMethod<TMethod extends Method<any, any>> = Parameters<TMethod>[0];
type ResFromMethod<TMethod extends Method<any, any>> = Promise<Parameters<Parameters<TMethod>[2]>[1]>;

export type ApiWrapper<TClient extends Client<TClient>> = {
  [P in keyof TClient]: (req: ReqFromMethod<TClient[P]>) => ResFromMethod<TClient[P]>;
}

export const methodFactory = (client: any) => <
  TMethod extends Method<TReq, TRes>,
  TReq = Parameters<TMethod>[0],
  TRes = Parameters<Parameters<TMethod>[2]>[1]
>(method: TMethod) => {
  return function async (req: TReq): Promise<TRes> {
    return new Promise((resolve, reject) => {
      API.call<TReq, TRes>(client, method, req, resolve, reject);
    });
  }
}

export const wrapClient = <TClient extends Client<TClient>>(client: TClient) => {
  return (Object.getOwnPropertyNames(Object.getPrototypeOf(client)) as Array<keyof typeof client>).filter(name => name !== 'constructor').reduce((obj, name) => ({
    ...obj,
    [name]: methodFactory(client)(client[name] as any) as any
  }), {} as ApiWrapper<TClient>);
};