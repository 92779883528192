import React, {FC, ReactNode, useContext, useState} from 'react';
import styled from "styled-components";
import {FlyHourPriceCalculatorField} from "./FlyHourPriceCalculatorField";
import {Row} from "antd";
import {
  IFlyHourCalculatorCategory,
  IFlyHourCalculatorSubCategory
} from "../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {FlyHourPriceCalculatorAddFieldModal} from "./FlyHourPriceCalculatorAddFieldModal";
import {FlyHourPriceContext} from "./context/FlyHourPriceContextProvider";
import {FlyHourPriceDefaultSubcategoryEnum} from "./types/flyHourPriceSettingEnums";

const StyledWrapper = styled.div`
  background: #F9F9F9;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 20px 16px 24px 16px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: row;

  div {
    font-size: 14px;
    line-height: 17px;

    :nth-child(1) {
      font-weight: 500;
      color: #000000;
    }

    :nth-child(2) {
      font-weight: 400;
      color: #E99700;
      margin-left: 24px;
    }
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;


const FlyHourPriceCalculatorSubgroupMessages = defineMessages({
  marginTaxesDisabled: {
    id: "flyHourPriceCalculatorSubgroup.marginTaxesDisabled",
    defaultMessage: "Specify company margin above"
  }
})

interface IFlyHourPriceCalculatorSubgroup {
  category: IFlyHourCalculatorCategory;
  subcategory: IFlyHourCalculatorSubCategory;
  isOnlyOne: boolean;
  isFieldsCountChangeable: boolean;
}

export const FlyHourPriceCalculatorSubgroup: FC<IFlyHourPriceCalculatorSubgroup> = ({
                                                                                      category,
                                                                                      subcategory,
                                                                                      isOnlyOne,
                                                                                      isFieldsCountChangeable
                                                                                    }) => {
  return (
    <FlyHourPriceCalculatorSubgroupWrapper isOnlyOne={isOnlyOne} title={subcategory.label} category={category}
                                           subcategory={subcategory}
                                           isFieldsCountChangeable={isFieldsCountChangeable}>
      {subcategory.settingTypes.map(st => <FlyHourPriceCalculatorField key={st.keyName} settingType={st}
                                                                       category={category} subcategory={subcategory}/>)}
    </FlyHourPriceCalculatorSubgroupWrapper>
  );
};

const FlyHourPriceCalculatorSubgroupWrapper: FC<{ title: string; children: ReactNode } & IFlyHourPriceCalculatorSubgroup> = ({
                                                                                                                               isOnlyOne,
                                                                                                                               title,
                                                                                                                               category,
                                                                                                                               subcategory,
                                                                                                                               isFieldsCountChangeable,
                                                                                                                               children
                                                                                                                             }) => {
  const intl = useIntl();
  const {isMarginTaxesDisabled} = useContext(FlyHourPriceContext);
  const [isAddFieldOpen, setIsAddFieldOpen] = useState(false);

  return <>
    {isOnlyOne
      ? <EmptyWrapper>{children}</EmptyWrapper>
      : <StyledWrapper>
        <Title>
          <TitleContent>
            <div>{title}</div>
            {subcategory.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_TAXES && isMarginTaxesDisabled &&
              <div>{intl.formatMessage(FlyHourPriceCalculatorSubgroupMessages.marginTaxesDisabled)}</div>}
          </TitleContent>
          {isFieldsCountChangeable &&
            <CustomButton type={'link'} onClick={() => setIsAddFieldOpen(true)}>
              + {intl.formatMessage(CommonButtonMessages.add)}
            </CustomButton>}
        </Title>
        <Row gutter={8}>{children}</Row>
      </StyledWrapper>}

    {isAddFieldOpen &&
      <FlyHourPriceCalculatorAddFieldModal isModalVisible={isAddFieldOpen} setIsModalVisible={setIsAddFieldOpen}
                                           category={category} subCategory={subcategory}/>}
  </>
}
