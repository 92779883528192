import React, {FC, ReactNode} from 'react';
import {Router} from 'react-router-dom';
import {BrowserHistory} from "history";

interface ICustomRouter {
  history: BrowserHistory,
  children: ReactNode,
}

export const CustomRouter: FC<ICustomRouter> = ({children, history}) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
