import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, message, Radio, Row} from "antd";
import {Role} from "../../../../../../config/acl/roles/Role";
import {
  clearCreateManager,
  createManager
} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducer";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {roles} from "../../../../../../config/acl/roles";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {EmailRegex} from "../../../../../../utils/helpers/regexes/emailRegex";
import {PasswordRegex} from "../../../../../../utils/helpers/regexes/passwordRegex";
import {CustomLink} from "../../../../../../components/UI/CustomLink/CustomLink";
import {PrivacyPolicyModal} from "../../../../../../components/businessCommon/privacyPolicy/PrivacyPolicyModal";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {PhoneRegex} from "../../../../../../utils/helpers/regexes/phoneRegex";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const AddManagerModalMessages = defineMessages({
  success: {
    id: "addManagerModal.success",
    defaultMessage: "New user has been successfully created",
  },
  title: {
    id: "addManagerModal.title",
    defaultMessage: "Add new user",
  },
  policyTitle: {
    id: "addManagerModal.policyTitle",
    defaultMessage: "By registering a new user I accept the {link}",
  },
  policyTitleLink: {
    id: "addManagerModal.policyTitleLink",
    defaultMessage: "Privacy Policy",
  },
  register: {
    id: "addManagerModal.register",
    defaultMessage: "Register",
  }
})

interface IAddManagerFormData {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone?: number;
  companyRole: Role;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IAddManagerModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const AddManagerModal: FC<IAddManagerModal> = ({
                                                        isModalVisible,
                                                        setIsModalVisible
                                                      }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const {role} = useAppSelector(state => state.auth);
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.personnelManagement.create);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearCreateManager());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(AddManagerModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IAddManagerFormData) => {
    dispatch(createManager({
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      phone: values.phone!,
      companyRole: values.companyRole,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IAddManagerFormData = {
    firstName: '',
    lastName: '',
    middleName: '',
    phone: undefined,
    companyRole: role === Role.chartererAdmin ? Role.chartererManager : Role.freighterClientManager,
    email: '',
    password: '',
    confirmPassword: '',
  }

  const RadioForRole: FC<{ role: Role }> = ({role}) => <Radio value={role}>{intl.formatMessage(roles[role].name)}</Radio>;

  return (
    <CustomModal
      title={intl.formatMessage(AddManagerModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.lastNameLabel)}
              name={'lastName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.lastNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.nameLabel)}
              name={'firstName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.namePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.middleNameLabel)}
              name={'middleName'}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.middleNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.phoneLabel)}
              name={'phone'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                pattern: PhoneRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
              }]}>
              <CustomInputNumber addonBefore={"+"} placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)} controls={false} maxLength={12} />
            </CustomFieldInput>
          </Col>
        </Row>


        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.roleLabel)}
              name={'companyRole'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Radio.Group>
                {role === Role.chartererAdmin && <RadioForRole role={Role.chartererManager}/>}

                {role === Role.freighterAdmin && <>
                  <RadioForRole role={Role.freighterClientManager}/>
                  <RadioForRole role={Role.freighterServiceManager}/>
                </>}
              </Radio.Group>
            </CustomFieldInput>
          </Col>
        </Row>


        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.emailLabel)}
            name={'email'}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {
                pattern: EmailRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectEmail)
              }
            ]}
          >
            <Input placeholder={intl.formatMessage(CommonFormMessages.emailPlaceholder)}/>
          </CustomFieldInput>
        </Col>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.passwordLabel)}
              name={'password'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  pattern: PasswordRegex.pattern,
                  message: PasswordRegex.error(
                    intl.formatMessage(CommonFormMessages.rulePasswordValidationTitle),
                    [
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule1),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule2),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule3),
                    ]
                  ),
                }
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.passwordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.repeatPasswordLabel)}
              name={'repeatPassword'}
              dependencies={['password']}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(intl.formatMessage(CommonFormMessages.passwordsMismatch)));
                  },
                })
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.repeatPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Col flex={1} style={{marginBottom: 24}}>
          {intl.formatMessage(AddManagerModalMessages.policyTitle, {
            link: <CustomLink
              onClick={() => setIsPolicyModalOpen(true)}>{intl.formatMessage(AddManagerModalMessages.policyTitleLink)}</CustomLink>
          })}
        </Col>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(AddManagerModalMessages.register)}
          </CustomButton>
        </CustomFieldInput>
      </Form>

      <PrivacyPolicyModal isModalVisible={isPolicyModalOpen} setIsModalVisible={setIsPolicyModalOpen}/>
    </CustomModal>
  );
};
