import {API} from "../../../API/API";
import {
  BookingApiAirportListRequest,
  BookingApiGetAirportRequest,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {InitialPage} from "../../constans/pagination/pagination";
import {PageModelLimits} from "../../constans/pagination/pageModelLimits";
import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {Logger} from "../../logger/Logger";
import {getErrorMessage} from "../../errorHelpers/getCommonErrorMessage";
import {IAirport} from "../../../store/reducers/fleetReducer/fleetReducerTypes";
import {mapTimeZoneEntityToITimezone} from "../../../store/reducers/fleetReducer/fleetMappers";

export async function fetchAirports(search?: string): Promise<DebounceSelectOptionProps[]> {
  const req = new BookingApiAirportListRequest()
    .setQuery(search ?? "")
    .setPagemodel(
      new PageModel().setPage(InitialPage).setLimit(PageModelLimits.Select)
    )

  const airportList = await API.getAirportList(req);

  return airportList.getAirportsList().map(a => {
    return {
      key: a.getAirportid(),
      label: `${a.getIcaocode() ? a.getIcaocode() + ", " : ""}${a.getIatacode() ? a.getIatacode() + ", " : ""}${a.getName()}`,
      value: a.getAirportid(),
    }
  })
}

export const getInitialAirportForSelect = (airport: IAirport): DebounceSelectOptionProps => {
  return {
    key: airport.id,
    value: airport.id,
    label: `${airport.icaoCode ? airport.icaoCode + ", " : ""}${airport.iataCode ? airport.iataCode + ", " : ""}${airport.name}`,
  }
}

export async function fetchInitialAirportForSelect(airportId?: number): Promise<DebounceSelectOptionProps[] | undefined> {
  try {
    if (!airportId)
      throw new Error("No airportId was given")

    const req = new BookingApiGetAirportRequest()
      .setAirportid(airportId);

    const airportEntity = (await API.getAirportById(req)).getAirport();
    if (!airportEntity)
      throw new Error("No airportEntity inside getAirportById response")

    const timezoneEntity = airportEntity.getTimezone();
    if (!timezoneEntity)
      throw new Error("No timezoneEntity inside getAirportById response")

    const airport: IAirport = {
      id: airportEntity?.getAirportid(),
      name: airportEntity.getName(),
      icaoCode: airportEntity.getIcaocode(),
      iataCode: airportEntity.getIatacode(),
      countryName: airportEntity.getCountryname(),
      timezone: mapTimeZoneEntityToITimezone(timezoneEntity),
    }

    return [getInitialAirportForSelect(airport)]
  } catch (e) {
    Logger.error("Error while getting airport by id for select: " + getErrorMessage(e));
  }
}