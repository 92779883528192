import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {Col, message, Row} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {IEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {archiveEmptyLeg, clearArchiveEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";


const ArchiveEmptyLegModalMessages = defineMessages({
  success: {
    id: "archiveEmptyLegModal.success",
    defaultMessage: "Empty Leg has been successfully archived.",
  },
  title: {
    id: "archiveEmptyLegModal.title",
    defaultMessage: "Empty Leg archiving",
  },
  warn: {
    id: "archiveEmptyLegModal.warn",
    defaultMessage: "Are you sure you want to archive the Empty Leg? You won't be able to revert this action.",
  },
})

interface IDeleteEmptyLegModal {
  emptyLeg: IEmptyLeg;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ArchiveEmptyLegModal: FC<IDeleteEmptyLegModal> = ({emptyLeg, isModalVisible, setIsModalVisible}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.emptyLegs.delete);

  useEffect(() => {
    return () => {
      dispatch(clearArchiveEmptyLeg());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ArchiveEmptyLegModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  return (
    <CustomModal
      title={intl.formatMessage(ArchiveEmptyLegModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Row gutter={8}>
        <Col span={24}>
          <div>{intl.formatMessage(ArchiveEmptyLegModalMessages.warn)}</div>
        </Col>
      </Row>

      <Row gutter={8} style={{marginTop: 24}}>
        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            onClick={() => setIsModalVisible(false)}
          >
            {intl.formatMessage(CommonButtonMessages.cancel)}
          </CustomButton>
        </Col>

        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            type={'primary'}
            icon={<DeleteOutlined />}
            danger
            loading={isLoading}
            onClick={() => dispatch(archiveEmptyLeg({itemId: emptyLeg.id}))}
          >
            {intl.formatMessage(CommonButtonMessages.archive)}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};
