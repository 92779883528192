import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

export enum AsyncActionStatus {
    none,
    pending,
    resolve,
    reject,
}

export type TCrudItemBase = {
    id: number | string
}

export type AsyncAction<TRejectPayload, TResolvePayload> = {
    status: AsyncActionStatus.none
} | {
    status: AsyncActionStatus.pending
} | {
    status: AsyncActionStatus.resolve
    payload: TResolvePayload
} | {
    status: AsyncActionStatus.reject
    payload: TRejectPayload
}

export type ItemCrudWrap<
    TItem extends TCrudItemBase,

    TUpdateResolvePayload = null,
    TUpdateRejectPayload = string,

    TDeleteResolvePayload = null,
    TDeleteRejectPayload = string,

    TCreateResolvePayload = null,
    TCreateRejectPayload = string,
> = {
    actions: {
        update: AsyncAction<TUpdateRejectPayload, TUpdateResolvePayload>
        delete: AsyncAction<TDeleteRejectPayload, TDeleteResolvePayload>
        create: AsyncAction<TCreateRejectPayload, TCreateResolvePayload>
    }
    item: TItem
}

export type ICRUDQuery<TItem> = {
    filter: Array<{
        field: keyof TItem,
        value: number | string | [number, ...number[]] | [string, ...string[]]
    }>
    sort?: Array<{
        field: keyof TItem,
        order: 'asc' | 'desc',
    }>
}

export type ICrudState<
    TItem extends TCrudItemBase,
    TQuery extends ICRUDQuery<TItem> = ICRUDQuery<TItem>,

    TReadResolvePayload = null,
    TReadRejectPayload = string,

    TUpdateResolvePayload = null,
    TUpdateRejectPayload = string,

    TDeleteResolvePayload = null,
    TDeleteRejectPayload = string,

    TCreateResolvePayload = null,
    TCreateRejectPayload = string,
> = {
    query: TQuery
    items: Array<ItemCrudWrap<TItem,
      TUpdateResolvePayload,
      TUpdateRejectPayload,
      TDeleteResolvePayload,
      TDeleteRejectPayload,
      TCreateResolvePayload,
      TCreateRejectPayload>>
    read: AsyncAction<TReadRejectPayload, TReadResolvePayload>
}

export const createCrudSlice = <
    TItem extends TCrudItemBase,
    TQuery extends ICRUDQuery<TItem> = ICRUDQuery<TItem>,
    TCreateRejectPayload = string,
    TReadRejectPayload = string,
    TUpdateRejectPayload = string,
    TDeleteRejectPayload = string,
    TCreateResolvePayload = null,
    TReadResolvePayload = null,
    TUpdateResolvePayload = null,
    TDeleteResolvePayload = null,
    >(name: string, initialQuery: TQuery) => {
    type TWrap = ItemCrudWrap<
      TItem,
      TUpdateResolvePayload,
      TUpdateRejectPayload,
      TDeleteResolvePayload,
      TDeleteRejectPayload,
      TCreateResolvePayload,
      TCreateRejectPayload
    >

    type TState = ICrudState<
      TItem,
      TQuery,
      TReadResolvePayload,
      TReadRejectPayload,
      TUpdateResolvePayload,
      TUpdateRejectPayload,
      TDeleteResolvePayload,
      TDeleteRejectPayload,
      TCreateResolvePayload,
      TCreateRejectPayload
    >

    const wrapItem = (item: TItem): TWrap => ({
        actions: {
            update: {
                status: AsyncActionStatus.none,
            },
            delete: {
                status: AsyncActionStatus.none,
            },
            create: {
                status: AsyncActionStatus.none,
            }
        },
        item,
    });

    const initialState: TState = {
        query: initialQuery,
        items: [],
        read: {
            status: AsyncActionStatus.none,
        }
    };

    return createSlice({
        name,
        initialState,
        reducers: {
            setQuery: (state, {payload}: PayloadAction<TQuery>) => {
                state.query = payload as Draft<TQuery>;
            },
            resetQuery: (state) => {
                const defaultFilter: ICRUDQuery<TItem> = {
                    filter: [],
                    sort: [{field: 'id', order: 'desc'}]
                }

                state.query = defaultFilter as Draft<TQuery>;
            },
            // Read Reduces
            read: (state) => {
                state.items = [];
                state.read.status = AsyncActionStatus.pending;
            },
            onReadSuccess: (state, {payload}: PayloadAction<{items: TItem[], resolvePayload: TReadResolvePayload}>) => {
                state.items = [
                    ...state.items.filter(item => item.actions.create.status === AsyncActionStatus.pending),
                    ...payload.items.map(item => wrapItem(item)),
                ]  as Draft<TWrap>[];

                state.read = {
                    status: AsyncActionStatus.resolve,
                    payload: payload.resolvePayload as Draft<TReadResolvePayload>,
                };
            },
            onReadFail: (state, {payload}: PayloadAction<{rejectPayload: TReadRejectPayload}>) => {
                state.read = {
                    status: AsyncActionStatus.reject,
                    payload: payload.rejectPayload as Draft<TReadRejectPayload>,
                };
            },
            beforeCreateIfNeedReload: (state) => {
                state.items = state.items.filter(i => i.actions.create.status !== AsyncActionStatus.pending);
                state.read.status = AsyncActionStatus.pending;
            },
            create: (state, payload: PayloadAction<{item: TItem}>) => {
            },
            afterCreate: (state, {payload}: PayloadAction<{item: TItem}>) => {
                const wrappedItem = wrapItem(payload.item);

                wrappedItem.actions.create = {
                    status: AsyncActionStatus.pending,
                };

                state.items.unshift(wrappedItem as Draft<TWrap>);
            },
            onCreateSuccess: (state, {payload}: PayloadAction<{currentId: TItem['id'], createdItem: TItem, resolvePayload: TCreateResolvePayload}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.currentId);

                if (foundItem) {
                    foundItem.item = payload.createdItem as Draft<TItem>;

                    foundItem.actions.create = {
                        status: AsyncActionStatus.resolve,
                        payload: payload.resolvePayload as Draft<TCreateResolvePayload>,
                    }
                }
            },
            onCreateFail: (state, {payload}: PayloadAction<{currentId: TItem['id'], rejectPayload: TCreateRejectPayload, removeItem?: boolean}>) => {
                if (payload.removeItem) {
                    state.items = state.items.filter(i => i.item.id !== payload.currentId);
                } else {
                    const foundItem = state.items.find(i => i.item.id === payload.currentId);

                    if (foundItem) {
                        foundItem.actions.create = {
                            status: AsyncActionStatus.reject,
                            payload: payload.rejectPayload as Draft<TCreateRejectPayload>
                        }
                    }
                }
            },
            update: (state, {payload}: PayloadAction<{item: TItem}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.item.id);

                if (foundItem) {
                    foundItem.actions.update = {
                        status: AsyncActionStatus.pending,
                    }
                }
            },
            onUpdateSuccess: (state, {payload}: PayloadAction<{id: TItem['id'], item: TItem, resolvePayload: TUpdateResolvePayload}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.id);

                if (foundItem) {
                    foundItem.actions.update = {
                        status: AsyncActionStatus.resolve,
                        payload: payload.resolvePayload as Draft<TUpdateResolvePayload>,
                    }

                    foundItem.item = payload.item as Draft<TItem>;
                }
            },
            onUpdateFail: (state, {payload}: PayloadAction<{id: TItem['id'], rejectPayload: TUpdateRejectPayload}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.id);

                if (foundItem) {
                    foundItem.actions.update = {
                        status: AsyncActionStatus.reject,
                        payload: payload.rejectPayload as Draft<TUpdateRejectPayload>,
                    }
                }
            },
            delete: (state, {payload}: PayloadAction<{id: TItem['id']}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.id);

                if (foundItem) {
                    foundItem.actions.delete = {
                        status: AsyncActionStatus.pending,
                    }
                }
            },
            onDeleteSuccess: (state, {payload}: PayloadAction<{id: TItem['id']}>) => {
                state.items = state.items.filter(i => i.item.id !== payload.id);
            },
            onDeleteFail: (state, {payload}: PayloadAction<{id: TItem['id'], rejectPayload: TDeleteRejectPayload}>) => {
                const foundItem = state.items.find(i => i.item.id === payload.id);

                if (foundItem) {
                    foundItem.actions.delete = {
                        status: AsyncActionStatus.reject,
                        payload: payload.rejectPayload as Draft<TDeleteRejectPayload>,
                    }
                }
            }
        }
    });
}
