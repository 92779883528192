import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  changeRegistrationStatus,
  getRegistrationsList, setChangeRegistrationStatusResult,
  setRegistrationsListResult
} from "../../reducers/registrationsReducer/registrationsReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  PageModel, UserAccountApiChangeUserStatusRequest,
  UserAccountApiUserListRequest,
  UserAccountApiUserListResponse, UserAccountApiUserResponse
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {
  IChangeRegistrationStatusPayload,
  IGetRegistrationsListPayload
} from "../../reducers/registrationsReducer/registrationsReducerTypes";


function* getRegistrationListSaga({payload}: PayloadAction<IGetRegistrationsListPayload>) {
  const getRegistrationListReq = new UserAccountApiUserListRequest()
    .setQuery(payload.query)
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.UserCards)
    )
    .setUserstatusesList(payload.userStatuses);

  try {
    const getRegistrationListRes: UserAccountApiUserListResponse = yield call(API.getUserList, getRegistrationListReq);

    yield put(setRegistrationsListResult({
      items: getRegistrationListRes.getUsersList(),
      totalCount: getRegistrationListRes.getTotalcount(),
    }));
  } catch {}
}


function* changeRegistrationStatusSaga({payload}: PayloadAction<IChangeRegistrationStatusPayload>) {
  const changeRegistrationStatusReq = new UserAccountApiChangeUserStatusRequest()
    .setUserid(payload.userId)
    .setUserstatusid(payload.userStatusId);

  try {
    const changeRegistrationStatusRes: UserAccountApiUserResponse
      = yield call(API.changeUserStatus, changeRegistrationStatusReq);

    if (changeRegistrationStatusRes.getResultresponse()?.getIssuccessful()) {
      yield put(setChangeRegistrationStatusResult({
        item: changeRegistrationStatusRes.getUser(),
      }))
    } else {
      yield put(setChangeRegistrationStatusResult({
        errorMessage: getErrorMessageByCode(changeRegistrationStatusRes.getResultresponse()?.getErrormessage()),
      }))
    }
  } catch {}
}


export function* registrationsSaga() {
  yield all([
    takeLatest(getRegistrationsList.type, getRegistrationListSaga),
    takeLatest(changeRegistrationStatus.type, changeRegistrationStatusSaga),
  ])
}