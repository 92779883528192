import {API} from "../../API";
import {
  BookingApiPlaneRequest,
  FileApiFileListRequest, FileApiUploadRequest, FileInfo, PageModel, PlaneEntity
} from "../../../proto/generated/api_entities_pb";
import {ILicense} from "../../../store/reducers/licensesReducer";

export const LicensesDatagate = {
  getLicenses: async (): Promise<ILicense[]> => {
    try {
      const res = await API.fileList(new FileApiFileListRequest()
        .setPagemodel(new PageModel().setPage(0).setLimit(1000))
        .setType(1)
      );

      return res.toObject().filesList.map(fi => ({
        id: fi.fileid,
        name: fi.name,
        path: fi.filepath,
        bytes: null,
      }));
    } catch (e: unknown) {
      throw new Error();
    }
  },
  createLicense: async (license: ILicense): Promise<ILicense> => {
    try {
      const res = await API.unaryUploadFile(new FileApiUploadRequest()
        .setFileinfo(new FileInfo()
          .setName(license.name)
        )
        .setBytes(license.bytes as Uint8Array)
      );

      if (res.toObject().resultresponse?.issuccessful) {
        const file = res.toObject();

        return {
          id: file.fileid,
          name: file.filename,
          path: file.filepath,
          bytes: null,
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      throw new Error();
    }
  },
  deleteLicense: async (id: string): Promise<void> => {
    try {
      const res = await API.fileDelete(new FileApiUploadRequest()
        .setFileinfo(new FileInfo()
          .setFileid(id)
        )
      );

      if (res.getResultresponse()?.getIssuccessful()) {
        return;
      } else {
        throw new Error();
      }
    } catch (e) {
      throw new Error();
    }
  },
}