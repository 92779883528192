import {
  BillingApiCurrencyListRequest, BillingApiCurrencyListResponse,
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";
import {BillingApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";

const client = new BillingApiServiceClient(getProxyUrl(), null, null);

export class BillingApi {
  static async getCurrencyList(req: BillingApiCurrencyListRequest): Promise<BillingApiCurrencyListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BillingApiCurrencyListRequest, BillingApiCurrencyListResponse>(client, client.currencyList, req, resolve, reject)
    })
  }
}