import React, {useEffect, useState} from 'react';
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {Loader} from "../../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../../components/UI/AntCustom/CustomResult";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {ClientCard} from "./components/ClientCard";
import {Row} from "antd";
import {
  clearRequestCallbackList,
  getRequestCallbackList
} from "../../../../store/reducers/requestCallbackReducer/requestCallbackReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";


const MyClientsRequestsPageMessages = defineMessages({
  title: {
    id: "myClientsRequestsPage.title",
    defaultMessage: "Clients",
  }
})

export const MyClientsRequestsPage = () => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const dispatch = useAppDispatch();
  const {
    items, isLoading, totalCount,
  } = useAppSelector(state => state.requestCallback.itemsObject)

  useEffect(() => {
    dispatch(getRequestCallbackList({
      query: searchQuery,
      page: currentPage,
    }));

    return () => {
      dispatch(clearRequestCallbackList());
    }
  }, [])

  const search = () => {
    dispatch(getRequestCallbackList({
      query: searchQuery,
      page: InitialPage,
    }))
    setCurrentPage(InitialPage)
  }

  const loadMore = () => {
    dispatch(getRequestCallbackList({
      query: searchQuery,
      page: currentPage + 1,
    }));
    setCurrentPage(prev => prev + 1);
  }

  return (
    <PageWrapper>
      {/*TODO intl*/}
      <CustomCard title={intl.formatMessage(MyClientsRequestsPageMessages.title)} width={'max'} isContainerCard={true}>
        {/*<Search*/}
        {/*  placeholder={'Поиск'}*/}
        {/*  value={searchQuery}*/}
        {/*  onChange={(e) => setSearchQuery(e.target.value)}*/}
        {/*  onSearch={search}*/}
        {/*/>*/}
        <Row style={{height: '100%'}} gutter={[16, 16]}>
          {items.length === 0
            ? (isLoading ? <Loader/> : <CustomResult title={intl.formatMessage(CommonFormMessages.noData)}/>)
            : items.map((client, idx) => <ClientCard key={idx} client={client}/>)}
        </Row>

        {items.length > 0 && totalCount > items.length &&
          <CustomButton
            loading={isLoading}
            type={'primary'}
            onClick={loadMore}>
            {intl.formatMessage(CommonFormMessages.showMore)}
          </CustomButton>}
      </CustomCard>
    </PageWrapper>
  )
};

