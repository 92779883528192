import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {
  getChatList,
  getChatMessages,
  IGetChatMessagesPayload,
  ISendMessagePayload, receiveMessages,
  sendMessage, setChatListResult,
  setChatMessagesResult
} from "../../reducers/chatReducer/chatReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  CommunicationApiChatListRequest,
  CommunicationApiChatListResponse,
  CommunicationApiChatMessageListRequest,
  CommunicationApiChatMessageListResponse,
  CommunicationApiChatMessageSendRequest, CommunicationApiChatMessageSendResponse,
  PageModel,
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {InitialPage} from "../../../utils/constans/pagination/pagination";
import {Logger} from "../../../utils/logger/Logger";
import {getErrorMessage} from "../../../utils/errorHelpers/getCommonErrorMessage";
import {setLastNotification} from "../../reducers/notificationsReducer/notificationsReducer";
import {
  ISetLastNotificationPayload,
} from "../../reducers/notificationsReducer/notificationsReducerTypes";


function* getChatListSaga() {
  const getChatListReq = new CommunicationApiChatListRequest()

  try {
    const getChatListRes: CommunicationApiChatListResponse = yield call(API.chatList, getChatListReq);

    yield put(setChatListResult({
      chats: getChatListRes.getChatsList(),
    }))
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}

function* getChatMessagesSaga({payload}: PayloadAction<IGetChatMessagesPayload>) {
  const getMessagesReq = new CommunicationApiChatMessageListRequest()
    .setChatid(payload.chatId)
    .setPagemodel(new PageModel()
      .setPage(InitialPage)
      .setLimit(100)
    );

  try {
    const getMessagesRes: CommunicationApiChatMessageListResponse = yield call(API.chatMessageList, getMessagesReq);

    yield put(setChatMessagesResult({
      messages: getMessagesRes.getChatmessagesList(),
      totalCount: getMessagesRes.getTotalcount(),
    }))
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}

function* sendMessageSaga({payload}: PayloadAction<ISendMessagePayload>) {
  const req = new CommunicationApiChatMessageSendRequest()
    .setChatid(payload.chatId)
    .setTouserid(payload.toUserId)
    .setMessage(payload.messageText);

  try {
    const res: CommunicationApiChatMessageSendResponse = yield call(API.chatMessageSend, req);
    const message = res.getChatmessage()

    if (res.getResultresponse() && message)
      yield put(receiveMessages({messages: [message]}));
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}

function* receiveMessageSaga({payload}: PayloadAction<ISetLastNotificationPayload>) {
  const currentChatId: string | undefined = yield select(state => state.chat.currentChatId);

  try {
    if (currentChatId) {
      const messagesCurrentChatReceived = payload.items.filter(n => n.getEntityid() === currentChatId);

      if (messagesCurrentChatReceived.length > 0) {
        const req = new CommunicationApiChatMessageListRequest()
          .setChatid(currentChatId)
          .setPagemodel(new PageModel()
            .setPage(InitialPage)
            .setLimit(messagesCurrentChatReceived.length)
          )

        const res: CommunicationApiChatMessageListResponse = yield call(API.chatMessageList, req);

        yield put(receiveMessages({messages: res.getChatmessagesList()}))
      }
    }
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }

}

export function* chatSaga() {
  yield all([
    takeEvery(getChatList.type, getChatListSaga),
    takeEvery(getChatMessages.type, getChatMessagesSaga),
    takeEvery(sendMessage.type, sendMessageSaga),
    takeEvery(setLastNotification.type, receiveMessageSaga),
  ]);
}