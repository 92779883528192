import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonClearCreate,
  initCommonClearDelete,
  initCommonClearEdit,
  initCommonClearList,
  initCommonCreate,
  initCommonCreateResult,
  initCommonDelete,
  initCommonDeleteResult,
  initCommonEdit,
  initCommonEditResult,
  initCommonGetList,
  initCommonSetListResult,
} from "../highOrderReducers/commonReducer";
import {
  ICreatePlanePayload, IDeletePlanePayload, IEditPlanePayload,
  IFleetInitialState,
  IGetPlaneListPayload, ISetCreatePlaneResultPayload, ISetDeletePlaneResultPayload, ISetEditPlaneResultPayload,
  ISetPlaneListResultPayload
} from "./fleetReducerTypes";
import {mapPlaneEntityToIPlane} from "./fleetMappers";

const fleetInitialState: IFleetInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  create: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  edit: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const fleetSlice = createSlice({
  name: 'fleet',
  initialState: fleetInitialState,
  reducers: {
    getPlaneList(state, {payload}: PayloadAction<IGetPlaneListPayload>) {
      initCommonGetList(state, payload);
    },
    clearPlaneList(state) {
      initCommonClearList(state);
    },
    setPlaneListResult(state, {payload}: PayloadAction<ISetPlaneListResultPayload>) {
      initCommonSetListResult(state, payload, mapPlaneEntityToIPlane);
    },

    createPlane(state, {payload}: PayloadAction<ICreatePlanePayload>) {
      initCommonCreate(state, payload);
    },
    clearCreatePlane(state) {
      initCommonClearCreate(state);
    },
    setCreatePlaneResult(state, {payload}: PayloadAction<ISetCreatePlaneResultPayload>) {
      initCommonCreateResult(state, payload, mapPlaneEntityToIPlane)
    },

    editPlane(state, {payload}: PayloadAction<IEditPlanePayload>) {
      initCommonEdit(state, payload);
    },
    clearEditPlane(state) {
      initCommonClearEdit(state);
    },
    setEditPlaneResult(state, {payload}: PayloadAction<ISetEditPlaneResultPayload>) {
      initCommonEditResult(state, payload, mapPlaneEntityToIPlane);
    },

    deletePlane(state, {payload}: PayloadAction<IDeletePlanePayload>) {
      initCommonDelete(state, payload);
    },
    clearDeletePlane(state) {
      initCommonClearDelete(state);
    },
    setDeletePlaneResult(state, {payload}: PayloadAction<ISetDeletePlaneResultPayload>) {
      initCommonDeleteResult(state, payload);
    },
  },
});

export const {
  getPlaneList,
  clearPlaneList,
  setPlaneListResult,

  createPlane,
  clearCreatePlane,
  setCreatePlaneResult,

  editPlane,
  clearEditPlane,
  setEditPlaneResult,

  deletePlane,
  clearDeletePlane,
  setDeletePlaneResult,
} = fleetSlice.actions;
export default fleetSlice.reducer;