import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Input, message} from "antd";
import {ScheduleType} from "../../../../../proto/generated/api_entities_pb";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {clearEditEvent, editEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducer";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {
  fetchAirports,
  fetchInitialAirportForSelect,
  getInitialAirportForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {ScheduleDeleteModal} from "./ScheduleDeleteModal";
import {DeleteOutlined} from "@ant-design/icons";
import {dayjsWithOffset} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {
  fetchOwnPlanes,
  getInitialOwnPlaneForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchOwnPlanes";
import {IPlaneEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducerTypes";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const ScheduleEditModalMessages = defineMessages({
  title: {
    id: "scheduleEditModal.title",
    defaultMessage: "Edit event",
  },
  success: {
    id: "scheduleEditModal.success",
    defaultMessage: "The event has been successfully updated",
  }
})

interface IScheduleCreateFormData {
  planeId: number;
  dateRange: [Dayjs, Dayjs];
  airportFromId: number;
  airportToId?: number;
  eventName?: string;
}

interface IScheduleCreateModal {
  planeEvent: IPlaneEvent;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ScheduleEditModal: FC<IScheduleCreateModal> = ({planeEvent, isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.schedule.edit);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearEditEvent());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ScheduleEditModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IScheduleCreateFormData) => {
    dispatch(editEvent({
      eventId: planeEvent.id,
      eventType: planeEvent.eventType,
      eventName: values.eventName,
      planeId: values.planeId,
      dateFrom: values.dateRange[0].toDate(),
      dateTo: values.dateRange[1].toDate(),
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IScheduleCreateFormData = {
    planeId: planeEvent.plane.id,
    dateRange: [dayjsWithOffset(planeEvent.dateFrom, 0), dayjsWithOffset(planeEvent.dateTo, 0)],
    airportFromId: planeEvent.airportFrom.id,
    airportToId: planeEvent.airportTo?.id,
    eventName: planeEvent.eventName,
  }

  return (
    <CustomModal
      title={intl.formatMessage(ScheduleEditModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.planeLabel)}
            name={'planeId'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <DebounceSelect
              fetchOptions={fetchOwnPlanes}
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
              defaultOptions={[getInitialOwnPlaneForSelect(planeEvent.plane)]}
            />
          </CustomFieldInput>
        </Col>

        {planeEvent.eventType === ScheduleType.CUSTOM &&
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.eventNameLabel)}
              name={'eventName'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.eventNamePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        }

        <Col flex={1}>
          <CustomFieldInput
            label={`${intl.formatMessage(CommonFormMessages.availableDatesLabel)} (UTC)`}
            name={'dateRange'}
            rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
          >
            <CustomDatePicker.RangePicker showTime format={"DD.MM.YYYY HH:mm"}
                                          placeholder={[intl.formatMessage(CommonUnitMessages.from), intl.formatMessage(CommonUnitMessages.to)]}
                                          inputReadOnly={true} placement={"bottomLeft"}
                                          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </CustomFieldInput>
        </Col>

        {planeEvent.eventType !== ScheduleType.CUSTOM &&
          <>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
                name={'airportFromId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                  showSearch={true}
                  defaultOptions={[getInitialAirportForSelect(planeEvent.airportFrom)]}
                />
              </CustomFieldInput>
            </Col>

            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
                name={'airportToId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                  showSearch={true}
                  defaultOptions={planeEvent.airportTo && [getInitialAirportForSelect(planeEvent.airportTo)]}
                />
              </CustomFieldInput>
            </Col>
          </>
        }

        {planeEvent.eventType === ScheduleType.CUSTOM &&
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.stayAirportLabel)}
              name={'airportFromId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                fetchOptions={fetchAirports}
                placeholder={intl.formatMessage(CommonFormMessages.stayAirportPlaceholder)}
                showSearch={true}
                defaultOptions={[getInitialAirportForSelect(planeEvent.airportFrom)]}
              />
            </CustomFieldInput>
          </Col>
        }

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(CommonButtonMessages.edit)}
          </CustomButton>
        </CustomFieldInput>

        <CustomButton type={"link"} block danger onClick={() => setIsDeleteModalVisible(true)}>
          <DeleteOutlined/> {intl.formatMessage(CommonButtonMessages.delete)}
        </CustomButton>
      </Form>

      {isDeleteModalVisible &&
        <ScheduleDeleteModal planeEvent={planeEvent} isModalVisible={isDeleteModalVisible}
                             setIsModalVisible={setIsDeleteModalVisible}
                             closeOuterEditModal={() => setIsModalVisible(false)}/>}
    </CustomModal>
  );
};
