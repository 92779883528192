import React, {FC, useState} from 'react';
import styled from "styled-components";
import {CustomCard} from "../../../../../../components/UI/AntCustom/CustomCard";
import {IManager} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducerTypes";
import Button from "antd/es/button/button";
import {CardBottomButtons} from "../../../../../../components/UI/AntCustom/CardBottomButtons";
import {ReactComponent as EmailIcon} from "../../../../../../media/icons/email.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../media/icons/phone.svg";
import {roles} from "../../../../../../config/acl/roles";
import {EditManagerModal} from "./EditManagerModal";
import {ChangeManagerPasswordModal} from "./ChangeManagerPasswordModal";
import {DisplayManagerFullName} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PropertyTitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #6B6B6B;
`;

const PropertyValue = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #31CA91;
  }
`;

const PropertyValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0 16px 0;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    
    ${PropertyTitle} {
      :not(:first-child) {
        margin-top: 19px;
      }
    }
  }
`;


const ProfileManagerCardMessages = defineMessages({
  changePassword: {
    id: "profileManagerCard.changePassword",
    defaultMessage: "Change password",
  }
})

interface IProfileManagerCard {
  manager: IManager;
}

export const ProfileManagerCard: FC<IProfileManagerCard> = ({manager}) => {
  const intl = useIntl();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);

  return (
    <CardWrapper>
      <CustomCard title={DisplayManagerFullName(manager)} width={"max"} noBottomBorderRadius={true}>
        <CardContent>
          <PropertyTitle>{intl.formatMessage(CommonDetailsMessages.role)}</PropertyTitle>
          <PropertyValue>{intl.formatMessage(roles[manager.role].name)}</PropertyValue>

          <PropertyTitle>{intl.formatMessage(CommonDetailsMessages.contacts)}</PropertyTitle>
          <PropertyValuesWrapper>
            <PropertyValue>
              <EmailIcon/>
              <div>{manager.email}</div>
            </PropertyValue>
            <PropertyValue>
              <PhoneIcon/>
              <div>+{manager.phone}</div>
            </PropertyValue>
          </PropertyValuesWrapper>

          <PropertyTitle>{intl.formatMessage(CommonDetailsMessages.subscription)}</PropertyTitle>
          <PropertyValue>
            <span/>
            <div>{intl.formatMessage(CommonDetailsMessages.subscriptionActive)}</div>
          </PropertyValue>
        </CardContent>
      </CustomCard>
      <CardBottomButtons>
        <Button ghost={true} type={"primary"} onClick={() => setIsChangePasswordModalOpen(true)}>{intl.formatMessage(ProfileManagerCardMessages.changePassword)}</Button>
        <Button type={'primary'} onClick={() => setIsEditModalOpen(true)}>{intl.formatMessage(CommonButtonMessages.edit)}</Button>
      </CardBottomButtons>

      {isEditModalOpen &&
        <EditManagerModal manager={manager} isModalVisible={isEditModalOpen} setIsModalVisible={setIsEditModalOpen}/>}

      {isChangePasswordModalOpen &&
        <ChangeManagerPasswordModal manager={manager} isModalVisible={isChangePasswordModalOpen}
                                    setIsModalVisible={setIsChangePasswordModalOpen}/>}
    </CardWrapper>
  );
};
