import {AuthApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {
  AuthApiForgotPasswordCheckCodeRequest,
  AuthApiForgotPasswordRequest,
  AuthApiForgotPasswordSetupRequest,
  AuthApiRegistrationRequest,
  AuthApiRequest,
  AuthApiResponse,
  ResultResponse
} from "../../proto/generated/api_entities_pb";
import {Metadata} from "grpc-web";
import {store} from "../../store/store";
import {setIsAuthenticated} from "../../store/reducers/authReducer/authReducer";
import {API} from "../API";
import {Logger} from "../../utils/logger/Logger";
import {roleFromBackendIdentifier} from "../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {Role} from "../../config/acl/roles/Role";
import {setUserResult} from "../../store/reducers/userReducer/userReducer";
import {setCompanyResult} from "../../store/reducers/companyReducer/companyReducer";

const client = new AuthApiServiceClient(getProxyUrl(), null, null);

export class AuthApi {
  static async login(req: AuthApiRequest): Promise<AuthApiResponse> {
    return new Promise((resolve, reject) => {
      API.call<AuthApiRequest, AuthApiResponse>(client, client.auth, req, resolve, reject);
    });
  }

  static async refreshToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const refreshToken: string = localStorage.getItem('refreshToken') || "";
      const metadata: Metadata = {
        Authorization: refreshToken
      }

      const refreshTokenReq = new AuthApiRequest().setRefreshtoken(refreshToken);
      client.auth(refreshTokenReq, metadata, (err, response) => {
        const success = response?.getResultresponse()?.getIssuccessful() || false;

        if (err)
          Logger.error(err.message);

        store.dispatch({
          type: setIsAuthenticated.type,
          payload: {
            isAuthenticated: success,
            accessToken: response?.getAccesstoken(),
            refreshToken: response?.getRefreshtoken(),
            user: response?.getUser(),
            role: success ? roleFromBackendIdentifier(response?.getRole()) : Role.guest,
          }
        });

        const user = response.getUser();
        if (user) {
          store.dispatch(setUserResult({item: user}));

          const company = user.getCompany();
          if (company)
            store.dispatch(setCompanyResult({item: company}));
        }


        if (success)
          resolve(success);
        else
          reject();
      });
    });
  }

  static async register(req: AuthApiRegistrationRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<AuthApiRegistrationRequest, ResultResponse>(client, client.registration, req, resolve, reject);
    });
  }

  static async forgotPassword(req: AuthApiForgotPasswordRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<AuthApiForgotPasswordRequest, ResultResponse>(client, client.forgotPassword, req, resolve, reject);
    });
  }

  static async checkConfirmationCode(req: AuthApiForgotPasswordCheckCodeRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<AuthApiForgotPasswordCheckCodeRequest, ResultResponse>(client, client.forgotPasswordCheckCode, req, resolve, reject);
    });
  }

  static async setNewPassword(req: AuthApiForgotPasswordSetupRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<AuthApiForgotPasswordSetupRequest, ResultResponse>(client, client.forgotPasswordSetup, req, resolve, reject);
    });
  }
}