import {all, call, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  CurrencyEntity,
  FileEntity,
  UserAccountApiCompanyRequest,
  UserAccountApiCompanyResponse,
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {
  editCompany, loadCertificates,
  loadCompanyAvatar,
  setEditCompanyResult, setLoadCertificatesResult,
  setLoadCompanyAvatarResult,
} from "../../reducers/companyReducer/companyReducer";
import {
  IEditCompanyPayload, ILoadCompanyAvatarPayload,
  ILoadCompanyCertificatesPayload,
} from "../../reducers/companyReducer/companyReducerTypes";
import {UploadFileResponseType} from "../../../utils/helpers/fileHelpers/fileTypes";
import {getFileInfoAfterUpload} from "../../../utils/helpers/fileHelpers/getFileInfoAfterUpload";
import {mapICompanyToCompanyEntity} from "../../reducers/companyReducer/companyMappers";


function* editCompanySaga({payload}: PayloadAction<IEditCompanyPayload>) {
  const company = mapICompanyToCompanyEntity(payload.company);

  payload.description && company.setDescription(payload.description);
  payload.email && company.setEmail(payload.email);
  payload.phone && company.setPhone(payload.phone);
  payload.address && company.setAddress(payload.address);
  payload.website && company.setWebsite(payload.website);
  payload.currencyId && company.setCurrency(new CurrencyEntity().setCurrencyid(payload.currencyId));

  const req = new UserAccountApiCompanyRequest()
    .setCompany(company)

  try {
    const res: UserAccountApiCompanyResponse
      = yield call(API.editCompany, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setEditCompanyResult({
        item: res.getCompany(),
      }));
    } else {
      yield put(setEditCompanyResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}

function* loadCertificatesSaga({payload}: PayloadAction<ILoadCompanyCertificatesPayload>) {
  try {
    const mappedCompany = mapICompanyToCompanyEntity(payload.company);

    const registrationCertificateFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.registrationCertificate);
    mappedCompany.setRegistrationcertificate(new FileEntity()
      .setFileid(registrationCertificateFile.fileId)
      .setName(registrationCertificateFile.fileName)
      .setFilepath(registrationCertificateFile.filePath)
    )

    if (payload.operatorCertificate) {
      const operatorCertificateFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.operatorCertificate);
      mappedCompany.setOperatorcard(new FileEntity()
        .setFileid(operatorCertificateFile.fileId)
        .setName(operatorCertificateFile.fileName)
        .setFilepath(operatorCertificateFile.filePath)
      )
    }

    const req = new UserAccountApiCompanyRequest()
      .setCompany(mappedCompany)

    const res: UserAccountApiCompanyResponse
      = yield call(API.editCompany, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setLoadCertificatesResult({
        company: res.getCompany(),
      }));
    } else {
      yield put(setLoadCertificatesResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* loadCompanyAvatarSaga({payload}: PayloadAction<ILoadCompanyAvatarPayload>) {
  try {
    const avatarFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.file);

    const mappedCompany = mapICompanyToCompanyEntity(payload.company);
    mappedCompany.setAvatar(new FileEntity()
      .setFileid(avatarFile.fileId)
      .setName(avatarFile.fileName)
      .setFilepath(avatarFile.filePath)
    )

    const req = new UserAccountApiCompanyRequest()
      .setCompany(mappedCompany)

    const res: UserAccountApiCompanyResponse
      = yield call(API.editCompany, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setLoadCompanyAvatarResult({
        company: res.getCompany(),
      }));
    } else {
      yield put(setLoadCompanyAvatarResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


export function* companySaga() {
  yield all([
    takeLatest(editCompany.type, editCompanySaga),
    takeLatest(loadCertificates.type, loadCertificatesSaga),
    takeLatest(loadCompanyAvatar.type, loadCompanyAvatarSaga),
  ])
}