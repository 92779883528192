import React, {Dispatch, FC, SetStateAction} from 'react';
import {Typography} from "antd";
import Button from 'antd/es/button/button';
import {CustomModal} from "../../UI/AntCustom/CustomModal";
import {defineMessages, useIntl} from "react-intl";

const PrivacyPolicyModalMessages = defineMessages({
  title: {
    id: "privacyPolicyModal.title",
    defaultMessage: "Privacy policy"
  }
})


interface IPrivacyPolicyModal {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const PrivacyPolicyModal: FC<IPrivacyPolicyModal> = ({isModalVisible, setIsModalVisible}) => {
  const intl = useIntl();

  return (
    <CustomModal title={intl.formatMessage(PrivacyPolicyModalMessages.title)}
           visible={isModalVisible}
           onCancel={() => setIsModalVisible(false)}
           centered={true}
           footer={<Button type={'primary'} onClick={() => setIsModalVisible(false)}>OK</Button>}
           style={{padding: "24px 0", minWidth: "90vw"}}
    >
      <Typography.Title level={3}>Privacy Policy</Typography.Title>
      <Typography>This Personal Data Privacy Policy (hereinafter referred to as the Privacy Policy) applies to all information that the Jetsnode.com website (hereinafter referred to as the platform) located on the Jetsnode.com domain name (as well as its subdomains) can receive about the User while using the site Jetsnode.com (as well as its subdomains), its programs and its products.</Typography>
      <Typography>1.Definition of terms</Typography>
      <Typography>1.1. This Privacy Policy uses the following terms:</Typography>
      <Typography>1.1.1. «Site Administration» (hereinafter — the Administration) — authorized employees to manage the Jetsnode website, who organize and (or) process personal data, and also determine the goals processing of personal data, the composition of personal data to be processed, actions (operations) performed with personal data.</Typography>
      <Typography>1.1.2. «Personal data» — any information relating to a directly or indirectly identified or identifiable natural person (subject of personal data).</Typography>
      <Typography>1.1.3. «Processing of personal data» — any action (operation) or a set of actions (operations) performed with the use of automation tools or without the use of such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.</Typography>
      <Typography>1.1.4. «Confidentiality of personal data» is a mandatory requirement for the Operator or other person who has gained access to personal data to prevent their distribution without the consent of the subject of personal data or other legal grounds.</Typography>
      <Typography>1.1.5. «The Jetsnode website» is a collection of interconnected web pages hosted on the Internet at a unique address (URL): Jetsnode.com, as well as its subdomains.</Typography>
      <Typography>1.1.6. «Subdomains» — these are pages or a set of pages located on third—level domains belonging to the site Jetsnode, as well as other temporary pages, at the bottom of which the contact information of the Administration is indicated.</Typography>
      <Typography>1.1.7. «User of the Jetsnode website» (hereinafter referred to as the User) is a capable individual who has access to the Jetsnode website via the Internet and uses the information, materials and products of the Jetsnode website.</Typography>
      <Typography>1.1.8. «Cookies» — a small piece of data sent by a web server and stored on a user's computer, which a web client or web browser sends to the web server in an HTTP request each time it tries to open a page on the corresponding site.</Typography>
      <Typography>1.1.9. «IP address» — a unique network address of a node in a computer network through which the User gains access to the platform.</Typography>
      <Typography>1.1.10. «Service» — a set of services and a license provided to the Jetsnode Platform User» using the «Jetsnode Platform».</Typography>
      <Typography>2.General provisions</Typography>
      <Typography>2.1. Use of the Jetsnode website by the User means acceptance of this Privacy Policy and the terms of processing the User's personal data.</Typography>
      <Typography>2.2. In case of disagreement with the terms of the Privacy Policy, the User must stop using the Jetsnode website.</Typography>
      <Typography>2.3. This Privacy Policy applies to the Jetsnode website. the platform does not control and is not responsible for the websites of third parties, to which the User can follow the links available on the Jetsnode  website.</Typography>
      <Typography>2.4. The Administration does not verify the accuracy of personal data provided by the User.</Typography>
      <Typography>3. Subject of the privacy policy</Typography>
      <Typography>3.1. This Privacy Policy establishes the obligations of the Administration to not disclose and ensure the confidentiality of personal data that the User provides at the request of the Administration when registering on the Jetsnode website, when subscribing to an e-mail newsletter or when placing an order.</Typography>
      <Typography>3.2. Personal data authorized for processing under this Privacy Policy is provided by the User by filling out forms on the Jetsnode website and includes the following information:</Typography>
      <Typography>3.2.1. surname, name, patronymic of the User;</Typography>
      <Typography>3.2.2. contact phone number of the User;</Typography>
      <Typography>3.2.3. E-mail address (e-mail)</Typography>
      <Typography>3.2.4. place of residence of the User (if necessary)</Typography>
      <Typography>3.2.5. delivery address of the Goods (if necessary)</Typography>
      <Typography>3.2.6. photograph (if necessary).</Typography>
      <Typography>3.3. The platform protects Data that is automatically transmitted when visiting pages:</Typography>
      <Typography>IP address;</Typography>
      <Typography>information from cookies;</Typography>
      <Typography>browser information</Typography>
      <Typography>access time;</Typography>
      <Typography>referrer (url of the previous page).</Typography>
      <Typography>3.3.1. Disabling cookies may result in the inability to access parts of the site that require authorization.</Typography>
      <Typography>3.3.2. The platform collects statistics about the IP addresses of its visitors. This information is used to prevent, detect and solve technical problems..</Typography>
      <Typography>3.4. Any other personal information not specified above (visit history, browsers used, operating systems, etc.) is subject to secure storage and non-distribution, except as provided in paragraphs. 5.2. and 5.3. of this Privacy Policy.</Typography>
      <Typography>4. Purposes of collecting personal information of the user</Typography>
      <Typography>4.1. The Administration can use the User's personal data for the following purposes:</Typography>
      <Typography>4.1.1. Identification of the User registered on the Jetsnode website for its further authorization, placing an order and other actions.</Typography>
      <Typography>4.1.2. Providing the User with access to personalized data of the Jetsnode  website.</Typography>
      <Typography>4.1.3. Establishing feedback with the User, including sending notifications, requests regarding the use of the Jetsnode website, the provision of services and the processing of requests and applications from the User.</Typography>
      <Typography>4.1.4. Determining the location of the User to ensure security, prevent fraud.</Typography>
      <Typography>4.1.5. Confirmation of the accuracy and completeness of personal data provided by the User.</Typography>
      <Typography>4.1.6. Creating an account for using parts of the site Jetsnode, if the User has agreed to create an account.</Typography>
      <Typography>4.1.7. User notifications by email.</Typography>
      <Typography>4.1.8. Providing the User with effective technical support in case of problems related to the use of theJetsnode website.</Typography>
      <Typography>4.1.9. Providing the User, with his consent, with special offers, pricing information, newsletters and other information on behalf of the Jetsnode website.</Typography>
      <Typography>4.1.10. Implementation of advertising activities with the consent of the User.</Typography>
      <Typography>5. Methods and terms of processing personal information</Typography>
      <Typography>5.1. The processing of the User's personal data is carried out without time limit, in any legal way, including in personal data information systems using automation tools or without using such tools.</Typography>
      <Typography>5.2. The User agrees that the Administration has the right to transfer personal data to third parties, in particular, courier services, postal organizations (including electronic), telecommunication operators, solely for the purpose of fulfilling the User's order placed on the Jetsnode website, including delivery of the Goods, documentation or e-mail messages.</Typography>
      <Typography>5.3. The User's personal data may be transferred to the authorized state authorities of the Netherlands only on the grounds and in the manner established by the legislation of the Netherlands.</Typography>
      <Typography>5.4. In case of loss or disclosure of personal data, the Administration has the right not to inform the User about the loss or disclosure of personal data.</Typography>
      <Typography>5.5. The Administration takes the necessary organizational and technical measures to protect the User's personal information from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, as well as from other illegal actions of third parties.</Typography>
      <Typography>5.6. The Administration, together with the User, takes all necessary measures to prevent losses or other negative consequences caused by the loss or disclosure of the User's personal data.</Typography>
      <Typography>6. Rights and obligations of the parties</Typography>
      <Typography>6.1.The user has the right:</Typography>
      <Typography>6.1.1. Make a free decision to provide your personal data necessary for using the Jetsnode website, and give consent to their processing.</Typography>
      <Typography>6.1.2. Update, supplement the provided information about personal data in case of changes in this information.</Typography>
      <Typography>6.1.3. The user has the right to receive information from the Administration regarding the processing of his personal data, if such right is not limited in accordance with federal laws. The user has the right to require the Administration to clarify his personal data, block or destroy them if the personal data is incomplete, outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing, as well as take legal measures to protect their rights.</Typography>
      <Typography>6.2. The administration is obliged:</Typography>
      <Typography>6.2.1. Use the information received solely for the purposes specified in clause 4 of this Privacy Policy.</Typography>
      <Typography>6.2.2. Ensure that confidential information is kept secret, not disclosed without the prior written permission of the User, and also not to sell, exchange, publish, or disclose in other possible ways the transferred personal data of the User, with the exception of clauses. 5.2 and 5.3. of this Privacy Policy.</Typography>
      <Typography>6.2.3. Take precautions to protect the confidentiality of the User's personal data in accordance with the procedure usually used to protect this kind of information in existing business transactions.</Typography>
      <Typography>6.2.4. Block personal data relating to the relevant User from the moment the User, or his legal representative or authorized body for the protection of the rights of personal data subjects, is contacted or requested for the period of verification, in case of revealing inaccurate personal data or illegal actions.</Typography>
      <Typography>7. Responsibility of the parties</Typography>
      <Typography>7.1. The Administration, which has not fulfilled its obligations, is liable for losses incurred by the User in connection with the unlawful use of personal data, in accordance with the legislation of the Netherlands, with the exception of cases provided for in paragraphs. 5.2., 5.3. and 7.2. of this Privacy Policy.</Typography>
      <Typography>7.2. In case of loss or disclosure of Confidential Information, the Administration is not responsible if this confidential information:</Typography>
      <Typography>7.2.1. Became public property before its loss or disclosure.</Typography>
      <Typography>7.2.2. It was received from a third party until it was received by the Resource Administration.</Typography>
      <Typography>7.2.3. Was disclosed with the consent of the User.</Typography>
      <Typography>7.3. The user is fully responsible for compliance with the requirements of the legislation of the Netherlands, including laws on advertising, on the protection of copyright and related rights, on the protection of trademarks and service marks, but not limited to the above, including full responsibility for the content and form of materials.</Typography>
      <Typography>7.4. The User acknowledges that the responsibility for any information (including, but not limited to: data files, texts, etc.) to which he may have access as part of the site Jetsnode is borne by the person who provided such information.</Typography>
      <Typography>7.5. The User agrees that the information provided to him as part of the site **Jetsnode ** may be an object of intellectual property, the rights to which are protected and belong to other Users, partners or advertisers who post such information on the site Jetsnode. The User does not have the right to make changes, lease, loan, sell, distribute or create derivative works based on such Content (in whole or in part), except in cases when such actions were expressly authorized in writing by the owners of such Content in accordance with the terms of a separate agreement.</Typography>
      <Typography>7.6. In relation to text materials (articles, publications that are freely available on the site Jetsnode) their distribution is allowed, provided that a link to the platform is given.</Typography>
      <Typography>7.7. The Administration is not responsible to the User for any loss or damage incurred By the User as a result of deletion, failure or inability to save any Content and other communication data contained on the website Jetsnode or transmitted through it.</Typography>
      <Typography>7.8. The Administration is not responsible for any direct or indirect losses that occurred due to: use or inability to use the site or individual services; unauthorized access to communications. The User; statements or behavior of any third party on the site.</Typography>
      <Typography>7.9. The Administration is not responsible for any information posted by the user on the site Jetsnode, including, but not limited to: copyrighted information without the express consent of the copyright owner.</Typography>
      <Typography>8. Dispute Resolution</Typography>
      <Typography>8.1. Before applying to the court with a claim for disputes arising from the relationship between the User and the Administration, it is mandatory to submit a claim (a written proposal or an electronic proposal for a voluntary settlement of the dispute).</Typography>
      <Typography>8.2. The recipient of the claim within 30 calendar days from the date of receipt of the claim, notifies the applicant of the claim in writing or electronically about the results of consideration of the claim.</Typography>
      <Typography>8.3. If an agreement is not reached, the dispute will be referred to the Court of Amsterdam</Typography>
      <Typography>8.4. The current legislation of the Netherlands applies to this Privacy Policy and the relations between the User and the Administration .</Typography>
      <Typography>9. Additional terms</Typography>
      <Typography>9.1. The Administration has the right to make changes to this Privacy Policy without the User's consent.</Typography>
      <Typography>9.2. The new Privacy Policy comes into force from the moment it is posted on the website Jetsnode, unless otherwise provided by the new version of the Privacy Policy.</Typography>
      <Typography>9.3. All suggestions or questions regarding this Privacy Policy should be reported to: admininfo@jetsnode.com</Typography>
    </CustomModal>
  );
};
