import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useMemo} from 'react';
import {CustomDrawer} from '../../../../../../components/UI/AntCustom/CustomDrawer';
import styled from "styled-components";
import {defineMessages, useIntl} from "react-intl";
import {FlyHourPriceCalculatorGroup, GroupFormOptionKeys} from "./FlyHourPriceCalculatorGroup";
import {FlyHourPriceCalculatorTotal} from "./FlyHourPriceCalculatorTotal";
import {ReactComponent as ExitIcon} from "../../../../../../media/icons/caret_left_black.svg"
import {FlyHourPriceContext} from "./context/FlyHourPriceContextProvider";
import {
  FlyHourPriceDefaultSubcategoryEnum
} from "./types/flyHourPriceSettingEnums";
import {getFlyHourPriceCalculation} from "./helpers/getFlyHourPriceCalculation";
import {RenderConfirmModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";


const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
  max-width: 513px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 32px;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 11px;
  align-items: center;
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

const SubtitleWarn = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #E99700;
`;

const CalculatorContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  justify-content: flex-start;
`;

const TotalAndExit = styled.div`
  position: relative;
`;

const TotalAndExitStickyContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: sticky;
  top: 0;
`;

const CalculatorGroups = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-grow: 1;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.7;
  }
`;


const FlyHourPriceCalculatorDrawerMessages = defineMessages({
  title: {
    id: "flyHourPriceCalculatorDrawer.title",
    defaultMessage: "Aircraft flight hour cost calculator",
  },
  description: {
    id: "flyHourPriceCalculatorDrawer.description",
    defaultMessage: "Important: all values are entered for a period of 1 year",
  },
  subtitle: {
    id: "flyHourPriceCalculatorDrawer.subtitle",
    defaultMessage: "Calculator",
  },
  unlockWarn: {
    id: "flyHourPriceCalculatorDrawer.unlockWarn",
    defaultMessage: "Enter the number of flying hours per year and currency to unlock the calculator",
  },
  exit: {
    id: "flyHourPriceCalculatorDrawer.exit",
    defaultMessage: "Exit",
  },
  totalSum: {
    id: "flyHourPriceCalculatorDrawer.totalSum",
    defaultMessage: "Specify the total amount",
  },
  calculateSeparately: {
    id: "flyHourPriceCalculatorDrawer.calculateSeparately",
    defaultMessage: "Calculate separately",
  },
  saveAndExitWarningText: {
    id: "flyHourPriceCalculatorDrawer.saveAndExitWarningText",
    defaultMessage: "You have unsaved changes. Continue?",
  },
  exitWarningText: {
    id: "flyHourPriceCalculatorDrawer.warningText",
    defaultMessage: "Are you sure you want to exit without completing the calculator? Changes will not be applied.",
  }
})

interface IFlyHourPriceCalculatorDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const FlyHourPriceCalculatorDrawer: FC<IFlyHourPriceCalculatorDrawer> = ({isOpen, setIsOpen}) => {
  const intl = useIntl();
  const {
    flyHourPrice,
    setFlyHourPrice,
    flyHoursCount,
    settingEntities, setSettingEntities,
    currency,
    setSavedFlyHoursCount,
    setSavedCurrency,
    setSavedFlyHourPrice,
    setSavedSettingEntities,
    lastSavedConstantCategory, setLastSavedConstantCategory,
    lastSavedChangeableCategory, setLastSavedChangeableCategory,
    lastSavedCoefficientCategory, setLastSavedCoefficientCategory,
    lastSavedMarginCategory, setLastSavedMarginCategory,
    categoriesSaving,
    setIsCalculateLoading,
    setSavedConstantCategory, setSavedChangeableCategory, setSavedMarginCategory, setSavedCoefficientCategory,
  } = useContext(FlyHourPriceContext);

  useEffect(() => {
    (async () => {
      if (flyHoursCount) {
        const {price, settings} = await getFlyHourPriceCalculation(flyHoursCount, [
          lastSavedConstantCategory,
          lastSavedChangeableCategory,
          lastSavedMarginCategory,
          lastSavedCoefficientCategory,
        ], settingEntities, flyHourPrice, setIsCalculateLoading);

        setFlyHourPrice(price);
        setSettingEntities(settings);
      }
    })()
  }, [lastSavedConstantCategory, lastSavedChangeableCategory, lastSavedMarginCategory, lastSavedCoefficientCategory])

  const isCategoriesBlocked =
    useMemo(() => flyHoursCount === undefined
      || flyHoursCount <= 0 || currency === undefined, [flyHoursCount, currency])

  const onExit = () => {
    RenderConfirmModal(intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.exitWarningText), () => setIsOpen(false), intl);
  }

  const onSaveAndExit = () => {
    const saveParamsAndClose = () => {
      setSavedFlyHoursCount(flyHoursCount);
      setSavedCurrency(currency);
      setSavedFlyHourPrice(flyHourPrice)
      setSavedSettingEntities(settingEntities);
      setSavedConstantCategory(lastSavedConstantCategory);
      setSavedChangeableCategory(lastSavedChangeableCategory);
      setSavedMarginCategory(lastSavedMarginCategory);
      setSavedCoefficientCategory(lastSavedCoefficientCategory);
      setIsOpen(false);
    }

    if (categoriesSaving.some(c => !c.isSaved))
      RenderConfirmModal(intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.saveAndExitWarningText), () => {
        saveParamsAndClose();
      }, intl);
    else {
      saveParamsAndClose();
    }
  }

  const constantGroup = useMemo(() => <FlyHourPriceCalculatorGroup
    key={lastSavedConstantCategory.keyName}
    category={lastSavedConstantCategory}
    setSavedCategory={setLastSavedConstantCategory}
    isBlocked={isCategoriesBlocked}
    isFieldsCountChangeable={true} groupFormOptions={[
    {
      key: GroupFormOptionKeys.TOTAL_SUM,
      title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.totalSum),
      subcategories: lastSavedConstantCategory.subcategories.filter(sc => sc.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT)
    },
    {
      key: GroupFormOptionKeys.SEPARATELY,
      title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.calculateSeparately),
      subcategories: lastSavedConstantCategory.subcategories.filter(sc => sc.keyName !== FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT),
    }
  ]}/>, [lastSavedConstantCategory, isCategoriesBlocked]);

  const changeableCategory = useMemo(() => <FlyHourPriceCalculatorGroup
    key={lastSavedChangeableCategory.keyName}
    category={lastSavedChangeableCategory}
    setSavedCategory={setLastSavedChangeableCategory}
    isBlocked={isCategoriesBlocked}
    isFieldsCountChangeable={true}
    groupFormOptions={[
      {
        key: GroupFormOptionKeys.TOTAL_SUM,
        title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.totalSum),
        subcategories: lastSavedChangeableCategory.subcategories.filter(sc => sc.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT)
      },
      {
        key: GroupFormOptionKeys.SEPARATELY,
        title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.calculateSeparately),
        subcategories: lastSavedChangeableCategory.subcategories.filter(sc => sc.keyName !== FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT),
      }
    ]}/>, [lastSavedChangeableCategory, isCategoriesBlocked])

  const marginCategory = useMemo(() => <FlyHourPriceCalculatorGroup
    key={lastSavedMarginCategory.keyName}
    category={lastSavedMarginCategory}
    setSavedCategory={setLastSavedMarginCategory}
    isBlocked={isCategoriesBlocked}
    isFieldsCountChangeable={false}
    groupFormOptions={[
      {
        key: GroupFormOptionKeys.SEPARATELY,
        title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.calculateSeparately),
        subcategories: lastSavedMarginCategory.subcategories
      },
    ]}/>, [lastSavedMarginCategory, isCategoriesBlocked])

  const coefficientCategory = useMemo(() => <FlyHourPriceCalculatorGroup
    key={lastSavedCoefficientCategory.keyName}
    category={lastSavedCoefficientCategory}
    setSavedCategory={setLastSavedCoefficientCategory}
    isBlocked={isCategoriesBlocked} isFieldsCountChangeable={false}
    groupFormOptions={[
      {
        key: GroupFormOptionKeys.SEPARATELY,
        title: intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.calculateSeparately),
        subcategories: lastSavedCoefficientCategory.subcategories
      },
    ]}/>, [lastSavedCoefficientCategory, isCategoriesBlocked])

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)}>
      <DrawerContent>
        <Title>{intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.title)}</Title>
        <Description>{intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.description)}</Description>
        <SubtitleWrapper>
          <Subtitle>{intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.subtitle)}</Subtitle>
          {isCategoriesBlocked &&
            <SubtitleWarn>{intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.unlockWarn)}</SubtitleWarn>}
        </SubtitleWrapper>

        <CalculatorContent>
          <CalculatorGroups>
            {constantGroup}
            {changeableCategory}
            {marginCategory}
            {coefficientCategory}
          </CalculatorGroups>

          <TotalAndExit>
            <TotalAndExitStickyContent>
              <FlyHourPriceCalculatorTotal onSaveAndExit={onSaveAndExit}/>

              <ExitButton onClick={onExit}>
                <ExitIcon/>
                <div>{intl.formatMessage(FlyHourPriceCalculatorDrawerMessages.exit)}</div>
              </ExitButton>
            </TotalAndExitStickyContent>
          </TotalAndExit>
        </CalculatorContent>
      </DrawerContent>
    </CustomDrawer>
  );
};
