import styled from "styled-components";

export const BrokerDrawerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
`;

export const BrokerDrawerDetailsTitle = styled.div`
  color: #000000;
  display: flex;
  align-items: center;
  height: 58px;
  justify-content: space-between;
  padding: 0 20px;
  
  & > div {
    :nth-child(1) {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
    
    :nth-child(2) {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const BrokerDrawerDetailsContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 0 20px 24px 20px;

  & > div {
    flex-grow: 1;
  }
`;