import React, {ReactNode, useState} from 'react';
import {PageWrapper} from "../../../components/UI/PageWrapper/PageWrapper";
import {CustomCard} from "../../../components/UI/AntCustom/CustomCard";
import {UsersTabPane} from "./components/UsersTabPane";
import { UserStatus } from '../../../utils/constans/statuses/userStatuses';
import {defineMessages, useIntl} from "react-intl";


const RegistrationsPageMessages = defineMessages({
  unprocessed: {
    id: "registrationsPage.unprocessed",
    defaultMessage: "Pending registrations",
  },
  processed: {
    id: "registrationsPage.processed",
    defaultMessage: "Processed registrations",
  },
  title: {
    id: "registrationsPage.title",
    defaultMessage: "Registration requests",
  }
})

enum RegistrationsTabKeysEum {
  UNPROCESSED = 'unprocessed',
  PROCESSED = 'processed',
}

interface IContentList {
  unprocessed: ReactNode;
  processed: ReactNode;
}

export const RegistrationsPage = () => {
  const intl = useIntl();
  const [activeTabKey, setActiveTabKey] = useState<RegistrationsTabKeysEum>(RegistrationsTabKeysEum.UNPROCESSED);

  const tabList = [
    {key: RegistrationsTabKeysEum.UNPROCESSED, tab: intl.formatMessage(RegistrationsPageMessages.unprocessed)},
    {key: RegistrationsTabKeysEum.PROCESSED, tab: intl.formatMessage(RegistrationsPageMessages.processed)},
  ];

  const contentList: IContentList = {
    unprocessed: <UsersTabPane userStatuses={[UserStatus.REGISTERED]} />,
    processed: <UsersTabPane userStatuses={[UserStatus.CONFIRMED, UserStatus.REJECTED]} />,
  };

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(RegistrationsPageMessages.title)} width={'max'} tabList={tabList} onTabChange={(key: string) => {
        setActiveTabKey(key as RegistrationsTabKeysEum)
      }}>
        {contentList[activeTabKey as keyof typeof contentList]}
      </CustomCard>
    </PageWrapper>
  );
};

