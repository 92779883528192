import {Mixin} from "ts-mixer";
import {GreeterApi} from "./ApiClasses/GreeterApi";
import {AuthApi} from "./ApiClasses/AuthApi";
import {Error, Metadata, StatusCode} from "grpc-web";
import {message} from "antd";
import {UserAccountApi} from "./ApiClasses/UserAccountApi";
import {BookingApi} from "./ApiClasses/BookingApi";
import {BillingApi} from "./ApiClasses/BillingApi";
import {Logger} from "../utils/logger/Logger";
import {CommunicationApi} from "./ApiClasses/CommunicationApi";
import {store} from "../store/store";
import {logout} from "../store/reducers/authReducer/authReducer";
import {FilesApi} from "./ApiClasses/FileUploaderApi";
import {ScheduleApi} from "./ApiClasses/ScheduleApi";
import {PlaneApi} from "./ApiClasses/PlaneApi";
import {FlyApi} from "./ApiClasses/FlyApi";
import {TimezoneApi} from "./ApiClasses/TimezoneApi";
import {NotificationApi} from "./ApiClasses/NotificationApi";
import {PlaneCalculatorApi} from "./ApiClasses/PlaneCalculatorApi";


class API_Mixin_1 extends Mixin(
  GreeterApi,
  AuthApi,
  UserAccountApi,
  BookingApi,
  BillingApi,
  CommunicationApi,
  FilesApi,
  ScheduleApi,
  PlaneApi,
  FlyApi) {
}

class API_Mixin_2 extends Mixin(
  TimezoneApi,
  NotificationApi,
  PlaneCalculatorApi) {
}

export class API extends Mixin(API_Mixin_1, API_Mixin_2) {
  static call<TReq, TRes>(methodContext: any, method: any, req: TReq, resolve: any, reject: any, isRepeatedRequest?: boolean) {
    Logger.collapsedLog(`[${getMethodName(methodContext, method)}]: request`, {time: new Date(), payload: (req as any).toObject()}, 'aqua');
    const accessToken: string = localStorage.getItem('accessToken') || "";
    const metadata: Metadata = {
      ['access-token']: accessToken
    }

    method.call(methodContext, req, metadata, (err: Error, res: TRes) => {
      if (!err) {
        Logger.collapsedLog(`[${getMethodName(methodContext, method)}]: response`, {time: new Date(), payload: (res as any).toObject()}, 'lime');
        resolve(res);
      } else {
        if (err.code === StatusCode.UNAUTHENTICATED && !isRepeatedRequest) {
          API.refreshToken()
            .then((success) => {
              if (success) {
                API.call(methodContext, method, req, resolve, reject, true);
                return;
              }
            }).catch((e) => {
            message.error('Failed to restore authorization. Please re-login.');
            store.dispatch({
              type: logout.type
            })
            reject();
          });
        } else {
          message.error('Error on the server. Please, try again later.')
          Logger.error(`Server error. Message: ${err.message}`);

          reject();
        }
      }
    });
  }
}

const getMethodName = (methodContext: any, method: any) => {
  for (const [key, value] of Object.entries(methodContext.__proto__)) {
    if (value === method)
      return key;
  }
}
