import React, {FC} from "react";
import {formatDate} from "../../../../utils/helpers/dateHelpers/dateHelpers";
import {
  BrokerDrawerDetailsTitle,
  BrokerDrawerDetailsWrapper
} from "./BrokerDrawerFooterStyledComponents";
import {defineMessages, useIntl} from "react-intl";
import {
  IFreighterOrderRequest,
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {useAppSelector} from "../../../../store/store";
import {OrderTypeShield} from "../../orderType/OrderTypeShield";
import {BrokerDrawerOrderRequestFlyRoute} from "./BrokerDrawerOrderRequestFlyRoute";
import {DetailItem} from "../../detailItem/DetailItem";
import {CommonDetailsMessages} from "../../../../intl/commonMessages/CommonDetailsMessages";
import {DisplayPlaneCategories} from "../../../../utils/helpers/textDisplayHelpers/DisplayPlaneCategories";
import {DisplayYesOrNo} from "../../../../utils/helpers/textDisplayHelpers/DisplayYesOrNo";

const BrokerDrawerOrderRequestDetailsMessages = defineMessages({
  title: {
    id: "brokerDrawerOrderRequestDetails.title",
    defaultMessage: "Respond details",
  },
  departureDate: {
    id: "brokerDrawerOrderRequestDetails.departureDate",
    defaultMessage: "Departure date",
  },
  departureTime: {
    id: "brokerDrawerOrderRequestDetails.departureTime",
    defaultMessage: "Departure time",
  }
})

interface IBrokerDrawerOrderRequestDetails {
  orderRequest: IFreighterOrderRequest;
}

export const BrokerDrawerOrderRequestDetails: FC<IBrokerDrawerOrderRequestDetails> = ({orderRequest}) => {
  const intl = useIntl();
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);

  const routes = orderRequest.orderRouteOrderRequests.map((orderRouteOrderRequest, idx) =>
      <BrokerDrawerOrderRequestFlyRoute key={orderRouteOrderRequest.orderRoute.id} orderRoute={orderRouteOrderRequest.orderRoute} num={idx + 1}/>)

  return (
    <>
      <BrokerDrawerDetailsWrapper>
        <BrokerDrawerDetailsTitle>
          <div>{intl.formatMessage(BrokerDrawerOrderRequestDetailsMessages.title)}</div>
          <div>{formatDate(orderRequest.dateCreated, offset, "date")}</div>
          <OrderTypeShield type={orderRequest.order.type} styleType={'fill'}/>
        </BrokerDrawerDetailsTitle>

        {routes}
      </BrokerDrawerDetailsWrapper>
      <DetailItem label={intl.formatMessage(CommonDetailsMessages.planeCategories)} value={DisplayPlaneCategories(orderRequest.order.planeCategories)} />
      <DetailItem label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)} value={DisplayYesOrNo(orderRequest.order.companionsAllowed)} />
    </>

  );
};