import React, {FC, ReactNode} from 'react';
import {ButtonProps} from "antd/lib/button/button";
import styled from "styled-components";
import Button from "antd/es/button/button";

export type CustomButtonSizeType = 's' | 'm' | 'l' | 'xl';

interface IButtonStyled {
  $heightSize: CustomButtonSizeType;
  $dangerous: boolean;
}

const ButtonStyled = styled(Button)<IButtonStyled>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  column-gap: 8px;

  span {
    margin-left: 0 !important;
  }

  height: ${props => (props.$heightSize === 's' && "28px")
          || (props.$heightSize === 'm' && "34px")
          || (props.$heightSize === 'l' && "42px")
          || (props.$heightSize === 'xl' && "50px")
  };

  line-height: ${props => (props.$heightSize === 's' && "20px")
          || (props.$heightSize === 'm' && "26px")
          || (props.$heightSize === 'l' && "34px")
          || (props.$heightSize === 'xl' && "42px")
  };

  ${props => props.$dangerous && `background: transparent;`}
`;

export interface ICustomButton extends ButtonProps {
  heightSize?: CustomButtonSizeType;
  children?: ReactNode;
}

export const CustomButton: FC<ICustomButton> = ({heightSize = 'l', children, ...props}) => {
  return (
    <ButtonStyled $heightSize={heightSize} $dangerous={!!props.danger} {...props}>
      {children}
    </ButtonStyled>
  );
};
