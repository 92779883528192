import React from 'react';
import styled from "styled-components";
import Arrow from "../../../../media/icons/Arrow.svg"
import {FONTS} from "../../../../utils/constans/styles/fonts";
import {HomeInfoCard} from "./HomeInfoCard";
import infoImage from "../../../../media/images/home_third_section_info_image.jpg";
import {useNavigate} from "react-router";
import {mediaScreenSizes, screenSizes} from "../../../../utils/constans/styles/screenSizes";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../config/acl/routes/Route";
import {defineMessages, useIntl} from "react-intl";

const HomeFourthSectionStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  display: flex;
  max-width: 1260px;
  box-sizing: content-box;
  flex-direction: column;
  row-gap: 39px;
  padding: 0 90px 156px 90px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    row-gap: 37px;
    padding: 0 27px 100px 27px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 32px;
    padding: 0 23px 100px 23px;
  }
`;

const TitleAndLink = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 60px;
  line-height: 56px;
  color: #000000;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 47px;
    line-height: 47px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 45px;
    line-height: 45px;
  }
`;

const SectionLink = styled.div`
  font: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 17px;
  line-height: 26px;
  color: #1890FF;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;

const InfoImageTitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const InfoImageTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 51px;
  line-height: 47px;
  color: #F4F6F8;
  max-width: 348px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 41px;
    line-height: 38px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 30px;
    line-height: 27px;
  }
`;

const InfoImageDescription = styled.div`
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 16px;
  line-height: 24px;
  color: #F4F6F8;
  max-width: 395px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const InfoImage = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding: 0 32px 78px 32px;
  flex-shrink: 0;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 48px 32px 48px 32px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    min-height: 524px;
  }
`;

const HomeInfoCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const InfoImageAndInfoCards = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    flex-direction: column;
    row-gap: 20px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {

  }
`;


const HomeThirdSectionMessages = defineMessages({
  title: {
    id: "homeThirdSection.title",
    defaultMessage: "For brokers",
  },
  join: {
    id: "homeThirdSection.join",
    defaultMessage: "Sign up",
  },
  infoImageTitle: {
    id: "homeThirdSection.infoImageTitle",
    defaultMessage: "Exclusive for everyone",
  },
  infoImageDescription: {
    id: "homeThirdSection.infoImageDescription",
    defaultMessage: "Send flight requests and get offers instantly from operators on Jetsnode chat.",
  },
  infoCardTitle1: {
    id: "homeThirdSection.infoCardTitle1",
    defaultMessage: "Find a flight",
  },
  infoCardDescription1: {
    id: "homeThirdSection.infoCardDescription1",
    defaultMessage: "Find and check the prices and availability of aircraft registered on Jetsnode.",
  },
  infoCardTitle2: {
    id: "homeThirdSection.infoCardTitle2",
    defaultMessage: "Communicate",
  },
  infoCardDescription2: {
    id: "homeThirdSection.infoCardDescription2",
    defaultMessage: "Reduce your inbox by interacting with other brokers and operators within Jetsnode",
  },
  infoCardTitle3: {
    id: "homeThirdSection.infoCardTitle3",
    defaultMessage: "View aircraft details",
  },
  infoCardDescription3: {
    id: "homeThirdSection.infoCardDescription3",
    defaultMessage: "Find detailed information about the aircraft: images, documentation, operator details, etc.",
  },
  infoCardTitle4: {
    id: "homeThirdSection.infoCardTitle4",
    defaultMessage: "Get flexible offers",
  },
  infoCardDescription4: {
    id: "homeThirdSection.infoCardDescription4",
    defaultMessage: "Get information about a specific airport: runway length, meal services, hotels, FBO and service agents, etc.",
  },
})

export const HomeThirdSection = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {width} = useWindowSize();

  return (
    <HomeFourthSectionStyled>
      <SectionContainer>
        <TitleAndLink>
          <SectionTitle>
            {intl.formatMessage(HomeThirdSectionMessages.title)}
          </SectionTitle>
          {width > screenSizes.mobile && <SectionLink onClick={() => navigate(pathFromRoute(Route.register))}>
            <div>{intl.formatMessage(HomeThirdSectionMessages.join)}</div>
            <img src={Arrow} alt="arrow"/>
          </SectionLink>}
        </TitleAndLink>

        <InfoImageAndInfoCards>
          <InfoImage style={{backgroundImage: `url(${infoImage})`}}>
            <InfoImageTitleAndDescription>
              <InfoImageTitle>{intl.formatMessage(HomeThirdSectionMessages.infoImageTitle)}</InfoImageTitle>
              <InfoImageDescription>{intl.formatMessage(HomeThirdSectionMessages.infoImageDescription)}</InfoImageDescription>
            </InfoImageTitleAndDescription>
          </InfoImage>
          <HomeInfoCards>
            <HomeInfoCard serialNumber={1} title={intl.formatMessage(HomeThirdSectionMessages.infoCardTitle1)}
                          description={intl.formatMessage(HomeThirdSectionMessages.infoCardDescription1)}
                          minHeight={"324px"}/>
            <HomeInfoCard serialNumber={2} title={intl.formatMessage(HomeThirdSectionMessages.infoCardTitle2)}
                          description={intl.formatMessage(HomeThirdSectionMessages.infoCardDescription2)}
                          minHeight={"324px"}/>
            <HomeInfoCard serialNumber={3} title={intl.formatMessage(HomeThirdSectionMessages.infoCardTitle3)}
                          description={intl.formatMessage(HomeThirdSectionMessages.infoCardDescription3)}
                          minHeight={"324px"}/>
            <HomeInfoCard serialNumber={4} title={intl.formatMessage(HomeThirdSectionMessages.infoCardTitle4)}
                          description={intl.formatMessage(HomeThirdSectionMessages.infoCardDescription4)}
                          minHeight={"324px"}/>
          </HomeInfoCards>
        </InfoImageAndInfoCards>
      </SectionContainer>
    </HomeFourthSectionStyled>
  );
};


