import {PlaneCalculatorApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  PlaneCalculatorApiFlyHourPriceRequest,
  PlaneCalculatorApiFlyHourPriceResponse,
  PlaneCalculatorApiPlaneCalculatorSettingListRequest, PlaneCalculatorApiPlaneCalculatorSettingListResponse,
  PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest,
  PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new PlaneCalculatorApiServiceClient(getProxyUrl(), null, null);

export class PlaneCalculatorApi {
  static async getFlyHourPrice(req: PlaneCalculatorApiFlyHourPriceRequest): Promise<PlaneCalculatorApiFlyHourPriceResponse> {
    return new Promise((resolve, reject) => {
      API.call<PlaneCalculatorApiFlyHourPriceRequest, PlaneCalculatorApiFlyHourPriceResponse>(client, client.getFlyHourPrice, req, resolve, reject)
    })
  }

  static async planeCalculatorSettingTypeList(req: PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest): Promise<PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse> {
    return new Promise((resolve, reject) => {
      API.call<PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest, PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse>(client, client.planeCalculatorSettingTypeList, req, resolve, reject)
    })
  }

  static async planeCalculatorSettingList(req: PlaneCalculatorApiPlaneCalculatorSettingListRequest): Promise<PlaneCalculatorApiPlaneCalculatorSettingListResponse> {
    return new Promise((resolve, reject) => {
      API.call<PlaneCalculatorApiPlaneCalculatorSettingListRequest, PlaneCalculatorApiPlaneCalculatorSettingListResponse>(client, client.planeCalculatorSettingList, req, resolve, reject)
    })
  }
}