import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/UI/AntCustom/customGlobalStyles.scss';
import {store} from "./store/store";
import {Provider} from "react-redux";
import {createBrowserHistory} from 'history';
import {App} from "./App";
import {CustomRouter} from "./routes/CustomRouter";
import {LanguageWrapper} from "./components/highOrderComponents/LanguageWrapper/LanguageWrapper";
import {YMInitializer} from "react-yandex-metrika";
import {MAIN_COUNTER, STAGING_COUNTER} from "./utils/constans/metrics/counters";

export const browserHistory = createBrowserHistory();

if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log = console.warn = console.error = () => {
  };
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CustomRouter history={browserHistory}>
    <YMInitializer accounts={[Number(MAIN_COUNTER), Number(STAGING_COUNTER)]} options={{webvisor: true}} version="2" />
    <Provider store={store}>
      <LanguageWrapper>
        <App/>
      </LanguageWrapper>
    </Provider>
  </CustomRouter>
);

//TODO add service worker + unregister, see https://stackoverflow.com/questions/49604821/cache-busting-with-cra-react
