import {Logger} from "../../../utils/logger/Logger";
import {InitialPage} from "../../../utils/constans/pagination/pagination";
import {getErrorMessage} from "../../../utils/errorHelpers/getCommonErrorMessage";

export type RemoteActionState = {
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export type ItemListState<StateItem> = {
  items: StateItem[];
  isLoading: boolean;
  totalCount: number;
}

export type OneItemState<StateItem> = {
  item: StateItem;
  isLoading: boolean;
}

export type CommonState<StateItem> = {
  itemsObject?: ItemListState<StateItem>;
  create?: RemoteActionState;
  edit?: RemoteActionState;
  delete?: RemoteActionState;
  oneItemObject?: OneItemState<StateItem>;
  [key: string]: any
}

export type MapperType<MapFromType, MapToType> = (item: MapFromType) => MapToType;
export type EmptyItemGeneratorType<StateItem> = () => StateItem;
const mapperErrorTitle = "MAPPER ERROR: ";
const LogMapperError = (errorText: string) => Logger.error(mapperErrorTitle + errorText);

//region ONE ITEM

export const initCommonGetOne = function <StateItem, PayloadType>(
  state: CommonState<StateItem>,
  payload?: PayloadType,
) {
  if (state.oneItemObject) {
    state.oneItemObject.isLoading = true;
  }
}

export const initCommonClearOne = function <StateItem>(
  state: CommonState<StateItem>,
  emptyItemGenerator: EmptyItemGeneratorType<StateItem>,
) {
  if (state.oneItemObject) {
    state.oneItemObject.isLoading = true;
    state.oneItemObject.item = emptyItemGenerator();
  }
}

export const initCommonSetOneResult = function <MapFromType,
  StateItemType,
  PayloadType extends { item: MapFromType },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  mapper: MapperType<MapFromType, StateItemType>
) {
    try {
      const mappedObj = mapper(payload.item);

      if (state.oneItemObject) {
        state.oneItemObject.item = mappedObj;
        state.oneItemObject.isLoading = false;
      }
    } catch (e) {
      LogMapperError(getErrorMessage(e));
    }
}

export const initCommonEditOne = function <StateItem, PayloadType>(
  state: CommonState<StateItem>,
  payload?: PayloadType,
) {
  if (state.edit) {
    state.edit.isLoading = true;
  }
}

export const initCommonClearEditOne = function <StateItem>(
  state: CommonState<StateItem>
) {
  if (state.edit) {
    state.edit.isLoading = false;
    state.edit.isSuccess = false;
    state.edit.errorMessage = '';
  }
}

export const initCommonSetEditOneResult = function <MapFromType,
  StateItemType,
  PayloadType extends { item?: MapFromType, errorMessage?: string },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  mapper: MapperType<MapFromType, StateItemType>
) {
  if (state.edit) {
    if (state.oneItemObject && payload && payload.item) {
      try {
        state.oneItemObject.item = mapper(payload.item);
      } catch (e) {
        LogMapperError(getErrorMessage(e));
      }
      state.edit.isSuccess = true;
      state.edit.errorMessage = '';
    } else if (payload.errorMessage && payload.errorMessage !== '') {
      state.edit.isSuccess = false;
      state.edit.errorMessage = payload.errorMessage;
    }
    state.edit.isLoading = false;
  }
}

//endregion

//region LIST

export const initCommonGetList = function <StateItem, PayloadType extends { page: number }>(
  state: CommonState<StateItem>,
  payload: PayloadType,
) {
  if (state.itemsObject) {
    state.itemsObject.isLoading = true;

    if (payload.page === InitialPage) {
      state.itemsObject.items = [];
      state.itemsObject.totalCount = 0;
    }
  }
}

export const initCommonClearList = function <StateItem>(
  state: CommonState<StateItem>,
) {
  if (state.itemsObject) {
    state.itemsObject.items = [];
    state.itemsObject.totalCount = 0;
    state.itemsObject.isLoading = true;
  }
}

export const initCommonSetListResult = function <MapFromType,
  StateItemType,
  PayloadType extends { items: MapFromType[], totalCount: number },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  mapper: MapperType<MapFromType, StateItemType>
) {
  let totalCount = payload.totalCount;
  const mappedList = payload.items.reduce((acc: StateItemType[], item: MapFromType) => {
    try {
      const mappedObj = mapper(item);
      acc.push(mappedObj);
    } catch (e) {
      LogMapperError(getErrorMessage(e));
      totalCount--;
    }
    return acc;
  }, []);

  if (state.itemsObject && payload) {
    state.itemsObject.items.push(...mappedList);
    state.itemsObject.totalCount = totalCount;
    state.itemsObject.isLoading = false;
  }
}

//endregion

//region CREATE
export const initCommonCreate = function <StateItem, PayloadType>(
  state: CommonState<StateItem>,
  payload: PayloadType,
) {
  if (state.create)
    state.create.isLoading = true;
}

export const initCommonClearCreate = function <StateItem>(
  state: CommonState<StateItem>,
) {
  if (state.create) {
    state.create.isLoading = false;
    state.create.isSuccess = false;
    state.create.errorMessage = '';
  }
}

export const initCommonCreateResult = function <MapFromType,
  StateItemType,
  PayloadType extends { item?: MapFromType, errorMessage?: string },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  mapper: MapperType<MapFromType, StateItemType>
) {
  if (state.create) {
    if (state.itemsObject && payload && payload.item) {
      try {
        const mappedItem = mapper(payload.item);
        state.itemsObject.items.unshift(mappedItem);
      } catch (e) {
        LogMapperError(getErrorMessage(e));
      }
      state.create.isSuccess = true;
      state.create.errorMessage = '';
    } else if (payload.errorMessage && payload.errorMessage !== '') {
      state.create.isSuccess = false;
      state.create.errorMessage = payload.errorMessage;
    }
    state.create.isLoading = false;
  }
}
//endregion

//region EDIT
export const initCommonEdit = function <StateItem, PayloadType>(
  state: CommonState<StateItem>,
  payload: PayloadType,
) {
  if (state.edit)
    state.edit.isLoading = true;
}

export const initCommonClearEdit = function <StateItem>(
  state: CommonState<StateItem>,
) {
  if (state.edit) {
    state.edit.isLoading = false;
    state.edit.isSuccess = false;
    state.edit.errorMessage = '';
  }
}

export const initCommonEditResult = function <MapFromType,
  StateItemType extends { id: number | string },
  PayloadType extends { item?: MapFromType, errorMessage?: string },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  mapper: MapperType<MapFromType, StateItemType>
) {
  if (state.edit) {
    if (state.itemsObject && payload && payload.item) {
      try {
        const mappedItem = mapper(payload.item);
        state.itemsObject.items = state.itemsObject.items.map(item => item.id !== mappedItem.id ? item : mappedItem);
      } catch (e) {
        LogMapperError(getErrorMessage(e));
      }
      state.edit.isSuccess = true;
      state.edit.errorMessage = '';
    } else if (payload.errorMessage && payload.errorMessage !== '') {
      state.edit.isSuccess = false;
      state.edit.errorMessage = payload.errorMessage;
    }
    state.edit.isLoading = false;
  }
}
//endregion

//region DELETE
export const initCommonDelete = function <StateItem, PayloadType extends {itemId: number}>(
  state: CommonState<StateItem>,
  payload: PayloadType
) {
  if (state.delete)
    state.delete.isLoading = true;
}

export const initCommonClearDelete = function <StateItem>(
  state: CommonState<StateItem>,
) {
  if (state.delete) {
    state.delete.isLoading = false;
    state.delete.isSuccess = false;
    state.delete.errorMessage = '';
  }
}

export const initCommonDeleteResult = function <StateItemType extends { id: number | string },
  PayloadType extends { itemId?: number | string, errorMessage?: string },
  >
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
) {
  if (state.delete) {
    if (state.itemsObject && payload) {
      state.itemsObject.items = state.itemsObject.items.filter(item => item.id !== payload.itemId);
      state.itemsObject.totalCount--;
      state.delete.isSuccess = true;
      state.delete.errorMessage = '';
    } else if (payload.errorMessage && payload.errorMessage !== '') {
      state.delete.isSuccess = false;
      state.delete.errorMessage = payload.errorMessage;
    }
    state.delete.isLoading = false;
  }
}
//endregion

//region COMMON_ACTION
export const initCommonAction = function <StateItemType>
(
  state: CommonState<StateItemType>,
  stateActionObjectKey: keyof CommonState<StateItemType>,
) {
  if (stateActionObjectKey) {
    try {
      (state[stateActionObjectKey] as RemoteActionState).isLoading = true;
    } catch (e) {
      LogMapperError(`initCommonAction error: ${getErrorMessage(e)}`)
    }
  } else {
    LogMapperError('initCommonAction error: stateActionObjectKey not found')
  }
}

export const initCommonClearAction = function <StateItemType>
(
  state: CommonState<StateItemType>,
  stateActionObjectKey: keyof CommonState<StateItemType>,
) {
  if (stateActionObjectKey) {
    try {
      (state[stateActionObjectKey] as RemoteActionState).isLoading = false;
      (state[stateActionObjectKey] as RemoteActionState).isSuccess = false;
      (state[stateActionObjectKey] as RemoteActionState).errorMessage = '';
    } catch (e) {
      LogMapperError(`initCommonClearAction error: ${getErrorMessage(e)}`)
    }
  } else {
    LogMapperError('initCommonClearAction error: stateActionObjectKey not found')
  }
}

export const initCommonActionResult = function <StateItemType, PayloadType extends { errorMessage?: string }>
(
  state: CommonState<StateItemType>,
  payload: PayloadType,
  stateActionObjectKey: keyof CommonState<StateItemType>,
) {
  if (stateActionObjectKey) {
    try {
      if (payload.errorMessage) {
        (state[stateActionObjectKey] as RemoteActionState).isSuccess = false;
        (state[stateActionObjectKey] as RemoteActionState).errorMessage = payload.errorMessage;
      } else {
        (state[stateActionObjectKey] as RemoteActionState).isSuccess = true;
        (state[stateActionObjectKey] as RemoteActionState).errorMessage = '';
      }

      (state[stateActionObjectKey] as RemoteActionState).isLoading = false;

    } catch (e) {
      LogMapperError(`initCommonActionResult error: ${getErrorMessage(e)}`)
    }
  } else {
    LogMapperError('initCommonActionResult error: stateActionObjectKey not found')
  }
}
//endregion
