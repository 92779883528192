import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  archiveEmptyLeg,
  changeEmptyLegRespondStatus,
  createEmptyLeg,
  editEmptyLeg, getEmptyLegList, setArchiveEmptyLegResult,
  setChangeEmptyLegRespondStatusResult,
  setCreateEmptyLegResult,
  setEditEmptyLegResult, setEmptyLegListResult,
} from "../../reducers/emptyLegsReducer/emptyLegsReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  AirportEntity,
  BookingApiEmptyLegListRequest,
  BookingApiEmptyLegListResponse,
  BookingApiEmptyLegOrderRouteRequest,
  BookingApiEmptyLegOrderRouteResponse,
  BookingApiEmptyLegRequest,
  BookingApiEmptyLegResponse, BookingApiGetAirportRequest, BookingApiGetAirportResponse,
  CurrencyEntity,
  EmptyLegEntity,
  EmptyLegOrderRouteEntity,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {
  IArchiveEmptyLegPayload,
  IChangeEmptyLegRespondStatusPayload, ICreateEmptyLegPayload, IEditEmptyLegPayload,
  IGetEmptyLegListPayload
} from "../../reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";


function* getEmptyLegListSaga({payload}: PayloadAction<IGetEmptyLegListPayload>) {
  const getEmptyLegListReq = new BookingApiEmptyLegListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.MyRequests)
    )
    .setIsshowarchived(payload.isArchival)

  if (payload.emptyLegIds)
    getEmptyLegListReq.setEmptylegidsList(payload.emptyLegIds);

  try {
    const getEmptyLegsRes: BookingApiEmptyLegListResponse = yield call(API.getEmptyLegList, getEmptyLegListReq);

    yield put(setEmptyLegListResult({
      items: getEmptyLegsRes.getEmptylegsList(),
      totalCount: getEmptyLegsRes.getTotalcount(),
    }));
  } catch {
  }
}


function* changeEmptyLegRespondStatusSaga({payload}: PayloadAction<IChangeEmptyLegRespondStatusPayload>) {
  const changeEmptyLegOrderRouteStatusReq = new BookingApiEmptyLegOrderRouteRequest()
    .setEmptylegorderrouteentity(
      new EmptyLegOrderRouteEntity()
        .setOrderrouteid(payload.orderRouteId)
        .setEmptylegid(payload.emptyLegId)
        .setStatus(payload.status)
    );

  try {
    const changeEmptyLegOrderRouteStatusRes: BookingApiEmptyLegOrderRouteResponse
      = yield call(API.emptyLegOrderRouteUpdate, changeEmptyLegOrderRouteStatusReq);

    if (changeEmptyLegOrderRouteStatusRes.getResultresponse()?.getIssuccessful()) {
      yield put(setChangeEmptyLegRespondStatusResult({
        emptyLegOrderRoute: changeEmptyLegOrderRouteStatusRes.getEmptylegorderrouteentity(),
      }));
    } else {
      yield put(setChangeEmptyLegRespondStatusResult({
        errorMessage: getErrorMessageByCode(changeEmptyLegOrderRouteStatusRes.getResultresponse()!.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* createEmptyLegSaga({payload}: PayloadAction<ICreateEmptyLegPayload>) {
  try {

    const flyDurationInMinutes = Number(payload.hours * 60) + Number(payload.minutes);

    const airportFromResponse: BookingApiGetAirportResponse = yield call(
      API.getAirportById,
      new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
    );

    const airportFrom = airportFromResponse.getAirport();
    if (!airportFrom)
      throw new Error("No airport with given id found");

    const newEmptyLeg = new EmptyLegEntity()
      .setPlaneid(payload.fleetId)
      .setAirportfrom(airportFrom)
      .setAirportto(
        new AirportEntity().setAirportid(payload.airportToId)
      )
      .setCurrencyentity(new CurrencyEntity()
        .setCurrencyid(payload.priceCurrencyId)
      )
      .setFlyduration(flyDurationInMinutes)
      .setFlydistance(payload.distance)
      .setPrice(payload.priceAmount)
      .setComment(payload.comment ?? "");

    payload.availableFromDate && newEmptyLeg.setAvailablefromdate(getLocalDateAsISOString(payload.availableFromDate));
    payload.availableToDate && newEmptyLeg.setAvailabletodate(getLocalDateAsISOString(payload.availableToDate));
    payload.departureDate && newEmptyLeg.setDeparturetime(getLocalDateAsISOString(payload.departureDate));

    const createEmptyLegReq = new BookingApiEmptyLegRequest()
      .setEmptyleg(newEmptyLeg)

    const createEmptyLegRes: BookingApiEmptyLegResponse = yield call(API.createEmptyLeg, createEmptyLegReq);

    if (createEmptyLegRes.getResultresponse()?.getIssuccessful()) {
      yield put(setCreateEmptyLegResult({
        item: createEmptyLegRes.getEmptyleg(),
      }));
    } else {
      yield put(setCreateEmptyLegResult({
        errorMessage: getErrorMessageByCode(createEmptyLegRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* editEmptyLegSaga({payload}: PayloadAction<IEditEmptyLegPayload>) {
  try {
    const flyDurationInMinutes = Number(payload.hours * 60) + Number(payload.minutes);

    const airportFromResponse: BookingApiGetAirportResponse = yield call(
      API.getAirportById,
      new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
    );

    const airportFrom = airportFromResponse.getAirport();
    if (!airportFrom)
      throw new Error("No timezone in received airport");

    const newEmptyLeg = new EmptyLegEntity()
      .setEmptylegid(payload.emptyLegId)
      .setPlaneid(payload.fleetId)
      .setAirportfrom(airportFrom)
      .setAirportto(
        new AirportEntity().setAirportid(payload.airportToId)
      )
      .setCurrencyentity(new CurrencyEntity()
        .setCurrencyid(payload.priceCurrencyId)
      )
      .setPrice(payload.priceAmount)
      .setFlyduration(flyDurationInMinutes)
      .setFlydistance(payload.distance)
      .setComment(payload.comment ?? "")
      .setEmptylegstatusid(payload.emptyLegStatusId)

    payload.availableFromDate && newEmptyLeg.setAvailablefromdate(getLocalDateAsISOString(payload.availableFromDate));
    payload.availableToDate && newEmptyLeg.setAvailabletodate(getLocalDateAsISOString(payload.availableToDate));
    payload.departureDate && newEmptyLeg.setDeparturetime(getLocalDateAsISOString(payload.departureDate));

    const editEmptyLegReq = new BookingApiEmptyLegRequest()
      .setEmptyleg(newEmptyLeg)

    const editEmptyLegRes: BookingApiEmptyLegResponse = yield call(API.editEmptyLeg, editEmptyLegReq);

    if (editEmptyLegRes.getResultresponse()?.getIssuccessful()) {
      yield put(setEditEmptyLegResult({
        item: editEmptyLegRes.getEmptyleg(),
      }));
    } else {
      yield put(setEditEmptyLegResult({
        errorMessage: getErrorMessageByCode(editEmptyLegRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* archiveEmptyLegSaga({payload}: PayloadAction<IArchiveEmptyLegPayload>) {
  const req = new BookingApiEmptyLegRequest()
    .setEmptyleg(
      new EmptyLegEntity()
        .setEmptylegid(payload.itemId)
        .setArchived(true)
    )

  try {
    const res: BookingApiEmptyLegResponse = yield call(API.archiveEmptyLeg, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setArchiveEmptyLegResult({
        itemId: payload.itemId,
      }));
    } else {
      yield put(setArchiveEmptyLegResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


export function* emptyLegsSaga() {
  yield all([
    takeLatest(getEmptyLegList.type, getEmptyLegListSaga),
    takeLatest(createEmptyLeg.type, createEmptyLegSaga),
    takeLatest(editEmptyLeg.type, editEmptyLegSaga),
    takeLatest(archiveEmptyLeg.type, archiveEmptyLegSaga),
    takeLatest(changeEmptyLegRespondStatus.type, changeEmptyLegRespondStatusSaga),
  ])
}