import {all, call, put, takeEvery, takeLatest} from "redux-saga/effects";
import {ILicense, licensesActions} from "../reducers/licensesReducer";
import {LicensesDatagate} from "../../API/datagates/user/licenses";
import {SagaPayloadFromReducer} from "./helpers/SagaPayloadFromReducer";

let lastLocalNegativeId = 0;

function* loadLicenses ({payload}: SagaPayloadFromReducer<typeof licensesActions.read>) {
  try {
    const licenses: ILicense[] = yield call(LicensesDatagate.getLicenses);

    yield put(licensesActions.onReadSuccess({items: licenses, resolvePayload: null}));
  } catch (e: any) {
    yield put(licensesActions.onReadFail({rejectPayload: e.message}));
  }
}

function* createLicenses({payload}: SagaPayloadFromReducer<typeof licensesActions.create>) {
  const currentLocalNegativeId = `local_id:${--lastLocalNegativeId}`;

  yield put(licensesActions.afterCreate({item: {
    id: currentLocalNegativeId,
    name: payload.item.name,
    path: '',
    bytes: null,
  }}));

  try {
    const license: ILicense = yield call(LicensesDatagate.createLicense, payload.item);
    yield put(licensesActions.onCreateSuccess({currentId: currentLocalNegativeId, createdItem: license, resolvePayload: null}));
  } catch (e: any) {
    yield put(licensesActions.onCreateFail({currentId: currentLocalNegativeId, rejectPayload: e.message, removeItem: true}));
  }
}

function* deleteLicenses({payload}: SagaPayloadFromReducer<typeof licensesActions.delete>) {
  try {
    yield call(LicensesDatagate.deleteLicense, payload.id);
    yield put(licensesActions.onDeleteSuccess({id: payload.id}));
  } catch (e: any) {
    yield put(licensesActions.onDeleteFail({id: payload.id, rejectPayload: e.message}));
  }
}

export function* licensesSaga() {
  yield all([
    takeLatest(licensesActions.read.type, loadLicenses),
    takeEvery(licensesActions.create.type, createLicenses),
    takeEvery(licensesActions.delete.type, deleteLicenses),
  ]);
}