import React, {FC, useContext, useState} from 'react';
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {Col, Radio, Tooltip} from "antd";
import {defineMessages, useIntl} from "react-intl";
import {FlyHourPriceContext} from "./context/FlyHourPriceContextProvider";
import {
  FlyHourPriceCalculatorSettingTypeEnum, FlyHourPriceDefaultCategoryEnum,
  FlyHourPriceDefaultSettingTypeEnum, FlyHourPriceDefaultSubcategoryEnum
} from "./types/flyHourPriceSettingEnums";
import {
  IFlyHourCalculatorCategory,
  IFlyHourCalculatorSettingType,
  IFlyHourCalculatorSubCategory
} from "../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import styled from "styled-components";
import {ReactComponent as RemoveIcon} from "../../../../../../media/icons/remove_field.svg";
import {useFlyPriceCategorySetter} from "./hooks/useFlyPriceCategorySetter";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";
import {Rule} from "antd/lib/form";

const RadioButtonsWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const RemoveButton = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  cursor: pointer;
`;


const FlyHourPriceCalculatorFieldMessages = defineMessages({
  percent: {
    id: "flyHourPriceCalculatorField.percent",
    defaultMessage: "In percentages",
  },
  fix: {
    id: "flyHourPriceCalculatorField.fix",
    defaultMessage: "Fixed amount",
  },
})

interface IFlyHourPriceCalculatorField {
  category: IFlyHourCalculatorCategory;
  subcategory: IFlyHourCalculatorSubCategory;
  settingType: IFlyHourCalculatorSettingType;
}

export const FlyHourPriceCalculatorField: FC<IFlyHourPriceCalculatorField> = ({category, subcategory, settingType}) => {
  const intl = useIntl();
  const {currency, isMarginTaxesDisabled} = useContext(FlyHourPriceContext);
  const categorySetter = useFlyPriceCategorySetter(category.keyName);
  const defaultType = settingType.setting.specificType ? settingType.setting.specificType : settingType.type;
  const [selectedType, setSelectedType] = useState<FlyHourPriceCalculatorSettingTypeEnum>(defaultType);

  const radioButtons = settingType.setting.specificType
    ? <RadioButtonsWrapper>
      {[FlyHourPriceCalculatorSettingTypeEnum.FLAT, FlyHourPriceCalculatorSettingTypeEnum.PERCENT].map(t => <Radio
        key={t}
        value={t}
        checked={t === selectedType}
        onClick={() => onTypeChange(t)}>
        {t === FlyHourPriceCalculatorSettingTypeEnum.FLAT && intl.formatMessage(FlyHourPriceCalculatorFieldMessages.fix)}
        {t === FlyHourPriceCalculatorSettingTypeEnum.PERCENT && intl.formatMessage(FlyHourPriceCalculatorFieldMessages.percent)}
      </Radio>)}
    </RadioButtonsWrapper>
    : undefined;

  const onTypeChange = (type: FlyHourPriceCalculatorSettingTypeEnum) => {
    setSelectedType(type);

    if (categorySetter) {
      categorySetter(c => {
        return {
          ...c, subcategories: c.subcategories.map(sc => {
            if (sc.keyName !== subcategory.keyName)
              return sc;
            return {
              ...sc, settingTypes: sc.settingTypes.map(st => {
                if (st.keyName !== settingType.keyName)
                  return st;
                return {
                  ...st,
                  setting: {
                    id: settingType.setting.id,
                    value: settingType.setting.value,
                    specificType: type
                  }
                }
              })
            }
          })
        }
      })
    }
  }

  const removeField = () => {
    if (categorySetter) {
      categorySetter(c => {
        return {
          ...c, subcategories: c.subcategories.map(sc => {
            if (sc.keyName !== subcategory.keyName)
              return sc;
            return {...sc, settingTypes: sc.settingTypes.filter(st => st.keyName !== settingType.keyName)}
          })
        }
      })
    }
  }

  const rules: Rule[] = category.keyName !== FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT ? [{
    min: 1,
    type: "number",
    message: intl.formatMessage(CommonFormMessages.ruleMustBePositive)
  }] : [];

  const isFieldDisabled = settingType.keyName === FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_TAXES && isMarginTaxesDisabled;
  const spanDependingOnSubcategory = [
    FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT,
    FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT,
    FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_TAXES,
    FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_COMPANY_MARGIN,
    FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_COEFFICIENT_DEFAULT,
  ].includes(subcategory.keyName as FlyHourPriceDefaultSubcategoryEnum) ? 12 : 6;

  return (
    <>
      {radioButtons}
      <Col span={spanDependingOnSubcategory}>
        <CustomFieldInput
          label={<Tooltip title={settingType.label}>
            <div>{settingType.label}</div>
            {!settingType.isDefault && <RemoveButton onClick={removeField} style={{minWidth: 12}}><RemoveIcon/></RemoveButton>}
          </Tooltip>}
          name={settingType.keyName}
          rules={rules}
        >
          <CustomInputNumber
            disabled={isFieldDisabled}
            controls={false}
            placeholder={intl.formatMessage(CommonFormMessages.commonPlaceholder)}
            addonAfter={selectedType === FlyHourPriceCalculatorSettingTypeEnum.FLAT ? currency?.name : '%'}
            style={{width: "100%", background: "#fff"}}
          />
        </CustomFieldInput>
      </Col>
    </>
  );
};

