import {ManagerEntity, UserEntity} from "../../../proto/generated/api_entities_pb";
import {roleFromBackendIdentifier} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {IManager} from "./personnelManagementReducerTypes";

export const mapManagerEntityToIManager = (item: ManagerEntity): IManager => {
  return {
    id: item.getManagerid(),
    name: item.getFirstname(),
    lastName: item.getLastname(),
    middleName: item.getMiddlename(),
    phone: item.getPhone(),
    email: item.getEmail(),
    role: roleFromBackendIdentifier(item.getRole()),
    avatarLink: item.getAvatarlink(),
  }
}

export const mapUserEntityToIManager = (item: UserEntity): IManager => {
  return {
    id: item.getUserid(),
    name: item.getFirstname(),
    lastName: item.getLastname(),
    middleName: item.getMiddlename(),
    phone: item.getPhone(),
    email: item.getEmail(),
    role: roleFromBackendIdentifier(item.getRole()),
    avatarLink: item.getAvatar(),
  }
}