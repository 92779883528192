import {useIntl} from "react-intl";
import { FlyHourPriceMessages } from "../../../../../../../intl/commonMessages/FlyHourPriceMessages";
import {IFlyHourCalculatorCategory} from "../../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {
  FlyHourPriceCalculatorSettingTypeEnum,
  FlyHourPriceDefaultCategoryEnum, FlyHourPriceDefaultSettingTypeEnum,
  FlyHourPriceDefaultSubcategoryEnum
} from "../types/flyHourPriceSettingEnums";


export const useFlyHourPriceDefaultFields = (initialCategories: IFlyHourCalculatorCategory[]) => {
  const intl = useIntl();

  let constantCategory: IFlyHourCalculatorCategory = {
    keyName: FlyHourPriceDefaultCategoryEnum.CATEGORY_CONSTANT,
    label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultCategoryEnum.CATEGORY_CONSTANT]),
    isDefault: true,
    subcategories: [
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SPENDING,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SPENDING]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_CREW,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_CREW]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_LEARNING,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_LEARNING]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_KVS,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_KVS]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SECOND_PILOT,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SECOND_PILOT]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FLIGHT_ATTENDANT,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FLIGHT_ATTENDANT]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_INSURANCE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_INSURANCE]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_OPERATION,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_OPERATION]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PLG,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PLG]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PRODUCER_DOCUMENTATION,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PRODUCER_DOCUMENTATION]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_JEPPESEN,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_JEPPESEN]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_ARINC,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_ARINC]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_INSURANCE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_INSURANCE]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FMS,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FMS]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_EGPWS,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_EGPWS]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMP,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMP]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMO,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMO]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_ADMINISTRATIVE_SPENDING,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_ADMINISTRATIVE_SPENDING]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_DRY_CLEANING_AND_STORAGE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_DRY_CLEANING_AND_STORAGE]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_LINEAR_MAINTENANCE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_LINEAR_MAINTENANCE]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_BASING,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_BASING]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_MAINTENANCE,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_MAINTENANCE]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_MANAGE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_MANAGE]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
    ]
  };

  let changeableCategory: IFlyHourCalculatorCategory = {
    keyName: FlyHourPriceDefaultCategoryEnum.CATEGORY_CHANGEABLE,
    label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultCategoryEnum.CATEGORY_CHANGEABLE]),
    isDefault: true,
    subcategories: [
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPENDING,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPENDING]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_SHIP_MAINTENANCE,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_SHIP_MAINTENANCE]),
        isDefault: true,
        settingTypes: [
          //TODO не хватает поля на бэке
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_NAVIGATION_TAXES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_NAVIGATION_TAXES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_ENGINE_TAXES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_ENGINE_TAXES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPARE_PARTS_TAXES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPARE_PARTS_TAXES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_VSU_TAXES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_VSU_TAXES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_FLY,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_FLY]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOUSEHOLD_EXPENSES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOUSEHOLD_EXPENSES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_FUEL,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_FUEL]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOTEL_EXPENSES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOTEL_EXPENSES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT,
          },
        ],
      },
    ]
  };

  let marginCategory: IFlyHourCalculatorCategory = {
    keyName: FlyHourPriceDefaultCategoryEnum.CATEGORY_MARGIN,
    label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultCategoryEnum.CATEGORY_MARGIN]),
    isDefault: true,
    subcategories: [
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_COMPANY_MARGIN,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_COMPANY_MARGIN]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_AMOUNT,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_AMOUNT]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
              specificType: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
          },
        ],
      },
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_TAXES,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_TAXES]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_TAXES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_TAXES]),
            isEnabled: true,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
          },
        ],
      },
    ]
  };

  let coefficientCategory: IFlyHourCalculatorCategory = {
    keyName: FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT,
    label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT]),
    isDefault: true,
    subcategories: [
      {
        keyName: FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_COEFFICIENT_DEFAULT,
        label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_COEFFICIENT_DEFAULT]),
        isDefault: true,
        settingTypes: [
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_NDS,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_NDS]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_HIGH_SEASON,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_HIGH_SEASON]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_LOW_DATES,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_LOW_DATES]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT,
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_URGENT_FLIGHT,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_URGENT_FLIGHT]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.PERCENT, // 1 - percent, 2 - sum
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_PASSENGER_SERVICE,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_PASSENGER_SERVICE]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT, // 1 - percent, 2 - sum
          },
          {
            keyName: FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_EAT,
            label: intl.formatMessage(FlyHourPriceMessages[FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_EAT]),
            isEnabled: false,
            isDefault: true,
            setting: {
              value: 0,
            },
            type: FlyHourPriceCalculatorSettingTypeEnum.FLAT, // 1 - percent, 2 - sum
          },
        ],
      },
    ]
  };

  let allCategories = [constantCategory, changeableCategory, marginCategory, coefficientCategory]

  initialCategories.forEach(initialCategory => {
    initialCategory.subcategories.forEach(initialSubcategory => {
      initialSubcategory.settingTypes.forEach(initialSettingType => {
        const subcategory = allCategories.find(c => c.keyName === initialCategory.keyName)?.subcategories
          .find(sc => sc.keyName === initialSubcategory.keyName);

        let isCustomType: boolean = false;
        if (subcategory?.settingTypes.every(st => st.keyName !== initialSettingType.keyName))
          isCustomType = true;

        allCategories = allCategories.map(c => {
          if (initialCategory.keyName !== c.keyName)
            return c;
          return {
            ...c, subcategories: [...c.subcategories.map(sc => {
              if (sc.keyName !== initialSubcategory.keyName)
                return sc;

              const settingTypes = [...sc.settingTypes.map(st => {
                if (st.keyName !== initialSettingType.keyName)
                  return st;
                return {...initialSettingType, label: st.label};
              })]

              if (isCustomType)
                settingTypes.push(initialSettingType);

              return {
                ...sc, settingTypes: settingTypes
              }
            })]
          }
        })
      })
    })
  })

  constantCategory = allCategories.find(c => c.keyName === constantCategory.keyName)!;
  changeableCategory = allCategories.find(c => c.keyName === changeableCategory.keyName)!;
  marginCategory = allCategories.find(c => c.keyName === marginCategory.keyName)!;
  coefficientCategory = allCategories.find(c => c.keyName === coefficientCategory.keyName)!;

  return {constantCategory, changeableCategory, marginCategory, coefficientCategory};
}