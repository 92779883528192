import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Row, Typography} from "antd";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {useNavigate} from "react-router";
import {clearLogin, login} from "../../../store/reducers/authReducer/authReducer";
import {ForgotPasswordModal} from "./components/ForgotPasswordModal";
import {ReactComponent as Logo} from '../../../media/icons/logo.svg';
import {CustomCard} from "../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../components/UI/PageWrapper/PageWrapper";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {roles} from '../../../config/acl/roles';
import {Route} from "../../../config/acl/routes/Route";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../components/UI/AntCustom/CustomButton";
import {onFinishTrimmed} from "../../../utils/helpers/formHelpers/onFinishTrimmed";


const LoginPageMessages = defineMessages({
  title: {
    id: "loginPage.title",
    defaultMessage: "Log in to access your Jetsnode account.",
  },
  login: {
    id: "loginPage.login",
    defaultMessage: "Log in",
  },
  forgotPassword: {
    id: "loginPage.forgotPassword",
    defaultMessage: "Forgot password?",
  },
  toRegister: {
    id: "loginPage.toRegister",
    defaultMessage: "Still don't have an account?",
  },
})

interface ILoginFormData {
  login: string,
  password: string,
}

export const LoginPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    isAuthenticated,
    isLoginLoading,
    isLoginSuccess,
    loginErrorMessage,
    role,
  } = useAppSelector(state => state.auth);

  useEffect(() => {
    return () => {
      dispatch(clearLogin());
    }
  }, [])

  useEffect(() => {
    if (!isLoginLoading && loginErrorMessage)
      message.error(loginErrorMessage);
  }, [isLoginLoading, isLoginSuccess, loginErrorMessage])

  const onFinish = (values: ILoginFormData) => {
    dispatch(login({
      login: values.login,
      password: values.password,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: ILoginFormData = {
    login: '',
    password: '',
  }
  const showModal = () => {
    setIsModalVisible(true);
  };

  if (isAuthenticated) {
    navigate(pathFromRoute(roles[role].homeRoute));
    return null;
  }

  return (
    <>
      {!isModalVisible &&
          <PageWrapper justifyContent={'center'}>
              <CustomCard title={<Logo/>} width={'max'} isContainerCard={true} maxWidth={400} titlePosition={'center'}>
                <Typography>{intl.formatMessage(LoginPageMessages.title)}</Typography>
                  <Form
                      form={form}
                      onFinish={(values) => onFinishTrimmed(values, onFinish)}
                      onFinishFailed={onFinishFailed}
                      layout={'vertical'}
                      initialValues={initialValues}
                  >
                      <CustomFieldInput
                          label={intl.formatMessage(CommonFormMessages.emailLabel)}
                          name={'login'}
                          rules={[
                            {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                            {type: 'email', message: intl.formatMessage(CommonFormMessages.ruleIncorrectEmail)}
                          ]}
                      >
                          <Input placeholder={intl.formatMessage(CommonFormMessages.emailPlaceholder)}/>
                      </CustomFieldInput>

                      <CustomFieldInput
                          label={intl.formatMessage(CommonFormMessages.passwordLabel)}
                          name={'password'}
                          rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
                      >
                          <Input.Password placeholder={intl.formatMessage(CommonFormMessages.passwordPlaceholder)}/>
                      </CustomFieldInput>

                      <CustomFieldInput style={{marginBottom: 12}}>
                          <CustomButton
                              block
                              heightSize={'m'}
                              type={'primary'}
                              htmlType={'submit'}
                              loading={isLoginLoading}
                          >
                            {intl.formatMessage(LoginPageMessages.login)}
                          </CustomButton>
                      </CustomFieldInput>

                    <Row justify={"space-between"}>
                      <Col>
                        <Button type={'link'} onClick={showModal}>{intl.formatMessage(LoginPageMessages.forgotPassword)}</Button>
                      </Col>
                      <Col>
                        <Button type={'link'} onClick={() => navigate(pathFromRoute(Route.register))}>{intl.formatMessage(LoginPageMessages.toRegister)}</Button>
                      </Col>
                    </Row>
                  </Form>
              </CustomCard>
          </PageWrapper>
      }

      {isModalVisible && <ForgotPasswordModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>}

    </>

  );
};

