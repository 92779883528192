import React, {useState} from 'react';
import {Tabs} from 'antd';
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {defineMessages, useIntl} from "react-intl";
import {OneWayForm} from "./components/OneWayForm";
import {RoundTripForm} from "./components/RoundTripForm";
import {MultiLegForm} from "./components/MultiLegForm";


export const NewTripRequestPageMessages = defineMessages({
  title: {
    id: "newTripRequestPage.title",
    defaultMessage: "Create request",
  },
  publishSuccess: {
    id: "newTripRequestPage.publishSuccess",
    defaultMessage: "The request has been successfully posted.",
  },
  addFlight: {
    id: "newTripRequestPage.addFlight",
    defaultMessage: "Add flight",
  },
  createRequest: {
    id: "newTripRequestPage.createRequest",
    defaultMessage: "Create request",
  },
  oneWay: {
    id: "newTripRequestPage.oneWay",
    defaultMessage: "One Way",
  },
  roundTrip: {
    id: "newTripRequestPage.roundTrip",
    defaultMessage: "Round Trip",
  },
  multiLeg: {
    id: "newTripRequestPage.multiLeg",
    defaultMessage: "Multi Leg",
  },
})

enum NewTripRequestTabKeysEnum {
  ONE_WAY = 'one_way',
  ROUND_TRIP = 'round_trip',
  MULTI_LEG = 'multi_leg',
}

export const NewTripRequestPage = () => {
  const intl = useIntl();
  const [activeTabKey, setActiveTabKey] = useState<NewTripRequestTabKeysEnum>(NewTripRequestTabKeysEnum.ONE_WAY);

  const tabList = [
    {
      key: NewTripRequestTabKeysEnum.ONE_WAY,
      label: intl.formatMessage(NewTripRequestPageMessages.oneWay),
      children: <OneWayForm />
    },
    {
      key: NewTripRequestTabKeysEnum.ROUND_TRIP,
      label: intl.formatMessage(NewTripRequestPageMessages.roundTrip),
      children: <RoundTripForm />
    },
    {
      key: NewTripRequestTabKeysEnum.MULTI_LEG,
      label: intl.formatMessage(NewTripRequestPageMessages.multiLeg),
      children: <MultiLegForm />
    },
  ];

  return (
    <PageWrapper alignItems={"center"}>
      <CustomCard title={intl.formatMessage(NewTripRequestPageMessages.title)} width={'max'} isContainerCard={true} onTabChange={(key: string) => {
        setActiveTabKey(key as NewTripRequestTabKeysEnum)
      }}>
        <Tabs activeKey={activeTabKey} onTabClick={(key: string) => {
          setActiveTabKey(key as NewTripRequestTabKeysEnum)
        }}>
          {tabList.map(t => <Tabs.TabPane key={t.key} tab={t.label}>{t.children}</Tabs.TabPane>)}
        </Tabs>
      </CustomCard>
    </PageWrapper>
  );
};