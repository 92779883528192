import React, {FC, useMemo, useState} from 'react';
import styled from "styled-components";
import {
  IChartererOrder,
} from "../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {ReactComponent as TrashIcon} from "../../../../../media/icons/delete.svg";
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {Col, Row} from "antd";
import {DetailItem} from "../../../../../components/businessCommon/detailItem/DetailItem";
import {ReactComponent as CollapseIcon} from "../../../../../media/icons/collapse.svg";
import {ReactComponent as ExpandIcon} from "../../../../../media/icons/expand.svg";
import {ReactComponent as PaxIcon} from "../../../../../media/icons/pax.svg";
import {ReactComponent as RouteArrowIcon} from "../../../../../media/icons/route_arrow_medium.svg";
import {ReactComponent as RouteMiniArrowIcon} from "../../../../../media/icons/route_mini_arrow.svg";
import {ArchiveOrderModal} from "./ArchiveOrderModal";
import {useAppSelector} from "../../../../../store/store";
import {DisplayComment} from "../../../../../utils/helpers/textDisplayHelpers/DisplayComment";
import {DisplayPlaneCategories} from "../../../../../utils/helpers/textDisplayHelpers/DisplayPlaneCategories";
import {DisplayYesOrNo} from "../../../../../utils/helpers/textDisplayHelpers/DisplayYesOrNo";
import {DisplayAirport} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {OrderTypeShield} from "../../../../../components/businessCommon/orderType/OrderTypeShield";
import {DisplayFullName} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {FlyRouteMini} from "../../../../../components/businessCommon/flyRoute/FlyRouteMini";
import {IAirport} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {FlyCompanies} from "../../../../../components/businessCommon/flyCompanies/FlyCompanies";
import {CustomLinkButton} from "../../../../../components/UI/Buttons/CustomLinkButton";
import {VerticalDivider} from "../../../../../components/UI/VerticalDivider/VerticalDivider";
import {
  IFreighterOrderRoute
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";


const OrderHeaderWrapper = styled.div`
  padding: 15px 16px 18px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  background: #FAFAFA;
  border-radius: 10px 10px 0 0;
  border: 1px solid #D9D9D9;
  border-bottom: none;

  & > hr {
    border: none;
    height: 1px;
    background: #D9D9D9;
    width: 100%;
    margin: 0;
  }
`;

const OrderHeaderMainInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

const OrderBrokerMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 16px;
  border-bottom: 1px solid #D9D9D9;
`;

const OrderBroker = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    :last-child {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: unset;
    flex-grow: 1;
    row-gap: 8px;

    & > div {
      :last-child {
        line-height: 17px;
      }
    }
  }
`;

const OrderHeaderMainInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 7px;

  & > div {
    :nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #808080;
    }

    :nth-child(2) {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
`;

const ArchiveSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;

  & > div {
    :last-child {
      cursor: pointer;
    }
  }
`;

const OrderHeaderAdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 16px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 8px;
    padding-bottom: 0;
  }
`;

const FlyRouteBigCards = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;

const FlyRouteBigCardWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
`;

const FlyRouteBigCardRoutesSection = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  align-items: center;
  flex-grow: 1;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    & > div {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      opacity: 0.5;

      :last-child {
        opacity: 1;
        display: flex;
        flex-direction: row;
        column-gap: 7px;

        & > div {
          :nth-child(1) {
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
          }

          :nth-child(2) {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

const FlyRouteBigCardPaxSection = styled.div`
  padding: 0 0 0 16px;
  width: 121px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    color: #000000;

    :nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      opacity: 0.5;
    }

    :nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      row-gap: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      & > svg > path {
        fill: #000000;
      }
    }
  }
`;

const MoreInfoMobileButton = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  color: #1890FF;

  svg > path {
    fill: #1890FF;
  }
`;

const FlyRouteSmallCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  row-gap: 16px;

  div {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;
  }

  & > div {
    :nth-child(1) {
      display: flex;
      flex-direction: column;
      row-gap: 1px;

      & > div {
        :nth-child(1) {
          font-weight: 400;
          opacity: 0.5;
        }

        :nth-child(2) {
          font-weight: 600;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          align-items: center;
        }
      }
    }

    :nth-child(2) {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      align-items: center;

      & > div {
        :nth-child(1) {
          font-weight: 400;
          opacity: 0.5;
        }

        :nth-child(2) {
          display: flex;
          align-items: center;
          flex-direction: row;
          column-gap: 4px;

          svg > path {
            fill: #000000;
          }
        }
      }
    }
  }
`;


const OrderHeaderMessages = defineMessages({
  title: {
    id: "orderHeader.title",
    defaultMessage: "Request",
  },
  companies: {
    id: "orderHeader.companies",
    defaultMessage: "Companies",
  },
  dateCreated: {
    id: "orderHeader.dateCreated",
    defaultMessage: "Creation date",
  },
})

interface IOrderRouteHeader {
  order: IChartererOrder;
}

export const OrderHeader: FC<IOrderRouteHeader> = ({order}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const [isMoreInfoExpanded, setIsMoreInfoExpanded] = useState<boolean>(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);

  const allOrderAirports: IAirport[] = useMemo(() => order.orderRoutes.reduce((acc, cur, index) => {
    if (index === 0)
      acc.push(cur.airportFrom);

    acc.push(cur.airportTo);
    return acc;
  }, [] as IAirport[]), [order.orderRoutes])

  const firstRoute = order.orderRoutes[0];
  const lastRoute = order.orderRoutes.slice(-1)[0];
  const isOnlyRoute = firstRoute.id === lastRoute.id;

  const moreInfo = <>
    {!isMobile && <hr/>}

    <OrderHeaderAdditionalInfoWrapper>
      {isMobile && <Col span={24}>
        <DetailItem label={intl.formatMessage(OrderHeaderMessages.dateCreated)}
                    value={formatDate(order.createdDate, offset, "date")}
                    size={'small'}/>
      </Col>}

      {!isMobile && <FlyRouteBigCards>
        {order.orderRoutes.map(orderRoute => <FlyRouteBigCard key={orderRoute.id} orderRoute={orderRoute}/>)}
      </FlyRouteBigCards>}

      {isMobile && order.orderRoutes.map(orderRoute => <FlyRouteSmallCard key={orderRoute.id}
                                                                          orderRoute={orderRoute}/>)}

      {isMobile &&
        <>
          <Col span={24}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.planeCategories)}
                        value={DisplayPlaneCategories(order.planeCategories)} size={'small'}/>
          </Col>
          <Col span={24}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)}
                        value={DisplayYesOrNo(order.companionsAllowed)} size={'small'}/>
          </Col>
        </>
      }

      {!isMobile && <Row gutter={10}>
        <Col flex={1}>
          <DetailItem label={intl.formatMessage(CommonDetailsMessages.planeCategories)}
                      value={DisplayPlaneCategories(order.planeCategories)}/>
        </Col>
        <Col>
          <DetailItem label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)}
                      value={DisplayYesOrNo(order.companionsAllowed)}/>
        </Col>
      </Row>}

      {/*<Row>*/}
      {/*  <Col flex={1}>*/}
      {/*    <DetailItem label={intl.formatMessage(CommonDetailsMessages.comment)}*/}
      {/*                value={DisplayComment(order.comment)}*/}
      {/*                size={isMobile ? 'small' : 'default'}/>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </OrderHeaderAdditionalInfoWrapper>
  </>

  const orderBrokerComponent = <OrderBroker>
    <OrderTypeShield type={order.type}/>
    <div>{DisplayFullName(order.user, "short")}</div>
  </OrderBroker>

  const companies = order.nestedOrderRequests.length > 0
    ? order.nestedOrderRequests.map(orderRequest => orderRequest.freighterCompany)
    : order.nestedEmptyLegs.map(emptyLeg => emptyLeg.freighterCompany);

  return (
    <OrderHeaderWrapper>
      <OrderHeaderMainInfoWrapper>
        {isMobile && <OrderBrokerMobileWrapper>
          {orderBrokerComponent}
          {!order.isArchived && <CustomButton icon={<TrashIcon/>} style={{width: 45, height: 35, background: "#FAFAFA"}}
                                              onClick={() => setIsArchiveModalOpen(true)}/>}
        </OrderBrokerMobileWrapper>}

        {!isMobile && <>
          {orderBrokerComponent}
          <VerticalDivider/>
        </>}

        <OrderHeaderMainInfoItem style={{width: isMobile ? "100%" : 320}}>
          <div>
            {formatDate(firstRoute.departureDate, firstRoute.airportFrom.timezone.offset, isOnlyRoute ? "datetime" : "date")}
            {!isOnlyRoute && ` – ${formatDate(lastRoute.departureDate, lastRoute.airportFrom.timezone.offset, "date")}`}
          </div>
          <FlyRouteMini airports={allOrderAirports} onMoreClick={() => setIsMoreInfoExpanded(prev => !prev)}/>
        </OrderHeaderMainInfoItem>

        {!isMobile &&
          <>
            <VerticalDivider/>

            <OrderHeaderMainInfoItem style={{flexGrow: 1}}>
              <div>{intl.formatMessage(OrderHeaderMessages.companies)}</div>
              <FlyCompanies companies={companies}/>
            </OrderHeaderMainInfoItem>

            <VerticalDivider/>

            <OrderHeaderMainInfoItem>
              <div>{intl.formatMessage(OrderHeaderMessages.dateCreated)}</div>
              <div>{formatDate(order.createdDate, offset, "date")}</div>
            </OrderHeaderMainInfoItem>

            <VerticalDivider/>

            <ArchiveSection>
              {!order.isArchived && <CustomLinkButton icon={<TrashIcon/>} onClick={() => setIsArchiveModalOpen(true)}>
                {intl.formatMessage(CommonButtonMessages.archive)}
              </CustomLinkButton>}
              <div onClick={() => setIsMoreInfoExpanded(prev => !prev)}>
                {isMoreInfoExpanded ? <CollapseIcon/> : <ExpandIcon/>}
              </div>
            </ArchiveSection>
          </>
        }
      </OrderHeaderMainInfoWrapper>

      {isMoreInfoExpanded && moreInfo}

      {isMobile && <MoreInfoMobileButton>
        <div
          onClick={() => setIsMoreInfoExpanded(prev => !prev)}>{intl.formatMessage(CommonButtonMessages.allDetails)}</div>
        {isMoreInfoExpanded ? <CollapseIcon/> : <ExpandIcon/>}
      </MoreInfoMobileButton>}

      {isArchiveModalOpen && <ArchiveOrderModal orderId={order.id} isModalVisible={isArchiveModalOpen}
                                                setIsModalVisible={setIsArchiveModalOpen}/>}
    </OrderHeaderWrapper>
  );
};


const FlyRouteBigCard: FC<{ orderRoute: IFreighterOrderRoute }> = ({orderRoute}) => {
  const intl = useIntl();

  return <FlyRouteBigCardWrapper>
    <FlyRouteBigCardRoutesSection>
      <div>
        <div>{intl.formatMessage(CommonDetailsMessages.from)}</div>
        <div>{formatDate(orderRoute.departureDate, orderRoute.airportFrom.timezone.offset, "datetime")}</div>
        <div>
          <div>{DisplayAirport(orderRoute.airportFrom, "codes")}</div>
          <div>({DisplayAirport(orderRoute.airportFrom, "location_and_name")})</div>
        </div>
      </div>
      <RouteArrowIcon/>
      <div>
        <div>{intl.formatMessage(CommonDetailsMessages.to)}</div>
        <div>
          <div>{DisplayAirport(orderRoute.airportTo, "codes")}</div>
          <div>({DisplayAirport(orderRoute.airportTo, "location_and_name")})</div>
        </div>
      </div>
    </FlyRouteBigCardRoutesSection>

    <VerticalDivider/>

    <FlyRouteBigCardPaxSection>
      <div>{intl.formatMessage(CommonDetailsMessages.pax)}</div>
      <div>
        <PaxIcon/>
        <div>{orderRoute.pax}</div>
      </div>
    </FlyRouteBigCardPaxSection>
  </FlyRouteBigCardWrapper>
}

const FlyRouteSmallCard: FC<{ orderRoute: IFreighterOrderRoute }> = ({orderRoute}) => {
  const intl = useIntl();

  return <FlyRouteSmallCardWrapper>
    <div>
      <div>{formatDate(orderRoute.departureDate, orderRoute.airportFrom.timezone.offset, "datetime")}</div>
      <div>
        <div>{DisplayAirport(orderRoute.airportFrom, "codes")}</div>
        <RouteMiniArrowIcon/>
        <div>{DisplayAirport(orderRoute.airportTo, "codes")}</div>
      </div>
    </div>

    <div>
      <div>{intl.formatMessage(CommonDetailsMessages.pax)}</div>
      <div>
        <PaxIcon/>
        <div>{orderRoute.pax}</div>
      </div>
    </div>
  </FlyRouteSmallCardWrapper>
}
