import {Carousel} from "antd";
import styled from "styled-components";

export const ChangeSlideButton = styled.div`
  height: 307px;
  width: 35px;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: background 0.2s ease;
  
  :hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const CarouselWrapper = styled.div`
  width: 468px;
  height: 307px;
  position: relative;

  ${ChangeSlideButton} {
    :first-child {
      left: 0;
      border-radius: 15px 0 0 15px;
    }

    :last-child {
      right: 0;
      border-radius: 0 15px 15px 0;
    }
  }
`;

export const CustomCarousel = styled(Carousel)`
  .slick-list {
    border-radius: 15px;
  }
`;
