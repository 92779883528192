import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {RegistrationsPage} from "../../../../../pages/Admin/Registrations/RegistrationsPage";
import {Role} from "../../../roles/Role";
import {defineMessage} from "react-intl";


const AdminRegistrationsRouteTitleMessage = defineMessage({
  id: "adminRegistrationsRoute.title",
  defaultMessage: "Registrations processing",
})

export const adminRegistrations: RouteDescriptor<Route.adminRegistrations> = {
  route: Route.adminRegistrations,
  render: RegistrationsPage,
  title: AdminRegistrationsRouteTitleMessage,
  path: '/lk/registrations',
  allowRoles: [
    Role.admin,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}