import {all, fork} from 'redux-saga/effects';
import {authSaga} from "./authSaga/authSaga";
import {emptyLegsSaga} from "./emptyLegsSaga/emptyLegsSaga";
import {newRequestSaga} from "./newRequestSaga/newRequestSaga";
import {tripBoardSaga} from "./tripBoardSaga/tripBoardSaga";
import {searchSaga} from "./searchSaga/searchSaga";
import {freighterRequestsSaga} from "./freighterRequestsSaga/freighterRequestsSaga";
import {chartererRequestsSaga} from "./chartererRequestsSaga/chartererRequestsSaga";
import {websocketSaga} from "./websocketSaga/websocketSaga";
import {chatSaga} from "./chatSaga/chatSaga";
import {licensesSaga} from "./licensesSaga";
import {scheduleSaga} from "./scheduleSaga/scheduleSaga";
import {requestCallbackSaga} from "./requestCallbackSaga/requestCallbackSaga";
import {agencySaga} from "./agencySaga/agencySaga";
import {personnelManagementSaga} from "./personnelManagementSaga/personnelManagementSaga";
import {registrationsSaga} from "./registrationsSaga/registrationsSaga";
import {fleetSaga} from "./fleetSaga/fleetSaga";
import {companySaga} from "./companySaga/companySaga";
import {userSaga} from "./userSaga/userSaga";
import {notificationsSaga} from "./notificationsSaga/notificationsSaga";

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(newRequestSaga),
    fork(emptyLegsSaga),
    fork(tripBoardSaga),
    fork(searchSaga),
    fork(freighterRequestsSaga),
    fork(chartererRequestsSaga),
    fork(websocketSaga),
    fork(chatSaga),
    fork(licensesSaga),
    fork(scheduleSaga),
    fork(requestCallbackSaga),
    fork(agencySaga),
    fork(personnelManagementSaga),
    fork(registrationsSaga),
    fork(fleetSaga),
    fork(companySaga),
    fork(userSaga),
    fork(notificationsSaga),
  ]);
}