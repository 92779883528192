import React, {FC} from 'react';
import {CustomButton, ICustomButton} from "../../UI/AntCustom/CustomButton";
import {ReactComponent as ChatIcon} from "../../../media/icons/chat_black.svg";
import styled from "styled-components";

const ChatNotificationDot = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  height: 10px;
  width: 10px;
  background: #C90000;
  border-radius: 100%;
`;

interface IChatButton extends Omit<ICustomButton, "children"> {
  hasNewMessages: boolean;
}

export const ChatButton: FC<IChatButton> = (props) => {
  const {hasNewMessages, ...otherProps} = props;

  return (
    <CustomButton heightSize={'m'} {...otherProps}>
      <ChatIcon />
      {hasNewMessages && <ChatNotificationDot />}
    </CustomButton>
  );
};
