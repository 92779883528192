import React, {Dispatch, FC, SetStateAction, useEffect} from "react";
import {Col, Form, Input, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {PasswordRegex} from "../../../../../../utils/helpers/regexes/passwordRegex";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {changePassword, clearChangePassword} from "../../../../../../store/reducers/userReducer/userReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const ChangeUserPasswordModalMessages = defineMessages({
  success: {
    id: "changeUserPasswordModal.success",
    defaultMessage: "Your password has been successfully changed.",
  },
  title: {
    id: "changeUserPasswordModal.title",
    defaultMessage: "Change password",
  },
})

interface IChangeUserPasswordFormData {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

interface IChangeUserPasswordModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ChangeUserPasswordModal: FC<IChangeUserPasswordModal> = ({
                                                                              isModalVisible,
                                                                              setIsModalVisible
                                                                            }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.user.changePassword);
  const user = useAppSelector(state => state.user.oneItemObject.item);

  useEffect(() => {
    return () => {
      dispatch(clearChangePassword());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ChangeUserPasswordModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IChangeUserPasswordFormData) => {
    dispatch(changePassword({
      userId: user.id,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      repeatNewPassword: values.repeatNewPassword,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IChangeUserPasswordFormData = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  }

  return (
    <CustomModal
      title={intl.formatMessage(ChangeUserPasswordModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.oldPasswordLabel)}
              name={'oldPassword'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.oldPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.newPasswordLabel)}
              name={'newPassword'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  pattern: PasswordRegex.pattern,
                  message: PasswordRegex.error(
                    intl.formatMessage(CommonFormMessages.rulePasswordValidationTitle),
                    [
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule1),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule2),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule3),
                    ]
                  ),
                }
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.newPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.repeatPasswordLabel)}
              name={'repeatNewPassword'}
              dependencies={['password']}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(intl.formatMessage(CommonFormMessages.passwordsMismatch)));
                  },
                })
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.repeatPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
          >
            {intl.formatMessage(CommonButtonMessages.change)}
          </CustomButton>
        </CustomFieldInput>
      </Form>
    </CustomModal>
  );
};