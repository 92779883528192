import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Form, Input, message} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {clearForgotPassword, forgotPassword} from "../../../../store/reducers/authReducer/authReducer";
import {defineMessages, useIntl} from "react-intl";
import {CustomModal} from "../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {renderSuccessModal} from "../../../../components/UI/StatusModals/renderSuccessModal";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";


const ForgotPasswordModalMessages = defineMessages({
  success: {
    id: "forgotPasswordModal.success",
    defaultMessage: "The request for password reset has been sent to your email address."
  },
  title: {
    id: "forgotPasswordModal.title",
    defaultMessage: "Reset password"
  },
})

interface IForgotPasswordFormData {
  email: string;
}

interface IForgotPasswordModal {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ForgotPasswordModal: FC<IForgotPasswordModal> = ({isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const {
    isForgotPasswordLoading,
    isForgotPasswordSuccess,
    forgotPasswordErrorMessage,
  } = useAppSelector(state => state.auth)

  useEffect(() => {
    return () => {
      dispatch(clearForgotPassword());
    }
  }, [])

  useEffect(() => {
    if (!isForgotPasswordLoading) {
      if (isForgotPasswordSuccess)
        renderSuccessModal(intl.formatMessage(ForgotPasswordModalMessages.success), () => setIsModalVisible(false));
      if (forgotPasswordErrorMessage)
        message.error(forgotPasswordErrorMessage);
    }
  }, [isForgotPasswordLoading, forgotPasswordErrorMessage, isForgotPasswordSuccess])

  const onFinish = (values: IForgotPasswordFormData) => {
    dispatch(forgotPassword({
      email: values.email
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IForgotPasswordFormData = {
    email: '',
  }

  return (
    <CustomModal title={intl.formatMessage(ForgotPasswordModalMessages.title)}
                 visible={isModalVisible}
                 onCancel={() => setIsModalVisible(false)}
                 centered={true}
                 footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <CustomFieldInput
          label={intl.formatMessage(CommonFormMessages.emailLabel)}
          name={'email'}
          rules={[
            {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
            {type: "email", message: intl.formatMessage(CommonFormMessages.ruleIncorrectEmail)}
          ]}>
          <Input placeholder={intl.formatMessage(CommonFormMessages.emailPlaceholder)}/>
        </CustomFieldInput>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isForgotPasswordLoading}
          >
            {intl.formatMessage(CommonButtonMessages.confirm)}
          </CustomButton>
        </CustomFieldInput>
      </Form>
    </CustomModal>
  );
};


