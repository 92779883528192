import React, {FC, ReactNode} from 'react';
import styled from "styled-components";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  
  .ant-btn {
    height: 50px;
    flex-grow: 1;
    
    :first-child {
      border-radius: 0 0 0 10px;
    }
    
    :last-child {
      border-radius: 0 0 10px 0;
    }
  }

  .ant-btn-background-ghost, 
  .ant-btn-background-ghost:hover, 
  .ant-btn-background-ghost:focus, 
  .ant-btn-background-ghost:active {
    background: #fff;
  }
`;

interface ICardBottomButtons {
  children: ReactNode;
}

export const CardBottomButtons: FC<ICardBottomButtons> = ({children}) => {
  return (
    <Buttons>
      {children}
    </Buttons>
  );
};
