import React, {useState} from 'react';
import {CustomCard} from "../../../../../../components/UI/AntCustom/CustomCard";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import styled from "styled-components";
import {useAppSelector} from "../../../../../../store/store";
import {ReactComponent as EmailIcon} from "../../../../../../media/icons/email.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../media/icons/phone.svg";
import {ReactComponent as LocationIcon} from "../../../../../../media/icons/location.svg";
import {ReactComponent as WebsiteIcon} from "../../../../../../media/icons/website.svg";
import {ProfileCompanyLicenses} from "./ProfileCompanyLicenses";
import {EditCompanyModal} from "./EditCompanyModal";
import {groupNotApproved, groupUserAdmin} from "../../../../../../config/acl/roles/groups";
import {ProfileCompanyAvatar} from "./ProfileCompanyAvatar";
import {
  isCompanyOnLoadDocuments, isCompanyOnModeration,
  useCompanyWarningStatus
} from "../../hooks/useCompanyWarningStatus";
import {Typography} from "antd";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 16px;
`;

const CompanyNameSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
  }
`;

const CompanyNameText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`;

const CompanyWarningText = styled.div<{ warningColor: string }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;

  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${props => props.warningColor};
  }

  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;
  }
`;

const AboutCompany = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const AboutCompanyTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const AboutCompanyDescription = styled(Typography.Paragraph)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6B6B6B;
  margin-bottom: 0 !important;
  word-break: break-word
`;

const EditCompanyButton = styled.button`
  display: flex;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1890FF;
  transition: 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;

const ContactInfo = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    word-break: break-word;
  }

  svg {
    min-width: 18px;
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1890FF;
    transition: 0.2s ease;
    text-decoration: underline;
    word-break: break-all;

    :hover {
      opacity: 0.8;
    }
  }
`;


const ProfileCompanyMessages = defineMessages({
  title: {
    id: "profileCompany.title",
    defaultMessage: "Company details",
  },
  loadDocuments: {
    id: "profileCompany.loadDocuments",
    defaultMessage: "Upload the documents",
  },
  onModeration: {
    id: "profileCompany.onModeration",
    defaultMessage: "Moderation",
  },
  aboutCompany: {
    id: "profileCompany.aboutCompany",
    defaultMessage: "Company description",
  },
  companyNoDescription: {
    id: "profileCompany.companyNoDescription",
    defaultMessage: "Add company description to highlight it among others",
  },
  tell: {
    id: "profileCompany.tell",
    defaultMessage: "Add...",
  },
  addEmail: {
    id: "profileCompany.addEmail",
    defaultMessage: "Add email",
  },
  addPhone: {
    id: "profileCompany.addPhone",
    defaultMessage: "Add phone number",
  },
  addAddress: {
    id: "profileCompany.addAddress",
    defaultMessage: "Add address",
  },
  addWebsite: {
    id: "profileCompany.addWebsite",
    defaultMessage: "Add company website",
  },
})

export const ProfileCompany = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {role} = useAppSelector(state => state.user.oneItemObject.item)
  const company = useAppSelector(state => state.company.oneItemObject.item)
  const [isEditCompanyOpen, setIsEditCompanyOpen] = useState<boolean>(false);
  const {companyWarningStatus, companyWarningStatusColor} = useCompanyWarningStatus()

  const isAdmin = [...groupUserAdmin, ...groupNotApproved].includes(role);

  const extra = isAdmin
    ? <CustomButton heightSize={'s'} type={'primary'}
                    onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(CommonButtonMessages.edit)}</CustomButton>
    : null

  return (
    <CustomCard title={!isMobile && intl.formatMessage(ProfileCompanyMessages.title)} width={'max'}
                isContainerCard={true} extra={!isMobile && extra}>
      <ContentWrapper>
        {!isMobile && <ProfileCompanyAvatar/>}
        <InfoSection>
          <CompanyNameSection>
            {isMobile && <ProfileCompanyAvatar/>}
            <CompanyNameText>{company.name}</CompanyNameText>
            {isCompanyOnLoadDocuments(companyWarningStatus) &&
              <CompanyWarningText warningColor={companyWarningStatusColor}>
                <span/>
                <div>{intl.formatMessage(ProfileCompanyMessages.loadDocuments)}</div>
              </CompanyWarningText>}

            {isCompanyOnModeration(companyWarningStatus) &&
              <CompanyWarningText warningColor={companyWarningStatusColor}>
                <span/>
                <div>{intl.formatMessage(ProfileCompanyMessages.onModeration)}</div>
              </CompanyWarningText>}
          </CompanyNameSection>
          <AboutCompany>
            <AboutCompanyTitle>{intl.formatMessage(ProfileCompanyMessages.aboutCompany)}</AboutCompanyTitle>
            <AboutCompanyDescription
              ellipsis={{rows: 2, expandable: true, symbol: intl.formatMessage(CommonButtonMessages.more)}}>
              {company.description
                ? company.description
                : intl.formatMessage(ProfileCompanyMessages.companyNoDescription)
              }
            </AboutCompanyDescription>
            {!company.description &&
              <EditCompanyButton style={{fontSize: 14}}
                                 onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(ProfileCompanyMessages.tell)}</EditCompanyButton>}
          </AboutCompany>
          <ContactInfo>
            <ContactInfoItem>
              <EmailIcon/>
              {company.email ? <div>{company.email}</div> :
                <EditCompanyButton
                  onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(ProfileCompanyMessages.addEmail)}</EditCompanyButton>}
            </ContactInfoItem>
            <ContactInfoItem>
              <PhoneIcon/>
              {company.phone ? <div>+{company.phone}</div> :
                <EditCompanyButton
                  onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(ProfileCompanyMessages.addPhone)}</EditCompanyButton>}
            </ContactInfoItem>
            <ContactInfoItem>
              <LocationIcon/>
              {company.address ? <div>{company.address}</div> :
                <EditCompanyButton
                  onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(ProfileCompanyMessages.addAddress)}</EditCompanyButton>}
            </ContactInfoItem>
            <ContactInfoItem>
              <WebsiteIcon/>
              {company.website
                ? <a href={`//${company.website}`} rel={'noreferrer'} target={'_blank'}>{company.website}</a>
                :
                <EditCompanyButton
                  onClick={() => setIsEditCompanyOpen(true)}>{intl.formatMessage(ProfileCompanyMessages.addWebsite)}</EditCompanyButton>
              }
            </ContactInfoItem>
          </ContactInfo>

          <ProfileCompanyLicenses/>

          {isMobile && <CustomButton onClick={() => setIsEditCompanyOpen(true)} type={'primary'}
                                     style={{width: "100%"}}>{intl.formatMessage(CommonButtonMessages.edit)}</CustomButton>}
        </InfoSection>
      </ContentWrapper>

      {isEditCompanyOpen &&
        <EditCompanyModal isModalVisible={isEditCompanyOpen} setIsModalVisible={setIsEditCompanyOpen}/>}
    </CustomCard>
  );
};
