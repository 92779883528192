import React, {Dispatch, FC, SetStateAction} from "react";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {PlanePhotos} from "../../../../pages/Lk/OperatorsOnly/Fleet/components/PlanePhotos";
import {FlightDrawerPlane} from "../plane/FlightDrawerPlane";
import {FlightDrawerOperator} from "../operator/FlightDrawerOperator";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {ReactComponent as ChatIcon} from "../../../../media/icons/chat_white.svg";
import {FlightDrawerContentWrapper, FlightDrawerPhotosAndContentWrapper} from "./FlightDrawerStyledComponents";
import {FlightDrawerOrderRequestOrderRouteFooter} from "../footers/FlightDrawerOrderRequestOrderRouteFooter";
import {FlightDrawerOrderRequestOrderDetails} from "../details/FlightDrawerOrderRequestOrderDetails";
import {defineMessages, useIntl} from "react-intl";
import {
  IFreighterOrderRequest
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {FlightDrawerPrice} from "../price/FlightDrawerPrice";


const OrderRequestOrderRouteFlightDrawerMessages = defineMessages({
  writeToChat: {
    id: "orderRequestOrderRouteFlightDrawer.writeToChat",
    defaultMessage: "Send message",
  },
})

interface IOrderRequestOrderRouteFlightDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  orderRequest: IFreighterOrderRequest;
  openChat: () => void;
}

export const OrderRequestOrderRouteFlightDrawer: FC<IOrderRequestOrderRouteFlightDrawer> = ({
                                                                                              isOpen,
                                                                                              setIsOpen,
                                                                                              orderRequest,
                                                                                              openChat,
                                                                                            }) => {
  const intl = useIntl();

  const footer = <FlightDrawerOrderRequestOrderRouteFooter orderRequest={orderRequest} />

  if (!orderRequest.isPlaneOffered)
    return null;

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer}>
      <FlightDrawerPhotosAndContentWrapper>
        {orderRequest.isPlaneOffered && <PlanePhotos imageFiles={orderRequest.plane!.imageFiles}/>}

        <FlightDrawerContentWrapper>
          {orderRequest.isPlaneOffered && <FlightDrawerPlane plane={orderRequest.plane!}/>}
          <FlightDrawerOrderRequestOrderDetails orderRequest={orderRequest}/>
          {orderRequest.isPlaneOffered && <FlightDrawerPrice price={orderRequest.price!} currency={orderRequest.currency!} />}
          <FlightDrawerOperator managerUser={orderRequest.user}
                                company={orderRequest.company}/>
          <CustomButton type={"primary"} onClick={openChat} style={{borderRadius: 10}}>
            <ChatIcon/>
            <div>{intl.formatMessage(OrderRequestOrderRouteFlightDrawerMessages.writeToChat)}</div>
          </CustomButton>
        </FlightDrawerContentWrapper>
      </FlightDrawerPhotosAndContentWrapper>
    </CustomDrawer>
  );
};