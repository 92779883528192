import {isValidDate, parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {EmptyLegEntity} from "../../../proto/generated/api_entities_pb";
import {ISearchedEmptyLeg} from "./searchReducerTypes";
import {mapAirportEntityToIAirport, mapPlaneEntityToIPlane} from "../fleetReducer/fleetMappers";
import {mapCompanyEntityToICompany} from "../companyReducer/companyMappers";
import {mapUserEntityToIUser} from "../userReducer/userMappers";

export const mapEmptyLegEntityToIFreighterEmptyLeg = (item: EmptyLegEntity): ISearchedEmptyLeg => {
  const airportFrom = item.getAirportfrom();
  if (!airportFrom)
    throw new Error("Null AirportEntity (airportFrom) in received EmptyLegEntity");

  const airportTo = item.getAirportto();
  if (!airportTo)
    throw new Error("Null AirportEntity (airportTo) in received EmptyLegEntity");

  const currency = item.getCurrencyentity();
  if (!currency)
    throw new Error("Null CurrencyEntity (currencyEntity) in received EmptyLegEntity")

  const availableFromDate = item.getAvailablefromdate();
  if (availableFromDate && !isValidDate(parseStringAsUTCDate(availableFromDate)))
    throw new Error(`Invalid availableFromDate in received EmptyLegEntity, ${item.getAvailablefromdate()}`);

  const availableToDate = item.getAvailabletodate();
  if (availableToDate && !isValidDate(parseStringAsUTCDate(availableToDate)))
    throw new Error(`Invalid availableToDate in received EmptyLegEntity, ${item.getAvailabletodate()}`);

  const departureDate = item.getDeparturetime();
  if (departureDate && !isValidDate(parseStringAsUTCDate(departureDate)))
    throw new Error(`Invalid departureDate in received EmptyLegEntity, ${item.getAvailabletodate()}`);

  const freighterUser = item.getFreighter();
  if (!freighterUser)
    throw new Error("Null UserEntity (freighter) in received EmptyLegEntity")

  const freighterCompany = item.getFlycompany();
  if (!freighterCompany)
    throw new Error("Null CompanyEntity (freighterCompany) in received EmptyLegEntity");

  const plane = item.getPlane();
  if (!plane)
    throw new Error("Null PlaneEntity (plane) in received EmptyLegEntity");
  const mappedPlane = mapPlaneEntityToIPlane(plane);

  const dateModified = parseStringAsUTCDate(item.getUpdatedat());
  if (dateModified && !isValidDate(dateModified))
    throw new Error(`Invalid availableFromDate in received OrderRouteEntity > EmptyLegEntity, ${item.getUpdatedat()}`);

  const flyHours = Math.floor(item.getFlyduration() / 60);
  const flyMinutes = item.getFlyduration() % 60

  const arrivalDate = (departureDate && item.getFlyduration())
    ? new Date(parseStringAsUTCDate(departureDate).getTime() + 60000 * item.getFlyduration())
    : undefined;

  return {
    emptyLegId: item.getEmptylegid(),
    freighterCompany: mapCompanyEntityToICompany(freighterCompany),
    freighterUser: mapUserEntityToIUser(freighterUser),
    airportFrom: mapAirportEntityToIAirport(airportFrom),
    airportTo: mapAirportEntityToIAirport(airportTo),
    availableFromDate: availableFromDate ? parseStringAsUTCDate(availableFromDate) : undefined,
    availableToDate: availableToDate ? parseStringAsUTCDate(availableToDate) : undefined,
    departureDate: departureDate ? parseStringAsUTCDate(departureDate) : undefined,
    arrivalDate: arrivalDate,
    emptyLegStatusId: item.getEmptylegstatusid(),
    price: item.getPrice(),
    currency: {
      id: currency.getCurrencyid(),
      name: currency.getName(),
    },
    flyHours: flyHours,
    flyMinutes: flyMinutes,
    flyDistance: item.getFlydistance(),
    comment: item.getComment(),
    plane: mappedPlane,
    dateModified: dateModified,
  }
}