import React, {useEffect} from 'react';
import {RootRoutes} from "./routes/RootRoutes";
import styled from "styled-components";
import 'antd/dist/antd.min.css';
import {Header} from "./components/UI/Header/Header";
import {Footer} from "./components/UI/Footer/Footer";
import {Content} from "antd/es/layout/layout";
import {API} from "./API/API";
import {useAppDispatch, useAppSelector} from "./store/store";
import {Loader} from "./components/UI/Loader/Loader";
import {Role} from "./config/acl/roles/Role";
import {NotificationsWindow} from "./components/notifications/notificationsCentre/NotificationsWindow";
import {NotificationsProvider} from "./components/notifications/context/NotificationsProvider";
import {connect, disconnect, sendPing} from "./websocket/websocket";
import {NotificationPush} from "./components/notifications/notificationsCentre/NotificationPush";
import {groupApproved} from "./config/acl/roles/groups";
import {getChatList} from "./store/reducers/chatReducer/chatReducer";
import {mediaScreenSizes} from "./utils/constans/styles/screenSizes";


const AppStyled = styled.div`
  display: flex;
  background-color: #edeff1;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    background-color: #F3F5F7;
  }
`;

const ContentStyled = styled(Content)`
  display: flex;
  flex-grow: 1;
  //for header space
  margin-top: 64px;
`;


export function App() {
  const dispatch = useAppDispatch();
  const {role, isAuthenticated} = useAppSelector(state => state.auth);

  useEffect(() => {
    API.refreshToken().then(() => {
    }).catch(() => {
    });

    // Fixes mobile height recognition
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      connect();
      sendPing();
      groupApproved.includes(role) && dispatch(getChatList());
    } else {
      disconnect();
    }
  }, [isAuthenticated])

  return (
    <AppStyled>
      {(role !== Role.unknown)
        ?
        <>
          <NotificationsProvider>
            <Header/>
            <NotificationsWindow/>
            <NotificationPush/>
            <ContentStyled>
              <RootRoutes/>
            </ContentStyled>
            <Footer/>
          </NotificationsProvider>
        </>
        : <Loader/>
      }

    </AppStyled>
  );
}

