import React, {FC, ReactNode, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {IntlProvider} from "react-intl";
import enLangMessages from "../../../intl/languages/en.json";

interface ILanguageContextProps {
  langCode?: string;
  changeLanguage?: (lang: string) => void;
}

export const LanguageContext = React.createContext<ILanguageContextProps>({});

interface ILanguageWrapperProps {
  children: ReactNode;
}

//TODO refactor for lang switch support
export const LanguageWrapper: FC<ILanguageWrapperProps> = ({children}) => {
  // const dispatch = useAppDispatch();
  // const {langCode, staticPhrases} = useAppSelector(state => state.language);
  const langCode = "en-us";
  const [messages, setMessages] = useState({});

  // useEffect(() => {
  //   setMessages(staticPhrases);
  // }, [staticPhrases])

  const changeLanguage = (lang: string) => {
  //   dispatch(setLanguage({preferredLangCode: lang}));
  };

  //TODO turn off error handling plug
  return (
    <LanguageContext.Provider value={{langCode, changeLanguage}}>
      <IntlProvider locale='en' defaultLocale="en" messages={enLangMessages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};