import React from 'react';
import {HeaderDesktopLeft} from "./HeaderDesktopLeft";
import {HeaderDesktopRight} from "./HeaderDesktopRight";
import styled from "styled-components";

const HeaderDesktopStyled = styled.div`
  display: flex;
  max-width: 100vw;
  width: 100%;
  background: white;
  height: 64px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
  //box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.22) inset;
  position: fixed;
  top: 0;
  z-index: 99;
`;


export const HeaderDesktop = () => {

  return (
    <HeaderDesktopStyled>
      <HeaderDesktopLeft />
      <HeaderDesktopRight />
    </HeaderDesktopStyled>
  );
};

