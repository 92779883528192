import {Route} from "../../../../config/acl/routes/Route";
import {
  INotification,
  NotificationType
} from "../../../../store/reducers/notificationsReducer/notificationsReducerTypes";

export const isNotificationForRoute = (route: Route, notification: INotification): boolean => {

  switch (route) {
    case Route.requests:
      switch (notification.type) {
        case NotificationType.TYPE_INCOME_MESSAGE: return true;
        case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED: return true;
        case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED: return true;
        case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN: return true;
        case NotificationType.TYPE_EMPTY_LEG_UPDATED: return true;
        case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED: return true;
        case NotificationType.TYPE_ORDER_REQUEST_ARCHIVED: return true;
        case NotificationType.TYPE_ORDER_REQUEST_OFFERED: return true;
        default: return false;
      }
    case Route.emptyLegs:
      switch (notification.type) {
        case NotificationType.TYPE_INCOME_MESSAGE: return !!notification.metadata.emptyLegId;
        case NotificationType.TYPE_EMPTY_LEG_ARCHIVED_EXPIRED: return true;
        case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED: return true;
        case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN: return true;
        default: return false;
      }
    case Route.requestsFromAgents:
      switch (notification.type) {
        case NotificationType.TYPE_INCOME_MESSAGE: return !!notification.metadata.orderRequestId;
        case NotificationType.TYPE_ORDER_REQUEST_CREATED: return true;
        case NotificationType.TYPE_ORDER_REQUEST_EXPIRED: return true;
        case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED: return true;
        default: return false;
      }
    default:
      return false;
  }
}