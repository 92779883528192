import {RequestCallbackEntity} from "../../../proto/generated/api_entities_pb";
import {IClient} from "./requestCallbackReducerTypes";

export const mapRequestCallbackEntityToIClient = (item: RequestCallbackEntity): IClient => {
  return {
    firstName: item.getFirstname(),
    lastName: item.getLastname(),
    middleName: item.getMiddlename(),
    phone: item.getPhone(),
    message: item.getMessage(),
  }
}