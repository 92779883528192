import {combineReducers} from 'redux';
import authSlice from './authReducer/authReducer';
import newRequestSlice from './newRequestReducer/newRequestReducer'
import emptyLegsSlice from './emptyLegsReducer/emptyLegsReducer';
import tripBoardSlice from './tripBoardReducer/tripBoardReducer';
import searchSlice from './searchReducer/searchReducer';
import freighterRequestsSlice from './freighterRequestsReducer/freighterRequestsReducer';
import chartererRequestsSlice from './chartererRequestsReducer/chartererRequestsReducer';
import websocketSlice from './websocketReducer/websocketReducer';
import chatSlice from './chatReducer/chatReducer';
import licensesSlice from "./licensesReducer";
import scheduleSlice from "./scheduleReducer/scheduleReducer";
import requestCallbackSlice from "./requestCallbackReducer/requestCallbackReducer";
import agencySlice from "./agencyReducer/agencyReducer";
import personnelManagementSlice from "./personnelManagementReducer/personnelManagementReducer";
import registrationsSlice from "./registrationsReducer/registrationsReducer";
import fleetSlice from "./fleetReducer/fleetReducer";
import companySlice from "./companyReducer/companyReducer";
import userSlice from "./userReducer/userReducer";
import notificationsSlice from "./notificationsReducer/notificationsReducer";


export const rootReducer = combineReducers({
  auth: authSlice,
  newRequest: newRequestSlice,
  emptyLegs: emptyLegsSlice,
  tripBoard: tripBoardSlice,
  search: searchSlice,
  freighterRequests: freighterRequestsSlice,
  chartererRequests: chartererRequestsSlice,
  websocket: websocketSlice,
  chat: chatSlice,
  licenses: licensesSlice,
  schedule: scheduleSlice,
  requestCallback: requestCallbackSlice,
  agency: agencySlice,
  personnelManagement: personnelManagementSlice,
  registrations: registrationsSlice,
  fleet: fleetSlice,
  company: companySlice,
  user: userSlice,
  notifications: notificationsSlice,
});