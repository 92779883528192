import React, {FC, ReactElement} from 'react';
import styled from "styled-components";
import {ReactComponent as AmbulanceIcon} from "../../../../media/icons/ambulance.svg";
import {ReactComponent as AnimalsIcon} from "../../../../media/icons/animals.svg";
import {ReactComponent as SmokingIcon} from "../../../../media/icons/smoking.svg";
import {ReactComponent as CargoIcon} from "../../../../media/icons/cargo.svg";
import {IPlane} from "../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {formatDate} from "../../../../utils/helpers/dateHelpers/dateHelpers";
import {CustomLoadedFileWrapper, CustomLoadedFileWrapperTitle, CustomUpload} from "../../../UI/AntCustom/CustomUpload";
import {useAppSelector} from "../../../../store/store";
import {defineMessages, useIntl} from "react-intl";
import {CommonUnitMessages} from "../../../../intl/commonMessages/CommonUnitMessages";
import {DisplayAirport} from "../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 24px 20px 30px 20px;
  
  hr {
    opacity: 0.3;
    border: none;
    height: 1px;
    background: #000000;
    margin: 16px 0;
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    padding: 16px 16px 32px 16px;
  }
`;

const Title = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 5px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 2px;
  }
`;

const Subtitle = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

const Specifications = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 16px 0;
`;

const Specification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    :first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #000000;
      opacity: 0.5;
    }

    :last-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const Files = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 24px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
  }
`;

const Comfort = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComfortTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
`;

const ComfortDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 16px;
`;

const ComfortItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 24px;
  row-gap: 12px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 8px;
  }
`;

const ComfortItem = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  align-items: center;
  
  svg {
    min-width: 29px;
    
    path {
      fill: ${props => props.isActive ? "#000000" : "#D9D9D9"};
    }
  }
  
  div {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${props => props.isActive ? "#000000" : "#D9D9D9"};
  }
`;


const FlightDrawerPlaneMessages = defineMessages({
  regNumber: {
    id: "flightDrawerPlane.regNumber",
    defaultMessage: "Registration number",
  },
  baseAirport: {
    id: "flightDrawerPlane.baseAirport",
    defaultMessage: "Base airport",
  },
  manufactureDate: {
    id: "flightDrawerPlane.manufactureDate",
    defaultMessage: "Manufacture date",
  },
  renovationDate: {
    id: "flightDrawerPlane.renovationDate",
    defaultMessage: "Renovation date",
  },
  capacity: {
    id: "flightDrawerPlane.capacity",
    defaultMessage: "Seat capacity",
  },
  flyDistance: {
    id: "flightDrawerPlane.flyDistance",
    defaultMessage: "Max. flying range",
  },
  ambulance: {
    id: "flightDrawerPlane.ambulance",
    defaultMessage: "Air ambulance",
  },
  animals: {
    id: "flightDrawerPlane.animals",
    defaultMessage: "Animals aboard",
  },
  smoking: {
    id: "flightDrawerPlane.smoking",
    defaultMessage: "Smoking aboard",
  },
  cargo: {
    id: "flightDrawerPlane.cargo",
    defaultMessage: "Air cargo freight",
  },
  insurance: {
    id: "flightDrawerPlane.insurance",
    defaultMessage: "Insurance",
  },
  registrationCertificate: {
    id: "flightDrawerPlane.registrationCertificate",
    defaultMessage: "Aircraft reg. certificate",
  },
  comfort: {
    id: "flightDrawerPlane.comfort",
    defaultMessage: "Facilities",
  },
})

interface IPlaneSpecification {
  title: string;
  value: string;
}

interface IPlaneComfort {
  icon: ReactElement;
  title: string;
  isActive: boolean;
}

interface IOrderRouteFlightDrawerPlane {
  plane: IPlane;
}

export const FlightDrawerPlane: FC<IOrderRouteFlightDrawerPlane> = ({plane}) => {
  const intl = useIntl();

  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);

  const specifications: IPlaneSpecification[] = [
    {title: intl.formatMessage(FlightDrawerPlaneMessages.regNumber), value: plane.registrationNumber},
    {title: intl.formatMessage(FlightDrawerPlaneMessages.baseAirport), value: DisplayAirport(plane.baseAirport, "codes")},
    {title: intl.formatMessage(FlightDrawerPlaneMessages.manufactureDate), value: formatDate(plane.manufactureDate, offset, "year")},
    {title: intl.formatMessage(FlightDrawerPlaneMessages.renovationDate), value: plane.renovationDate ? formatDate(plane.renovationDate, offset, "year") : "-"},
    {title: intl.formatMessage(FlightDrawerPlaneMessages.capacity), value: `${plane.maxPassengers} ${intl.formatMessage(CommonUnitMessages.people)}`},
    {title: intl.formatMessage(FlightDrawerPlaneMessages.flyDistance), value: `${plane.maxDistance} ${intl.formatMessage(CommonUnitMessages.km)}`},
  ]

  const comfortItems: IPlaneComfort[] = [
    {icon: <AmbulanceIcon />, title: intl.formatMessage(FlightDrawerPlaneMessages.ambulance), isActive: plane.isAmbulance},
    {icon: <AnimalsIcon />, title: intl.formatMessage(FlightDrawerPlaneMessages.animals), isActive: plane.isAnimals},
    {icon: <SmokingIcon />, title: intl.formatMessage(FlightDrawerPlaneMessages.smoking), isActive: plane.isSmoking},
    {icon: <CargoIcon />, title: intl.formatMessage(FlightDrawerPlaneMessages.cargo), isActive: plane.isCargo},
  ]

  return (
    <Wrapper>
      <Title>{plane.planeType.name}</Title>
      <Subtitle>{plane.planeType.planeCategory.name}</Subtitle>
      <Specifications>
        {specifications.map(sp =>
          <Specification key={sp.title}>
            <div>{sp.title}</div>
            <div>{sp.value}</div>
          </Specification>
        )}
      </Specifications>

      <Files>
        <CustomLoadedFileWrapper>
          <CustomLoadedFileWrapperTitle>{intl.formatMessage(FlightDrawerPlaneMessages.insurance)}</CustomLoadedFileWrapperTitle>
          <CustomUpload fileList={[plane.insuranceFile]} />
        </CustomLoadedFileWrapper>

        <CustomLoadedFileWrapper>
          <CustomLoadedFileWrapperTitle>{intl.formatMessage(FlightDrawerPlaneMessages.registrationCertificate)}</CustomLoadedFileWrapperTitle>
          <CustomUpload fileList={[plane.certificateFile]} />
        </CustomLoadedFileWrapper>
      </Files>

      <hr />

      <Comfort>
        <ComfortTitle>{intl.formatMessage(FlightDrawerPlaneMessages.comfort)}</ComfortTitle>
        {plane.description && <ComfortDescription>{plane.description}</ComfortDescription>}
        <ComfortItems>
          {comfortItems.map(c => <ComfortItem isActive={c.isActive} key={c.title}>
            {c.icon}
            <div>{c.title}</div>
          </ComfortItem>)}
        </ComfortItems>
      </Comfort>
    </Wrapper>
  );
};
