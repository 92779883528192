import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {SearchPage} from "../../../../../pages/Lk/BrokersOnly/Search/SearchPage";
import {groupCharterer} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const SearchEmptyLegsRouteTitleMessage = defineMessage({
  id: "searchEmptyLegsRoute.title",
  defaultMessage: "Find Empty Legs",
})

export const searchEmptyLegs: RouteDescriptor<Route.searchEmptyLegs> = {
  route: Route.searchEmptyLegs,
  render: SearchPage,
  title: SearchEmptyLegsRouteTitleMessage,
  path: '/lk/search',
  allowRoles: [
    ...groupCharterer,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}