import {NotificationApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  NotificationApiNotificationListRequest,
  NotificationApiNotificationListResponse,
  NotificationApiReadNotificationsRequest,
  ResultResponse
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new NotificationApiServiceClient(getProxyUrl(), null, null);

export class NotificationApi {
  static async getNotificationList(req: NotificationApiNotificationListRequest): Promise<NotificationApiNotificationListResponse> {
    return new Promise((resolve, reject) => {
      API.call<NotificationApiNotificationListRequest, NotificationApiNotificationListResponse>(client, client.notificationList, req, resolve, reject)
    })
  }

  static async readNotifications(req: NotificationApiReadNotificationsRequest): Promise<ResultResponse> {
    return new Promise((resolve, reject) => {
      API.call<NotificationApiReadNotificationsRequest, ResultResponse>(client, client.readNotifications, req, resolve, reject)
    })
  }
}