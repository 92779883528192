import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {Role} from "../../../roles/Role";
import {FleetPage} from "../../../../../pages/Lk/OperatorsOnly/Fleet/FleetPage";
import {PageGroupMessages, PageGroups} from "../../pageGroups";
import {defineMessage} from "react-intl";

const FleetRouteTitleMessage = defineMessage({
  id: "fleetRoute.title",
  defaultMessage: "Aircraft",
})

export const fleet: RouteDescriptor<Route.fleet> = {
  route: Route.fleet,
  render: FleetPage,
  title: FleetRouteTitleMessage,
  path: '/lk/fleet',
  allowRoles: [
    Role.freighterAdmin,
    Role.freighterServiceManager,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
  group: PageGroups.FLEET,
  groupTitle: PageGroupMessages.fleet,
}