import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import {API} from "../../../../../API/API";
import {defineMessages, useIntl} from "react-intl";
import {PageModel, UserAccountApiFlyCompanyListRequest} from "../../../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../../../utils/constans/pagination/pageModelLimits";
import defaultAvatar from "../../../../../media/images/default_avatar.png"
import {Checkbox, Input, Spin} from "antd";
import {ReactComponent as CloseMiniIcon} from "../../../../../media/icons/close_mini.svg";
import debounce from 'lodash/debounce';
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const CompanyMultiSelectorWrapper = styled.div`
  width: 354px;
  display: flex;
  flex-direction: column;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
  }
`;

const CompaniesSelectorFrame = styled.div`
  padding: 16px 0 16px 0;
  border: 1px solid #D9DAD9;
  border-radius: 5px;
`;

const CompaniesSearchWrapper = styled.div`
  padding: 0 12px 12px 12px;
`;

const CompaniesSelectorList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 358px;
  overflow-y: scroll;
`;

const CompaniesSelectorOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;
  padding: 4px 12px;

  :hover {
    background: #D9D9D9;
  }
`;

const CompaniesSelectorCompanyImageAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;

  img {
    width: 31px;
    height: 31px;
    border-radius: 100%;
    object-fit: cover;
  }
`;

const SelectedCompaniesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  column-gap: 16px;
  row-gap: 10px;
`;

const SelectedCompany = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;

  div {
    :last-child {
      display: flex;
      align-items: center;
      opacity: 1;
      transition: opacity 0.2s ease;
      cursor: pointer;

      :hover {
        opacity: 0.7;
      }
    }
  }
`;

export const CompanyMultiSelectorMessages = defineMessages({
  label: {
    id: "companyMultiSelector.label",
    defaultMessage: "Choose companies"
  },
  error: {
    id: "companyMultiSelector.error",
    defaultMessage: "Please select at least one company"
  },
})


export type CompanySelectorOption = {
  id: number;
  name: string;
  imageUrl?: string;
}

interface ICompanyMultiSelector {
  selectedCompanies: CompanySelectorOption[];
  setSelectedCompanies: Dispatch<SetStateAction<CompanySelectorOption[]>>
}

export const CompanyMultiSelector: FC<ICompanyMultiSelector> = ({selectedCompanies, setSelectedCompanies}) => {
  const intl = useIntl();
  const [companyList, setCompanyList] = useState<CompanySelectorOption[]>([]);
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res = await API.getFlyCompanyList(
        new UserAccountApiFlyCompanyListRequest()
          .setPagemodel(new PageModel().setPage(0).setLimit(PageModelLimits.FlyCompanies))
          .setQuery(query)
      )

      const mappedCompanies = res.getFlycompaniesList().map<CompanySelectorOption>(company => {
        return {
          id: company.getCompanyid(),
          name: company.getCompanyname(),
          imageUrl: company.getAvatar()?.getFilepath(),
        }
      });

      setCompanyList(mappedCompanies);
      setIsLoading(false);
    })()
  }, [query])

  const onCompanyCheck = (company: CompanySelectorOption) => {
    if (selectedCompanies.some(c => c.id === company.id))
      setSelectedCompanies(prev => prev.filter(c => c.id !== company.id));
    else
      setSelectedCompanies(prev => [...prev, company])
  }

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => setQuery(e.target.value), 1000)();
  }

  return (
    <CustomFieldInput
      label={intl.formatMessage(CompanyMultiSelectorMessages.label)}
    >
      <CompanyMultiSelectorWrapper>
        <CompaniesSelectorFrame>
          <CompaniesSearchWrapper>
            <Input placeholder={intl.formatMessage(CommonFormMessages.searchPlaceholder)}
                   onChange={onSearch}
            />
          </CompaniesSearchWrapper>

          <CompaniesSelectorList>
            {isLoading
              ? <Spin />
              : companyList.map(company => {
              return <CompaniesSelectorOption key={company.id} onClick={() => onCompanyCheck(company)}>
                <CompaniesSelectorCompanyImageAndName>
                  <img src={company.imageUrl ? company.imageUrl : defaultAvatar} alt={"company " + company.name}/>
                  <div>{company.name}</div>
                </CompaniesSelectorCompanyImageAndName>

                <Checkbox checked={selectedCompanies.some(c => c.id === company.id)}/>
              </CompaniesSelectorOption>
            })}
          </CompaniesSelectorList>
        </CompaniesSelectorFrame>

        <SelectedCompaniesWrapper>
          {selectedCompanies.map(company => <SelectedCompany key={company.id}>
            <div>{company.name}</div>
            <div onClick={() => onCompanyCheck(company)}>
              <CloseMiniIcon/>
            </div>
          </SelectedCompany>)}
        </SelectedCompaniesWrapper>
      </CompanyMultiSelectorWrapper>
    </CustomFieldInput>
  );
};
