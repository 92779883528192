import {EmptyLegEntity, EmptyLegOrderRouteEntity, OrderRouteEntity} from "../../../proto/generated/api_entities_pb";
import {IEmptyLeg, IEmptyLegOrder} from "./emptyLegsReducerTypes";
import {isValidDate, parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {
  mapAirportEntityToIAirport,
  mapPlaneCategoryEntityToIPlaneCategory,
  mapPlaneEntityToIPlane
} from "../fleetReducer/fleetMappers";
import {mapUserEntityToIUser} from "../userReducer/userMappers";
import {mapCompanyEntityToICompany} from "../companyReducer/companyMappers";
import {mapUserEntityToIManager} from "../personnelManagementReducer/personnelManagementMappers";


const mapOrderRouteEntityToIEmptyLegOrderRoute = (item: OrderRouteEntity, emptyLegOrderRouteEntity?: EmptyLegOrderRouteEntity): IEmptyLegOrder => {
  const airportFrom = item.getAirportfrom();
  if (!airportFrom)
    throw new Error("Null AirportEntity (airportFrom) in received OrderRouteEntity");

  const airportTo = item.getAirportto();
  if (!airportTo)
    throw new Error("Null AirportEntity (airportTo) in received OrderRouteEntity");

  const departureDate = parseStringAsUTCDate(item.getDeparturedate());
  if (!isValidDate(departureDate))
    throw new Error(`Invalid departureDate in received OrderRouteEntity, ${item.getDeparturedate()}`);

  const company = item.getCompany();
  if (!company)
    throw new Error("Null CompanyEntity (company) in received OrderRouteEntity");

  const order = item.getOrder();
  if (!order)
    throw new Error("Null OrderEntity (order) in received OrderRouteEntity");

  const planeCategories = order.getPlanecategoriesList().map(mapPlaneCategoryEntityToIPlaneCategory);

  const manager = order.getUser();
  if (!manager)
    throw new Error("Null UserEntity (user) in received OrderRouteEntity > OrderEntity");

  if (!emptyLegOrderRouteEntity)
    throw new Error("Zero EmptyLegOrderRouteEntity (emptyLegOrderRoutesList) in received OrderRouteEntity");

  const createdAtDate = parseStringAsUTCDate(emptyLegOrderRouteEntity.getCreatedat());
  if (!isValidDate(createdAtDate))
    throw new Error(`Invalid createdAt in received OrderRouteEntity > EmptyLegOrderRouteEntity, ${emptyLegOrderRouteEntity.getCreatedat()}`);

  return {
    orderRouteId: item.getOrderrouteid(),
    emptyLegOrderRouteId: emptyLegOrderRouteEntity.getEmptylegorderrouteid(),
    orderId: item.getOrderid(),
    airportFrom: mapAirportEntityToIAirport(airportFrom),
    airportTo: mapAirportEntityToIAirport(airportTo),
    departureDate: departureDate,
    pax: item.getPax(),
    planeCategories: planeCategories,
    companionsAllowed: order.getCompanionsallowed(),
    comment: order.getComment(),
    manager: mapUserEntityToIManager(manager),
    company: mapCompanyEntityToICompany(company),
    status: emptyLegOrderRouteEntity.getStatus() as EmptyLegOrderRouteStatusEnum || EmptyLegOrderRouteStatusEnum.ACTIVE,
    createdDate: createdAtDate,
  }
}


export const mapEmptyLegEntityToIEmptyLeg = (item: EmptyLegEntity): IEmptyLeg => {
  const airportFrom = item.getAirportfrom();
  if (!airportFrom)
    throw new Error("Null AirportEntity (airportFrom) in received EmptyLegEntity");

  const airportTo = item.getAirportto();
  if (!airportTo)
    throw new Error("Null AirportEntity (airportTo) in received EmptyLegEntity");

  const currency = item.getCurrencyentity();
  if (!currency)
    throw new Error("Null CurrencyEntity (currencyEntity) in received EmptyLegEntity");

  const availableFromDate = item.getAvailablefromdate();
  if (availableFromDate && !isValidDate(parseStringAsUTCDate(availableFromDate)))
    throw new Error(`Invalid availableFromDate in received EmptyLegEntity, ${item.getAvailablefromdate()}`);

  const availableToDate = item.getAvailabletodate();
  if (availableToDate && !isValidDate(parseStringAsUTCDate(availableToDate)))
    throw new Error(`Invalid availableToDate in received EmptyLegEntity, ${item.getAvailabletodate()}`);

  const departureDate = item.getDeparturetime();
  if (departureDate && !isValidDate(parseStringAsUTCDate(departureDate)))
    throw new Error(`Invalid departureDate in received EmptyLegEntity, ${item.getAvailabletodate()}`);

  const freighterUser = item.getFreighter();
  if (!freighterUser)
    throw new Error("Null UserEntity (freighter) in received EmptyLegEntity")

  const freighterCompany = item.getFlycompany();
  if (!freighterCompany)
    throw new Error("Null CompanyEntity (freighterCompany) in received EmptyLegEntity")

  const plane = item.getPlane();
  if (!plane)
    throw new Error("Null PlaneEntity (plane) in received EmptyLegEntity");

  const dateModified = parseStringAsUTCDate(item.getUpdatedat());
  if (dateModified && !isValidDate(dateModified))
    throw new Error(`Invalid availableFromDate in received OrderRouteEntity > EmptyLegEntity, ${item.getUpdatedat()}`);

  const flyHours = Math.floor(item.getFlyduration() / 60);
  const flyMinutes = item.getFlyduration() % 60

  const arrivalDate = (departureDate && item.getFlyduration())
    ? new Date(parseStringAsUTCDate(departureDate).getTime() + 60000 * item.getFlyduration())
    : undefined;

  return {
    id: item.getEmptylegid(),
    freighterUser: mapUserEntityToIUser(freighterUser),
    freighterCompany: mapCompanyEntityToICompany(freighterCompany),
    plane: mapPlaneEntityToIPlane(plane),
    airportFrom: mapAirportEntityToIAirport(airportFrom),
    airportTo: mapAirportEntityToIAirport(airportTo),
    availableFromDate: availableFromDate ? parseStringAsUTCDate(availableFromDate) : undefined,
    availableToDate: availableToDate ? parseStringAsUTCDate(availableToDate) : undefined,
    departureDate: departureDate ? parseStringAsUTCDate(departureDate) : undefined,
    arrivalDate: arrivalDate,
    emptyLegStatusId: item.getEmptylegstatusid(),
    price: item.getPrice(),
    currency: {
      id: currency.getCurrencyid(),
      name: currency.getName(),
    },
    flyHours: flyHours,
    flyMinutes: flyMinutes,
    flyDistance: item.getFlydistance(),
    comment: item.getComment(),
    dateModified: dateModified,
    isArchived: item.getArchived(),
    orderRoutes: item.getOrderroutesList().map(or => {
      return mapOrderRouteEntityToIEmptyLegOrderRoute(or, item.getEmptylegorderroutesList().find(elOr => elOr.getOrderrouteid() === or.getOrderrouteid()))
    }),
  }
}