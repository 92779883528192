import {BookingApiServiceClient, PlaneApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  BookingApiPlaneCategoryListRequest,
  BookingApiPlaneCategoryListResponse,
  BookingApiPlaneListRequest,
  BookingApiPlaneListResponse,
  BookingApiPlaneRequest,
  BookingApiPlaneResponse,
  BookingApiPlaneShortListResponse,
  BookingApiPlaneTypeListRequest,
  BookingApiPlaneTypeListResponse,
  EmptyRequest
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new PlaneApiServiceClient(getProxyUrl(), null, null);


export class PlaneApi {
  static async getPlaneCategoryList(req: BookingApiPlaneCategoryListRequest): Promise<BookingApiPlaneCategoryListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneCategoryListRequest, BookingApiPlaneCategoryListResponse>(client, client.planeCategoryList, req, resolve, reject)
    })
  }

  static async getPlaneTypeList(req: BookingApiPlaneTypeListRequest): Promise<BookingApiPlaneTypeListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneTypeListRequest, BookingApiPlaneTypeListResponse>(client, client.planeTypeList, req, resolve, reject)
    })
  }

  static async getPlaneList(req: BookingApiPlaneListRequest): Promise<BookingApiPlaneListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneListRequest, BookingApiPlaneListResponse>(client, client.planeList, req, resolve, reject)
    });
  }

  static async getPlaneShortList(req: EmptyRequest): Promise<BookingApiPlaneShortListResponse> {
    return new Promise((resolve, reject) => {
      API.call<EmptyRequest, BookingApiPlaneShortListResponse>(client, client.planeShortList, req, resolve, reject)
    });
  }

  static async planeCreate(req: BookingApiPlaneRequest): Promise<BookingApiPlaneResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneRequest, BookingApiPlaneResponse>(client, client.planeCreate, req, resolve, reject)
    });
  }

  static async planeUpdate(req: BookingApiPlaneRequest): Promise<BookingApiPlaneResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneRequest, BookingApiPlaneResponse>(client, client.planeUpdate, req, resolve, reject)
    });
  }

  static async planeDelete(req: BookingApiPlaneRequest): Promise<BookingApiPlaneResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiPlaneRequest, BookingApiPlaneResponse>(client, client.planeDelete, req, resolve, reject)
    });
  }
}