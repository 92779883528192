import {NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import chatIconBlack from "../../../media/icons/notification_chat_black.png";
import charterIconGreen from "../../../media/icons/notification_charter_green.png";
import charterIconRed from "../../../media/icons/notification_charter_red.png";
import emptyLegIconGreen from "../../../media/icons/notification_empty_leg_green.png";
import emptyLegIconYellow from "../../../media/icons/notification_empty_leg_yellow.png";
import emptyLegIconRed from "../../../media/icons/notification_empty_leg_red.png";
import emptyLegIconBlack from "../../../media/icons/notification_empty_leg_black.png";
import {Logger} from "../../../utils/logger/Logger";

export const getNotificationIconByType = (type: NotificationType): string => {
  switch (type) {
    case NotificationType.TYPE_INCOME_MESSAGE:
      return chatIconBlack;

    case NotificationType.TYPE_EMPTY_LEG_ARCHIVED_EXPIRED:
      return emptyLegIconRed;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED:
      return emptyLegIconGreen;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED:
      return emptyLegIconGreen;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED:
      return emptyLegIconRed;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN:
      return emptyLegIconYellow;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN:
      return emptyLegIconYellow;
    case NotificationType.TYPE_EMPTY_LEG_UPDATED:
      return emptyLegIconBlack;

    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED:
      return charterIconRed;
    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED:
      return charterIconRed;

    case NotificationType.TYPE_ORDER_REQUEST_CREATED:
      return charterIconGreen;
    case NotificationType.TYPE_ORDER_REQUEST_ARCHIVED:
      return charterIconRed;
    case NotificationType.TYPE_ORDER_REQUEST_EXPIRED:
      return charterIconRed;
    case NotificationType.TYPE_ORDER_REQUEST_OFFERED:
      return charterIconGreen;
    default:
      Logger.error("No icon matches with given type of Notification")
      return "";
  }
}