import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  createEvent,
  deleteEvent,
  editEvent,
  getSchedule,
  setCreateEventResult, setDeleteEventResult, setEditEventResult, setScheduleResult
} from "../../reducers/scheduleReducer/scheduleReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  BookingApiGetAirportRequest, BookingApiGetAirportResponse, PlaneShortEntity,
  ScheduleApiScheduleListRequest,
  ScheduleApiScheduleListResponse,
  ScheduleApiScheduleRequest, ScheduleApiScheduleResponse, ScheduleEntity
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {
  ICreateEventPayload, IDeleteEventPayload,
  IEditEventPayload,
  IGetSchedulePayload
} from "../../reducers/scheduleReducer/scheduleReducerTypes";


function* getScheduleSaga({payload}: PayloadAction<IGetSchedulePayload>) {
  const req = new ScheduleApiScheduleListRequest()
    .setDatefrom(getLocalDateAsISOString(payload.dateFrom))
    .setDateto(getLocalDateAsISOString(payload.dateTo))
    .setPlaneidsList(payload.planeIds);

  try {
    const res: ScheduleApiScheduleListResponse = yield call(API.getSchedule, req);

    yield put(setScheduleResult({
      items: res.getSchedulesList(),
      totalCount: res.getSchedulesList().length,
    }))
  } catch {
  }
}


function* createEventSaga({payload}: PayloadAction<ICreateEventPayload>) {
  try {
    const scheduleEntity = new ScheduleEntity()
      .setPlane(new PlaneShortEntity().setPlaneid(payload.planeId))
      .setScheduletype(payload.eventType)
      .setDatefrom(getLocalDateAsISOString(payload.dateFrom))
      .setDateto(getLocalDateAsISOString(payload.dateTo));

    const airportFromResponse: BookingApiGetAirportResponse = yield call(
      API.getAirportById,
      new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
    );

    const airportFrom = airportFromResponse.getAirport();
    if (!airportFrom)
      throw new Error("No airport with given id found");
    scheduleEntity.setAirportfrom(airportFrom);

    if (payload.airportToId) {
      const airportToResponse: BookingApiGetAirportResponse = yield call(
        API.getAirportById,
        new BookingApiGetAirportRequest().setAirportid(payload.airportToId)
      );

      const airportTo = airportToResponse.getAirport();
      if (!airportTo)
        throw new Error("No airport with given id found");

      scheduleEntity.setAirportto(airportTo);
    }

    if (payload.eventName)
      scheduleEntity.setSchedulename(payload.eventName);

    const req = new ScheduleApiScheduleRequest()
      .setSchedule(scheduleEntity);

    const res: ScheduleApiScheduleResponse = yield call(API.createEvent, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setCreateEventResult({
        item: res.getSchedule(),
      }))
    } else {
      yield put(setCreateEventResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }))
    }
  } catch {
  }
}


function* editEventSaga({payload}: PayloadAction<IEditEventPayload>) {
  const scheduleEntity = new ScheduleEntity()
    .setScheduleid(payload.eventId)
    .setScheduletype(payload.eventType)
    .setPlane(new PlaneShortEntity().setPlaneid(payload.planeId))
    .setDatefrom(getLocalDateAsISOString(payload.dateFrom))
    .setDateto(getLocalDateAsISOString(payload.dateTo))

  const airportFromResponse: BookingApiGetAirportResponse = yield call(
    API.getAirportById,
    new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
  );

  const airportFrom = airportFromResponse.getAirport();
  if (!airportFrom)
    throw new Error("No airport with given id found");
  scheduleEntity.setAirportfrom(airportFrom);

  if (payload.airportToId) {
    const airportToResponse: BookingApiGetAirportResponse = yield call(
      API.getAirportById,
      new BookingApiGetAirportRequest().setAirportid(payload.airportToId)
    );

    const airportTo = airportToResponse.getAirport();
    if (!airportTo)
      throw new Error("No airport with given id found");

    scheduleEntity.setAirportto(airportTo);
  }

  if (payload.eventName)
    scheduleEntity.setSchedulename(payload.eventName);

  const req = new ScheduleApiScheduleRequest()
    .setSchedule(scheduleEntity);

  try {
    const res: ScheduleApiScheduleResponse = yield call(API.editEvent, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setEditEventResult({
        item: res.getSchedule(),
      }))
    } else {
      yield put(setEditEventResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }))
    }
  } catch {
  }
}


function* deleteEventSaga({payload}: PayloadAction<IDeleteEventPayload>) {
  const scheduleEntity = new ScheduleEntity()
    .setScheduleid(payload.itemId)

  const req = new ScheduleApiScheduleRequest()
    .setSchedule(scheduleEntity);

  try {
    const res: ScheduleApiScheduleResponse = yield call(API.deleteEvent, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setDeleteEventResult({
        itemId: payload.itemId,
      }))
    } else {
      yield put(setDeleteEventResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }))
    }
  } catch {
  }
}


export function* scheduleSaga() {
  yield all([
    takeLatest(getSchedule.type, getScheduleSaga),
    takeLatest(createEvent.type, createEventSaga),
    takeLatest(editEvent.type, editEventSaga),
    takeLatest(deleteEvent.type, deleteEventSaga),
  ])
}