import styled from "styled-components";

export const FlightDrawerDetailsWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
`;

export const FlightDrawerDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
  align-items: flex-start;
  padding: 15px 20px 0 20px;
`;

export const FlightDrawerDetailsTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

export const FlightDrawerDetailsTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

export const FlightDrawerDetailsUpdatedTime = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  span {
    color: #808080;
  }
`;