import React from 'react';
import {useIsMobile} from "../../../hooks/useWindowSize";
import {HeaderDesktop} from "./HeaderDesktop/HeaderDesktop";
import {HeaderMobile} from "./HeaderMobile/HeaderMobile";

export const Header = () => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile
        ? <HeaderMobile />
        : <HeaderDesktop />}
    </>
  );
};