import styled from "styled-components";
import React, {FC} from "react";
import {Typography} from "antd";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppSelector} from "../../../store/store";

interface IChatStrangerMessage {
  messageText: string;
  sendDate?: Date;
}

const ChatStrangerMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 16px;
  box-sizing: border-box;
`;

const ChatStrangerMessageStyled = styled.div`
  border-radius: 5px;
  padding: 5px 10px;
  background: transparent;
  border: 1px solid #d9d9d9;
  width: fit-content;
  text-align: left;
`;

export const ChatStrangerMessage: FC<IChatStrangerMessage> = ({messageText, sendDate}) => {
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);

  return (
    <ChatStrangerMessageWrapper>
      <ChatStrangerMessageStyled>
        <Typography>{messageText}</Typography>
      </ChatStrangerMessageStyled>
      <div style={{fontSize: 9}}>{sendDate && formatDate(sendDate, offset)}</div>
    </ChatStrangerMessageWrapper>
  );
};