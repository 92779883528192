import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {groupAll} from "../../../roles/groups";
import {defineMessage} from "react-intl";
import {LegalDocumentsPage} from "../../../../../pages/General/LegalDocuments/LegalDocumentsPage";

const LegalDocumentsRouteTitleMessage = defineMessage({
  id: "legalDocumentsRoute.title",
  defaultMessage: "Legal Documents",
})

export const legalDocuments: RouteDescriptor<Route.legalDocuments> = {
  route: Route.legalDocuments,
  render: LegalDocumentsPage,
  title: LegalDocumentsRouteTitleMessage,
  path: '/legalDocuments/:section',
  allowRoles: [
    ...groupAll,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: false,
}