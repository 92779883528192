import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {InitialPage} from "../../../../../utils/constans/pagination/pagination";
import {
  clearFreighterOrderRequests,
  getFreighterOrderRequests
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducer";
import {Loader} from "../../../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../../../components/UI/AntCustom/CustomResult";
import {OrderRequestCard} from "./OrderRequestCard";
import {IOrderRequestFilters} from "../RequestFromAgentsPage";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {useSearchParams} from "react-router-dom";
import isNumeric from "antd/es/_util/isNumeric";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {ReactComponent as CloseIcon} from "../../../../../media/icons/close.svg"
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {OrderTypeEnum} from "../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";

const OrderRequestCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    margin-top: 16px;
  }
`;

interface IOrderRequestsTabPane {
  orderTypes: OrderTypeEnum[];
  filters: IOrderRequestFilters;
  isArchival?: boolean;
}

export const OrderRequestsTabPane: FC<IOrderRequestsTabPane> = ({orderTypes, filters, isArchival = false}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderRequestIdFromParams = isNumeric(searchParams.get('orderRequestId')) ? Number(searchParams.get('orderRequestId')) : undefined;
  const {
    items,
    totalCount,
    isLoading,
  } = useAppSelector(state => state.freighterRequests.itemsObject)
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);

  useEffect(() => {
    dispatch(getFreighterOrderRequests({
      page: currentPage,
      isArchival: isArchival,
      orderTypes: orderTypes,
      filters: filters,
      orderRequestIds: orderRequestIdFromParams ? [orderRequestIdFromParams] : undefined
    }));

    return () => {
      dispatch(clearFreighterOrderRequests());
    }
  }, [isArchival, orderTypes, filters, orderRequestIdFromParams])

  const loadMore = () => {
    dispatch(getFreighterOrderRequests({
      page: currentPage + 1,
      isArchival: isArchival,
      orderTypes: orderTypes,
      filters: filters
    }));

    setCurrentPage(prev => prev + 1);
  }

  const removeIdFromSearchParams = () => {
    if (searchParams.has('orderRequestId')) {
      searchParams.delete('orderRequestId')
      setSearchParams(searchParams);
    }
  }

  return (
    <>
      {orderRequestIdFromParams && <CustomButton icon={<CloseIcon />} onClick={removeIdFromSearchParams}>{intl.formatMessage(CommonFormMessages.showAll)}</CustomButton>}

      {items.length === 0
        ? (isLoading ? <Loader/> : <CustomResult title={intl.formatMessage(CommonFormMessages.noData)}/>)
        : <OrderRequestCardsWrapper>{items.map((or) => {
          return <OrderRequestCard key={or.id} orderRequest={or}/>
        })}</OrderRequestCardsWrapper>
      }

      {items.length > 0 && totalCount > items.length &&
        <CustomButton
          loading={isLoading}
          type={'primary'}
          onClick={loadMore}
        >
          {intl.formatMessage(CommonFormMessages.showMore)}
        </CustomButton>}
    </>
  );
};
