import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {browserHistory} from "../../../index";
import {Role} from "../../../config/acl/roles/Role";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../config/acl/routes/Route";
import {
  IAuthCheckConfirmationCode,
  IAuthForgotPasswordPayload,
  IAuthForgotPasswordValidPayload,
  IAuthInitialState,
  IAuthLoginPayload,
  IAuthRegisterPayload,
  IAuthSetIsAuthenticatedPayload,
  IAuthSetIsConfirmationCodeValidPayload,
  IAuthSetLoginResultPayload,
  IAuthSetNewPasswordPayload,
  IAuthSetNewPasswordResultPayload,
  IAuthSetRegisterResultPayload
} from "./authReducerTypes";


const authInitialState: IAuthInitialState = {
  isAuthenticated: false,
  isAuthChecked: false,
  role: Role.unknown,

  isRegisterLoading: false,
  isRegisterSuccess: false,
  registerErrorMessage: '',

  isLoginLoading: false,
  isLoginSuccess: false,
  loginErrorMessage: '',

  isConfirmationCodeValid: false,
  isConfirmationCodeLoading: true,

  isSetNewPasswordLoading: false,
  isSetNewPasswordSuccess: false,
  setNewPasswordErrorMessage: '',

  isForgotPasswordLoading: false,
  isForgotPasswordSuccess: false,
  forgotPasswordErrorMessage: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setIsAuthenticated(state, {payload}: PayloadAction<IAuthSetIsAuthenticatedPayload>) {
      state.isAuthenticated = payload.isAuthenticated;

      if (payload.accessToken && payload.refreshToken) {
        state.role = payload.role;
        localStorage.setItem('accessToken', payload.accessToken);
        localStorage.setItem('refreshToken', payload.refreshToken);
      } else {
        state.role = Role.guest;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }

      state.isAuthChecked = true;
    },

    login(state, {payload}: PayloadAction<IAuthLoginPayload>) {
      state.isLoginLoading = true;
    },
    clearLogin(state) {
      state.loginErrorMessage = '';
      state.isLoginLoading = false;
      state.isLoginSuccess = false;
    },
    setLoginResult(state, {payload}: PayloadAction<IAuthSetLoginResultPayload>) {
      state.isLoginLoading = false;
      state.isAuthenticated = payload.isAuthenticated;
      state.role = payload.role;

      state.isLoginSuccess = payload.isLoginSuccess;
      state.loginErrorMessage = payload.loginErrorMessage;

      if (payload.accessToken && payload.refreshToken) {
        localStorage.setItem('accessToken', payload.accessToken);
        localStorage.setItem('refreshToken', payload.refreshToken);
      }
    },

    logout(state) {
      state.isAuthenticated = false;
      state.role = Role.guest;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      browserHistory.push(pathFromRoute(Route.login));
    },

    register(state, {payload}: PayloadAction<IAuthRegisterPayload>) {
      state.isRegisterLoading = true;
    },
    clearRegister(state) {
      state.registerErrorMessage = '';
      state.isRegisterLoading = false;
      state.isRegisterSuccess = false;
    },
    setRegisterResult(state, {payload}: PayloadAction<IAuthSetRegisterResultPayload>) {
      state.isRegisterLoading = false;
      state.isRegisterSuccess = payload.isRegisterSuccess;
      state.registerErrorMessage = payload.registerErrorMessage;
    },


    forgotPassword(state, {payload}: PayloadAction<IAuthForgotPasswordPayload>) {
      state.isForgotPasswordLoading = true;
    },
    clearForgotPassword(state) {
      state.forgotPasswordErrorMessage = '';
      state.isForgotPasswordLoading = false;
      state.isForgotPasswordSuccess = false;
    },
    setIsForgotPasswordResult(state, {payload}: PayloadAction<IAuthForgotPasswordValidPayload>) {
      state.isForgotPasswordLoading = false;
      state.isForgotPasswordSuccess = payload.isForgotPasswordSuccess;
      state.forgotPasswordErrorMessage = payload.forgotPasswordErrorMessage;
    },

    checkConfirmationCode(state, {payload}: PayloadAction<IAuthCheckConfirmationCode>) {
    },
    setIsConfirmationCodeValid(state, {payload}: PayloadAction<IAuthSetIsConfirmationCodeValidPayload>) {
      state.isConfirmationCodeValid = payload.isValid;
      state.isConfirmationCodeLoading = false;
    },

    setNewPassword(state, {payload}: PayloadAction<IAuthSetNewPasswordPayload>) {
      state.isSetNewPasswordLoading = true;
    },
    clearSetNewPassword(state) {
      state.setNewPasswordErrorMessage = '';
      state.isSetNewPasswordLoading = false;
      state.isSetNewPasswordSuccess = false;
    },
    setNewPasswordResult(state, {payload}: PayloadAction<IAuthSetNewPasswordResultPayload>) {
      state.isSetNewPasswordSuccess = payload.isSetNewPasswordSuccess;
      state.setNewPasswordErrorMessage = payload.setNewPasswordErrorMessage;
      state.isSetNewPasswordLoading = false;
    },
  },
});

export const {
  setIsAuthenticated,
  login,
  logout,
  register,
  forgotPassword,
  checkConfirmationCode,
  setNewPassword,
  setIsForgotPasswordResult,

  setRegisterResult,
  setLoginResult,
  setIsConfirmationCodeValid,
  setNewPasswordResult,

  clearRegister,
  clearLogin,
  clearForgotPassword,
  clearSetNewPassword,
} =
  authSlice.actions;
export default authSlice.reducer;
