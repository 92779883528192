import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Col, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {clearDeleteEvent, deleteEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducer";
import {DeleteOutlined} from "@ant-design/icons";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {IPlaneEvent} from "../../../../../store/reducers/scheduleReducer/scheduleReducerTypes";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {deleteManager} from "../../../../../store/reducers/personnelManagementReducer/personnelManagementReducer";


const ScheduleDeleteModalMessages = defineMessages({
  title: {
    id: "scheduleDeleteModal.title",
    defaultMessage: "Delete event from schedule",
  },
  success: {
    id: "scheduleDeleteModal.success",
    defaultMessage: "Event has been successfully deleted",
  },
  warn: {
    id: "scheduleDeleteModal.warn",
    defaultMessage: "Are you sure you want to delete the event?",
  },
})

interface IScheduleDeleteModal {
  planeEvent: IPlaneEvent;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  closeOuterEditModal: () => void;
}

export const ScheduleDeleteModal: FC<IScheduleDeleteModal> = ({planeEvent, isModalVisible, setIsModalVisible, closeOuterEditModal}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.schedule.delete);

  useEffect(() => {
    return () => {
      dispatch(clearDeleteEvent());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ScheduleDeleteModalMessages.success), () => {
          setIsModalVisible(false);
          closeOuterEditModal();
        });
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  return (
    <CustomModal
      title={intl.formatMessage(ScheduleDeleteModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <div>{intl.formatMessage(ScheduleDeleteModalMessages.warn)}</div>
      <Row gutter={8} style={{marginTop: 24}}>
        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            onClick={() => setIsModalVisible(false)}
          >
            {intl.formatMessage(CommonButtonMessages.cancel)}
          </CustomButton>
        </Col>
        <Col span={12}>
          <CustomButton
            style={{width: "100%"}}
            heightSize={'m'}
            type={'primary'}
            icon={<DeleteOutlined />}
            danger
            loading={isLoading}
            onClick={() => dispatch(deleteEvent({itemId: planeEvent.id}))}
          >
            {intl.formatMessage(CommonButtonMessages.delete)}
          </CustomButton>
        </Col>
      </Row>
    </CustomModal>
  );
};
