import React from 'react';
import styled from "styled-components";
import PlaneCloud from "../../../../media/icons/planeCloudHomeSecondSection.png";
import {FONTS} from "../../../../utils/constans/styles/fonts";
import {mediaScreenSizes, screenSizes} from "../../../../utils/constans/styles/screenSizes";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {PROJECT_NAME} from "../../../../utils/constans/common/projectName";
import {defineMessages, useIntl} from "react-intl";

const HomeSecondSectionStyled = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: #00529D;
  margin-bottom: 200px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    margin-bottom: 74px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  padding: 97px 90px 186px 90px;
  max-width: 1260px;
  box-sizing: content-box;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    row-gap: 59px;
    padding: 77px 27px 128px 27px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 40px;
    padding: 273px 23px 56px 23px;
  }
`;


const SecondSectionTitle = styled.div`
  display: flex;
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 60px;
  width: 100%;
  line-height: 60px;
  color: #F7F3F0;
  box-sizing: border-box;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    line-height: 47px;
    font-size: 47px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    line-height: 33px;
    font-size: 36px;
  }
`;

const DescriptionAndImage = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    column-gap: 72px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 36px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
  }
`;

const DescriptionImage = styled.img`
  position: absolute;
  width: 412px;
  height: 490px;
  box-shadow: 0 12px 10px -5px rgba(0, 39, 76, 0.19);

  @media (max-width: ${mediaScreenSizes.tablet}) {
    position: relative;
    max-width: 328px;
    max-height: 352px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -87px;
    width: 328px;
    height: 336px;
  }
`;

const Hr = styled.hr`
  border: none;
  background: #F7F3F0;
  height: 1px;
  margin: 0 0 16px 0;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 18px;
  line-height: 27px;
  color: #F7F3F0;
  max-width: 620px;
  margin-left: 480px;
  letter-spacing: 0.02rem;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    margin-left: 0;
    max-width: 380px;
    line-height: 27px;
    font-size: 18px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    margin-left: 0;
    line-height: 21px;
    font-size: 14px;
  }
`;


const HomeSecondSectionMessages = defineMessages({
  title: {
    id: "homeSecondSection.title",
    defaultMessage: "Jetsnode is a platform that helps air companies and passengers find each other",
  },
  description1: {
    id: "homeSecondSection.description1",
    defaultMessage: "Jetsnode is an online platform for buying and selling business flights. Web apps and APIs register approximately 2 million search requests for business aviation services annually, and the number of such requests increases constantly.",
  },
  description2: {
    id: "homeSecondSection.description2",
    defaultMessage: "Jetsnode is your key solution to address your brokers' requests quickly and qualitatively.",
  },
})

export const HomeSecondSection = () => {
  const {width} = useWindowSize();
  const intl = useIntl();

  return (
    <HomeSecondSectionStyled>
      <SectionContainer>
        {width <= screenSizes.mobile && <DescriptionImage src={PlaneCloud} alt="PlaneCloud"/>}

        <SecondSectionTitle>
          {intl.formatMessage(HomeSecondSectionMessages.title)}
        </SecondSectionTitle>

        <DescriptionAndImage>
          {width > screenSizes.mobile && <DescriptionImage src={PlaneCloud} alt="PlaneCloud"/>}
          <Description>
            <div>
              <Hr />
              {intl.formatMessage(HomeSecondSectionMessages.description1)}
            </div>
            <div>
              {intl.formatMessage(HomeSecondSectionMessages.description2)}
            </div>
          </Description>
        </DescriptionAndImage>
      </SectionContainer>

    </HomeSecondSectionStyled>
  );
};

