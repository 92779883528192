import React, {FC} from "react";
import {
  FlightDrawerDetailsHeader,
  FlightDrawerDetailsTitle,
  FlightDrawerDetailsTitleWrapper,
  FlightDrawerDetailsWrapper,
} from "./FlightDrawerDetailsStyledComponents";
import {FlyRoute} from "../../flyRoute/FlyRoute";
import {defineMessages, useIntl} from "react-intl";
import {
  IFreighterOrderRequest
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {OrderTypeShield} from "../../orderType/OrderTypeShield";
import {Logger} from "../../../../utils/logger/Logger";


const FlightDrawerOrderRequestOrderDetailsMessages = defineMessages({
  title: {
    id: "flightDrawerOrderRequestOrderDetails.title",
    defaultMessage: "Route details",
  },
})

interface IFlightDrawerOrderRequestOrderDetails {
  orderRequest: IFreighterOrderRequest;
}

export const FlightDrawerOrderRequestOrderDetails: FC<IFlightDrawerOrderRequestOrderDetails> = ({
                                                                                                  orderRequest,
                                                                                                }) => {
  const intl = useIntl();
  const {orderRouteOrderRequests} = orderRequest;

  return (
    <FlightDrawerDetailsWrapper>
      <FlightDrawerDetailsHeader>
        <FlightDrawerDetailsTitleWrapper>
          <FlightDrawerDetailsTitle>{intl.formatMessage(FlightDrawerOrderRequestOrderDetailsMessages.title)}</FlightDrawerDetailsTitle>
        </FlightDrawerDetailsTitleWrapper>
        <OrderTypeShield type={orderRequest.order.type} styleType={'fill'}/>
      </FlightDrawerDetailsHeader>

      {orderRouteOrderRequests.map((orderRouteOrderRequest, index) => {
        const {arrivalDate, flyHours, flyMinutes, flyDistance, technicalStopDuration = 0} = orderRouteOrderRequest;
        if ([arrivalDate, flyHours, flyMinutes, flyDistance].some(p => p === undefined)) {
          Logger.error("No arrivalDate || flyHours || flyMinutes || flyDistance for orderRouteOrderRequest")
          return null;
        }

        return <FlyRoute key={orderRouteOrderRequest.orderRouteOrderRequestId}
                         index={index}
                         airportFrom={orderRouteOrderRequest.orderRoute.airportFrom}
                         airportTo={orderRouteOrderRequest.orderRoute.airportTo}
                         dateFrom={orderRouteOrderRequest.orderRoute.departureDate}
                         dateTo={arrivalDate!}
                         flyHoursDuration={flyHours!}
                         flyMinutesDuration={flyMinutes!}
                         distance={flyDistance!}
                         technicalStopHoursDuration={Math.floor(technicalStopDuration / 60)}
                         technicalStopMinutesDuration={technicalStopDuration % 60}
                         pax={orderRouteOrderRequest.orderRoute.pax}/>
      })}
    </FlightDrawerDetailsWrapper>
  );
};