import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Row, Segmented, Typography} from "antd";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {clearRegister, register} from "../../../store/reducers/authReducer/authReducer";
import {useNavigate} from "react-router";
import {CustomCard} from "../../../components/UI/AntCustom/CustomCard";
import {ReactComponent as Logo} from "../../../media/icons/logo.svg";
import {PageWrapper} from "../../../components/UI/PageWrapper/PageWrapper";
import {PrivacyPolicyModal} from "../../../components/businessCommon/privacyPolicy/PrivacyPolicyModal";
import {CustomLink} from "../../../components/UI/CustomLink/CustomLink";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../config/acl/routes/Route";
import {roles} from "../../../config/acl/roles";
import {Role} from "../../../config/acl/roles/Role";
import {EmailRegex} from "../../../utils/helpers/regexes/emailRegex";
import {PasswordRegex} from "../../../utils/helpers/regexes/passwordRegex";
import {renderSuccessModal} from "../../../components/UI/StatusModals/renderSuccessModal";
import {PhoneRegex} from "../../../utils/helpers/regexes/phoneRegex";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../components/UI/AntCustom/CustomButton";
import {CustomInputNumber} from "../../../components/UI/AntCustom/CustomInputNumber";
import {metricsGoals} from "../../../utils/constans/metrics/goals";


const RegisterPageMessages = defineMessages({
  success: {
    id: "registerPage.success",
    defaultMessage: "Registration request has been sent for moderation. Please, log into your account to upload the necessary licenses, and then wait until your account is approved by the service administrator.",
  },
  title: {
    id: "registerPage.title",
    defaultMessage: "Create an account to start using Jetsnode.",
  },
  policyTitle: {
    id: "registerPage.policyTitle",
    defaultMessage: "By registering I accept the {link}",
  },
  policyTitleLink: {
    id: "registerPage.policyTitleLink",
    defaultMessage: "Privacy policy",
  },
  register: {
    id: "registerPage.register",
    defaultMessage: "Sign up",
  },
  toLogin: {
    id: "registerPage.toLogin",
    defaultMessage: "Already have an account?",
  },
  roleOperator: {
    id: "registerPage.roleOperator",
    defaultMessage: "Operator",
  },
  roleBroker: {
    id: "registerPage.roleBroker",
    defaultMessage: "Broker",
  }
})

interface IRegisterFormData {
  firstName: string,
  lastName: string,
  middleName: string,
  phone?: number,
  companyName: string,
  companyRole: Role,
  email: string,
  password: string,
  confirmPassword: string,
}

export const RegisterPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isRegisterLoading,
    isRegisterSuccess,
    registerErrorMessage,
    role,
  } = useAppSelector(state => state.auth);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const [isFormTouchedOnce, setIsFormTouchedOnce] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearRegister());
    }
  }, [])

  useEffect(() => {
    if (!isRegisterLoading) {
      if (isRegisterSuccess) {
        metricsGoals.registrationSuccess();
        renderSuccessModal(intl.formatMessage(RegisterPageMessages.success),
          () => navigate(pathFromRoute(Route.profile), {replace: true})
        );
      }
      if (registerErrorMessage)
        message.error(registerErrorMessage);
    }
  }, [isRegisterSuccess, registerErrorMessage, isRegisterLoading])


  const onFinish = (values: IRegisterFormData) => {
    values.companyRole === Role.freighterAdmin
      ? metricsGoals.operatorRegistered()
      : metricsGoals.brokerRegistered();

    dispatch(register({
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      phone: values.phone,
      companyName: values.companyName,
      companyRole: values.companyRole,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const onValuesChange = () => {
    if (!isFormTouchedOnce) {
      setIsFormTouchedOnce(true);
      metricsGoals.registerFormTouched();
    }
  }

  const initialValues: IRegisterFormData = {
    firstName: '',
    lastName: '',
    middleName: '',
    phone: undefined,
    companyName: '',
    companyRole: Role.chartererAdmin,
    email: '',
    password: '',
    confirmPassword: '',
  }

  if (isAuthenticated) {
    navigate(pathFromRoute(roles[role].homeRoute));
    return null;
  }

  return (
    <PageWrapper justifyContent={'center'}>
      <CustomCard title={<Logo/>} titlePosition={'center'} width={'max'} isContainerCard={true} maxWidth={600}>
        <Typography>{intl.formatMessage(RegisterPageMessages.title)}</Typography>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout={'vertical'}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <Row gutter={8}>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.lastNameLabel)}
                name={'lastName'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <Input placeholder={intl.formatMessage(CommonFormMessages.lastNamePlaceholder)}/>
              </CustomFieldInput>
            </Col>

            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.nameLabel)}
                name={'firstName'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <Input placeholder={intl.formatMessage(CommonFormMessages.namePlaceholder)}/>
              </CustomFieldInput>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.middleNameLabel)}
                name={'middleName'}
              >
                <Input placeholder={intl.formatMessage(CommonFormMessages.middleNamePlaceholder)}/>
              </CustomFieldInput>
            </Col>

            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.phoneLabel)}
                name={'phone'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                  pattern: PhoneRegex.pattern,
                  message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
                }]}>
                <CustomInputNumber addonBefore={"+"}
                                   placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)}
                                   controls={false} style={{display: "flex", flexGrow: 1}}/>
              </CustomFieldInput>
            </Col>
          </Row>


          <Row gutter={8}>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.companyLabel)}
                name={'companyName'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <Input placeholder={intl.formatMessage(CommonFormMessages.companyPlaceholder)}/>
              </CustomFieldInput>
            </Col>

            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.roleLabel)}
                name={'companyRole'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <Segmented block
                           options={[
                             {label: intl.formatMessage(RegisterPageMessages.roleBroker), value: Role.chartererAdmin},
                             {label: intl.formatMessage(RegisterPageMessages.roleOperator), value: Role.freighterAdmin},
                           ]}
                />
              </CustomFieldInput>
            </Col>
          </Row>


          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.emailLabel)}
            name={'email'}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {
                pattern: EmailRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectEmail),
              }
            ]}
          >
            <Input placeholder={intl.formatMessage(CommonFormMessages.emailPlaceholder)}/>
          </CustomFieldInput>

          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.passwordLabel)}
              name={'password'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {
                  pattern: PasswordRegex.pattern,
                  message: PasswordRegex.error(
                    intl.formatMessage(CommonFormMessages.rulePasswordValidationTitle),
                    [
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule1),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule2),
                      intl.formatMessage(CommonFormMessages.rulePasswordValidationRule3),
                    ]
                  ),
                }
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.passwordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.repeatPasswordLabel)}
              name={'confirmPassword'}
              dependencies={['password']}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(intl.formatMessage(CommonFormMessages.passwordsMismatch)));
                  },
                })
              ]}
            >
              <Input.Password placeholder={intl.formatMessage(CommonFormMessages.repeatPasswordPlaceholder)}/>
            </CustomFieldInput>
          </Col>

          <Col>
            {intl.formatMessage(RegisterPageMessages.policyTitle, {
              link: <CustomLink
                onClick={() => setIsPolicyModalOpen(true)}>{intl.formatMessage(RegisterPageMessages.policyTitleLink)}</CustomLink>
            })}
          </Col>

          <CustomFieldInput style={{marginTop: 24, marginBottom: 12}}>
            <CustomButton
              block
              heightSize={'m'}
              type={'primary'}
              htmlType={'submit'}
              loading={isRegisterLoading}
            >
              {intl.formatMessage(RegisterPageMessages.register)}
            </CustomButton>
          </CustomFieldInput>

          <Row justify={"center"}>
            <Col>
              <Button type={'link'}
                      onClick={() => navigate(pathFromRoute(Route.login))}>{intl.formatMessage(RegisterPageMessages.toLogin)}</Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>

      <PrivacyPolicyModal isModalVisible={isPolicyModalOpen} setIsModalVisible={setIsPolicyModalOpen}/>
    </PageWrapper>
  );
};

