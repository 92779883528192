import {useEffect, useState} from 'react';
import {screenSizes} from "../utils/constans/styles/screenSizes";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = (): boolean => {
  const {width} = useWindowSize();
  return width <= screenSizes.mobile;
}

export const useIsTablet = (): boolean => {
  const {width} = useWindowSize();
  return width > screenSizes.mobile && width <= screenSizes.tablet;
}
