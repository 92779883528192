import {
  CommunicationApiChatListRequest,
  CommunicationApiChatListResponse,
  CommunicationApiChatMessageListRequest,
  CommunicationApiChatMessageListResponse, CommunicationApiChatMessageSendRequest,
  CommunicationApiChatMessageSendResponse,
  CommunicationApiRequestCallbackListRequest,
  CommunicationApiRequestCallbackListResponse,
  CommunicationApiRequestCallbackRequest,
  CommunicationApiRequestCallbackResponse
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";
import {
  CommunicationApiServiceClient,
} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";

const client = new CommunicationApiServiceClient(getProxyUrl(), null, null);

export class CommunicationApi {
  static async requestCallback(req: CommunicationApiRequestCallbackRequest): Promise<CommunicationApiRequestCallbackResponse> {
    return new Promise((resolve, reject) => {
      API.call<CommunicationApiRequestCallbackRequest, CommunicationApiRequestCallbackResponse>(client, client.requestCallback, req, resolve, reject);
    });
  }

  static async chatList(req: CommunicationApiChatListRequest): Promise<CommunicationApiChatListResponse> {
    return new Promise((resolve, reject) => {
      API.call<CommunicationApiChatListRequest, CommunicationApiChatListResponse>(client, client.chatList, req, resolve, reject);
    });
  }

  static async chatMessageList(req: CommunicationApiChatMessageListRequest): Promise<CommunicationApiChatMessageListResponse> {
    return new Promise((resolve, reject) => {
      API.call<CommunicationApiChatMessageListRequest, CommunicationApiChatMessageListResponse>(client, client.chatMessageList, req, resolve, reject);
    });
  }

  static async chatMessageSend(req: CommunicationApiChatMessageSendRequest): Promise<CommunicationApiChatMessageSendResponse> {
    return new Promise((resolve, reject) => {
      API.call<CommunicationApiChatMessageSendRequest, CommunicationApiChatMessageSendResponse>(client, client.chatMessageSend, req, resolve, reject);
    });
  }

  static async requestCallbackList(req: CommunicationApiRequestCallbackListRequest): Promise<CommunicationApiRequestCallbackListResponse> {
    return new Promise((resolve, reject) => {
      API.call<CommunicationApiRequestCallbackListRequest, CommunicationApiRequestCallbackListResponse>(client, client.requestCallbackList, req, resolve, reject);
    });
  }
}
