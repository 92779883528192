import {defineMessages} from "react-intl";

export const CommonDetailsMessages = defineMessages({
  duration: {
    id: "app.details.duration",
    defaultMessage: "Duration",
  },
  distance: {
    id: "app.details.distance",
    defaultMessage: "Range",
  },
  price: {
    id: "app.details.price",
    defaultMessage: "Price",
  },
  availableRange: {
    id: "app.details.availableRange",
    defaultMessage: "Available",
  },
  route: {
    id: "app.details.route",
    defaultMessage: "Route",
  },
  date: {
    id: "app.details.date",
    defaultMessage: "Date",
  },
  departureDate: {
    id: "app.details.departureDate",
    defaultMessage: "Departure date",
  },
  departureTime: {
    id: "app.details.departureTime",
    defaultMessage: "Departure time",
  },
  pax: {
    id: "app.details.pax",
    defaultMessage: "PAX",
  },
  isEmptyLegUsable: {
    id: "app.details.isEmptyLegUsable",
    defaultMessage: "Allow Empty Legs",
  },
  companionsAllowed: {
    id: "app.details.companionsAllowed",
    defaultMessage: "Allow flight-sharing",
  },
  planeCategories: {
    id: "app.details.planeCategories",
    defaultMessage: "Aircraft categories",
  },
  comment: {
    id: "app.details.comment",
    defaultMessage: "Comment",
  },
  operator: {
    id: "app.details.operator",
    defaultMessage: "Operator",
  },
  airport: {
    id: "app.details.airport",
    defaultMessage: "Airport",
  },
  capacity: {
    id: "app.details.capacity",
    defaultMessage: "Seat capacity",
  },
  registrationNumber: {
    id: "app.details.registrationNumber",
    defaultMessage: "Registration No.",
  },
  request: {
    id: "app.details.request",
    defaultMessage: "Request details",
  },
  respond: {
    id: "app.details.respond",
    defaultMessage: "Respond",
  },
  period: {
    id: "app.details.period",
    defaultMessage: "Period",
  },
  event: {
    id: "app.details.event",
    defaultMessage: "Event",
  },
  week: {
    id: "app.details.week",
    defaultMessage: "Week",
  },
  day: {
    id: "app.details.day",
    defaultMessage: "Day",
  },
  employee: {
    id: "app.details.employee",
    defaultMessage: "User",
  },
  role: {
    id: "app.details.role",
    defaultMessage: "Role",
  },
  contacts: {
    id: "app.details.contacts",
    defaultMessage: "Contact information",
  },
  subscription: {
    id: "app.details.subscription",
    defaultMessage: "Subscription",
  },
  subscriptionActive: {
    id: "app.details.subscriptionActive",
    defaultMessage: "Active",
  },
  manufactureYear: {
    id: "app.details.manufactureYear",
    defaultMessage: "Production year",
  },
  from: {
    id: "app.details.from",
    defaultMessage: "From",
  },
  to: {
    id: "app.details.to",
    defaultMessage: "To",
  },
  technicalStop: {
    id: "app.details.technicalStop",
    defaultMessage: "Tech. stop",
  }
})