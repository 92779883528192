import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {
  IChangeRegistrationStatusPayload,
  IGetRegistrationsListPayload,
  IRegistrationsInitialState, ISetChangeRegistrationStatusResultPayload,
  ISetRegistrationsListResultPayload
} from "./registrationsReducerTypes";
import {mapUserEntityToIRegistration} from "./registrationsMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";

const registrationsInitialState: IRegistrationsInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  changeRegistrationStatus: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
}

const registrationsSlice = createSlice({
  name: 'registrations',
  initialState: registrationsInitialState,
  reducers: {
    getRegistrationsList(state, {payload}: PayloadAction<IGetRegistrationsListPayload>) {
      initCommonGetList(state, payload)
    },
    clearRegistrationsList(state) {
      initCommonClearList(state)
    },
    setRegistrationsListResult(state, {payload}: PayloadAction<ISetRegistrationsListResultPayload>) {
      initCommonSetListResult(state, payload, mapUserEntityToIRegistration);
    },

    changeRegistrationStatus(state, {payload}: PayloadAction<IChangeRegistrationStatusPayload>) {
      initCommonAction(state, nameof<IRegistrationsInitialState>("changeRegistrationStatus"))
    },
    clearChangeRegistrationStatus(state) {
      initCommonClearAction(state, nameof<IRegistrationsInitialState>("changeRegistrationStatus"))
    },
    setChangeRegistrationStatusResult(state, {payload}: PayloadAction<ISetChangeRegistrationStatusResultPayload>) {
      if (!payload.errorMessage) {
        state.itemsObject.items = state.itemsObject.items.filter((r) => r.id !== payload.item?.getUserid());
        state.itemsObject.totalCount--;
      }

      initCommonActionResult(state, payload, nameof<IRegistrationsInitialState>("changeRegistrationStatus"));
    }
  },
});

export const {
  getRegistrationsList,
  clearRegistrationsList,
  setRegistrationsListResult,

  changeRegistrationStatus,
  clearChangeRegistrationStatus,
  setChangeRegistrationStatusResult,
} = registrationsSlice.actions;
export default registrationsSlice.reducer;