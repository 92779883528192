import React, {Dispatch, FC, SetStateAction} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {Col, Row} from "antd";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {
  archiveOrder,
} from "../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";


const ArchiveOrderModalMessages = defineMessages({
  title: {
    id: "archiveOrderModal.title",
    defaultMessage: "Request archiving",
  },
  description: {
    id: "archiveOrderModal.description",
    defaultMessage: "Are you sure you want to archive the request? You won't be able to revert this action.",
  },
})

interface IArchiveOrderRouteModal {
  orderId: number;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ArchiveOrderModal: FC<IArchiveOrderRouteModal> = ({orderId, isModalVisible, setIsModalVisible}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading: isArchiveLoading,
  } = useAppSelector(state => state.chartererRequests.delete);

  return <CustomModal
    title={intl.formatMessage(ArchiveOrderModalMessages.title)}
    visible={isModalVisible}
    onCancel={() => setIsModalVisible(false)}
    centered
    footer={null}
  >
    <Row gutter={8}>
      <Col span={24}>
        <div>{intl.formatMessage(ArchiveOrderModalMessages.description)}</div>
      </Col>
    </Row>

    <Row gutter={8} style={{marginTop: 24}}>
      <Col span={12}>
        <CustomButton
          style={{width: "100%"}}
          heightSize={'m'}
          onClick={() => setIsModalVisible(false)}
        >
          {intl.formatMessage(CommonButtonMessages.cancel)}
        </CustomButton>
      </Col>

      <Col span={12}>
        <CustomButton
          style={{width: "100%"}}
          heightSize={'m'}
          type={'primary'}
          icon={<DeleteOutlined />}
          danger
          loading={isArchiveLoading}
          onClick={() => dispatch(archiveOrder({itemId: orderId}))}
        >
          {intl.formatMessage(CommonButtonMessages.archive)}
        </CustomButton>
      </Col>
    </Row>
  </CustomModal>
}