export const websocketUrl = (() => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_WEBSOCKET_API_URL) {
      // eslint-disable-next-line no-restricted-globals
      const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
      const path = process.env.REACT_APP_WEBSOCKET_API_URL;
      return `${protocol}//${window.location.hostname}/${path}`;
    } else {
      throw Error('env REACT_APP_WEBSOCKET_API_URL not presents');
    }
  } else {
    // eslint-disable-next-line no-restricted-globals
    const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
    const path = '/broadcast';
    // eslint-disable-next-line no-restricted-globals
    return `${protocol}//websocket.jetsnode:1337${path}`;
  }
})();
