import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {NewTripRequestPage} from "../../../../../pages/Lk/BrokersOnly/NewTripRequest/NewTripRequestPage";
import {groupCharterer} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const NewTripRequestRouteTitleMessage = defineMessage({
  id: "newTripRequestRoute.title",
  defaultMessage: "Create request",
})

export const newTripRequest: RouteDescriptor<Route.newTripRequest> = {
  route: Route.newTripRequest,
  render: NewTripRequestPage,
  title: NewTripRequestRouteTitleMessage,
  path: '/lk/new-trip-request',
  allowRoles: [
    ...groupCharterer,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}