import React, {FC} from 'react';
import {IAirport} from "../../../store/reducers/fleetReducer/fleetReducerTypes";
import styled from "styled-components";
import {ReactComponent as RouteArrowMiniIcon} from "../.././../media/icons/route_mini_arrow.svg";
import {DisplayAirport} from "../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {useIsMobile} from "../../../hooks/useWindowSize";

const FlyRouteMiniStyled = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const FlyRouteMiniMore = styled.div`
  cursor: pointer;
  color: #1890FF;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

interface IFlyRouteMini {
  airports: IAirport[];
  onMoreClick: () => void;
}

export const FlyRouteMini: FC<IFlyRouteMini> = ({airports, onMoreClick}) => {
  const isMobile = useIsMobile();
  const firstSliceCount = isMobile ? 2 : 3;

  const airportsToDisplay = airports.slice(0, firstSliceCount).map(a => DisplayAirport(a));
  const moreAirports = airports.slice(firstSliceCount);

  return (
    <FlyRouteMiniStyled>
      {airportsToDisplay.map((airport, index) => {
        if (index === 0) return <div key={index}>{airport}</div>
        return <React.Fragment key={index}><RouteArrowMiniIcon /><div>{airport}</div></React.Fragment>
      })}
      {moreAirports.length > 0 && <FlyRouteMiniMore onClick={onMoreClick}>(+{moreAirports.length})</FlyRouteMiniMore>}
    </FlyRouteMiniStyled>
  );
};
