import {all, call, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  createOrder,
  getFreighterEmptyLegs, setCreateOrderResult, setFreighterEmptyLegsResult
} from "../../reducers/searchReducer/searchReducer";
import {
  AirportEntity,
  BookingApiEmptyLegListRequest,
  BookingApiEmptyLegListResponse, BookingApiGetAirportRequest, BookingApiGetAirportResponse,
  BookingApiOrderRequest, BookingApiOrderResponse,
  EmptyLegEntity,
  OrderEntity,
  OrderRouteEntity,
  PageModel,
  PlaneCategoryEntity
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {
  ICreateOrderPayload,
  IGetFreighterEmptyLegsPayload,
  IOrderCreateOrderRoute
} from "../../reducers/searchReducer/searchReducerTypes";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {OrderTypeEnum} from "../../reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {metricsGoals} from "../../../utils/constans/metrics/goals";


function* getFreighterEmptyLegsSaga({payload}: PayloadAction<IGetFreighterEmptyLegsPayload>) {
  try {
    const airportFromResponse: BookingApiGetAirportResponse = yield call(
      API.getAirportById,
      new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
    );

    const airportFrom = airportFromResponse.getAirport();
    if (!airportFrom)
      throw new Error("No airport with given id found");

    const getEmptyLegsReq = new BookingApiEmptyLegListRequest()
      .setPagemodel(
        new PageModel()
          .setPage(payload.page)
          .setLimit(PageModelLimits.Search)
      )
      .setPax(payload.pax);

    payload.departureDate && getEmptyLegsReq.setDeparturedate(getLocalDateAsISOString(payload.departureDate));
    payload.fromDate && getEmptyLegsReq.setDatestart(getLocalDateAsISOString(payload.fromDate));
    payload.toDate && getEmptyLegsReq.setDeparturedate(getLocalDateAsISOString(payload.toDate));
    payload.planeCategoryIds && getEmptyLegsReq.setPlanecategoryidsList(payload.planeCategoryIds);
    payload.planeIsNotOlderDate && getEmptyLegsReq.setManufactureyearfrom(payload.planeIsNotOlderDate.getUTCFullYear());
    payload.capacity && getEmptyLegsReq.setMaxpax(payload.capacity);
    payload.ambulance && getEmptyLegsReq.setIsambulance(payload.ambulance);
    payload.animals && getEmptyLegsReq.setIsanimals(payload.animals);
    payload.smoking && getEmptyLegsReq.setIssmoking(payload.smoking);
    payload.cargo && getEmptyLegsReq.setIscargo(payload.cargo);

    getEmptyLegsReq.setAirportfrom(airportFrom);
    getEmptyLegsReq.setAirportto(new AirportEntity().setAirportid(payload.airportToId));

    const getEmptyLegsRes: BookingApiEmptyLegListResponse = yield call(API.getEmptyLegList, getEmptyLegsReq);

    yield put(setFreighterEmptyLegsResult({
      items: getEmptyLegsRes.getEmptylegsList(),
      totalCount: getEmptyLegsRes.getTotalcount(),
    }))
  } catch {
  }
}


function* createOrderSaga({payload}: PayloadAction<ICreateOrderPayload>) {
  try {
    const orderRoutes: OrderRouteEntity[] = yield all(payload.orderCreateOrderRoutes.map(or => call(addOrderRouteToOrderSaga, or)));
    const planeCategories = payload.planeCategoryIds.map(pcId => new PlaneCategoryEntity().setPlanecategoryid(pcId));

    const createOrderReq: BookingApiOrderRequest = new BookingApiOrderRequest()
      .setOrder(new OrderEntity()
        .setType(OrderTypeEnum.EMPTY_LEG)
        .setOrderroutesList(orderRoutes)
        .setPlanecategoriesList(planeCategories)
      );

    payload.welcomeMessage && createOrderReq.setWelcomemessage(payload.welcomeMessage);

    const createOrderRes: BookingApiOrderResponse = yield call(API.orderCreate, createOrderReq);

    if (createOrderRes.getResultresponse()?.getIssuccessful()) {
      yield put(setCreateOrderResult({
        order: createOrderRes.getOrder(),
      }));

      metricsGoals.createEmptyLegOrder();
    } else {
      yield put(setCreateOrderResult({
        errorMessage: getErrorMessageByCode(createOrderRes.getResultresponse()!.getErrormessage()),
      }));
    }
  } catch {
  }
}

function* addOrderRouteToOrderSaga(orderRoute: IOrderCreateOrderRoute) {
  const emptyLegList = orderRoute.selectedEmptyLegIds.map(elId => new EmptyLegEntity().setEmptylegid(elId));

  const airportFromResponse: BookingApiGetAirportResponse = yield call(
    API.getAirportById,
    new BookingApiGetAirportRequest().setAirportid(orderRoute.airportFromId)
  );

  const airportFrom = airportFromResponse.getAirport();
  if (!airportFrom)
    throw new Error("No airport with given id found");

  return new OrderRouteEntity()
    .setAirportfrom(airportFrom)
    .setAirportto(
      new AirportEntity().setAirportid(orderRoute.airportToId)
    )
    .setDeparturedate(getLocalDateAsISOString(orderRoute.departureDate))
    .setPax(orderRoute.pax)
    .setEmptylegsList(emptyLegList)
}


export function* searchSaga() {
  yield all([
    takeLatest(getFreighterEmptyLegs.type, getFreighterEmptyLegsSaga),
    takeLatest(createOrder.type, createOrderSaga),
  ])
}