import React, {Dispatch, FC, SetStateAction} from 'react';
import {FlightDrawerPlane} from "../plane/FlightDrawerPlane";
import {FlightDrawerOperator} from "../operator/FlightDrawerOperator";
import {ReactComponent as ChatIcon} from "../../../../media/icons/chat_white.svg";
import {
  IChartererEmptyLegFromOrder, IChartererOrder,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {PlanePhotos} from "../../../../pages/Lk/OperatorsOnly/Fleet/components/PlanePhotos";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {FlightDrawerOrderRouteEmptyLegDetails} from "../details/FlightDrawerOrderRouteEmptyLegDetails";
import {
  FlightDrawerContentWrapper,
  FlightDrawerPhotosAndContentWrapper
} from "./FlightDrawerStyledComponents";
import {FlightDrawerOrderRouteEmptyLegFooter} from "../footers/FlightDrawerOrderRouteEmptyLegFooter";
import {defineMessages, useIntl} from "react-intl";


const OrderRouteEmptyLegFlightDrawerMessages = defineMessages({
  writeToChat: {
    id: "orderRouteEmptyLegFlightDrawer.writeToChat",
    defaultMessage: "Send message",
  },
})

interface IOrderRouteEmptyLegFlightDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  order: IChartererOrder;
  emptyLeg: IChartererEmptyLegFromOrder;
  openChat: () => void;
}

export const OrderRouteEmptyLegFlightDrawer: FC<IOrderRouteEmptyLegFlightDrawer> = ({
                                                                                      isOpen,
                                                                                      setIsOpen,
                                                                                      order,
                                                                                      emptyLeg,
                                                                                      openChat,
                                                                                    }) => {
  const intl = useIntl();

  const footer = <FlightDrawerOrderRouteEmptyLegFooter emptyLeg={emptyLeg} order={order} />

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer}>
      <FlightDrawerPhotosAndContentWrapper>
        <PlanePhotos imageFiles={emptyLeg.plane.imageFiles}/>

        <FlightDrawerContentWrapper>
          <FlightDrawerPlane plane={emptyLeg.plane}/>

          <FlightDrawerOrderRouteEmptyLegDetails emptyLeg={emptyLeg}/>

          <FlightDrawerOperator managerUser={emptyLeg.freighterUser}
                                company={emptyLeg.freighterCompany}/>

          <CustomButton type={"primary"} onClick={openChat} style={{borderRadius: 10}}>
            <ChatIcon/>
            <div>{intl.formatMessage(OrderRouteEmptyLegFlightDrawerMessages.writeToChat)}</div>
          </CustomButton>
        </FlightDrawerContentWrapper>
      </FlightDrawerPhotosAndContentWrapper>
    </CustomDrawer>
  );
};