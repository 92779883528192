import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const FreighterClientManagerMessage = defineMessage({
  id: "freighterClientManager.name",
  defaultMessage: "Operator, client manager",
})

export const freighterClientManager: RoleDescriptor<Role.freighterClientManager> = {
  role: Role.freighterClientManager,
  name: FreighterClientManagerMessage,
  backendIdentifier: 'freighter_client',
  homeRoute: Route.profile,
  navigation: {
    profile: [
      Route.requestsFromAgents,
      Route.requestsFromClients,
    ],
    sidebar: [
      // Route.tripBoard,
      Route.requestsFromAgents,
      Route.profile,
    ]
  },
}