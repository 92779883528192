import React, {FC, ReactNode} from 'react';
import styled from "styled-components";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

interface IPageWrapperStyled {
  alignItems: 'none' | 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'none' | 'center' | 'flex-start' | 'flex-end';
}

const PageWrapperStyled = styled.div<IPageWrapperStyled>`
  display: flex;
  flex-grow: 1;
  justify-content: ${props => props.justifyContent};
  flex-direction: column;
  padding: 30px 0;
  align-items: ${props => props.alignItems};
  row-gap: 20px;
  max-width: 100%;

  @media(max-width: ${mediaScreenSizes.tablet}) {
    width: 100%;
    padding: 16px 16px 30px 16px;
    row-gap: 16px;
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
    padding: 30px 10px;
    row-gap: 16px;
  }
`;

interface IPageWrapper {
  alignItems?: 'none' | 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'none' | 'center' | 'flex-start' | 'flex-end';
  children: ReactNode;
}

export const PageWrapper: FC<IPageWrapper> = ({alignItems = 'center', justifyContent = 'flex-start', children}) => {
  return (
    <PageWrapperStyled alignItems={alignItems} justifyContent={justifyContent}>
      {children}
    </PageWrapperStyled>
  );
};
