import {UserEntity} from "../../../proto/generated/api_entities_pb";
import {IUser} from "./userReducerTypes";
import {roleFromBackendIdentifier} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {UserStatus} from "../../../utils/constans/statuses/userStatuses";
import {mapTimeZoneEntityToITimezone} from "../fleetReducer/fleetMappers";

export const mapUserEntityToIUser = (item: UserEntity): IUser => {
  const role = roleFromBackendIdentifier(item.getRole());

  const timezone = item.getTimezone();
  if (!timezone)
    throw new Error("Null TimezoneEntity (timezone) in received UserEntity");




  return {
    id: item.getUserid(),
    role: role,
    firstName: item.getFirstname(),
    lastName: item.getLastname(),
    middleName: item.getMiddlename(),
    email: item.getEmail(),
    userStatus: item.getUserstatusid() as UserStatus,
    phone: item.getPhone(),
    timezone: mapTimeZoneEntityToITimezone(timezone),
  }
}