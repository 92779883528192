import styled from "styled-components";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

export const BrokerDrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 43px;
  width: 380px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: unset;
  }
`;