import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearCreate,
  initCommonClearDelete,
  initCommonClearEdit,
  initCommonClearList,
  initCommonCreate,
  initCommonCreateResult,
  initCommonDelete,
  initCommonDeleteResult,
  initCommonEdit,
  initCommonEditResult,
  initCommonGetList,
  initCommonSetListResult,
} from "../highOrderReducers/commonReducer";
import {mapManagerEntityToIManager, mapUserEntityToIManager} from "./personnelManagementMappers";
import {
  IChangeManagerPasswordPayload,
  ICreateManagerPayload,
  IDeleteManagerPayload,
  IEditManagerPayload,
  IGetManagerListPayload,
  IPersonnelManagementInitialState,
  ISetCreateManagerResultPayload,
  ISetDeleteManagerResultPayload,
  ISetEditManagerResultPayload,
  ISetManagerListResultPayload
} from "./personnelManagementReducerTypes";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";


const personnelManagementInitialState: IPersonnelManagementInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  create: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  edit: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  changeManagerPassword: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const personnelManagementSlice = createSlice({
  name: 'personnelManagement',
  initialState: personnelManagementInitialState,
  reducers: {
    getManagerList(state, {payload}: PayloadAction<IGetManagerListPayload>) {
      initCommonGetList(state, payload)
    },
    clearManagerList(state) {
      initCommonClearList(state)
    },
    setManagerListResult(state, {payload}: PayloadAction<ISetManagerListResultPayload>) {
      initCommonSetListResult(state, payload, mapManagerEntityToIManager)
    },

    createManager(state, {payload}: PayloadAction<ICreateManagerPayload>) {
      initCommonCreate(state, payload);
    },
    clearCreateManager(state) {
      initCommonClearCreate(state);
    },
    setCreateManagerResult(state, {payload}: PayloadAction<ISetCreateManagerResultPayload>) {
      initCommonCreateResult(state, payload, mapUserEntityToIManager)
    },

    editManager(state, {payload}: PayloadAction<IEditManagerPayload>) {
      initCommonEdit(state, payload);
    },
    clearEditManager(state) {
      initCommonClearEdit(state);
    },
    setEditManagerResult(state, {payload}: PayloadAction<ISetEditManagerResultPayload>) {
      initCommonEditResult(state, payload, mapUserEntityToIManager);
    },

    deleteManager(state, {payload}: PayloadAction<IDeleteManagerPayload>) {
      initCommonDelete(state, payload);
    },
    clearDeleteManager(state) {
      initCommonClearDelete(state);
    },
    setDeleteManagerResult(state, {payload}: PayloadAction<ISetDeleteManagerResultPayload>) {
      initCommonDeleteResult(state, payload);
    },

    changeManagerPassword(state, {payload}: PayloadAction<IChangeManagerPasswordPayload>) {
      initCommonAction(state, nameof<IPersonnelManagementInitialState>("changeManagerPassword"))
    },
    clearChangeManagerPassword(state) {
      initCommonClearAction(state, nameof<IPersonnelManagementInitialState>("changeManagerPassword"))
    },
    setChangeManagerPasswordResult(state, {payload}: PayloadAction<{errorMessage?: string}>) {
      initCommonActionResult(state, payload, nameof<IPersonnelManagementInitialState>("changeManagerPassword"))
    }
  },
});

export const {
  getManagerList,
  clearManagerList,
  setManagerListResult,

  createManager,
  clearCreateManager,
  setCreateManagerResult,

  editManager,
  clearEditManager,
  setEditManagerResult,

  deleteManager,
  clearDeleteManager,
  setDeleteManagerResult,

  changeManagerPassword,
  clearChangeManagerPassword,
  setChangeManagerPasswordResult,
} = personnelManagementSlice.actions;
export default personnelManagementSlice.reducer;