import React, {FC} from 'react';
import {IEmptyLegOrder} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {DetailItem} from "../../detailItem/DetailItem";
import {formatDate} from "../../../../utils/helpers/dateHelpers/dateHelpers";
import {
  BrokerDrawerDetailsContent,
  BrokerDrawerDetailsTitle,
  BrokerDrawerDetailsWrapper
} from "./BrokerDrawerFooterStyledComponents";
import {defineMessages, useIntl} from "react-intl";

const BrokerDrawerEmptyLegRespondDetailsMessages = defineMessages({
  title: {
    id: "brokerDrawerEmptyLegRespond.title",
    defaultMessage: "Respond details",
  },
  departureDate: {
    id: "brokerDrawerEmptyLegRespond.departureDate",
    defaultMessage: "Departure date",
  },
  departureTime: {
    id: "brokerDrawerEmptyLegRespond.departureTime",
    defaultMessage: "Departure time",
  }
})

interface IBrokerDrawerEmptyLegRespondDetails {
  emptyLegOrder: IEmptyLegOrder;
}

export const BrokerDrawerEmptyLegRespondDetails: FC<IBrokerDrawerEmptyLegRespondDetails> = ({emptyLegOrder}) => {
  const intl = useIntl();

  return (
    <BrokerDrawerDetailsWrapper>
      <BrokerDrawerDetailsTitle>
        <div>{intl.formatMessage(BrokerDrawerEmptyLegRespondDetailsMessages.title)}</div>
      </BrokerDrawerDetailsTitle>
      <BrokerDrawerDetailsContent>
        <DetailItem size={"small"} label={intl.formatMessage(BrokerDrawerEmptyLegRespondDetailsMessages.departureDate)}
                    value={formatDate(emptyLegOrder.departureDate, emptyLegOrder.airportFrom.timezone.offset, "date")}/>
        <DetailItem size={"small"} label={intl.formatMessage(BrokerDrawerEmptyLegRespondDetailsMessages.departureTime)}
                    value={`${formatDate(emptyLegOrder.departureDate, emptyLegOrder.airportFrom.timezone.offset, "time")} local`}/>
        <DetailItem size={"small"} label={'PAX'} value={emptyLegOrder.pax.toString()}/>
      </BrokerDrawerDetailsContent>
    </BrokerDrawerDetailsWrapper>
  );
};
