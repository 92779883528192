import {INotification} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {defineMessage, IntlShape} from "react-intl";


const NotificationGroupTodayMessage = defineMessage({
  id: "notificationGroup.today",
  defaultMessage: "Today",
})

interface IOldNotificationGroups {
  [dateString: string]: INotification[];
}

export interface INotificationGroups {
  unread: INotification[];
  old: IOldNotificationGroups;
}

export const getNotificationGroups = (notifications: INotification[], timezoneOffset: number, intl: IntlShape): INotificationGroups => {
  const unreadNotifications = notifications.filter(n => !n.isRead);
  const oldNotifications = notifications.filter(n => n.isRead);
  const today = formatDate(new Date(), timezoneOffset, "date");

  const oldNotificationGroups: IOldNotificationGroups = {}
  oldNotifications.forEach(notification => {
    let dateText = formatDate(notification.dateCreated, timezoneOffset, "date");
    if (dateText === today)
      dateText = intl.formatMessage(NotificationGroupTodayMessage)

    oldNotificationGroups.hasOwnProperty(dateText)
      ? oldNotificationGroups[dateText].push(notification)
      : oldNotificationGroups[dateText] = [notification];
  })

  return {
    unread: unreadNotifications,
    old: oldNotificationGroups,
  }
}