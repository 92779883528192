import React, {useEffect, useState} from 'react';
import {CustomCard} from "../../../../../../components/UI/AntCustom/CustomCard";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {ProfileManagerCard} from "./ProfileManagerCard";
import {
  clearManagerList,
  getManagerList
} from "../../../../../../store/reducers/personnelManagementReducer/personnelManagementReducer";
import {InitialPage} from "../../../../../../utils/constans/pagination/pagination";
import {Loader} from "../../../../../../components/UI/Loader/Loader";
import {NoData} from "../../../../../../components/UI/NoData/NoData";
import {AddManagerModal} from "./AddManagerModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";

const PersonnelContent = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(363px, 1fr));
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  }
`;


const ProfilePersonnelMessages = defineMessages({
  addManager: {
    id: "profilePersonnel.addManager",
    defaultMessage: "Add user",
  },
  noDataTitle: {
    id: "profilePersonnel.noDataTitle",
    defaultMessage: "You don't have any clients yet. You can add users by creating separate accounts for them",
  },
  noDataAction: {
    id: "profilePersonnel.noDataAction",
    defaultMessage: "Add user",
  },
  title: {
    id: "profilePersonnel.title",
    defaultMessage: "Users",
  }
})

export const ProfilePersonnel = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const managers = useAppSelector(state => state.personnelManagement.itemsObject.items);
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const [isAddManagerModalOpen, setIsAddManagerModalOpen] = useState<boolean>(false);
  const {
    items,
    isLoading,
    totalCount,
  } = useAppSelector(state => state.personnelManagement.itemsObject);

  useEffect(() => {
    dispatch(getManagerList({
      page: currentPage,
    }));

    return () => {
      dispatch(clearManagerList());
    }
  }, []);

  const loadMore = () => {
    dispatch(getManagerList({
      page: currentPage + 1,
    }));

    setCurrentPage(prev => prev + 1);
  }


  const extra = <CustomButton heightSize={'s'} ghost={true} type={'primary'} onClick={() => setIsAddManagerModalOpen(true)}>+{intl.formatMessage(ProfilePersonnelMessages.addManager)}</CustomButton>

  const noData = <NoData
    title={intl.formatMessage(ProfilePersonnelMessages.noDataTitle)}
    actionButton={{
      title: `+${intl.formatMessage(ProfilePersonnelMessages.noDataAction)}`,
      action: () => setIsAddManagerModalOpen(true)
    }}
  />

  return (
    <CustomCard title={intl.formatMessage(ProfilePersonnelMessages.title)} width={'max'} isContainerCard={true} extra={extra} titlePosition={'space-between'} style={{minHeight: 315}}>
      {items.length === 0
        ? (isLoading ? <Loader/> : noData)
        : <PersonnelContent>
          {managers.map(m => <ProfileManagerCard key={m.id} manager={m}/>)}
        </PersonnelContent>
      }

      {items.length > 0 && totalCount > items.length &&
        <CustomButton
          loading={isLoading}
          type={'primary'}
          onClick={loadMore}
        >
          {intl.formatMessage(CommonFormMessages.showMore)}
        </CustomButton>}

      {totalCount > 0 && isMobile && <CustomButton type={'primary'}>{intl.formatMessage(ProfilePersonnelMessages.addManager)}</CustomButton>}

      {isAddManagerModalOpen &&
        <AddManagerModal
          isModalVisible={isAddManagerModalOpen}
          setIsModalVisible={setIsAddManagerModalOpen}
        />}
    </CustomCard>
  );
};
