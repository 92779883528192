import React, {Dispatch, FC, SetStateAction} from "react";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {FlightDrawerContentWrapper, FlightDrawerPhotosAndContentWrapper} from "./FlightDrawerStyledComponents";
import {PlanePhotos} from "../../../../pages/Lk/OperatorsOnly/Fleet/components/PlanePhotos";
import {FlightDrawerPlane} from "../plane/FlightDrawerPlane";
import {FlightDrawerEmptyLegDetails} from "../details/FlightDrawerEmptyLegDetails";
import {FlightDrawerOperator} from "../operator/FlightDrawerOperator";
import {ISearchedEmptyLeg} from "../../../../store/reducers/searchReducer/searchReducerTypes";
import {FlightDrawerSearchedEmptyLegFooter} from "../footers/FlightDrawerSeachedEmptyLegFooter";
import {FlightDrawerPrice} from "../price/FlightDrawerPrice";

interface ISearchedEmptyLegFlightDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchedEmptyLeg: ISearchedEmptyLeg;
  onRespondClick: () => void;
}

export const SearchedEmptyLegFlightDrawer: FC<ISearchedEmptyLegFlightDrawer> = ({
                                                                                  isOpen,
                                                                                  setIsOpen,
                                                                                  searchedEmptyLeg,
                                                                                  onRespondClick,
                                                                                }) => {
  const footer = <FlightDrawerSearchedEmptyLegFooter searchedEmptyLeg={searchedEmptyLeg} onRespondClick={onRespondClick} />

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer}>
      <FlightDrawerPhotosAndContentWrapper>
        <PlanePhotos imageFiles={searchedEmptyLeg.plane.imageFiles}/>

        <FlightDrawerContentWrapper>
          <FlightDrawerPlane plane={searchedEmptyLeg.plane}/>

          <FlightDrawerEmptyLegDetails emptyLeg={searchedEmptyLeg}/>

          <FlightDrawerPrice price={searchedEmptyLeg.price} currency={searchedEmptyLeg.currency} />

          <FlightDrawerOperator managerUser={searchedEmptyLeg.freighterUser}
                                company={searchedEmptyLeg.freighterCompany}/>
        </FlightDrawerContentWrapper>
      </FlightDrawerPhotosAndContentWrapper>
    </CustomDrawer>
  );
};