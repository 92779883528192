import React, {FC, ReactNode, useEffect, useState} from 'react';
import {Col} from "antd";
import {CustomPanel} from "../../../../../components/UI/AntCustom/CustomPanel";
import {mediaScreenSizes, screenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {
  defaultEmptyLegRespondStatusesInfo,
  generateEmptyLegRespondStatusesInfo,
  IEmptyLegRespondStatusesInfo,
} from "../../../../../components/businessCommon/respondsInfo/generateRespondInfo";
import {CardFieldTitle} from "../../../../../components/UI/CardFieldTitle/CardFieldTItle";
import {useIsMobile, useWindowSize} from "../../../../../hooks/useWindowSize";
import {EmptyLegRespondCard} from "./EmptyLegRespondCard";
import styled from "styled-components";
import {IEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {CustomCollapse} from "../../../../../components/UI/AntCustom/CustomCollapse";
import {defineMessages, useIntl} from "react-intl";
import {useAppSelector} from "../../../../../store/store";
import {NotificationDot} from "../../../../../components/notifications/components/NotificationDot";
import {EmptyLegRespondsInfoBlock} from "../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";


const EmptyLegRespondsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  gap: 10px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: none;
    padding: 16px 8px 24px 8px;
  }
`;


const EmptyLegRespondsCollapseMessages = defineMessages({
  responds: {
    id: "emptyLegRespondsCollapse.responds",
    defaultMessage: "Responds",
  },
  noResponds: {
    id: "emptyLegRespondsCollapse.noResponds",
    defaultMessage: "No offers",
  },
})

interface IEmptyLegRespondsCollapse {
  emptyLeg: IEmptyLeg;
}

export const EmptyLegRespondsCollapse: FC<IEmptyLegRespondsCollapse> = ({emptyLeg}) => {
  const {width} = useWindowSize();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const hasUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)
    .some(n => n.metadata.emptyLegId === emptyLeg.id)

  const [respondInfo, setRespondInfo] = useState<IEmptyLegRespondStatusesInfo>(defaultEmptyLegRespondStatusesInfo);

  useEffect(() => {
    const newRespondInfo = generateEmptyLegRespondStatusesInfo(
      emptyLeg.orderRoutes.map(or => or.status || 0));

    setRespondInfo(newRespondInfo);
  }, [...emptyLeg.orderRoutes.map(or => or.status)])

  const panelHeader: ReactNode =
    <Col>
      <CardFieldTitle>
        <div>
          {emptyLeg.orderRoutes.length < 1
            ? intl.formatMessage(EmptyLegRespondsCollapseMessages.noResponds)
            : `${intl.formatMessage(EmptyLegRespondsCollapseMessages.responds)} (${emptyLeg.orderRoutes.length})`}
        </div>
        {hasUnreadNotifications && <NotificationDot/>}
      </CardFieldTitle>
    </Col>

  return (
    <CustomCollapse>
      <CustomPanel
        key={emptyLeg.id}
        header={panelHeader}
        extra={<EmptyLegRespondsInfoBlock emptyLegRespondStatusesInfo={respondInfo}/>}
        collapsible={emptyLeg.orderRoutes.length === 0 ? "disabled" : undefined}
        noPaddings={isMobile}
      >
        {emptyLeg.orderRoutes.length > 0 && <EmptyLegRespondsContent>
          {emptyLeg.orderRoutes.map((emptyLegOrder, index) => {
              return <EmptyLegRespondCard
                key={emptyLegOrder.orderId}
                indexNumber={index + 1}
                emptyLegOrder={emptyLegOrder}
                emptyLeg={emptyLeg}
              />
            }
          )}
        </EmptyLegRespondsContent>}
      </CustomPanel>
    </CustomCollapse>
  );
};
