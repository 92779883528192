import {UserEntity} from "../../../proto/generated/api_entities_pb";
import {IRegistration} from "./registrationsReducerTypes";
import {roleFromBackendIdentifier} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {UserStatus} from "../../../utils/constans/statuses/userStatuses";
import {mapCompanyEntityToICompany} from "../companyReducer/companyMappers";

export const mapUserEntityToIRegistration = (item: UserEntity): IRegistration => {
  const company = item.getCompany();
  if (!company)
    throw new Error("Null CompanyEntity (company) in received UserEntity");

  return {
    id: item.getUserid(),
    firstName: item.getFirstname(),
    lastName: item.getLastname(),
    middleName: item.getMiddlename(),
    email: item.getEmail(),
    companyRole: roleFromBackendIdentifier(item.getRole()),
    userStatus: item.getUserstatusid() as UserStatus,
    company: mapCompanyEntityToICompany(company),
  }
}