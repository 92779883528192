import React, {FC} from 'react';
import styled from "styled-components";
import {ICharterOfferStatusesInfo, IEmptyLegRespondStatusesInfo} from "./generateRespondInfo";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import {defineMessages, useIntl} from "react-intl";


export const StatusShield = styled.div<{backgroundColor: string}>`
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  padding: 2px 13px;
  border-radius: 23px;
  background: ${props => props.backgroundColor};
  white-space: nowrap;
`;

const RespondsInfoBlockStyled = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr max-content;
  gap: 8px;
  align-items: center;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
`;


const RespondsInfoBlockMessages = defineMessages({
  accepted: {
    id: "respondsInfoBlock.accepted",
    defaultMessage: "Accepted",
  },
  noAnswer: {
    id: "respondsInfoBlock.noAnswer",
    defaultMessage: "Pending",
  },
  rejected: {
    id: "respondsInfoBlock.rejected",
    defaultMessage: "Rejected",
  },
  withdrawn: {
    id: "respondsInfoBlock.withdrawn",
    defaultMessage: "Withdrawn",
  },

  notOffered: {
    id: "respondsInfoBlock.notOffered",
    defaultMessage: "Not offered",
  },
  offered: {
    id: "respondsInfoBlock.offered",
    defaultMessage: "Offered",
  },
  declinedByOperator: {
    id: "respondsInfoBlock.declinedByOperator",
    defaultMessage: "Declined",
  },
});

interface IEmptyLegRespondsInfoBlock {
  emptyLegRespondStatusesInfo: IEmptyLegRespondStatusesInfo;
}

export const EmptyLegRespondsInfoBlock: FC<IEmptyLegRespondsInfoBlock> = ({emptyLegRespondStatusesInfo}) => {
  const intl = useIntl();

  return (
    <RespondsInfoBlockStyled>
      {emptyLegRespondStatusesInfo.approved > 0 &&
        <StatusShield backgroundColor={"#D6E6CD"}>{intl.formatMessage(RespondsInfoBlockMessages.accepted)} {emptyLegRespondStatusesInfo.approved}</StatusShield>}
      {emptyLegRespondStatusesInfo.active > 0 &&
        <StatusShield backgroundColor={"#CDDDE6"}>{intl.formatMessage(RespondsInfoBlockMessages.noAnswer)} {emptyLegRespondStatusesInfo.active}</StatusShield>}
      {emptyLegRespondStatusesInfo.declined > 0 &&
        <StatusShield backgroundColor={"#E7C4C4"}>{intl.formatMessage(RespondsInfoBlockMessages.rejected)} {emptyLegRespondStatusesInfo.declined}</StatusShield>}
      {emptyLegRespondStatusesInfo.withdrawn > 0 &&
        <StatusShield backgroundColor={"#F9D99B"}>{intl.formatMessage(RespondsInfoBlockMessages.withdrawn)} {emptyLegRespondStatusesInfo.withdrawn}</StatusShield>}
    </RespondsInfoBlockStyled>
  );
};

interface ICharterOffersInfoBlock {
  charterOfferStatusesInfo: ICharterOfferStatusesInfo;
}

export const CharterOffersInfoBlock: FC<ICharterOffersInfoBlock> = ({charterOfferStatusesInfo}) => {
  const intl = useIntl();

  return (
    <RespondsInfoBlockStyled>
      {charterOfferStatusesInfo.offered > 0 &&
        <StatusShield backgroundColor={"#D6E6CD"}>{intl.formatMessage(RespondsInfoBlockMessages.offered)} {charterOfferStatusesInfo.offered}</StatusShield>}
      {charterOfferStatusesInfo.notOffered > 0 &&
        <StatusShield backgroundColor={"#CDDDE6"}>{intl.formatMessage(RespondsInfoBlockMessages.notOffered)} {charterOfferStatusesInfo.notOffered}</StatusShield>}
      {charterOfferStatusesInfo.declined > 0 &&
        <StatusShield backgroundColor={"#E7C4C4"}>{intl.formatMessage(RespondsInfoBlockMessages.declinedByOperator)} {charterOfferStatusesInfo.declined}</StatusShield>}
    </RespondsInfoBlockStyled>
  );
};
