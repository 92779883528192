import {RouteDescriptor} from "../RouteDescriptor";
import {Route} from "../Route";
import {groupNotGuest} from "../../roles/groups";
import {LkPage} from "../../../../pages/Lk/LkPage";
import {defineMessage} from "react-intl";

const LkRouteTitleMessage = defineMessage({
  id: "lkRoute.title",
  defaultMessage: "Profile",
})

export const lk: RouteDescriptor<Route.lk> = {
  route: Route.lk,
  render: LkPage,
  title: LkRouteTitleMessage,
  path: '/lk/*',
  allowRoles: [
    ...groupNotGuest
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: false,
}