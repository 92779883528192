import React, {useState} from 'react';
import {CustomCard} from "../../../../../../components/UI/AntCustom/CustomCard";
import {useAppSelector} from "../../../../../../store/store";
import styled from "styled-components";
import {roles} from "../../../../../../config/acl/roles";
import {ReactComponent as EmailIcon} from "../../../../../../media/icons/email.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../media/icons/phone.svg";
import {ReactComponent as Settings} from "../../../../../../media/icons/settings.svg";
import Button from "antd/es/button/button";
import {CardBottomButtons} from "../../../../../../components/UI/AntCustom/CardBottomButtons";
import {groupUserAdmin} from "../../../../../../config/acl/roles/groups";
import {EditUserModal} from "./EditUserModal";
import {ChangeUserPasswordModal} from "./ChangeUserPasswordModal";
import {UserSettingsDrawer} from "./UserSettingsDrawer";
import {DisplayFullName} from "../../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";


const ProfileUserTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;
`;

const ProfileUserSettingsButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  transition: 0.2s ease;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ProfileUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6B6B6B;
`;

const RoleAndFullName = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;
const FullName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ContactsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 42px;
  row-gap: 8px;
`;

const ContactIconAndValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
`;


const Subscription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const SubscriptionStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  span {
    width: 10px;
    height: 10px;
    background: #31CA91;
    border-radius: 100%;
  }
`;

const SubscriptionDescription = styled.div`
  margin-top: 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6B6B6B;
  letter-spacing: -0.01em;
  max-width: 365px;
`;


const ProfileUserMessages = defineMessages({
  title: {
    id: "profileUser.title",
    defaultMessage: "Profile",
  },
  settings: {
    id: "profileUser.settings",
    defaultMessage: "Settings",
  },
  contactInfo: {
    id: "profileUser.contactInfo",
    defaultMessage: "Contact information",
  },
  subscriptionDescription: {
    id: "profileUser.subscriptionDescription",
    defaultMessage: "Jetsnode is free for all users till March, 2023. Thank you for helping us develop our service!",
  },
  changePassword: {
    id: "profileUser.changePassword",
    defaultMessage: "Change password",
  },
})

export const ProfileUser = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const user = useAppSelector(state => state.user.oneItemObject.item)
  const isAdmin = groupUserAdmin.includes(user.role);
  const [isUserSettingsDrawerOpen, setIsUserSettingsDrawerOpen] = useState<boolean>(false);
  const [isUserEditModalOpen, setIsUserEditModalOpen] = useState<boolean>(false);
  const [isUserChangePasswordModalOpen, setIsUserChangePasswordModalOpen] = useState<boolean>(false);

  const cardTitle = <ProfileUserTitleWrapper>
    <div>{intl.formatMessage(ProfileUserMessages.title)}</div>

    <ProfileUserSettingsButton onClick={() => setIsUserSettingsDrawerOpen(true)}>
      <Settings/>
      <div>{intl.formatMessage(ProfileUserMessages.settings)}</div>
    </ProfileUserSettingsButton>
  </ProfileUserTitleWrapper>

  return (
    <ProfileUserWrapper>
      <CustomCard title={cardTitle} width={'max'} isContainerCard={true} maxWidth={isMobile ? undefined : 700} noBottomBorderRadius={isAdmin}
                  borderless={true}
                  style={{flexGrow: 1}}>
        <ContentWrapper>
          <RoleAndFullName>
            <SectionTitle>{intl.formatMessage(roles[user.role].name)}</SectionTitle>
            <FullName>{DisplayFullName(user)}</FullName>
          </RoleAndFullName>

          <Contacts>
            <SectionTitle>{intl.formatMessage(ProfileUserMessages.contactInfo)}</SectionTitle>
            <ContactsContent>
              <ContactIconAndValue>
                <PhoneIcon/>
                <div>+{user.phone}</div>
              </ContactIconAndValue>
              <ContactIconAndValue>
                <EmailIcon/>
                <div>{user.email}</div>
              </ContactIconAndValue>
            </ContactsContent>
          </Contacts>

          <Subscription>
            <SubscriptionStatus>
              <span/>
              <div>{intl.formatMessage(CommonDetailsMessages.subscriptionActive)}</div>
            </SubscriptionStatus>
            <SubscriptionDescription>
              {intl.formatMessage(ProfileUserMessages.subscriptionDescription)}
            </SubscriptionDescription>
          </Subscription>
        </ContentWrapper>
      </CustomCard>
      {isAdmin &&
        <CardBottomButtons>
          <Button ghost={true} type={"primary"} onClick={() => setIsUserChangePasswordModalOpen(true)}>{intl.formatMessage(ProfileUserMessages.changePassword)}</Button>
          <Button type={'primary'} onClick={() => setIsUserEditModalOpen(true)}>{intl.formatMessage(CommonButtonMessages.edit)}</Button>
        </CardBottomButtons>}

      {isUserEditModalOpen &&
        <EditUserModal isModalVisible={isUserEditModalOpen} setIsModalVisible={setIsUserEditModalOpen}/>}

      {isUserChangePasswordModalOpen &&
        <ChangeUserPasswordModal isModalVisible={isUserChangePasswordModalOpen}
                                 setIsModalVisible={setIsUserChangePasswordModalOpen}/>}

      {isUserSettingsDrawerOpen &&
        <UserSettingsDrawer isOpen={isUserSettingsDrawerOpen} setIsOpen={setIsUserSettingsDrawerOpen}/>}
    </ProfileUserWrapper>
  );
};
