export enum Route {
  home,

  // гостевые роуты
  price,
  agents,
  legalDocuments,

  // авторизация и логин
  login,
  register,
  setNewPassword,

  // роут личного кабинета
  lk,

  // общие роуты ЛК
  profile,

  // лк фрахтователя
  newTripRequest,
  searchEmptyLegs,
  requests,
  requestsFromClients,

  // лк фрахтовщика
  fleet,
  requestsFromAgents,
  emptyLegs,
  // tripBoard,
  schedule,

  // лк админа сайта
  adminRegistrations,

  // 404
  notFound,
}