import React, {FC, useContext, useEffect} from 'react';
import styled from "styled-components";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {DebounceSelect} from "../../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchCurrencies, getCurrencyById,
} from "../../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {defineMessages, useIntl} from "react-intl";
import {Col, Form, message, Row} from "antd";
import {CommonUnitMessages} from "../../../../../../intl/commonMessages/CommonUnitMessages";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {FlyHourPriceContext} from "./context/FlyHourPriceContextProvider";
import {Logger} from "../../../../../../utils/logger/Logger";
import {getErrorMessage} from "../../../../../../utils/errorHelpers/getCommonErrorMessage";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";

const Wrapper = styled.div`
  display: flex;
  width: 245px;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px 16px 8px 16px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: fit-content;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 16px;
`;

const FlyHourPriceCalculatorTotalMessages = defineMessages({
  description: {
    id: "flyHourPriceCalculatorTotal.description",
    defaultMessage: "The calculated value of the cost of a flight hour:",
  },
})


interface IFlyHourPriceCalculatorTotal {
  onSaveAndExit: () => void;
}

export const FlyHourPriceCalculatorTotal: FC<IFlyHourPriceCalculatorTotal> = ({onSaveAndExit}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const {
    flyHoursCount,
    flyHourPrice,
    setFlyHoursCount,
    currency,
    setCurrency,
    isCalculateLoading,
  } = useContext(FlyHourPriceContext);

  useEffect(() => {

    let defaultValues: { [key: string]: number | undefined } = {
      'flyHourCalculatorFlyHours': flyHoursCount,
      'flyHourCalculatorCurrencyId': currency?.id,
    }

    form.setFieldsValue(defaultValues);
  }, [])

  const handleFormValuesChange = async (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "flyHourCalculatorFlyHours" && changedValues.hasOwnProperty(fieldName)) {
      const value = changedValues[fieldName];
      setFlyHoursCount(value ?? undefined);
    }

    if (fieldName === "flyHourCalculatorCurrencyId" && changedValues.hasOwnProperty(fieldName)) {
      const value = changedValues[fieldName];
      try {
        const currency = await getCurrencyById(value);
        currency && setCurrency(currency);
      } catch (e) {
        Logger.error(getErrorMessage(e));
        setCurrency(undefined)
      }
    }
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  return (
    <Wrapper>
      <Form
        form={form}
        layout={'vertical'}
        onValuesChange={async (changedValues) => {
          await handleFormValuesChange(changedValues);
        }}
        onFinish={onSaveAndExit}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.calcFlyHoursLabel)}
              name={'flyHourCalculatorFlyHours'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMustBePositive)},
                {max: 8760, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
              ]}
            >
              <CustomInputNumber placeholder={intl.formatMessage(CommonFormMessages.commonPlaceholder)}
                                 addonAfter={intl.formatMessage(CommonUnitMessages.flyHours)} precision={0}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.currencyLabel)}
              name={'flyHourCalculatorCurrencyId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <DebounceSelect
                defaultOptionsFetchCallback={fetchCurrencies}
                placeholder={intl.formatMessage(CommonFormMessages.currencyPlaceholder)}
              />
            </CustomFieldInput>
          </Col>
        </Row>

        <Description>{intl.formatMessage(FlyHourPriceCalculatorTotalMessages.description)}</Description>
        <Price>{flyHourPrice ? `${flyHourPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0} {currency?.name}</Price>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={"submit"}
            loading={isCalculateLoading}
            disabled={!flyHourPrice || flyHourPrice <= 0}
          >
            {intl.formatMessage(CommonButtonMessages.saveAndExit)}
          </CustomButton>
        </CustomFieldInput>

        {/*TODO reset all*/}
        {/*<CustomButton*/}
        {/*  block*/}
        {/*  danger*/}
        {/*>*/}
        {/*  {intl.formatMessage(CommonButtonMessages.resetAllValues)}*/}
        {/*</CustomButton>*/}
      </Form>
    </Wrapper>
  );
};
