import React, {FC} from 'react';
import {ExtendedMenuItem} from "../../../../hooks/useGenerateMenu";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";
import {useAppSelector} from "../../../../store/store";

const HeaderMobileMenuSharedRouteItemContent = styled.div<{isActive: boolean, isAuthenticated: boolean}>`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: ${props => props.isActive ? "#1890FF" : (props.isAuthenticated ? "#808080" : "#000000")};
`;


interface IHeaderMobileMenuSharedRouteItem {
  menuItem: ExtendedMenuItem;
  closeMenu: () => void;
}

export const HeaderMobileMenuSharedRouteItem: FC<IHeaderMobileMenuSharedRouteItem> = ({menuItem, closeMenu}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {isAuthenticated} = useAppSelector(state => state.auth)
  const isActive = menuItem.key === pathname;

  const onClick = () => {
    navigate(menuItem.key as string)
    closeMenu()
  }

  return (
    <HeaderMobileMenuSharedRouteItemContent isActive={isActive} isAuthenticated={isAuthenticated} onClick={onClick}>
      {menuItem.label}
    </HeaderMobileMenuSharedRouteItemContent>
  );
};

