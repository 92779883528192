import {TimezoneApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  TimezoneApiTimezoneListRequest, TimezoneApiTimezoneListResponse
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new TimezoneApiServiceClient(getProxyUrl(), null, null);

export class TimezoneApi {
  static async getTimezoneList(req: TimezoneApiTimezoneListRequest): Promise<TimezoneApiTimezoneListResponse> {
    return new Promise((resolve, reject) => {
      API.call<TimezoneApiTimezoneListRequest, TimezoneApiTimezoneListResponse>(client, client.timezoneList, req, resolve, reject);
    });
  }
}