import React, {Dispatch, FC, SetStateAction} from 'react';
import {Modal} from "antd";
import {getNginxHost} from "../../../../API/helpers/getProxyUrl";
import {UploadFile} from "antd/es/upload";
import {defineMessages, useIntl} from "react-intl";
import {CustomModal} from "../../../../components/UI/AntCustom/CustomModal";


const RegistrationLicensesModalMessages = defineMessages({
  title: {
    id: "registrationLicensesModal.title",
    defaultMessage: "User licenses"
  },
  warn: {
    id: "registrationLicensesModal.warn",
    defaultMessage: "User hasn't uploaded any files yet."
  },
})

interface RegistrationLicensesModalProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  operatorCard?: UploadFile;
  registrationCertificate?: UploadFile;
}

export const RegistrationLicensesModal: FC<RegistrationLicensesModalProps> = ({
                                                                                isVisible,
                                                                                setIsVisible,
                                                                                operatorCard,
                                                                                registrationCertificate
                                                                              }) => {
  const intl = useIntl();

  return (
    <CustomModal
      title={intl.formatMessage(RegistrationLicensesModalMessages.title)}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      centered
      footer={null}
    >
      {!operatorCard && !registrationCertificate && intl.formatMessage(RegistrationLicensesModalMessages.warn)}
      <ul>
        {operatorCard &&
          <li key={operatorCard.uid}>
            <a href={`${getNginxHost()}${operatorCard.url}`} target={'_blank'} rel="noreferrer">
              {operatorCard.name}
            </a>
          </li>}

        {registrationCertificate &&
          <li key={registrationCertificate.uid}>
            <a href={`${getNginxHost()}${registrationCertificate.url}`} target={'_blank'} rel="noreferrer">
              {registrationCertificate.name}
            </a>
          </li>}
      </ul>
    </CustomModal>
  );
};
