import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const FreighterAdminMessage = defineMessage({
  id: "freighterAdmin.name",
  defaultMessage: "Administrator",
})

export const freighterAdmin: RoleDescriptor<Role.freighterAdmin> = {
  role: Role.freighterAdmin,
  name: FreighterAdminMessage,
  backendIdentifier: 'freighter_admin',
  homeRoute: Route.profile,
  navigation: {
    profile: [
      Route.requestsFromAgents,
      Route.emptyLegs,
      Route.fleet,
      Route.schedule,
    ],
    sidebar: [
      // Route.tripBoard,
      Route.requestsFromAgents,
      Route.emptyLegs,
      Route.fleet,
      Route.schedule,
      Route.profile,
    ]
  },
}