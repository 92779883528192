import {INotification, NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {Logger} from "../../../utils/logger/Logger";
import {Route} from "../../../config/acl/routes/Route";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {Modal} from "antd";

export const getNotificationCallback = (notification: INotification): () => void => {
  const {type} = notification;

  const emptyLegByIdPage = () =>
    window.open(`${pathFromRoute(Route.emptyLegs)}?emptyLegId=${notification.entityId}`);

  const emptyLegArchiveByIdPage = () =>
    window.open(`${pathFromRoute(Route.emptyLegs)}?emptyLegId=${notification.entityId}&?archive=true`);

  const orderRequestByIdPage = () =>
    window.open(`${pathFromRoute(Route.requestsFromAgents)}?orderRequestId=${notification.entityId}`);

  const orderRequestArchivedByIdPage = () =>
    window.open(`${pathFromRoute(Route.requestsFromAgents)}?orderRequestId=${notification.entityId}&?archive=true`);

  const orderRouteByIdPage = () =>
    window.open(`${pathFromRoute(Route.requests)}?orderId=${notification.entityId}`);

  const orderRouteArchiveByIdPage = () =>
    window.open(`${pathFromRoute(Route.requests)}?orderId=${notification.entityId}&?archive=true`);

  switch (type) {
    case NotificationType.TYPE_EMPTY_LEG_ARCHIVED_EXPIRED:
      return emptyLegArchiveByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED:
      return orderRouteByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED:
      return emptyLegByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED:
      return orderRouteByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN:
      return orderRouteByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN:
      return emptyLegByIdPage;
    case NotificationType.TYPE_EMPTY_LEG_UPDATED:
      return orderRouteByIdPage;

    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED:
      return orderRequestByIdPage;
    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED:
      return orderRouteArchiveByIdPage;

    case NotificationType.TYPE_ORDER_REQUEST_CREATED:
      return orderRequestByIdPage;
    case NotificationType.TYPE_ORDER_REQUEST_ARCHIVED:
      return orderRouteByIdPage;
    case NotificationType.TYPE_ORDER_REQUEST_EXPIRED:
      return orderRequestArchivedByIdPage;
    case NotificationType.TYPE_ORDER_REQUEST_OFFERED:
      return orderRouteByIdPage;

    default:
      Logger.error("No route matches given notification type was found")
      return () => {};
  }
}