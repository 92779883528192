import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import styled from "styled-components";
import {PlaneImage} from "../../../../../components/businessCommon/planeImage/PlaneImage";
import {CustomButton, ICustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {ReactComponent as CheckIcon} from "../../../../../media/icons/accept.svg";
import {ISearchedEmptyLeg} from "../../../../../store/reducers/searchReducer/searchReducerTypes";
import {
  SearchedEmptyLegFlightDrawer
} from "../../../../../components/businessCommon/flightDrawer/wrappers/SearchedEmptyLegFlightDrawer";
import {DisplayFlyPrice} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {DisplayFlyDuration} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import {DisplayEmptyLegDates} from "../../../../../utils/helpers/textDisplayHelpers/DisplayEmptyLegDates";
import {DisplayRoute} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";


const EmptyLegCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #D9D9D9;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    background: #FAFAFA;
  }
`;

const EmptyLegCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px 24px 24px;
  row-gap: 24px;
  flex-grow: 1;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 16px;
    padding: 16px 16px 24px 16px;
  }
`;

const CheckButton = styled(CustomButton)<ICustomButton & { $isActive: boolean }>`
  svg path {
    fill: ${props => props.$isActive ? "#FFFFFF" : "#1890FF"}
  }
`;

const EmptyLegCardTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    column-gap: 12px;
    justify-content: flex-start;

    img {
      border-radius: 7px;
    }
  }
`;

const EmptyLegCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;

  div {
    :first-child {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    :last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #262526;
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
  }
`;

const EmptyLegCardBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 32px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 24px;
  }
`;

const EmptyLegCardDetailsItem = styled.div`
  display: flex;
  flex-direction: column;

  div {
    :first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      justify-content: flex-start;
    }

    :last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const EmptyLegCardDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 8px;
    column-gap: 32px;
    
    ${EmptyLegCardDetailsItem} {
      :nth-child(1), :nth-child(2), :nth-child(3) {
        width: 100%;
      }
    }
  }
`;

const EmptyLegCardBottomButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
    margin-top: 8px;

    button {
      :nth-child(2) {
        flex-grow: 1;
      }
    }
  }
`;

interface IDetailItem {
  title: string;
  value: string;
}

interface ISearchedEmptyLegCard {
  searchedEmptyLeg: ISearchedEmptyLeg;
  selectedEmptyLegs: ISearchedEmptyLeg[];
  setSelectedEmptyLegs: Dispatch<SetStateAction<ISearchedEmptyLeg[]>>;
  setIsCreateOrderDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const SearchedEmptyLegCard: FC<ISearchedEmptyLegCard> = ({
                                                                  searchedEmptyLeg,
                                                                  selectedEmptyLegs,
                                                                  setSelectedEmptyLegs,
                                                                  setIsCreateOrderDrawerOpen,
                                                                }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [isSearchedEmptyLegDrawerOpen, setIsSearchedEmptyLegDrawerOpen] = useState<boolean>(false);
  const isActive = selectedEmptyLegs.map(el => el.emptyLegId).includes(searchedEmptyLeg.emptyLegId);

  const details: IDetailItem[] = [
    {title: intl.formatMessage(CommonDetailsMessages.operator), value: searchedEmptyLeg.freighterCompany.name},
    {
      title: intl.formatMessage(CommonDetailsMessages.route),
      value: DisplayRoute([searchedEmptyLeg.airportFrom, searchedEmptyLeg.airportTo], "codes")
    },
    {
      title: intl.formatMessage(CommonDetailsMessages.date),
      value: DisplayEmptyLegDates(searchedEmptyLeg.airportFrom.timezone.offset, searchedEmptyLeg.departureDate, searchedEmptyLeg.availableFromDate, searchedEmptyLeg.availableToDate)
    },
    {
      title: intl.formatMessage(CommonDetailsMessages.duration),
      value: DisplayFlyDuration(searchedEmptyLeg.flyHours, searchedEmptyLeg.flyMinutes)
    },
    {
      title: intl.formatMessage(CommonDetailsMessages.price),
      value: DisplayFlyPrice(searchedEmptyLeg.price, searchedEmptyLeg.currency)
    },
  ]

  const onSelect = () => {
    if (isActive)
      setSelectedEmptyLegs(prev => prev.filter(el => el.emptyLegId !== searchedEmptyLeg.emptyLegId));
    else
      setSelectedEmptyLegs(prev => [...prev, searchedEmptyLeg]);
  }

  const onRespondClick = () => {
    if (!isActive)
      setSelectedEmptyLegs(prev => [...prev, searchedEmptyLeg]);
    setIsCreateOrderDrawerOpen(true);
    setIsSearchedEmptyLegDrawerOpen(false);
  }

  const planeImage = <PlaneImage files={searchedEmptyLeg.plane.imageFiles}
                                 width={isMobile ? '92px' : '175px'}
                                 height={isMobile ? '72px' : '100%'}/>

  const checkEmptyLegButton = <CheckButton type={"primary"} ghost={!isActive} onClick={onSelect}
                                           $isActive={isActive}><CheckIcon/></CheckButton>

  return (
    <EmptyLegCardWrapper>
      {!isMobile && planeImage}
      <EmptyLegCardContent>
        <EmptyLegCardTop>
          {isMobile && planeImage}
          <EmptyLegCardTitle>
            <div>{searchedEmptyLeg.plane.planeType.name}</div>
            <div>{searchedEmptyLeg.plane.planeType.planeCategory.name}</div>
          </EmptyLegCardTitle>
          {!isMobile && checkEmptyLegButton}
        </EmptyLegCardTop>

        <EmptyLegCardBottom>
          <EmptyLegCardDetails>
            {details.map(d => <EmptyLegCardDetailsItem key={d.title}>
              <div>{d.title}</div>
              <div>{d.value}</div>
            </EmptyLegCardDetailsItem>)}
          </EmptyLegCardDetails>

          <EmptyLegCardBottomButtons>
            {isMobile && checkEmptyLegButton}
            <CustomButton type={'primary'} heightSize={isMobile ? 'l' : 'm'} style={{flexGrow: isMobile ? 1 : 'none'}}
                          onClick={() => setIsSearchedEmptyLegDrawerOpen(true)}>{intl.formatMessage(CommonButtonMessages.more)}</CustomButton>
          </EmptyLegCardBottomButtons>
        </EmptyLegCardBottom>
      </EmptyLegCardContent>

      {isSearchedEmptyLegDrawerOpen &&
        <SearchedEmptyLegFlightDrawer isOpen={isSearchedEmptyLegDrawerOpen} setIsOpen={setIsSearchedEmptyLegDrawerOpen}
                                      searchedEmptyLeg={searchedEmptyLeg} onRespondClick={onRespondClick}/>}
    </EmptyLegCardWrapper>
  );
};
