import React, {FC, ReactElement} from 'react';
import styled from "styled-components";
import {useIsMobile} from "../../../hooks/useWindowSize";
import {Tooltip} from "antd";
import {ReactComponent as TooltipIcon} from "../../../media/icons/info.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextContent = styled.div<{ size: string, needWrap: boolean }>`
  flex-direction: column;
  row-gap: ${props => props.size === "small" ? "4px" : "0"};
  white-space: ${props => props.needWrap ? "normal" : "nowrap"};
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    :first-child {
      font-weight: 400;
      font-size: 10px;
      line-height: ${props => props.size === "small" ? "12px" : "22px"};
      letter-spacing: -0.01em;
      color: #000000;
      opacity: 0.5;
    }

    :last-child {
      font-weight: 600;
      font-size: 14px;
      line-height: ${props => props.size === "small" ? "17px" : "22px"};
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: -0.01em;
    }
  }
`;

const DetailStyled = styled.div<{ size: string, background: string, needWrap: boolean }>`
  display: flex;
  background: ${props => props.background};
  padding: ${props => props.size === "small" ? "8px 10px" : "5px 10px"};
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`

type DetailItemSize = "default" | "small";

interface IDetailItem {
  label: string;
  value: string;
  tooltip?: string;
  icon?: ReactElement;
  size?: DetailItemSize;
  background?: string;
  needWrap?: boolean;

  [key: string]: any;
}

export const DetailItem: FC<IDetailItem> = ({
                                              label,
                                              value,
                                              tooltip,
                                              icon = undefined,
                                              size = "default",
                                              background = "#FFFFFF",
                                              needWrap = false,
                                              ...props
                                            }) => {
  const isMobile = useIsMobile();

  const tooltipComponent = tooltip && <Tooltip title={tooltip}>
    <TooltipIcon style={{marginLeft: 3}}/>
  </Tooltip>

  return (
    <DetailStyled size={size} background={background} needWrap={needWrap} {...props}>
      {icon && !isMobile && <IconWrapper>
        {icon}
      </IconWrapper>}
      <TextContent size={size} needWrap={needWrap}>
        <div>{label}</div>
        <div>{value}{tooltipComponent}</div>
      </TextContent>
    </DetailStyled>
  );
};
