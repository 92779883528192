import React, {useEffect, useState} from 'react';
import {Menu} from "antd";
import {MenuItem, useGenerateMenu} from "../../../hooks/useGenerateMenu";
import {useLocation, useNavigate} from "react-router";
import {PageGroups} from "../../../config/acl/routes/pageGroups";
import styled from "styled-components";
import {useIsTablet} from "../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import {ReactComponent as OpenDetailedMenuIcon} from "../../../media/icons/open_detailed_menu.svg";

const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  //header space
  top: 94px;
  left: 0;
  height: calc(100vh - 174px);
  border-radius: 10px;
  width: 226px;
  margin-left: 30px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    top: 80px;
    height: calc(100vh - 176px);
    min-width: 61px;
    width: 61px;
    margin-left: 16px;
  }
`;

const OpenDetailedMenuButton = styled.div<{$isCollapsed: boolean}>`
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  transform: rotateY(${props => props.$isCollapsed ? 0 : "180deg"});
  
  :hover {
    opacity: 0.7;
  }
`;

const MenuItemLabelStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const MenuItemNotificationBadge = styled.div`
  background: #C90000;
  border-radius: 18px;
  padding: 1px 7px 1px 7px;
  height: 15px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    color: #ffffff !important;
    min-width: 20px;
    height: 20px;
    border-radius: 100%;
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: 12px !important;
  }
`;

const CustomMenu = styled(Menu)<{$isDetailedMenuCollapsed: boolean}>`
  position: fixed;
  //header space
  top: 94px;
  left: 0;
  height: calc(100vh - 174px);
  border-radius: 10px;
  width: 226px;
  margin-left: 30px;

  .ant-menu-sub {
    .ant-menu-item, .ant-menu-item-only-child {
      height: 17px;
      line-height: 17px;
      padding-left: 54px !important;
    }
  }

  @media (max-width: ${mediaScreenSizes.tablet}) {
    top: 80px;
    height: calc(100vh - 176px);
    min-width: 61px;
    margin-left: 16px;

    ${props => props.$isDetailedMenuCollapsed && 
      `
      ul.ant-menu-sub {
        border-radius: 10px !important;
      }
  
      .ant-menu-item, .ant-menu-item-selected {
        align-items: center !important;
        display: flex !important;
        padding: 0 !important;
        max-width: 61px !important;
        justify-content: center !important;
  
        svg {
          width: 20px;
          height: 20px;
        }
      }
  
      .ant-menu-submenu-title {
        display: flex !important;
        align-items: center !important;
        padding: 0 !important;
        justify-content: center !important;
  
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
  
      .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-submenu-selected {
        background: transparent !important;
        border-right: 3px solid #1890FF !important;
      }
  
      .ant-menu-title-content {
        display: none !important;
      }
  
      .ant-menu-sub {
        .ant-menu-item, .ant-menu-item-only-child {
          height: 17px;
          line-height: 17px;
          padding-left: 0 !important;
        }
      }
      `
    }
  }
`;

export const Sidebar = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isTablet = useIsTablet();
  const [isDetailedMenuCollapsed, setIsDetailedMenuCollapsed] = useState<boolean>(isTablet);
  const {menuItems} = useGenerateMenu("sidebar", isDetailedMenuCollapsed);

  useEffect(() => {
    setIsDetailedMenuCollapsed(isTablet);
  }, [isTablet])

  return (
    <Wrapper>
      <CustomMenu
        $isDetailedMenuCollapsed={isDetailedMenuCollapsed}
        style={{width: isDetailedMenuCollapsed ? 61 : 226}}
        selectedKeys={[pathname]}
        defaultOpenKeys={Object.keys(PageGroups)}
        mode="inline"
        items={menuItems as MenuItem[]}
        onSelect={s => {isTablet && setIsDetailedMenuCollapsed(true); navigate(s.key)}}
        inlineCollapsed={isDetailedMenuCollapsed}
        _internalDisableMenuItemTitleTooltip={true}
      />

      {isTablet && <OpenDetailedMenuButton onClick={() => setIsDetailedMenuCollapsed(prev => !prev)} $isCollapsed={isDetailedMenuCollapsed}>
        <OpenDetailedMenuIcon />
      </OpenDetailedMenuButton>}
    </Wrapper>
  );
};


export const MenuItemLabel = ({title, count}: { title: string, count: number }) => {
  const badge = (count < 100)
    ? <MenuItemNotificationBadge>{count}</MenuItemNotificationBadge>
    : <MenuItemNotificationBadge>99+</MenuItemNotificationBadge>;

  return <MenuItemLabelStyled>
    <div>{title}</div>
    {count > 0 && badge}
  </MenuItemLabelStyled>
}
