import React, {Dispatch, FC, SetStateAction} from "react";
import {
  IChartererOrder,
  IChartererOrderRequestFromOrder,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {PlanePhotos} from "../../../../pages/Lk/OperatorsOnly/Fleet/components/PlanePhotos";
import {FlightDrawerPlane} from "../plane/FlightDrawerPlane";
import {FlightDrawerOrderOrderRequestDetails} from "../details/FlightDrawerOrderOrderRequestDetails";
import {FlightDrawerOperator} from "../operator/FlightDrawerOperator";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {ReactComponent as ChatIcon} from "../../../../media/icons/chat_white.svg";
import {FlightDrawerContentWrapper, FlightDrawerPhotosAndContentWrapper} from "./FlightDrawerStyledComponents";
import {FlightDrawerOrderOrderRequestFooter} from "../footers/FlightDrawerOrderOrderRequestFooter";
import {defineMessages, useIntl} from "react-intl";
import {FlightDrawerPrice} from "../price/FlightDrawerPrice";


const OrderOrderRequestFlightDrawerMessages = defineMessages({
  writeToChat: {
    id: "orderRouteOrderRequestFlightDrawer.writeToChat",
    defaultMessage: "Send message",
  },
})

interface IOrderOrderRequestFlightDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  order: IChartererOrder;
  orderRequest: IChartererOrderRequestFromOrder;
  openChat: () => void;
}

export const OrderOrderRequestFlightDrawer: FC<IOrderOrderRequestFlightDrawer> = ({
                                                                                              isOpen,
                                                                                              setIsOpen,
                                                                                              order,
                                                                                              orderRequest,
                                                                                              openChat,
                                                                                            }) => {
  const intl = useIntl();

  const footer = <FlightDrawerOrderOrderRequestFooter orderRequest={orderRequest}/>

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer}>
      <FlightDrawerPhotosAndContentWrapper>
        <PlanePhotos imageFiles={orderRequest.plane?.imageFiles ?? []}/>

        <FlightDrawerContentWrapper>
          {orderRequest.plane && <FlightDrawerPlane plane={orderRequest.plane}/>}
          <FlightDrawerOrderOrderRequestDetails order={order}
                                                orderRequest={orderRequest}/>
          <FlightDrawerPrice price={orderRequest.price} currency={orderRequest.currency} />
          <FlightDrawerOperator managerUser={orderRequest.freighterUser}
                                company={orderRequest.freighterCompany}/>
          <CustomButton type={"primary"} onClick={openChat} style={{borderRadius: 10}}>
            <ChatIcon/>
            <div>{intl.formatMessage(OrderOrderRequestFlightDrawerMessages.writeToChat)}</div>
          </CustomButton>
        </FlightDrawerContentWrapper>
      </FlightDrawerPhotosAndContentWrapper>
    </CustomDrawer>
  );
};