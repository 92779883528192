import React, {Dispatch, FC, SetStateAction, useMemo, useState} from "react";
import {Checkbox, Col, Form} from "antd";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {
  fetchAirports,
  fetchInitialAirportForSelect
} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DeleteOutlined} from "@ant-design/icons";
import {IChartererOrderRouteFilters} from "../MyRequestPage";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";


const ChartererOrderRouteFilterModalMessages = defineMessages({
  title: {
    id: "chartererOrderRouteFilterModal.title",
    defaultMessage: "Requests filter",
  },
  onlyApproved: {
    id: "chartererOrderRouteFilterModal.onlyApproved",
    defaultMessage: "Only accepted requests",
  },
})

interface IChartererOrderRouteFilterModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  filters: IChartererOrderRouteFilters;
  setFilters: Dispatch<SetStateAction<IChartererOrderRouteFilters>>;
}

export const ChartererOrderFilterModal: FC<IChartererOrderRouteFilterModal> = ({
                                                                          isModalVisible,
                                                                          setIsModalVisible,
                                                                          filters,
                                                                          setFilters
                                                                        }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const isResetDisabled = useMemo(() => Object.values(filters).every(v => v === undefined || v === false), [filters]);
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(Object.values(filters).every(v => v === undefined || v === false));

  const onFinish = (values: IChartererOrderRouteFilters) => {
    setFilters({...values});
    setIsModalVisible(false);
  }

  const onResetFilters = () => {
    setFilters({});
    setIsModalVisible(false);
  }

  const onValuesChange = () => {
    const fields = form.getFieldsValue();
    setIsApplyDisabled(Object.values(fields).every(v => v === undefined || v === false));
  }

  const initialValues: IChartererOrderRouteFilters = {
    airportFromId: filters.airportFromId,
    airportToId: filters.airportToId,
    onlyApproved: filters.onlyApproved,
  }

  return (
    <CustomModal
      title={intl.formatMessage(ChartererOrderRouteFilterModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
            name={'airportFromId'}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
              showSearch={true}
              defaultOptionsFetchCallback={() => fetchInitialAirportForSelect(filters.airportFromId)}
            />
          </CustomFieldInput>
        </Col>
        <Col flex={1}>
          <CustomFieldInput
            label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
            name={'airportToId'}
          >
            <DebounceSelect
              fetchOptions={fetchAirports}
              placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
              showSearch={true}
              defaultOptionsFetchCallback={() => fetchInitialAirportForSelect(filters.airportToId)}
            />
          </CustomFieldInput>
        </Col>
        <Col>
          <CustomFieldInput
            name={'onlyApproved'}
            valuePropName={'checked'}
          >
            <Checkbox>{intl.formatMessage(ChartererOrderRouteFilterModalMessages.onlyApproved)}</Checkbox>
          </CustomFieldInput>
        </Col>

        <CustomFieldInput>
          <CustomButton
            block
            type={'primary'}
            htmlType={'submit'}
            disabled={isApplyDisabled}
          >
            {intl.formatMessage(CommonButtonMessages.apply)}
          </CustomButton>
        </CustomFieldInput>

        {!isResetDisabled && <CustomButton block danger onClick={onResetFilters}>
          <DeleteOutlined/>
          {intl.formatMessage(CommonButtonMessages.resetFilters)}
        </CustomButton>}
      </Form>
    </CustomModal>
  );
};