import React, {useEffect, useState} from 'react';
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {Badge, Checkbox, Col, Form, message, Row, Typography} from "antd";
import {CustomDatePicker} from "../../../../components/UI/AntCustom/CustomDatePicker";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {
  clearFreighterEmptyLegs,
  getFreighterEmptyLegs,
} from "../../../../store/reducers/searchReducer/searchReducer";
import {Loader} from "../../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../../components/UI/AntCustom/CustomResult";
import {SearchedEmptyLegCard} from "./components/SearchedEmptyLegCard";
import {CreateOrderDrawer} from "./components/CreateOrderDrawer";
import {fetchAirports} from "../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DebounceSelect} from "../../../../components/UI/AntCustom/DebounceSelect";
import {fetchPlaneCategories} from "../../../../utils/helpers/selectDataFetchers/fetchPlaneCategories";
import {
  isDateLessTanOneMinuteAgo,
} from "../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {CustomFieldInput} from "../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {Hr} from "../../../../components/UI/Hr/Hr";
import {ISearchedEmptyLeg} from "../../../../store/reducers/searchReducer/searchReducerTypes";
import {paxRules} from "../../../../utils/rules/paxRules";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CommonUnitMessages} from "../../../../intl/commonMessages/CommonUnitMessages";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../hooks/useWindowSize";
import styled from "styled-components";
import {metricsGoals} from "../../../../utils/constans/metrics/goals";

const CreateRequestMobileFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 75px;
  background: #fff;
  z-index: 100;
  box-shadow: 0px -3px 12px rgb(0 0 0 / 7%);
`;


const SearchPageMessages = defineMessages({
  title: {
    id: "searchPage.title",
    defaultMessage: "Search Empty Legs",
  },
  description: {
    id: "searchPage.description",
    defaultMessage: "Find suitable Empty Leg flights based on search criteria.",
  },
  advancedSearch: {
    id: "searchPage.advancedSearch",
    defaultMessage: "Advanced search",
  },
  searchedFlights: {
    id: "searchPage.searchedFlights",
    defaultMessage: "Flights found",
  },
  createRequest: {
    id: "searchPage.createRequest",
    defaultMessage: "Create request",
  },
})

export interface IGetEmptyLegListFormData {
  airportFromId: number;
  airportToId: number;
  dates?: [Dayjs, Dayjs];
  departureDate?: Dayjs;
  pax: number;
  planeCategoryIds?: number[];
  planeIsNotOlderDate?: Dayjs;
  capacity?: number;
  ambulance?: boolean;
  animals?: boolean;
  smoking?: boolean;
  cargo?: boolean;
}

export const SearchPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const intl = useIntl();
  const {
    items,
    totalCount,
    isLoading,
  } = useAppSelector(state => state.search.itemsObject);
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const [currentFormData, setCurrentFormData] = useState<IGetEmptyLegListFormData>();
  const [isFirstSearchInitialized, setIsFirstSearchInitialized] = useState<boolean>(false);
  const [selectedEmptyLegs, setSelectedEmptyLegs] = useState<ISearchedEmptyLeg[]>([]);
  const [isCreateOrderDrawerOpen, setIsCreateOrderDrawerOpen] = useState<boolean>(false);
  const [isDateRangeActive, setIsDateRangeActive] = useState<boolean>(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState<boolean>(false);
  const [advancedSearchAppliedCount, setAdvancedSearchAppliedCount] = useState<number>(0);

  useEffect(() => {
    return () => {
      dispatch(clearFreighterEmptyLegs());
    }
  }, [])

  const loadMore = () => {
    if (currentFormData) {
      dispatch(getFreighterEmptyLegs({
        page: currentPage + 1,
        airportFromId: currentFormData.airportFromId,
        airportToId: currentFormData.airportToId,
        departureDate: currentFormData.departureDate?.toDate(),
        fromDate: currentFormData.dates ? currentFormData.dates[0].toDate() : undefined,
        toDate: currentFormData.dates ? currentFormData.dates[1].toDate() : undefined,
        pax: currentFormData.pax,
        planeCategoryIds: currentFormData.planeCategoryIds,
        planeIsNotOlderDate: currentFormData.planeIsNotOlderDate?.toDate(),
        capacity: currentFormData.capacity,
        ambulance: currentFormData.ambulance,
        animals: currentFormData.animals,
        smoking: currentFormData.smoking,
        cargo: currentFormData.cargo,
      }));

      setCurrentPage(prev => prev + 1);
    }
  }

  const onFinish = (values: IGetEmptyLegListFormData) => {
    metricsGoals.searchEmptyLegs();

    setCurrentFormData({
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
      departureDate: values.departureDate,
      dates: values.dates,
      pax: values.pax,
      planeCategoryIds: values.planeCategoryIds,
      planeIsNotOlderDate: values.planeIsNotOlderDate,
      capacity: values.capacity,
      ambulance: values.ambulance,
      animals: values.animals,
      smoking: values.smoking,
      cargo: values.cargo,
    });

    const advancedFiltersCount = [
      values.planeCategoryIds,
      values.planeIsNotOlderDate,
      values.capacity,
      values.ambulance,
      values.animals,
      values.smoking,
      values.cargo
    ].filter(v => {
      // For planeCategoryIds
      if (Array.isArray(v))
        return v.length > 0;
      return !!v;
    }).length;

    setAdvancedSearchAppliedCount(advancedFiltersCount);

    setSelectedEmptyLegs([]);

    dispatch(getFreighterEmptyLegs({
      page: InitialPage,
      airportFromId: values.airportFromId,
      airportToId: values.airportToId,
      departureDate: values.departureDate?.toDate(),
      fromDate: values.dates ? values.dates[0].toDate() : undefined,
      toDate: values.dates ? values.dates[1].toDate() : undefined,
      pax: values.pax,
      planeCategoryIds: values.planeCategoryIds,
      planeIsNotOlderDate: values.planeIsNotOlderDate?.toDate(),
      capacity: values.capacity,
      ambulance: values.ambulance,
      animals: values.animals,
      smoking: values.smoking,
      cargo: values.cargo,
    }));

    setIsFirstSearchInitialized(true);
    setCurrentPage(InitialPage)
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const dateTypeCheckbox = <Checkbox checked={isDateRangeActive} onChange={() => setIsDateRangeActive(prev => !prev)}>
    {intl.formatMessage(CommonFormMessages.chooseDateRangeCheckbox)}
  </Checkbox>

  const initialValues: Partial<IGetEmptyLegListFormData> = {
    airportFromId: undefined,
    airportToId: undefined,
    departureDate: undefined,
    dates: undefined,
    pax: undefined,
    planeCategoryIds: undefined,
    capacity: undefined,
    planeIsNotOlderDate: undefined,
    ambulance: undefined,
    animals: undefined,
    smoking: undefined,
    cargo: undefined,
  }

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(SearchPageMessages.title)} width={'max'} isContainerCard={true}>
        <Typography>{intl.formatMessage(SearchPageMessages.description)}</Typography>

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout={'vertical'}
          initialValues={initialValues}
        >
          <Row gutter={8}>
            <Col span={isMobile ? 24 : 7}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
                name={'airportFromId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 24 : 7}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
                name={'airportToId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchAirports}
                  placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                  showSearch={true}
                />
              </CustomFieldInput>
            </Col>
            <Col span={isMobile ? 24 : 7}>
              {!isDateRangeActive && <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.departureDateLabel)}
                name={'departureDate'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
                extra={dateTypeCheckbox}
              >
                <CustomDatePicker
                  showTime={true}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={intl.formatMessage(CommonFormMessages.departureDatePlaceholder)}
                  disabledDate={isDateLessTanOneMinuteAgo}
                  inputReadOnly={true} placement={"bottomLeft"}
                  getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                />
              </CustomFieldInput>}

              {isDateRangeActive && <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.departureDateLabel)}
                name={'dates'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
                extra={dateTypeCheckbox}
              >
                <CustomDatePicker.RangePicker showTime format={"DD.MM.YYYY HH:mm"}
                                              disabledDate={isDateLessTanOneMinuteAgo}
                                              placeholder={[intl.formatMessage(CommonUnitMessages.from), intl.formatMessage(CommonUnitMessages.to)]}
                                              inputReadOnly={true} placement={"bottomLeft"}
                                              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                />
              </CustomFieldInput>}
            </Col>
            <Col span={isMobile ? 24 : 3}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.paxLabel)}
                name={'pax'}
                rules={paxRules}>
                <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                   style={{width: "100%"}}/>
              </CustomFieldInput>
            </Col>
          </Row>

          {isAdvancedSearchOpen && <>
            {!isMobile && <Hr style={{marginBottom: 16}}/>}
            <Row gutter={8}>
              <Col span={isMobile ? 24 : 6}>
                <CustomFieldInput
                  label={intl.formatMessage(CommonFormMessages.planeCategoryLabel)}
                  name={'planeCategoryIds'}
                >
                  <DebounceSelect
                    fetchOptions={fetchPlaneCategories}
                    placeholder={intl.formatMessage(CommonFormMessages.planeCategoryPlaceholder)}
                    mode={'multiple'}
                  />
                </CustomFieldInput>
              </Col>
              <Col span={isMobile ? 12 : 4}>
                <CustomFieldInput
                  label={intl.formatMessage(CommonFormMessages.planeIsNotOlderDateLabel)}
                  name={'planeIsNotOlderDate'}
                >
                  <CustomDatePicker picker={"year"}
                                    placeholder={intl.formatMessage(CommonFormMessages.planeIsNotOlderDatePlaceholder)}
                                    inputReadOnly={true} placement={"bottomLeft"}
                                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                  />
                </CustomFieldInput>
              </Col>
              <Col span={isMobile ? 12 : 4}>
                <CustomFieldInput
                  label={intl.formatMessage(CommonFormMessages.capacityLabel)}
                  name={'capacity'}
                  rules={[
                    {
                      min: 1,
                      type: "number",
                      message: "Минимум 1 место"
                    },
                  ]}>
                  <CustomInputNumber controls={true} prefix={intl.formatMessage(CommonUnitMessages.to)}
                                     placeholder={intl.formatMessage(CommonFormMessages.capacityPlaceholder)}
                                     style={{width: "100%"}}/>
                </CustomFieldInput>
              </Col>
            </Row>
            <Row gutter={isMobile ? 0 : 20}>
              <Col span={isMobile ? 24 : undefined}>
                <CustomFieldInput
                  name={'ambulance'}
                  valuePropName={'checked'}
                  style={{marginBottom: 0}}
                >
                  <Checkbox>{intl.formatMessage(CommonFormMessages.ambulanceCheckbox)}</Checkbox>
                </CustomFieldInput>
              </Col>
              <Col span={isMobile ? 24 : undefined}>
                <CustomFieldInput
                  name={'animals'}
                  valuePropName={'checked'}
                  style={{marginBottom: 0}}
                >
                  <Checkbox>{intl.formatMessage(CommonFormMessages.animalsCheckbox)}</Checkbox>
                </CustomFieldInput>
              </Col>
              <Col span={isMobile ? 24 : undefined}>
                <CustomFieldInput
                  name={'smoking'}
                  valuePropName={'checked'}
                  style={{marginBottom: 0}}
                >
                  <Checkbox>{intl.formatMessage(CommonFormMessages.smokingCheckbox)}</Checkbox>
                </CustomFieldInput>
              </Col>
              <Col span={isMobile ? 24 : undefined}>
                <CustomFieldInput
                  name={'cargo'}
                  valuePropName={'checked'}
                >
                  <Checkbox>{intl.formatMessage(CommonFormMessages.cargoCheckbox)}</Checkbox>
                </CustomFieldInput>
              </Col>
            </Row>
          </>}

          <Row justify={'end'} gutter={[16, 10]} style={{marginTop: 8}}>
            {advancedSearchAppliedCount > 0 &&
              <Col span={isMobile ? 24 : undefined}>
                <CustomFieldInput style={{marginBottom: 0}}>
                  <CustomButton
                    danger={true}
                    onClick={() => {
                      form.resetFields(["planeCategoryIds",
                        "planeIsNotOlderDate",
                        "capacity",
                        "ambulance",
                        "animals",
                        "smoking",
                        "cargo"]);
                      setIsAdvancedSearchOpen(false);
                    }}
                    htmlType={"submit"}
                    style={{width: isMobile ? "100%" : "auto"}}
                  >
                    {intl.formatMessage(CommonButtonMessages.resetFilters)}
                  </CustomButton>
                </CustomFieldInput>
              </Col>
            }

            <Col span={isMobile ? 24 : undefined}>
              <CustomFieldInput style={{marginBottom: 0}}>
                <Badge count={advancedSearchAppliedCount}>
                  <CustomButton
                    onClick={() => setIsAdvancedSearchOpen(prev => !prev)}
                    style={{width: isMobile ? "calc(100vw - 52px)" : "auto"}}
                  >
                    {isAdvancedSearchOpen ? "-" : "+"} {intl.formatMessage(SearchPageMessages.advancedSearch)}
                  </CustomButton>
                </Badge>
              </CustomFieldInput>
            </Col>

            <Col span={isMobile ? 24 : undefined}>
              <CustomFieldInput style={{marginBottom: 0}}>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{width: isMobile ? "100%" : "auto"}}
                >
                  {intl.formatMessage(CommonButtonMessages.find)}
                </CustomButton>
              </CustomFieldInput>
            </Col>
          </Row>
        </Form>
      </CustomCard>

      {isFirstSearchInitialized &&
        <CustomCard
          title={`${intl.formatMessage(SearchPageMessages.searchedFlights)} (${totalCount})`}
          width={'max'}
          extra={
            !isMobile && <CustomButton type={'primary'} disabled={selectedEmptyLegs.length < 1}
                                       onClick={() => setIsCreateOrderDrawerOpen(true)}>
              {intl.formatMessage(SearchPageMessages.createRequest)} ({selectedEmptyLegs.length})
            </CustomButton>
          }
        >
          {items.length === 0
            ? (isLoading ? <Loader/> : <CustomResult title={'Не найдено'}/>)
            : items.map((searchedEmptyLeg) => {
              return <SearchedEmptyLegCard
                key={searchedEmptyLeg.emptyLegId}
                searchedEmptyLeg={searchedEmptyLeg}
                selectedEmptyLegs={selectedEmptyLegs}
                setSelectedEmptyLegs={setSelectedEmptyLegs}
                setIsCreateOrderDrawerOpen={setIsCreateOrderDrawerOpen}
              />
            })
          }

          {items.length > 0 && totalCount > items.length &&
            <CustomButton
              loading={isLoading}
              type={'primary'}
              onClick={loadMore}
            >
              {intl.formatMessage(CommonFormMessages.showMore)}
            </CustomButton>}
        </CustomCard>
      }

      {selectedEmptyLegs.length > 0 && isMobile && <CreateRequestMobileFooter>
        <CustomButton type={'primary'} onClick={() => setIsCreateOrderDrawerOpen(true)}>
          {intl.formatMessage(SearchPageMessages.createRequest)} ({selectedEmptyLegs.length})
        </CustomButton>
      </CreateRequestMobileFooter>}

      {isCreateOrderDrawerOpen && currentFormData &&
        <CreateOrderDrawer orderRouteDetails={currentFormData} selectedEmptyLegs={selectedEmptyLegs}
                           setSelectedEmptyLegs={setSelectedEmptyLegs}
                           isOpen={isCreateOrderDrawerOpen} setIsOpen={setIsCreateOrderDrawerOpen}/>}
    </PageWrapper>
  );
};

