import React, {FC, useEffect, useState} from 'react';
import {
  IChartererOrder,
} from "../../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import styled from "styled-components";
import {CustomPanel} from "../../../../../../components/UI/AntCustom/CustomPanel";
import {
  defaultCharterOfferStatusesInfo, generateCharterOffersStatusesInfo,
  ICharterOfferStatusesInfo,
} from "../../../../../../components/businessCommon/respondsInfo/generateRespondInfo";
import {
  CharterOffersInfoBlock,
} from "../../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";
import {OrderCharterRespondCard} from "./OrderCharterRespondCard";
import {OrderHeader} from "../OrderHeader";
import {CustomCollapse} from "../../../../../../components/UI/AntCustom/CustomCollapse";
import {defineMessages, useIntl} from "react-intl";
import {useAppSelector} from "../../../../../../store/store";
import {CardFieldTitle} from "../../../../../../components/UI/CardFieldTitle/CardFieldTItle";
import {NotificationDot} from "../../../../../../components/notifications/components/NotificationDot";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";


const RespondsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(351px, 1fr));
  gap: 10px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
    gap: 8px;
  }
`;


const OrderRouteCharterCardMessages = defineMessages({
  responds: {
    id: "orderRouteCharterCard.responds",
    defaultMessage: "Responds",
  },
  noResponds: {
    id: "orderRouteCharterCard.noResponds",
    defaultMessage: "Pending",
  },
})

interface IOrderRouteCharterCard {
  order: IChartererOrder;
}

export const OrderCharterCard: FC<IOrderRouteCharterCard> = ({order}) => {
  const intl = useIntl();
  const [charterOfferStatusesInfo, setCharterOfferStatusesInfo] = useState<ICharterOfferStatusesInfo>(defaultCharterOfferStatusesInfo);

  const hasUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)
    .some(n => n.metadata.orderId === order.id)

  useEffect(() => {
    const newStatuses = generateCharterOffersStatusesInfo(order.nestedOrderRequests
      .map(orderRequest => orderRequest.status)
    );
    setCharterOfferStatusesInfo(newStatuses);
  }, [...order.nestedOrderRequests.map(orderRequest => orderRequest.status)])

  const respondsHeader =
    <CardFieldTitle>
      <div>
        {order.nestedOrderRequests.length > 0
          ? `${intl.formatMessage(OrderRouteCharterCardMessages.responds)} (${order.nestedOrderRequests.length})`
          : intl.formatMessage(OrderRouteCharterCardMessages.noResponds)}
      </div>
      {hasUnreadNotifications && <NotificationDot/>}
    </CardFieldTitle>

  return (
    <div>
      <OrderHeader order={order}/>
      <CustomCollapse expandIconPosition={'end'}>
        <CustomPanel
          key={order.id}
          header={respondsHeader}
          extra={<CharterOffersInfoBlock charterOfferStatusesInfo={charterOfferStatusesInfo}/>}
          collapsible={order.nestedOrderRequests.length === 0 ? "disabled" : undefined}
        >
          <RespondsWrapper>
            {order.nestedOrderRequests.map(
              orderRequest => <OrderCharterRespondCard key={orderRequest.orderRequestId}
                                                       order={order}
                                                       orderRequest={orderRequest}/>
            )}
          </RespondsWrapper>
        </CustomPanel>
      </CustomCollapse>
    </div>
  );
};
