import React, {FC} from 'react';
import {ReactComponent as ClockIcon} from "../../../../media/icons/clock.svg";
import {
  IChartererEmptyLegFromOrder,
  OrderTypeEnum,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {formatDate} from "../../../../utils/helpers/dateHelpers/dateHelpers";
import {FlyRoute} from "../../flyRoute/FlyRoute";
import {
  FlightDrawerDetailsHeader,
  FlightDrawerDetailsTitle,
  FlightDrawerDetailsTitleWrapper,
  FlightDrawerDetailsUpdatedTime,
  FlightDrawerDetailsWrapper
} from "./FlightDrawerDetailsStyledComponents";
import {useAppSelector} from "../../../../store/store";
import {defineMessages, useIntl} from "react-intl";
import {OrderTypeShield} from "../../orderType/OrderTypeShield";


const FlightDrawerOrderRouteEmptyLegDetailsMessages = defineMessages({
  price: {
    id: "flightDrawerOrderRouteEmptyLegDetails.price",
    defaultMessage: "Price",
  },
  flyDuration: {
    id: "flightDrawerOrderRouteEmptyLegDetails.flyDuration",
    defaultMessage: "Flight duration",
  },
  title: {
    id: "flightDrawerOrderRouteEmptyLegDetails.title",
    defaultMessage: "Route details",
  },
  updated: {
    id: "flightDrawerOrderRouteEmptyLegDetails.updated",
    defaultMessage: "Updated",
  },
})

interface IFlightDrawerOrderRouteEmptyLegDetails {
  emptyLeg: IChartererEmptyLegFromOrder;
}

export const FlightDrawerOrderRouteEmptyLegDetails: FC<IFlightDrawerOrderRouteEmptyLegDetails> = ({
                                                                                                    emptyLeg,
                                                                                                  }) => {
  const intl = useIntl();

  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);

  return (
    <FlightDrawerDetailsWrapper>
      <FlightDrawerDetailsHeader>
        <FlightDrawerDetailsTitleWrapper>
          <FlightDrawerDetailsTitle>{intl.formatMessage(FlightDrawerOrderRouteEmptyLegDetailsMessages.title)}</FlightDrawerDetailsTitle>
          {emptyLeg.dateModified && <FlightDrawerDetailsUpdatedTime>
            <ClockIcon/>
            <div>{intl.formatMessage(FlightDrawerOrderRouteEmptyLegDetailsMessages.updated)}
              <span>{formatDate(emptyLeg.dateModified, offset, "datetime_short_year")}</span></div>
          </FlightDrawerDetailsUpdatedTime>}
        </FlightDrawerDetailsTitleWrapper>
        <OrderTypeShield type={OrderTypeEnum.EMPTY_LEG} styleType={'fill'} />
      </FlightDrawerDetailsHeader>

      <FlyRoute airportFrom={emptyLeg.airportFrom}
                airportTo={emptyLeg.airportTo}
                dateFrom={emptyLeg.departureDate}
                dateTo={emptyLeg.arrivalDate}
                availableFromDate={emptyLeg.availableFromDate}
                availableToDate={emptyLeg.availableToDate}
                flyHoursDuration={emptyLeg.flyHours}
                flyMinutesDuration={emptyLeg.flyMinutes}
                distance={emptyLeg.flyDistance}
                pax={emptyLeg.plane.maxPassengers}
      />
    </FlightDrawerDetailsWrapper>
  );
};
