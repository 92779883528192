import React from "react";
import {IPasswordRegexHelper} from "./regexTypes";

//TODO localization
export const PasswordRegex: IPasswordRegexHelper = {
  pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
  error: (title: string, rules: string[]) => {
    return <div style={{width: "inherit"}}>
      <span>{title}</span>
      <ul>
        {rules.map((rule, idx) => <li key={idx}>{rule}</li>)}
      </ul>
    </div>
  }
}