import React from 'react';
import styled from "styled-components";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import Button from "antd/es/button/button";
import {SubscriptionsCards} from "./SubscriptionCards";
import {defineMessages, useIntl} from "react-intl";

const ManagingSubscriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  padding: 64px 60px 0 60px;
  width: 100%;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 64px 15px 20px 15px;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    row-gap: 0;
    padding: 30px 15px 20px 15px;
  }
`

const Title = styled.div`
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;

  div {
    max-width: 730px;
    max-height: 52px;
    @media (max-width: ${mediaScreenSizes.mobile}) {
      max-height: max-content;
    }
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding-bottom: 20px;
  }
`;

const SubscriptionsCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  opacity: 0.5;
  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const SpecialOffer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #FAFAFA;
  width: 100%;
  padding: 41px;
  opacity: 0.5;
  @media (max-width: ${mediaScreenSizes.mobile}) {
    margin-top: 20px;
    flex-direction: column;
    row-gap: 10px;
    text-align: center;
  }
`;


const PricePageMessages = defineMessages({
  title: {
    id: "pricePage.title",
    defaultMessage: "Platform and all types of subscriptions are totally free till 2023. Thank you for helping us develop our service!"
  },
  personalOfferTitle: {
    id: "pricePage.personalOfferTitle",
    defaultMessage: "Get a personal offer"
  },
  personalOfferAction: {
    id: "pricePage.personalOfferAction",
    defaultMessage: "Get"
  },
  card1: {
    id: "pricePage.card1",
    defaultMessage: "Start - 1 month"
  },
  card2: {
    id: "pricePage.card2",
    defaultMessage: "Medium – 3 months"
  },
  card3: {
    id: "pricePage.card3",
    defaultMessage: "Pro – 6 months"
  },
  have: {
    id: "pricePage.have",
    defaultMessage: "Yes"
  },
  doesNotHave: {
    id: "pricePage.doesNotHave",
    defaultMessage: "No"
  },
})

export const PricePage = () => {
  const intl = useIntl();

  const managingCards = [
    {
      title: intl.formatMessage(PricePageMessages.card1),
      personalSalesValue: intl.formatMessage(PricePageMessages.doesNotHave),
      supportServiceValue: intl.formatMessage(PricePageMessages.have),
    },
    {
      title: intl.formatMessage(PricePageMessages.card2),
      personalSalesValue: intl.formatMessage(PricePageMessages.have),
      supportServiceValue: intl.formatMessage(PricePageMessages.have),
      benefitInterest: '20%',
    },
    {
      title: intl.formatMessage(PricePageMessages.card3),
      personalSalesValue: intl.formatMessage(PricePageMessages.have),
      supportServiceValue: intl.formatMessage(PricePageMessages.have),
      benefitInterest: '40%',
    }
  ];

  return (
    <ManagingSubscriptionsWrapper>
      <Title>
        <div>
          {intl.formatMessage(PricePageMessages.title)}
        </div>
      </Title>
      <SubscriptionsCardWrapper>
        {managingCards.map(el =>
          <SubscriptionsCards
            key={el.title}
            benefitInterest={el.benefitInterest}
            title={el.title}
            personalSalesValue={el.personalSalesValue}
            supportServiceValue={el.supportServiceValue}
          />
        )}
      </SubscriptionsCardWrapper>
      <SpecialOffer>
        <div>{intl.formatMessage(PricePageMessages.personalOfferTitle)}</div>
        <Button
          type={'primary'}
          disabled={true}
          style={{backgroundColor: '#1890FF', color: '#FFFFFF'}}
        >
          {intl.formatMessage(PricePageMessages.personalOfferAction)}
        </Button>
      </SpecialOffer>
    </ManagingSubscriptionsWrapper>
  );
};

