import {useAppSelector} from "../../../store/store";
import {IChat} from "../../../store/reducers/chatReducer/chatReducer";

export const useChat = (fromUserId: number, toUserId: number, orderId: number): IChat | undefined => {
  const chatList = useAppSelector(state => state.chat.chatList);
  return  chatList.find(c => {
    const searchedChatUserIdPair = [fromUserId, toUserId].sort()
    const chatUserIdPair = [c.fromUser.userId, c.toUser.userId].sort();

    return searchedChatUserIdPair[0] === chatUserIdPair[0]
      && searchedChatUserIdPair[1] === chatUserIdPair[1]
      && orderId === c.orderId;
  })
}