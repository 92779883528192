import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  archiveOrderRequest,
  changeOrderRequestWithdraw, getFreighterOrderRequests,
  makeCharterOffer,
  setArchiveOrderRequestResult,
  setChangeOrderRequestWithdrawResult, setFreighterOrderRequestsResult,
  setMakeCharterOfferResult,
} from "../../reducers/freighterRequestsReducer/freighterRequestsReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {API} from "../../../API/API";
import {
  BookingApiOrderRequestListRequest,
  BookingApiOrderRequestListResponse,
  BookingApiOrderRequestRequest,
  BookingApiOrderRequestResponse,
  CurrencyEntity,
  OrderRequestEntity, OrderRouteEntity, OrderRouteOrderRequestEntity,
  PageModel,
  PlaneEntity
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {
  IArchiveOrderRequestPayload,
  IChangeOrderRequestWithdrawPayload, IGetFreighterOrderRequestsPayload,
  IMakeCharterOfferPayload
} from "../../reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";
import {metricsGoals} from "../../../utils/constans/metrics/goals";


function* getFreighterOrderRequestsSaga({payload}: PayloadAction<IGetFreighterOrderRequestsPayload>) {
  const {filters} = payload;
  const getOrderRequestListReq = new BookingApiOrderRequestListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.MyRequests)
    )
    .setIsshowarchived(payload.isArchival)
    .setOrdertypesList(payload.orderTypes)
  if (filters.maxPax)
    getOrderRequestListReq.setMaxpax(filters.maxPax)
  if (filters.airportFromId)
    getOrderRequestListReq.setAirportfromid(filters.airportFromId)
  if (filters.airportToId)
    getOrderRequestListReq.setAirporttoid(filters.airportToId)
  if (filters.minPax)
    getOrderRequestListReq.setMinpax(filters.minPax)
  if (filters.companionsAllowed)
    getOrderRequestListReq.setCompanionsallowed(filters.companionsAllowed)
  if (filters.isEmptyLegUsable)
    getOrderRequestListReq.setIsemptylegusable(filters.isEmptyLegUsable)
  if (filters.planeCategoryIds)
    getOrderRequestListReq.setPlanecategoryidsList(filters.planeCategoryIds);
  if (payload.orderRequestIds)
    getOrderRequestListReq.setOrderrequestidsList(payload.orderRequestIds);

  try {
    const getOrderRequestListRes: BookingApiOrderRequestListResponse = yield call(API.orderRequestList, getOrderRequestListReq);

    yield put(setFreighterOrderRequestsResult({
      items: getOrderRequestListRes.getOrderrequestsList(),
      totalCount: getOrderRequestListRes.getTotalcount(),
    }));
  } catch {
  }
}


function* changeOrderRequestWithdrawSaga({payload}: PayloadAction<IChangeOrderRequestWithdrawPayload>) {
  const req = new BookingApiOrderRequestRequest()
    .setOrderrequest(
      new OrderRequestEntity()
        .setOrderrequestid(payload.orderRequestId)
        .setOrderrequeststatusid(payload.orderRequestStatus)
    );

  try {
    const res: BookingApiOrderRequestResponse = yield call(API.orderRequestUpdate, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setChangeOrderRequestWithdrawResult({
        item: res.getOrderrequest(),
      }));
    } else {
      yield put(setChangeOrderRequestWithdrawResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* archiveOrderRequestSaga({payload}: PayloadAction<IArchiveOrderRequestPayload>) {
  const req = new BookingApiOrderRequestRequest()
    .setOrderrequest(
      new OrderRequestEntity()
        .setOrderrequestid(payload.orderRequestId)
        .setArchived(true)
    );

  try {
    const res: BookingApiOrderRequestResponse = yield call(API.orderRequestArchive, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setArchiveOrderRequestResult({
        itemId: payload.orderRequestId,
      }));
    } else {
      yield put(setArchiveOrderRequestResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* makeCharterOfferSaga({payload}: PayloadAction<IMakeCharterOfferPayload>) {
  const {formData} = payload;

  const orderRouteOrderRequests: OrderRouteOrderRequestEntity[] = formData.routes.map(route => {
    return new OrderRouteOrderRequestEntity()
      .setOrderrequest(new OrderRequestEntity().setOrderrequestid(formData.orderRequestId))
      .setOrderroute(new OrderRouteEntity().setOrderrouteid(route.orderRoute.id))
      .setFlydistance(route.distance)
      .setFlyduration(route.flyHours * 60 + route.flyMinutes)
      .setTechnicalstopduration(route.techStopHours * 60 + route.techStopMinutes)
      .setTechnicalstopcount(1);
  })

  const orderRequest = new OrderRequestEntity()
    .setOrderrequestid(formData.orderRequestId)
    .setOrderrequeststatusid(OrderRequestStatusEnum.OFFERED)
    .setPlane(new PlaneEntity().setPlaneid(formData.planeId))
    .setPrice(formData.price)
    .setCurrency(new CurrencyEntity().setCurrencyid(formData.currencyId))
    .setOrderrouteorderrequestsList(orderRouteOrderRequests)

  orderRequest.setOrderrouteorderrequestsList(orderRouteOrderRequests);

  const req = new BookingApiOrderRequestRequest()
    .setOrderrequest(orderRequest)
    .setWelcomemessage(formData.welcomeMessage);

  try {
    const res: BookingApiOrderRequestResponse = yield call(API.orderRequestUpdate, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setMakeCharterOfferResult({
        item: res.getOrderrequest(),
      }));

      metricsGoals.orderRequestMakeOffer();
    } else {
      yield put(setMakeCharterOfferResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


export function* freighterRequestsSaga() {
  yield all([
    takeLatest(getFreighterOrderRequests.type, getFreighterOrderRequestsSaga),
    takeLatest(changeOrderRequestWithdraw.type, changeOrderRequestWithdrawSaga),
    takeLatest(archiveOrderRequest.type, archiveOrderRequestSaga),
    takeLatest(makeCharterOffer.type, makeCharterOfferSaga),
  ])
}