import {
  AirportEntity, PlaneCalculatorSettingCategoryEntity,
  PlaneCalculatorSettingEntity,
  PlaneCalculatorSettingSubCategoryEntity,
  PlaneCalculatorSettingTypeEntity,
  PlaneCategoryEntity,
  PlaneEntity,
  PlaneShortEntity,
  TimezoneEntity
} from "../../../proto/generated/api_entities_pb";
import {
  IAirport,
  IFlyHourCalculatorCategory, IFlyHourCalculatorSettingType,
  IPlane,
  IPlaneCategory,
  IPlaneShort
} from "./fleetReducerTypes";
import {UploadFile} from "antd/es/upload";
import {getNginxHost} from "../../../API/helpers/getProxyUrl";
import {parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {ITimezone} from "../userReducer/userReducerTypes";
import {DisplayTimezoneOffset} from "../../../utils/helpers/textDisplayHelpers/DisplayTimezoneOffset";
import {
  FlyHourPriceCalculatorSettingTypeEnum,
  FlyHourPriceDefaultCategoryEnum,
  FlyHourPriceDefaultSettingTypeEnum,
  FlyHourPriceDefaultSubcategoryEnum
} from "../../../pages/Lk/OperatorsOnly/Fleet/components/flyHourPriceCalculator/types/flyHourPriceSettingEnums";
import isNumeric from "antd/es/_util/isNumeric";
import {Logger} from "../../../utils/logger/Logger";


export const mapPlaneEntityToIPlane = (item: PlaneEntity): IPlane => {
  const planeType = item.getPlanetype();
  if (!planeType)
    throw new Error("Null PlaneTypeEntity in received PlaneEntity");

  const planeCategory = planeType.getPlanecategory();
  if (!planeCategory)
    throw new Error("Null PlaneCategoryEntity in received PlaneEntity -> PlaneTypeEntity");

  const baseAirport = item.getBaseairport();
  if (!baseAirport)
    throw new Error("Null AirportEntity in received PlaneEntity");

  const insurance = item.getInsurance();
  if (!insurance)
    throw new Error("Null insurance in received PlaneEntity");

  const insuranceFile: UploadFile = {
    uid: insurance.getFileid(),
    name: insurance.getName(),
    fileName: insurance.getName(),
    url: insurance.getFilepath(),
  }

  const certificate = item.getCertificate();
  if (!certificate)
    throw new Error("Null certificate in received PlaneEntity");

  const certificateFile: UploadFile = {
    uid: certificate.getFileid(),
    name: certificate.getName(),
    fileName: certificate.getName(),
    url: certificate.getFilepath(),
  }

  const currency = item.getCurrency();
  if (!currency)
    throw new Error("Null CurrencyEntity (currency) in received PlaneEntity");

  const calculatorCategories = mapPlaneCalculatorSettingEntityListToIFlyHourCalculatorCategoryList(item.getPlanecalculatorsettingsList());

  return {
    id: item.getPlaneid(),
    planeType: {
      id: planeType.getPlanetypeid(),
      name: planeType.getName(),
      planeCategory: {
        id: planeCategory.getPlanecategoryid(),
        name: planeCategory.getName(),
      }
    },
    baseAirport: mapAirportEntityToIAirport(baseAirport),
    registrationNumber: item.getRegistrationnumber(),
    manufactureDate: parseStringAsUTCDate(item.getManufacturedate()),
    renovationDate: parseStringAsUTCDate(item.getRenovationdate()),
    maxPassengers: item.getMaxpassengers(),
    maxDistance: item.getMaxdistance(),
    isAnimals: item.getIsanimals(),
    isCargo: item.getIscargo(),
    isSmoking: item.getIssmoking(),
    isAmbulance: item.getIsambulance(),
    description: item.getDescription(),
    imageFiles: item.getImagesList().map(f => {
      return {
        uid: f.getFileid(),
        name: f.getName(),
        fileName: f.getName(),
        //TODO refactor
        url: getNginxHost() + f.getFilepath(),
      }
    }),
    insuranceFile: insuranceFile,
    certificateFile: certificateFile,
    flyHourCurrency: {
      id: currency.getCurrencyid(),
      name: currency.getName(),
    },
    flyHourPrice: item.getFlyhourprice(),
    calculatorCategories: calculatorCategories,
  }
}


export const mapPlaneShortEntityToIPlaneShort = (item: PlaneShortEntity): IPlaneShort => {
  return {
    id: item.getPlaneid(),
    registrationNumber: item.getRegistrationnumber(),
  }
}


export const mapPlaneCategoryEntityToIPlaneCategory = (item: PlaneCategoryEntity): IPlaneCategory => {
  return {
    id: item.getPlanecategoryid(),
    name: item.getName(),
  }
}


export const mapPlaneCategoryIdToIPlaneCategory = (item: number): PlaneCategoryEntity => {
  return new PlaneCategoryEntity()
    .setPlanecategoryid(item)
}


export const mapAirportEntityToIAirport = (item: AirportEntity): IAirport => {
  const timezone = item.getTimezone();
  if (!timezone)
    throw new Error("Null TimezoneEntity (timezone) in received AirportEntity");

  return {
    id: item.getAirportid(),
    name: item.getName(),
    icaoCode: item.getIcaocode(),
    iataCode: item.getIatacode(),
    countryName: item.getCountryname(),
    timezone: mapTimeZoneEntityToITimezone(timezone),
  }
}


export const mapTimeZoneEntityToITimezone = (item: TimezoneEntity): ITimezone => {
  const timezoneLabel = `${item.getName()} ${DisplayTimezoneOffset(item.getOffset())}`;

  return {
    id: item.getTimezoneid(),
    name: timezoneLabel,
    offset: item.getOffset(),
  }
}


export const mapPlaneCalculatorSettingEntityListToIFlyHourCalculatorCategoryList = (planeCalculatorSettingEntities: PlaneCalculatorSettingEntity[]): IFlyHourCalculatorCategory[] => {
  let categories: IFlyHourCalculatorCategory[] = []

  planeCalculatorSettingEntities.forEach(setting => {
    const settingType = setting.getPlanecalculatorsettingtype();
    if (!settingType)
      throw new Error("Null PlaneCalculatorSettingTypeEntity (planeCalculatorSettingType) in received PlaneEntity -> SettingEntity");

    const subcategory = settingType.getPlanecalculatorsettingsubcategory();
    if (!subcategory)
      throw new Error("Null PlaneCalculatorSettingSubcategoryEntity (planeCalculatorSettingSubcategory) in received PlaneEntity -> SettingEntity -> PlaneCalculatorSettingTypeEntity");

    const category = subcategory.getPlanecalculatorsettingcategory();
    if (!category)
      throw new Error("Null PlaneCalculatorSettingCategoryEntity (planeCalculatorSettingCategory) in received PlaneEntity -> SettingEntity -> PlaneCalculatorSettingTypeEntity -> PlaneCalculatorSettingSubcategoryEntity");

    const noSuchCategory = categories.every(c => c.keyName !== category.getName());
    if (noSuchCategory) {
      categories.push({
        id: category.getPlanecalculatorsettingcategoryid(),
        keyName: category.getName(),
        label: category.getName(),
        isDefault: category.getIsdefault(),
        subcategories: []
      });
    }

    const noSuchSubcategory = categories.map(c => c.subcategories).flat().every(sc => sc.keyName !== subcategory.getName());
    if (noSuchSubcategory) {
      categories = categories.map(c => {
        if (c.keyName !== category.getName())
          return c;

        const newSubcategory = {
          id: subcategory.getPlanecalculatorsettingsubcategoryid(),
          keyName: subcategory.getName(),
          label: subcategory.getName(),
          isDefault: subcategory.getIsdefault(),
          settingTypes: [],
        }
        return {...c, subcategories: [...c.subcategories, newSubcategory]}
      });
    }

    const noSuchSettingType = categories.map(c => c.subcategories).flat().map(sc => sc.settingTypes).flat().every(st => st.keyName !== settingType.getName());
    if (noSuchSettingType) {
      categories = categories.map(c => {
        return {
          ...c, subcategories: [...c.subcategories.map(sc => {
            if (sc.keyName !== subcategory.getName())
              return sc;

            const isValueNumeric = isNumeric(setting.getValue());
            if (!isValueNumeric)
              Logger.error(`Got not numeric value ${setting.getValue()} for key: ${settingType.getName()}`)

            const newSettingType: IFlyHourCalculatorSettingType = {
              id: settingType.getPlanecalculatorsettingstypeid(),
              keyName: settingType.getName(),
              label: settingType.getName(),
              isEnabled: settingType.getIsenable(),
              isDefault: settingType.getIsdefault(),
              setting: {
                id: setting.getPlanecalculatorsettingid(),
                value: isValueNumeric ? Number(setting.getValue()) : 0,
                specificType: setting.getType() !== 0 ? setting.getType() : undefined,
              },
              type: settingType.getType(),
            }
            return {...sc, settingTypes: [...sc.settingTypes, newSettingType]};
          })]
        }
      });
    }
  });

  return categories;
}


export const mapIFlyHourCalculatorCategoriesToPlaneCalculatorSettingEntities = (flyHoursCount: number, categories: IFlyHourCalculatorCategory[]) => {
  const settingEntities: PlaneCalculatorSettingEntity[] = [];

  settingEntities.push(new PlaneCalculatorSettingEntity()
    .setValue(flyHoursCount.toString())
    .setPlanecalculatorsettingtype(new PlaneCalculatorSettingTypeEntity()
      .setName(FlyHourPriceDefaultSettingTypeEnum.PARAMETER_BASE_FLY_HOURS)
      .setIsenable(true)
      .setIsdefault(true)
      .setType(FlyHourPriceCalculatorSettingTypeEnum.FLAT)
      .setPlanecalculatorsettingsubcategory(new PlaneCalculatorSettingSubCategoryEntity()
        .setName(FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_BASE)
        .setIsdefault(true)
        .setPlanecalculatorsettingcategory(new PlaneCalculatorSettingCategoryEntity()
          .setName(FlyHourPriceDefaultCategoryEnum.CATEGORY_BASE)
          .setIsdefault(true)
        )
      )
    ))

  categories.forEach(c => {
    c.subcategories.forEach(sc => {
      sc.settingTypes.forEach(st => {
        if (st.setting.value) {
          const setting = new PlaneCalculatorSettingEntity()
            .setValue(st.setting.value.toString())
            .setPlanecalculatorsettingtype(new PlaneCalculatorSettingTypeEntity()
              .setName(st.keyName)
              .setIsenable(st.isEnabled)
              .setIsdefault(st.isDefault)
              .setType(st.type)
              .setPlanecalculatorsettingsubcategory(new PlaneCalculatorSettingSubCategoryEntity()
                .setName(sc.keyName)
                .setIsdefault(sc.isDefault)
                .setPlanecalculatorsettingcategory(new PlaneCalculatorSettingCategoryEntity()
                  .setName(c.keyName)
                  .setIsdefault(c.isDefault)
                )
              )
            )

          if (st.setting.specificType)
            setting.setType(st.setting.specificType)
          settingEntities.push(setting)
        }
      })
    })
  })

  return settingEntities;
}