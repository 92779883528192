import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult,
} from "../highOrderReducers/commonReducer";
import {IAgencyInitialState, IGetAgencyListPayload, ISetAgencyListResultPayload} from "./agencyReducerTypes";
import {mapCompanyEntityToIAgency} from "./agencyMappers";

const agencyInitialState: IAgencyInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
}

const agencySlice = createSlice({
  name: 'agency',
  initialState: agencyInitialState,
  reducers: {
    getAgencyList(state, {payload}: PayloadAction<IGetAgencyListPayload>) {
      initCommonGetList(state, payload);
    },
    clearAgencyList(state) {
      initCommonClearList(state);
    },
    setAgencyListResult(state, {payload}: PayloadAction<ISetAgencyListResultPayload>) {
      initCommonSetListResult(state, payload, mapCompanyEntityToIAgency);
    },
  },
});

export const {
  getAgencyList,
  clearAgencyList,
  setAgencyListResult,
} = agencySlice.actions;
export default agencySlice.reducer;