import {defineMessages} from "react-intl";
import {useEnumIntl} from "../../../intl/helpers/useEnumIntl";


// *** DO NOT CHANGE WITHOUT BACKEND DEVELOPERS AGREEMENT ***
export enum OrderRequestStatusEnum {
  NOT_OFFERED = 1,
  OFFERED = 2,
  DECLINED = 3,
}

export const OrderRequestStatusColorMap = {
  [OrderRequestStatusEnum.NOT_OFFERED]: "#CDDDE6",
  [OrderRequestStatusEnum.OFFERED]: "#D6E6CD",
  [OrderRequestStatusEnum.DECLINED]: "#E7C4C4",
}

const OrderRequestStatusNameMessages = defineMessages({
  notOffered: {
    id: "statuses.orderRequestStatus.notOffered",
    defaultMessage: "Not offered",
  },
  offered: {
    id: "statuses.orderRequestStatus.offered",
    defaultMessage: "Offered",
  },
  declined: {
    id: "statuses.orderRequestStatus.declined",
    defaultMessage: "Declined",
  },
})

export const useOrderRequestStatusName = (status: OrderRequestStatusEnum) => {
  return useEnumIntl(status, {
    [OrderRequestStatusEnum.NOT_OFFERED]: OrderRequestStatusNameMessages.notOffered,
    [OrderRequestStatusEnum.OFFERED]: OrderRequestStatusNameMessages.offered,
    [OrderRequestStatusEnum.DECLINED]: OrderRequestStatusNameMessages.declined,
  });
}
