import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  ICompanyInitialState,
  IEditCompanyPayload, ILoadCompanyAvatarPayload, ILoadCompanyCertificatesPayload,
  ISetCompanyResultPayload,
  ISetEditCompanyResultPayload, ISetLoadCompanyAvatarResultPayload, ISetLoadCompanyCertificatesResultPayload
} from "./companyReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearEditOne,
  initCommonClearOne,
  initCommonEditOne,
  initCommonGetOne, initCommonSetEditOneResult,
  initCommonSetOneResult
} from "../highOrderReducers/commonReducer";
import {mapCompanyEntityToICompany} from "./companyMappers";
import {generateEmptyCompany} from "./companyReducerEmptyData";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";


const companyInitialState: ICompanyInitialState = {
  oneItemObject: {
    item: generateEmptyCompany(),
    isLoading: true,
  },
  edit: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  loadCertificates: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  loadCompanyAvatar: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const companySlice = createSlice({
  name: 'company',
  initialState: companyInitialState,
  reducers: {
    getCompany(state) {
      initCommonGetOne(state);
    },
    clearCompany(state) {
      initCommonClearOne(state, generateEmptyCompany);
    },
    setCompanyResult(state, {payload}: PayloadAction<ISetCompanyResultPayload>) {
      initCommonSetOneResult(state, payload, mapCompanyEntityToICompany);
    },

    editCompany(state, {payload}: PayloadAction<IEditCompanyPayload>) {
      initCommonEditOne(state);
    },
    clearEditCompany(state) {
      initCommonClearEditOne(state);
    },
    setEditCompanyResult(state, {payload}: PayloadAction<ISetEditCompanyResultPayload>) {
      initCommonSetEditOneResult(state, payload, mapCompanyEntityToICompany);
    },

    loadCertificates(state, {payload}: PayloadAction<ILoadCompanyCertificatesPayload>) {
      initCommonAction(state, nameof<ICompanyInitialState>("loadCertificates"));
    },
    clearLoadCertificates(state) {
      initCommonClearAction(state, nameof<ICompanyInitialState>("loadCertificates"));
    },
    setLoadCertificatesResult(state, {payload}: PayloadAction<ISetLoadCompanyCertificatesResultPayload>) {
      if (payload.company)
        state.oneItemObject.item = mapCompanyEntityToICompany(payload.company);
      initCommonActionResult(state, payload, nameof<ICompanyInitialState>("loadCertificates"));
    },

    loadCompanyAvatar(state, {payload}: PayloadAction<ILoadCompanyAvatarPayload>) {
      initCommonAction(state, nameof<ICompanyInitialState>("loadCompanyAvatar"));
    },
    clearLoadCompanyAvatar(state) {
      initCommonClearAction(state, nameof<ICompanyInitialState>("loadCompanyAvatar"));
    },
    setLoadCompanyAvatarResult(state, {payload}: PayloadAction<ISetLoadCompanyAvatarResultPayload>) {
      if (payload.company)
        state.oneItemObject.item = mapCompanyEntityToICompany(payload.company);
      initCommonActionResult(state, payload, nameof<ICompanyInitialState>("loadCompanyAvatar"));
    },
  },
});

export const {
  getCompany,
  clearCompany,
  setCompanyResult,

  editCompany,
  clearEditCompany,
  setEditCompanyResult,

  loadCertificates,
  clearLoadCertificates,
  setLoadCertificatesResult,

  loadCompanyAvatar,
  clearLoadCompanyAvatar,
  setLoadCompanyAvatarResult,

} = companySlice.actions;
export default companySlice.reducer;