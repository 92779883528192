import {
  IChartererOrderRequestFromOrder,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import React, {FC} from "react";
import {
  FlightDrawerFooterContent,
  FlightDrawerFooterContentWrapper,
  FlightDrawerFooterPrice,
  FlightDrawerFooterPriceLabel,
  FlightDrawerFooterPriceValue,
  FlightDrawerFooterStatus,
} from "./FlightDrawerFooterStyledComponents";
import {defineMessages, useIntl} from "react-intl";
import {useOrderRequestStatusName} from "../../../../utils/constans/statuses/orderRequestStatuses";
import {DisplayFlyPrice} from "../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";


const FlightDrawerOrderOrderRequestFooterMessages = defineMessages({
  price: {
    id: "flightDrawerOrderOrderRequestFooter.price",
    defaultMessage: "Price",
  }
})

interface IFlightDrawerOrderOrderRequestFooter {
  orderRequest: IChartererOrderRequestFromOrder;
}

export const FlightDrawerOrderOrderRequestFooter: FC<IFlightDrawerOrderOrderRequestFooter> = ({
                                                                                                orderRequest,
                                                                                              }) => {
  const intl = useIntl();
  const orderRequestStatusName = useOrderRequestStatusName(orderRequest.status);

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      <FlightDrawerFooterPrice>
        <FlightDrawerFooterPriceLabel>{intl.formatMessage(FlightDrawerOrderOrderRequestFooterMessages.price)}</FlightDrawerFooterPriceLabel>
        <FlightDrawerFooterPriceValue>{DisplayFlyPrice(orderRequest.price, orderRequest.currency)}</FlightDrawerFooterPriceValue>
      </FlightDrawerFooterPrice>

      <FlightDrawerFooterStatus status={orderRequest.status}>
        {orderRequestStatusName}
      </FlightDrawerFooterStatus>
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
};
