import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {RenderConfirmModal, renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {fetchOwnPlanes} from "../../../../../utils/helpers/selectDataFetchers/fetchOwnPlanes";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {fetchCurrencies} from "../../../../../utils/helpers/selectDataFetchers/fetchCurrencies";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomDrawer} from "../../../../../components/UI/AntCustom/CustomDrawer";
import styled from "styled-components";
import {CharterRouteDrawerElement} from "./CharterRouteDrawerElement";
import {CalculateSwitcher} from "../../../../../components/businessCommon/calculateButton/CalculateSwitcher";
import {CalculatePrice} from "../../../../../utils/helpers/formHelpers/CalculatePrice";
import {CharterRoutePriceCoefficients} from "./CharterRoutePriceCoefficients";
import {
  clearMakeCharterOffer,
  makeCharterOffer
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducer";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {
  IFreighterOrderRequest, IFreighterOrderRoute
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {RecursivePartial} from "../../../../../utils/helpers/tsHelpers/reqursivePartialType";
import {MakeOfferRouteItem} from "./MakeOfferRouteItem";
import TextArea from "antd/es/input/TextArea";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
`;

const ChartererRouteDrawerMessages = defineMessages({
  title: {
    id: "chartererRouteDrawer.title",
    defaultMessage: "Make offer to",
  },
  chooseAnAircraft: {
    id: "chartererRouteDrawer.chooseAnAircraft",
    defaultMessage: "Choose an aircraft",
  },
  flightDetails: {
    id: "chartererRouteDrawer.flightDetails",
    defaultMessage: "Flight details",
  },
  flightDuration: {
    id: "chartererRouteDrawer.flightDuration",
    defaultMessage: "Flight duration",
  },
  flightPrice: {
    id: "chartererRouteDrawer.flightPrice",
    defaultMessage: "Flight price",
  },
  requestDetails: {
    id: "chartererRouteDrawer.requestDetails",
    defaultMessage: "Request details",
  },
  warnFlyDuration: {
    id: "chartererRouteDrawer.warnFlyDuration",
    defaultMessage: "Flight duration and range have been filled out manually. Do you want to proceed?",
  },
  warnPrice: {
    id: "chartererRouteDrawer.warnPrice",
    defaultMessage: "Flight price and currency have been filled out manually. Do you want to request a charter?",
  },
  success: {
    id: "chartererRouteDrawer.success",
    defaultMessage: "Request has been successfully added to \"My offers\" section.",
  },
})

export interface ICharterRespondRouteItem {
  orderRouteOrderRequestId: number;
  orderRoute: IFreighterOrderRoute;
  flyHours: number;
  flyMinutes: number;
  distance: number;
  techStopHours: number;
  techStopMinutes: number;
}

export interface ICharterRespondFormData {
  orderRequestId: number;
  planeId: number;
  currencyId: number;
  price: number;
  welcomeMessage: string;
  routes: ICharterRespondRouteItem[];
}

interface IMakeOfferDrawer {
  orderRequest: IFreighterOrderRequest;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}


export const MakeOfferDrawer: FC<IMakeOfferDrawer> = ({orderRequest, isOpen, setIsOpen}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.freighterRequests.makeCharterOffer);
  const [isPriceAutoEnabled, setIsPriceAutoEnabled] = useState(false);
  const [isPriceAutoBlocked, setIsPriceAutoBlocked] = useState(false);
  const planeId: number | undefined = Form.useWatch(['planeId'], form);
  const price: number | undefined = Form.useWatch(['price'], form);
  const currencyId: number | undefined = Form.useWatch(['currencyId'], form);
  const routes: ICharterRespondRouteItem[] = Form.useWatch(['routes'], form);

  useEffect(() => {
    return () => {
      dispatch(clearMakeCharterOffer());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ChartererRouteDrawerMessages.success), () => {
          setIsOpen(false)
        });
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  useEffect(() => {
    const hasMissing = routes?.some(r => !r.flyHours && !r.flyMinutes);
    setIsPriceAutoBlocked(!planeId || hasMissing);
  }, [planeId, routes, price, currencyId])

  const turnOffPriceAuto = () => setIsPriceAutoEnabled(false);

  const onFinish = (values: ICharterRespondFormData) => {
    if (values.routes.some(r => r.flyHours === 0 && r.flyMinutes === 0)) {
      message.error(intl.formatMessage(CommonFormMessages.ruleIncorrectFlightTime));
      return;
    }

    const respondCharterCallback = () => dispatch(makeCharterOffer({
      formData: {
        ...values,
        orderRequestId: orderRequest.id,
      }
    }));

    if (!isPriceAutoEnabled)
      RenderConfirmModal(
        intl.formatMessage(ChartererRouteDrawerMessages.warnPrice),
        respondCharterCallback,
        intl);
    else
      respondCharterCallback();
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const onCalculatePrice = async () => {
    if (planeId) {
      const totalDuration = routes.reduce((acc, cur) => {
        return acc + cur.flyHours * 60 + cur.flyMinutes
      }, 0)

      const {
        price,
        currencyId,
        isAutoCalculationDisabled,
        isAutoCalculationApplied
      } = await CalculatePrice(planeId, totalDuration, intl);

      form.setFieldsValue({
        'price': price,
        'currencyId': currencyId,
      });

      setIsPriceAutoEnabled(isAutoCalculationApplied);
      setIsPriceAutoBlocked(isAutoCalculationDisabled);
    }
  }

  const initialValues: RecursivePartial<ICharterRespondFormData> = {
    planeId: undefined,
    currencyId: undefined,
    price: undefined,
    welcomeMessage: undefined,
    routes: orderRequest.orderRouteOrderRequests.map(orderRouteOrderRequest => {
      const {orderRoute} = orderRouteOrderRequest;

      return {
        orderRouteOrderRequestId: orderRouteOrderRequest.orderRouteOrderRequestId,
        orderRoute: orderRoute,
        flyHours: undefined,
        flyMinutes: undefined,
        distance: undefined,
        techStopHours: 0,
        techStopMinutes: 0,
      }
    })
  }

  return (
    <CustomDrawer
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      contentMinWidth={isMobile ? undefined : 546}
      contentMaxWidth={isMobile ? undefined : 546}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <DrawerContent>

          <CharterRouteDrawerElement style={{marginBottom: 16}}
                                     title={intl.formatMessage(ChartererRouteDrawerMessages.title) + " " + orderRequest.order.company.name}/>

          <CharterRouteDrawerElement title={intl.formatMessage(ChartererRouteDrawerMessages.chooseAnAircraft)}>
            <Col span={24}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.planeLabel)}
                name={'planeId'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <DebounceSelect
                  fetchOptions={fetchOwnPlanes}
                  placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
                  onChange={turnOffPriceAuto}
                />
              </CustomFieldInput>
            </Col>
          </CharterRouteDrawerElement>


          {planeId &&
            <>
              <CharterRouteDrawerElement title={intl.formatMessage(ChartererRouteDrawerMessages.flightDetails)}>
                <Form.List name="routes">
                  {(fields) => (
                    fields.map((field, index) => {
                        return <MakeOfferRouteItem key={field.key} field={field}
                                                   routeNumber={index + 1}
                                                   turnOffPriceAuto={() => setIsPriceAutoEnabled(false)}
                                                   setIsPriceAutoBlocked={setIsPriceAutoBlocked}
                        />
                      }
                    ))}
                </Form.List>
              </CharterRouteDrawerElement>

              <CharterRouteDrawerElement
                title={intl.formatMessage(ChartererRouteDrawerMessages.flightPrice)}
                extra={<CalculateSwitcher
                  disabled={isPriceAutoBlocked}
                  isAutoCalculationApplied={isPriceAutoEnabled}
                  setIsAutoCalculationApplied={setIsPriceAutoEnabled}
                  onClick={onCalculatePrice}
                  size={'small'}
                />}
              >
                <Row gutter={8}>
                  <Col span={18}>
                    <CustomFieldInput
                      name={'price'}
                      rules={[
                        {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                        {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMustBePositive)},
                        {
                          max: 1000000000,
                          type: "number",
                          message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)
                        }
                      ]}
                    >
                      <CustomInputNumber controls={false}
                                         placeholder={intl.formatMessage(CommonFormMessages.pricePlaceholder)}
                                         style={{width: '100%', color: "#000"}}
                                         onChange={turnOffPriceAuto}/>
                    </CustomFieldInput>
                  </Col>

                  <Col span={6}>
                    <CustomFieldInput
                      name={'currencyId'}
                      rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
                    >
                      <DebounceSelect
                        defaultOptionsFetchCallback={fetchCurrencies}
                        placeholder={"-"}
                        onChange={turnOffPriceAuto}
                      />
                    </CustomFieldInput>
                  </Col>
                </Row>

                <CharterRoutePriceCoefficients calculationApplied={isPriceAutoEnabled}
                                               disabled={!isPriceAutoEnabled}/>

                <Row style={{marginTop: 32}}>
                  <Col flex={1}>
                    <CustomFieldInput
                      name={'welcomeMessage'}
                      label={intl.formatMessage(CommonFormMessages.welcomeMessageLabel)}
                    >
                      <TextArea placeholder={intl.formatMessage(CommonFormMessages.welcomeMessagePlaceholder)}  />
                    </CustomFieldInput>
                  </Col>
                </Row>
              </CharterRouteDrawerElement>
            </>
          }

          <Row gutter={8} justify={"end"} align={"middle"}>
            <Col flex={isMobile ? 1 : undefined}>
              <CustomButton type={'primary'} ghost={true} onClick={() => setIsOpen(false)} style={{width: "100%"}}>
                {intl.formatMessage(CommonButtonMessages.cancel)}
              </CustomButton>
            </Col>
            <Col flex={isMobile ? 1 : undefined}>
              <CustomFieldInput style={{margin: 0}}>
                <CustomButton
                  type={'primary'}
                  htmlType={"submit"}
                  loading={isLoading}
                  disabled={!planeId}
                  style={{width: "100%"}}
                >
                  {intl.formatMessage(CommonButtonMessages.doRespond)}
                </CustomButton>
              </CustomFieldInput>
            </Col>
          </Row>
        </DrawerContent>
      </Form>
    </CustomDrawer>
  );
};

