import {PayloadAction} from "@reduxjs/toolkit";
import {Role} from "../../../config/acl/roles/Role";
import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {
  PageModel, ResultResponse, UserAccountApiChangePasswordRequest,
  UserAccountApiCreateManagerRequest,
  UserAccountApiManagerListRequest,
  UserAccountApiManagerListResponse,
  UserAccountApiRemoveManagerRequest,
  UserAccountApiUserResponse,
  UserAccountApiUserUpdateRequest,
  UserEntity
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {backendIdentifierFromRole} from "../../../utils/helpers/acl/mapRoleAndBackendIdentifier";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {sha256} from "js-sha256";
import {
  IChangeManagerPasswordPayload,
  ICreateManagerPayload, IDeleteManagerPayload,
  IEditManagerPayload,
  IGetManagerListPayload
} from "../../reducers/personnelManagementReducer/personnelManagementReducerTypes";
import {
  changeManagerPassword,
  createManager, deleteManager,
  editManager,
  getManagerList, setChangeManagerPasswordResult,
  setCreateManagerResult, setDeleteManagerResult, setEditManagerResult,
  setManagerListResult
} from "../../reducers/personnelManagementReducer/personnelManagementReducer";


function* getManagerListSaga({payload}: PayloadAction<IGetManagerListPayload>) {
  const role: Role = yield select(state => state.auth.role);
  if ([Role.freighterAdmin, Role.chartererAdmin].every(r => r !== role)) return;

  const getManagerListReq = new UserAccountApiManagerListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.PersonnelCards)
    );

  try {
    const managerListRes: UserAccountApiManagerListResponse = yield call(
      role === Role.freighterAdmin ? API.getFreighterManagerList : API.getChartererManagerList,
      getManagerListReq,
    );

    yield put(setManagerListResult({
      items: managerListRes.getManagersList(),
      totalCount: managerListRes.getTotalcount(),
    }));
  } catch {
  }
}


function* createManagerSaga({payload}: PayloadAction<ICreateManagerPayload>) {
  const hashedPassword: string = sha256(payload.password);
  const manager = new UserEntity()
    .setFirstname(payload.firstName)
    .setLastname(payload.lastName)
    .setPhone(payload.phone)
    .setRole(backendIdentifierFromRole(payload.companyRole))
    .setEmail(payload.email)
    .setPassword(hashedPassword);

  if (payload.middleName)
    manager.setMiddlename(payload.middleName);

  const addManagerReq = new UserAccountApiCreateManagerRequest()
    .setUser(manager)

  try {
    const addManagerRes: UserAccountApiUserResponse
      = yield call(API.createManager, addManagerReq);

    if (addManagerRes.getResultresponse()?.getIssuccessful()) {
      yield put(setCreateManagerResult({
        item: addManagerRes.getUser(),
      }));
    } else {
      yield put(setCreateManagerResult({
        errorMessage: getErrorMessageByCode(addManagerRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* editManagerSaga({payload}: PayloadAction<IEditManagerPayload>) {
  const editManagerReq = new UserAccountApiUserUpdateRequest()
    .setUserid(payload.id)
    .setRole(backendIdentifierFromRole(payload.companyRole))
    .setFirstname(payload.firstName)
    .setLastname(payload.lastName)
    .setPhone(payload.phone);

  if (payload.middleName)
    editManagerReq.setMiddlename(payload.middleName);

  try {
    const editManagerRes: UserAccountApiUserResponse
      = yield call(API.editUser, editManagerReq);

    if (editManagerRes.getResultresponse()?.getIssuccessful()) {
      yield put(setEditManagerResult({
        item: editManagerRes.getUser(),
      }));
    } else {
      yield put(setEditManagerResult({
        errorMessage: getErrorMessageByCode(editManagerRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* deleteManagerSaga({payload}: PayloadAction<IDeleteManagerPayload>) {
  const removeManagerReq = new UserAccountApiRemoveManagerRequest()
    .setManagerid(payload.itemId);

  try {
    const deleteManagerRes: UserAccountApiUserResponse
      = yield call(API.deleteManager, removeManagerReq);

    if (deleteManagerRes.getResultresponse()?.getIssuccessful()) {
      yield put(setDeleteManagerResult({
        itemId: payload.itemId,
      }));
    } else {
      yield put(setDeleteManagerResult({
        errorMessage: getErrorMessageByCode(deleteManagerRes.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch {
  }
}


function* changeManagerPasswordSaga({payload}: PayloadAction<IChangeManagerPasswordPayload>) {
  const hashedOldPassword: string = sha256(payload.oldPassword);
  const hashedNewPassword: string = sha256(payload.newPassword);
  const hashedRepeatNewPassword: string = sha256(payload.repeatNewPassword);

  const req = new UserAccountApiChangePasswordRequest()
    .setUserid(payload.managerId)
    .setOldpassword(hashedOldPassword)
    .setNewpassword(hashedNewPassword)
    .setRepeatnewpassword(hashedRepeatNewPassword);

  try {
    const res: ResultResponse = yield call(API.changePassword, req);

    if (res.getIssuccessful()) {
      yield put(setChangeManagerPasswordResult({}));
    } else {
      yield put(setChangeManagerPasswordResult({errorMessage: getErrorMessageByCode(res.getErrormessage())}));
    }
  } catch {
  }
}


export function* personnelManagementSaga() {
  yield all([
    takeLatest(getManagerList.type, getManagerListSaga),
    takeLatest(createManager.type, createManagerSaga),
    takeLatest(editManager.type, editManagerSaga),
    takeLatest(deleteManager.type, deleteManagerSaga),
    takeLatest(changeManagerPassword.type, changeManagerPasswordSaga),
  ])
}