import {
  CommunicationWebsocketMessage, NotificationApiNotificationListRequest,
  WebsocketNotificationMessage
} from "../proto/generated/api_entities_pb";
import {API} from "../API/API";
import {Logger} from "../utils/logger/Logger";
import {store} from "../store/store";
import {setLastNotification} from "../store/reducers/notificationsReducer/notificationsReducer";
import {getErrorMessage} from "../utils/errorHelpers/getCommonErrorMessage";


let newNotificationIdsQueue: number[] = [];

let currentTimeout: NodeJS.Timeout | null = null;

export const handleNotificationPush = (message: CommunicationWebsocketMessage) => {
  try {
    const data = message.getData_asU8();
    const notificationMessage = WebsocketNotificationMessage.deserializeBinary(data);
    newNotificationIdsQueue.push(notificationMessage.getNotificationid());

    if (currentTimeout)
      clearTimeout(currentTimeout);

    currentTimeout = setTimeout(async () => {
      const requestedNotifications = [...newNotificationIdsQueue];
      newNotificationIdsQueue = [];

      const res = await API.getNotificationList(new NotificationApiNotificationListRequest()
        .setNotificationidsList(requestedNotifications));
      const receivedNotifications = res.getNotificationsList();

      if (receivedNotifications.length > 0) {
        store.dispatch(setLastNotification({items: receivedNotifications}));
      } else {
        Logger.error('Cannot receive pushed notification by given ids');
      }
    }, 0);
  } catch (e) {
    Logger.error("handleNotificationPush error: " + getErrorMessage(e))
  }

}