import {defineMessages} from "react-intl";
import {
  FlyHourPriceDefaultCategoryEnum, FlyHourPriceDefaultSettingTypeEnum,
  FlyHourPriceDefaultSubcategoryEnum
} from "../../pages/Lk/OperatorsOnly/Fleet/components/flyHourPriceCalculator/types/flyHourPriceSettingEnums";

export const FlyHourPriceMessages = defineMessages({
  //CATEGORIES
  [FlyHourPriceDefaultCategoryEnum.CATEGORY_BASE]: {
    id: "flyHourPrice.category.base",
    defaultMessage: ""
  },
  [FlyHourPriceDefaultCategoryEnum.CATEGORY_CONSTANT]: {
    id: "flyHourPrice.category.constant",
    defaultMessage: "Fixed costs"
  },
  [FlyHourPriceDefaultCategoryEnum.CATEGORY_CHANGEABLE]: {
    id: "flyHourPrice.category.changeable",
    defaultMessage: "Variable costs"
  },
  [FlyHourPriceDefaultCategoryEnum.CATEGORY_MARGIN]: {
    id: "flyHourPrice.category.margin",
    defaultMessage: "Company margin"
  },
  [FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT]: {
    id: "flyHourPrice.category.coefficient",
    defaultMessage: "Multiplier"
  },

  //SUBCATEGORIES
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_CREW]: {
    id: "flyHourPrice.subcategory.constantCrew",
    defaultMessage: "Crew"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_DEFAULT]: {
    id: "flyHourPrice.subcategory.constantDefault",
    defaultMessage: "Default"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_OPERATION]: {
    id: "flyHourPrice.subcategory.constantShipOperation",
    defaultMessage: "Aircraft operation"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_SHIP_MAINTENANCE]: {
    id: "flyHourPrice.subcategory.constantShipMaintenance",
    defaultMessage: "Aircraft maintenance"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CONSTANT_ADMINISTRATIVE_SPENDING]: {
    id: "flyHourPrice.subcategory.constantAdministrativeSpending",
    defaultMessage: "Administrative costs"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_DEFAULT]: {
    id: "flyHourPrice.subcategory.changeableDefault",
    defaultMessage: "Default"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_SHIP_MAINTENANCE]: {
    id: "flyHourPrice.subcategory.changeableShipMaintenance",
    defaultMessage: "Aircraft maintenance"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_CHANGEABLE_FLY]: {
    id: "flyHourPrice.subcategory.changeableFly",
    defaultMessage: "Flight"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_COMPANY_MARGIN]: {
    id: "flyHourPrice.subcategory.marginCompanyMargin",
    defaultMessage: "Company margin"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_MARGIN_TAXES]: {
    id: "flyHourPrice.subcategory.marginTaxes",
    defaultMessage: "Taxes"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_COEFFICIENT_DEFAULT]: {
    id: "flyHourPrice.subcategory.coefficientDefault",
    defaultMessage: "Default"
  },
  [FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_BASE]: {
    id: "flyHourPrice.subcategory.base",
    defaultMessage: "Base"
  },

  //BASE PARAMS
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_BASE_FLY_HOURS]: {
    id: "flyHourPrice.parameter.baseFlyHours",
    defaultMessage: "Aircraft year schedule"
  },

  //CONSTANT PARAMS
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SPENDING]: {
    id: "flyHourPrice.parameter.constantSpending",
    defaultMessage: "Total costs per year"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_LEARNING]: {
    id: "flyHourPrice.parameter.constantCrewLearning",
    defaultMessage: "Crew training"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_KVS]: {
    id: "flyHourPrice.parameter.constantKVS",
    defaultMessage: "Pilot-in-command"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_SECOND_PILOT]: {
    id: "flyHourPrice.parameter.constantSecondPilot",
    defaultMessage: "Copilot"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FLIGHT_ATTENDANT]: {
    id: "flyHourPrice.parameter.constantFlightAttendant",
    defaultMessage: "Flight attendant"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PLG]: {
    id: "flyHourPrice.parameter.constantPLG",
    defaultMessage: "Maintenance of navigable readiness"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_PRODUCER_DOCUMENTATION]: {
    id: "flyHourPrice.parameter.constantProducerDocumentation",
    defaultMessage: "Manufacturer documentation"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_JEPPESEN]: {
    id: "flyHourPrice.parameter.constantJeppesen",
    defaultMessage: "Jeppesen publication"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_ARINC]: {
    id: "flyHourPrice.parameter.constantArinc",
    defaultMessage: "Arinc support"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_FMS]: {
    id: "flyHourPrice.parameter.constantFMS",
    defaultMessage: "FMS update"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_EGPWS]: {
    id: "flyHourPrice.parameter.constantEGPWS",
    defaultMessage: "EGPWS update"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMP]: {
    id: "flyHourPrice.parameter.constantCAMP",
    defaultMessage: "CAMP"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CAMO]: {
    id: "flyHourPrice.parameter.constantCAMO",
    defaultMessage: "CAMO"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_INSURANCE]: {
    id: "flyHourPrice.parameter.constantInsurance",
    defaultMessage: "Aircraft insurance"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_CREW_INSURANCE]: {
    id: "flyHourPrice.parameter.constantCrewInsurance",
    defaultMessage: "Crew insurance"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_DRY_CLEANING_AND_STORAGE]: {
    id: "flyHourPrice.parameter.constantDryCleaningAndStorage",
    defaultMessage: "Storage and dry cleaning"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_LINEAR_MAINTENANCE]: {
    id: "flyHourPrice.parameter.constantLinearMaintenance",
    defaultMessage: "Inline maintenance"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_BASING]: {
    id: "flyHourPrice.parameter.constantBasing",
    defaultMessage: "Basing"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CONSTANT_MANAGE]: {
    id: "flyHourPrice.parameter.constantManage",
    defaultMessage: "Management"
  },

  //CHANGEABLE PARAMS
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPENDING]: {
    id: "flyHourPrice.parameter.changeableSpending",
    defaultMessage: "Total costs per year"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_FUEL]: {
    id: "flyHourPrice.parameter.changeableFuel",
    defaultMessage: "Fuel"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_NAVIGATION_TAXES]: {
    id: "flyHourPrice.parameter.changeableNavigationTaxes",
    defaultMessage: "Nav. taxes and permissions"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOUSEHOLD_EXPENSES]: {
    id: "flyHourPrice.parameter.changeableHouseholdExpenses",
    defaultMessage: "Household consumables"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_HOTEL_EXPENSES]: {
    id: "flyHourPrice.parameter.changeableHotelExpenses",
    defaultMessage: "Hotel, tickets"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_ENGINE_TAXES]: {
    id: "flyHourPrice.parameter.changeableEngineTaxes",
    defaultMessage: "Engine taxes"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_SPARE_PARTS_TAXES]: {
    id: "flyHourPrice.parameter.changeablePartsTaxes",
    defaultMessage: "Spare parts planning"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_CHANGEABLE_VSU_TAXES]: {
    id: "flyHourPrice.parameter.changeableVsuTaxes",
    defaultMessage: "Slave engine costs"
  },

  //MARGIN PARAMS
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_AMOUNT]: {
    id: "flyHourPrice.parameter.marginAmount",
    defaultMessage: "Amount"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_MARGIN_TAXES]: {
    id: "flyHourPrice.parameter.marginTaxes",
    defaultMessage: "Income tax"
  },

  //COEFFICIENT PARAMS
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_NDS]: {
    id: "flyHourPrice.parameter.coefficientNDS",
    defaultMessage: "Domestic flights VAT"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_HIGH_SEASON]: {
    id: "flyHourPrice.parameter.coefficientHighSeason",
    defaultMessage: "Peak season"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_LOW_DATES]: {
    id: "flyHourPrice.parameter.coefficientLowDates",
    defaultMessage: "Low season"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_URGENT_FLIGHT]: {
    id: "flyHourPrice.parameter.coefficientUrgentFlight",
    defaultMessage: "Urgent flight"
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_PASSENGER_SERVICE]: {
    id: "flyHourPrice.parameter.coefficientPassengerService",
    defaultMessage: "VIP terminal per PAX, avg."
  },
  [FlyHourPriceDefaultSettingTypeEnum.PARAMETER_COEFFICIENT_EAT]: {
    id: "flyHourPrice.parameter.coefficientEat",
    defaultMessage: "Onboard meals, avg."
  },
});