import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {ProfilePage} from "../../../../../pages/Lk/Shared/Profile/ProfilePage";
import {groupProfile} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const ProfileRouteTitleMessage = defineMessage({
  id: "profileRoute.title",
  defaultMessage: "Profile",
})

export const profile: RouteDescriptor<Route.profile> = {
  route: Route.profile,
  render: ProfilePage,
  title: ProfileRouteTitleMessage,
  path: '/lk/profile',
  allowRoles: [
    ...groupProfile
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}