import React, {FC, ReactNode, useEffect, useState} from 'react';
import styled from "styled-components";
import {defineMessages, useIntl} from "react-intl";
import {Route} from "../../../config/acl/routes/Route";
import {routes} from "../../../config/acl/routes";
import {useNavigate, useParams} from "react-router";
import {generalTerms} from "./documents/generalTerms";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  padding-top: 55px;
  min-height: calc(100vh - 55px);
  min-height: calc(var(--app-height) - 55px);
  background: #F3F5F7;
  width: 100%;

  @media(max-width: ${mediaScreenSizes.tablet}) {
    row-gap: 25px;
    padding-top: 45px;
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    padding-top: 25px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 50px;
  line-height: 46px;
  padding: 0 90px;
  color: #000000;

  @media(max-width: ${mediaScreenSizes.tablet}) {
    font-size: 30px;
    line-height: 27px;
    padding: 0 24px;
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    padding: 0 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  @media(max-width: ${mediaScreenSizes.tablet}) {
    flex-direction: column;
  }
`;

const DocumentsNavigation = styled.div`
  min-width: 390px;
  width: 390px;
  border-right: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  padding-right: 15px;

  @media(max-width: ${mediaScreenSizes.tablet}) {
    min-width: unset;
    width: unset;
    border-right: none;
    flex-direction: row;
    padding: 0 24px;
    margin-bottom: 33px;
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 8px;
    padding: 0 10px;
  }
`;

const DocumentText = styled.div`
  padding: 0 60px 79px 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    
    :not(:first-child) {
      margin-top: 32px;
    }
  }

  @media(max-width: ${mediaScreenSizes.tablet}) {
    padding: 0 24px 32px 24px;
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 8px;
    padding: 0 10px 16px 10px;
  }
`;


export enum LegalDocumentEnum {
  GeneralTerms = 'generalTerms',
  // PrivacyPolicy = 'privacyPolicy',
  // PrivacyPrinciples = 'privacyPrinciples',
  // CookiePolicy = 'cookiePolicy',
  // Subprocesses = 'subprocesses',
}

const LegalDocumentsPageMessages = defineMessages({
  title: {
    id: "legalDocumentsPage.title",
    defaultMessage: "Legal Documents"
  },
  generalTerms: {
    id: "legalDocumentsPage.generalTerms",
    defaultMessage: "General Terms & Conditions"
  },
  privacyPolicy: {
    id: "legalDocumentsPage.privacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  privacyPrinciples: {
    id: "legalDocumentsPage.privacyPrinciples",
    defaultMessage: "Privacy Principles",
  },
  cookiePolicy: {
    id: "legalDocumentsPage.cookiePolicy",
    defaultMessage: "Cookie Policy",
  },
  subprocesses: {
    id: "legalDocumentsPage.subprocesses",
    defaultMessage: "Subprocesses",
  },
})

export const LegalDocumentsPage = () => {
  const intl = useIntl();
  let {section} = useParams();
  const navigate = useNavigate();
  const [sectionKey, setSectionKey] = useState<LegalDocumentEnum>();

  useEffect(() => {
    if (!section || !isLegalDocumentKey(section))
      navigate(routes[Route.notFound].path);
    else
      setSectionKey(section as LegalDocumentEnum);
  }, [section])

  const legalDocNavigation = {
    [LegalDocumentEnum.GeneralTerms]: {
      key: LegalDocumentEnum.GeneralTerms,
      name: intl.formatMessage(LegalDocumentsPageMessages.generalTerms),
      path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.GeneralTerms),
    },
    // [LegalDocumentEnum.PrivacyPolicy]: {
    //   key: LegalDocumentEnum.PrivacyPolicy,
    //   name: intl.formatMessage(LegalDocumentsPageMessages.privacyPolicy),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.PrivacyPolicy),
    // },
    // [LegalDocumentEnum.PrivacyPrinciples]: {
    //   key: LegalDocumentEnum.PrivacyPrinciples,
    //   name: intl.formatMessage(LegalDocumentsPageMessages.privacyPrinciples),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.PrivacyPrinciples),
    // },
    // [LegalDocumentEnum.CookiePolicy]: {
    //   key: LegalDocumentEnum.CookiePolicy,
    //   name: intl.formatMessage(LegalDocumentsPageMessages.cookiePolicy),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.CookiePolicy),
    // },
    // [LegalDocumentEnum.Subprocesses]: {
    //   key: LegalDocumentEnum.Subprocesses,
    //   name: intl.formatMessage(LegalDocumentsPageMessages.subprocesses),
    //   path: routes[Route.legalDocuments].path.replace(':section', LegalDocumentEnum.Subprocesses),
    // },
  }

  return (
    <Wrapper>
      <Title>{intl.formatMessage(LegalDocumentsPageMessages.title)}</Title>

      <ContentWrapper>
        <DocumentsNavigation>
          {Object.values(legalDocNavigation).map(item => <LegalDocNavItem key={item.path} path={item.path} isActive={sectionKey === item.key}>
            {item.name}
          </LegalDocNavItem>)}
        </DocumentsNavigation>

        {sectionKey && <DocumentText dangerouslySetInnerHTML={{__html: legalDocTexts[sectionKey]}} />}
      </ContentWrapper>
    </Wrapper>
  );
};


const BlueLine = styled.div`
  display: flex;
  height: 3px;
  background: #1890FF;
  margin-right: 20px;
`;

const LegalDocNavItemWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${props => props.isActive ? 0 : "90px"};
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
  transition: padding-left 0.2s ease;
  
  ${BlueLine} {
    transition: width 0.2s ease;
    width: ${props => props.isActive ? "115px" : 0};
  }
  
  @media(max-width: ${mediaScreenSizes.tablet}) {
    padding: 9px 18px;
    border: ${props => props.isActive ? "1px solid #1890FF" : "none"};
    color: ${props => props.isActive ? "#1890FF" : "#000000"};
    border-radius: 5px;
    font-size: 14px;
    line-height: 13px;
    
    ${BlueLine} {
      display: none;
    }
  }

  @media(max-width: ${mediaScreenSizes.mobile}) {
    padding: 9px 18px;
    border: ${props => props.isActive ? "1px solid #1890FF" : "1px solid #D9D9D9"};
    color: ${props => props.isActive ? "#1890FF" : "#000000"};
    border-radius: 5px;
    font-size: 14px;
    line-height: 13px;
    width: max-content;

    ${BlueLine} {
      display: none;
    }
  }
`;

interface ILegalDocNavItem {
  isActive: boolean;
  path: string;
  children: ReactNode;
}

const LegalDocNavItem: FC<ILegalDocNavItem> = ({isActive, path, children}) => {
  const navigate = useNavigate();

  return <LegalDocNavItemWrapper isActive={isActive} onClick={() => navigate(path, {replace: true})}>
    <BlueLine />
    <div>{children}</div>
  </LegalDocNavItemWrapper>
}


function isLegalDocumentKey(key: string): key is LegalDocumentEnum {
  return Object.values(LegalDocumentEnum).includes(key as LegalDocumentEnum);
}

const legalDocTexts = {
  [LegalDocumentEnum.GeneralTerms]: generalTerms,
  // [LegalDocumentEnum.PrivacyPolicy]: `Privacy policy`,
  // [LegalDocumentEnum.PrivacyPrinciples]: `Privacy principles`,
  // [LegalDocumentEnum.CookiePolicy]: `Cookie policy`,
  // [LegalDocumentEnum.Subprocesses]: `Subprocesses`
}
