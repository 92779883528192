import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
} from "../highOrderReducers/commonReducer";
import {
  IMultiLegRequestPayload,
  INewRequestInitialState,
  IOneWayRequestPayload,
  IRoundTripRequestPayload
} from "./newRequestReducerTypes";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";

const newRequestInitialState: INewRequestInitialState = {
  oneWayRequest: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  roundTripRequest: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  multiLegRequest: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const newRequestSlice = createSlice({
    name: 'newRequest',
    initialState: newRequestInitialState,
    reducers: {
      oneWayRequest(state, {payload}: PayloadAction<IOneWayRequestPayload>) {
        initCommonAction(state, nameof<INewRequestInitialState>("oneWayRequest"));
      },
      clearOneWayRequest(state) {
        initCommonClearAction(state, nameof<INewRequestInitialState>("oneWayRequest"))
      },
      oneWayRequestResult(state, {payload}: PayloadAction<{ errorMessage?: string }>) {
        initCommonActionResult(state, payload, nameof<INewRequestInitialState>("oneWayRequest"))
      },

      roundTripRequest(state, {payload}: PayloadAction<IRoundTripRequestPayload>) {
        initCommonAction(state, nameof<INewRequestInitialState>("roundTripRequest"));
      },
      clearRoundTripRequest(state) {
        initCommonClearAction(state, nameof<INewRequestInitialState>("roundTripRequest"))
      },
      roundTripRequestResult(state, {payload}: PayloadAction<{ errorMessage?: string }>) {
        initCommonActionResult(state, payload, nameof<INewRequestInitialState>("roundTripRequest"))
      },

      multiLegRequest(state, {payload}: PayloadAction<IMultiLegRequestPayload>) {
        initCommonAction(state, nameof<INewRequestInitialState>("multiLegRequest"));
      },
      clearMultiLegRequest(state) {
        initCommonClearAction(state, nameof<INewRequestInitialState>("multiLegRequest"))
      },
      multiLegRequestResult(state, {payload}: PayloadAction<{ errorMessage?: string }>) {
        initCommonActionResult(state, payload, nameof<INewRequestInitialState>("multiLegRequest"))
      },
    }
  }
);

export const {
  oneWayRequest,
  clearOneWayRequest,
  oneWayRequestResult,

  roundTripRequest,
  clearRoundTripRequest,
  roundTripRequestResult,

  multiLegRequest,
  clearMultiLegRequest,
  multiLegRequestResult,
} =
  newRequestSlice.actions;
export default newRequestSlice.reducer;
