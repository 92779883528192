import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {HomePage} from "../../../../../pages/General/Home/HomePage";
import {groupAll} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const HomeRouteTitleMessage = defineMessage({
  id: "homeRoute.title",
  defaultMessage: "Jetsnode",
})

export const home: RouteDescriptor<Route.home> = {
  route: Route.home,
  render: HomePage,
  title: HomeRouteTitleMessage,
  path: '/',
  allowRoles: [
    ...groupAll,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: false,
}