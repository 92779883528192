import {useIntl} from "react-intl";

type StandardEnumValue = string | number;
export type TranslateTypes<TEnum extends StandardEnumValue> = {
  [key in TEnum]: {
    id: string;
    defaultMessage: string;
  };
}

export function useEnumIntl<TEnum extends StandardEnumValue>(obj: TEnum, translates: TranslateTypes<TEnum>) {
  const intl = useIntl();

  const translate = translates[obj];
  return intl.formatMessage({id: translate.id, defaultMessage: translate.defaultMessage});
}