import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  getChartererRoutes,
  respondCharterer, setChartererRoutesResult, setRespondChartererResult
} from "../../reducers/tripBoardReducer/tripBoardReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  BookingApiGetAirportRequest, BookingApiGetAirportResponse,
  BookingApiOrderRequestRequest,
  BookingApiOrderRequestResponse,
  BookingApiOrderRouteListRequest,
  BookingApiOrderRouteListResponse,
  CurrencyEntity,
  OrderRequestEntity,
  OrderRouteEntity,
  PageModel,
  PlaneEntity
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {
  IGetChartererRoutesListPayload,
  IRespondChartererPayload
} from "../../reducers/tripBoardReducer/tripBoardReducerTypes";
import {mapPlaneCategoryIdToIPlaneCategory} from "../../reducers/fleetReducer/fleetMappers";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";


function* getChartererRoutesSaga({payload}: PayloadAction<IGetChartererRoutesListPayload>) {
  // try {
  //   const requestedPlaneCategories = payload.planeCategoryIds?.map(mapPlaneCategoryIdToIPlaneCategory);
  //
  //   const getOrderRouteListReq = new BookingApiOrderRouteListRequest()
  //     .setPagemodel(
  //       new PageModel()
  //         .setPage(payload.page)
  //         .setLimit(PageModelLimits.TripBoard)
  //     )
  //     .setCustom(true);
  //
  //   payload.dateFrom && getOrderRouteListReq.setDatefrom(getLocalDateAsISOString(payload.dateFrom));
  //   payload.dateTo && getOrderRouteListReq.setDateto(getLocalDateAsISOString(payload.dateTo));
  //   payload.pax && getOrderRouteListReq.setPax(payload.pax);
  //   requestedPlaneCategories && getOrderRouteListReq.setPlanecategoriesList(requestedPlaneCategories);
  //   payload.companionsAllowed && getOrderRouteListReq.setCompanionsallowed(payload.companionsAllowed);
  //   payload.isEmptyLegUsable && getOrderRouteListReq.setIsemptylegusable(payload.isEmptyLegUsable);
  //
  //   if (payload.airportFromId) {
  //     const airportFromResponse: BookingApiGetAirportResponse = yield call(
  //       API.getAirportById,
  //       new BookingApiGetAirportRequest().setAirportid(payload.airportFromId)
  //     );
  //
  //     const airportFrom = airportFromResponse.getAirport();
  //     if (!airportFrom)
  //       throw new Error("No airport with given id found");
  //     getOrderRouteListReq.setAirportfrom(airportFrom);
  //   }
  //
  //   if (payload.airportToId) {
  //     const airportToResponse: BookingApiGetAirportResponse = yield call(
  //       API.getAirportById,
  //       new BookingApiGetAirportRequest().setAirportid(payload.airportToId)
  //     );
  //
  //     const airportTo = airportToResponse.getAirport();
  //     if (!airportTo)
  //       throw new Error("No airport with given id found");
  //
  //     getOrderRouteListReq.setAirportto(airportTo);
  //   }
  //
  //   const getOrderRouteListRes: BookingApiOrderRouteListResponse = yield call(API.getOrderRouteList, getOrderRouteListReq);
  //
  //   yield put(setChartererRoutesResult({
  //     items: getOrderRouteListRes.getOrderroutesList(),
  //     totalCount: getOrderRouteListRes.getTotalcount(),
  //   }));
  // } catch {
  // }
}


function* respondChartererSaga({payload}: PayloadAction<IRespondChartererPayload>) {
  // try {
  //
  //   const flyDurationInMinutes = Number(payload.hours * 60) + Number(payload.minutes);
  //
  //   const orderRequestCreateReq = new BookingApiOrderRequestRequest()
  //     .setOrderrequest(
  //       new OrderRequestEntity()
  //         .setOrderroute(new OrderRouteEntity().setOrderrouteid(payload.orderRouteId))
  //         .setPlane(new PlaneEntity().setPlaneid(payload.planeId))
  //         .setFlyduration(flyDurationInMinutes)
  //         .setFlydistance(payload.distance)
  //         .setPrice(payload.price)
  //         .setCurrency(new CurrencyEntity().setCurrencyid(payload.currencyId))
  //     )
  //     .setWelcomemessage(payload.welcomeMessage);
  //
  //   const orderRequestCreateRes: BookingApiOrderRequestResponse = yield call(API.orderRequestCreate, orderRequestCreateReq);
  //
  //   const isSuccess = orderRequestCreateRes.getResultresponse()?.getIssuccessful();
  //   const orderRouteId = orderRequestCreateRes.getOrderrequest()?.getOrderroute()?.getOrderrouteid();
  //
  //   if (isSuccess && orderRouteId) {
  //     yield put(setRespondChartererResult({
  //       orderRouteId: orderRouteId,
  //     }));
  //   } else {
  //     yield put(setRespondChartererResult({
  //       errorMessage: getErrorMessageByCode(orderRequestCreateRes.getResultresponse()!.getErrormessage()),
  //     }));
  //   }
  // } catch {
  // }
}


export function* tripBoardSaga() {
  yield all([
    takeLatest(getChartererRoutes.type, getChartererRoutesSaga),
    takeLatest(respondCharterer.type, respondChartererSaga),
  ])
}