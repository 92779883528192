import {IAirport, ICurrency, IPlane, IPlaneCategory} from "../fleetReducer/fleetReducerTypes";
import {CommonState, ItemListState, RemoteActionState} from "../highOrderReducers/commonReducer";
import {
  EmptyLegOrderRouteEntity, OrderEntity,
} from "../../../proto/generated/api_entities_pb";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";
import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {IUser} from "../userReducer/userReducerTypes";
import {ICompany} from "../companyReducer/companyReducerTypes";


export enum OrderTypeEnum {
  ONE_WAY = 1,
  ROUND_TRIP = 2,
  MULTI_LEG = 3,
  EMPTY_LEG = 4,
}

export enum OrderStatusEnum {
  NOT_ANSWERED = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export interface IChartererOrder {
  id: number;
  type: OrderTypeEnum;
  user: IUser;
  company: ICompany;
  createdDate: Date;
  planeCategories: IPlaneCategory[];
  companionsAllowed: boolean;
  comment: string;
  orderRoutes: IChartererOrderRoute[];
  nestedEmptyLegs: IChartererEmptyLegFromOrder[];
  nestedOrderRequests: IChartererOrderRequestFromOrder[];
  isArchived: boolean;
}

export interface IChartererEmptyLegFromOrder {
  emptyLegId: number;
  orderRouteId: number;
  emptyLegOrderRouteId: number;
  freighterUser: IUser;
  freighterCompany: ICompany;
  airportFrom: IAirport;
  airportTo: IAirport;
  plane: IPlane;
  availableFromDate?: Date;
  availableToDate?: Date;
  departureDate?: Date;
  arrivalDate?: Date;
  price: number;
  currency: ICurrency;
  flyHours: number;
  flyMinutes: number;
  flyDistance: number;
  comment: string;
  dateModified?: Date;
  status: EmptyLegOrderRouteStatusEnum;
  isArchived: boolean;
}

export interface IChartererOrderRouteOrderRequest {
  orderRouteOrderRequestId: number;
  flyHours: number;
  flyMinutes: number;
  flyDistance: number;
  arrivalDate: Date;
  technicalStopDuration?: number;
  technicalStopCount?: number;
  orderRoute: IChartererOrderRoute;
}

export interface IChartererOrderRequestFromOrder {
  orderRequestId: number;
  createdAtDate: Date;
  freighterUser: IUser;
  freighterCompany: ICompany;
  plane?: IPlane,
  price: number;
  currency: ICurrency;
  orderRouteOrderRequests: IChartererOrderRouteOrderRequest[];
  status: OrderRequestStatusEnum;
  isArchived: boolean;
}

export interface IChartererOrderRoute {
  id: number;
  airportFrom: IAirport;
  airportTo: IAirport;
  departureDate: Date;
  pax: number;
}

export interface IGetChartererOrderListPayload {
  page: number;
  types: OrderTypeEnum[];
  airportFromId?: number;
  airportToId?: number;
  onlyApproved?: boolean;
  showArchived: boolean;
}

export interface ISetChartererOrderListResultPayload {
  items: OrderEntity[];
  totalCount: number;
}

export interface IChangeEmptyLegOrderRouteWithdrawPayload {
  orderRouteId: number;
  emptyLegId: number;
  emptyLegOrderRouteStatus: EmptyLegOrderRouteStatusEnum;
}

export interface ISetChangeEmptyLegOrderRouteWithdrawResultPayload {
  emptyLegOrderRoute?: EmptyLegOrderRouteEntity;
  errorMessage?: string;
}

export interface IArchiveOrderPayload {
  itemId: number
}

export interface ISetArchiveOrderResultPayload {
  itemId?: number;
  errorMessage?: string;
}


export interface IChartererRequestsInitialState extends CommonState<IChartererOrder>{
  itemsObject: ItemListState<IChartererOrder>;
  changeEmptyLegOrderRouteWithdraw: RemoteActionState;
  delete: RemoteActionState;
}