import React, {FC, ReactNode} from 'react';
import styled from "styled-components";
import Modal, {ModalProps} from "antd/es/modal/Modal";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const ModalStyled = styled(Modal)`
  .ant-modal-close-x {
    height: 62px;
  }
  
  .ant-modal-content {
    border-radius: 10px;
  }
  
  .ant-modal-header {
    padding: 20px 24px;
    border-radius: 10px 10px 0 0 !important;
  }
  
  .ant-modal-title {
    font-size: 18px;
    font-weight: 400;
  }
  
  .ant-modal-body {
    border-radius: 0 0 10px 10px !important;
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    .ant-modal-body {
      padding: 10px;
    }
  }
`;

interface ICustomModal extends ModalProps {
  children: ReactNode;
}

export const CustomModal: FC<ICustomModal> = ({children, ...props}) => {
  return (
      <ModalStyled {...props}>
        {children}
      </ModalStyled>
  );
};
