import React, {FC} from 'react';
import {ExtendedMenuItem} from "../../../../hooks/useGenerateMenu";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";

const HeaderMobileSecondLevelItemContent = styled.div<{isActive: boolean}>`
  display: flex;
  margin-left: 32px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.isActive ? "#1890FF" : "#808080"};
`;


interface IHeaderMobileSecondLevelItem {
  menuItem: ExtendedMenuItem;
  closeMenu: () => void;
}

export const HeaderMobileSecondLevelItem: FC<IHeaderMobileSecondLevelItem> = ({menuItem, closeMenu}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isActive = menuItem.key === pathname;

  const onClick = () => {
      navigate(menuItem.key as string)
      closeMenu()
  }

  return (
    <HeaderMobileSecondLevelItemContent isActive={isActive} onClick={onClick}>
      {menuItem.label}
    </HeaderMobileSecondLevelItemContent>
  );
};
