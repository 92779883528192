import React, {FC} from 'react';
import styled from "styled-components";
import {ICompany} from "../../../store/reducers/companyReducer/companyReducerTypes";
import {Tooltip} from "antd";

const Wrapper = styled.div`
  display: flex;
`;

const CompaniesMore = styled.div`
  cursor: pointer;
  color: #1890FF;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;


interface IFlyCompanies {
  companies: ICompany[];
}

export const FlyCompanies: FC<IFlyCompanies> = ({companies}) => {
  const companyNames = companies.map(c => c.name);
  const companiesToDisplay = companyNames.slice(0, 3).join(', ');
  const moreCompanies = companyNames.slice(3);

  return (
    <Wrapper>
      <div>{companiesToDisplay}</div>
      {moreCompanies.length > 0 && <Tooltip placement={'top'} title={moreCompanies.join(', ')}>
        <CompaniesMore>(+{moreCompanies.length})</CompaniesMore>
      </Tooltip>}
    </Wrapper>
  );
};
