import {Modal} from "antd";
import "./renderSuccessModal.scss";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../intl/commonMessages/CommonButtonMessages";

export const renderSuccessModal = (text: string, onClose?: () => void) => {
  Modal.success({
    wrapClassName: 'render-success-modal-container',
    content: text,
    centered: true,
    onOk: onClose
      ? (close) => {
        onClose();
        close()
      }
      : (close) => {
        close();
      }
  });
}

export const RenderConfirmModal = (text: string, onOk: () => void, intl: IntlShape) => {

  Modal.confirm({
    wrapClassName: 'render-success-modal-container',
    content: text,
    centered: true,
    onOk: (close) => {
      onOk();
      close()
    },
    cancelText: intl.formatMessage(CommonButtonMessages.cancel),
  });
}