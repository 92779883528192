import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Col, Form, Input, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomModal} from "../../../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../../../components/UI/AntCustom/CustomFieldInput";
import {PhoneRegex} from "../../../../../../utils/helpers/regexes/phoneRegex";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {clearEditCompany, editCompany} from "../../../../../../store/reducers/companyReducer/companyReducer";
import {EmailRegex} from "../../../../../../utils/helpers/regexes/emailRegex";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {CustomInputNumber} from "../../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../../hooks/useWindowSize";
import {onFinishTrimmed} from "../../../../../../utils/helpers/formHelpers/onFinishTrimmed";


const EditCompanyModalMessages = defineMessages({
  success: {
    id: "editCompanyModal.success",
    defaultMessage: "The information about company has been successfully updated.",
  },
  title: {
    id: "editCompanyModal.title",
    defaultMessage: "Company details",
  },
})

interface IEditCompanyFormData {
  description: string;
  phone: number;
  email: string;
  address: string;
  website: string;
}

interface IEditCompanyModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const EditCompanyModal: FC<IEditCompanyModal> = ({isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.company.edit)
  const company = useAppSelector(state => state.company.oneItemObject.item)

  useEffect(() => {
    return () => {
      dispatch(clearEditCompany());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(EditCompanyModalMessages.success), () => setIsModalVisible(false));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const onFinish = (values: IEditCompanyFormData) => {
    dispatch(editCompany({
      company: company,
      description: values.description,
      phone: values.phone,
      email: values.email,
      address: values.address,
      website: values.website,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: Partial<IEditCompanyFormData> = {
    description: company.description,
    phone: company.phone,
    email: company.email,
    address: company.address,
    website: company.website,
  }

  return (
    <CustomModal
      title={intl.formatMessage(EditCompanyModalMessages.title)}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      centered
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onFinishTrimmed(values, onFinish)}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.companyDescriptionLabel)}
              name={'description'}
            >
              <Input.TextArea placeholder={intl.formatMessage(CommonFormMessages.companyDescriptionPlaceholder)} maxLength={999} />
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.phoneLabel)}
              name={'phone'}
              rules={[{required: false, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                pattern: PhoneRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
              }]}>
              <CustomInputNumber addonBefore={"+"} placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)} controls={false} maxLength={12}/>
            </CustomFieldInput>
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.emailLabel)}
              name={'email'}
              rules={[{
                pattern: EmailRegex.pattern,
                message: intl.formatMessage(CommonFormMessages.ruleIncorrectEmail),
              }]}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.emailPlaceholder)} maxLength={255}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.companyAddressLabel)}
              name={'address'}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.companyAddressPlaceholder)} maxLength={255}/>
            </CustomFieldInput>
          </Col>

          <Col span={24}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.companyWebsiteLabel)}
              name={'website'}
            >
              <Input placeholder={intl.formatMessage(CommonFormMessages.companyWebsitePlaceholder)} maxLength={255}/>
            </CustomFieldInput>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <CustomFieldInput>
              <CustomButton
                block
                type={'primary'}
                htmlType={'submit'}
                loading={isLoading}
              >
                {intl.formatMessage(CommonButtonMessages.save)}
              </CustomButton>
            </CustomFieldInput>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};