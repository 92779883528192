import React, {FC, useState} from 'react';
import {Button, Card, Col} from "antd";
import {ClientDetailedInformationModal} from "./ClientDetailedInformationModal";
import styled from "styled-components";
import {IClient} from "../../../../../store/reducers/requestCallbackReducer/requestCallbackReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CustomCard} from "../../../../../components/UI/AntCustom/CustomCard";

const CommentClient = styled.div`
  display: flex;
  width: 255px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


const ClientCardMessages = defineMessages({
  clientFullName: {
    id: "clientCard.clientFullName",
    defaultMessage: "Client full name",
  },
  phone: {
    id: "clientCard.phone",
    defaultMessage: "Client phone number",
  },
  comment: {
    id: "clientCard.comment",
    defaultMessage: "Comment",
  },
  details: {
    id: "clientCard.details",
    defaultMessage: "Details",
  },
})

interface IClientCard {
  client: IClient,
}

export const ClientCard: FC<IClientCard> = ({client}) => {
  const intl = useIntl();
  let fio = `${client.lastName} ${client.firstName} ${client.middleName}`;
  const [isClientDetailedInformationModalOpen, setIsClientDetailedInformationModalOpen] = useState<boolean>(false);

  return (
    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} style={{height: "max-content"}} key={client.phone}>
      <CustomCard>
        <Card.Meta
          title={fio}
          description={<div>
            <div>{intl.formatMessage(ClientCardMessages.clientFullName)}: {fio}</div>
            <div>{intl.formatMessage(ClientCardMessages.phone)}: {client.phone} </div>
            <CommentClient>{intl.formatMessage(ClientCardMessages.comment)}: {client.message}</CommentClient>
          </div>}
        />
        <CustomButton
          block
          type={"primary"}
          onClick={() => setIsClientDetailedInformationModalOpen(true)}
        >
          {intl.formatMessage(ClientCardMessages.details)}
        </CustomButton>
      </CustomCard>
      {isClientDetailedInformationModalOpen &&
        <ClientDetailedInformationModal client={client} isModalVisible={isClientDetailedInformationModalOpen}
                                        setIsModalVisible={setIsClientDetailedInformationModalOpen}/>}
    </Col>
  );
};

