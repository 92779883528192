import React, {useEffect} from 'react';
import {LkRoutes} from "../../routes/LkRoutes";
import styled from "styled-components";
import {Sidebar} from "../../components/UI/Sidebar/Sidebar";
import {useIsMobile} from "../../hooks/useWindowSize";
import {useLocation, useNavigate} from "react-router";
import {lk} from "../../config/acl/routes/lk/lk";
import {profile} from "../../config/acl/routes/lk/lkCommon/profile";

const LkPageStyled = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  row-gap: 21px;
`;

export const LkPage = () => {
  const isMobile = useIsMobile();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === lk.path)
      navigate(profile.path);
  }, [pathname])

  return (
    <LkPageStyled>
      {!isMobile && <Sidebar />}
      <LkRoutes />
    </LkPageStyled>
  );
};