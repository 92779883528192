import React, {FC} from "react";
import {
  FlightDrawerFooterContent,
  FlightDrawerFooterContentWrapper, FlightDrawerFooterPrice,
  FlightDrawerFooterPriceLabel, FlightDrawerFooterPriceValue
} from "./FlightDrawerFooterStyledComponents";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {ISearchedEmptyLeg} from "../../../../store/reducers/searchReducer/searchReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {DisplayFlyPrice} from "../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";


const FlightDrawerSearchedEmptyLegFooterMessages = defineMessages({
  price: {
    id: "flightDrawerSearchedEmptyLegFooter.price",
    defaultMessage: "Price",
  },
  doRespond: {
    id: "flightDrawerSearchedEmptyLegFooter.doRespond",
    defaultMessage: "Respond",
  }
})

interface IFlightDrawerSearchedEmptyLegFooter {
  searchedEmptyLeg: ISearchedEmptyLeg;
  onRespondClick: () => void;
}

export const FlightDrawerSearchedEmptyLegFooter: FC<IFlightDrawerSearchedEmptyLegFooter> = ({
                                                                                              searchedEmptyLeg,
                                                                                              onRespondClick
                                                                                            }) => {
  const intl = useIntl();

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      <FlightDrawerFooterPrice>
        <FlightDrawerFooterPriceLabel>{intl.formatMessage(FlightDrawerSearchedEmptyLegFooterMessages.price)}</FlightDrawerFooterPriceLabel>
        <FlightDrawerFooterPriceValue>{DisplayFlyPrice(searchedEmptyLeg.price, searchedEmptyLeg.currency)}</FlightDrawerFooterPriceValue>
      </FlightDrawerFooterPrice>

      <CustomButton onClick={onRespondClick} type={"primary"}>{intl.formatMessage(FlightDrawerSearchedEmptyLegFooterMessages.doRespond)}</CustomButton>
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
};