import React, {Dispatch, FC, SetStateAction} from "react";
import {CustomDrawer} from "../../../UI/AntCustom/CustomDrawer";
import {BrokerDrawerContentWrapper} from "./BrokerDrawerStyledComponents";
import {BrokerDrawerManager} from "../manager/BrokerDrawerManager";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {ReactComponent as ChatIcon} from "../../../../media/icons/chat_white.svg";
import {BrokerDrawerCompany} from "../company/BrokerDrawerCompany";
import {
  IFreighterOrderRequest,
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {BrokerDrawerOrderRequestDetails} from "../details/BrokerDrawerOrderRequestDetails";
import {BrokerDrawerOrderRequestFooter} from "../footers/BrokerDrawerOrderRequestFooter";
import {defineMessages, useIntl} from "react-intl";


const OrderRequestBrokerDrawerMessages = defineMessages({
  writeToChat: {
    id: "orderRequestBrokerDrawer.writeToChat",
    defaultMessage: "Send message"
  },
})

interface IOrderRequestBrokerDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  orderRequest: IFreighterOrderRequest;
  openChat: () => void;
  openMakeOffer: () => void;
  openArchive: () => void;
}

export const OrderRequestBrokerDrawer: FC<IOrderRequestBrokerDrawer> = ({
                                                                          isOpen,
                                                                          setIsOpen,
                                                                          orderRequest,
                                                                          openChat,
                                                                          openMakeOffer,
                                                                          openArchive
                                                                        }) => {
  const intl = useIntl();

  const footer = orderRequest.isArchived
    ? null
    : <BrokerDrawerOrderRequestFooter orderRequest={orderRequest} closeBrokerModal={() => setIsOpen(false)}
                                      openArchive={openArchive} openMakeOffer={openMakeOffer}/>

  return (
    <CustomDrawer
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      footer={footer}
    >
      <BrokerDrawerContentWrapper>
        <BrokerDrawerOrderRequestDetails orderRequest={orderRequest}/>

        <BrokerDrawerManager manager={orderRequest.order.manager}/>

        <CustomButton type={"primary"} onClick={openChat} style={{borderRadius: 10, marginBottom: 10}}>
          <ChatIcon/>
          <div>{intl.formatMessage(OrderRequestBrokerDrawerMessages.writeToChat)}</div>
        </CustomButton>

        <BrokerDrawerCompany company={orderRequest.order.company}/>
      </BrokerDrawerContentWrapper>
    </CustomDrawer>
  );
};