import React, {Dispatch, FC, SetStateAction} from 'react';
import styled from "styled-components";
import {ReactComponent as CloseIcon} from "../../../../media/icons/close.svg"
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {DisplayFullName} from "../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import defaultAvatar from "../../../../media/images/default_avatar.png";
import {ExtendedMenuItem, LogoutMessage} from "../../../../hooks/useGenerateMenu";
import {HeaderMobileFirstLevelItem} from "./HeaderMobileFirstLevelItem";
import {HeaderMobileMenuSharedRouteItem} from "./HeaderMobileMenuSharedRouteItem";
import {ReactComponent as LogoutMenuItemIcon} from "../../../../media/icons/logout_mobile_menu.svg";
import {ReactComponent as Logo} from "../../../../media/icons/logo.svg";
import {logout} from "../../../../store/reducers/authReducer/authReducer";
import {login} from "../../../../config/acl/routes/all/auth/login";
import {register} from "../../../../config/acl/routes/all/auth/register";
import {useIntl} from "react-intl";
import {CustomButton} from "../../AntCustom/CustomButton";
import {useNavigate} from "react-router";


const HeaderMobileMenuBackground = styled.div<{isOpened: boolean}>`
  position: fixed;
  top: 0;
  width: 100vw;
  right: ${props => props.isOpened ? 0 : "-100vw"};
  z-index: 1099;
  height: 100vh;
  height: var(--app-height);
`;

const HeaderMobileMenuWrapper = styled.div<{isOpened: boolean}>`
  display: flex;
  position: fixed;
  flex-direction: column;
  background: #ffffff;
  right: ${props => props.isOpened ? 0 : "-281px"};
  top: 0;
  height: 100%;
  width: 281px;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.11);
  transition: right 0.2s ease;
  z-index: 1100;
`;

const MobileMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 67px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  column-gap: 8px;
  padding: 0 16px;
  border-bottom: 1px solid #D9D9D9;
`;

const CloseMobileMenuButton = styled.button`
  position: absolute;
  right: 10px;
  top: 12px;
  display: flex;
  width: 39px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  
  svg path {
    fill: #808080
  }
`;

const UserAvatarWrapper = styled.div`
  img {
    width: 29px;
    height: 29px;
    border-radius: 100%;
    object-fit: cover;
  }
`;

const HeaderMobileMenuSharedRoutesNotAuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 32px;
  row-gap: 16px;
  flex-grow: 1;
  justify-content: center;
`;

const HeaderMobileMenuSharedRoutesAuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 32px;
  row-gap: 16px;
  flex-grow: 1;
`;

const LogoutMenuItemWrapper = styled.div`
  position: absolute;
  bottom: 22px;
  left: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const AuthButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 22px 16px;
  
  button {
    width: 100%;
  }
`;


type MenuItemGroups = {
  groupedMenuItems: ExtendedMenuItem[];
  ungroupedMenuItems: ExtendedMenuItem[];
}


interface IHeaderMobileMenu {
  isMenuOpened: boolean;
  setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
  menuItems: ExtendedMenuItem[];
}

export const HeaderMobileMenu: FC<IHeaderMobileMenu> = ({isMenuOpened, setIsMenuOpened, menuItems}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const user = useAppSelector(state => state.user.oneItemObject.item)
  const {isAuthenticated} = useAppSelector(state => state.auth)
  const companyAvatar = useAppSelector(state => state.company.oneItemObject.item.avatar)
  const closeMenu = () => setIsMenuOpened(false);

  const {groupedMenuItems, ungroupedMenuItems} = menuItems.reduce((acc: MenuItemGroups, cur) => {
    if (cur.type === 'group') {
      cur.children && acc.groupedMenuItems.push(...cur.children);
      return acc;
    }
    if ([login.path, register.path, 'logout'].some(k => k === cur.key)) {
      return acc;
    }
    acc.ungroupedMenuItems.push(cur);
    return acc;
  }, {groupedMenuItems: [], ungroupedMenuItems: []});

  const stopCloseMenuPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const onNavigate = (path: string) => {
    navigate(path)
    closeMenu()
  }

  const onLogout = () => {
      dispatch(logout());
      closeMenu()
  }

  const notAuthenticatedMenuContent = <>
    <MobileMenuHeader>
      <Logo />
    </MobileMenuHeader>

    <HeaderMobileMenuSharedRoutesNotAuthWrapper>
      {ungroupedMenuItems.map(item => <HeaderMobileMenuSharedRouteItem key={item.key} menuItem={item} closeMenu={closeMenu} />)}
    </HeaderMobileMenuSharedRoutesNotAuthWrapper>

    <AuthButtonsWrapper>
      <CustomButton type={"primary"} ghost={true} onClick={() => onNavigate(login.path)}>{intl.formatMessage(login.title)}</CustomButton>
      <CustomButton type={"primary"} onClick={() => onNavigate(register.path)}>{intl.formatMessage(register.title)}</CustomButton>
    </AuthButtonsWrapper>
  </>

  const authenticatedMenuContent = <>
    <MobileMenuHeader>
      <div>{DisplayFullName(user, 'short')}</div>
      <UserAvatarWrapper>
        <img src={companyAvatar?.url ?? defaultAvatar} alt={'User avatar'} />
      </UserAvatarWrapper>
    </MobileMenuHeader>

    {groupedMenuItems.map(item => <HeaderMobileFirstLevelItem key={item.key} menuItem={item} closeMenu={closeMenu} />)}

    <HeaderMobileMenuSharedRoutesAuthWrapper>
      {ungroupedMenuItems.map(item => <HeaderMobileMenuSharedRouteItem key={item.key} menuItem={item} closeMenu={closeMenu} />)}
    </HeaderMobileMenuSharedRoutesAuthWrapper>

    <LogoutMenuItemWrapper onClick={onLogout}>
      <LogoutMenuItemIcon />
      <div>{intl.formatMessage(LogoutMessage)}</div>
    </LogoutMenuItemWrapper>
  </>

  return (
    <HeaderMobileMenuBackground isOpened={isMenuOpened} onClick={closeMenu}>
      <HeaderMobileMenuWrapper isOpened={isMenuOpened} onClick={stopCloseMenuPropagation}>
        <CloseMobileMenuButton onClick={() => setIsMenuOpened(false)}>
          <CloseIcon />
        </CloseMobileMenuButton>

        {isAuthenticated ? authenticatedMenuContent : notAuthenticatedMenuContent}
      </HeaderMobileMenuWrapper>
    </HeaderMobileMenuBackground>
  );
};
