import React from 'react';
import styled from "styled-components";
import sectionBackgroundImage from "../../../../media/images/planeCloud2.png";
import sectionBackgroundDesktopImage from "../../../../media/images/plane_clouds_desktop.jpg";
import PlaneIcon from "../../../../media/icons/plane-icon.svg";
import PassengerIcon from "../../../../media/icons/passenger-icon.svg";
import {FONTS} from "../../../../utils/constans/styles/fonts";
import {useNavigate} from "react-router";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {mediaScreenSizes, screenSizes} from "../../../../utils/constans/styles/screenSizes";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../config/acl/routes/Route";
import {defineMessages, useIntl} from "react-intl";
import {metricsGoals} from "../../../../utils/constans/metrics/goals";

const HomeFirstSectionStyled = styled.div`
  min-height: calc(100vh - 64px);
  overflow: hidden;
  background-size: cover;
  background-position: center;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    background-position: center;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    background-position: center;
  }
`;

const BackgroundGradient = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 64px);
  height: 100%;
  background: linear-gradient(-90deg, rgba(243, 245, 247, 0.18) 1.11%, rgba(243, 245, 247, 0) 35.24%, #F3F5F7 99.25%);

  @media (max-width: ${mediaScreenSizes.tablet}) {
    background: linear-gradient(90.46deg, rgba(243, 245, 247, 0.18) 1.11%, rgba(243, 245, 247, 0) 35.24%, #F3F5F7 99.25%);
  }
  
  @media (max-width: ${mediaScreenSizes.mobile}) {
    background: linear-gradient(358.16deg, #F3F5F7 -0.42%, rgba(243, 245, 247, 0) 35.54%, rgba(243, 245, 247, 0.86) 76.58%);
    padding-bottom: 169px;
  }
`;

const FirstSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1260px;
  min-height: calc(100vh - 64px);
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;
  padding: 0 90px;
  box-sizing: content-box;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 64px 27px;
    justify-content: space-between;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 12px 23px;
    justify-content: space-between;
  }
`;

const HeadingText = styled.h1`
  display: block;
  line-height: 80px !important;
  max-width: 1040px;
  letter-spacing: -0.01em;
  color: #323439;
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 101px;
  margin: 0;
  padding: 0;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 70px;
    line-height: 58px !important;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 45px;
    line-height: 37px !important;
  }
`;

const DescriptionAndButtons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 83px;
  width: auto;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    width: 100%;
    row-gap: 47px;
  }
`;

const HeadingDescription = styled.div`
  display: flex;
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  max-width: 588px;
  line-height: 27px;
  font-size: 18px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    max-width: 506px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
  }
`;

const BlueButton = styled.button`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  height: 64px;
  width: 330px;
  font-size: 16px;
  box-sizing: border-box;
  border: none;
  background-color: #1890FF;
  color: #F7F3F0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 12px 10px -5px rgba(0, 39, 76, 0.19);
  transition: 0.2s ease;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${mediaScreenSizes.tablet}) {
    width: auto;
    flex-grow: 1;
    background-color: #00529D;
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    width: auto;
    flex-grow: 1;
    background-color: #00529D;
  }
`;


const HomeFirstSectionMessages = defineMessages({
  title: {
    id: "homeFirstSection.title",
    defaultMessage: "Brand-new platform for searching business flights",
  },
  description: {
    id: "homeFirstSection.description",
    defaultMessage: "Find flights, post requests, post your aircraft, and find your passengers online",
  },
  findPlane: {
    id: "homeFirstSection.findPlane",
    defaultMessage: "Find flight",
  },
  findPassengers: {
    id: "homeFirstSection.findPassengers",
    defaultMessage: "Find passengers",
  }
})

export const HomeFirstSection = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {width} = useWindowSize();

  const onClick = () => {
    metricsGoals.openRegisterPageAfterVisitHome();
    navigate(pathFromRoute(Route.register));
  }

  return (
    <HomeFirstSectionStyled style={{backgroundImage: `url(${width > screenSizes.tablet ? sectionBackgroundDesktopImage : sectionBackgroundImage})`}}>
      <BackgroundGradient>
        <FirstSectionContainer>
          <HeadingText>
            {intl.formatMessage(HomeFirstSectionMessages.title)}
          </HeadingText>

          <DescriptionAndButtons>
            {width >= screenSizes.mobile && <HeadingDescription>
              {intl.formatMessage(HomeFirstSectionMessages.description)}
            </HeadingDescription>}
            <ButtonWrapper>
              <BlueButton onClick={onClick}>
                <img src={PlaneIcon} alt="PlaneIcon"/>
                {intl.formatMessage(HomeFirstSectionMessages.findPlane)}
              </BlueButton>
              <BlueButton onClick={onClick}>
                <img src={PassengerIcon} alt="Passenger"/>
                {intl.formatMessage(HomeFirstSectionMessages.findPassengers)}
              </BlueButton>
            </ButtonWrapper>
          </DescriptionAndButtons>
        </FirstSectionContainer>
      </BackgroundGradient>
    </HomeFirstSectionStyled>

  );
};

