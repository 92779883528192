import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult,
} from "../highOrderReducers/commonReducer";
import {mapRequestCallbackEntityToIClient} from "./requestCallbackMappers";
import {
  IGetClientListPayload,
  IRequestCallbackInitialState, IRequestCallbackPayload,
  ISetClientListResultPayload
} from "./requestCallbackReducerTypes";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";

const requestCallbackInitialState: IRequestCallbackInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  doRequestCallback: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const requestCallbackSlice = createSlice({
  name: 'requestCallback',
  initialState: requestCallbackInitialState,
  reducers: {
    getRequestCallbackList(state, {payload}: PayloadAction<IGetClientListPayload>) {
      initCommonGetList(state, payload);
    },
    clearRequestCallbackList(state) {
      initCommonClearList(state);
    },
    setRequestCallbackListResult(state, {payload}: PayloadAction<ISetClientListResultPayload>) {
      initCommonSetListResult(state, payload, mapRequestCallbackEntityToIClient);
    },

    doRequestCallback(state, {payload}: PayloadAction<IRequestCallbackPayload>) {
      initCommonAction(state, nameof<IRequestCallbackInitialState>("doRequestCallback"));
    },
    clearDoRequestCallback(state) {
      initCommonClearAction(state, nameof<IRequestCallbackInitialState>("doRequestCallback"));
    },
    setDoRequestCallbackResult(state, {payload}: PayloadAction<{ errorMessage?: string }>) {
      initCommonActionResult(state, payload, nameof<IRequestCallbackInitialState>("doRequestCallback"));
    },
  },
});

export const {
  getRequestCallbackList,
  clearRequestCallbackList,
  setRequestCallbackListResult,

  doRequestCallback,
  clearDoRequestCallback,
  setDoRequestCallbackResult,
} = requestCallbackSlice.actions;
export default requestCallbackSlice.reducer;