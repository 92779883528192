import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {
  BookingApiPlaneTypeListRequest,
  PageModel
} from "../../../proto/generated/api_entities_pb";
import {InitialPage} from "../../constans/pagination/pagination";
import {PageModelLimits} from "../../constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {IPlaneType} from "../../../store/reducers/fleetReducer/fleetReducerTypes";

export async function fetchPlaneTypes(search?: string): Promise<DebounceSelectOptionProps[]> {
  const req = new BookingApiPlaneTypeListRequest()
    .setQuery(search ?? "")
    .setPagemodel(
      new PageModel().setPage(InitialPage).setLimit(PageModelLimits.Select)
    )

  const airportList = await API.getPlaneTypeList(req);

  return airportList.getPlanetypesList().map(pt => {
    return {
      key: pt.getPlanetypeid(),
      label: pt.getName(),
      value: pt.getPlanetypeid(),
    }
  })
}

export const getInitialPlaneTypeForSelect = (planeType: IPlaneType): DebounceSelectOptionProps => {
  return {
    key: planeType.id,
    value: planeType.id,
    label: planeType.name,
  }
}