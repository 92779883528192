import {defineMessages} from "react-intl";
import {useEnumIntl} from "../../../intl/helpers/useEnumIntl";

export enum EmptyLegDateTypeEnum {
  RANGE,
  SPECIFIC,
}

const EmptyLegDateTypeNameMessages = defineMessages({
  range: {
    id: "statuses.emptyLegDateType.range",
    defaultMessage: "Date range",
  },
  specific: {
    id: "statuses.emptyLegDateType.specific",
    defaultMessage: "Exact date",
  },
})

export const useEmptyLegDateTypeName = (status: EmptyLegDateTypeEnum) => {
  return useEnumIntl(status, {
    [EmptyLegDateTypeEnum.RANGE]: EmptyLegDateTypeNameMessages.range,
    [EmptyLegDateTypeEnum.SPECIFIC]: EmptyLegDateTypeNameMessages.specific,
  })
}