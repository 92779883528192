import React, {FC} from 'react';
import {Switch, SwitchProps} from "antd";
import styled from "styled-components";

const SwitchStyled = styled(Switch)`
  min-width: 30px;
  height: 17px;
  .ant-switch-checked, .ant-switch-handle {
    left: ${props => props.checked ? "calc(100% - 15px)" : "2px"};
  } 
  
  .ant-switch-handle {
    width: 13px;
    height: 13px;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`;

const SwitchLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1D3658;
`;


interface ICustomSwitch extends SwitchProps {
  label?: string;
}

export const CustomSwitch: FC<ICustomSwitch> = ({label, ...props}) => {
  return (
    <SwitchWrapper>
      <SwitchStyled {...props} />
      {label && <SwitchLabel>{label}</SwitchLabel>}
    </SwitchWrapper>
  );
};
