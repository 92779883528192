import React, {FC, ReactNode} from 'react';
import {IManager} from "../../../../store/reducers/personnelManagementReducer/personnelManagementReducerTypes";
import styled from "styled-components";
import {ReactComponent as PhoneIcon} from "../../../../media/icons/phone.svg";
import {ReactComponent as EmailIcon} from "../../../../media/icons/email.svg";
import {DisplayManagerFullName} from "../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";

const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 16px 20px;
  border-bottom: 1px solid #D9D9D9;
`;

const Content = styled.div`
  padding: 16px 20px 32px 16px;
`;

const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`;

const ContentDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6B6B6B;
  margin-top: 16px;
`;

const ContentDescriptionContactItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 8px;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
`;


const BrokerDrawerManagerMessages = defineMessages({
  title: {
    id: "brokerDrawerManager.title",
    defaultMessage: "Broker data",
  },
  contactInfo: {
    id: "brokerDrawerManager.contactInfo",
    defaultMessage: "Contact information",
  },
})

interface IContactItem {
  icon: ReactNode;
  value: string | ReactNode;
}

interface IBrokerDrawerManager {
  manager: IManager;
}

export const BrokerDrawerManager: FC<IBrokerDrawerManager> = ({manager}) => {
  const intl = useIntl();

  const managerContactItems: IContactItem[] = [
    {icon: <PhoneIcon/>, value: manager.phone ? `+${manager.phone}` : undefined},
    {icon: <EmailIcon/>, value: manager.email},
  ];

  return (
    <Wrapper>
      <Title>{intl.formatMessage(BrokerDrawerManagerMessages.title)}</Title>
      <Content>
        <ContentTitle>{DisplayManagerFullName(manager)}</ContentTitle>
        <ContentDescription>{intl.formatMessage(BrokerDrawerManagerMessages.contactInfo)}</ContentDescription>

        {managerContactItems.filter(item => !!item.value).map((item, idx) => <ContentDescriptionContactItem key={idx}>
          {item.icon}
          <div>{item.value}</div>
        </ContentDescriptionContactItem>)}
      </Content>
    </Wrapper>
  );
};
