import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {message, Modal} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {ButtonProps} from "antd/lib/button/button";
import {IRegistration} from "../../../../store/reducers/registrationsReducer/registrationsReducerTypes";
import {
  changeRegistrationStatus,
  clearChangeRegistrationStatus
} from "../../../../store/reducers/registrationsReducer/registrationsReducer";
import {UserStatus} from "../../../../utils/constans/statuses/userStatuses";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";
import {CommonErrorMessages} from "../../../../intl/commonMessages/CommonErrorMessages";
import {CustomModal} from "../../../../components/UI/AntCustom/CustomModal";


const HandleUserRegistrationModalMessages = defineMessages({
  confirmRegisterTitle: {
    id: "handleUserRegistrationModal.confirmRegisterTitle",
    defaultMessage: "Registration confirmation",
  },
  confirmRegisterDescription: {
    id: "handleUserRegistrationModal.confirmRegisterDescription",
    defaultMessage: "Confirm user registration",
  },
  rejectRegisterTitle: {
    id: "handleUserRegistrationModal.rejectRegisterTitle",
    defaultMessage: "Reject registration",
  },
  rejectRegisterDescription: {
    id: "handleUserRegistrationModal.rejectRegisterDescription",
    defaultMessage: "Reject user registration",
  },
})

interface IHandleUserRegistrationModal {
  user: IRegistration;
  statusId: number;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const HandleUserRegistrationModal: FC<IHandleUserRegistrationModal> = ({user, statusId, isVisible, setIsVisible }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.registrations.changeRegistrationStatus);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        setIsVisible(false);
      if (errorMessage)
        message.error(errorMessage);
    }

    return () => {
      dispatch(clearChangeRegistrationStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, errorMessage, isLoading])

  let title: string = '';
  let description: string = '';
  let okButtonProps: ButtonProps = {};
  let okText: string = "";

  switch (statusId) {
    case UserStatus.CONFIRMED:
      title = intl.formatMessage(HandleUserRegistrationModalMessages.confirmRegisterTitle);
      description = `${intl.formatMessage(HandleUserRegistrationModalMessages.confirmRegisterDescription)} ${user.lastName} ${user.firstName} ${user.middleName}?`;
      okButtonProps = {loading: isLoading};
      okText = intl.formatMessage(CommonButtonMessages.confirm);
      break;
    case UserStatus.REJECTED:
      title = intl.formatMessage(HandleUserRegistrationModalMessages.rejectRegisterTitle);
      description = `${intl.formatMessage(HandleUserRegistrationModalMessages.rejectRegisterDescription)} ${user.lastName} ${user.firstName} ${user.middleName}?`;
      okButtonProps = {danger: true, type: "primary", loading: isLoading};
      okText = intl.formatMessage(CommonButtonMessages.reject);
      break;
    default:
      message.error(intl.formatMessage(CommonErrorMessages.unknown));
      setIsVisible(false)
      break;
  }

  return (
    <CustomModal
      visible={isVisible}
      title={title}
      centered
      okButtonProps={okButtonProps}
      okText={okText}
      cancelText={intl.formatMessage(CommonButtonMessages.cancel)}
      onCancel={() => setIsVisible(false)}
      onOk={() => dispatch(changeRegistrationStatus({userId: user.id, userStatusId: statusId}))}
      afterClose={() => dispatch(clearChangeRegistrationStatus())}
    >
      {description}
    </CustomModal>
  );
};
