import React from 'react';
import {HomeFirstSection} from "./components/HomeFirstSection";
import {HomeSecondSection} from "./components/HomeSecondSection";
import styled from "styled-components";
import {HomeThirdSection} from "./components/HomeThirdSection";
import {HomeFourthSection} from "./components/HomeFourthSection";
import {HomeFifthSection} from "./components/HomeFifthSection";

const HomePageStyled = styled.div`
  height: 100%;
  width: 100%;
  background: #F3F5F7;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
`
export const HomePage = () => {


  return (
    <HomePageStyled>
      <HomeFirstSection/>
      <HomeSecondSection/>
      <HomeThirdSection/>
      <HomeFourthSection />
      <HomeFifthSection />
    </HomePageStyled>

  );
};

