import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {LoginPage} from "../../../../../pages/General/Login/LoginPage";
import {Role} from "../../../roles/Role";
import {groupNotGuest} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const LoginRouteTitleMessage = defineMessage({
  id: "loginRoute.title",
  defaultMessage: "Log in",
})

export const login: RouteDescriptor<Route.login> = {
  route: Route.login,
  render: LoginPage,
  title: LoginRouteTitleMessage,
  path: '/login',
  allowRoles: [
    Role.guest,
  ],
  redirectToHomeRoles: [
    ...groupNotGuest,
  ],
  showInSidebar: false,
}