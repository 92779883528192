export const PageModelLimits = {
  PersonnelCards: 12,
  UserCards: 12,
  EmptyLegs: 10,
  TripBoard: 10,
  AgencyList: 12,
  Search: 10,
  MyRequests: 10,
  ClientList: 12,
  Select: 10,
  Planes: 12,
  Notifications: 20,
  FlyCompanies: 25,
}