import {FileUploaderClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {wrapClient} from "../helpers/CommonClientMethod";
import {getProxyUrl} from "../helpers/getProxyUrl";

const wrap = wrapClient(new FileUploaderClient(getProxyUrl(), null, null));

// TODO пока сделано так из-за специфичности миксина классов
export class FilesApi {
  static unaryUploadFile = wrap.unaryUploadFile;
  static fileList = wrap.fileList;
  static fileDelete = wrap.fileDelete;
}
/*
TODO Нужно все апи привести в вид
api = {
  booking: wrapClient(new BookingApiClient(...))
  files: wrapClient(new FileUploaderClient(...))
}

и вынести отдельно метод call в функцию
это полностью избавит от копипасты, слой архитектуры станет автогенерируемым с полной поддержкой типизации
 */

