import {API} from "../../../API/API";
import {FileApiUploadRequest, FileInfo} from "../../../proto/generated/api_entities_pb";
import {Logger} from "../../logger/Logger";
import {getErrorMessage} from "../../errorHelpers/getCommonErrorMessage";
import {UploadFileResponseType} from "./fileTypes";
import {UploadFile} from "antd/es/upload";


export async function getFileInfoAfterUpload(uploadFile: UploadFile): Promise<UploadFileResponseType> {
  return new Promise(async (resolve, reject) => {
    try {
      const file = uploadFile as unknown as File;

      const fileInfo = new FileInfo()
        .setName(file.name)
        .setSize(file.size)

      const fileReq = new FileApiUploadRequest()
        .setFileinfo(fileInfo);

      if (await file.arrayBuffer) {
        // This is the case when file is loaded from user's host machine
        fileReq.setBytes(new Uint8Array(await file.arrayBuffer()))
      } else if (uploadFile.fileName && uploadFile.url) {
        // This is the case when file is already loaded from server, so we don't need to upload it again
        resolve({
          fileId: uploadFile.uid,
          fileName: uploadFile.fileName,
          filePath: uploadFile.url,
        });
        return;
      } else {
        throw new Error("Отсутствует буффер. Отсутствуют поля fileName и url у объекта файла.")
      }

      const fileResponse = await API.unaryUploadFile(fileReq);

      if (!fileResponse.getResultresponse()?.getIssuccessful()) {
        throw new Error("Файл не удалось загрузить");
      } else {
        resolve({
          fileId: fileResponse.getFileid(),
          fileName: fileResponse.getFilename(),
          filePath: fileResponse.getFilepath(),
        })
      }
    } catch (e) {
      Logger.error(getErrorMessage(e))
      reject(getErrorMessage(e))
    }
  })
}