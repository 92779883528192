import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {MyEmptyLegsPage} from "../../../../../pages/Lk/OperatorsOnly/MyEmptyLegs/MyEmptyLegsPage";
import {Role} from "../../../roles/Role";
import {defineMessage} from "react-intl";

const EmptyLegsRouteTitleMessage = defineMessage({
  id: "emptyLegsRoute.title",
  defaultMessage: "Empty Legs",
})

export const emptyLegs: RouteDescriptor<Route.emptyLegs> = {
  route: Route.emptyLegs,
  render: MyEmptyLegsPage,
  title: EmptyLegsRouteTitleMessage,
  path: '/lk/my-empty-legs',
  allowRoles: [
    Role.freighterAdmin,
    Role.freighterServiceManager,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}