import {ScheduleApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  ScheduleApiScheduleListRequest,
  ScheduleApiScheduleListResponse,
  ScheduleApiScheduleRequest,
  ScheduleApiScheduleResponse,
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new ScheduleApiServiceClient(getProxyUrl(), null, null);

export class ScheduleApi {
  static async getSchedule(req: ScheduleApiScheduleListRequest): Promise<ScheduleApiScheduleListResponse> {
    return new Promise((resolve, reject) => {
      API.call<ScheduleApiScheduleListRequest, ScheduleApiScheduleListResponse>(client, client.scheduleList, req, resolve, reject);
    });
  }

  static async createEvent(req: ScheduleApiScheduleRequest): Promise<ScheduleApiScheduleResponse> {
    return new Promise((resolve, reject) => {
      API.call<ScheduleApiScheduleRequest, ScheduleApiScheduleResponse>(client, client.scheduleCreate, req, resolve, reject);
    });
  }

  static async editEvent(req: ScheduleApiScheduleRequest): Promise<ScheduleApiScheduleResponse> {
    return new Promise((resolve, reject) => {
      API.call<ScheduleApiScheduleRequest, ScheduleApiScheduleResponse>(client, client.scheduleUpdate, req, resolve, reject);
    });
  }

  static async deleteEvent(req: ScheduleApiScheduleRequest): Promise<ScheduleApiScheduleResponse> {
    return new Promise((resolve, reject) => {
      API.call<ScheduleApiScheduleRequest, ScheduleApiScheduleResponse>(client, client.scheduleDelete, req, resolve, reject);
    });
  }
}