import React, {FC} from 'react';
import {IFreighterOrderRoute} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import styled from "styled-components";
import {defineMessages, useIntl} from "react-intl";
import {ReactComponent as FlyRouteArrowIcon} from "../../../../media/icons/route_arrow.svg"
import {DisplayAirport} from "../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {DetailItem} from "../../detailItem/DetailItem";
import {CommonDetailsMessages} from "../../../../intl/commonMessages/CommonDetailsMessages";
import {formatDate} from "../../../../utils/helpers/dateHelpers/dateHelpers";

const Wrapper = styled.div`
  display: flex;
  padding: 16px 20px 24px 20px;
  border-top: 1px solid #D9D9D9;
  flex-direction: column;
  row-gap: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const FlyRoutes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > div {
    :nth-child(1), :nth-child(3) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      
      & > div {
        color: #000000;

        :nth-child(1) {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          opacity: 0.5;
        }

        :nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    :nth-child(2) {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const RouteDetails = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  
  & > div {
    flex-grow: 1;
  }
`;


const BrokerDrawerOrderRequestFlyRouteMessages = defineMessages({
  title: {
    id: "brokerDrawerOrderRequestFlyRoute.title",
    defaultMessage: "Route",
  },
  from: {
    id: "brokerDrawerOrderRequestFlyRoute.from",
    defaultMessage: "From",
  },
  to: {
    id: "brokerDrawerOrderRequestFlyRoute.to",
    defaultMessage: "To",
  }
})

interface IBrokerDrawerOrderRequestFlyRoute {
  num: number
  orderRoute: IFreighterOrderRoute;
}

export const BrokerDrawerOrderRequestFlyRoute: FC<IBrokerDrawerOrderRequestFlyRoute> = ({num, orderRoute}) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Title>{intl.formatMessage(BrokerDrawerOrderRequestFlyRouteMessages.title)} {num}</Title>

      <FlyRoutes>
        <div>
          <div>{intl.formatMessage(BrokerDrawerOrderRequestFlyRouteMessages.from)}</div>
          <div>{DisplayAirport(orderRoute.airportFrom, "codes")}</div>
        </div>

        <div>
          <FlyRouteArrowIcon />
        </div>

        <div>
          <div>{intl.formatMessage(BrokerDrawerOrderRequestFlyRouteMessages.to)}</div>
          <div>{DisplayAirport(orderRoute.airportTo, "codes")}</div>
        </div>
      </FlyRoutes>

      <RouteDetails>
        <DetailItem
          label={intl.formatMessage(CommonDetailsMessages.departureDate)}
          value={formatDate(orderRoute.departureDate, orderRoute.airportFrom.timezone.offset, "datetime")}
        />
        <DetailItem
          label={intl.formatMessage(CommonDetailsMessages.pax)}
          value={orderRoute.pax.toString()}
        />
      </RouteDetails>
    </Wrapper>
  );
};
