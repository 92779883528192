import React, {FC} from 'react';
import styled from "styled-components";
import {WarningOutlined} from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.3;
  color: #000000;
  
  svg > path {
    fill: #000000;
  }
`;

interface IWarningShield {
  warningText: string;
  [key: string]: any;
}

export const WarningShield: FC<IWarningShield> = ({warningText, ...props}) => {
  return (
    <Wrapper {...props}>
      <WarningOutlined />
      <div>{warningText}</div>
    </Wrapper>
  );
};
