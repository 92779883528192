import {GreeterClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {HelloReply, HelloRequest} from "../../proto/generated/api_entities_pb";
import {API} from "../API";
import {getProxyUrl} from "../helpers/getProxyUrl";


const client = new GreeterClient(getProxyUrl(), null, null);

export class GreeterApi {
  static async sayHello(req: HelloRequest): Promise<HelloReply> {
    return new Promise((resolve, reject) => {
      API.call<HelloRequest, HelloReply>(client, client.sayHello, req, resolve, reject);
    });
  }
}