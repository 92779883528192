import React, {FC, ReactNode} from 'react';
import styled from "styled-components";

const StyledButton = styled.button<{color: string}>`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  background: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  outline: none;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  padding: 0;
  transition: opacity 0.2s ease;
  
  svg path {
    fill: ${props => props.color};
  }

  :hover, :focus {
    opacity: 0.8;
  }
`;

interface ICustomLinkButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  icon?: ReactNode;
  color?: string;
  [key: string]: any;
}

export const CustomLinkButton: FC<ICustomLinkButton> = ({children, icon, color="#808080", ...props}) => {
  return (
    <StyledButton color={color} {...props}>
      {icon}
      {children && <div>{children}</div>}
    </StyledButton>
  );
};
