import styled from "styled-components";
import Search from "antd/es/input/Search";

export const CustomSearch = styled(Search)`
  .ant-input {
    height: 46px;
    border-radius: 5px 0 0 5px;
  }
  
  .ant-input-group-addon {
    height: 46px;
    
    .ant-btn, .ant-btn-default, .ant-btn-icon-only, .ant-input-search-button {
      height: 46px;
      border-radius: 0 5px 5px 0 !important;
    }
  }
`;