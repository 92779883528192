import {RoleDescriptor} from "../RoleDescriptor";
import {Route} from "../../routes/Route";
import {Role} from "../Role";
import {defineMessage} from "react-intl";

const UnknownMessage = defineMessage({
  id: "unknown.name",
  defaultMessage: "Unknown",
})

export const unknown: RoleDescriptor<Role.unknown> = {
  role: Role.unknown,
  name: UnknownMessage,
  backendIdentifier: null,
  homeRoute: Route.home,
  navigation: {
    profile: [],
    sidebar: [],
  },
}