import {defineMessages} from "react-intl";

export const CommonUserMessages = defineMessages({
  email: {
    id: "app.user.email",
    defaultMessage: "Email",
  },
  role: {
    id: "app.user.role",
    defaultMessage: "Role",
  },
  company: {
    id: "app.user.company",
    defaultMessage: "Company",
  },
  status: {
    id: "app.user.status",
    defaultMessage: "Status",
  },
  licensesAndFiles: {
    id: "app.user.licensesAndFiles",
    defaultMessage: "Licenses and files",
  },
})