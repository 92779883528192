import {useEnumIntl} from "../../../intl/helpers/useEnumIntl";
import {defineMessages} from "react-intl";

// *** DO NOT CHANGE WITHOUT BACKEND DEVELOPERS AGREEMENT ***
export enum CompanyStatus {
  REGISTERED = 1,
  ACTIVE = 2,
  BLOCKED = 3,
  UNKNOWN = 4,
}

const CompanyStatusNameMessages = defineMessages({
  registered: {
    id: "statuses.companyStatus.registered",
    defaultMessage: "Registered",
  },
  active: {
    id: "statuses.companyStatus.active",
    defaultMessage: "Active",
  },
  blocked: {
    id: "statuses.companyStatus.blocked",
    defaultMessage: "Blocked",
  },
  unknown: {
    id: "statuses.companyStatus.unknown",
    defaultMessage: "Not specified",
  }
})

export const useCompanyStatusName = (status: CompanyStatus) => {
  return useEnumIntl(status, {
    [CompanyStatus.REGISTERED]: CompanyStatusNameMessages.registered,
    [CompanyStatus.ACTIVE]: CompanyStatusNameMessages.active,
    [CompanyStatus.BLOCKED]: CompanyStatusNameMessages.blocked,
    [CompanyStatus.UNKNOWN]: CompanyStatusNameMessages.unknown,
  });
}