import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {AgentsPage} from "../../../../../pages/General/Agents/AgentsPage";
import {groupAll} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const AgentsRouteTitleMessage = defineMessage({
  id: "agentsRoute.title",
  defaultMessage: "Find broker",
})

export const agents: RouteDescriptor<Route.agents> = {
  route: Route.agents,
  render: AgentsPage,
  title: AgentsRouteTitleMessage,
  path: '/agents',
  allowRoles: [
    ...groupAll,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: false,
}