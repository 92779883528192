import {CompanyEntity} from "../../../proto/generated/api_entities_pb";
import {IAgency} from "./agencyReducerTypes";
import {UploadFile} from "antd/es/upload";

export const mapCompanyEntityToIAgency = (item: CompanyEntity): IAgency => {
  const admin = item.getAdmin();
  if (!admin)
    throw new Error("No UserEntity (admin) in received CompanyEntity")

  const avatar = item.getAvatar();
  let avatarMapped: UploadFile | undefined;
  if (avatar?.getFileid()) {
    avatarMapped = {
      uid: avatar.getFileid(),
      name: avatar.getName(),
      fileName: avatar.getName(),
      url: avatar.getFilepath(),
    }
  }

  return {
    companyName: item.getCompanyname(),
    companyId: item.getCompanyid(),
    avatar: avatarMapped,
    admin: {
      firstName: admin.getFirstname(),
      lastName: admin.getLastname(),
      middleName: admin.getMiddlename(),
    },
  }
}