import {IUser} from "../../../store/reducers/userReducer/userReducerTypes";
import {IManager} from "../../../store/reducers/personnelManagementReducer/personnelManagementReducerTypes";

export const DisplayFullName = (user: IUser, type: 'full' | 'short' = 'full'): string => {
  const userFullNameParts = [user.lastName, user.firstName];

  if (type === 'short')
    return `${user.lastName} ${user.firstName && `${user.firstName.slice(0, 1)}.`} ${user.middleName && (user.middleName?.slice(0, 1) + ".")}`

  if (user.middleName)
    userFullNameParts.push(user.middleName);

  return userFullNameParts.join(' ');
}

export const DisplayManagerFullName = (manager: IManager): string => {
  const managerFullNameParts = [manager.lastName, manager.name];

  //  TODO need for middlename?
  // if (manager.middleName)
  //   managerFullNameParts.push(manager.middleName);

  return managerFullNameParts.join(' ');
}