import React, {FC, useMemo, useState} from 'react';
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {EditEmptyLegModal} from "./EditEmptyLegModal";
import {ArchiveEmptyLegModal} from "./ArchiveEmptyLegModal";
import {EmptyLegRespondsCollapse} from "./EmptyLegRespondsCollapse";
import {IEmptyLeg} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {PlaneImage} from "../../../../../components/businessCommon/planeImage/PlaneImage";
import {ReactComponent as DeleteIcon} from "../../../../../media/icons/delete.svg";
import styled from "styled-components";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {
  EmptyLegFlightDrawer
} from "../../../../../components/businessCommon/flightDrawer/wrappers/EmptyLegFlightDrawer";
import {useAppSelector} from "../../../../../store/store";
import {DisplayRoute} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {DisplayFlyPrice} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const EmptyLegCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyLegCardMainContent = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 0 0;
  border: 1px solid #D9D9D9;
  background: #FAFAFA;
  border-bottom: none;

  img {
    border-radius: 10px 0 0 0;
    
    @media(max-width: ${mediaScreenSizes.mobile}) {
      border-radius: 7px;
      width: 92px;
      height: 72px;
    }
  }
`;

const EmptyLegInfoWrapper = styled.div`
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-grow: 1;
`;

const EmptyLegInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

const EmptyLegInfoTitleAndDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    justify-content: flex-end;
    flex-direction: column-reverse;
    row-gap: 8px;
  }
`;

const EmptyLegInfoHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  align-items: center;

  div {
    :first-child {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    :last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #262526;
    }
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const EmptyLegInfoHeaderDateAndDelete = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;

  div {
    :first-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.01em;
    }

    :last-child {
      display: flex;
      cursor: pointer;
      transition: opacity 0.2s ease;

      :hover {
        opacity: 0.5;
      }
      
      svg {
        width: 22px;
        height: 22px;

        @media(max-width: ${mediaScreenSizes.mobile}) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

const EmptyLegInfoDetailsAndAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
    
    button {
      width: 100%;
    }
  }
`;

const EmptyLegInfoDetails = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    row-gap: 8px;
  }
`;

const EmptyLegInfoDetailsItem = styled.div`
  display: flex;
  flex-direction: column;

  div {
    :first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      justify-content: flex-start;
    }

    :last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

interface IEmptyLegCard {
  emptyLeg: IEmptyLeg;
}

export const EmptyLegCard: FC<IEmptyLegCard> = ({emptyLeg}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [isFlightDrawerOpen, setIsFlightDrawerOpen] = useState<boolean>(false);

  const emptyLegRespondsCollapse = useMemo(() => <EmptyLegRespondsCollapse emptyLeg={emptyLeg}/>, [emptyLeg])

  const emptyLegFlightDrawer = useMemo(() => <EmptyLegFlightDrawer isOpen={isFlightDrawerOpen}
                                                                   setIsOpen={setIsFlightDrawerOpen} emptyLeg={emptyLeg}
                                                                   setIsEditModalOpen={setIsEditModalOpen}
                                                                   setIsArchiveModalOpen={setIsArchiveModalOpen}/>, [isFlightDrawerOpen])

  const editEmptyLegModal = useMemo(() => <EditEmptyLegModal emptyLeg={emptyLeg} isModalVisible={isEditModalOpen}
                                                             setIsModalVisible={setIsEditModalOpen}/>, [isEditModalOpen])

  const archiveEmptyLegModal = useMemo(() => <ArchiveEmptyLegModal emptyLeg={emptyLeg}
                                                                   isModalVisible={isArchiveModalOpen}
                                                                   setIsModalVisible={setIsArchiveModalOpen}/>, [isArchiveModalOpen])

  const planeImage = <PlaneImage files={emptyLeg.plane.imageFiles} height={"100%"}/>

  return (
    <EmptyLegCardWrapper>
      <EmptyLegCardMainContent>
        {!isMobile && planeImage}
        <EmptyLegInfoWrapper>

          <EmptyLegInfoHeader>
            {isMobile && planeImage}

            <EmptyLegInfoTitleAndDate>
              <EmptyLegInfoHeaderTitle>
                <div>{emptyLeg.plane.planeType.name}</div>
                <div>{emptyLeg.plane.registrationNumber}</div>
              </EmptyLegInfoHeaderTitle>

              <EmptyLegInfoHeaderDateAndDelete>
                {emptyLeg.dateModified && <div>{formatDate(emptyLeg.dateModified, offset, isMobile ? "date" : "datetime")}</div>}
                {!emptyLeg.isArchived && <div onClick={() => setIsArchiveModalOpen(true)}><DeleteIcon/></div>}
              </EmptyLegInfoHeaderDateAndDelete>
            </EmptyLegInfoTitleAndDate>
          </EmptyLegInfoHeader>

          <EmptyLegInfoDetailsAndAction>
            <EmptyLegInfoDetails>
              <EmptyLegInfoDetailsItem>
                <div>{intl.formatMessage(CommonDetailsMessages.route)}</div>
                <div>{DisplayRoute([emptyLeg.airportFrom, emptyLeg.airportTo], "codes")}</div>
              </EmptyLegInfoDetailsItem>
              <EmptyLegInfoDetailsItem>
                <div>{intl.formatMessage(CommonDetailsMessages.date)}</div>
                {emptyLeg.availableFromDate && emptyLeg.availableToDate &&
                  <div>{formatDate(emptyLeg.availableFromDate, emptyLeg.airportFrom.timezone.offset)} → {formatDate(emptyLeg.availableToDate, emptyLeg.airportFrom.timezone.offset)}</div>}

                {emptyLeg.departureDate &&
                  <div>{formatDate(emptyLeg.departureDate, emptyLeg.airportFrom.timezone.offset)}</div>}
              </EmptyLegInfoDetailsItem>
              <EmptyLegInfoDetailsItem>
                <div>{intl.formatMessage(CommonDetailsMessages.price)}</div>
                <div>{DisplayFlyPrice(emptyLeg.price, emptyLeg.currency)}</div>
              </EmptyLegInfoDetailsItem>
            </EmptyLegInfoDetails>
            <CustomButton heightSize={'m'} type={"primary"}
                          onClick={() => setIsFlightDrawerOpen(true)}>{intl.formatMessage(CommonButtonMessages.look)}</CustomButton>
          </EmptyLegInfoDetailsAndAction>
        </EmptyLegInfoWrapper>
      </EmptyLegCardMainContent>

      {emptyLegRespondsCollapse}
      {isFlightDrawerOpen && emptyLegFlightDrawer}
      {isEditModalOpen && editEmptyLegModal}
      {isArchiveModalOpen && archiveEmptyLegModal}
    </EmptyLegCardWrapper>
  );
};
