import {Role} from "../../../config/acl/roles/Role";
import {roles} from "../../../config/acl/roles";

export const roleFromBackendIdentifier = (backendIdentifier: string): Role => {
  const roleDescriptor = Object.values(roles)
    .filter(roleDescriptor => roleDescriptor.backendIdentifier !== null)
    .find(roleDescriptor => roleDescriptor.backendIdentifier === backendIdentifier)
  ;

  if (!roleDescriptor) {
    throw new Error(`No role found for identifier: ${backendIdentifier}`);
  }

  return roleDescriptor.role;
}

// Exclude<Role, Role.guest | Role.unknown> можно попробовать это использовать в дальнейшем

export const backendIdentifierFromRole = (role: Role): string => {
  const roleDescriptor = Object.values(roles)
    .filter(roleDescriptor => roleDescriptor.backendIdentifier !== null)
    .find(roleDescriptor => roleDescriptor.role === role)
  ;

  if (!roleDescriptor) {
    throw new Error(`No backend identifier for role: ${role}`);
  }

  return roleDescriptor.backendIdentifier as string;
}