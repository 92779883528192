import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {MyClientsRequestsPage} from "../../../../../pages/Lk/BrokersOnly/MyClientsRequests/MyClientsRequestsPage";
import {groupCharterer} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const RequestsFromClientsRouteTitleMessage = defineMessage({
  id: "requestsFromClientsRoute.title",
  defaultMessage: "Clients",
})

export const requestsFromClients: RouteDescriptor<Route.requestsFromClients> = {
  route: Route.requestsFromClients,
  render: MyClientsRequestsPage,
  title: RequestsFromClientsRouteTitleMessage,
  path: '/lk/my-clients-requests',
  allowRoles: [
    ...groupCharterer,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}