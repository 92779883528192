// source: api_entities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.protobuf.AirportEntity', null, global);
goog.exportSymbol('proto.protobuf.AirportRouteMetadataEntity', null, global);
goog.exportSymbol('proto.protobuf.AirportShortEntity', null, global);
goog.exportSymbol('proto.protobuf.AuthApiForgotPasswordCheckCodeRequest', null, global);
goog.exportSymbol('proto.protobuf.AuthApiForgotPasswordRequest', null, global);
goog.exportSymbol('proto.protobuf.AuthApiForgotPasswordSetupRequest', null, global);
goog.exportSymbol('proto.protobuf.AuthApiRegistrationRequest', null, global);
goog.exportSymbol('proto.protobuf.AuthApiRequest', null, global);
goog.exportSymbol('proto.protobuf.AuthApiResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiCurrencyListRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiCurrencyListResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiGetInvoiceListRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiGetInvoiceListResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiInvoiceRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiInvoiceResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiSubscriptionPricesResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTariffPackageListResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTariffPackageRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTariffPackageResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTariffRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTariffResponse', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTransactionRequest', null, global);
goog.exportSymbol('proto.protobuf.BillingApiTransactionResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiAirportListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiAirportListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiAirportShortListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiAirportShortListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegOrderRouteRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegOrderRouteResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegStatusListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiEmptyLegStatusListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiGetAirportRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiGetAirportResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderListRequest.OrdertypeCase', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestStatusListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRequestStatusListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRouteListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRouteListRequest.OrderroutetypeCase', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRouteListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRouteRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderRouteResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderStatusListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiOrderStatusListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneCategoryListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneCategoryListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneShortListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneTypeListRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneTypeListResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneTypeRequest', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneTypeResponse', null, global);
goog.exportSymbol('proto.protobuf.BookingApiPlaneTypeShortListResponse', null, global);
goog.exportSymbol('proto.protobuf.ChatEntity', null, global);
goog.exportSymbol('proto.protobuf.ChatMessageEntity', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatListRequest', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatListResponse', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatMessageListRequest', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatMessageListResponse', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatMessageSendRequest', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiChatMessageSendResponse', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiRequestCallbackListRequest', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiRequestCallbackListResponse', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiRequestCallbackRequest', null, global);
goog.exportSymbol('proto.protobuf.CommunicationApiRequestCallbackResponse', null, global);
goog.exportSymbol('proto.protobuf.CommunicationWebsocketMessage', null, global);
goog.exportSymbol('proto.protobuf.CompanyEntity', null, global);
goog.exportSymbol('proto.protobuf.CompanyTariffPackageEntity', null, global);
goog.exportSymbol('proto.protobuf.CurrencyEntity', null, global);
goog.exportSymbol('proto.protobuf.EmptyLegEntity', null, global);
goog.exportSymbol('proto.protobuf.EmptyLegOrderRouteEntity', null, global);
goog.exportSymbol('proto.protobuf.EmptyLegStatusEntity', null, global);
goog.exportSymbol('proto.protobuf.EmptyRequest', null, global);
goog.exportSymbol('proto.protobuf.FileApiFileListRequest', null, global);
goog.exportSymbol('proto.protobuf.FileApiFileListResponse', null, global);
goog.exportSymbol('proto.protobuf.FileApiUploadRequest', null, global);
goog.exportSymbol('proto.protobuf.FileApiUploadResponse', null, global);
goog.exportSymbol('proto.protobuf.FileEntity', null, global);
goog.exportSymbol('proto.protobuf.FileInfo', null, global);
goog.exportSymbol('proto.protobuf.FileManagementApiUploadChunkRequest', null, global);
goog.exportSymbol('proto.protobuf.FileManagementApiUploadChunkRequest.DataCase', null, global);
goog.exportSymbol('proto.protobuf.FileManagementApiUploadFileRequest', null, global);
goog.exportSymbol('proto.protobuf.FileManagementApiUploadFileResponse', null, global);
goog.exportSymbol('proto.protobuf.FlyApiFlyCostRequest', null, global);
goog.exportSymbol('proto.protobuf.FlyApiFlyCostResponse', null, global);
goog.exportSymbol('proto.protobuf.FlyApiFlyRouteDetailRequest', null, global);
goog.exportSymbol('proto.protobuf.FlyApiFlyRouteDetailResponse', null, global);
goog.exportSymbol('proto.protobuf.FlyRouteEntity', null, global);
goog.exportSymbol('proto.protobuf.HelloReply', null, global);
goog.exportSymbol('proto.protobuf.HelloRequest', null, global);
goog.exportSymbol('proto.protobuf.InvoiceEntity', null, global);
goog.exportSymbol('proto.protobuf.ManagerEntity', null, global);
goog.exportSymbol('proto.protobuf.NotificationApiNotificationListRequest', null, global);
goog.exportSymbol('proto.protobuf.NotificationApiNotificationListResponse', null, global);
goog.exportSymbol('proto.protobuf.NotificationApiReadNotificationsRequest', null, global);
goog.exportSymbol('proto.protobuf.NotificationEntity', null, global);
goog.exportSymbol('proto.protobuf.NotificationMetadataEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderRequestEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderRequestStatusEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderRouteEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderRouteOrderRequestEntity', null, global);
goog.exportSymbol('proto.protobuf.OrderStatusEntity', null, global);
goog.exportSymbol('proto.protobuf.PageModel', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorSettingCategoryEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorSettingEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorSettingSubCategoryEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneCalculatorSettingTypeEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneCategoryEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneShortEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneTypeEntity', null, global);
goog.exportSymbol('proto.protobuf.PlaneTypeShortEntity', null, global);
goog.exportSymbol('proto.protobuf.RequestCallbackEntity', null, global);
goog.exportSymbol('proto.protobuf.ResultResponse', null, global);
goog.exportSymbol('proto.protobuf.ScheduleApiScheduleListRequest', null, global);
goog.exportSymbol('proto.protobuf.ScheduleApiScheduleListResponse', null, global);
goog.exportSymbol('proto.protobuf.ScheduleApiScheduleRequest', null, global);
goog.exportSymbol('proto.protobuf.ScheduleApiScheduleResponse', null, global);
goog.exportSymbol('proto.protobuf.ScheduleEntity', null, global);
goog.exportSymbol('proto.protobuf.ScheduleType', null, global);
goog.exportSymbol('proto.protobuf.TariffEntity', null, global);
goog.exportSymbol('proto.protobuf.TariffPackageEntity', null, global);
goog.exportSymbol('proto.protobuf.TariffPackagePriceEntity', null, global);
goog.exportSymbol('proto.protobuf.TimezoneApiTimezoneListRequest', null, global);
goog.exportSymbol('proto.protobuf.TimezoneApiTimezoneListResponse', null, global);
goog.exportSymbol('proto.protobuf.TimezoneEntity', null, global);
goog.exportSymbol('proto.protobuf.TransactionEntity', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiAgentListRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiAgentListResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiChangePasswordRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiChangeUserStatusRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiCompanyRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiCompanyResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiCreateManagerRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiCreateManagerResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiFlyCompanyListRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiFlyCompanyListResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiManagerListRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiManagerListResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiRemoveManagerRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiRemoveManagerResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiUserListRequest', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiUserListResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiUserResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiUserStatusListResponse', null, global);
goog.exportSymbol('proto.protobuf.UserAccountApiUserUpdateRequest', null, global);
goog.exportSymbol('proto.protobuf.UserEntity', null, global);
goog.exportSymbol('proto.protobuf.UserStatusEntity', null, global);
goog.exportSymbol('proto.protobuf.WebsocketErrorResponseMessage', null, global);
goog.exportSymbol('proto.protobuf.WebsocketMessageType', null, global);
goog.exportSymbol('proto.protobuf.WebsocketNotificationMessage', null, global);
goog.exportSymbol('proto.protobuf.WebsocketPingMessage', null, global);
goog.exportSymbol('proto.protobuf.WebsocketPongMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.HelloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.HelloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.HelloRequest.displayName = 'proto.protobuf.HelloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.HelloReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.HelloReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.HelloReply.displayName = 'proto.protobuf.HelloReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ResultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ResultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ResultResponse.displayName = 'proto.protobuf.ResultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.EmptyRequest.displayName = 'proto.protobuf.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PageModel.displayName = 'proto.protobuf.PageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AuthApiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiRequest.displayName = 'proto.protobuf.AuthApiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.AuthApiResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.AuthApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiResponse.displayName = 'proto.protobuf.AuthApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiRegistrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AuthApiRegistrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiRegistrationRequest.displayName = 'proto.protobuf.AuthApiRegistrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiForgotPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AuthApiForgotPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiForgotPasswordRequest.displayName = 'proto.protobuf.AuthApiForgotPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AuthApiForgotPasswordCheckCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.displayName = 'proto.protobuf.AuthApiForgotPasswordCheckCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AuthApiForgotPasswordSetupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AuthApiForgotPasswordSetupRequest.displayName = 'proto.protobuf.AuthApiForgotPasswordSetupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiSubscriptionPricesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiSubscriptionPricesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiSubscriptionPricesResponse.displayName = 'proto.protobuf.BillingApiSubscriptionPricesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TariffPackagePriceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.TariffPackagePriceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TariffPackagePriceEntity.displayName = 'proto.protobuf.TariffPackagePriceEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TariffPackageEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.TariffPackageEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.TariffPackageEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TariffPackageEntity.displayName = 'proto.protobuf.TariffPackageEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CompanyTariffPackageEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.CompanyTariffPackageEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.CompanyTariffPackageEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CompanyTariffPackageEntity.displayName = 'proto.protobuf.CompanyTariffPackageEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TariffEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.TariffEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.TariffEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TariffEntity.displayName = 'proto.protobuf.TariffEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTariffRequest.displayName = 'proto.protobuf.BillingApiTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTariffResponse.displayName = 'proto.protobuf.BillingApiTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TransactionEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.TransactionEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TransactionEntity.displayName = 'proto.protobuf.TransactionEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTransactionRequest.displayName = 'proto.protobuf.BillingApiTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTransactionResponse.displayName = 'proto.protobuf.BillingApiTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTariffPackageListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BillingApiTariffPackageListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BillingApiTariffPackageListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTariffPackageListResponse.displayName = 'proto.protobuf.BillingApiTariffPackageListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTariffPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTariffPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTariffPackageRequest.displayName = 'proto.protobuf.BillingApiTariffPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiTariffPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiTariffPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiTariffPackageResponse.displayName = 'proto.protobuf.BillingApiTariffPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.InvoiceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.InvoiceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.InvoiceEntity.displayName = 'proto.protobuf.InvoiceEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiInvoiceRequest.displayName = 'proto.protobuf.BillingApiInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiInvoiceResponse.displayName = 'proto.protobuf.BillingApiInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiGetInvoiceListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiGetInvoiceListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiGetInvoiceListRequest.displayName = 'proto.protobuf.BillingApiGetInvoiceListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiGetInvoiceListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BillingApiGetInvoiceListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BillingApiGetInvoiceListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiGetInvoiceListResponse.displayName = 'proto.protobuf.BillingApiGetInvoiceListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CurrencyEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CurrencyEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CurrencyEntity.displayName = 'proto.protobuf.CurrencyEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiCurrencyListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BillingApiCurrencyListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiCurrencyListRequest.displayName = 'proto.protobuf.BillingApiCurrencyListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BillingApiCurrencyListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BillingApiCurrencyListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BillingApiCurrencyListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BillingApiCurrencyListResponse.displayName = 'proto.protobuf.BillingApiCurrencyListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiAgentListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiAgentListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiAgentListRequest.displayName = 'proto.protobuf.UserAccountApiAgentListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiFlyCompanyListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiFlyCompanyListRequest.displayName = 'proto.protobuf.UserAccountApiFlyCompanyListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CompanyEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CompanyEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CompanyEntity.displayName = 'proto.protobuf.CompanyEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiAgentListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiAgentListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiAgentListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiAgentListResponse.displayName = 'proto.protobuf.UserAccountApiAgentListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiFlyCompanyListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiFlyCompanyListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiFlyCompanyListResponse.displayName = 'proto.protobuf.UserAccountApiFlyCompanyListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ManagerEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ManagerEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ManagerEntity.displayName = 'proto.protobuf.ManagerEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiManagerListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiManagerListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiManagerListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiManagerListResponse.displayName = 'proto.protobuf.UserAccountApiManagerListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiManagerListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiManagerListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiManagerListRequest.displayName = 'proto.protobuf.UserAccountApiManagerListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiRemoveManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiRemoveManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiRemoveManagerRequest.displayName = 'proto.protobuf.UserAccountApiRemoveManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiRemoveManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiRemoveManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiRemoveManagerResponse.displayName = 'proto.protobuf.UserAccountApiRemoveManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserEntity.displayName = 'proto.protobuf.UserEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiUserUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiUserUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiUserUpdateRequest.displayName = 'proto.protobuf.UserAccountApiUserUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiUserResponse.displayName = 'proto.protobuf.UserAccountApiUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserStatusEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserStatusEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserStatusEntity.displayName = 'proto.protobuf.UserStatusEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiUserStatusListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiUserStatusListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiUserStatusListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiUserStatusListResponse.displayName = 'proto.protobuf.UserAccountApiUserStatusListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiUserListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiUserListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiUserListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiUserListRequest.displayName = 'proto.protobuf.UserAccountApiUserListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiUserListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.UserAccountApiUserListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.UserAccountApiUserListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiUserListResponse.displayName = 'proto.protobuf.UserAccountApiUserListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiChangeUserStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiChangeUserStatusRequest.displayName = 'proto.protobuf.UserAccountApiChangeUserStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiCreateManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiCreateManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiCreateManagerRequest.displayName = 'proto.protobuf.UserAccountApiCreateManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiCreateManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiCreateManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiCreateManagerResponse.displayName = 'proto.protobuf.UserAccountApiCreateManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiChangePasswordRequest.displayName = 'proto.protobuf.UserAccountApiChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiCompanyRequest.displayName = 'proto.protobuf.UserAccountApiCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.UserAccountApiCompanyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.UserAccountApiCompanyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.UserAccountApiCompanyResponse.displayName = 'proto.protobuf.UserAccountApiCompanyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.RequestCallbackEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.RequestCallbackEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.RequestCallbackEntity.displayName = 'proto.protobuf.RequestCallbackEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiRequestCallbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiRequestCallbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiRequestCallbackRequest.displayName = 'proto.protobuf.CommunicationApiRequestCallbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiRequestCallbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiRequestCallbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiRequestCallbackResponse.displayName = 'proto.protobuf.CommunicationApiRequestCallbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiRequestCallbackListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiRequestCallbackListRequest.displayName = 'proto.protobuf.CommunicationApiRequestCallbackListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.CommunicationApiRequestCallbackListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.CommunicationApiRequestCallbackListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiRequestCallbackListResponse.displayName = 'proto.protobuf.CommunicationApiRequestCallbackListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ChatEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ChatEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ChatEntity.displayName = 'proto.protobuf.ChatEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ChatMessageEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ChatMessageEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ChatMessageEntity.displayName = 'proto.protobuf.ChatMessageEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatMessageListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatMessageListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatMessageListRequest.displayName = 'proto.protobuf.CommunicationApiChatMessageListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatMessageListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.CommunicationApiChatMessageListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatMessageListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatMessageListResponse.displayName = 'proto.protobuf.CommunicationApiChatMessageListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatListRequest.displayName = 'proto.protobuf.CommunicationApiChatListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.CommunicationApiChatListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatListResponse.displayName = 'proto.protobuf.CommunicationApiChatListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatMessageSendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatMessageSendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatMessageSendRequest.displayName = 'proto.protobuf.CommunicationApiChatMessageSendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationApiChatMessageSendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationApiChatMessageSendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationApiChatMessageSendResponse.displayName = 'proto.protobuf.CommunicationApiChatMessageSendResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.CommunicationWebsocketMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.CommunicationWebsocketMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.CommunicationWebsocketMessage.displayName = 'proto.protobuf.CommunicationWebsocketMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.WebsocketErrorResponseMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.WebsocketErrorResponseMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.WebsocketErrorResponseMessage.displayName = 'proto.protobuf.WebsocketErrorResponseMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.WebsocketPingMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.WebsocketPingMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.WebsocketPingMessage.displayName = 'proto.protobuf.WebsocketPingMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.WebsocketPongMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.WebsocketPongMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.WebsocketPongMessage.displayName = 'proto.protobuf.WebsocketPongMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.WebsocketNotificationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.WebsocketNotificationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.WebsocketNotificationMessage.displayName = 'proto.protobuf.WebsocketNotificationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneTypeEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneTypeEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneTypeEntity.displayName = 'proto.protobuf.PlaneTypeEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneTypeShortEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneTypeShortEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneTypeShortEntity.displayName = 'proto.protobuf.PlaneTypeShortEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneTypeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneTypeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneTypeListRequest.displayName = 'proto.protobuf.BookingApiPlaneTypeListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneTypeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiPlaneTypeListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneTypeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneTypeListResponse.displayName = 'proto.protobuf.BookingApiPlaneTypeListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiPlaneTypeShortListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneTypeShortListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneTypeShortListResponse.displayName = 'proto.protobuf.BookingApiPlaneTypeShortListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneTypeRequest.displayName = 'proto.protobuf.BookingApiPlaneTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneTypeResponse.displayName = 'proto.protobuf.BookingApiPlaneTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneListRequest.displayName = 'proto.protobuf.BookingApiPlaneListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneEntity.displayName = 'proto.protobuf.PlaneEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneShortEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneShortEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneShortEntity.displayName = 'proto.protobuf.PlaneShortEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiPlaneListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneListResponse.displayName = 'proto.protobuf.BookingApiPlaneListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneShortListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiPlaneShortListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneShortListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneShortListResponse.displayName = 'proto.protobuf.BookingApiPlaneShortListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneRequest.displayName = 'proto.protobuf.BookingApiPlaneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneResponse.displayName = 'proto.protobuf.BookingApiPlaneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiEmptyLegListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegListRequest.displayName = 'proto.protobuf.BookingApiEmptyLegListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.EmptyLegEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.EmptyLegEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.EmptyLegEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.EmptyLegEntity.displayName = 'proto.protobuf.EmptyLegEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiEmptyLegListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegListResponse.displayName = 'proto.protobuf.BookingApiEmptyLegListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegRequest.displayName = 'proto.protobuf.BookingApiEmptyLegRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegResponse.displayName = 'proto.protobuf.BookingApiEmptyLegResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderListRequest.repeatedFields_, proto.protobuf.BookingApiOrderListRequest.oneofGroups_);
};
goog.inherits(proto.protobuf.BookingApiOrderListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderListRequest.displayName = 'proto.protobuf.BookingApiOrderListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.OrderEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.OrderEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderEntity.displayName = 'proto.protobuf.OrderEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderListResponse.displayName = 'proto.protobuf.BookingApiOrderListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderRouteEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.OrderRouteEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.OrderRouteEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderRouteEntity.displayName = 'proto.protobuf.OrderRouteEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderRouteOrderRequestEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.OrderRouteOrderRequestEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.OrderRouteOrderRequestEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderRouteOrderRequestEntity.displayName = 'proto.protobuf.OrderRouteOrderRequestEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequest.displayName = 'proto.protobuf.BookingApiOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderResponse.displayName = 'proto.protobuf.BookingApiOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderStatusListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderStatusListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderStatusListRequest.displayName = 'proto.protobuf.BookingApiOrderStatusListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderStatusEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.OrderStatusEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderStatusEntity.displayName = 'proto.protobuf.OrderStatusEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderStatusListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderStatusListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderStatusListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderStatusListResponse.displayName = 'proto.protobuf.BookingApiOrderStatusListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegStatusListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegStatusListRequest.displayName = 'proto.protobuf.BookingApiEmptyLegStatusListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.EmptyLegStatusEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.EmptyLegStatusEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.EmptyLegStatusEntity.displayName = 'proto.protobuf.EmptyLegStatusEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiEmptyLegStatusListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegStatusListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegStatusListResponse.displayName = 'proto.protobuf.BookingApiEmptyLegStatusListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderRequestListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestListRequest.displayName = 'proto.protobuf.BookingApiOrderRequestListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderRequestEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.OrderRequestEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.OrderRequestEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderRequestEntity.displayName = 'proto.protobuf.OrderRequestEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderRequestListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestListResponse.displayName = 'proto.protobuf.BookingApiOrderRequestListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestRequest.displayName = 'proto.protobuf.BookingApiOrderRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestResponse.displayName = 'proto.protobuf.BookingApiOrderRequestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestStatusListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestStatusListRequest.displayName = 'proto.protobuf.BookingApiOrderRequestStatusListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.OrderRequestStatusEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.OrderRequestStatusEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.OrderRequestStatusEntity.displayName = 'proto.protobuf.OrderRequestStatusEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderRequestStatusListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRequestStatusListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRequestStatusListResponse.displayName = 'proto.protobuf.BookingApiOrderRequestStatusListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AirportShortEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AirportShortEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AirportShortEntity.displayName = 'proto.protobuf.AirportShortEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiAirportShortListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiAirportShortListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiAirportShortListRequest.displayName = 'proto.protobuf.BookingApiAirportShortListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiAirportShortListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiAirportShortListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiAirportShortListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiAirportShortListResponse.displayName = 'proto.protobuf.BookingApiAirportShortListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AirportEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AirportEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AirportEntity.displayName = 'proto.protobuf.AirportEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiGetAirportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiGetAirportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiGetAirportRequest.displayName = 'proto.protobuf.BookingApiGetAirportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiGetAirportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiGetAirportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiGetAirportResponse.displayName = 'proto.protobuf.BookingApiGetAirportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiAirportListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiAirportListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiAirportListRequest.displayName = 'proto.protobuf.BookingApiAirportListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiAirportListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiAirportListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiAirportListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiAirportListResponse.displayName = 'proto.protobuf.BookingApiAirportListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneCategoryListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneCategoryListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneCategoryListRequest.displayName = 'proto.protobuf.BookingApiPlaneCategoryListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCategoryEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCategoryEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCategoryEntity.displayName = 'proto.protobuf.PlaneCategoryEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiPlaneCategoryListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiPlaneCategoryListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiPlaneCategoryListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiPlaneCategoryListResponse.displayName = 'proto.protobuf.BookingApiPlaneCategoryListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRouteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderRouteListRequest.repeatedFields_, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_);
};
goog.inherits(proto.protobuf.BookingApiOrderRouteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRouteListRequest.displayName = 'proto.protobuf.BookingApiOrderRouteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRouteListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.BookingApiOrderRouteListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRouteListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRouteListResponse.displayName = 'proto.protobuf.BookingApiOrderRouteListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.EmptyLegOrderRouteEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.EmptyLegOrderRouteEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.EmptyLegOrderRouteEntity.displayName = 'proto.protobuf.EmptyLegOrderRouteEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegOrderRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegOrderRouteRequest.displayName = 'proto.protobuf.BookingApiEmptyLegOrderRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiEmptyLegOrderRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiEmptyLegOrderRouteResponse.displayName = 'proto.protobuf.BookingApiEmptyLegOrderRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRouteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRouteRequest.displayName = 'proto.protobuf.BookingApiOrderRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.BookingApiOrderRouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.BookingApiOrderRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.BookingApiOrderRouteResponse.displayName = 'proto.protobuf.BookingApiOrderRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FlyRouteEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FlyRouteEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FlyRouteEntity.displayName = 'proto.protobuf.FlyRouteEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FlyApiFlyRouteDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FlyApiFlyRouteDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FlyApiFlyRouteDetailRequest.displayName = 'proto.protobuf.FlyApiFlyRouteDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FlyApiFlyRouteDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.FlyApiFlyRouteDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.FlyApiFlyRouteDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FlyApiFlyRouteDetailResponse.displayName = 'proto.protobuf.FlyApiFlyRouteDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FlyApiFlyCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.FlyApiFlyCostRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.FlyApiFlyCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FlyApiFlyCostRequest.displayName = 'proto.protobuf.FlyApiFlyCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FlyApiFlyCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FlyApiFlyCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FlyApiFlyCostResponse.displayName = 'proto.protobuf.FlyApiFlyCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorSettingCategoryEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorSettingCategoryEntity.displayName = 'proto.protobuf.PlaneCalculatorSettingCategoryEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorSettingSubCategoryEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.displayName = 'proto.protobuf.PlaneCalculatorSettingSubCategoryEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorSettingTypeEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorSettingTypeEntity.displayName = 'proto.protobuf.PlaneCalculatorSettingTypeEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorSettingEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorSettingEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorSettingEntity.displayName = 'proto.protobuf.PlaneCalculatorSettingEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.displayName = 'proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.displayName = 'proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.displayName = 'proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileApiUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileApiUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileApiUploadRequest.displayName = 'proto.protobuf.FileApiUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileApiUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileApiUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileApiUploadResponse.displayName = 'proto.protobuf.FileApiUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileInfo.displayName = 'proto.protobuf.FileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileManagementApiUploadChunkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_);
};
goog.inherits(proto.protobuf.FileManagementApiUploadChunkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileManagementApiUploadChunkRequest.displayName = 'proto.protobuf.FileManagementApiUploadChunkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileManagementApiUploadFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.FileManagementApiUploadFileRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.FileManagementApiUploadFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileManagementApiUploadFileRequest.displayName = 'proto.protobuf.FileManagementApiUploadFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileManagementApiUploadFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileManagementApiUploadFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileManagementApiUploadFileResponse.displayName = 'proto.protobuf.FileManagementApiUploadFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileApiFileListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileApiFileListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileApiFileListRequest.displayName = 'proto.protobuf.FileApiFileListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileApiFileListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.FileApiFileListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.FileApiFileListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileApiFileListResponse.displayName = 'proto.protobuf.FileApiFileListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.FileEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.FileEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.FileEntity.displayName = 'proto.protobuf.FileEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ScheduleEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ScheduleEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ScheduleEntity.displayName = 'proto.protobuf.ScheduleEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ScheduleApiScheduleListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.ScheduleApiScheduleListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.ScheduleApiScheduleListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ScheduleApiScheduleListRequest.displayName = 'proto.protobuf.ScheduleApiScheduleListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ScheduleApiScheduleListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.ScheduleApiScheduleListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.ScheduleApiScheduleListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ScheduleApiScheduleListResponse.displayName = 'proto.protobuf.ScheduleApiScheduleListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ScheduleApiScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ScheduleApiScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ScheduleApiScheduleRequest.displayName = 'proto.protobuf.ScheduleApiScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.ScheduleApiScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.ScheduleApiScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.ScheduleApiScheduleResponse.displayName = 'proto.protobuf.ScheduleApiScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TimezoneEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.TimezoneEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TimezoneEntity.displayName = 'proto.protobuf.TimezoneEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TimezoneApiTimezoneListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.TimezoneApiTimezoneListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TimezoneApiTimezoneListRequest.displayName = 'proto.protobuf.TimezoneApiTimezoneListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.TimezoneApiTimezoneListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.TimezoneApiTimezoneListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.TimezoneApiTimezoneListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.TimezoneApiTimezoneListResponse.displayName = 'proto.protobuf.TimezoneApiTimezoneListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.NotificationMetadataEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.NotificationMetadataEntity.repeatedFields_, null);
};
goog.inherits(proto.protobuf.NotificationMetadataEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.NotificationMetadataEntity.displayName = 'proto.protobuf.NotificationMetadataEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.AirportRouteMetadataEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.AirportRouteMetadataEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.AirportRouteMetadataEntity.displayName = 'proto.protobuf.AirportRouteMetadataEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.NotificationEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.NotificationEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.NotificationEntity.displayName = 'proto.protobuf.NotificationEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.NotificationApiNotificationListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.NotificationApiNotificationListRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.NotificationApiNotificationListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.NotificationApiNotificationListRequest.displayName = 'proto.protobuf.NotificationApiNotificationListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.NotificationApiNotificationListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.NotificationApiNotificationListResponse.repeatedFields_, null);
};
goog.inherits(proto.protobuf.NotificationApiNotificationListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.NotificationApiNotificationListResponse.displayName = 'proto.protobuf.NotificationApiNotificationListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.NotificationApiReadNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.NotificationApiReadNotificationsRequest.repeatedFields_, null);
};
goog.inherits(proto.protobuf.NotificationApiReadNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.NotificationApiReadNotificationsRequest.displayName = 'proto.protobuf.NotificationApiReadNotificationsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.HelloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.HelloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.HelloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.HelloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.HelloRequest}
 */
proto.protobuf.HelloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.HelloRequest;
  return proto.protobuf.HelloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.HelloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.HelloRequest}
 */
proto.protobuf.HelloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.HelloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.HelloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.HelloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.HelloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protobuf.HelloRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.HelloRequest} returns this
 */
proto.protobuf.HelloRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.HelloReply.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.HelloReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.HelloReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.HelloReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.HelloReply}
 */
proto.protobuf.HelloReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.HelloReply;
  return proto.protobuf.HelloReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.HelloReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.HelloReply}
 */
proto.protobuf.HelloReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.HelloReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.HelloReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.HelloReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.HelloReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.protobuf.HelloReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.HelloReply} returns this
 */
proto.protobuf.HelloReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ResultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ResultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ResultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ResultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuccessful: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errormessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ResultResponse}
 */
proto.protobuf.ResultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ResultResponse;
  return proto.protobuf.ResultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ResultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ResultResponse}
 */
proto.protobuf.ResultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssuccessful(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ResultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ResultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ResultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ResultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuccessful();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool isSuccessful = 1;
 * @return {boolean}
 */
proto.protobuf.ResultResponse.prototype.getIssuccessful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.ResultResponse} returns this
 */
proto.protobuf.ResultResponse.prototype.setIssuccessful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string errorMessage = 2;
 * @return {string}
 */
proto.protobuf.ResultResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ResultResponse} returns this
 */
proto.protobuf.ResultResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.EmptyRequest}
 */
proto.protobuf.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.EmptyRequest;
  return proto.protobuf.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.EmptyRequest}
 */
proto.protobuf.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PageModel}
 */
proto.protobuf.PageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PageModel;
  return proto.protobuf.PageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PageModel}
 */
proto.protobuf.PageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.protobuf.PageModel.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PageModel} returns this
 */
proto.protobuf.PageModel.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.protobuf.PageModel.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PageModel} returns this
 */
proto.protobuf.PageModel.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiRequest}
 */
proto.protobuf.AuthApiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiRequest;
  return proto.protobuf.AuthApiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiRequest}
 */
proto.protobuf.AuthApiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.protobuf.AuthApiRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRequest} returns this
 */
proto.protobuf.AuthApiRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.protobuf.AuthApiRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRequest} returns this
 */
proto.protobuf.AuthApiRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refreshToken = 3;
 * @return {string}
 */
proto.protobuf.AuthApiRequest.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRequest} returns this
 */
proto.protobuf.AuthApiRequest.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.AuthApiResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, ""),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.protobuf.UserEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiResponse}
 */
proto.protobuf.AuthApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiResponse;
  return proto.protobuf.AuthApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiResponse}
 */
proto.protobuf.AuthApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPermissions(value);
      break;
    case 5:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    case 6:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.protobuf.AuthApiResponse.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string refreshToken = 2;
 * @return {string}
 */
proto.protobuf.AuthApiResponse.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string role = 3;
 * @return {string}
 */
proto.protobuf.AuthApiResponse.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string permissions = 4;
 * @return {!Array<string>}
 */
proto.protobuf.AuthApiResponse.prototype.getPermissionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional ResultResponse resultResponse = 5;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.AuthApiResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 5));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
*/
proto.protobuf.AuthApiResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AuthApiResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserEntity user = 6;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.AuthApiResponse.prototype.getUser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 6));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.AuthApiResponse} returns this
*/
proto.protobuf.AuthApiResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AuthApiResponse} returns this
 */
proto.protobuf.AuthApiResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AuthApiResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiRegistrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiRegistrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiRegistrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    password: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repeatpassword: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timezone: (f = msg.getTimezone()) && proto.protobuf.TimezoneEntity.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiRegistrationRequest}
 */
proto.protobuf.AuthApiRegistrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiRegistrationRequest;
  return proto.protobuf.AuthApiRegistrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiRegistrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiRegistrationRequest}
 */
proto.protobuf.AuthApiRegistrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatpassword(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    case 10:
      var value = new proto.protobuf.TimezoneEntity;
      reader.readMessage(value,proto.protobuf.TimezoneEntity.deserializeBinaryFromReader);
      msg.setTimezone(value);
      break;
    case 11:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiRegistrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiRegistrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiRegistrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepeatpassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTimezone();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.TimezoneEntity.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lastName = 2;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string middleName = 3;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string companyName = 4;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string role = 5;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string password = 7;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string repeatPassword = 8;
 * @return {string}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getRepeatpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setRepeatpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 phone = 9;
 * @return {number}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TimezoneEntity timezone = 10;
 * @return {?proto.protobuf.TimezoneEntity}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getTimezone = function() {
  return /** @type{?proto.protobuf.TimezoneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TimezoneEntity, 10));
};


/**
 * @param {?proto.protobuf.TimezoneEntity|undefined} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
*/
proto.protobuf.AuthApiRegistrationRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.clearTimezone = function() {
  return this.setTimezone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.hasTimezone = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CurrencyEntity currency = 11;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 11));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
*/
proto.protobuf.AuthApiRegistrationRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AuthApiRegistrationRequest} returns this
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AuthApiRegistrationRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiForgotPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiForgotPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiForgotPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiForgotPasswordRequest}
 */
proto.protobuf.AuthApiForgotPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiForgotPasswordRequest;
  return proto.protobuf.AuthApiForgotPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiForgotPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiForgotPasswordRequest}
 */
proto.protobuf.AuthApiForgotPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiForgotPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiForgotPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiForgotPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.protobuf.AuthApiForgotPasswordRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiForgotPasswordRequest} returns this
 */
proto.protobuf.AuthApiForgotPasswordRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest}
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiForgotPasswordCheckCodeRequest;
  return proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest}
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} returns this
 */
proto.protobuf.AuthApiForgotPasswordCheckCodeRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AuthApiForgotPasswordSetupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repeatpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AuthApiForgotPasswordSetupRequest}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AuthApiForgotPasswordSetupRequest;
  return proto.protobuf.AuthApiForgotPasswordSetupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AuthApiForgotPasswordSetupRequest}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AuthApiForgotPasswordSetupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepeatpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiForgotPasswordSetupRequest} returns this
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string repeatPassword = 2;
 * @return {string}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.getRepeatpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiForgotPasswordSetupRequest} returns this
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.setRepeatpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AuthApiForgotPasswordSetupRequest} returns this
 */
proto.protobuf.AuthApiForgotPasswordSetupRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiSubscriptionPricesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiSubscriptionPricesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    flycompanyprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiSubscriptionPricesResponse}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiSubscriptionPricesResponse;
  return proto.protobuf.BillingApiSubscriptionPricesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiSubscriptionPricesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiSubscriptionPricesResponse}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAgentprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFlycompanyprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiSubscriptionPricesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiSubscriptionPricesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentprice();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFlycompanyprice();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float agentPrice = 1;
 * @return {number}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.getAgentprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BillingApiSubscriptionPricesResponse} returns this
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.setAgentprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float flyCompanyPrice = 2;
 * @return {number}
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.getFlycompanyprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BillingApiSubscriptionPricesResponse} returns this
 */
proto.protobuf.BillingApiSubscriptionPricesResponse.prototype.setFlycompanyprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TariffPackagePriceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TariffPackagePriceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TariffPackagePriceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffPackagePriceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffpackagepriceid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currencyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TariffPackagePriceEntity}
 */
proto.protobuf.TariffPackagePriceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TariffPackagePriceEntity;
  return proto.protobuf.TariffPackagePriceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TariffPackagePriceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TariffPackagePriceEntity}
 */
proto.protobuf.TariffPackagePriceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTariffpackagepriceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrencyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TariffPackagePriceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TariffPackagePriceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TariffPackagePriceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffPackagePriceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffpackagepriceid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrencyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 tariffPackagePriceId = 1;
 * @return {number}
 */
proto.protobuf.TariffPackagePriceEntity.prototype.getTariffpackagepriceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackagePriceEntity} returns this
 */
proto.protobuf.TariffPackagePriceEntity.prototype.setTariffpackagepriceid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 currencyId = 2;
 * @return {number}
 */
proto.protobuf.TariffPackagePriceEntity.prototype.getCurrencyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackagePriceEntity} returns this
 */
proto.protobuf.TariffPackagePriceEntity.prototype.setCurrencyid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.protobuf.TariffPackagePriceEntity.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackagePriceEntity} returns this
 */
proto.protobuf.TariffPackagePriceEntity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.TariffPackageEntity.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TariffPackageEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TariffPackageEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TariffPackageEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffPackageEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffpackageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tariffpackagetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    value: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tariffpackagepricesList: jspb.Message.toObjectList(msg.getTariffpackagepricesList(),
    proto.protobuf.TariffPackagePriceEntity.toObject, includeInstance),
    companytype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.TariffPackageEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TariffPackageEntity;
  return proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TariffPackageEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTariffpackageid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTariffpackagetype(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 5:
      var value = new proto.protobuf.TariffPackagePriceEntity;
      reader.readMessage(value,proto.protobuf.TariffPackagePriceEntity.deserializeBinaryFromReader);
      msg.addTariffpackageprices(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TariffPackageEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TariffPackageEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TariffPackageEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffPackageEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffpackageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTariffpackagetype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTariffpackagepricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.protobuf.TariffPackagePriceEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompanytype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 tariffPackageId = 1;
 * @return {number}
 */
proto.protobuf.TariffPackageEntity.prototype.getTariffpackageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.setTariffpackageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 tariffPackageType = 2;
 * @return {number}
 */
proto.protobuf.TariffPackageEntity.prototype.getTariffpackagetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.setTariffpackagetype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool required = 3;
 * @return {boolean}
 */
proto.protobuf.TariffPackageEntity.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 value = 4;
 * @return {number}
 */
proto.protobuf.TariffPackageEntity.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated TariffPackagePriceEntity tariffPackagePrices = 5;
 * @return {!Array<!proto.protobuf.TariffPackagePriceEntity>}
 */
proto.protobuf.TariffPackageEntity.prototype.getTariffpackagepricesList = function() {
  return /** @type{!Array<!proto.protobuf.TariffPackagePriceEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.TariffPackagePriceEntity, 5));
};


/**
 * @param {!Array<!proto.protobuf.TariffPackagePriceEntity>} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
*/
proto.protobuf.TariffPackageEntity.prototype.setTariffpackagepricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.protobuf.TariffPackagePriceEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.TariffPackagePriceEntity}
 */
proto.protobuf.TariffPackageEntity.prototype.addTariffpackageprices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.protobuf.TariffPackagePriceEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.clearTariffpackagepricesList = function() {
  return this.setTariffpackagepricesList([]);
};


/**
 * optional int32 companyType = 6;
 * @return {number}
 */
proto.protobuf.TariffPackageEntity.prototype.getCompanytype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TariffPackageEntity} returns this
 */
proto.protobuf.TariffPackageEntity.prototype.setCompanytype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.CompanyTariffPackageEntity.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CompanyTariffPackageEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CompanyTariffPackageEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CompanyTariffPackageEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    companytariffpackageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tariffpackageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tariffpackage: (f = msg.getTariffpackage()) && proto.protobuf.TariffPackageEntity.toObject(includeInstance, f),
    companytariffpackageusersList: jspb.Message.toObjectList(msg.getCompanytariffpackageusersList(),
    proto.protobuf.UserEntity.toObject, includeInstance),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    autorenewal: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    tariffend: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CompanyTariffPackageEntity}
 */
proto.protobuf.CompanyTariffPackageEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CompanyTariffPackageEntity;
  return proto.protobuf.CompanyTariffPackageEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CompanyTariffPackageEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CompanyTariffPackageEntity}
 */
proto.protobuf.CompanyTariffPackageEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanytariffpackageid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTariffpackageid(value);
      break;
    case 3:
      var value = new proto.protobuf.TariffPackageEntity;
      reader.readMessage(value,proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader);
      msg.setTariffpackage(value);
      break;
    case 4:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.addCompanytariffpackageusers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutorenewal(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CompanyTariffPackageEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CompanyTariffPackageEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CompanyTariffPackageEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanytariffpackageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTariffpackageid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTariffpackage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.TariffPackageEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompanytariffpackageusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAutorenewal();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTariffend();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 companyTariffPackageId = 1;
 * @return {number}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getCompanytariffpackageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.setCompanytariffpackageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 tariffPackageId = 2;
 * @return {number}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getTariffpackageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.setTariffpackageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TariffPackageEntity tariffPackage = 3;
 * @return {?proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getTariffpackage = function() {
  return /** @type{?proto.protobuf.TariffPackageEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TariffPackageEntity, 3));
};


/**
 * @param {?proto.protobuf.TariffPackageEntity|undefined} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
*/
proto.protobuf.CompanyTariffPackageEntity.prototype.setTariffpackage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.clearTariffpackage = function() {
  return this.setTariffpackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.hasTariffpackage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated UserEntity companyTariffPackageUsers = 4;
 * @return {!Array<!proto.protobuf.UserEntity>}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getCompanytariffpackageusersList = function() {
  return /** @type{!Array<!proto.protobuf.UserEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.UserEntity, 4));
};


/**
 * @param {!Array<!proto.protobuf.UserEntity>} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
*/
proto.protobuf.CompanyTariffPackageEntity.prototype.setCompanytariffpackageusersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.protobuf.UserEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.UserEntity}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.addCompanytariffpackageusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.protobuf.UserEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.clearCompanytariffpackageusersList = function() {
  return this.setCompanytariffpackageusersList([]);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool autoRenewal = 6;
 * @return {boolean}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getAutorenewal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.setAutorenewal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string tariffEnd = 7;
 * @return {string}
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.getTariffend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyTariffPackageEntity} returns this
 */
proto.protobuf.CompanyTariffPackageEntity.prototype.setTariffend = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.TariffEntity.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TariffEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TariffEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TariffEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    companytariffpackagesList: jspb.Message.toObjectList(msg.getCompanytariffpackagesList(),
    proto.protobuf.CompanyTariffPackageEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TariffEntity}
 */
proto.protobuf.TariffEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TariffEntity;
  return proto.protobuf.TariffEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TariffEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TariffEntity}
 */
proto.protobuf.TariffEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CompanyTariffPackageEntity;
      reader.readMessage(value,proto.protobuf.CompanyTariffPackageEntity.deserializeBinaryFromReader);
      msg.addCompanytariffpackages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TariffEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TariffEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TariffEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TariffEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanytariffpackagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.CompanyTariffPackageEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyTariffPackageEntity companyTariffPackages = 1;
 * @return {!Array<!proto.protobuf.CompanyTariffPackageEntity>}
 */
proto.protobuf.TariffEntity.prototype.getCompanytariffpackagesList = function() {
  return /** @type{!Array<!proto.protobuf.CompanyTariffPackageEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.CompanyTariffPackageEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.CompanyTariffPackageEntity>} value
 * @return {!proto.protobuf.TariffEntity} returns this
*/
proto.protobuf.TariffEntity.prototype.setCompanytariffpackagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.CompanyTariffPackageEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.CompanyTariffPackageEntity}
 */
proto.protobuf.TariffEntity.prototype.addCompanytariffpackages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.CompanyTariffPackageEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.TariffEntity} returns this
 */
proto.protobuf.TariffEntity.prototype.clearCompanytariffpackagesList = function() {
  return this.setCompanytariffpackagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariff: (f = msg.getTariff()) && proto.protobuf.TariffEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTariffRequest}
 */
proto.protobuf.BillingApiTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTariffRequest;
  return proto.protobuf.BillingApiTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTariffRequest}
 */
proto.protobuf.BillingApiTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TariffEntity;
      reader.readMessage(value,proto.protobuf.TariffEntity.deserializeBinaryFromReader);
      msg.setTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TariffEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional TariffEntity tariff = 1;
 * @return {?proto.protobuf.TariffEntity}
 */
proto.protobuf.BillingApiTariffRequest.prototype.getTariff = function() {
  return /** @type{?proto.protobuf.TariffEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TariffEntity, 1));
};


/**
 * @param {?proto.protobuf.TariffEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTariffRequest} returns this
*/
proto.protobuf.BillingApiTariffRequest.prototype.setTariff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffRequest} returns this
 */
proto.protobuf.BillingApiTariffRequest.prototype.clearTariff = function() {
  return this.setTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffRequest.prototype.hasTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariff: (f = msg.getTariff()) && proto.protobuf.TariffEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTariffResponse}
 */
proto.protobuf.BillingApiTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTariffResponse;
  return proto.protobuf.BillingApiTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTariffResponse}
 */
proto.protobuf.BillingApiTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TariffEntity;
      reader.readMessage(value,proto.protobuf.TariffEntity.deserializeBinaryFromReader);
      msg.setTariff(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TariffEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional TariffEntity tariff = 1;
 * @return {?proto.protobuf.TariffEntity}
 */
proto.protobuf.BillingApiTariffResponse.prototype.getTariff = function() {
  return /** @type{?proto.protobuf.TariffEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TariffEntity, 1));
};


/**
 * @param {?proto.protobuf.TariffEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTariffResponse} returns this
*/
proto.protobuf.BillingApiTariffResponse.prototype.setTariff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffResponse} returns this
 */
proto.protobuf.BillingApiTariffResponse.prototype.clearTariff = function() {
  return this.setTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffResponse.prototype.hasTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BillingApiTariffResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BillingApiTariffResponse} returns this
*/
proto.protobuf.BillingApiTariffResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffResponse} returns this
 */
proto.protobuf.BillingApiTariffResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TransactionEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TransactionEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TransactionEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TransactionEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    invoiceid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TransactionEntity}
 */
proto.protobuf.TransactionEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TransactionEntity;
  return proto.protobuf.TransactionEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TransactionEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TransactionEntity}
 */
proto.protobuf.TransactionEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TransactionEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TransactionEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TransactionEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TransactionEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getInvoiceid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 transactionId = 1;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 companyId = 2;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getCompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setCompanyid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 userId = 3;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 type = 5;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 invoiceId = 6;
 * @return {number}
 */
proto.protobuf.TransactionEntity.prototype.getInvoiceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TransactionEntity} returns this
 */
proto.protobuf.TransactionEntity.prototype.setInvoiceid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && proto.protobuf.TransactionEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTransactionRequest}
 */
proto.protobuf.BillingApiTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTransactionRequest;
  return proto.protobuf.BillingApiTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTransactionRequest}
 */
proto.protobuf.BillingApiTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TransactionEntity;
      reader.readMessage(value,proto.protobuf.TransactionEntity.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TransactionEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransactionEntity transaction = 1;
 * @return {?proto.protobuf.TransactionEntity}
 */
proto.protobuf.BillingApiTransactionRequest.prototype.getTransaction = function() {
  return /** @type{?proto.protobuf.TransactionEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TransactionEntity, 1));
};


/**
 * @param {?proto.protobuf.TransactionEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTransactionRequest} returns this
*/
proto.protobuf.BillingApiTransactionRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTransactionRequest} returns this
 */
proto.protobuf.BillingApiTransactionRequest.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTransactionRequest.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && proto.protobuf.TransactionEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTransactionResponse}
 */
proto.protobuf.BillingApiTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTransactionResponse;
  return proto.protobuf.BillingApiTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTransactionResponse}
 */
proto.protobuf.BillingApiTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TransactionEntity;
      reader.readMessage(value,proto.protobuf.TransactionEntity.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TransactionEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransactionEntity transaction = 1;
 * @return {?proto.protobuf.TransactionEntity}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.getTransaction = function() {
  return /** @type{?proto.protobuf.TransactionEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TransactionEntity, 1));
};


/**
 * @param {?proto.protobuf.TransactionEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTransactionResponse} returns this
*/
proto.protobuf.BillingApiTransactionResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTransactionResponse} returns this
 */
proto.protobuf.BillingApiTransactionResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BillingApiTransactionResponse} returns this
*/
proto.protobuf.BillingApiTransactionResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTransactionResponse} returns this
 */
proto.protobuf.BillingApiTransactionResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTransactionResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BillingApiTariffPackageListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTariffPackageListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTariffPackageListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTariffPackageListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffpackagesList: jspb.Message.toObjectList(msg.getTariffpackagesList(),
    proto.protobuf.TariffPackageEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTariffPackageListResponse}
 */
proto.protobuf.BillingApiTariffPackageListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTariffPackageListResponse;
  return proto.protobuf.BillingApiTariffPackageListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTariffPackageListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTariffPackageListResponse}
 */
proto.protobuf.BillingApiTariffPackageListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TariffPackageEntity;
      reader.readMessage(value,proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader);
      msg.addTariffpackages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTariffPackageListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTariffPackageListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTariffPackageListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffpackagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.TariffPackageEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TariffPackageEntity tariffPackages = 1;
 * @return {!Array<!proto.protobuf.TariffPackageEntity>}
 */
proto.protobuf.BillingApiTariffPackageListResponse.prototype.getTariffpackagesList = function() {
  return /** @type{!Array<!proto.protobuf.TariffPackageEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.TariffPackageEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.TariffPackageEntity>} value
 * @return {!proto.protobuf.BillingApiTariffPackageListResponse} returns this
*/
proto.protobuf.BillingApiTariffPackageListResponse.prototype.setTariffpackagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.TariffPackageEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.BillingApiTariffPackageListResponse.prototype.addTariffpackages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.TariffPackageEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BillingApiTariffPackageListResponse} returns this
 */
proto.protobuf.BillingApiTariffPackageListResponse.prototype.clearTariffpackagesList = function() {
  return this.setTariffpackagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTariffPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTariffPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffpackage: (f = msg.getTariffpackage()) && proto.protobuf.TariffPackageEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTariffPackageRequest}
 */
proto.protobuf.BillingApiTariffPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTariffPackageRequest;
  return proto.protobuf.BillingApiTariffPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTariffPackageRequest}
 */
proto.protobuf.BillingApiTariffPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TariffPackageEntity;
      reader.readMessage(value,proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader);
      msg.setTariffpackage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTariffPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTariffPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffpackage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TariffPackageEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional TariffPackageEntity tariffPackage = 1;
 * @return {?proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.BillingApiTariffPackageRequest.prototype.getTariffpackage = function() {
  return /** @type{?proto.protobuf.TariffPackageEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TariffPackageEntity, 1));
};


/**
 * @param {?proto.protobuf.TariffPackageEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTariffPackageRequest} returns this
*/
proto.protobuf.BillingApiTariffPackageRequest.prototype.setTariffpackage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffPackageRequest} returns this
 */
proto.protobuf.BillingApiTariffPackageRequest.prototype.clearTariffpackage = function() {
  return this.setTariffpackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffPackageRequest.prototype.hasTariffpackage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiTariffPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiTariffPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffpackage: (f = msg.getTariffpackage()) && proto.protobuf.TariffPackageEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiTariffPackageResponse}
 */
proto.protobuf.BillingApiTariffPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiTariffPackageResponse;
  return proto.protobuf.BillingApiTariffPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiTariffPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiTariffPackageResponse}
 */
proto.protobuf.BillingApiTariffPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TariffPackageEntity;
      reader.readMessage(value,proto.protobuf.TariffPackageEntity.deserializeBinaryFromReader);
      msg.setTariffpackage(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiTariffPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiTariffPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiTariffPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffpackage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.TariffPackageEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional TariffPackageEntity tariffPackage = 1;
 * @return {?proto.protobuf.TariffPackageEntity}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.getTariffpackage = function() {
  return /** @type{?proto.protobuf.TariffPackageEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TariffPackageEntity, 1));
};


/**
 * @param {?proto.protobuf.TariffPackageEntity|undefined} value
 * @return {!proto.protobuf.BillingApiTariffPackageResponse} returns this
*/
proto.protobuf.BillingApiTariffPackageResponse.prototype.setTariffpackage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffPackageResponse} returns this
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.clearTariffpackage = function() {
  return this.setTariffpackage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.hasTariffpackage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BillingApiTariffPackageResponse} returns this
*/
proto.protobuf.BillingApiTariffPackageResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiTariffPackageResponse} returns this
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiTariffPackageResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.InvoiceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.InvoiceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.InvoiceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.InvoiceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    filepath: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.InvoiceEntity}
 */
proto.protobuf.InvoiceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.InvoiceEntity;
  return proto.protobuf.InvoiceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.InvoiceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.InvoiceEntity}
 */
proto.protobuf.InvoiceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 4:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.InvoiceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.InvoiceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.InvoiceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.InvoiceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 invoiceId = 1;
 * @return {number}
 */
proto.protobuf.InvoiceEntity.prototype.getInvoiceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.setInvoiceid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 companyId = 2;
 * @return {number}
 */
proto.protobuf.InvoiceEntity.prototype.getCompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.setCompanyid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float amount = 3;
 * @return {number}
 */
proto.protobuf.InvoiceEntity.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional CurrencyEntity currency = 4;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.InvoiceEntity.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 4));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
*/
proto.protobuf.InvoiceEntity.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.InvoiceEntity.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 status = 5;
 * @return {number}
 */
proto.protobuf.InvoiceEntity.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string filePath = 6;
 * @return {string}
 */
proto.protobuf.InvoiceEntity.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.InvoiceEntity} returns this
 */
proto.protobuf.InvoiceEntity.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.protobuf.InvoiceEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiInvoiceRequest}
 */
proto.protobuf.BillingApiInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiInvoiceRequest;
  return proto.protobuf.BillingApiInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiInvoiceRequest}
 */
proto.protobuf.BillingApiInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.InvoiceEntity;
      reader.readMessage(value,proto.protobuf.InvoiceEntity.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.InvoiceEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional InvoiceEntity invoice = 1;
 * @return {?proto.protobuf.InvoiceEntity}
 */
proto.protobuf.BillingApiInvoiceRequest.prototype.getInvoice = function() {
  return /** @type{?proto.protobuf.InvoiceEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.InvoiceEntity, 1));
};


/**
 * @param {?proto.protobuf.InvoiceEntity|undefined} value
 * @return {!proto.protobuf.BillingApiInvoiceRequest} returns this
*/
proto.protobuf.BillingApiInvoiceRequest.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiInvoiceRequest} returns this
 */
proto.protobuf.BillingApiInvoiceRequest.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiInvoiceRequest.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.protobuf.InvoiceEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiInvoiceResponse}
 */
proto.protobuf.BillingApiInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiInvoiceResponse;
  return proto.protobuf.BillingApiInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiInvoiceResponse}
 */
proto.protobuf.BillingApiInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.InvoiceEntity;
      reader.readMessage(value,proto.protobuf.InvoiceEntity.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.InvoiceEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional InvoiceEntity invoice = 1;
 * @return {?proto.protobuf.InvoiceEntity}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.protobuf.InvoiceEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.InvoiceEntity, 1));
};


/**
 * @param {?proto.protobuf.InvoiceEntity|undefined} value
 * @return {!proto.protobuf.BillingApiInvoiceResponse} returns this
*/
proto.protobuf.BillingApiInvoiceResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiInvoiceResponse} returns this
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BillingApiInvoiceResponse} returns this
*/
proto.protobuf.BillingApiInvoiceResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiInvoiceResponse} returns this
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiInvoiceResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiGetInvoiceListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiGetInvoiceListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiGetInvoiceListRequest;
  return proto.protobuf.BillingApiGetInvoiceListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyid(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiGetInvoiceListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiGetInvoiceListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 companyId = 1;
 * @return {number}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.getCompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest} returns this
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.setCompanyid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest} returns this
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.clearCompanyid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.hasCompanyid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest} returns this
*/
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiGetInvoiceListRequest} returns this
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiGetInvoiceListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BillingApiGetInvoiceListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiGetInvoiceListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiGetInvoiceListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiGetInvoiceListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicelistList: jspb.Message.toObjectList(msg.getInvoicelistList(),
    proto.protobuf.InvoiceEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiGetInvoiceListResponse;
  return proto.protobuf.BillingApiGetInvoiceListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiGetInvoiceListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.InvoiceEntity;
      reader.readMessage(value,proto.protobuf.InvoiceEntity.deserializeBinaryFromReader);
      msg.addInvoicelist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 3:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiGetInvoiceListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiGetInvoiceListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiGetInvoiceListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.InvoiceEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InvoiceEntity invoiceList = 1;
 * @return {!Array<!proto.protobuf.InvoiceEntity>}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.getInvoicelistList = function() {
  return /** @type{!Array<!proto.protobuf.InvoiceEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.InvoiceEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.InvoiceEntity>} value
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse} returns this
*/
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.setInvoicelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.InvoiceEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.InvoiceEntity}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.addInvoicelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.InvoiceEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse} returns this
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.clearInvoicelistList = function() {
  return this.setInvoicelistList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse} returns this
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ResultResponse resultResponse = 3;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 3));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse} returns this
*/
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiGetInvoiceListResponse} returns this
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiGetInvoiceListResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CurrencyEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CurrencyEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CurrencyEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CurrencyEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CurrencyEntity}
 */
proto.protobuf.CurrencyEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CurrencyEntity;
  return proto.protobuf.CurrencyEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CurrencyEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CurrencyEntity}
 */
proto.protobuf.CurrencyEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrencyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CurrencyEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CurrencyEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CurrencyEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CurrencyEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 currencyId = 1;
 * @return {number}
 */
proto.protobuf.CurrencyEntity.prototype.getCurrencyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CurrencyEntity} returns this
 */
proto.protobuf.CurrencyEntity.prototype.setCurrencyid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.CurrencyEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CurrencyEntity} returns this
 */
proto.protobuf.CurrencyEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiCurrencyListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiCurrencyListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiCurrencyListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiCurrencyListRequest}
 */
proto.protobuf.BillingApiCurrencyListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiCurrencyListRequest;
  return proto.protobuf.BillingApiCurrencyListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiCurrencyListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiCurrencyListRequest}
 */
proto.protobuf.BillingApiCurrencyListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiCurrencyListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiCurrencyListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiCurrencyListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PageModel pageModel = 1;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 1));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BillingApiCurrencyListRequest} returns this
*/
proto.protobuf.BillingApiCurrencyListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BillingApiCurrencyListRequest} returns this
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BillingApiCurrencyListRequest} returns this
 */
proto.protobuf.BillingApiCurrencyListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BillingApiCurrencyListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BillingApiCurrencyListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BillingApiCurrencyListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BillingApiCurrencyListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiCurrencyListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currenciesList: jspb.Message.toObjectList(msg.getCurrenciesList(),
    proto.protobuf.CurrencyEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BillingApiCurrencyListResponse}
 */
proto.protobuf.BillingApiCurrencyListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BillingApiCurrencyListResponse;
  return proto.protobuf.BillingApiCurrencyListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BillingApiCurrencyListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BillingApiCurrencyListResponse}
 */
proto.protobuf.BillingApiCurrencyListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BillingApiCurrencyListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BillingApiCurrencyListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BillingApiCurrencyListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BillingApiCurrencyListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CurrencyEntity currencies = 1;
 * @return {!Array<!proto.protobuf.CurrencyEntity>}
 */
proto.protobuf.BillingApiCurrencyListResponse.prototype.getCurrenciesList = function() {
  return /** @type{!Array<!proto.protobuf.CurrencyEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.CurrencyEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.CurrencyEntity>} value
 * @return {!proto.protobuf.BillingApiCurrencyListResponse} returns this
*/
proto.protobuf.BillingApiCurrencyListResponse.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.CurrencyEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.CurrencyEntity}
 */
proto.protobuf.BillingApiCurrencyListResponse.prototype.addCurrencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.CurrencyEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BillingApiCurrencyListResponse} returns this
 */
proto.protobuf.BillingApiCurrencyListResponse.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiAgentListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiAgentListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiAgentListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiAgentListRequest}
 */
proto.protobuf.UserAccountApiAgentListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiAgentListRequest;
  return proto.protobuf.UserAccountApiAgentListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiAgentListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiAgentListRequest}
 */
proto.protobuf.UserAccountApiAgentListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiAgentListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiAgentListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiAgentListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiAgentListRequest} returns this
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.UserAccountApiAgentListRequest} returns this
*/
proto.protobuf.UserAccountApiAgentListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiAgentListRequest} returns this
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiAgentListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiFlyCompanyListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListRequest}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiFlyCompanyListRequest;
  return proto.protobuf.UserAccountApiFlyCompanyListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListRequest}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiFlyCompanyListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListRequest} returns this
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListRequest} returns this
*/
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListRequest} returns this
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiFlyCompanyListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CompanyEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CompanyEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CompanyEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CompanyEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatar: (f = msg.getAvatar()) && proto.protobuf.FileEntity.toObject(includeInstance, f),
    admin: (f = msg.getAdmin()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    companystatusid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phone: jspb.Message.getFieldWithDefault(msg, 6, 0),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    website: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    operatorcard: (f = msg.getOperatorcard()) && proto.protobuf.FileEntity.toObject(includeInstance, f),
    registrationcertificate: (f = msg.getRegistrationcertificate()) && proto.protobuf.FileEntity.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CompanyEntity}
 */
proto.protobuf.CompanyEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CompanyEntity;
  return proto.protobuf.CompanyEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CompanyEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CompanyEntity}
 */
proto.protobuf.CompanyEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 3:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.setAvatar(value);
      break;
    case 4:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setAdmin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanystatusid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.setOperatorcard(value);
      break;
    case 12:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.setRegistrationcertificate(value);
      break;
    case 13:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CompanyEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CompanyEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CompanyEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CompanyEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatar();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getAdmin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompanystatusid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOperatorcard();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationcertificate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 companyId = 1;
 * @return {number}
 */
proto.protobuf.CompanyEntity.prototype.getCompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setCompanyid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string companyName = 2;
 * @return {string}
 */
proto.protobuf.CompanyEntity.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FileEntity avatar = 3;
 * @return {?proto.protobuf.FileEntity}
 */
proto.protobuf.CompanyEntity.prototype.getAvatar = function() {
  return /** @type{?proto.protobuf.FileEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileEntity, 3));
};


/**
 * @param {?proto.protobuf.FileEntity|undefined} value
 * @return {!proto.protobuf.CompanyEntity} returns this
*/
proto.protobuf.CompanyEntity.prototype.setAvatar = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.clearAvatar = function() {
  return this.setAvatar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyEntity.prototype.hasAvatar = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserEntity admin = 4;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.CompanyEntity.prototype.getAdmin = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 4));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.CompanyEntity} returns this
*/
proto.protobuf.CompanyEntity.prototype.setAdmin = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.clearAdmin = function() {
  return this.setAdmin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyEntity.prototype.hasAdmin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 companyStatusId = 5;
 * @return {number}
 */
proto.protobuf.CompanyEntity.prototype.getCompanystatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setCompanystatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 phone = 6;
 * @return {number}
 */
proto.protobuf.CompanyEntity.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.protobuf.CompanyEntity.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string website = 8;
 * @return {string}
 */
proto.protobuf.CompanyEntity.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.protobuf.CompanyEntity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.protobuf.CompanyEntity.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional FileEntity operatorCard = 11;
 * @return {?proto.protobuf.FileEntity}
 */
proto.protobuf.CompanyEntity.prototype.getOperatorcard = function() {
  return /** @type{?proto.protobuf.FileEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileEntity, 11));
};


/**
 * @param {?proto.protobuf.FileEntity|undefined} value
 * @return {!proto.protobuf.CompanyEntity} returns this
*/
proto.protobuf.CompanyEntity.prototype.setOperatorcard = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.clearOperatorcard = function() {
  return this.setOperatorcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyEntity.prototype.hasOperatorcard = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FileEntity registrationCertificate = 12;
 * @return {?proto.protobuf.FileEntity}
 */
proto.protobuf.CompanyEntity.prototype.getRegistrationcertificate = function() {
  return /** @type{?proto.protobuf.FileEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileEntity, 12));
};


/**
 * @param {?proto.protobuf.FileEntity|undefined} value
 * @return {!proto.protobuf.CompanyEntity} returns this
*/
proto.protobuf.CompanyEntity.prototype.setRegistrationcertificate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.clearRegistrationcertificate = function() {
  return this.setRegistrationcertificate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyEntity.prototype.hasRegistrationcertificate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CurrencyEntity currency = 13;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.CompanyEntity.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 13));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.CompanyEntity} returns this
*/
proto.protobuf.CompanyEntity.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CompanyEntity.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 type = 14;
 * @return {number}
 */
proto.protobuf.CompanyEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CompanyEntity} returns this
 */
proto.protobuf.CompanyEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiAgentListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiAgentListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiAgentListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiAgentListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agenciesList: jspb.Message.toObjectList(msg.getAgenciesList(),
    proto.protobuf.CompanyEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiAgentListResponse}
 */
proto.protobuf.UserAccountApiAgentListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiAgentListResponse;
  return proto.protobuf.UserAccountApiAgentListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiAgentListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiAgentListResponse}
 */
proto.protobuf.UserAccountApiAgentListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.addAgencies(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiAgentListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiAgentListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiAgentListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated CompanyEntity agencies = 1;
 * @return {!Array<!proto.protobuf.CompanyEntity>}
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.getAgenciesList = function() {
  return /** @type{!Array<!proto.protobuf.CompanyEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.CompanyEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.CompanyEntity>} value
 * @return {!proto.protobuf.UserAccountApiAgentListResponse} returns this
*/
proto.protobuf.UserAccountApiAgentListResponse.prototype.setAgenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.CompanyEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.CompanyEntity}
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.addAgencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.CompanyEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiAgentListResponse} returns this
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.clearAgenciesList = function() {
  return this.setAgenciesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiAgentListResponse} returns this
 */
proto.protobuf.UserAccountApiAgentListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiFlyCompanyListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flycompaniesList: jspb.Message.toObjectList(msg.getFlycompaniesList(),
    proto.protobuf.CompanyEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListResponse}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiFlyCompanyListResponse;
  return proto.protobuf.UserAccountApiFlyCompanyListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListResponse}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.addFlycompanies(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiFlyCompanyListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlycompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated CompanyEntity flyCompanies = 1;
 * @return {!Array<!proto.protobuf.CompanyEntity>}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.getFlycompaniesList = function() {
  return /** @type{!Array<!proto.protobuf.CompanyEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.CompanyEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.CompanyEntity>} value
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListResponse} returns this
*/
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.setFlycompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.CompanyEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.CompanyEntity}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.addFlycompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.CompanyEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListResponse} returns this
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.clearFlycompaniesList = function() {
  return this.setFlycompaniesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiFlyCompanyListResponse} returns this
 */
proto.protobuf.UserAccountApiFlyCompanyListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ManagerEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ManagerEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ManagerEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ManagerEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatarlink: jspb.Message.getFieldWithDefault(msg, 5, ""),
    role: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ManagerEntity}
 */
proto.protobuf.ManagerEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ManagerEntity;
  return proto.protobuf.ManagerEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ManagerEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ManagerEntity}
 */
proto.protobuf.ManagerEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManagerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarlink(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ManagerEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ManagerEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ManagerEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ManagerEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatarlink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int32 managerId = 1;
 * @return {number}
 */
proto.protobuf.ManagerEntity.prototype.getManagerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setManagerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string middleName = 4;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string avatarLink = 5;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getAvatarlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setAvatarlink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string role = 6;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.protobuf.ManagerEntity.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 phone = 8;
 * @return {number}
 */
proto.protobuf.ManagerEntity.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ManagerEntity} returns this
 */
proto.protobuf.ManagerEntity.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiManagerListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiManagerListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiManagerListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiManagerListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    managersList: jspb.Message.toObjectList(msg.getManagersList(),
    proto.protobuf.ManagerEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiManagerListResponse}
 */
proto.protobuf.UserAccountApiManagerListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiManagerListResponse;
  return proto.protobuf.UserAccountApiManagerListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiManagerListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiManagerListResponse}
 */
proto.protobuf.UserAccountApiManagerListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ManagerEntity;
      reader.readMessage(value,proto.protobuf.ManagerEntity.deserializeBinaryFromReader);
      msg.addManagers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiManagerListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiManagerListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiManagerListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.ManagerEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated ManagerEntity managers = 1;
 * @return {!Array<!proto.protobuf.ManagerEntity>}
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.getManagersList = function() {
  return /** @type{!Array<!proto.protobuf.ManagerEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.ManagerEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.ManagerEntity>} value
 * @return {!proto.protobuf.UserAccountApiManagerListResponse} returns this
*/
proto.protobuf.UserAccountApiManagerListResponse.prototype.setManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.ManagerEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.ManagerEntity}
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.addManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.ManagerEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiManagerListResponse} returns this
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.clearManagersList = function() {
  return this.setManagersList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiManagerListResponse} returns this
 */
proto.protobuf.UserAccountApiManagerListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiManagerListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiManagerListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiManagerListRequest}
 */
proto.protobuf.UserAccountApiManagerListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiManagerListRequest;
  return proto.protobuf.UserAccountApiManagerListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiManagerListRequest}
 */
proto.protobuf.UserAccountApiManagerListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiManagerListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiManagerListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiManagerListRequest} returns this
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.UserAccountApiManagerListRequest} returns this
*/
proto.protobuf.UserAccountApiManagerListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiManagerListRequest} returns this
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiManagerListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiRemoveManagerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerRequest}
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiRemoveManagerRequest;
  return proto.protobuf.UserAccountApiRemoveManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerRequest}
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManagerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiRemoveManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 managerId = 1;
 * @return {number}
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.prototype.getManagerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiRemoveManagerRequest} returns this
 */
proto.protobuf.UserAccountApiRemoveManagerRequest.prototype.setManagerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiRemoveManagerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiRemoveManagerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    manager: (f = msg.getManager()) && proto.protobuf.ManagerEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiRemoveManagerResponse;
  return proto.protobuf.UserAccountApiRemoveManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiRemoveManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ManagerEntity;
      reader.readMessage(value,proto.protobuf.ManagerEntity.deserializeBinaryFromReader);
      msg.setManager(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiRemoveManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiRemoveManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManager();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ManagerEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ManagerEntity manager = 1;
 * @return {?proto.protobuf.ManagerEntity}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.getManager = function() {
  return /** @type{?proto.protobuf.ManagerEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ManagerEntity, 1));
};


/**
 * @param {?proto.protobuf.ManagerEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse} returns this
*/
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.setManager = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse} returns this
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.clearManager = function() {
  return this.setManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.hasManager = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse} returns this
*/
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiRemoveManagerResponse} returns this
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiRemoveManagerResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userstatusid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    role: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, 0),
    company: (f = msg.getCompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    password: jspb.Message.getFieldWithDefault(msg, 11, ""),
    timezone: (f = msg.getTimezone()) && proto.protobuf.TimezoneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserEntity}
 */
proto.protobuf.UserEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserEntity;
  return proto.protobuf.UserEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserEntity}
 */
proto.protobuf.UserEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserstatusid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    case 10:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 12:
      var value = new proto.protobuf.TimezoneEntity;
      reader.readMessage(value,proto.protobuf.TimezoneEntity.deserializeBinaryFromReader);
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserstatusid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTimezone();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.protobuf.TimezoneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.protobuf.UserEntity.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string middleName = 4;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string avatar = 5;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 userStatusId = 7;
 * @return {number}
 */
proto.protobuf.UserEntity.prototype.getUserstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setUserstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string role = 8;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 phone = 9;
 * @return {number}
 */
proto.protobuf.UserEntity.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional CompanyEntity company = 10;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.UserEntity.prototype.getCompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 10));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.UserEntity} returns this
*/
proto.protobuf.UserEntity.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserEntity.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string password = 11;
 * @return {string}
 */
proto.protobuf.UserEntity.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional TimezoneEntity timezone = 12;
 * @return {?proto.protobuf.TimezoneEntity}
 */
proto.protobuf.UserEntity.prototype.getTimezone = function() {
  return /** @type{?proto.protobuf.TimezoneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TimezoneEntity, 12));
};


/**
 * @param {?proto.protobuf.TimezoneEntity|undefined} value
 * @return {!proto.protobuf.UserEntity} returns this
*/
proto.protobuf.UserEntity.prototype.setTimezone = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserEntity} returns this
 */
proto.protobuf.UserEntity.prototype.clearTimezone = function() {
  return this.setTimezone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserEntity.prototype.hasTimezone = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiUserUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timezone: (f = msg.getTimezone()) && proto.protobuf.TimezoneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiUserUpdateRequest;
  return proto.protobuf.UserAccountApiUserUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    case 7:
      var value = new proto.protobuf.TimezoneEntity;
      reader.readMessage(value,proto.protobuf.TimezoneEntity.deserializeBinaryFromReader);
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiUserUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTimezone();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.TimezoneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string middleName = 4;
 * @return {string}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string role = 5;
 * @return {string}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 phone = 6;
 * @return {number}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TimezoneEntity timezone = 7;
 * @return {?proto.protobuf.TimezoneEntity}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.getTimezone = function() {
  return /** @type{?proto.protobuf.TimezoneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TimezoneEntity, 7));
};


/**
 * @param {?proto.protobuf.TimezoneEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
*/
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiUserUpdateRequest} returns this
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.clearTimezone = function() {
  return this.setTimezone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiUserUpdateRequest.prototype.hasTimezone = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiUserResponse}
 */
proto.protobuf.UserAccountApiUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiUserResponse;
  return proto.protobuf.UserAccountApiUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiUserResponse}
 */
proto.protobuf.UserAccountApiUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserEntity user = 1;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.getUser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 1));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiUserResponse} returns this
*/
proto.protobuf.UserAccountApiUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiUserResponse} returns this
 */
proto.protobuf.UserAccountApiUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.UserAccountApiUserResponse} returns this
*/
proto.protobuf.UserAccountApiUserResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiUserResponse} returns this
 */
proto.protobuf.UserAccountApiUserResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiUserResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserStatusEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserStatusEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserStatusEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserStatusEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    userstatusid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserStatusEntity}
 */
proto.protobuf.UserStatusEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserStatusEntity;
  return proto.protobuf.UserStatusEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserStatusEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserStatusEntity}
 */
proto.protobuf.UserStatusEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserstatusid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserStatusEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserStatusEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserStatusEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserStatusEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserstatusid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 userStatusId = 1;
 * @return {number}
 */
proto.protobuf.UserStatusEntity.prototype.getUserstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserStatusEntity} returns this
 */
proto.protobuf.UserStatusEntity.prototype.setUserstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.UserStatusEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserStatusEntity} returns this
 */
proto.protobuf.UserStatusEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiUserStatusListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiUserStatusListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiUserStatusListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserStatusListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userstatusesList: jspb.Message.toObjectList(msg.getUserstatusesList(),
    proto.protobuf.UserStatusEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiUserStatusListResponse}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiUserStatusListResponse;
  return proto.protobuf.UserAccountApiUserStatusListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiUserStatusListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiUserStatusListResponse}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.UserStatusEntity;
      reader.readMessage(value,proto.protobuf.UserStatusEntity.deserializeBinaryFromReader);
      msg.addUserstatuses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiUserStatusListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiUserStatusListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserStatusListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserstatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.UserStatusEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated UserStatusEntity userStatuses = 1;
 * @return {!Array<!proto.protobuf.UserStatusEntity>}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.getUserstatusesList = function() {
  return /** @type{!Array<!proto.protobuf.UserStatusEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.UserStatusEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.UserStatusEntity>} value
 * @return {!proto.protobuf.UserAccountApiUserStatusListResponse} returns this
*/
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.setUserstatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.UserStatusEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.UserStatusEntity}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.addUserstatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.UserStatusEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiUserStatusListResponse} returns this
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.clearUserstatusesList = function() {
  return this.setUserstatusesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiUserStatusListResponse} returns this
 */
proto.protobuf.UserAccountApiUserStatusListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiUserListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiUserListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiUserListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userstatusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    query: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiUserListRequest}
 */
proto.protobuf.UserAccountApiUserListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiUserListRequest;
  return proto.protobuf.UserAccountApiUserListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiUserListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiUserListRequest}
 */
proto.protobuf.UserAccountApiUserListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserstatuses(values[i]);
      }
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiUserListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiUserListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserstatusesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated int32 userStatuses = 1;
 * @return {!Array<number>}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.getUserstatusesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.setUserstatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.addUserstatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.clearUserstatusesList = function() {
  return this.setUserstatusesList([]);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
*/
proto.protobuf.UserAccountApiUserListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiUserListRequest} returns this
 */
proto.protobuf.UserAccountApiUserListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.UserAccountApiUserListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiUserListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiUserListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.protobuf.UserEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiUserListResponse}
 */
proto.protobuf.UserAccountApiUserListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiUserListResponse;
  return proto.protobuf.UserAccountApiUserListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiUserListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiUserListResponse}
 */
proto.protobuf.UserAccountApiUserListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiUserListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiUserListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiUserListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated UserEntity users = 1;
 * @return {!Array<!proto.protobuf.UserEntity>}
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.protobuf.UserEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.UserEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.UserEntity>} value
 * @return {!proto.protobuf.UserAccountApiUserListResponse} returns this
*/
proto.protobuf.UserAccountApiUserListResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.UserEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.UserEntity}
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.UserEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.UserAccountApiUserListResponse} returns this
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiUserListResponse} returns this
 */
proto.protobuf.UserAccountApiUserListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiChangeUserStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userstatusid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiChangeUserStatusRequest}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiChangeUserStatusRequest;
  return proto.protobuf.UserAccountApiChangeUserStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiChangeUserStatusRequest}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserstatusid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiChangeUserStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserstatusid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userStatusId = 1;
 * @return {number}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.getUserstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiChangeUserStatusRequest} returns this
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.setUserstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiChangeUserStatusRequest} returns this
 */
proto.protobuf.UserAccountApiChangeUserStatusRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiCreateManagerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCreateManagerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.protobuf.UserEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiCreateManagerRequest}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiCreateManagerRequest;
  return proto.protobuf.UserAccountApiCreateManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiCreateManagerRequest}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiCreateManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCreateManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserEntity user = 1;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.getUser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 1));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiCreateManagerRequest} returns this
*/
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCreateManagerRequest} returns this
 */
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCreateManagerRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiCreateManagerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiCreateManagerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCreateManagerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiCreateManagerResponse;
  return proto.protobuf.UserAccountApiCreateManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiCreateManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiCreateManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiCreateManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCreateManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserEntity user = 1;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.getUser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 1));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse} returns this
*/
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse} returns this
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse} returns this
*/
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCreateManagerResponse} returns this
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCreateManagerResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oldpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newpassword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    repeatnewpassword: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiChangePasswordRequest;
  return proto.protobuf.UserAccountApiChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatnewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOldpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRepeatnewpassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest} returns this
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string oldPassword = 2;
 * @return {string}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.getOldpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest} returns this
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.setOldpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string newPassword = 3;
 * @return {string}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest} returns this
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.setNewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string repeatNewPassword = 4;
 * @return {string}
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.getRepeatnewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.UserAccountApiChangePasswordRequest} returns this
 */
proto.protobuf.UserAccountApiChangePasswordRequest.prototype.setRepeatnewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: (f = msg.getCompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiCompanyRequest}
 */
proto.protobuf.UserAccountApiCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiCompanyRequest;
  return proto.protobuf.UserAccountApiCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiCompanyRequest}
 */
proto.protobuf.UserAccountApiCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompanyEntity company = 1;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.UserAccountApiCompanyRequest.prototype.getCompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 1));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiCompanyRequest} returns this
*/
proto.protobuf.UserAccountApiCompanyRequest.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCompanyRequest} returns this
 */
proto.protobuf.UserAccountApiCompanyRequest.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCompanyRequest.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.UserAccountApiCompanyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.UserAccountApiCompanyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCompanyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: (f = msg.getCompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.UserAccountApiCompanyResponse}
 */
proto.protobuf.UserAccountApiCompanyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.UserAccountApiCompanyResponse;
  return proto.protobuf.UserAccountApiCompanyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.UserAccountApiCompanyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.UserAccountApiCompanyResponse}
 */
proto.protobuf.UserAccountApiCompanyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.UserAccountApiCompanyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.UserAccountApiCompanyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.UserAccountApiCompanyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompanyEntity company = 1;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.getCompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 1));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.UserAccountApiCompanyResponse} returns this
*/
proto.protobuf.UserAccountApiCompanyResponse.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCompanyResponse} returns this
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.UserAccountApiCompanyResponse} returns this
*/
proto.protobuf.UserAccountApiCompanyResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.UserAccountApiCompanyResponse} returns this
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.UserAccountApiCompanyResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.RequestCallbackEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.RequestCallbackEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.RequestCallbackEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.RequestCallbackEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    agencyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, 0),
    message: jspb.Message.getFieldWithDefault(msg, 6, ""),
    callbackrequestid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.RequestCallbackEntity}
 */
proto.protobuf.RequestCallbackEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.RequestCallbackEntity;
  return proto.protobuf.RequestCallbackEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.RequestCallbackEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.RequestCallbackEntity}
 */
proto.protobuf.RequestCallbackEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAgencyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCallbackrequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.RequestCallbackEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.RequestCallbackEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.RequestCallbackEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.RequestCallbackEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgencyid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCallbackrequestid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 agencyId = 1;
 * @return {number}
 */
proto.protobuf.RequestCallbackEntity.prototype.getAgencyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setAgencyid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.protobuf.RequestCallbackEntity.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.protobuf.RequestCallbackEntity.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string middleName = 4;
 * @return {string}
 */
proto.protobuf.RequestCallbackEntity.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 phone = 5;
 * @return {number}
 */
proto.protobuf.RequestCallbackEntity.prototype.getPhone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setPhone = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string message = 6;
 * @return {string}
 */
proto.protobuf.RequestCallbackEntity.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 callbackRequestId = 7;
 * @return {number}
 */
proto.protobuf.RequestCallbackEntity.prototype.getCallbackrequestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.RequestCallbackEntity} returns this
 */
proto.protobuf.RequestCallbackEntity.prototype.setCallbackrequestid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiRequestCallbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestcallback: (f = msg.getRequestcallback()) && proto.protobuf.RequestCallbackEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackRequest}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiRequestCallbackRequest;
  return proto.protobuf.CommunicationApiRequestCallbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackRequest}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.RequestCallbackEntity;
      reader.readMessage(value,proto.protobuf.RequestCallbackEntity.deserializeBinaryFromReader);
      msg.setRequestcallback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiRequestCallbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestcallback();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.RequestCallbackEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestCallbackEntity requestCallback = 1;
 * @return {?proto.protobuf.RequestCallbackEntity}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.getRequestcallback = function() {
  return /** @type{?proto.protobuf.RequestCallbackEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.RequestCallbackEntity, 1));
};


/**
 * @param {?proto.protobuf.RequestCallbackEntity|undefined} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackRequest} returns this
*/
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.setRequestcallback = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackRequest} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.clearRequestcallback = function() {
  return this.setRequestcallback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiRequestCallbackRequest.prototype.hasRequestcallback = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiRequestCallbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiRequestCallbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestcallback: (f = msg.getRequestcallback()) && proto.protobuf.RequestCallbackEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiRequestCallbackResponse;
  return proto.protobuf.CommunicationApiRequestCallbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.RequestCallbackEntity;
      reader.readMessage(value,proto.protobuf.RequestCallbackEntity.deserializeBinaryFromReader);
      msg.setRequestcallback(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiRequestCallbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestcallback();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.RequestCallbackEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestCallbackEntity requestCallback = 1;
 * @return {?proto.protobuf.RequestCallbackEntity}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.getRequestcallback = function() {
  return /** @type{?proto.protobuf.RequestCallbackEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.RequestCallbackEntity, 1));
};


/**
 * @param {?proto.protobuf.RequestCallbackEntity|undefined} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse} returns this
*/
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.setRequestcallback = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.clearRequestcallback = function() {
  return this.setRequestcallback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.hasRequestcallback = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse} returns this
*/
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackResponse} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiRequestCallbackResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiRequestCallbackListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListRequest}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiRequestCallbackListRequest;
  return proto.protobuf.CommunicationApiRequestCallbackListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListRequest}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiRequestCallbackListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListRequest} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListRequest} returns this
*/
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListRequest} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiRequestCallbackListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiRequestCallbackListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestcallbacksList: jspb.Message.toObjectList(msg.getRequestcallbacksList(),
    proto.protobuf.RequestCallbackEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListResponse}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiRequestCallbackListResponse;
  return proto.protobuf.CommunicationApiRequestCallbackListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListResponse}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.RequestCallbackEntity;
      reader.readMessage(value,proto.protobuf.RequestCallbackEntity.deserializeBinaryFromReader);
      msg.addRequestcallbacks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiRequestCallbackListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestcallbacksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.RequestCallbackEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated RequestCallbackEntity requestCallbacks = 1;
 * @return {!Array<!proto.protobuf.RequestCallbackEntity>}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.getRequestcallbacksList = function() {
  return /** @type{!Array<!proto.protobuf.RequestCallbackEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.RequestCallbackEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.RequestCallbackEntity>} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListResponse} returns this
*/
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.setRequestcallbacksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.RequestCallbackEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.RequestCallbackEntity}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.addRequestcallbacks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.RequestCallbackEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListResponse} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.clearRequestcallbacksList = function() {
  return this.setRequestcallbacksList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CommunicationApiRequestCallbackListResponse} returns this
 */
proto.protobuf.CommunicationApiRequestCallbackListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ChatEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ChatEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ChatEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ChatEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    emptylegid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fromuser: (f = msg.getFromuser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    touser: (f = msg.getTouser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    fromcompany: (f = msg.getFromcompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    tocompany: (f = msg.getTocompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ChatEntity}
 */
proto.protobuf.ChatEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ChatEntity;
  return proto.protobuf.ChatEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ChatEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ChatEntity}
 */
proto.protobuf.ChatEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegid(value);
      break;
    case 4:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setFromuser(value);
      break;
    case 5:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setTouser(value);
      break;
    case 6:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setFromcompany(value);
      break;
    case 7:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setTocompany(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ChatEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ChatEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ChatEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ChatEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEmptylegid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFromuser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getTouser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getFromcompany();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getTocompany();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.protobuf.ChatEntity.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 orderId = 2;
 * @return {number}
 */
proto.protobuf.ChatEntity.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 emptyLegId = 3;
 * @return {number}
 */
proto.protobuf.ChatEntity.prototype.getEmptylegid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.setEmptylegid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional UserEntity fromUser = 4;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.ChatEntity.prototype.getFromuser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 4));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.ChatEntity} returns this
*/
proto.protobuf.ChatEntity.prototype.setFromuser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.clearFromuser = function() {
  return this.setFromuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatEntity.prototype.hasFromuser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UserEntity toUser = 5;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.ChatEntity.prototype.getTouser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 5));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.ChatEntity} returns this
*/
proto.protobuf.ChatEntity.prototype.setTouser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.clearTouser = function() {
  return this.setTouser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatEntity.prototype.hasTouser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CompanyEntity fromCompany = 6;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.ChatEntity.prototype.getFromcompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 6));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.ChatEntity} returns this
*/
proto.protobuf.ChatEntity.prototype.setFromcompany = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.clearFromcompany = function() {
  return this.setFromcompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatEntity.prototype.hasFromcompany = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CompanyEntity toCompany = 7;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.ChatEntity.prototype.getTocompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 7));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.ChatEntity} returns this
*/
proto.protobuf.ChatEntity.prototype.setTocompany = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatEntity} returns this
 */
proto.protobuf.ChatEntity.prototype.clearTocompany = function() {
  return this.setTocompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatEntity.prototype.hasTocompany = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ChatMessageEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ChatMessageEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ChatMessageEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ChatMessageEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatmessageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senddate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chat: (f = msg.getChat()) && proto.protobuf.ChatEntity.toObject(includeInstance, f),
    fromuser: (f = msg.getFromuser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    touser: (f = msg.getTouser()) && proto.protobuf.UserEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ChatMessageEntity}
 */
proto.protobuf.ChatMessageEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ChatMessageEntity;
  return proto.protobuf.ChatMessageEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ChatMessageEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ChatMessageEntity}
 */
proto.protobuf.ChatMessageEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatmessageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenddate(value);
      break;
    case 4:
      var value = new proto.protobuf.ChatEntity;
      reader.readMessage(value,proto.protobuf.ChatEntity.deserializeBinaryFromReader);
      msg.setChat(value);
      break;
    case 5:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setFromuser(value);
      break;
    case 6:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setTouser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ChatMessageEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ChatMessageEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ChatMessageEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ChatMessageEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatmessageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenddate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.ChatEntity.serializeBinaryToWriter
    );
  }
  f = message.getFromuser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getTouser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string chatMessageId = 1;
 * @return {string}
 */
proto.protobuf.ChatMessageEntity.prototype.getChatmessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.setChatmessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.protobuf.ChatMessageEntity.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sendDate = 3;
 * @return {string}
 */
proto.protobuf.ChatMessageEntity.prototype.getSenddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.setSenddate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ChatEntity chat = 4;
 * @return {?proto.protobuf.ChatEntity}
 */
proto.protobuf.ChatMessageEntity.prototype.getChat = function() {
  return /** @type{?proto.protobuf.ChatEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ChatEntity, 4));
};


/**
 * @param {?proto.protobuf.ChatEntity|undefined} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
*/
proto.protobuf.ChatMessageEntity.prototype.setChat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.clearChat = function() {
  return this.setChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatMessageEntity.prototype.hasChat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UserEntity fromUser = 5;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.ChatMessageEntity.prototype.getFromuser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 5));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
*/
proto.protobuf.ChatMessageEntity.prototype.setFromuser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.clearFromuser = function() {
  return this.setFromuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatMessageEntity.prototype.hasFromuser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserEntity toUser = 6;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.ChatMessageEntity.prototype.getTouser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 6));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.ChatMessageEntity} returns this
*/
proto.protobuf.ChatMessageEntity.prototype.setTouser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ChatMessageEntity} returns this
 */
proto.protobuf.ChatMessageEntity.prototype.clearTouser = function() {
  return this.setTouser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ChatMessageEntity.prototype.hasTouser = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatMessageListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    chatid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatMessageListRequest}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatMessageListRequest;
  return proto.protobuf.CommunicationApiChatMessageListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatMessageListRequest}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatMessageListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PageModel pageModel = 1;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 1));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.CommunicationApiChatMessageListRequest} returns this
*/
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiChatMessageListRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string chatId = 2;
 * @return {string}
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CommunicationApiChatMessageListRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageListRequest.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.CommunicationApiChatMessageListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatMessageListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatMessageListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatmessagesList: jspb.Message.toObjectList(msg.getChatmessagesList(),
    proto.protobuf.ChatMessageEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatMessageListResponse}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatMessageListResponse;
  return proto.protobuf.CommunicationApiChatMessageListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatMessageListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatMessageListResponse}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ChatMessageEntity;
      reader.readMessage(value,proto.protobuf.ChatMessageEntity.deserializeBinaryFromReader);
      msg.addChatmessages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatMessageListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatMessageListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatmessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.ChatMessageEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated ChatMessageEntity chatMessages = 1;
 * @return {!Array<!proto.protobuf.ChatMessageEntity>}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.getChatmessagesList = function() {
  return /** @type{!Array<!proto.protobuf.ChatMessageEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.ChatMessageEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.ChatMessageEntity>} value
 * @return {!proto.protobuf.CommunicationApiChatMessageListResponse} returns this
*/
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.setChatmessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.ChatMessageEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.ChatMessageEntity}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.addChatmessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.ChatMessageEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.CommunicationApiChatMessageListResponse} returns this
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.clearChatmessagesList = function() {
  return this.setChatmessagesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CommunicationApiChatMessageListResponse} returns this
 */
proto.protobuf.CommunicationApiChatMessageListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatListRequest}
 */
proto.protobuf.CommunicationApiChatListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatListRequest;
  return proto.protobuf.CommunicationApiChatListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatListRequest}
 */
proto.protobuf.CommunicationApiChatListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.CommunicationApiChatListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatsList: jspb.Message.toObjectList(msg.getChatsList(),
    proto.protobuf.ChatEntity.toObject, includeInstance),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatListResponse}
 */
proto.protobuf.CommunicationApiChatListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatListResponse;
  return proto.protobuf.CommunicationApiChatListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatListResponse}
 */
proto.protobuf.CommunicationApiChatListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ChatEntity;
      reader.readMessage(value,proto.protobuf.ChatEntity.deserializeBinaryFromReader);
      msg.addChats(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.ChatEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChatEntity chats = 1;
 * @return {!Array<!proto.protobuf.ChatEntity>}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.getChatsList = function() {
  return /** @type{!Array<!proto.protobuf.ChatEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.ChatEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.ChatEntity>} value
 * @return {!proto.protobuf.CommunicationApiChatListResponse} returns this
*/
proto.protobuf.CommunicationApiChatListResponse.prototype.setChatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.ChatEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.ChatEntity}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.addChats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.ChatEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.CommunicationApiChatListResponse} returns this
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.clearChatsList = function() {
  return this.setChatsList([]);
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.CommunicationApiChatListResponse} returns this
*/
proto.protobuf.CommunicationApiChatListResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiChatListResponse} returns this
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiChatListResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatMessageSendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    touserid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderrouteid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chatid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatMessageSendRequest;
  return proto.protobuf.CommunicationApiChatMessageSendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTouserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrouteid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatMessageSendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTouserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderrouteid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 toUserId = 1;
 * @return {number}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.getTouserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.setTouserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 orderRouteId = 3;
 * @return {number}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.getOrderrouteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.setOrderrouteid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string chatId = 4;
 * @return {string}
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendRequest} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendRequest.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationApiChatMessageSendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationApiChatMessageSendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatmessage: (f = msg.getChatmessage()) && proto.protobuf.ChatMessageEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationApiChatMessageSendResponse;
  return proto.protobuf.CommunicationApiChatMessageSendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationApiChatMessageSendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ChatMessageEntity;
      reader.readMessage(value,proto.protobuf.ChatMessageEntity.deserializeBinaryFromReader);
      msg.setChatmessage(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationApiChatMessageSendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationApiChatMessageSendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatmessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ChatMessageEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatMessageEntity chatMessage = 1;
 * @return {?proto.protobuf.ChatMessageEntity}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.getChatmessage = function() {
  return /** @type{?proto.protobuf.ChatMessageEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ChatMessageEntity, 1));
};


/**
 * @param {?proto.protobuf.ChatMessageEntity|undefined} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse} returns this
*/
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.setChatmessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.clearChatmessage = function() {
  return this.setChatmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.hasChatmessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse} returns this
*/
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.CommunicationApiChatMessageSendResponse} returns this
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.CommunicationApiChatMessageSendResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.CommunicationWebsocketMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.CommunicationWebsocketMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationWebsocketMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64(),
    accesstoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.CommunicationWebsocketMessage}
 */
proto.protobuf.CommunicationWebsocketMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.CommunicationWebsocketMessage;
  return proto.protobuf.CommunicationWebsocketMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.CommunicationWebsocketMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.CommunicationWebsocketMessage}
 */
proto.protobuf.CommunicationWebsocketMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.protobuf.WebsocketMessageType} */ (reader.readEnum());
      msg.setMessagetype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMessageid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.CommunicationWebsocketMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.CommunicationWebsocketMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.CommunicationWebsocketMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessageid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional WebsocketMessageType messageType = 1;
 * @return {!proto.protobuf.WebsocketMessageType}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getMessagetype = function() {
  return /** @type {!proto.protobuf.WebsocketMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.protobuf.WebsocketMessageType} value
 * @return {!proto.protobuf.CommunicationWebsocketMessage} returns this
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 messageId = 2;
 * @return {number}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getMessageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.CommunicationWebsocketMessage} returns this
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {string}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.CommunicationWebsocketMessage} returns this
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string accessToken = 4;
 * @return {string}
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.CommunicationWebsocketMessage} returns this
 */
proto.protobuf.CommunicationWebsocketMessage.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.WebsocketErrorResponseMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.WebsocketErrorResponseMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.WebsocketErrorResponseMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketErrorResponseMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errormessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.WebsocketErrorResponseMessage}
 */
proto.protobuf.WebsocketErrorResponseMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.WebsocketErrorResponseMessage;
  return proto.protobuf.WebsocketErrorResponseMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.WebsocketErrorResponseMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.WebsocketErrorResponseMessage}
 */
proto.protobuf.WebsocketErrorResponseMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.WebsocketErrorResponseMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.WebsocketErrorResponseMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.WebsocketErrorResponseMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketErrorResponseMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string errorMessage = 1;
 * @return {string}
 */
proto.protobuf.WebsocketErrorResponseMessage.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.WebsocketErrorResponseMessage} returns this
 */
proto.protobuf.WebsocketErrorResponseMessage.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.WebsocketPingMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.WebsocketPingMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.WebsocketPingMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketPingMessage.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.WebsocketPingMessage}
 */
proto.protobuf.WebsocketPingMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.WebsocketPingMessage;
  return proto.protobuf.WebsocketPingMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.WebsocketPingMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.WebsocketPingMessage}
 */
proto.protobuf.WebsocketPingMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.WebsocketPingMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.WebsocketPingMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.WebsocketPingMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketPingMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.WebsocketPongMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.WebsocketPongMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.WebsocketPongMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketPongMessage.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.WebsocketPongMessage}
 */
proto.protobuf.WebsocketPongMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.WebsocketPongMessage;
  return proto.protobuf.WebsocketPongMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.WebsocketPongMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.WebsocketPongMessage}
 */
proto.protobuf.WebsocketPongMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.WebsocketPongMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.WebsocketPongMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.WebsocketPongMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketPongMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.WebsocketNotificationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.WebsocketNotificationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.WebsocketNotificationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketNotificationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.WebsocketNotificationMessage}
 */
proto.protobuf.WebsocketNotificationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.WebsocketNotificationMessage;
  return proto.protobuf.WebsocketNotificationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.WebsocketNotificationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.WebsocketNotificationMessage}
 */
proto.protobuf.WebsocketNotificationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotificationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.WebsocketNotificationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.WebsocketNotificationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.WebsocketNotificationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.WebsocketNotificationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 notificationId = 1;
 * @return {number}
 */
proto.protobuf.WebsocketNotificationMessage.prototype.getNotificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.WebsocketNotificationMessage} returns this
 */
proto.protobuf.WebsocketNotificationMessage.prototype.setNotificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.protobuf.WebsocketNotificationMessage.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.WebsocketNotificationMessage} returns this
 */
proto.protobuf.WebsocketNotificationMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string entityId = 3;
 * @return {string}
 */
proto.protobuf.WebsocketNotificationMessage.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.WebsocketNotificationMessage} returns this
 */
proto.protobuf.WebsocketNotificationMessage.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneTypeEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneTypeEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneTypeEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneTypeEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetypeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    planecategory: (f = msg.getPlanecategory()) && proto.protobuf.PlaneCategoryEntity.toObject(includeInstance, f),
    maxpassengers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    productionyear: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.PlaneTypeEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneTypeEntity;
  return proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneTypeEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanetypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.protobuf.PlaneCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader);
      msg.setPlanecategory(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxpassengers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductionyear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneTypeEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneTypeEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetypeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlanecategory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getMaxpassengers();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProductionyear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 planeTypeId = 1;
 * @return {number}
 */
proto.protobuf.PlaneTypeEntity.prototype.getPlanetypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.setPlanetypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.PlaneTypeEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.protobuf.PlaneTypeEntity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PlaneCategoryEntity planeCategory = 4;
 * @return {?proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.PlaneTypeEntity.prototype.getPlanecategory = function() {
  return /** @type{?proto.protobuf.PlaneCategoryEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCategoryEntity, 4));
};


/**
 * @param {?proto.protobuf.PlaneCategoryEntity|undefined} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
*/
proto.protobuf.PlaneTypeEntity.prototype.setPlanecategory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.clearPlanecategory = function() {
  return this.setPlanecategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneTypeEntity.prototype.hasPlanecategory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 maxPassengers = 5;
 * @return {number}
 */
proto.protobuf.PlaneTypeEntity.prototype.getMaxpassengers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.setMaxpassengers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 productionYear = 6;
 * @return {number}
 */
proto.protobuf.PlaneTypeEntity.prototype.getProductionyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneTypeEntity} returns this
 */
proto.protobuf.PlaneTypeEntity.prototype.setProductionyear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneTypeShortEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneTypeShortEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneTypeShortEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneTypeShortEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetypeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneTypeShortEntity}
 */
proto.protobuf.PlaneTypeShortEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneTypeShortEntity;
  return proto.protobuf.PlaneTypeShortEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneTypeShortEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneTypeShortEntity}
 */
proto.protobuf.PlaneTypeShortEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanetypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneTypeShortEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneTypeShortEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneTypeShortEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneTypeShortEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetypeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 planeTypeId = 1;
 * @return {number}
 */
proto.protobuf.PlaneTypeShortEntity.prototype.getPlanetypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneTypeShortEntity} returns this
 */
proto.protobuf.PlaneTypeShortEntity.prototype.setPlanetypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.PlaneTypeShortEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneTypeShortEntity} returns this
 */
proto.protobuf.PlaneTypeShortEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneTypeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneTypeListRequest}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneTypeListRequest;
  return proto.protobuf.BookingApiPlaneTypeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneTypeListRequest}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneTypeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiPlaneTypeListRequest} returns this
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneTypeListRequest} returns this
*/
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneTypeListRequest} returns this
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneTypeListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiPlaneTypeListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneTypeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneTypeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetypesList: jspb.Message.toObjectList(msg.getPlanetypesList(),
    proto.protobuf.PlaneTypeEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneTypeListResponse}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneTypeListResponse;
  return proto.protobuf.BookingApiPlaneTypeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneTypeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneTypeListResponse}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader);
      msg.addPlanetypes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneTypeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneTypeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated PlaneTypeEntity planeTypes = 1;
 * @return {!Array<!proto.protobuf.PlaneTypeEntity>}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.getPlanetypesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneTypeEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneTypeEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneTypeEntity>} value
 * @return {!proto.protobuf.BookingApiPlaneTypeListResponse} returns this
*/
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.setPlanetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneTypeEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.addPlanetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneTypeEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiPlaneTypeListResponse} returns this
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.clearPlanetypesList = function() {
  return this.setPlanetypesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiPlaneTypeListResponse} returns this
 */
proto.protobuf.BookingApiPlaneTypeListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneTypeShortListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneTypeShortListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetypesList: jspb.Message.toObjectList(msg.getPlanetypesList(),
    proto.protobuf.PlaneTypeShortEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneTypeShortListResponse}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneTypeShortListResponse;
  return proto.protobuf.BookingApiPlaneTypeShortListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneTypeShortListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneTypeShortListResponse}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneTypeShortEntity;
      reader.readMessage(value,proto.protobuf.PlaneTypeShortEntity.deserializeBinaryFromReader);
      msg.addPlanetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneTypeShortListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneTypeShortListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneTypeShortEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlaneTypeShortEntity planeTypes = 1;
 * @return {!Array<!proto.protobuf.PlaneTypeShortEntity>}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.getPlanetypesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneTypeShortEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneTypeShortEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneTypeShortEntity>} value
 * @return {!proto.protobuf.BookingApiPlaneTypeShortListResponse} returns this
*/
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.setPlanetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneTypeShortEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneTypeShortEntity}
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.addPlanetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneTypeShortEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiPlaneTypeShortListResponse} returns this
 */
proto.protobuf.BookingApiPlaneTypeShortListResponse.prototype.clearPlanetypesList = function() {
  return this.setPlanetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetype: (f = msg.getPlanetype()) && proto.protobuf.PlaneTypeEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneTypeRequest}
 */
proto.protobuf.BookingApiPlaneTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneTypeRequest;
  return proto.protobuf.BookingApiPlaneTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneTypeRequest}
 */
proto.protobuf.BookingApiPlaneTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader);
      msg.setPlanetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneTypeEntity planeType = 1;
 * @return {?proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.BookingApiPlaneTypeRequest.prototype.getPlanetype = function() {
  return /** @type{?proto.protobuf.PlaneTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneTypeEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneTypeEntity|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneTypeRequest} returns this
*/
proto.protobuf.BookingApiPlaneTypeRequest.prototype.setPlanetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneTypeRequest} returns this
 */
proto.protobuf.BookingApiPlaneTypeRequest.prototype.clearPlanetype = function() {
  return this.setPlanetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneTypeRequest.prototype.hasPlanetype = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planetype: (f = msg.getPlanetype()) && proto.protobuf.PlaneTypeEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse}
 */
proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneTypeResponse;
  return proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse}
 */
proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader);
      msg.setPlanetype(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneTypeEntity planeType = 1;
 * @return {?proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.getPlanetype = function() {
  return /** @type{?proto.protobuf.PlaneTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneTypeEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneTypeEntity|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse} returns this
*/
proto.protobuf.BookingApiPlaneTypeResponse.prototype.setPlanetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse} returns this
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.clearPlanetype = function() {
  return this.setPlanetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.hasPlanetype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse} returns this
*/
proto.protobuf.BookingApiPlaneTypeResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneTypeResponse} returns this
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneTypeResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneListRequest}
 */
proto.protobuf.BookingApiPlaneListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneListRequest;
  return proto.protobuf.BookingApiPlaneListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneListRequest}
 */
proto.protobuf.BookingApiPlaneListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiPlaneListRequest} returns this
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneListRequest} returns this
*/
proto.protobuf.BookingApiPlaneListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneListRequest} returns this
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneEntity.repeatedFields_ = [14,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planetype: (f = msg.getPlanetype()) && proto.protobuf.PlaneTypeEntity.toObject(includeInstance, f),
    baseairport: (f = msg.getBaseairport()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    registrationnumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    manufacturedate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    renovationdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    maxpassengers: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxdistance: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isanimals: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    iscargo: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    issmoking: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isambulance: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.protobuf.FileEntity.toObject, includeInstance),
    insurance: (f = msg.getInsurance()) && proto.protobuf.FileEntity.toObject(includeInstance, f),
    certificate: (f = msg.getCertificate()) && proto.protobuf.FileEntity.toObject(includeInstance, f),
    planecalculatorsettingsList: jspb.Message.toObjectList(msg.getPlanecalculatorsettingsList(),
    proto.protobuf.PlaneCalculatorSettingEntity.toObject, includeInstance),
    flyhourprice: jspb.Message.getFieldWithDefault(msg, 18, 0),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneEntity}
 */
proto.protobuf.PlaneEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneEntity;
  return proto.protobuf.PlaneEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneEntity}
 */
proto.protobuf.PlaneEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaneid(value);
      break;
    case 2:
      var value = new proto.protobuf.PlaneTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneTypeEntity.deserializeBinaryFromReader);
      msg.setPlanetype(value);
      break;
    case 3:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setBaseairport(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationnumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturedate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenovationdate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxpassengers(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxdistance(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsanimals(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscargo(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssmoking(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsambulance(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 15:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.setInsurance(value);
      break;
    case 16:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.setCertificate(value);
      break;
    case 17:
      var value = new proto.protobuf.PlaneCalculatorSettingEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader);
      msg.addPlanecalculatorsettings(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyhourprice(value);
      break;
    case 19:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaneid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlanetype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PlaneTypeEntity.serializeBinaryToWriter
    );
  }
  f = message.getBaseairport();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getManufacturedate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRenovationdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMaxpassengers();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMaxdistance();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIsanimals();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIscargo();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIssmoking();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsambulance();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getInsurance();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getCertificate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlanecalculatorsettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter
    );
  }
  f = message.getFlyhourprice();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 planeId = 1;
 * @return {number}
 */
proto.protobuf.PlaneEntity.prototype.getPlaneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setPlaneid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlaneTypeEntity planeType = 2;
 * @return {?proto.protobuf.PlaneTypeEntity}
 */
proto.protobuf.PlaneEntity.prototype.getPlanetype = function() {
  return /** @type{?proto.protobuf.PlaneTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneTypeEntity, 2));
};


/**
 * @param {?proto.protobuf.PlaneTypeEntity|undefined} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setPlanetype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearPlanetype = function() {
  return this.setPlanetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.hasPlanetype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AirportEntity baseAirport = 3;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.PlaneEntity.prototype.getBaseairport = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 3));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setBaseairport = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearBaseairport = function() {
  return this.setBaseairport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.hasBaseairport = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string registrationNumber = 4;
 * @return {string}
 */
proto.protobuf.PlaneEntity.prototype.getRegistrationnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setRegistrationnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string manufactureDate = 5;
 * @return {string}
 */
proto.protobuf.PlaneEntity.prototype.getManufacturedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setManufacturedate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string renovationDate = 6;
 * @return {string}
 */
proto.protobuf.PlaneEntity.prototype.getRenovationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setRenovationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 maxPassengers = 7;
 * @return {number}
 */
proto.protobuf.PlaneEntity.prototype.getMaxpassengers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setMaxpassengers = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 maxDistance = 8;
 * @return {number}
 */
proto.protobuf.PlaneEntity.prototype.getMaxdistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setMaxdistance = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool isAnimals = 9;
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.getIsanimals = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setIsanimals = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool isCargo = 10;
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.getIscargo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setIscargo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool isSmoking = 11;
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.getIssmoking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setIssmoking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool isAmbulance = 12;
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.getIsambulance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setIsambulance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.protobuf.PlaneEntity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated FileEntity images = 14;
 * @return {!Array<!proto.protobuf.FileEntity>}
 */
proto.protobuf.PlaneEntity.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.protobuf.FileEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.FileEntity, 14));
};


/**
 * @param {!Array<!proto.protobuf.FileEntity>} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.protobuf.FileEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FileEntity}
 */
proto.protobuf.PlaneEntity.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.protobuf.FileEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional FileEntity insurance = 15;
 * @return {?proto.protobuf.FileEntity}
 */
proto.protobuf.PlaneEntity.prototype.getInsurance = function() {
  return /** @type{?proto.protobuf.FileEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileEntity, 15));
};


/**
 * @param {?proto.protobuf.FileEntity|undefined} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setInsurance = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearInsurance = function() {
  return this.setInsurance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.hasInsurance = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional FileEntity certificate = 16;
 * @return {?proto.protobuf.FileEntity}
 */
proto.protobuf.PlaneEntity.prototype.getCertificate = function() {
  return /** @type{?proto.protobuf.FileEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileEntity, 16));
};


/**
 * @param {?proto.protobuf.FileEntity|undefined} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setCertificate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearCertificate = function() {
  return this.setCertificate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.hasCertificate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated PlaneCalculatorSettingEntity planeCalculatorSettings = 17;
 * @return {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>}
 */
proto.protobuf.PlaneEntity.prototype.getPlanecalculatorsettingsList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCalculatorSettingEntity, 17));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setPlanecalculatorsettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.PlaneEntity.prototype.addPlanecalculatorsettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.protobuf.PlaneCalculatorSettingEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearPlanecalculatorsettingsList = function() {
  return this.setPlanecalculatorsettingsList([]);
};


/**
 * optional int32 flyHourPrice = 18;
 * @return {number}
 */
proto.protobuf.PlaneEntity.prototype.getFlyhourprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.setFlyhourprice = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional CurrencyEntity currency = 19;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.PlaneEntity.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 19));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.PlaneEntity} returns this
*/
proto.protobuf.PlaneEntity.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneEntity} returns this
 */
proto.protobuf.PlaneEntity.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneEntity.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneShortEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneShortEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneShortEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneShortEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registrationnumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneShortEntity}
 */
proto.protobuf.PlaneShortEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneShortEntity;
  return proto.protobuf.PlaneShortEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneShortEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneShortEntity}
 */
proto.protobuf.PlaneShortEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaneid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneShortEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneShortEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneShortEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneShortEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaneid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRegistrationnumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 planeId = 1;
 * @return {number}
 */
proto.protobuf.PlaneShortEntity.prototype.getPlaneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneShortEntity} returns this
 */
proto.protobuf.PlaneShortEntity.prototype.setPlaneid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string registrationNumber = 2;
 * @return {string}
 */
proto.protobuf.PlaneShortEntity.prototype.getRegistrationnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneShortEntity} returns this
 */
proto.protobuf.PlaneShortEntity.prototype.setRegistrationnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiPlaneListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planesList: jspb.Message.toObjectList(msg.getPlanesList(),
    proto.protobuf.PlaneEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneListResponse}
 */
proto.protobuf.BookingApiPlaneListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneListResponse;
  return proto.protobuf.BookingApiPlaneListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneListResponse}
 */
proto.protobuf.BookingApiPlaneListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.addPlanes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated PlaneEntity planes = 1;
 * @return {!Array<!proto.protobuf.PlaneEntity>}
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.getPlanesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneEntity>} value
 * @return {!proto.protobuf.BookingApiPlaneListResponse} returns this
*/
proto.protobuf.BookingApiPlaneListResponse.prototype.setPlanesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneEntity}
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.addPlanes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiPlaneListResponse} returns this
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.clearPlanesList = function() {
  return this.setPlanesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiPlaneListResponse} returns this
 */
proto.protobuf.BookingApiPlaneListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiPlaneShortListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneShortListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneShortListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneShortListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneShortListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planesList: jspb.Message.toObjectList(msg.getPlanesList(),
    proto.protobuf.PlaneShortEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneShortListResponse}
 */
proto.protobuf.BookingApiPlaneShortListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneShortListResponse;
  return proto.protobuf.BookingApiPlaneShortListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneShortListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneShortListResponse}
 */
proto.protobuf.BookingApiPlaneShortListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneShortEntity;
      reader.readMessage(value,proto.protobuf.PlaneShortEntity.deserializeBinaryFromReader);
      msg.addPlanes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneShortListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneShortListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneShortListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneShortListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneShortEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlaneShortEntity planes = 1;
 * @return {!Array<!proto.protobuf.PlaneShortEntity>}
 */
proto.protobuf.BookingApiPlaneShortListResponse.prototype.getPlanesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneShortEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneShortEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneShortEntity>} value
 * @return {!proto.protobuf.BookingApiPlaneShortListResponse} returns this
*/
proto.protobuf.BookingApiPlaneShortListResponse.prototype.setPlanesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneShortEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneShortEntity}
 */
proto.protobuf.BookingApiPlaneShortListResponse.prototype.addPlanes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneShortEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiPlaneShortListResponse} returns this
 */
proto.protobuf.BookingApiPlaneShortListResponse.prototype.clearPlanesList = function() {
  return this.setPlanesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneRequest}
 */
proto.protobuf.BookingApiPlaneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneRequest;
  return proto.protobuf.BookingApiPlaneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneRequest}
 */
proto.protobuf.BookingApiPlaneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneEntity plane = 1;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.BookingApiPlaneRequest.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneRequest} returns this
*/
proto.protobuf.BookingApiPlaneRequest.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneRequest} returns this
 */
proto.protobuf.BookingApiPlaneRequest.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneRequest.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneResponse}
 */
proto.protobuf.BookingApiPlaneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneResponse;
  return proto.protobuf.BookingApiPlaneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneResponse}
 */
proto.protobuf.BookingApiPlaneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneEntity plane = 1;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneResponse} returns this
*/
proto.protobuf.BookingApiPlaneResponse.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneResponse} returns this
 */
proto.protobuf.BookingApiPlaneResponse.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneResponse} returns this
*/
proto.protobuf.BookingApiPlaneResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneResponse} returns this
 */
proto.protobuf.BookingApiPlaneResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiEmptyLegListRequest.repeatedFields_ = [3,8,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    planetypeidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    datestart: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    departuredate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    planecategoryidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    isshowarchived: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isshared: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    maxpax: jspb.Message.getFieldWithDefault(msg, 12, 0),
    manufactureyearfrom: jspb.Message.getFieldWithDefault(msg, 13, 0),
    iscargo: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isanimals: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    issmoking: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    isambulance: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    emptylegidsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest}
 */
proto.protobuf.BookingApiEmptyLegListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegListRequest;
  return proto.protobuf.BookingApiEmptyLegListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest}
 */
proto.protobuf.BookingApiEmptyLegListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 2:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanetypeids(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPax(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturedate(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanecategoryids(values[i]);
      }
      break;
    case 9:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshowarchived(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshared(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxpax(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManufactureyearfrom(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscargo(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsanimals(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssmoking(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsambulance(value);
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEmptylegids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlanetypeidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlanecategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBool(
      16,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getEmptylegidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      18,
      f
    );
  }
};


/**
 * optional AirportEntity airportFrom = 1;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AirportEntity airportTo = 2;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 2));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int32 planeTypeIds = 3;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getPlanetypeidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setPlanetypeidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.addPlanetypeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearPlanetypeidsList = function() {
  return this.setPlanetypeidsList([]);
};


/**
 * optional string dateStart = 4;
 * @return {string}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setDatestart = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearDatestart = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasDatestart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string dateEnd = 5;
 * @return {string}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setDateend = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearDateend = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasDateend = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 pax = 6;
 * @return {number}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getPax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setPax = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearPax = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasPax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string departureDate = 7;
 * @return {string}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getDeparturedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setDeparturedate = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearDeparturedate = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasDeparturedate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated int32 planeCategoryIds = 8;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getPlanecategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setPlanecategoryidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.addPlanecategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearPlanecategoryidsList = function() {
  return this.setPlanecategoryidsList([]);
};


/**
 * optional PageModel pageModel = 9;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 9));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool isShowArchived = 10;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIsshowarchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIsshowarchived = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIsshowarchived = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIsshowarchived = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool isShared = 11;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIsshared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIsshared = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIsshared = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIsshared = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 maxPax = 12;
 * @return {number}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getMaxpax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setMaxpax = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearMaxpax = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasMaxpax = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 manufactureYearFrom = 13;
 * @return {number}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getManufactureyearfrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setManufactureyearfrom = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearManufactureyearfrom = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasManufactureyearfrom = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool isCargo = 14;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIscargo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIscargo = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIscargo = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIscargo = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool isAnimals = 15;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIsanimals = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIsanimals = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIsanimals = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIsanimals = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool isSmoking = 16;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIssmoking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIssmoking = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIssmoking = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIssmoking = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool isAmbulance = 17;
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getIsambulance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setIsambulance = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearIsambulance = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.hasIsambulance = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated int32 emptyLegIds = 18;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.getEmptylegidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.setEmptylegidsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.addEmptylegids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiEmptyLegListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegListRequest.prototype.clearEmptylegidsList = function() {
  return this.setEmptylegidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.EmptyLegEntity.repeatedFields_ = [12,15,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.EmptyLegEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.EmptyLegEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.EmptyLegEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    freighter: (f = msg.getFreighter()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    planeid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    availablefromdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    availabletodate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    emptylegstatusid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    currencyentity: (f = msg.getCurrencyentity()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 11, ""),
    orderroutesList: jspb.Message.toObjectList(msg.getOrderroutesList(),
    proto.protobuf.OrderRouteEntity.toObject, includeInstance),
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f),
    flycompany: (f = msg.getFlycompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    emptylegorderroutesList: jspb.Message.toObjectList(msg.getEmptylegorderroutesList(),
    proto.protobuf.EmptyLegOrderRouteEntity.toObject, includeInstance),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    isshared: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    flyduration: jspb.Message.getFieldWithDefault(msg, 18, 0),
    flydistance: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatedat: jspb.Message.getFieldWithDefault(msg, 20, ""),
    departuretime: jspb.Message.getFieldWithDefault(msg, 21, ""),
    flyroutesList: jspb.Message.toObjectList(msg.getFlyroutesList(),
    proto.protobuf.FlyRouteEntity.toObject, includeInstance),
    createdat: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.EmptyLegEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.EmptyLegEntity;
  return proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.EmptyLegEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegid(value);
      break;
    case 2:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setFreighter(value);
      break;
    case 3:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 4:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaneid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailablefromdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailabletodate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegstatusid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 10:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrencyentity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 12:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.addOrderroutes(value);
      break;
    case 13:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    case 14:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setFlycompany(value);
      break;
    case 15:
      var value = new proto.protobuf.EmptyLegOrderRouteEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader);
      msg.addEmptylegorderroutes(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshared(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyduration(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlydistance(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturetime(value);
      break;
    case 22:
      var value = new proto.protobuf.FlyRouteEntity;
      reader.readMessage(value,proto.protobuf.FlyRouteEntity.deserializeBinaryFromReader);
      msg.addFlyroutes(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.EmptyLegEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.EmptyLegEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.EmptyLegEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFreighter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlaneid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAvailablefromdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvailabletodate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmptylegstatusid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCurrencyentity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrderroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
  f = message.getFlycompany();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getEmptylegorderroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getIsshared();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getFlyduration();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getFlydistance();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDeparturetime();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFlyroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.protobuf.FlyRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional int32 emptyLegId = 1;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getEmptylegid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setEmptylegid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserEntity freighter = 2;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getFreighter = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 2));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setFreighter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearFreighter = function() {
  return this.setFreighter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasFreighter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AirportEntity airportFrom = 3;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 3));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AirportEntity airportTo = 4;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 4));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 planeId = 5;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getPlaneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setPlaneid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string availableFromDate = 6;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getAvailablefromdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setAvailablefromdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string availableToDate = 7;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getAvailabletodate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setAvailabletodate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 emptyLegStatusId = 8;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getEmptylegstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setEmptylegstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional float price = 9;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional CurrencyEntity currencyEntity = 10;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getCurrencyentity = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 10));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setCurrencyentity = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearCurrencyentity = function() {
  return this.setCurrencyentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasCurrencyentity = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string comment = 11;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated OrderRouteEntity orderRoutes = 12;
 * @return {!Array<!proto.protobuf.OrderRouteEntity>}
 */
proto.protobuf.EmptyLegEntity.prototype.getOrderroutesList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRouteEntity, 12));
};


/**
 * @param {!Array<!proto.protobuf.OrderRouteEntity>} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setOrderroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.protobuf.OrderRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.addOrderroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.protobuf.OrderRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearOrderroutesList = function() {
  return this.setOrderroutesList([]);
};


/**
 * optional PlaneEntity plane = 13;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 13));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional CompanyEntity flyCompany = 14;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.getFlycompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 14));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setFlycompany = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearFlycompany = function() {
  return this.setFlycompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.hasFlycompany = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated EmptyLegOrderRouteEntity emptyLegOrderRoutes = 15;
 * @return {!Array<!proto.protobuf.EmptyLegOrderRouteEntity>}
 */
proto.protobuf.EmptyLegEntity.prototype.getEmptylegorderroutesList = function() {
  return /** @type{!Array<!proto.protobuf.EmptyLegOrderRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.EmptyLegOrderRouteEntity, 15));
};


/**
 * @param {!Array<!proto.protobuf.EmptyLegOrderRouteEntity>} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setEmptylegorderroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.protobuf.EmptyLegOrderRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.addEmptylegorderroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.protobuf.EmptyLegOrderRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearEmptylegorderroutesList = function() {
  return this.setEmptylegorderroutesList([]);
};


/**
 * optional bool archived = 16;
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool isShared = 17;
 * @return {boolean}
 */
proto.protobuf.EmptyLegEntity.prototype.getIsshared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setIsshared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 flyDuration = 18;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getFlyduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setFlyduration = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 flyDistance = 19;
 * @return {number}
 */
proto.protobuf.EmptyLegEntity.prototype.getFlydistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setFlydistance = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string updatedAt = 20;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string departureTime = 21;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getDeparturetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setDeparturetime = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated FlyRouteEntity flyRoutes = 22;
 * @return {!Array<!proto.protobuf.FlyRouteEntity>}
 */
proto.protobuf.EmptyLegEntity.prototype.getFlyroutesList = function() {
  return /** @type{!Array<!proto.protobuf.FlyRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.FlyRouteEntity, 22));
};


/**
 * @param {!Array<!proto.protobuf.FlyRouteEntity>} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
*/
proto.protobuf.EmptyLegEntity.prototype.setFlyroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.protobuf.FlyRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FlyRouteEntity}
 */
proto.protobuf.EmptyLegEntity.prototype.addFlyroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.protobuf.FlyRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.clearFlyroutesList = function() {
  return this.setFlyroutesList([]);
};


/**
 * optional string createdAt = 23;
 * @return {string}
 */
proto.protobuf.EmptyLegEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegEntity} returns this
 */
proto.protobuf.EmptyLegEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiEmptyLegListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegsList: jspb.Message.toObjectList(msg.getEmptylegsList(),
    proto.protobuf.EmptyLegEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse}
 */
proto.protobuf.BookingApiEmptyLegListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegListResponse;
  return proto.protobuf.BookingApiEmptyLegListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse}
 */
proto.protobuf.BookingApiEmptyLegListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.addEmptylegs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 3:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EmptyLegEntity emptyLegs = 1;
 * @return {!Array<!proto.protobuf.EmptyLegEntity>}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.getEmptylegsList = function() {
  return /** @type{!Array<!proto.protobuf.EmptyLegEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.EmptyLegEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.EmptyLegEntity>} value
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegListResponse.prototype.setEmptylegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.EmptyLegEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.addEmptylegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.EmptyLegEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.clearEmptylegsList = function() {
  return this.setEmptylegsList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ResultResponse resultResponse = 3;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 3));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegListResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegListResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegListResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptyleg: (f = msg.getEmptyleg()) && proto.protobuf.EmptyLegEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegRequest}
 */
proto.protobuf.BookingApiEmptyLegRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegRequest;
  return proto.protobuf.BookingApiEmptyLegRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegRequest}
 */
proto.protobuf.BookingApiEmptyLegRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.setEmptyleg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptyleg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmptyLegEntity emptyLeg = 1;
 * @return {?proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.BookingApiEmptyLegRequest.prototype.getEmptyleg = function() {
  return /** @type{?proto.protobuf.EmptyLegEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegEntity, 1));
};


/**
 * @param {?proto.protobuf.EmptyLegEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegRequest.prototype.setEmptyleg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegRequest.prototype.clearEmptyleg = function() {
  return this.setEmptyleg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegRequest.prototype.hasEmptyleg = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptyleg: (f = msg.getEmptyleg()) && proto.protobuf.EmptyLegEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegResponse}
 */
proto.protobuf.BookingApiEmptyLegResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegResponse;
  return proto.protobuf.BookingApiEmptyLegResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegResponse}
 */
proto.protobuf.BookingApiEmptyLegResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.setEmptyleg(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptyleg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmptyLegEntity emptyLeg = 1;
 * @return {?proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.getEmptyleg = function() {
  return /** @type{?proto.protobuf.EmptyLegEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegEntity, 1));
};


/**
 * @param {?proto.protobuf.EmptyLegEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegResponse.prototype.setEmptyleg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.clearEmptyleg = function() {
  return this.setEmptyleg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.hasEmptyleg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderListRequest.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.BookingApiOrderListRequest.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.protobuf.BookingApiOrderListRequest.OrdertypeCase = {
  ORDERTYPE_NOT_SET: 0,
  SHOWEMPTYLEGS: 3,
  SHOWCUSTOMORDERS: 4
};

/**
 * @return {proto.protobuf.BookingApiOrderListRequest.OrdertypeCase}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getOrdertypeCase = function() {
  return /** @type {proto.protobuf.BookingApiOrderListRequest.OrdertypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.BookingApiOrderListRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    showemptylegs: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    showcustomorders: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isshowarchived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    typesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderListRequest}
 */
proto.protobuf.BookingApiOrderListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderListRequest;
  return proto.protobuf.BookingApiOrderListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderListRequest}
 */
proto.protobuf.BookingApiOrderListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowemptylegs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcustomorders(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshowarchived(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsshowarchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
*/
proto.protobuf.BookingApiOrderListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool showEmptyLegs = 3;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getShowemptylegs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.setShowemptylegs = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.protobuf.BookingApiOrderListRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.clearShowemptylegs = function() {
  return jspb.Message.setOneofField(this, 3, proto.protobuf.BookingApiOrderListRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.hasShowemptylegs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool showCustomOrders = 4;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getShowcustomorders = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.setShowcustomorders = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.protobuf.BookingApiOrderListRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.clearShowcustomorders = function() {
  return jspb.Message.setOneofField(this, 4, proto.protobuf.BookingApiOrderListRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.hasShowcustomorders = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool isShowArchived = 5;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getIsshowarchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.setIsshowarchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 types = 6;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiOrderListRequest.prototype.getTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderListRequest} returns this
 */
proto.protobuf.BookingApiOrderListRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.OrderEntity.repeatedFields_ = [4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    user: (f = msg.getUser()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    orderstatusid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderroutesList: jspb.Message.toObjectList(msg.getOrderroutesList(),
    proto.protobuf.OrderRouteEntity.toObject, includeInstance),
    company: (f = msg.getCompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    orderrequestsList: jspb.Message.toObjectList(msg.getOrderrequestsList(),
    proto.protobuf.OrderRequestEntity.toObject, includeInstance),
    planecategoriesList: jspb.Message.toObjectList(msg.getPlanecategoriesList(),
    proto.protobuf.PlaneCategoryEntity.toObject, includeInstance),
    companionsallowed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    comment: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderEntity}
 */
proto.protobuf.OrderEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderEntity;
  return proto.protobuf.OrderEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderEntity}
 */
proto.protobuf.OrderEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderstatusid(value);
      break;
    case 4:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.addOrderroutes(value);
      break;
    case 5:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 7:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.addOrderrequests(value);
      break;
    case 8:
      var value = new proto.protobuf.PlaneCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader);
      msg.addPlanecategories(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanionsallowed(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderstatusid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrderroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOrderrequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlanecategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompanionsallowed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 orderId = 1;
 * @return {number}
 */
proto.protobuf.OrderEntity.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserEntity user = 2;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.OrderEntity.prototype.getUser = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 2));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.OrderEntity} returns this
*/
proto.protobuf.OrderEntity.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderEntity.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 orderStatusId = 3;
 * @return {number}
 */
proto.protobuf.OrderEntity.prototype.getOrderstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setOrderstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated OrderRouteEntity orderRoutes = 4;
 * @return {!Array<!proto.protobuf.OrderRouteEntity>}
 */
proto.protobuf.OrderEntity.prototype.getOrderroutesList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRouteEntity, 4));
};


/**
 * @param {!Array<!proto.protobuf.OrderRouteEntity>} value
 * @return {!proto.protobuf.OrderEntity} returns this
*/
proto.protobuf.OrderEntity.prototype.setOrderroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.protobuf.OrderRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.OrderEntity.prototype.addOrderroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.protobuf.OrderRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.clearOrderroutesList = function() {
  return this.setOrderroutesList([]);
};


/**
 * optional CompanyEntity company = 5;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.OrderEntity.prototype.getCompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 5));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.OrderEntity} returns this
*/
proto.protobuf.OrderEntity.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderEntity.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool archived = 6;
 * @return {boolean}
 */
proto.protobuf.OrderEntity.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated OrderRequestEntity orderRequests = 7;
 * @return {!Array<!proto.protobuf.OrderRequestEntity>}
 */
proto.protobuf.OrderEntity.prototype.getOrderrequestsList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRequestEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRequestEntity, 7));
};


/**
 * @param {!Array<!proto.protobuf.OrderRequestEntity>} value
 * @return {!proto.protobuf.OrderEntity} returns this
*/
proto.protobuf.OrderEntity.prototype.setOrderrequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.protobuf.OrderRequestEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.OrderEntity.prototype.addOrderrequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.protobuf.OrderRequestEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.clearOrderrequestsList = function() {
  return this.setOrderrequestsList([]);
};


/**
 * repeated PlaneCategoryEntity planeCategories = 8;
 * @return {!Array<!proto.protobuf.PlaneCategoryEntity>}
 */
proto.protobuf.OrderEntity.prototype.getPlanecategoriesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCategoryEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCategoryEntity, 8));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCategoryEntity>} value
 * @return {!proto.protobuf.OrderEntity} returns this
*/
proto.protobuf.OrderEntity.prototype.setPlanecategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protobuf.PlaneCategoryEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.OrderEntity.prototype.addPlanecategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protobuf.PlaneCategoryEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.clearPlanecategoriesList = function() {
  return this.setPlanecategoriesList([]);
};


/**
 * optional bool companionsAllowed = 9;
 * @return {boolean}
 */
proto.protobuf.OrderEntity.prototype.getCompanionsallowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setCompanionsallowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string comment = 10;
 * @return {string}
 */
proto.protobuf.OrderEntity.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 type = 11;
 * @return {number}
 */
proto.protobuf.OrderEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string createdAt = 12;
 * @return {string}
 */
proto.protobuf.OrderEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderEntity} returns this
 */
proto.protobuf.OrderEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.protobuf.OrderEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderListResponse}
 */
proto.protobuf.BookingApiOrderListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderListResponse;
  return proto.protobuf.BookingApiOrderListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderListResponse}
 */
proto.protobuf.BookingApiOrderListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 3:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderEntity orders = 1;
 * @return {!Array<!proto.protobuf.OrderEntity>}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.protobuf.OrderEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.OrderEntity>} value
 * @return {!proto.protobuf.BookingApiOrderListResponse} returns this
*/
proto.protobuf.BookingApiOrderListResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.OrderEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderEntity}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.OrderEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderListResponse} returns this
 */
proto.protobuf.BookingApiOrderListResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderListResponse} returns this
 */
proto.protobuf.BookingApiOrderListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ResultResponse resultResponse = 3;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 3));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiOrderListResponse} returns this
*/
proto.protobuf.BookingApiOrderListResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderListResponse} returns this
 */
proto.protobuf.BookingApiOrderListResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderListResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.OrderRouteEntity.repeatedFields_ = [7,11,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderRouteEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderRouteEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderRouteEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRouteEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrouteid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    departuredate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    emptylegsList: jspb.Message.toObjectList(msg.getEmptylegsList(),
    proto.protobuf.EmptyLegEntity.toObject, includeInstance),
    order: (f = msg.getOrder()) && proto.protobuf.OrderEntity.toObject(includeInstance, f),
    company: (f = msg.getCompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    emptylegorderroutesList: jspb.Message.toObjectList(msg.getEmptylegorderroutesList(),
    proto.protobuf.EmptyLegOrderRouteEntity.toObject, includeInstance),
    createdat: jspb.Message.getFieldWithDefault(msg, 12, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    orderrouteorderrequestsList: jspb.Message.toObjectList(msg.getOrderrouteorderrequestsList(),
    proto.protobuf.OrderRouteOrderRequestEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.OrderRouteEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderRouteEntity;
  return proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderRouteEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrouteid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 3:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 4:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturedate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPax(value);
      break;
    case 7:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.addEmptylegs(value);
      break;
    case 8:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 9:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 11:
      var value = new proto.protobuf.EmptyLegOrderRouteEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader);
      msg.addEmptylegorderroutes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 14:
      var value = new proto.protobuf.OrderRouteOrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteOrderRequestEntity.deserializeBinaryFromReader);
      msg.addOrderrouteorderrequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderRouteEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderRouteEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderRouteEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRouteEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrouteid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getDeparturedate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPax();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEmptylegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getEmptylegorderroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getOrderrouteorderrequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.protobuf.OrderRouteOrderRequestEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orderRouteId = 1;
 * @return {number}
 */
proto.protobuf.OrderRouteEntity.prototype.getOrderrouteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setOrderrouteid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 orderId = 2;
 * @return {number}
 */
proto.protobuf.OrderRouteEntity.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AirportEntity airportFrom = 3;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 3));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteEntity.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AirportEntity airportTo = 4;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 4));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteEntity.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string departureDate = 5;
 * @return {string}
 */
proto.protobuf.OrderRouteEntity.prototype.getDeparturedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setDeparturedate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 pax = 6;
 * @return {number}
 */
proto.protobuf.OrderRouteEntity.prototype.getPax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setPax = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated EmptyLegEntity emptyLegs = 7;
 * @return {!Array<!proto.protobuf.EmptyLegEntity>}
 */
proto.protobuf.OrderRouteEntity.prototype.getEmptylegsList = function() {
  return /** @type{!Array<!proto.protobuf.EmptyLegEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.EmptyLegEntity, 7));
};


/**
 * @param {!Array<!proto.protobuf.EmptyLegEntity>} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setEmptylegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.protobuf.EmptyLegEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.addEmptylegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.protobuf.EmptyLegEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearEmptylegsList = function() {
  return this.setEmptylegsList([]);
};


/**
 * optional OrderEntity order = 8;
 * @return {?proto.protobuf.OrderEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.getOrder = function() {
  return /** @type{?proto.protobuf.OrderEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderEntity, 8));
};


/**
 * @param {?proto.protobuf.OrderEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteEntity.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CompanyEntity company = 9;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.getCompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 9));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteEntity.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 userId = 10;
 * @return {number}
 */
proto.protobuf.OrderRouteEntity.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated EmptyLegOrderRouteEntity emptyLegOrderRoutes = 11;
 * @return {!Array<!proto.protobuf.EmptyLegOrderRouteEntity>}
 */
proto.protobuf.OrderRouteEntity.prototype.getEmptylegorderroutesList = function() {
  return /** @type{!Array<!proto.protobuf.EmptyLegOrderRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.EmptyLegOrderRouteEntity, 11));
};


/**
 * @param {!Array<!proto.protobuf.EmptyLegOrderRouteEntity>} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setEmptylegorderroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.protobuf.EmptyLegOrderRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.addEmptylegorderroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.protobuf.EmptyLegOrderRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearEmptylegorderroutesList = function() {
  return this.setEmptylegorderroutesList([]);
};


/**
 * optional string createdAt = 12;
 * @return {string}
 */
proto.protobuf.OrderRouteEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool archived = 13;
 * @return {boolean}
 */
proto.protobuf.OrderRouteEntity.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * repeated OrderRouteOrderRequestEntity orderRouteOrderRequests = 14;
 * @return {!Array<!proto.protobuf.OrderRouteOrderRequestEntity>}
 */
proto.protobuf.OrderRouteEntity.prototype.getOrderrouteorderrequestsList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRouteOrderRequestEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRouteOrderRequestEntity, 14));
};


/**
 * @param {!Array<!proto.protobuf.OrderRouteOrderRequestEntity>} value
 * @return {!proto.protobuf.OrderRouteEntity} returns this
*/
proto.protobuf.OrderRouteEntity.prototype.setOrderrouteorderrequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.protobuf.OrderRouteOrderRequestEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity}
 */
proto.protobuf.OrderRouteEntity.prototype.addOrderrouteorderrequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.protobuf.OrderRouteOrderRequestEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderRouteEntity} returns this
 */
proto.protobuf.OrderRouteEntity.prototype.clearOrderrouteorderrequestsList = function() {
  return this.setOrderrouteorderrequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.OrderRouteOrderRequestEntity.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderRouteOrderRequestEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderRouteOrderRequestEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRouteOrderRequestEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrouteorderrequestid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderroute: (f = msg.getOrderroute()) && proto.protobuf.OrderRouteEntity.toObject(includeInstance, f),
    orderrequest: (f = msg.getOrderrequest()) && proto.protobuf.OrderRequestEntity.toObject(includeInstance, f),
    flyduration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flydistance: jspb.Message.getFieldWithDefault(msg, 5, 0),
    technicalstopduration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    technicalstopcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    flyroutesList: jspb.Message.toObjectList(msg.getFlyroutesList(),
    proto.protobuf.FlyRouteEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity}
 */
proto.protobuf.OrderRouteOrderRequestEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderRouteOrderRequestEntity;
  return proto.protobuf.OrderRouteOrderRequestEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderRouteOrderRequestEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity}
 */
proto.protobuf.OrderRouteOrderRequestEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrouteorderrequestid(value);
      break;
    case 2:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.setOrderroute(value);
      break;
    case 3:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.setOrderrequest(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyduration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlydistance(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTechnicalstopduration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTechnicalstopcount(value);
      break;
    case 8:
      var value = new proto.protobuf.FlyRouteEntity;
      reader.readMessage(value,proto.protobuf.FlyRouteEntity.deserializeBinaryFromReader);
      msg.addFlyroutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderRouteOrderRequestEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderRouteOrderRequestEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRouteOrderRequestEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrouteorderrequestid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderroute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getFlyduration();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlydistance();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTechnicalstopduration();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTechnicalstopcount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFlyroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protobuf.FlyRouteEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orderRouteOrderRequestId = 1;
 * @return {number}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getOrderrouteorderrequestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setOrderrouteorderrequestid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrderRouteEntity orderRoute = 2;
 * @return {?proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getOrderroute = function() {
  return /** @type{?proto.protobuf.OrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRouteEntity, 2));
};


/**
 * @param {?proto.protobuf.OrderRouteEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
*/
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setOrderroute = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.clearOrderroute = function() {
  return this.setOrderroute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.hasOrderroute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrderRequestEntity orderRequest = 3;
 * @return {?proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getOrderrequest = function() {
  return /** @type{?proto.protobuf.OrderRequestEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRequestEntity, 3));
};


/**
 * @param {?proto.protobuf.OrderRequestEntity|undefined} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
*/
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setOrderrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.clearOrderrequest = function() {
  return this.setOrderrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.hasOrderrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 flyDuration = 4;
 * @return {number}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getFlyduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setFlyduration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flyDistance = 5;
 * @return {number}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getFlydistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setFlydistance = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 technicalStopDuration = 6;
 * @return {number}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getTechnicalstopduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setTechnicalstopduration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 technicalStopCount = 7;
 * @return {number}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getTechnicalstopcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setTechnicalstopcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated FlyRouteEntity flyRoutes = 8;
 * @return {!Array<!proto.protobuf.FlyRouteEntity>}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.getFlyroutesList = function() {
  return /** @type{!Array<!proto.protobuf.FlyRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.FlyRouteEntity, 8));
};


/**
 * @param {!Array<!proto.protobuf.FlyRouteEntity>} value
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
*/
proto.protobuf.OrderRouteOrderRequestEntity.prototype.setFlyroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protobuf.FlyRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FlyRouteEntity}
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.addFlyroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protobuf.FlyRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity} returns this
 */
proto.protobuf.OrderRouteOrderRequestEntity.prototype.clearFlyroutesList = function() {
  return this.setFlyroutesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.protobuf.OrderEntity.toObject(includeInstance, f),
    welcomemessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequest}
 */
proto.protobuf.BookingApiOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequest;
  return proto.protobuf.BookingApiOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequest}
 */
proto.protobuf.BookingApiOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWelcomemessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
  f = message.getWelcomemessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional OrderEntity order = 1;
 * @return {?proto.protobuf.OrderEntity}
 */
proto.protobuf.BookingApiOrderRequest.prototype.getOrder = function() {
  return /** @type{?proto.protobuf.OrderEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequest} returns this
*/
proto.protobuf.BookingApiOrderRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequest} returns this
 */
proto.protobuf.BookingApiOrderRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string welcomeMessage = 2;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRequest.prototype.getWelcomemessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRequest} returns this
 */
proto.protobuf.BookingApiOrderRequest.prototype.setWelcomemessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.protobuf.OrderEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderResponse}
 */
proto.protobuf.BookingApiOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderResponse;
  return proto.protobuf.BookingApiOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderResponse}
 */
proto.protobuf.BookingApiOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderEntity order = 1;
 * @return {?proto.protobuf.OrderEntity}
 */
proto.protobuf.BookingApiOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.protobuf.OrderEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderResponse} returns this
*/
proto.protobuf.BookingApiOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderResponse} returns this
 */
proto.protobuf.BookingApiOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiOrderResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiOrderResponse} returns this
*/
proto.protobuf.BookingApiOrderResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderResponse} returns this
 */
proto.protobuf.BookingApiOrderResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderStatusListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderStatusListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderStatusListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderStatusListRequest}
 */
proto.protobuf.BookingApiOrderStatusListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderStatusListRequest;
  return proto.protobuf.BookingApiOrderStatusListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderStatusListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderStatusListRequest}
 */
proto.protobuf.BookingApiOrderStatusListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderStatusListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderStatusListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderStatusListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderStatusListRequest} returns this
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiOrderStatusListRequest} returns this
*/
proto.protobuf.BookingApiOrderStatusListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderStatusListRequest} returns this
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderStatusListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderStatusEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderStatusEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderStatusEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderStatusEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderstatusid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderStatusEntity}
 */
proto.protobuf.OrderStatusEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderStatusEntity;
  return proto.protobuf.OrderStatusEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderStatusEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderStatusEntity}
 */
proto.protobuf.OrderStatusEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderstatusid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderStatusEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderStatusEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderStatusEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderStatusEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderstatusid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 orderStatusId = 1;
 * @return {number}
 */
proto.protobuf.OrderStatusEntity.prototype.getOrderstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderStatusEntity} returns this
 */
proto.protobuf.OrderStatusEntity.prototype.setOrderstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.OrderStatusEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderStatusEntity} returns this
 */
proto.protobuf.OrderStatusEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderStatusListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderStatusListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderStatusListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderStatusListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderstatusesList: jspb.Message.toObjectList(msg.getOrderstatusesList(),
    proto.protobuf.OrderStatusEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderStatusListResponse}
 */
proto.protobuf.BookingApiOrderStatusListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderStatusListResponse;
  return proto.protobuf.BookingApiOrderStatusListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderStatusListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderStatusListResponse}
 */
proto.protobuf.BookingApiOrderStatusListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderStatusEntity;
      reader.readMessage(value,proto.protobuf.OrderStatusEntity.deserializeBinaryFromReader);
      msg.addOrderstatuses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderStatusListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderStatusListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderStatusListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderstatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.OrderStatusEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated OrderStatusEntity orderStatuses = 1;
 * @return {!Array<!proto.protobuf.OrderStatusEntity>}
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.getOrderstatusesList = function() {
  return /** @type{!Array<!proto.protobuf.OrderStatusEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderStatusEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.OrderStatusEntity>} value
 * @return {!proto.protobuf.BookingApiOrderStatusListResponse} returns this
*/
proto.protobuf.BookingApiOrderStatusListResponse.prototype.setOrderstatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.OrderStatusEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderStatusEntity}
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.addOrderstatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.OrderStatusEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderStatusListResponse} returns this
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.clearOrderstatusesList = function() {
  return this.setOrderstatusesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderStatusListResponse} returns this
 */
proto.protobuf.BookingApiOrderStatusListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegStatusListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListRequest}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegStatusListRequest;
  return proto.protobuf.BookingApiEmptyLegStatusListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListRequest}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegStatusListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegStatusListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.EmptyLegStatusEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.EmptyLegStatusEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.EmptyLegStatusEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegStatusEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegstatusid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.EmptyLegStatusEntity}
 */
proto.protobuf.EmptyLegStatusEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.EmptyLegStatusEntity;
  return proto.protobuf.EmptyLegStatusEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.EmptyLegStatusEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.EmptyLegStatusEntity}
 */
proto.protobuf.EmptyLegStatusEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegstatusid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.EmptyLegStatusEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.EmptyLegStatusEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.EmptyLegStatusEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegStatusEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegstatusid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 emptyLegStatusId = 1;
 * @return {number}
 */
proto.protobuf.EmptyLegStatusEntity.prototype.getEmptylegstatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegStatusEntity} returns this
 */
proto.protobuf.EmptyLegStatusEntity.prototype.setEmptylegstatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.EmptyLegStatusEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegStatusEntity} returns this
 */
proto.protobuf.EmptyLegStatusEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegStatusListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegstatusesList: jspb.Message.toObjectList(msg.getEmptylegstatusesList(),
    proto.protobuf.EmptyLegStatusEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListResponse}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegStatusListResponse;
  return proto.protobuf.BookingApiEmptyLegStatusListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListResponse}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegStatusEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegStatusEntity.deserializeBinaryFromReader);
      msg.addEmptylegstatuses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegStatusListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegstatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.EmptyLegStatusEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated EmptyLegStatusEntity emptyLegStatuses = 1;
 * @return {!Array<!proto.protobuf.EmptyLegStatusEntity>}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.getEmptylegstatusesList = function() {
  return /** @type{!Array<!proto.protobuf.EmptyLegStatusEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.EmptyLegStatusEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.EmptyLegStatusEntity>} value
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.setEmptylegstatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.EmptyLegStatusEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.EmptyLegStatusEntity}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.addEmptylegstatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.EmptyLegStatusEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.clearEmptylegstatusesList = function() {
  return this.setEmptylegstatusesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiEmptyLegStatusListResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegStatusListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderRequestListRequest.repeatedFields_ = [10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    isshowarchived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    airportfromid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    airporttoid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minpax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxpax: jspb.Message.getFieldWithDefault(msg, 7, 0),
    companionsallowed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isemptylegusable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    planecategoryidsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    orderrequestidsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    ordertypesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest}
 */
proto.protobuf.BookingApiOrderRequestListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestListRequest;
  return proto.protobuf.BookingApiOrderRequestListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest}
 */
proto.protobuf.BookingApiOrderRequestListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshowarchived(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirportfromid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirporttoid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinpax(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxpax(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanionsallowed(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemptylegusable(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanecategoryids(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderrequestids(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrdertypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPlanecategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      10,
      f
    );
  }
  f = message.getOrderrequestidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      11,
      f
    );
  }
  f = message.getOrdertypesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
*/
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isShowArchived = 3;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getIsshowarchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setIsshowarchived = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearIsshowarchived = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasIsshowarchived = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 airportFromId = 4;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getAirportfromid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setAirportfromid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearAirportfromid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasAirportfromid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 airportToId = 5;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getAirporttoid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setAirporttoid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearAirporttoid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasAirporttoid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 minPax = 6;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getMinpax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setMinpax = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearMinpax = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasMinpax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 maxPax = 7;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getMaxpax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setMaxpax = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearMaxpax = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasMaxpax = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool companionsAllowed = 8;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getCompanionsallowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setCompanionsallowed = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearCompanionsallowed = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasCompanionsallowed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool isEmptyLegUsable = 9;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getIsemptylegusable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setIsemptylegusable = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearIsemptylegusable = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.hasIsemptylegusable = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated int32 planeCategoryIds = 10;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getPlanecategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setPlanecategoryidsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.addPlanecategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearPlanecategoryidsList = function() {
  return this.setPlanecategoryidsList([]);
};


/**
 * repeated int32 orderRequestIds = 11;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getOrderrequestidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setOrderrequestidsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.addOrderrequestids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearOrderrequestidsList = function() {
  return this.setOrderrequestidsList([]);
};


/**
 * repeated int32 orderTypes = 12;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.getOrdertypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.setOrdertypesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.addOrdertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRequestListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestListRequest.prototype.clearOrdertypesList = function() {
  return this.setOrdertypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.OrderRequestEntity.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderRequestEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderRequestEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderRequestEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRequestEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequestid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    freighter: (f = msg.getFreighter()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    orderrequeststatusid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flycompany: (f = msg.getFlycompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    createdat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f),
    price: jspb.Message.getFieldWithDefault(msg, 9, 0),
    order: (f = msg.getOrder()) && proto.protobuf.OrderEntity.toObject(includeInstance, f),
    orderrouteorderrequestsList: jspb.Message.toObjectList(msg.getOrderrouteorderrequestsList(),
    proto.protobuf.OrderRouteOrderRequestEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.OrderRequestEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderRequestEntity;
  return proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderRequestEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrequestid(value);
      break;
    case 2:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setFreighter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrequeststatusid(value);
      break;
    case 4:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setFlycompany(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 7:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    case 8:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 10:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 11:
      var value = new proto.protobuf.OrderRouteOrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteOrderRequestEntity.deserializeBinaryFromReader);
      msg.addOrderrouteorderrequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderRequestEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderRequestEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderRequestEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRequestEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequestid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFreighter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderrequeststatusid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFlycompany();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderrouteorderrequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.protobuf.OrderRouteOrderRequestEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orderRequestId = 1;
 * @return {number}
 */
proto.protobuf.OrderRequestEntity.prototype.getOrderrequestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.setOrderrequestid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserEntity freighter = 2;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.getFreighter = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 2));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setFreighter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearFreighter = function() {
  return this.setFreighter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.hasFreighter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 orderRequestStatusId = 3;
 * @return {number}
 */
proto.protobuf.OrderRequestEntity.prototype.getOrderrequeststatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.setOrderrequeststatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional CompanyEntity flyCompany = 4;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.getFlycompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 4));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setFlycompany = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearFlycompany = function() {
  return this.setFlycompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.hasFlycompany = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool archived = 5;
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string createdAt = 6;
 * @return {string}
 */
proto.protobuf.OrderRequestEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PlaneEntity plane = 7;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 7));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CurrencyEntity currency = 8;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 8));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 price = 9;
 * @return {number}
 */
proto.protobuf.OrderRequestEntity.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional OrderEntity order = 10;
 * @return {?proto.protobuf.OrderEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.getOrder = function() {
  return /** @type{?proto.protobuf.OrderEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderEntity, 10));
};


/**
 * @param {?proto.protobuf.OrderEntity|undefined} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.OrderRequestEntity.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated OrderRouteOrderRequestEntity orderRouteOrderRequests = 11;
 * @return {!Array<!proto.protobuf.OrderRouteOrderRequestEntity>}
 */
proto.protobuf.OrderRequestEntity.prototype.getOrderrouteorderrequestsList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRouteOrderRequestEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRouteOrderRequestEntity, 11));
};


/**
 * @param {!Array<!proto.protobuf.OrderRouteOrderRequestEntity>} value
 * @return {!proto.protobuf.OrderRequestEntity} returns this
*/
proto.protobuf.OrderRequestEntity.prototype.setOrderrouteorderrequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.protobuf.OrderRouteOrderRequestEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRouteOrderRequestEntity}
 */
proto.protobuf.OrderRequestEntity.prototype.addOrderrouteorderrequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.protobuf.OrderRouteOrderRequestEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.OrderRequestEntity} returns this
 */
proto.protobuf.OrderRequestEntity.prototype.clearOrderrouteorderrequestsList = function() {
  return this.setOrderrouteorderrequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderRequestListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequestsList: jspb.Message.toObjectList(msg.getOrderrequestsList(),
    proto.protobuf.OrderRequestEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestListResponse}
 */
proto.protobuf.BookingApiOrderRequestListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestListResponse;
  return proto.protobuf.BookingApiOrderRequestListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestListResponse}
 */
proto.protobuf.BookingApiOrderRequestListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.addOrderrequests(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated OrderRequestEntity orderRequests = 1;
 * @return {!Array<!proto.protobuf.OrderRequestEntity>}
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.getOrderrequestsList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRequestEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRequestEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.OrderRequestEntity>} value
 * @return {!proto.protobuf.BookingApiOrderRequestListResponse} returns this
*/
proto.protobuf.BookingApiOrderRequestListResponse.prototype.setOrderrequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.OrderRequestEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.addOrderrequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.OrderRequestEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRequestListResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.clearOrderrequestsList = function() {
  return this.setOrderrequestsList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestListResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequest: (f = msg.getOrderrequest()) && proto.protobuf.OrderRequestEntity.toObject(includeInstance, f),
    welcomemessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestRequest}
 */
proto.protobuf.BookingApiOrderRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestRequest;
  return proto.protobuf.BookingApiOrderRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestRequest}
 */
proto.protobuf.BookingApiOrderRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.setOrderrequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWelcomemessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getWelcomemessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional OrderRequestEntity orderRequest = 1;
 * @return {?proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.getOrderrequest = function() {
  return /** @type{?proto.protobuf.OrderRequestEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRequestEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderRequestEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequestRequest} returns this
*/
proto.protobuf.BookingApiOrderRequestRequest.prototype.setOrderrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.clearOrderrequest = function() {
  return this.setOrderrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.hasOrderrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string welcomeMessage = 2;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.getWelcomemessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRequestRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestRequest.prototype.setWelcomemessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequest: (f = msg.getOrderrequest()) && proto.protobuf.OrderRequestEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestResponse}
 */
proto.protobuf.BookingApiOrderRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestResponse;
  return proto.protobuf.BookingApiOrderRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestResponse}
 */
proto.protobuf.BookingApiOrderRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.setOrderrequest(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderRequestEntity orderRequest = 1;
 * @return {?proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.getOrderrequest = function() {
  return /** @type{?proto.protobuf.OrderRequestEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRequestEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderRequestEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequestResponse} returns this
*/
proto.protobuf.BookingApiOrderRequestResponse.prototype.setOrderrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.clearOrderrequest = function() {
  return this.setOrderrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.hasOrderrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequestResponse} returns this
*/
proto.protobuf.BookingApiOrderRequestResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestStatusListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListRequest}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestStatusListRequest;
  return proto.protobuf.BookingApiOrderRequestStatusListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListRequest}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestStatusListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListRequest} returns this
*/
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListRequest} returns this
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRequestStatusListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.OrderRequestStatusEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.OrderRequestStatusEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.OrderRequestStatusEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRequestStatusEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequeststatusid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.OrderRequestStatusEntity}
 */
proto.protobuf.OrderRequestStatusEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.OrderRequestStatusEntity;
  return proto.protobuf.OrderRequestStatusEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.OrderRequestStatusEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.OrderRequestStatusEntity}
 */
proto.protobuf.OrderRequestStatusEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrequeststatusid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.OrderRequestStatusEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.OrderRequestStatusEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.OrderRequestStatusEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.OrderRequestStatusEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequeststatusid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 orderRequestStatusId = 1;
 * @return {number}
 */
proto.protobuf.OrderRequestStatusEntity.prototype.getOrderrequeststatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.OrderRequestStatusEntity} returns this
 */
proto.protobuf.OrderRequestStatusEntity.prototype.setOrderrequeststatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.OrderRequestStatusEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.OrderRequestStatusEntity} returns this
 */
proto.protobuf.OrderRequestStatusEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRequestStatusListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderrequeststatusesList: jspb.Message.toObjectList(msg.getOrderrequeststatusesList(),
    proto.protobuf.OrderRequestStatusEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListResponse}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRequestStatusListResponse;
  return proto.protobuf.BookingApiOrderRequestStatusListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListResponse}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRequestStatusEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestStatusEntity.deserializeBinaryFromReader);
      msg.addOrderrequeststatuses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRequestStatusListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderrequeststatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.OrderRequestStatusEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated OrderRequestStatusEntity orderRequestStatuses = 1;
 * @return {!Array<!proto.protobuf.OrderRequestStatusEntity>}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.getOrderrequeststatusesList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRequestStatusEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRequestStatusEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.OrderRequestStatusEntity>} value
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListResponse} returns this
*/
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.setOrderrequeststatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.OrderRequestStatusEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRequestStatusEntity}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.addOrderrequeststatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.OrderRequestStatusEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.clearOrderrequeststatusesList = function() {
  return this.setOrderrequeststatusesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRequestStatusListResponse} returns this
 */
proto.protobuf.BookingApiOrderRequestStatusListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AirportShortEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AirportShortEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AirportShortEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportShortEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    iatacode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AirportShortEntity}
 */
proto.protobuf.AirportShortEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AirportShortEntity;
  return proto.protobuf.AirportShortEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AirportShortEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AirportShortEntity}
 */
proto.protobuf.AirportShortEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIatacode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AirportShortEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AirportShortEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AirportShortEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportShortEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIatacode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 airportId = 1;
 * @return {number}
 */
proto.protobuf.AirportShortEntity.prototype.getAirportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.AirportShortEntity} returns this
 */
proto.protobuf.AirportShortEntity.prototype.setAirportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string iataCode = 2;
 * @return {string}
 */
proto.protobuf.AirportShortEntity.prototype.getIatacode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportShortEntity} returns this
 */
proto.protobuf.AirportShortEntity.prototype.setIatacode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiAirportShortListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiAirportShortListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportShortListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiAirportShortListRequest}
 */
proto.protobuf.BookingApiAirportShortListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiAirportShortListRequest;
  return proto.protobuf.BookingApiAirportShortListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiAirportShortListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiAirportShortListRequest}
 */
proto.protobuf.BookingApiAirportShortListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiAirportShortListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiAirportShortListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportShortListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiAirportShortListRequest} returns this
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiAirportShortListRequest} returns this
*/
proto.protobuf.BookingApiAirportShortListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiAirportShortListRequest} returns this
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiAirportShortListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiAirportShortListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiAirportShortListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiAirportShortListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportShortListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto.protobuf.AirportShortEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiAirportShortListResponse}
 */
proto.protobuf.BookingApiAirportShortListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiAirportShortListResponse;
  return proto.protobuf.BookingApiAirportShortListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiAirportShortListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiAirportShortListResponse}
 */
proto.protobuf.BookingApiAirportShortListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportShortEntity;
      reader.readMessage(value,proto.protobuf.AirportShortEntity.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiAirportShortListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiAirportShortListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportShortListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.AirportShortEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated AirportShortEntity airports = 1;
 * @return {!Array<!proto.protobuf.AirportShortEntity>}
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.protobuf.AirportShortEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.AirportShortEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.AirportShortEntity>} value
 * @return {!proto.protobuf.BookingApiAirportShortListResponse} returns this
*/
proto.protobuf.BookingApiAirportShortListResponse.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.AirportShortEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.AirportShortEntity}
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.AirportShortEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiAirportShortListResponse} returns this
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiAirportShortListResponse} returns this
 */
proto.protobuf.BookingApiAirportShortListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AirportEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AirportEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AirportEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icaocode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iatacode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    countryname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    latitude: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timezone: (f = msg.getTimezone()) && proto.protobuf.TimezoneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AirportEntity}
 */
proto.protobuf.AirportEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AirportEntity;
  return proto.protobuf.AirportEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AirportEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AirportEntity}
 */
proto.protobuf.AirportEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirportid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcaocode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIatacode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 8:
      var value = new proto.protobuf.TimezoneEntity;
      reader.readMessage(value,proto.protobuf.TimezoneEntity.deserializeBinaryFromReader);
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AirportEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AirportEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AirportEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIcaocode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIatacode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountryname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimezone();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.TimezoneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 airportId = 1;
 * @return {number}
 */
proto.protobuf.AirportEntity.prototype.getAirportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setAirportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string icaoCode = 2;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getIcaocode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setIcaocode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iataCode = 3;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getIatacode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setIatacode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string countryName = 5;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getCountryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setCountryname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string latitude = 6;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string longitude = 7;
 * @return {string}
 */
proto.protobuf.AirportEntity.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TimezoneEntity timezone = 8;
 * @return {?proto.protobuf.TimezoneEntity}
 */
proto.protobuf.AirportEntity.prototype.getTimezone = function() {
  return /** @type{?proto.protobuf.TimezoneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.TimezoneEntity, 8));
};


/**
 * @param {?proto.protobuf.TimezoneEntity|undefined} value
 * @return {!proto.protobuf.AirportEntity} returns this
*/
proto.protobuf.AirportEntity.prototype.setTimezone = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AirportEntity} returns this
 */
proto.protobuf.AirportEntity.prototype.clearTimezone = function() {
  return this.setTimezone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AirportEntity.prototype.hasTimezone = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiGetAirportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiGetAirportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiGetAirportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiGetAirportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiGetAirportRequest}
 */
proto.protobuf.BookingApiGetAirportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiGetAirportRequest;
  return proto.protobuf.BookingApiGetAirportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiGetAirportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiGetAirportRequest}
 */
proto.protobuf.BookingApiGetAirportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirportid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiGetAirportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiGetAirportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiGetAirportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiGetAirportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 airportId = 1;
 * @return {number}
 */
proto.protobuf.BookingApiGetAirportRequest.prototype.getAirportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiGetAirportRequest} returns this
 */
proto.protobuf.BookingApiGetAirportRequest.prototype.setAirportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiGetAirportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiGetAirportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiGetAirportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    airport: (f = msg.getAirport()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiGetAirportResponse}
 */
proto.protobuf.BookingApiGetAirportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiGetAirportResponse;
  return proto.protobuf.BookingApiGetAirportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiGetAirportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiGetAirportResponse}
 */
proto.protobuf.BookingApiGetAirportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirport(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiGetAirportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiGetAirportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiGetAirportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AirportEntity airport = 1;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.getAirport = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.BookingApiGetAirportResponse} returns this
*/
proto.protobuf.BookingApiGetAirportResponse.prototype.setAirport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiGetAirportResponse} returns this
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.clearAirport = function() {
  return this.setAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.hasAirport = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiGetAirportResponse} returns this
*/
proto.protobuf.BookingApiGetAirportResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiGetAirportResponse} returns this
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiGetAirportResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiAirportListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiAirportListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiAirportListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiAirportListRequest}
 */
proto.protobuf.BookingApiAirportListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiAirportListRequest;
  return proto.protobuf.BookingApiAirportListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiAirportListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiAirportListRequest}
 */
proto.protobuf.BookingApiAirportListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiAirportListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiAirportListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiAirportListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiAirportListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiAirportListRequest} returns this
 */
proto.protobuf.BookingApiAirportListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiAirportListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiAirportListRequest} returns this
*/
proto.protobuf.BookingApiAirportListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiAirportListRequest} returns this
 */
proto.protobuf.BookingApiAirportListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiAirportListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiAirportListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiAirportListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiAirportListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiAirportListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto.protobuf.AirportEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiAirportListResponse}
 */
proto.protobuf.BookingApiAirportListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiAirportListResponse;
  return proto.protobuf.BookingApiAirportListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiAirportListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiAirportListResponse}
 */
proto.protobuf.BookingApiAirportListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiAirportListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiAirportListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiAirportListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiAirportListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated AirportEntity airports = 1;
 * @return {!Array<!proto.protobuf.AirportEntity>}
 */
proto.protobuf.BookingApiAirportListResponse.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.protobuf.AirportEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.AirportEntity>} value
 * @return {!proto.protobuf.BookingApiAirportListResponse} returns this
*/
proto.protobuf.BookingApiAirportListResponse.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.AirportEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiAirportListResponse.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.AirportEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiAirportListResponse} returns this
 */
proto.protobuf.BookingApiAirportListResponse.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiAirportListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiAirportListResponse} returns this
 */
proto.protobuf.BookingApiAirportListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneCategoryListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListRequest}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneCategoryListRequest;
  return proto.protobuf.BookingApiPlaneCategoryListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListRequest}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneCategoryListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiPlaneCategoryListRequest} returns this
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiPlaneCategoryListRequest} returns this
*/
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListRequest} returns this
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiPlaneCategoryListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCategoryEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCategoryEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCategoryEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCategoryEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecategoryid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.PlaneCategoryEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCategoryEntity;
  return proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCategoryEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanecategoryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCategoryEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCategoryEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecategoryid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 planeCategoryId = 1;
 * @return {number}
 */
proto.protobuf.PlaneCategoryEntity.prototype.getPlanecategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCategoryEntity} returns this
 */
proto.protobuf.PlaneCategoryEntity.prototype.setPlanecategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.PlaneCategoryEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneCategoryEntity} returns this
 */
proto.protobuf.PlaneCategoryEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiPlaneCategoryListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiPlaneCategoryListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecategoriesList: jspb.Message.toObjectList(msg.getPlanecategoriesList(),
    proto.protobuf.PlaneCategoryEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListResponse}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiPlaneCategoryListResponse;
  return proto.protobuf.BookingApiPlaneCategoryListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiPlaneCategoryListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListResponse}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader);
      msg.addPlanecategories(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiPlaneCategoryListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiPlaneCategoryListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated PlaneCategoryEntity planeCategories = 1;
 * @return {!Array<!proto.protobuf.PlaneCategoryEntity>}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.getPlanecategoriesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCategoryEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCategoryEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCategoryEntity>} value
 * @return {!proto.protobuf.BookingApiPlaneCategoryListResponse} returns this
*/
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.setPlanecategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneCategoryEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.addPlanecategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneCategoryEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiPlaneCategoryListResponse} returns this
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.clearPlanecategoriesList = function() {
  return this.setPlanecategoriesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiPlaneCategoryListResponse} returns this
 */
proto.protobuf.BookingApiPlaneCategoryListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderRouteListRequest.repeatedFields_ = [5,16];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_ = [[11,12,13]];

/**
 * @enum {number}
 */
proto.protobuf.BookingApiOrderRouteListRequest.OrderroutetypeCase = {
  ORDERROUTETYPE_NOT_SET: 0,
  EMPTYLEG: 11,
  CUSTOM: 12,
  ALL: 13
};

/**
 * @return {proto.protobuf.BookingApiOrderRouteListRequest.OrderroutetypeCase}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getOrderroutetypeCase = function() {
  return /** @type {proto.protobuf.BookingApiOrderRouteListRequest.OrderroutetypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRouteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRouteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    pax: jspb.Message.getFieldWithDefault(msg, 4, 0),
    planecategoriesList: jspb.Message.toObjectList(msg.getPlanecategoriesList(),
    proto.protobuf.PlaneCategoryEntity.toObject, includeInstance),
    departuredate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companionsallowed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isemptylegusable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    datefrom: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateto: jspb.Message.getFieldWithDefault(msg, 10, ""),
    emptyleg: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    all: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    showapproved: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    showarchived: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    orderrouteidsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest}
 */
proto.protobuf.BookingApiOrderRouteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRouteListRequest;
  return proto.protobuf.BookingApiOrderRouteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRouteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest}
 */
proto.protobuf.BookingApiOrderRouteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 2:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 3:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPax(value);
      break;
    case 5:
      var value = new proto.protobuf.PlaneCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCategoryEntity.deserializeBinaryFromReader);
      msg.addPlanecategories(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturedate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanionsallowed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemptylegusable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatefrom(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateto(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmptyleg(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAll(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowapproved(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowarchived(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderrouteids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRouteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRouteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getPax();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPlanecategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.protobuf.PlaneCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getDeparturedate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDatefrom();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getShowapproved();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getShowarchived();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getOrderrouteidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      16,
      f
    );
  }
};


/**
 * optional PageModel pageModel = 1;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 1));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
*/
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AirportEntity airportFrom = 2;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 2));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
*/
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AirportEntity airportTo = 3;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 3));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
*/
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 pax = 4;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getPax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setPax = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PlaneCategoryEntity planeCategories = 5;
 * @return {!Array<!proto.protobuf.PlaneCategoryEntity>}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getPlanecategoriesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCategoryEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCategoryEntity, 5));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCategoryEntity>} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
*/
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setPlanecategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.protobuf.PlaneCategoryEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCategoryEntity}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.addPlanecategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.protobuf.PlaneCategoryEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearPlanecategoriesList = function() {
  return this.setPlanecategoriesList([]);
};


/**
 * optional string departureDate = 6;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getDeparturedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setDeparturedate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool companionsAllowed = 7;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getCompanionsallowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setCompanionsallowed = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearCompanionsallowed = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasCompanionsallowed = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool isEmptyLegUsable = 8;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getIsemptylegusable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setIsemptylegusable = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearIsemptylegusable = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasIsemptylegusable = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string dateFrom = 9;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getDatefrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setDatefrom = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dateTo = 10;
 * @return {string}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getDateto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setDateto = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool emptyLeg = 11;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getEmptyleg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setEmptyleg = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearEmptyleg = function() {
  return jspb.Message.setOneofField(this, 11, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasEmptyleg = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool custom = 12;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setCustom = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearCustom = function() {
  return jspb.Message.setOneofField(this, 12, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasCustom = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool all = 13;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setAll = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearAll = function() {
  return jspb.Message.setOneofField(this, 13, proto.protobuf.BookingApiOrderRouteListRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.hasAll = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool showApproved = 14;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getShowapproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setShowapproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool showArchived = 15;
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getShowarchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setShowarchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated int32 orderRouteIds = 16;
 * @return {!Array<number>}
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.getOrderrouteidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.setOrderrouteidsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.addOrderrouteids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRouteListRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteListRequest.prototype.clearOrderrouteidsList = function() {
  return this.setOrderrouteidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.BookingApiOrderRouteListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRouteListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRouteListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderroutesList: jspb.Message.toObjectList(msg.getOrderroutesList(),
    proto.protobuf.OrderRouteEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRouteListResponse}
 */
proto.protobuf.BookingApiOrderRouteListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRouteListResponse;
  return proto.protobuf.BookingApiOrderRouteListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRouteListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRouteListResponse}
 */
proto.protobuf.BookingApiOrderRouteListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.addOrderroutes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRouteListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRouteListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated OrderRouteEntity orderRoutes = 1;
 * @return {!Array<!proto.protobuf.OrderRouteEntity>}
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.getOrderroutesList = function() {
  return /** @type{!Array<!proto.protobuf.OrderRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.OrderRouteEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.OrderRouteEntity>} value
 * @return {!proto.protobuf.BookingApiOrderRouteListResponse} returns this
*/
proto.protobuf.BookingApiOrderRouteListResponse.prototype.setOrderroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.OrderRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.addOrderroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.OrderRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.BookingApiOrderRouteListResponse} returns this
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.clearOrderroutesList = function() {
  return this.setOrderroutesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.BookingApiOrderRouteListResponse} returns this
 */
proto.protobuf.BookingApiOrderRouteListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.EmptyLegOrderRouteEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.EmptyLegOrderRouteEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegOrderRouteEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegorderrouteid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emptylegid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderrouteid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.EmptyLegOrderRouteEntity;
  return proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.EmptyLegOrderRouteEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegorderrouteid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrouteid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.EmptyLegOrderRouteEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegorderrouteid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmptylegid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrderrouteid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 emptyLegOrderRouteId = 1;
 * @return {number}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.getEmptylegorderrouteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity} returns this
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.setEmptylegorderrouteid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 emptyLegId = 2;
 * @return {number}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.getEmptylegid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity} returns this
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.setEmptylegid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 orderRouteId = 3;
 * @return {number}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.getOrderrouteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity} returns this
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.setOrderrouteid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 status = 4;
 * @return {number}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity} returns this
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string createdAt = 5;
 * @return {string}
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.EmptyLegOrderRouteEntity} returns this
 */
proto.protobuf.EmptyLegOrderRouteEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegOrderRouteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegorderrouteentity: (f = msg.getEmptylegorderrouteentity()) && proto.protobuf.EmptyLegOrderRouteEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegOrderRouteRequest;
  return proto.protobuf.BookingApiEmptyLegOrderRouteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegOrderRouteEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader);
      msg.setEmptylegorderrouteentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegOrderRouteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegorderrouteentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmptyLegOrderRouteEntity emptyLegOrderRouteEntity = 1;
 * @return {?proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.getEmptylegorderrouteentity = function() {
  return /** @type{?proto.protobuf.EmptyLegOrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegOrderRouteEntity, 1));
};


/**
 * @param {?proto.protobuf.EmptyLegOrderRouteEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} returns this
*/
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.setEmptylegorderrouteentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} returns this
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.clearEmptylegorderrouteentity = function() {
  return this.setEmptylegorderrouteentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteRequest.prototype.hasEmptylegorderrouteentity = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiEmptyLegOrderRouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emptylegorderrouteentity: (f = msg.getEmptylegorderrouteentity()) && proto.protobuf.EmptyLegOrderRouteEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiEmptyLegOrderRouteResponse;
  return proto.protobuf.BookingApiEmptyLegOrderRouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.EmptyLegOrderRouteEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader);
      msg.setEmptylegorderrouteentity(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiEmptyLegOrderRouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmptylegorderrouteentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmptyLegOrderRouteEntity emptyLegOrderRouteEntity = 1;
 * @return {?proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.getEmptylegorderrouteentity = function() {
  return /** @type{?proto.protobuf.EmptyLegOrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegOrderRouteEntity, 1));
};


/**
 * @param {?proto.protobuf.EmptyLegOrderRouteEntity|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.setEmptylegorderrouteentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.clearEmptylegorderrouteentity = function() {
  return this.setEmptylegorderrouteentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.hasEmptylegorderrouteentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} returns this
*/
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiEmptyLegOrderRouteResponse} returns this
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiEmptyLegOrderRouteResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRouteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRouteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRouteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderroute: (f = msg.getOrderroute()) && proto.protobuf.OrderRouteEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRouteRequest}
 */
proto.protobuf.BookingApiOrderRouteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRouteRequest;
  return proto.protobuf.BookingApiOrderRouteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRouteRequest}
 */
proto.protobuf.BookingApiOrderRouteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.setOrderroute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRouteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRouteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderroute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderRouteEntity orderRoute = 1;
 * @return {?proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.BookingApiOrderRouteRequest.prototype.getOrderroute = function() {
  return /** @type{?proto.protobuf.OrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRouteEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderRouteEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteRequest} returns this
*/
proto.protobuf.BookingApiOrderRouteRequest.prototype.setOrderroute = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteRequest} returns this
 */
proto.protobuf.BookingApiOrderRouteRequest.prototype.clearOrderroute = function() {
  return this.setOrderroute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteRequest.prototype.hasOrderroute = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.BookingApiOrderRouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.BookingApiOrderRouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderroute: (f = msg.getOrderroute()) && proto.protobuf.OrderRouteEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.BookingApiOrderRouteResponse}
 */
proto.protobuf.BookingApiOrderRouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.BookingApiOrderRouteResponse;
  return proto.protobuf.BookingApiOrderRouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.BookingApiOrderRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.BookingApiOrderRouteResponse}
 */
proto.protobuf.BookingApiOrderRouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.setOrderroute(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.BookingApiOrderRouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.BookingApiOrderRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.BookingApiOrderRouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderroute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderRouteEntity orderRoute = 1;
 * @return {?proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.getOrderroute = function() {
  return /** @type{?proto.protobuf.OrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRouteEntity, 1));
};


/**
 * @param {?proto.protobuf.OrderRouteEntity|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteResponse} returns this
*/
proto.protobuf.BookingApiOrderRouteResponse.prototype.setOrderroute = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteResponse} returns this
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.clearOrderroute = function() {
  return this.setOrderroute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.hasOrderroute = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.BookingApiOrderRouteResponse} returns this
*/
proto.protobuf.BookingApiOrderRouteResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.BookingApiOrderRouteResponse} returns this
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.BookingApiOrderRouteResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FlyRouteEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FlyRouteEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FlyRouteEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyRouteEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    flyrouteid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    latitude: jspb.Message.getFieldWithDefault(msg, 2, 0),
    longitude: jspb.Message.getFieldWithDefault(msg, 3, 0),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    label: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emptylegid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    orderrequestid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FlyRouteEntity}
 */
proto.protobuf.FlyRouteEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FlyRouteEntity;
  return proto.protobuf.FlyRouteEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FlyRouteEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FlyRouteEntity}
 */
proto.protobuf.FlyRouteEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyrouteid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptylegid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderrequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FlyRouteEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FlyRouteEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FlyRouteEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyRouteEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlyrouteid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmptylegid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrderrequestid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 flyRouteId = 1;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getFlyrouteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setFlyrouteid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 latitude = 2;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 longitude = 3;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string label = 5;
 * @return {string}
 */
proto.protobuf.FlyRouteEntity.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 emptyLegId = 6;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getEmptylegid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setEmptylegid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 orderRequestId = 7;
 * @return {number}
 */
proto.protobuf.FlyRouteEntity.prototype.getOrderrequestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyRouteEntity} returns this
 */
proto.protobuf.FlyRouteEntity.prototype.setOrderrequestid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FlyApiFlyRouteDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FlyApiFlyRouteDetailRequest;
  return proto.protobuf.FlyApiFlyRouteDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 2:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 3:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FlyApiFlyRouteDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional AirportEntity airportFrom = 1;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
*/
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AirportEntity airportTo = 2;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 2));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
*/
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PlaneEntity plane = 3;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 3));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
*/
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailRequest} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyRouteDetailRequest.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FlyApiFlyRouteDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FlyApiFlyRouteDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flyroutesList: jspb.Message.toObjectList(msg.getFlyroutesList(),
    proto.protobuf.FlyRouteEntity.toObject, includeInstance),
    flyduration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flydistance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    technicalstopduration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    technicalstopcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FlyApiFlyRouteDetailResponse;
  return proto.protobuf.FlyApiFlyRouteDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FlyApiFlyRouteDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.FlyRouteEntity;
      reader.readMessage(value,proto.protobuf.FlyRouteEntity.deserializeBinaryFromReader);
      msg.addFlyroutes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyduration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlydistance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTechnicalstopduration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTechnicalstopcount(value);
      break;
    case 6:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FlyApiFlyRouteDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FlyApiFlyRouteDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlyroutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.FlyRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getFlyduration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFlydistance();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTechnicalstopduration();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTechnicalstopcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlyRouteEntity flyRoutes = 1;
 * @return {!Array<!proto.protobuf.FlyRouteEntity>}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getFlyroutesList = function() {
  return /** @type{!Array<!proto.protobuf.FlyRouteEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.FlyRouteEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.FlyRouteEntity>} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
*/
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setFlyroutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.FlyRouteEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FlyRouteEntity}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.addFlyroutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.FlyRouteEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.clearFlyroutesList = function() {
  return this.setFlyroutesList([]);
};


/**
 * optional int32 flyDuration = 2;
 * @return {number}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getFlyduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setFlyduration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 flyDistance = 3;
 * @return {number}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getFlydistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setFlydistance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 technicalStopDuration = 4;
 * @return {number}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getTechnicalstopduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setTechnicalstopduration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 technicalStopCount = 5;
 * @return {number}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getTechnicalstopcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setTechnicalstopcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ResultResponse resultResponse = 6;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 6));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
*/
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyRouteDetailResponse} returns this
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyRouteDetailResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.FlyApiFlyCostRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FlyApiFlyCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FlyApiFlyCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planeentity: (f = msg.getPlaneentity()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f),
    flyduration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    planecalculatorsettingsList: jspb.Message.toObjectList(msg.getPlanecalculatorsettingsList(),
    proto.protobuf.PlaneCalculatorSettingEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FlyApiFlyCostRequest}
 */
proto.protobuf.FlyApiFlyCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FlyApiFlyCostRequest;
  return proto.protobuf.FlyApiFlyCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FlyApiFlyCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FlyApiFlyCostRequest}
 */
proto.protobuf.FlyApiFlyCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlaneentity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlyduration(value);
      break;
    case 3:
      var value = new proto.protobuf.PlaneCalculatorSettingEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader);
      msg.addPlanecalculatorsettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FlyApiFlyCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FlyApiFlyCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaneentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
  f = message.getFlyduration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlanecalculatorsettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneEntity planeEntity = 1;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.getPlaneentity = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.FlyApiFlyCostRequest} returns this
*/
proto.protobuf.FlyApiFlyCostRequest.prototype.setPlaneentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyCostRequest} returns this
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.clearPlaneentity = function() {
  return this.setPlaneentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.hasPlaneentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 flyDuration = 2;
 * @return {number}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.getFlyduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyCostRequest} returns this
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.setFlyduration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated PlaneCalculatorSettingEntity planeCalculatorSettings = 3;
 * @return {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.getPlanecalculatorsettingsList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCalculatorSettingEntity, 3));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} value
 * @return {!proto.protobuf.FlyApiFlyCostRequest} returns this
*/
proto.protobuf.FlyApiFlyCostRequest.prototype.setPlanecalculatorsettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.addPlanecalculatorsettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.protobuf.PlaneCalculatorSettingEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.FlyApiFlyCostRequest} returns this
 */
proto.protobuf.FlyApiFlyCostRequest.prototype.clearPlanecalculatorsettingsList = function() {
  return this.setPlanecalculatorsettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FlyApiFlyCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FlyApiFlyCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: (f = msg.getCurrency()) && proto.protobuf.CurrencyEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FlyApiFlyCostResponse}
 */
proto.protobuf.FlyApiFlyCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FlyApiFlyCostResponse;
  return proto.protobuf.FlyApiFlyCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FlyApiFlyCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FlyApiFlyCostResponse}
 */
proto.protobuf.FlyApiFlyCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCost(value);
      break;
    case 2:
      var value = new proto.protobuf.CurrencyEntity;
      reader.readMessage(value,proto.protobuf.CurrencyEntity.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 3:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FlyApiFlyCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FlyApiFlyCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FlyApiFlyCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.CurrencyEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 cost = 1;
 * @return {number}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FlyApiFlyCostResponse} returns this
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CurrencyEntity currency = 2;
 * @return {?proto.protobuf.CurrencyEntity}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.getCurrency = function() {
  return /** @type{?proto.protobuf.CurrencyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CurrencyEntity, 2));
};


/**
 * @param {?proto.protobuf.CurrencyEntity|undefined} value
 * @return {!proto.protobuf.FlyApiFlyCostResponse} returns this
*/
proto.protobuf.FlyApiFlyCostResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyCostResponse} returns this
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ResultResponse resultResponse = 3;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 3));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.FlyApiFlyCostResponse} returns this
*/
proto.protobuf.FlyApiFlyCostResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FlyApiFlyCostResponse} returns this
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FlyApiFlyCostResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorSettingCategoryEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingcategoryid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorSettingCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorSettingCategoryEntity;
  return proto.protobuf.PlaneCalculatorSettingCategoryEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorSettingCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanecalculatorsettingcategoryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorSettingCategoryEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingcategoryid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 planeCalculatorSettingCategoryId = 1;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.getPlanecalculatorsettingcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.setPlanecalculatorsettingcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isDefault = 3;
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneCalculatorSettingCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingCategoryEntity.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingsubcategoryid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planecalculatorsettingcategory: (f = msg.getPlanecalculatorsettingcategory()) && proto.protobuf.PlaneCalculatorSettingCategoryEntity.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorSettingSubCategoryEntity;
  return proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanecalculatorsettingsubcategoryid(value);
      break;
    case 2:
      var value = new proto.protobuf.PlaneCalculatorSettingCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingCategoryEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingcategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingsubcategoryid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlanecalculatorsettingcategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PlaneCalculatorSettingCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 planeCalculatorSettingSubCategoryId = 1;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.getPlanecalculatorsettingsubcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.setPlanecalculatorsettingsubcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlaneCalculatorSettingCategoryEntity planeCalculatorSettingCategory = 2;
 * @return {?proto.protobuf.PlaneCalculatorSettingCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.getPlanecalculatorsettingcategory = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingCategoryEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingCategoryEntity, 2));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingCategoryEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} returns this
*/
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.setPlanecalculatorsettingcategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.clearPlanecalculatorsettingcategory = function() {
  return this.setPlanecalculatorsettingcategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.hasPlanecalculatorsettingcategory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isDefault = 4;
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorSettingTypeEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingstypeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planecalculatorsettingsubcategory: (f = msg.getPlanecalculatorsettingsubcategory()) && proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.toObject(includeInstance, f),
    isenable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorSettingTypeEntity;
  return proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorSettingTypeEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanecalculatorsettingstypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.protobuf.PlaneCalculatorSettingSubCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingsubcategory(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsenable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorSettingTypeEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingstypeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanecalculatorsettingsubcategory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.serializeBinaryToWriter
    );
  }
  f = message.getIsenable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 planeCalculatorSettingsTypeId = 1;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getPlanecalculatorsettingstypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setPlanecalculatorsettingstypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PlaneCalculatorSettingSubCategoryEntity planeCalculatorSettingSubCategory = 3;
 * @return {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getPlanecalculatorsettingsubcategory = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingSubCategoryEntity, 3));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
*/
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setPlanecalculatorsettingsubcategory = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.clearPlanecalculatorsettingsubcategory = function() {
  return this.setPlanecalculatorsettingsubcategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.hasPlanecalculatorsettingsubcategory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool isEnable = 4;
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getIsenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setIsenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool isDefault = 5;
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 type = 6;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingTypeEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorSettingEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    planecalculatorsettingtype: (f = msg.getPlanecalculatorsettingtype()) && proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject(includeInstance, f),
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorSettingEntity;
  return proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanecalculatorsettingid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.protobuf.PlaneCalculatorSettingTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingtype(value);
      break;
    case 5:
      var value = new proto.protobuf.PlaneEntity;
      reader.readMessage(value,proto.protobuf.PlaneEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPlanecalculatorsettingtype();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter
    );
  }
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.PlaneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 planeCalculatorSettingId = 1;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.getPlanecalculatorsettingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.setPlanecalculatorsettingid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 type = 3;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PlaneCalculatorSettingTypeEntity planeCalculatorSettingType = 4;
 * @return {?proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.getPlanecalculatorsettingtype = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingTypeEntity, 4));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingTypeEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
*/
proto.protobuf.PlaneCalculatorSettingEntity.prototype.setPlanecalculatorsettingtype = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.clearPlanecalculatorsettingtype = function() {
  return this.setPlanecalculatorsettingtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.hasPlanecalculatorsettingtype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PlaneEntity plane = 5;
 * @return {?proto.protobuf.PlaneEntity}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneEntity, 5));
};


/**
 * @param {?proto.protobuf.PlaneEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
*/
proto.protobuf.PlaneCalculatorSettingEntity.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity} returns this
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorSettingEntity.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingentitiesList: jspb.Message.toObjectList(msg.getPlanecalculatorsettingentitiesList(),
    proto.protobuf.PlaneCalculatorSettingEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest;
  return proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCalculatorSettingEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader);
      msg.addPlanecalculatorsettingentities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingentitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlaneCalculatorSettingEntity planeCalculatorSettingEntities = 1;
 * @return {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.getPlanecalculatorsettingentitiesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCalculatorSettingEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} value
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} returns this
*/
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.setPlanecalculatorsettingentitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.addPlanecalculatorsettingentities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneCalculatorSettingEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest.prototype.clearPlanecalculatorsettingentitiesList = function() {
  return this.setPlanecalculatorsettingentitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse;
  return proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 price = 1;
 * @return {number}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    planeidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    categorynamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryids(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlaneids(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorynames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getPlaneidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getCategorynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated int32 categoryIds = 1;
 * @return {!Array<number>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.getCategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * repeated int32 planeIds = 2;
 * @return {!Array<number>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.getPlaneidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.setPlaneidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.addPlaneids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.clearPlaneidsList = function() {
  return this.setPlaneidsList([]);
};


/**
 * repeated string categoryNames = 3;
 * @return {!Array<string>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.getCategorynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.setCategorynamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.addCategorynames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest.prototype.clearCategorynamesList = function() {
  return this.setCategorynamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planeidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    categorynamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlaneids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorynames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaneidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getCategorynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated int32 planeIds = 1;
 * @return {!Array<number>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.getPlaneidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.setPlaneidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.addPlaneids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.clearPlaneidsList = function() {
  return this.setPlaneidsList([]);
};


/**
 * repeated string categoryNames = 2;
 * @return {!Array<string>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.getCategorynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.setCategorynamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.addCategorynames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest.prototype.clearCategorynamesList = function() {
  return this.setCategorynamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingtypesList: jspb.Message.toObjectList(msg.getPlanecalculatorsettingtypesList(),
    proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCalculatorSettingTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader);
      msg.addPlanecalculatorsettingtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlaneCalculatorSettingTypeEntity PlaneCalculatorSettingTypes = 1;
 * @return {!Array<!proto.protobuf.PlaneCalculatorSettingTypeEntity>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.getPlanecalculatorsettingtypesList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCalculatorSettingTypeEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCalculatorSettingTypeEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCalculatorSettingTypeEntity>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.setPlanecalculatorsettingtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorSettingTypeEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.addPlanecalculatorsettingtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneCalculatorSettingTypeEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.prototype.clearPlanecalculatorsettingtypesList = function() {
  return this.setPlanecalculatorsettingtypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingsList: jspb.Message.toObjectList(msg.getPlanecalculatorsettingsList(),
    proto.protobuf.PlaneCalculatorSettingEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCalculatorSettingEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingEntity.deserializeBinaryFromReader);
      msg.addPlanecalculatorsettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.PlaneCalculatorSettingEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlaneCalculatorSettingEntity planeCalculatorSettings = 1;
 * @return {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.getPlanecalculatorsettingsList = function() {
  return /** @type{!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.PlaneCalculatorSettingEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.PlaneCalculatorSettingEntity>} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.setPlanecalculatorsettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorSettingEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.PlaneCalculatorSettingEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.addPlanecalculatorsettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.PlaneCalculatorSettingEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.prototype.clearPlanecalculatorsettingsList = function() {
  return this.setPlanecalculatorsettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingtype: (f = msg.getPlanecalculatorsettingtype()) && proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCalculatorSettingTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingtype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneCalculatorSettingTypeEntity planeCalculatorSettingType = 1;
 * @return {?proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.getPlanecalculatorsettingtype = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingTypeEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingTypeEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.setPlanecalculatorsettingtype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.clearPlanecalculatorsettingtype = function() {
  return this.setPlanecalculatorsettingtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest.prototype.hasPlanecalculatorsettingtype = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f),
    planecalculatorsettingtype: (f = msg.getPlanecalculatorsettingtype()) && proto.protobuf.PlaneCalculatorSettingTypeEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    case 2:
      var value = new proto.protobuf.PlaneCalculatorSettingTypeEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingTypeEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlanecalculatorsettingtype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PlaneCalculatorSettingTypeEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResultResponse resultResponse = 1;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 1));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlaneCalculatorSettingTypeEntity planeCalculatorSettingType = 2;
 * @return {?proto.protobuf.PlaneCalculatorSettingTypeEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.getPlanecalculatorsettingtype = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingTypeEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingTypeEntity, 2));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingTypeEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.setPlanecalculatorsettingtype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.clearPlanecalculatorsettingtype = function() {
  return this.setPlanecalculatorsettingtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.prototype.hasPlanecalculatorsettingtype = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planecalculatorsettingsubcategory: (f = msg.getPlanecalculatorsettingsubcategory()) && proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PlaneCalculatorSettingSubCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingsubcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanecalculatorsettingsubcategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaneCalculatorSettingSubCategoryEntity planeCalculatorSettingSubCategory = 1;
 * @return {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.getPlanecalculatorsettingsubcategory = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingSubCategoryEntity, 1));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.setPlanecalculatorsettingsubcategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.clearPlanecalculatorsettingsubcategory = function() {
  return this.setPlanecalculatorsettingsubcategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest.prototype.hasPlanecalculatorsettingsubcategory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f),
    planecalculatorsettingsubcategory: (f = msg.getPlanecalculatorsettingsubcategory()) && proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse;
  return proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    case 2:
      var value = new proto.protobuf.PlaneCalculatorSettingSubCategoryEntity;
      reader.readMessage(value,proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.deserializeBinaryFromReader);
      msg.setPlanecalculatorsettingsubcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlanecalculatorsettingsubcategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PlaneCalculatorSettingSubCategoryEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResultResponse resultResponse = 1;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 1));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlaneCalculatorSettingSubCategoryEntity planeCalculatorSettingSubCategory = 2;
 * @return {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.getPlanecalculatorsettingsubcategory = function() {
  return /** @type{?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneCalculatorSettingSubCategoryEntity, 2));
};


/**
 * @param {?proto.protobuf.PlaneCalculatorSettingSubCategoryEntity|undefined} value
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} returns this
*/
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.setPlanecalculatorsettingsubcategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse} returns this
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.clearPlanecalculatorsettingsubcategory = function() {
  return this.setPlanecalculatorsettingsubcategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.prototype.hasPlanecalculatorsettingsubcategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileApiUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileApiUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileApiUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileinfo: (f = msg.getFileinfo()) && proto.protobuf.FileInfo.toObject(includeInstance, f),
    bytes: msg.getBytes_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileApiUploadRequest}
 */
proto.protobuf.FileApiUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileApiUploadRequest;
  return proto.protobuf.FileApiUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileApiUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileApiUploadRequest}
 */
proto.protobuf.FileApiUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.FileInfo;
      reader.readMessage(value,proto.protobuf.FileInfo.deserializeBinaryFromReader);
      msg.setFileinfo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileApiUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileApiUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileApiUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.FileInfo.serializeBinaryToWriter
    );
  }
  f = message.getBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional FileInfo fileInfo = 1;
 * @return {?proto.protobuf.FileInfo}
 */
proto.protobuf.FileApiUploadRequest.prototype.getFileinfo = function() {
  return /** @type{?proto.protobuf.FileInfo} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileInfo, 1));
};


/**
 * @param {?proto.protobuf.FileInfo|undefined} value
 * @return {!proto.protobuf.FileApiUploadRequest} returns this
*/
proto.protobuf.FileApiUploadRequest.prototype.setFileinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileApiUploadRequest} returns this
 */
proto.protobuf.FileApiUploadRequest.prototype.clearFileinfo = function() {
  return this.setFileinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileApiUploadRequest.prototype.hasFileinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes bytes = 2;
 * @return {string}
 */
proto.protobuf.FileApiUploadRequest.prototype.getBytes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes bytes = 2;
 * This is a type-conversion wrapper around `getBytes()`
 * @return {string}
 */
proto.protobuf.FileApiUploadRequest.prototype.getBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytes()));
};


/**
 * optional bytes bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytes()`
 * @return {!Uint8Array}
 */
proto.protobuf.FileApiUploadRequest.prototype.getBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.FileApiUploadRequest} returns this
 */
proto.protobuf.FileApiUploadRequest.prototype.setBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileApiUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileApiUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileApiUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f),
    fileid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filepath: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileApiUploadResponse}
 */
proto.protobuf.FileApiUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileApiUploadResponse;
  return proto.protobuf.FileApiUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileApiUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileApiUploadResponse}
 */
proto.protobuf.FileApiUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileApiUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileApiUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileApiUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ResultResponse resultResponse = 1;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.FileApiUploadResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 1));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.FileApiUploadResponse} returns this
*/
proto.protobuf.FileApiUploadResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileApiUploadResponse} returns this
 */
proto.protobuf.FileApiUploadResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileApiUploadResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fileId = 2;
 * @return {string}
 */
proto.protobuf.FileApiUploadResponse.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileApiUploadResponse} returns this
 */
proto.protobuf.FileApiUploadResponse.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fileName = 3;
 * @return {string}
 */
proto.protobuf.FileApiUploadResponse.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileApiUploadResponse} returns this
 */
proto.protobuf.FileApiUploadResponse.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filePath = 4;
 * @return {string}
 */
proto.protobuf.FileApiUploadResponse.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileApiUploadResponse} returns this
 */
proto.protobuf.FileApiUploadResponse.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileInfo}
 */
proto.protobuf.FileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileInfo;
  return proto.protobuf.FileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileInfo}
 */
proto.protobuf.FileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string fileId = 1;
 * @return {string}
 */
proto.protobuf.FileInfo.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileInfo} returns this
 */
proto.protobuf.FileInfo.prototype.setFileid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.FileInfo} returns this
 */
proto.protobuf.FileInfo.prototype.clearFileid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileInfo.prototype.hasFileid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 size = 2;
 * @return {number}
 */
proto.protobuf.FileInfo.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FileInfo} returns this
 */
proto.protobuf.FileInfo.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.protobuf.FileInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileInfo} returns this
 */
proto.protobuf.FileInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.DataCase = {
  DATA_NOT_SET: 0,
  INFO: 1,
  CHUNKDATA: 2
};

/**
 * @return {proto.protobuf.FileManagementApiUploadChunkRequest.DataCase}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.getDataCase = function() {
  return /** @type {proto.protobuf.FileManagementApiUploadChunkRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileManagementApiUploadChunkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileManagementApiUploadChunkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadChunkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.protobuf.FileInfo.toObject(includeInstance, f),
    chunkdata: msg.getChunkdata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileManagementApiUploadChunkRequest;
  return proto.protobuf.FileManagementApiUploadChunkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileManagementApiUploadChunkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.FileInfo;
      reader.readMessage(value,proto.protobuf.FileInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileManagementApiUploadChunkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileManagementApiUploadChunkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadChunkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.FileInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional FileInfo info = 1;
 * @return {?proto.protobuf.FileInfo}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.getInfo = function() {
  return /** @type{?proto.protobuf.FileInfo} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileInfo, 1));
};


/**
 * @param {?proto.protobuf.FileInfo|undefined} value
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest} returns this
*/
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.setInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest} returns this
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunkData = 2;
 * @return {string}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.getChunkdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunkData = 2;
 * This is a type-conversion wrapper around `getChunkdata()`
 * @return {string}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.getChunkdata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkdata()));
};


/**
 * optional bytes chunkData = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkdata()`
 * @return {!Uint8Array}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.getChunkdata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkdata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest} returns this
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.setChunkdata = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.FileManagementApiUploadChunkRequest} returns this
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.clearChunkdata = function() {
  return jspb.Message.setOneofField(this, 2, proto.protobuf.FileManagementApiUploadChunkRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileManagementApiUploadChunkRequest.prototype.hasChunkdata = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.FileManagementApiUploadFileRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileManagementApiUploadFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileManagementApiUploadFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.protobuf.FileInfo.toObject(includeInstance, f),
    chunkdataList: msg.getChunkdataList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest}
 */
proto.protobuf.FileManagementApiUploadFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileManagementApiUploadFileRequest;
  return proto.protobuf.FileManagementApiUploadFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileManagementApiUploadFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest}
 */
proto.protobuf.FileManagementApiUploadFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.FileInfo;
      reader.readMessage(value,proto.protobuf.FileInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addChunkdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileManagementApiUploadFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileManagementApiUploadFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.FileInfo.serializeBinaryToWriter
    );
  }
  f = message.getChunkdataList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
};


/**
 * optional FileInfo info = 1;
 * @return {?proto.protobuf.FileInfo}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.getInfo = function() {
  return /** @type{?proto.protobuf.FileInfo} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.FileInfo, 1));
};


/**
 * @param {?proto.protobuf.FileInfo|undefined} value
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest} returns this
*/
proto.protobuf.FileManagementApiUploadFileRequest.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest} returns this
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated bytes chunkData = 2;
 * @return {!Array<string>}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.getChunkdataList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes chunkData = 2;
 * This is a type-conversion wrapper around `getChunkdataList()`
 * @return {!Array<string>}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.getChunkdataList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getChunkdataList()));
};


/**
 * repeated bytes chunkData = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkdataList()`
 * @return {!Array<!Uint8Array>}
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.getChunkdataList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getChunkdataList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest} returns this
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.setChunkdataList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest} returns this
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.addChunkdata = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.FileManagementApiUploadFileRequest} returns this
 */
proto.protobuf.FileManagementApiUploadFileRequest.prototype.clearChunkdataList = function() {
  return this.setChunkdataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileManagementApiUploadFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileManagementApiUploadFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f),
    fileid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filepath: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse}
 */
proto.protobuf.FileManagementApiUploadFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileManagementApiUploadFileResponse;
  return proto.protobuf.FileManagementApiUploadFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileManagementApiUploadFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse}
 */
proto.protobuf.FileManagementApiUploadFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileManagementApiUploadFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileManagementApiUploadFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileManagementApiUploadFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ResultResponse resultResponse = 1;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 1));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse} returns this
*/
proto.protobuf.FileManagementApiUploadFileResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse} returns this
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fileId = 2;
 * @return {string}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse} returns this
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fileName = 3;
 * @return {string}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse} returns this
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filePath = 4;
 * @return {string}
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileManagementApiUploadFileResponse} returns this
 */
proto.protobuf.FileManagementApiUploadFileResponse.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileApiFileListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileApiFileListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileApiFileListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiFileListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileApiFileListRequest}
 */
proto.protobuf.FileApiFileListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileApiFileListRequest;
  return proto.protobuf.FileApiFileListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileApiFileListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileApiFileListRequest}
 */
proto.protobuf.FileApiFileListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileApiFileListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileApiFileListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileApiFileListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiFileListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.protobuf.FileApiFileListRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FileApiFileListRequest} returns this
 */
proto.protobuf.FileApiFileListRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PageModel pageModel = 2;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.FileApiFileListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 2));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.FileApiFileListRequest} returns this
*/
proto.protobuf.FileApiFileListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.FileApiFileListRequest} returns this
 */
proto.protobuf.FileApiFileListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.FileApiFileListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.FileApiFileListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileApiFileListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileApiFileListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileApiFileListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiFileListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.protobuf.FileEntity.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileApiFileListResponse}
 */
proto.protobuf.FileApiFileListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileApiFileListResponse;
  return proto.protobuf.FileApiFileListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileApiFileListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileApiFileListResponse}
 */
proto.protobuf.FileApiFileListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.FileEntity;
      reader.readMessage(value,proto.protobuf.FileEntity.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileApiFileListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileApiFileListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileApiFileListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileApiFileListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.FileEntity.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated FileEntity files = 1;
 * @return {!Array<!proto.protobuf.FileEntity>}
 */
proto.protobuf.FileApiFileListResponse.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.protobuf.FileEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.FileEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.FileEntity>} value
 * @return {!proto.protobuf.FileApiFileListResponse} returns this
*/
proto.protobuf.FileApiFileListResponse.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.FileEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.FileEntity}
 */
proto.protobuf.FileApiFileListResponse.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.FileEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.FileApiFileListResponse} returns this
 */
proto.protobuf.FileApiFileListResponse.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.protobuf.FileApiFileListResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.FileApiFileListResponse} returns this
 */
proto.protobuf.FileApiFileListResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.FileEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.FileEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.FileEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filepath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isdeleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.FileEntity}
 */
proto.protobuf.FileEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.FileEntity;
  return proto.protobuf.FileEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.FileEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.FileEntity}
 */
proto.protobuf.FileEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.FileEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.FileEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.FileEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.FileEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsdeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string fileId = 1;
 * @return {string}
 */
proto.protobuf.FileEntity.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileEntity} returns this
 */
proto.protobuf.FileEntity.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.FileEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileEntity} returns this
 */
proto.protobuf.FileEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string filePath = 3;
 * @return {string}
 */
proto.protobuf.FileEntity.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.FileEntity} returns this
 */
proto.protobuf.FileEntity.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isDeleted = 4;
 * @return {boolean}
 */
proto.protobuf.FileEntity.prototype.getIsdeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.FileEntity} returns this
 */
proto.protobuf.FileEntity.prototype.setIsdeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ScheduleEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ScheduleEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ScheduleEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    flycompanyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    freighterid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scheduletype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    schedulename: jspb.Message.getFieldWithDefault(msg, 5, ""),
    plane: (f = msg.getPlane()) && proto.protobuf.PlaneShortEntity.toObject(includeInstance, f),
    datefrom: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dateto: jspb.Message.getFieldWithDefault(msg, 8, ""),
    emptyleg: (f = msg.getEmptyleg()) && proto.protobuf.EmptyLegEntity.toObject(includeInstance, f),
    orderrequest: (f = msg.getOrderrequest()) && proto.protobuf.OrderRequestEntity.toObject(includeInstance, f),
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ScheduleEntity}
 */
proto.protobuf.ScheduleEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ScheduleEntity;
  return proto.protobuf.ScheduleEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ScheduleEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ScheduleEntity}
 */
proto.protobuf.ScheduleEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduleid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlycompanyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreighterid(value);
      break;
    case 4:
      var value = /** @type {!proto.protobuf.ScheduleType} */ (reader.readEnum());
      msg.setScheduletype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchedulename(value);
      break;
    case 6:
      var value = new proto.protobuf.PlaneShortEntity;
      reader.readMessage(value,proto.protobuf.PlaneShortEntity.deserializeBinaryFromReader);
      msg.setPlane(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatefrom(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateto(value);
      break;
    case 9:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.setEmptyleg(value);
      break;
    case 10:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.setOrderrequest(value);
      break;
    case 11:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 12:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ScheduleEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ScheduleEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ScheduleEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFlycompanyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFreighterid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getScheduletype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSchedulename();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlane();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.PlaneShortEntity.serializeBinaryToWriter
    );
  }
  f = message.getDatefrom();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmptyleg();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderrequest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 scheduleId = 1;
 * @return {number}
 */
proto.protobuf.ScheduleEntity.prototype.getScheduleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setScheduleid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 flyCompanyId = 2;
 * @return {number}
 */
proto.protobuf.ScheduleEntity.prototype.getFlycompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setFlycompanyid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 freighterId = 3;
 * @return {number}
 */
proto.protobuf.ScheduleEntity.prototype.getFreighterid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setFreighterid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ScheduleType scheduleType = 4;
 * @return {!proto.protobuf.ScheduleType}
 */
proto.protobuf.ScheduleEntity.prototype.getScheduletype = function() {
  return /** @type {!proto.protobuf.ScheduleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.protobuf.ScheduleType} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setScheduletype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string scheduleName = 5;
 * @return {string}
 */
proto.protobuf.ScheduleEntity.prototype.getSchedulename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setSchedulename = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PlaneShortEntity plane = 6;
 * @return {?proto.protobuf.PlaneShortEntity}
 */
proto.protobuf.ScheduleEntity.prototype.getPlane = function() {
  return /** @type{?proto.protobuf.PlaneShortEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PlaneShortEntity, 6));
};


/**
 * @param {?proto.protobuf.PlaneShortEntity|undefined} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
*/
proto.protobuf.ScheduleEntity.prototype.setPlane = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.clearPlane = function() {
  return this.setPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleEntity.prototype.hasPlane = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string dateFrom = 7;
 * @return {string}
 */
proto.protobuf.ScheduleEntity.prototype.getDatefrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setDatefrom = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dateTo = 8;
 * @return {string}
 */
proto.protobuf.ScheduleEntity.prototype.getDateto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.setDateto = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional EmptyLegEntity emptyLeg = 9;
 * @return {?proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.ScheduleEntity.prototype.getEmptyleg = function() {
  return /** @type{?proto.protobuf.EmptyLegEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegEntity, 9));
};


/**
 * @param {?proto.protobuf.EmptyLegEntity|undefined} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
*/
proto.protobuf.ScheduleEntity.prototype.setEmptyleg = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.clearEmptyleg = function() {
  return this.setEmptyleg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleEntity.prototype.hasEmptyleg = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional OrderRequestEntity orderRequest = 10;
 * @return {?proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.ScheduleEntity.prototype.getOrderrequest = function() {
  return /** @type{?proto.protobuf.OrderRequestEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRequestEntity, 10));
};


/**
 * @param {?proto.protobuf.OrderRequestEntity|undefined} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
*/
proto.protobuf.ScheduleEntity.prototype.setOrderrequest = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.clearOrderrequest = function() {
  return this.setOrderrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleEntity.prototype.hasOrderrequest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AirportEntity airportFrom = 11;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.ScheduleEntity.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 11));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
*/
proto.protobuf.ScheduleEntity.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleEntity.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AirportEntity airportTo = 12;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.ScheduleEntity.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 12));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.ScheduleEntity} returns this
*/
proto.protobuf.ScheduleEntity.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleEntity} returns this
 */
proto.protobuf.ScheduleEntity.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleEntity.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.ScheduleApiScheduleListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ScheduleApiScheduleListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ScheduleApiScheduleListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planeidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    datefrom: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateto: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest}
 */
proto.protobuf.ScheduleApiScheduleListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ScheduleApiScheduleListRequest;
  return proto.protobuf.ScheduleApiScheduleListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ScheduleApiScheduleListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest}
 */
proto.protobuf.ScheduleApiScheduleListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlaneids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatefrom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ScheduleApiScheduleListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ScheduleApiScheduleListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaneidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDatefrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated int32 planeIds = 1;
 * @return {!Array<number>}
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.getPlaneidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.setPlaneidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.addPlaneids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.clearPlaneidsList = function() {
  return this.setPlaneidsList([]);
};


/**
 * optional string dateFrom = 2;
 * @return {string}
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.getDatefrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.setDatefrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dateTo = 3;
 * @return {string}
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.getDateto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.ScheduleApiScheduleListRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleListRequest.prototype.setDateto = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.ScheduleApiScheduleListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ScheduleApiScheduleListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ScheduleApiScheduleListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ScheduleApiScheduleListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedulesList: jspb.Message.toObjectList(msg.getSchedulesList(),
    proto.protobuf.ScheduleEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ScheduleApiScheduleListResponse}
 */
proto.protobuf.ScheduleApiScheduleListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ScheduleApiScheduleListResponse;
  return proto.protobuf.ScheduleApiScheduleListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ScheduleApiScheduleListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ScheduleApiScheduleListResponse}
 */
proto.protobuf.ScheduleApiScheduleListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ScheduleEntity;
      reader.readMessage(value,proto.protobuf.ScheduleEntity.deserializeBinaryFromReader);
      msg.addSchedules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ScheduleApiScheduleListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ScheduleApiScheduleListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ScheduleApiScheduleListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.ScheduleEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScheduleEntity schedules = 1;
 * @return {!Array<!proto.protobuf.ScheduleEntity>}
 */
proto.protobuf.ScheduleApiScheduleListResponse.prototype.getSchedulesList = function() {
  return /** @type{!Array<!proto.protobuf.ScheduleEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.ScheduleEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.ScheduleEntity>} value
 * @return {!proto.protobuf.ScheduleApiScheduleListResponse} returns this
*/
proto.protobuf.ScheduleApiScheduleListResponse.prototype.setSchedulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.ScheduleEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.ScheduleEntity}
 */
proto.protobuf.ScheduleApiScheduleListResponse.prototype.addSchedules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.ScheduleEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.ScheduleApiScheduleListResponse} returns this
 */
proto.protobuf.ScheduleApiScheduleListResponse.prototype.clearSchedulesList = function() {
  return this.setSchedulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ScheduleApiScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ScheduleApiScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedule: (f = msg.getSchedule()) && proto.protobuf.ScheduleEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ScheduleApiScheduleRequest}
 */
proto.protobuf.ScheduleApiScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ScheduleApiScheduleRequest;
  return proto.protobuf.ScheduleApiScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ScheduleApiScheduleRequest}
 */
proto.protobuf.ScheduleApiScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ScheduleEntity;
      reader.readMessage(value,proto.protobuf.ScheduleEntity.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ScheduleApiScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ScheduleApiScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ScheduleEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScheduleEntity schedule = 1;
 * @return {?proto.protobuf.ScheduleEntity}
 */
proto.protobuf.ScheduleApiScheduleRequest.prototype.getSchedule = function() {
  return /** @type{?proto.protobuf.ScheduleEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ScheduleEntity, 1));
};


/**
 * @param {?proto.protobuf.ScheduleEntity|undefined} value
 * @return {!proto.protobuf.ScheduleApiScheduleRequest} returns this
*/
proto.protobuf.ScheduleApiScheduleRequest.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleApiScheduleRequest} returns this
 */
proto.protobuf.ScheduleApiScheduleRequest.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleApiScheduleRequest.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.ScheduleApiScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.ScheduleApiScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedule: (f = msg.getSchedule()) && proto.protobuf.ScheduleEntity.toObject(includeInstance, f),
    resultresponse: (f = msg.getResultresponse()) && proto.protobuf.ResultResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.ScheduleApiScheduleResponse}
 */
proto.protobuf.ScheduleApiScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.ScheduleApiScheduleResponse;
  return proto.protobuf.ScheduleApiScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.ScheduleApiScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.ScheduleApiScheduleResponse}
 */
proto.protobuf.ScheduleApiScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.ScheduleEntity;
      reader.readMessage(value,proto.protobuf.ScheduleEntity.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 2:
      var value = new proto.protobuf.ResultResponse;
      reader.readMessage(value,proto.protobuf.ResultResponse.deserializeBinaryFromReader);
      msg.setResultresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.ScheduleApiScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.ScheduleApiScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.ScheduleApiScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.ScheduleEntity.serializeBinaryToWriter
    );
  }
  f = message.getResultresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.ResultResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScheduleEntity schedule = 1;
 * @return {?proto.protobuf.ScheduleEntity}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.getSchedule = function() {
  return /** @type{?proto.protobuf.ScheduleEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ScheduleEntity, 1));
};


/**
 * @param {?proto.protobuf.ScheduleEntity|undefined} value
 * @return {!proto.protobuf.ScheduleApiScheduleResponse} returns this
*/
proto.protobuf.ScheduleApiScheduleResponse.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleApiScheduleResponse} returns this
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResultResponse resultResponse = 2;
 * @return {?proto.protobuf.ResultResponse}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.getResultresponse = function() {
  return /** @type{?proto.protobuf.ResultResponse} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.ResultResponse, 2));
};


/**
 * @param {?proto.protobuf.ResultResponse|undefined} value
 * @return {!proto.protobuf.ScheduleApiScheduleResponse} returns this
*/
proto.protobuf.ScheduleApiScheduleResponse.prototype.setResultresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.ScheduleApiScheduleResponse} returns this
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.clearResultresponse = function() {
  return this.setResultresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.ScheduleApiScheduleResponse.prototype.hasResultresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TimezoneEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TimezoneEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TimezoneEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    timezoneid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TimezoneEntity}
 */
proto.protobuf.TimezoneEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TimezoneEntity;
  return proto.protobuf.TimezoneEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TimezoneEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TimezoneEntity}
 */
proto.protobuf.TimezoneEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimezoneid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TimezoneEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TimezoneEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TimezoneEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimezoneid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 timezoneId = 1;
 * @return {number}
 */
proto.protobuf.TimezoneEntity.prototype.getTimezoneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TimezoneEntity} returns this
 */
proto.protobuf.TimezoneEntity.prototype.setTimezoneid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.TimezoneEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.TimezoneEntity} returns this
 */
proto.protobuf.TimezoneEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.protobuf.TimezoneEntity.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.TimezoneEntity} returns this
 */
proto.protobuf.TimezoneEntity.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TimezoneApiTimezoneListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneApiTimezoneListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TimezoneApiTimezoneListRequest}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TimezoneApiTimezoneListRequest;
  return proto.protobuf.TimezoneApiTimezoneListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TimezoneApiTimezoneListRequest}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TimezoneApiTimezoneListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneApiTimezoneListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PageModel pageModel = 1;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 1));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.TimezoneApiTimezoneListRequest} returns this
*/
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.TimezoneApiTimezoneListRequest} returns this
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.TimezoneApiTimezoneListRequest} returns this
 */
proto.protobuf.TimezoneApiTimezoneListRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.TimezoneApiTimezoneListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.TimezoneApiTimezoneListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.TimezoneApiTimezoneListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneApiTimezoneListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timezonesList: jspb.Message.toObjectList(msg.getTimezonesList(),
    proto.protobuf.TimezoneEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.TimezoneApiTimezoneListResponse}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.TimezoneApiTimezoneListResponse;
  return proto.protobuf.TimezoneApiTimezoneListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.TimezoneApiTimezoneListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.TimezoneApiTimezoneListResponse}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.TimezoneEntity;
      reader.readMessage(value,proto.protobuf.TimezoneEntity.deserializeBinaryFromReader);
      msg.addTimezones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.TimezoneApiTimezoneListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.TimezoneApiTimezoneListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.TimezoneApiTimezoneListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimezonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.TimezoneEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimezoneEntity timezones = 1;
 * @return {!Array<!proto.protobuf.TimezoneEntity>}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.getTimezonesList = function() {
  return /** @type{!Array<!proto.protobuf.TimezoneEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.TimezoneEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.TimezoneEntity>} value
 * @return {!proto.protobuf.TimezoneApiTimezoneListResponse} returns this
*/
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.setTimezonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.TimezoneEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.TimezoneEntity}
 */
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.addTimezones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.TimezoneEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.TimezoneApiTimezoneListResponse} returns this
 */
proto.protobuf.TimezoneApiTimezoneListResponse.prototype.clearTimezonesList = function() {
  return this.setTimezonesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.NotificationMetadataEntity.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.NotificationMetadataEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.NotificationMetadataEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.NotificationMetadataEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationMetadataEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    requestcompany: (f = msg.getRequestcompany()) && proto.protobuf.CompanyEntity.toObject(includeInstance, f),
    emptyleg: (f = msg.getEmptyleg()) && proto.protobuf.EmptyLegEntity.toObject(includeInstance, f),
    orderroute: (f = msg.getOrderroute()) && proto.protobuf.OrderRouteEntity.toObject(includeInstance, f),
    emptylegorderroute: (f = msg.getEmptylegorderroute()) && proto.protobuf.EmptyLegOrderRouteEntity.toObject(includeInstance, f),
    orderrequest: (f = msg.getOrderrequest()) && proto.protobuf.OrderRequestEntity.toObject(includeInstance, f),
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto.protobuf.AirportRouteMetadataEntity.toObject, includeInstance),
    order: (f = msg.getOrder()) && proto.protobuf.OrderEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.NotificationMetadataEntity}
 */
proto.protobuf.NotificationMetadataEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.NotificationMetadataEntity;
  return proto.protobuf.NotificationMetadataEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.NotificationMetadataEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.NotificationMetadataEntity}
 */
proto.protobuf.NotificationMetadataEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 2:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    case 3:
      var value = new proto.protobuf.CompanyEntity;
      reader.readMessage(value,proto.protobuf.CompanyEntity.deserializeBinaryFromReader);
      msg.setRequestcompany(value);
      break;
    case 4:
      var value = new proto.protobuf.EmptyLegEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegEntity.deserializeBinaryFromReader);
      msg.setEmptyleg(value);
      break;
    case 5:
      var value = new proto.protobuf.OrderRouteEntity;
      reader.readMessage(value,proto.protobuf.OrderRouteEntity.deserializeBinaryFromReader);
      msg.setOrderroute(value);
      break;
    case 6:
      var value = new proto.protobuf.EmptyLegOrderRouteEntity;
      reader.readMessage(value,proto.protobuf.EmptyLegOrderRouteEntity.deserializeBinaryFromReader);
      msg.setEmptylegorderroute(value);
      break;
    case 7:
      var value = new proto.protobuf.OrderRequestEntity;
      reader.readMessage(value,proto.protobuf.OrderRequestEntity.deserializeBinaryFromReader);
      msg.setOrderrequest(value);
      break;
    case 8:
      var value = new proto.protobuf.AirportRouteMetadataEntity;
      reader.readMessage(value,proto.protobuf.AirportRouteMetadataEntity.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 9:
      var value = new proto.protobuf.OrderEntity;
      reader.readMessage(value,proto.protobuf.OrderEntity.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.NotificationMetadataEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.NotificationMetadataEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.NotificationMetadataEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationMetadataEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getRequestcompany();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.CompanyEntity.serializeBinaryToWriter
    );
  }
  f = message.getEmptyleg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.EmptyLegEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderroute();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.OrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getEmptylegorderroute();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.EmptyLegOrderRouteEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrderrequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.OrderRequestEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protobuf.AirportRouteMetadataEntity.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.OrderEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional AirportEntity airportFrom = 1;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AirportEntity airportTo = 2;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 2));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompanyEntity requestCompany = 3;
 * @return {?proto.protobuf.CompanyEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getRequestcompany = function() {
  return /** @type{?proto.protobuf.CompanyEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.CompanyEntity, 3));
};


/**
 * @param {?proto.protobuf.CompanyEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setRequestcompany = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearRequestcompany = function() {
  return this.setRequestcompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasRequestcompany = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EmptyLegEntity emptyLeg = 4;
 * @return {?proto.protobuf.EmptyLegEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getEmptyleg = function() {
  return /** @type{?proto.protobuf.EmptyLegEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegEntity, 4));
};


/**
 * @param {?proto.protobuf.EmptyLegEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setEmptyleg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearEmptyleg = function() {
  return this.setEmptyleg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasEmptyleg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OrderRouteEntity orderRoute = 5;
 * @return {?proto.protobuf.OrderRouteEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getOrderroute = function() {
  return /** @type{?proto.protobuf.OrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRouteEntity, 5));
};


/**
 * @param {?proto.protobuf.OrderRouteEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setOrderroute = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearOrderroute = function() {
  return this.setOrderroute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasOrderroute = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EmptyLegOrderRouteEntity emptyLegOrderRoute = 6;
 * @return {?proto.protobuf.EmptyLegOrderRouteEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getEmptylegorderroute = function() {
  return /** @type{?proto.protobuf.EmptyLegOrderRouteEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.EmptyLegOrderRouteEntity, 6));
};


/**
 * @param {?proto.protobuf.EmptyLegOrderRouteEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setEmptylegorderroute = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearEmptylegorderroute = function() {
  return this.setEmptylegorderroute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasEmptylegorderroute = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OrderRequestEntity orderRequest = 7;
 * @return {?proto.protobuf.OrderRequestEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getOrderrequest = function() {
  return /** @type{?proto.protobuf.OrderRequestEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderRequestEntity, 7));
};


/**
 * @param {?proto.protobuf.OrderRequestEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setOrderrequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearOrderrequest = function() {
  return this.setOrderrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasOrderrequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated AirportRouteMetadataEntity airports = 8;
 * @return {!Array<!proto.protobuf.AirportRouteMetadataEntity>}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.protobuf.AirportRouteMetadataEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.AirportRouteMetadataEntity, 8));
};


/**
 * @param {!Array<!proto.protobuf.AirportRouteMetadataEntity>} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protobuf.AirportRouteMetadataEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.AirportRouteMetadataEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protobuf.AirportRouteMetadataEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * optional OrderEntity order = 9;
 * @return {?proto.protobuf.OrderEntity}
 */
proto.protobuf.NotificationMetadataEntity.prototype.getOrder = function() {
  return /** @type{?proto.protobuf.OrderEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.OrderEntity, 9));
};


/**
 * @param {?proto.protobuf.OrderEntity|undefined} value
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
*/
proto.protobuf.NotificationMetadataEntity.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationMetadataEntity} returns this
 */
proto.protobuf.NotificationMetadataEntity.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationMetadataEntity.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.AirportRouteMetadataEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.AirportRouteMetadataEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportRouteMetadataEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    airportfrom: (f = msg.getAirportfrom()) && proto.protobuf.AirportEntity.toObject(includeInstance, f),
    airportto: (f = msg.getAirportto()) && proto.protobuf.AirportEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.AirportRouteMetadataEntity}
 */
proto.protobuf.AirportRouteMetadataEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.AirportRouteMetadataEntity;
  return proto.protobuf.AirportRouteMetadataEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.AirportRouteMetadataEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.AirportRouteMetadataEntity}
 */
proto.protobuf.AirportRouteMetadataEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportfrom(value);
      break;
    case 2:
      var value = new proto.protobuf.AirportEntity;
      reader.readMessage(value,proto.protobuf.AirportEntity.deserializeBinaryFromReader);
      msg.setAirportto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.AirportRouteMetadataEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.AirportRouteMetadataEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.AirportRouteMetadataEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirportfrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
  f = message.getAirportto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.AirportEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional AirportEntity airportFrom = 1;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.getAirportfrom = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 1));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.AirportRouteMetadataEntity} returns this
*/
proto.protobuf.AirportRouteMetadataEntity.prototype.setAirportfrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AirportRouteMetadataEntity} returns this
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.clearAirportfrom = function() {
  return this.setAirportfrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.hasAirportfrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AirportEntity airportTo = 2;
 * @return {?proto.protobuf.AirportEntity}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.getAirportto = function() {
  return /** @type{?proto.protobuf.AirportEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.AirportEntity, 2));
};


/**
 * @param {?proto.protobuf.AirportEntity|undefined} value
 * @return {!proto.protobuf.AirportRouteMetadataEntity} returns this
*/
proto.protobuf.AirportRouteMetadataEntity.prototype.setAirportto = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.AirportRouteMetadataEntity} returns this
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.clearAirportto = function() {
  return this.setAirportto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.AirportRouteMetadataEntity.prototype.hasAirportto = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.NotificationEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.NotificationEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.NotificationEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isread: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    sender: (f = msg.getSender()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && proto.protobuf.UserEntity.toObject(includeInstance, f),
    createdat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    metadata: (f = msg.getMetadata()) && proto.protobuf.NotificationMetadataEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.NotificationEntity}
 */
proto.protobuf.NotificationEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.NotificationEntity;
  return proto.protobuf.NotificationEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.NotificationEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.NotificationEntity}
 */
proto.protobuf.NotificationEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotificationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsread(value);
      break;
    case 5:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 6:
      var value = new proto.protobuf.UserEntity;
      reader.readMessage(value,proto.protobuf.UserEntity.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 8:
      var value = new proto.protobuf.NotificationMetadataEntity;
      reader.readMessage(value,proto.protobuf.NotificationMetadataEntity.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.NotificationEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.NotificationEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.NotificationEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsread();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.UserEntity.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.NotificationMetadataEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 notificationId = 1;
 * @return {number}
 */
proto.protobuf.NotificationEntity.prototype.getNotificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.setNotificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.protobuf.NotificationEntity.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string entityId = 3;
 * @return {string}
 */
proto.protobuf.NotificationEntity.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isRead = 4;
 * @return {boolean}
 */
proto.protobuf.NotificationEntity.prototype.getIsread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.setIsread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional UserEntity sender = 5;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.NotificationEntity.prototype.getSender = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 5));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.NotificationEntity} returns this
*/
proto.protobuf.NotificationEntity.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationEntity.prototype.hasSender = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserEntity receiver = 6;
 * @return {?proto.protobuf.UserEntity}
 */
proto.protobuf.NotificationEntity.prototype.getReceiver = function() {
  return /** @type{?proto.protobuf.UserEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.UserEntity, 6));
};


/**
 * @param {?proto.protobuf.UserEntity|undefined} value
 * @return {!proto.protobuf.NotificationEntity} returns this
*/
proto.protobuf.NotificationEntity.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationEntity.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string createdAt = 7;
 * @return {string}
 */
proto.protobuf.NotificationEntity.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional NotificationMetadataEntity metadata = 8;
 * @return {?proto.protobuf.NotificationMetadataEntity}
 */
proto.protobuf.NotificationEntity.prototype.getMetadata = function() {
  return /** @type{?proto.protobuf.NotificationMetadataEntity} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.NotificationMetadataEntity, 8));
};


/**
 * @param {?proto.protobuf.NotificationMetadataEntity|undefined} value
 * @return {!proto.protobuf.NotificationEntity} returns this
*/
proto.protobuf.NotificationEntity.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationEntity} returns this
 */
proto.protobuf.NotificationEntity.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationEntity.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.NotificationApiNotificationListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.NotificationApiNotificationListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.NotificationApiNotificationListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiNotificationListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    isread: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pagemodel: (f = msg.getPagemodel()) && proto.protobuf.PageModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.NotificationApiNotificationListRequest}
 */
proto.protobuf.NotificationApiNotificationListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.NotificationApiNotificationListRequest;
  return proto.protobuf.NotificationApiNotificationListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.NotificationApiNotificationListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.NotificationApiNotificationListRequest}
 */
proto.protobuf.NotificationApiNotificationListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotificationids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsread(value);
      break;
    case 3:
      var value = new proto.protobuf.PageModel;
      reader.readMessage(value,proto.protobuf.PageModel.deserializeBinaryFromReader);
      msg.setPagemodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.NotificationApiNotificationListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.NotificationApiNotificationListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiNotificationListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPagemodel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.PageModel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 notificationIds = 1;
 * @return {!Array<number>}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.getNotificationidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.setNotificationidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.addNotificationids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.clearNotificationidsList = function() {
  return this.setNotificationidsList([]);
};


/**
 * optional bool isRead = 2;
 * @return {boolean}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.getIsread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.setIsread = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.clearIsread = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.hasIsread = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PageModel pageModel = 3;
 * @return {?proto.protobuf.PageModel}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.getPagemodel = function() {
  return /** @type{?proto.protobuf.PageModel} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.PageModel, 3));
};


/**
 * @param {?proto.protobuf.PageModel|undefined} value
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
*/
proto.protobuf.NotificationApiNotificationListRequest.prototype.setPagemodel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.NotificationApiNotificationListRequest} returns this
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.clearPagemodel = function() {
  return this.setPagemodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.NotificationApiNotificationListRequest.prototype.hasPagemodel = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.NotificationApiNotificationListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.NotificationApiNotificationListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.NotificationApiNotificationListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.NotificationApiNotificationListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiNotificationListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.protobuf.NotificationEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.NotificationApiNotificationListResponse}
 */
proto.protobuf.NotificationApiNotificationListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.NotificationApiNotificationListResponse;
  return proto.protobuf.NotificationApiNotificationListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.NotificationApiNotificationListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.NotificationApiNotificationListResponse}
 */
proto.protobuf.NotificationApiNotificationListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.NotificationEntity;
      reader.readMessage(value,proto.protobuf.NotificationEntity.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.NotificationApiNotificationListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.NotificationApiNotificationListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.NotificationApiNotificationListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiNotificationListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protobuf.NotificationEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationEntity notifications = 1;
 * @return {!Array<!proto.protobuf.NotificationEntity>}
 */
proto.protobuf.NotificationApiNotificationListResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.protobuf.NotificationEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.NotificationEntity, 1));
};


/**
 * @param {!Array<!proto.protobuf.NotificationEntity>} value
 * @return {!proto.protobuf.NotificationApiNotificationListResponse} returns this
*/
proto.protobuf.NotificationApiNotificationListResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protobuf.NotificationEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.NotificationEntity}
 */
proto.protobuf.NotificationApiNotificationListResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protobuf.NotificationEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.NotificationApiNotificationListResponse} returns this
 */
proto.protobuf.NotificationApiNotificationListResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.NotificationApiReadNotificationsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.NotificationApiReadNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiReadNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.NotificationApiReadNotificationsRequest}
 */
proto.protobuf.NotificationApiReadNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.NotificationApiReadNotificationsRequest;
  return proto.protobuf.NotificationApiReadNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.NotificationApiReadNotificationsRequest}
 */
proto.protobuf.NotificationApiReadNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotificationids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.NotificationApiReadNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.NotificationApiReadNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 notificationIds = 1;
 * @return {!Array<number>}
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.getNotificationidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.protobuf.NotificationApiReadNotificationsRequest} returns this
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.setNotificationidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.NotificationApiReadNotificationsRequest} returns this
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.addNotificationids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.NotificationApiReadNotificationsRequest} returns this
 */
proto.protobuf.NotificationApiReadNotificationsRequest.prototype.clearNotificationidsList = function() {
  return this.setNotificationidsList([]);
};


/**
 * @enum {number}
 */
proto.protobuf.WebsocketMessageType = {
  PING_MESSAGE: 0,
  PONG_MESSAGE: 1,
  PUSH_NOTIFICATION_MESSAGE: 2,
  ERROR_MESSAGE: 3
};

/**
 * @enum {number}
 */
proto.protobuf.ScheduleType = {
  EMPTY_LEG: 0,
  ORDER_REQUEST: 1,
  CUSTOM: 2
};

goog.object.extend(exports, proto.protobuf);
