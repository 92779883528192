import React from 'react';
import styled from "styled-components";
import {FONTS} from "../../../../utils/constans/styles/fonts";
import teamImage from "../../../../media/images/team_image.jpg"
import {ReactComponent as BigButtonArrowIcon} from "../../../../media/icons/big_button_arrow.svg"
import {ReactComponent as BigButtonCircleIcon} from "../../../../media/icons/big_button_circle.svg"
import {useNavigate} from "react-router";
import {mediaScreenSizes, screenSizes} from "../../../../utils/constans/styles/screenSizes";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../config/acl/routes/Route";
import {defineMessages, useIntl} from "react-intl";
import {metricsGoals} from "../../../../utils/constans/metrics/goals";

const SectionTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 80px;
  line-height: 74px;
  color: #F7F3F0;
  max-width: 650px;
  transition: transform 0.8s ease;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    font-size: 60px;
    line-height: 56px;
    max-width: 504px;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 36px;
  }
`;

const SectionMobileButton = styled.div`
  background: #1890FF;
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  color: #F7F3F0;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0;
  border-radius: 22px;
  width: 100%;
  text-align: center;
  box-shadow: 0 12px 10px -5px rgba(0, 39, 76, 0.19);
`;

const SectionButton = styled.div`
  position: relative;
  width: 244px;
  height: 244px;
  margin-left: 140px;
  svg {
    :first-child {
      width: 244px;
      height: 244px;
      position: relative;
      transition: transform 0.8s ease;
    }
    
    :last-child {
      position: absolute;
      width: 272px;
      height: 54px;
      left: -140px;
      top: 50%;
      transform: translate(0, -100%);
      transition: transform 0.8s ease;
    }
  }

  @media (max-width: ${mediaScreenSizes.tablet}) {
    width: 132px;
    height: 132px;
    margin-left: 76px;
    
    svg {
      :first-child {
        width: 132px;
        height: 132px;
      }

      :last-child {
        width: 148px;
        height: 29px;
        left: -76px;
      }
    }
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 36px;
  }
`;

const TitleAndButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  column-gap: 16px;
  row-gap: 56px;
  flex-wrap: wrap;
  align-items: center;

  :hover {
    ${SectionButton} {
      svg {
        :first-child {
          transform: scale(1.2);
        }

        :last-child {
          transform: translate(20px, -100%);
        }
      }
    }
    
    ${SectionTitle} {
      transform: scale(1.05);
    }
  }

  @media (max-width: ${mediaScreenSizes.tablet}) {
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    margin-top: -30px;
  }
`;

const SectionImage = styled.img`
  position: relative;
  top: -56px;
  width: 100%;
  padding-bottom: 48px;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding-bottom: 28px;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    top: -65px;
    height: 330px;
    padding-bottom: 0;
    object-fit: cover;
  }
`;

const SectionContainer = styled.div`
  position: relative;
  max-width: 1260px;
  width: 100%;
  padding: 0 90px 106px 90px;
  box-sizing: content-box;

  @media (max-width: ${mediaScreenSizes.tablet}) {
    padding: 0 27px 106px 27px;
  }
  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 0 23px 71px 23px;
  }
`;

const HomeFifthSectionStyled = styled.div`
  display: flex;
  justify-content: center;
  background: #00529D;
`;


const HomeFifthSectionMessages = defineMessages({
  sectionTitle: {
    id: "homeFifthSection.sectionTitle",
    defaultMessage: "Sign up and join us now!"
  },
  register: {
    id: "homeFifthSection.register",
    defaultMessage: "Sign up"
  }
})

export const HomeFifthSection = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {width} = useWindowSize();

  const onClick = () => {
    metricsGoals.openRegisterPageAfterVisitHome();
    navigate(pathFromRoute(Route.register));
  }

  return (
    <HomeFifthSectionStyled>
      <SectionContainer>
        <SectionImage src={teamImage} alt={"Фото"} />
        <TitleAndButton onClick={() => width > screenSizes.mobile && onClick()}>
          <SectionTitle>{intl.formatMessage(HomeFifthSectionMessages.sectionTitle)}</SectionTitle>
          {width > screenSizes.mobile && <SectionButton>
            <BigButtonCircleIcon />
            <BigButtonArrowIcon />
          </SectionButton>}
          {width <= screenSizes.mobile && <SectionMobileButton onClick={onClick}>
            {intl.formatMessage(HomeFifthSectionMessages.register)}
          </SectionMobileButton>}
        </TitleAndButton>
      </SectionContainer>
    </HomeFifthSectionStyled>
  );
};
