import styled from "styled-components";
import React, {FC, ReactNode} from "react";

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const ElementHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ElementTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

const ElementChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CharterRouteDrawerElement: FC<{title: string, extra?: ReactNode, children?: ReactNode, [key: string]: any}> = ({title, extra, children, ...props}) => {
  return <ElementWrapper {...props}>
    <ElementHeader>
      <ElementTitle>{title}</ElementTitle>
      {extra}
    </ElementHeader>
    {children && <ElementChildrenWrapper>{children}</ElementChildrenWrapper>}
  </ElementWrapper>
}