import styled from "styled-components";
import {Collapse} from "antd";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const {Panel} = Collapse;

export const CustomPanel = styled(Panel)<{noPaddings?: boolean; background?: string}>`
  border-radius: 0 0 10px 10px;
  
  .ant-collapse-content-box {
    padding: ${props => props.noPaddings ? "0" : "24px 24px 40px 24px"};
  }

  .ant-collapse-header {
    align-items: center !important;
    color: #000000 !important;
    opacity: ${props => props.collapsible === "disabled" ? 0.5 : 1};
  }

  .ant-collapse-content, .ant-collapse-content-active {
    background: ${props => props.background ?? "#FAFAFA"} !important;
  }
  
  .ant-collapse-expand-icon {
    display: ${props => props.collapsible === "disabled" ? "none" : "block"};
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    .ant-collapse-header {
      flex-direction: column;  
    }
    
    .ant-collapse-expand-icon {
      svg {
        transform: none !important;
        top: 20px !important;
      }
    }
    
    .ant-collapse-extra {
      margin-top: 8px;
      display: flex;
      width: 100%;
    }
    
    .ant-collapse-content-box {
      padding: ${props => props.noPaddings ? 0 : "24px 8px"};
    }
  }
`;