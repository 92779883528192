import React from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router";
import styled from "styled-components";
import {pathFromRoute} from "../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../config/acl/routes/Route";
import {defineMessages, useIntl} from "react-intl";

const NotFoundPageStyled = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;


const NotFoundPageMessages = defineMessages({
  title: {
    id: "notFoundPage.title",
    defaultMessage: "Unfortunately, the requested page doesn't exist.",
  },
  toHome: {
    id: "notFoundPage.toHome",
    defaultMessage: "Homepage",
  },
})

export const NotFoundPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <NotFoundPageStyled>
      <Result
        status="404"
        title="404"
        subTitle={intl.formatMessage(NotFoundPageMessages.title)}
        extra={<Button type="primary" onClick={() => navigate(pathFromRoute(Route.home))}>
          {intl.formatMessage(NotFoundPageMessages.toHome)}
        </Button>}
      />
    </NotFoundPageStyled>
  );
};

