import {
  IChartererEmptyLegFromOrder, IChartererOrder,
  IChartererOrderRoute,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import React, {FC} from "react";
import {
  FlightDrawerFooterContent,
  FlightDrawerFooterContentWrapper,
  FlightDrawerFooterPrice,
  FlightDrawerFooterPriceLabel,
  FlightDrawerFooterPriceValue,
  FlightDrawerFooterStatus,
  FlightDrawerFooterStatusAndAction
} from "./FlightDrawerFooterStyledComponents";
import {
  EmptyLegOrderRouteStatusEnum, useEmptyLegOrderRouteStatusName,
} from "../../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {CustomLinkButton} from "../../../UI/Buttons/CustomLinkButton";
import {ReactComponent as TrashIcon} from "../../../../media/icons/delete.svg";
import {ReactComponent as ReturnIcon} from "../../../../media/icons/return.svg";
import {useAppDispatch} from "../../../../store/store";
import {
  changeEmptyLegOrderRouteWithdraw
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";


const FlightDrawerOrderRouteEmptyLegFooterMessages = defineMessages({
  price: {
    id: "flightDrawerOrderRouteEmptyLegFooter.price",
    defaultMessage: "Price",
  }
})

interface IFlightDrawerOrderRouteEmptyLegFooter {
  order: IChartererOrder;
  emptyLeg: IChartererEmptyLegFromOrder;
}

export const FlightDrawerOrderRouteEmptyLegFooter: FC<IFlightDrawerOrderRouteEmptyLegFooter> = ({
                                                                                                  order,
                                                                                                  emptyLeg,
                                                                                                }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const emptyLegOrderRouteStatusName = useEmptyLegOrderRouteStatusName(emptyLeg.status);

  const onEmptyLegOrderRouteStatusChange = (status: EmptyLegOrderRouteStatusEnum) => {
    dispatch(changeEmptyLegOrderRouteWithdraw({
      orderRouteId: emptyLeg.orderRouteId,
      emptyLegId: emptyLeg.emptyLegId,
      emptyLegOrderRouteStatus: status,
    }))
  }

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      <FlightDrawerFooterPrice>
        <FlightDrawerFooterPriceLabel>{intl.formatMessage(FlightDrawerOrderRouteEmptyLegFooterMessages.price)}</FlightDrawerFooterPriceLabel>
        <FlightDrawerFooterPriceValue>{emptyLeg.price} {emptyLeg.currency.name}</FlightDrawerFooterPriceValue>
      </FlightDrawerFooterPrice>

      <FlightDrawerFooterStatusAndAction>
        {!order.isArchived && [EmptyLegOrderRouteStatusEnum.ACTIVE, EmptyLegOrderRouteStatusEnum.APPROVED].includes(emptyLeg.status) &&
          <CustomLinkButton onClick={() => onEmptyLegOrderRouteStatusChange(EmptyLegOrderRouteStatusEnum.WITHDRAWN)}
                            icon={<TrashIcon/>}>{intl.formatMessage(CommonButtonMessages.withdraw)}</CustomLinkButton>}

        {!order.isArchived && [EmptyLegOrderRouteStatusEnum.WITHDRAWN].includes(emptyLeg.status) &&
          <CustomLinkButton onClick={() => onEmptyLegOrderRouteStatusChange(EmptyLegOrderRouteStatusEnum.ACTIVE)}
                            icon={<ReturnIcon/>}>{intl.formatMessage(CommonButtonMessages.return)}</CustomLinkButton>}

        <FlightDrawerFooterStatus status={emptyLeg.status}>
          {emptyLegOrderRouteStatusName}
        </FlightDrawerFooterStatus>
      </FlightDrawerFooterStatusAndAction>
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
};
