import React, {FC, useEffect, useState} from 'react';
import styled from "styled-components";
import {CustomCollapse} from "../../../../../components/UI/AntCustom/CustomCollapse";
import {API} from "../../../../../API/API";
import {
  FlyApiFlyCostRequest,
  PlaneCalculatorApiPlaneCalculatorSettingListRequest, PlaneCalculatorSettingEntity, PlaneEntity
} from "../../../../../proto/generated/api_entities_pb";
import {Logger} from "../../../../../utils/logger/Logger";
import {getErrorMessage} from "../../../../../utils/errorHelpers/getCommonErrorMessage";
import {
  mapPlaneCalculatorSettingEntityListToIFlyHourCalculatorCategoryList
} from "../../../../../store/reducers/fleetReducer/fleetMappers";
import {
  IFlyHourCalculatorSettingType
} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {CustomPanel} from "../../../../../components/UI/AntCustom/CustomPanel";
import {defineMessages, useIntl} from "react-intl";
import {
  FlyHourPriceCalculatorSettingTypeEnum,
  FlyHourPriceDefaultCategoryEnum, FlyHourPriceDefaultSettingTypeEnum, FlyHourPriceDefaultSubcategoryEnum
} from "../../Fleet/components/flyHourPriceCalculator/types/flyHourPriceSettingEnums";
import {Form, Spin, Tooltip} from "antd";
import {FlyHourPriceMessages} from "../../../../../intl/commonMessages/FlyHourPriceMessages";
import {CustomSwitch} from "../../../../../components/UI/AntCustom/CustomSwitch";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import {ICharterRespondRouteItem} from "./MakeOfferDrawer";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";


const CharterRoutePriceLoaderWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CharterRoutePriceNoCoefficientsWrapper = styled.div`
  padding: 16px;
  display: flex;
`;

const CharterRoutePriceCoefficientsWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 16px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 14px;
`;

const SwitchLabel = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  overflow: hidden;

  div {
    :nth-child(1) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000000;

    }

    :nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #808080;
      white-space: nowrap;
    }
  }
`;

const CharterRoutePriceCoefficientsMessages = defineMessages({
  apply: {
    id: "charterRoutePriceCoefficients.apply",
    defaultMessage: "Apply multipliers",
  },
  noCoefficients: {
    id: "charterRoutePriceCoefficients.noCoefficients",
    defaultMessage: "Fill in multiplication values in the flight hour cost calculator for the selected aircraft to apply proper multipliers.",
  },
})

const CharterRoutePriceCoefficientsPanelKey = "charterRoutePriceCoefficientsPanelKey";


interface ICharterRoutePriceCoefficients {
  calculationApplied: boolean;
  disabled: boolean;
}

export const CharterRoutePriceCoefficients: FC<ICharterRoutePriceCoefficients> = ({
                                                                                        calculationApplied,
                                                                                        disabled,
                                                                                      }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const planeId: number | undefined = Form.useWatch(['planeId'], form);
  const routes: ICharterRespondRouteItem[] = Form.useWatch(['routes'], form);
  const [settingTypes, setSettingTypes] = useState<IFlyHourCalculatorSettingType[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(planeId === undefined);
  const [activeSettings, setActiveSettings] = useState<{ id: number, type: FlyHourPriceCalculatorSettingTypeEnum, value: number }[]>([]);
  const [currencyNameForView, setCurrencyNameForView] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (planeId) {
      setIsDisabled(false);
      (async () => {
        try {
          setIsLoading(true);

          const res = await API.planeCalculatorSettingList(
            new PlaneCalculatorApiPlaneCalculatorSettingListRequest()
              .setPlaneidsList([planeId])
              .setCategorynamesList([FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT])
          );

          const settings = res.getPlanecalculatorsettingsList();
          const mappedSettings = mapPlaneCalculatorSettingEntityListToIFlyHourCalculatorCategoryList(settings)
            .find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT)?.subcategories
            .find(sc => sc.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_COEFFICIENT_DEFAULT)?.settingTypes;

          if (!mappedSettings)
            Logger.error("No coefficient settings related to chosen plane found");

          setSettingTypes(mappedSettings ?? []);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          Logger.error(getErrorMessage(e));
        }
      })()
    } else {
      setIsDisabled(true);
    }
  }, [planeId])

  useEffect(() => {
    if (calculationApplied)
      (async () => setNewPrice(activeSettings))();
  }, [calculationApplied])


  const setNewPrice = async (settings: { id: number, type: FlyHourPriceCalculatorSettingTypeEnum, value: number }[]) => {
    try {
      const totalDuration = routes.reduce((acc, cur) => {
        return acc + cur.flyHours * 60 + cur.flyMinutes
      }, 0)

      if (planeId && totalDuration) {
        const res = await API.getFlyCost(
          new FlyApiFlyCostRequest()
            .setPlaneentity(new PlaneEntity().setPlaneid(planeId))
            .setFlyduration(totalDuration)
            .setPlanecalculatorsettingsList(settings.map(s => {
              return new PlaneCalculatorSettingEntity()
                .setPlanecalculatorsettingid(s.id)
                .setValue(s.value.toString())
                .setType(s.type)
            }))
        );

        const price = res.getCost()
        setCurrencyNameForView(res.getCurrency()?.getName() ?? "")
        form.setFieldsValue({'price': price});
      } else {
        Logger.error("No plane or fly duration given for calculation")
      }
    } catch (e) {
      Logger.error(getErrorMessage(e));
    }
  }

  const onCoefficientStateChange = async (isActive: boolean, settingType: IFlyHourCalculatorSettingType) => {
    let newSettings: { id: number, type: FlyHourPriceCalculatorSettingTypeEnum, value: number }[];
    if (isActive)
      newSettings = [...activeSettings, {
        id: settingType.setting.id!,
        type: settingType.type,
        value: settingType.setting.value
      }]
    else
      newSettings = activeSettings.filter(s => s.id !== settingType.setting.id);

    await setNewPrice(newSettings);
    setActiveSettings(newSettings);
  }

  return (
    <CustomCollapse
      expandIconPosition={'end'}
      collapsible={isDisabled ? "disabled" : undefined}
      activeKey={isDisabled ? [] : [CharterRoutePriceCoefficientsPanelKey]}
      borderRadius={"all"}
      style={{background: "#fff"}}
    >
      <CustomPanel
        key={CharterRoutePriceCoefficientsPanelKey}
        header={intl.formatMessage(CharterRoutePriceCoefficientsMessages.apply)}
        noPaddings={true}
      >
        {isLoading && <CharterRoutePriceLoaderWrapper>
          <Spin/>
        </CharterRoutePriceLoaderWrapper>}

        {!isLoading && settingTypes.length === 0 && <CharterRoutePriceNoCoefficientsWrapper>
          {intl.formatMessage(CharterRoutePriceCoefficientsMessages.noCoefficients)}
        </CharterRoutePriceNoCoefficientsWrapper>}

        {!isLoading && settingTypes.length > 0 && <CharterRoutePriceCoefficientsWrapper>
          {settingTypes.map(st => {
            const settingTitle = FlyHourPriceMessages.hasOwnProperty(st.keyName)
              //@ts-ignore TODO fix ts error
              ? intl.formatMessage(FlyHourPriceMessages[st.keyName as keyof typeof FlyHourPriceDefaultSettingTypeEnum])
              : st.label

            return <SwitchWrapper key={st.id}>
              <CustomSwitch checked={activeSettings.some(aSetting => aSetting.id === st.setting.id)} onChange={(isActive) => onCoefficientStateChange(isActive, st)} disabled={disabled}/>
              <SwitchLabel>
                <Tooltip placement={"top"} title={settingTitle}>
                  <div>
                    {settingTitle}
                  </div>
                </Tooltip>
                <div>{st.setting.value} {st.type === FlyHourPriceCalculatorSettingTypeEnum.PERCENT ? "%" : currencyNameForView}</div>
              </SwitchLabel>
            </SwitchWrapper>
          })}
        </CharterRoutePriceCoefficientsWrapper>}

      </CustomPanel>
    </CustomCollapse>
  );
};
