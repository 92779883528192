import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {WsActionType} from "../../middlewares/websocket/WsActionType";
import {setIsConnected, setIsReconnecting} from "../../reducers/websocketReducer/websocketReducer";
import {IWebsocketLoggerData, Logger} from "../../../utils/logger/Logger";
import {WebsocketController} from "../../middlewares/websocket/WebsocketController";
import {
  CommunicationWebsocketMessage, ResultResponse,
  WebsocketMessageType, WebsocketPingMessage,
} from "../../../proto/generated/api_entities_pb";
import {store} from "../../store";
import {PayloadAction} from "@reduxjs/toolkit";
import {emitter} from "../../../utils/emitter/EventEmitter";
import {WEBSOCKET_LOGS} from "../../../config/loggerConfig";

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));

//region ***CONNECT / DISCONNECT / RECONNECT***
function* socketConnectedSaga() {
  yield put({type: setIsConnected.type, payload: true});

  yield fork(sendPingSaga);
}


function* socketDisconnectedSaga() {
  yield put({type: setIsConnected.type, payload: false});
  const isReconnecting: boolean = yield select(s => s.websocket.isReconnecting)
  emitter.removeAllListeners();

  if (!isReconnecting)
    yield fork(autoReconnectSaga);
}


function* autoReconnectSaga() {
  waitingForServerPong = false

  yield put({type: setIsReconnecting.type, payload: true});

  WEBSOCKET_LOGS && Logger.info('Automatic reconnect will be after 10s...')

  // waiting 10 sec
  yield call(delay, 10000)

  const isConnected: boolean = yield select(s => s.websocket.isConnected)

  // if user still not connected (after 10s), try auto-reconnect
  if (!isConnected) {
    // tell that now we can do auto reconnect again (we are not reconnecting already)
    yield put({type: setIsReconnecting.type, payload: false});
    yield put({type: WsActionType.WS_AUTO_RECONNECT});
  }
}

//endregion


//region *** CLIENT PING - SERVER PONG ***
let waitingForServerPong: boolean = false;

function* sendPingSaga() {
  WebsocketController.sendRequest(WebsocketMessageType.PING_MESSAGE, new WebsocketPingMessage())
    .then(() => {
      waitingForServerPong = false
      delay(3000).then(() => {
        store.dispatch({type: WsActionType.WS_PING})
      })
    })
    .catch()

  waitingForServerPong = true;

  yield call(delay, 5000);

  if (waitingForServerPong) {
    yield fork(socketDisconnectedSaga)
  }
}

//endregion


//region ***HANDLE RECEIVED MESSAGE***
function* gotMessageSaga({payload}: PayloadAction<any>) {
  try {
    const bytesArray = new Uint8Array(payload);

    const coreDataMessage = CommunicationWebsocketMessage.deserializeBinary(bytesArray);

    switch (coreDataMessage.getMessagetype()) {
      case WebsocketMessageType.PING_MESSAGE:
        const response = new ResultResponse()
          .setIssuccessful(true);
        WebsocketController.sendResponse(
          WebsocketMessageType.PONG_MESSAGE,
          coreDataMessage.getMessageid(), response
        );
        break;
      // case WebsocketMessageType.RECEIVE_MESSAGE:
      //   const chatMessageResponse = WebsocketReceiveMessageRequest.deserializeBinary(coreDataMessage.getData_asU8());
      //   yield put(receiveMessage({
      //     message: chatMessageResponse,
      //   }))
      //   WebsocketController.sendResponse(WebsocketMessageType.RECEIVE_MESSAGE, coreDataMessage.getMessageid(), new ResultResponse().setIssuccessful(true));
      //   break;
      default:
        emitter.emit(coreDataMessage.getMessageid(), coreDataMessage);
        break;
    }

    // Received data object for logs
    const loggerData: IWebsocketLoggerData = {
      messageId: coreDataMessage.getMessageid(),
      messageType: WebsocketMessageType[coreDataMessage.getMessagetype()],
      data: coreDataMessage.getData(),
    }

    // Log received data
    const color: string = coreDataMessage.getMessagetype() === WebsocketMessageType.ERROR_MESSAGE ? "red" : "green";
    WEBSOCKET_LOGS && Logger.log(`[RECEIVED] ${WebsocketMessageType[coreDataMessage.getMessagetype() as WebsocketMessageType]}`, loggerData, color);
  } catch
    (e) {
    // @ts-ignore
    console.log(e.message)
  }
}

//endregion


export function* websocketSaga() {
  yield all([
    takeEvery(WsActionType.WS_CONNECTED, socketConnectedSaga),
    takeEvery(WsActionType.WS_DISCONNECTED, socketDisconnectedSaga),
    takeEvery(WsActionType.WS_MESSAGE, gotMessageSaga),
    takeEvery(WsActionType.WS_PING, sendPingSaga),
  ]);
}
