import React, {FC, useState} from 'react';
import styled from "styled-components";
import noPhotoImage from "../../../../../media/images/no_photo.jpg";
import {PlaneEditDrawer} from "./PlaneEditDrawer";
import {IPlane} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {Route} from "../../../../../config/acl/routes/Route";
import {pathFromRoute} from "../../../../../utils/helpers/acl/pathFromRoute";
import {DisplayAirport} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CustomLinkButton} from "../../../../../components/UI/Buttons/CustomLinkButton";
import {ReactComponent as CalendarIcon} from "../../../../../media/icons/calendar.svg";
import {ReactComponent as CalendarBlackIcon} from "../../../../../media/icons/calendar_black.svg";
import {ReactComponent as PlaneIcon} from "../../../../../media/icons/plane.svg";
import {ReactComponent as DocumentIcon} from "../../../../../media/icons/document.svg";
import {DetailItem} from "../../../../../components/businessCommon/detailItem/DetailItem";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {Col, Row} from "antd";
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppSelector} from "../../../../../store/store";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const PlaneCardStyled = styled.div`
  background: #FAFAFA;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  font-size: 14px;
`;

const CardTop = styled.div`
  padding: 16px 16px 24px 16px;
  display: flex;
  column-gap: 9px;
`;

const CardImage = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 100%;
`;

const CardInfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CardBottom = styled.div`
  padding: 0 16px 32px 16px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 0 16px 24px 16px;
  }
`;

const CardBottomButtons = styled.div`
  margin-top: 24px;
  display: flex;
  column-gap: 8px;

  button {
    flex-grow: 1;
  }
`;


const PlaneCardMessages = defineMessages({
  planeSchedule: {
    id: "planeCard.planeSchedule",
    defaultMessage: "Schedule"
  },
  toPlane: {
    id: "planeCard.toPlane",
    defaultMessage: "To plane"
  }
})

interface IPlaneCard {
  plane: IPlane;
}

export const PlaneCard: FC<IPlaneCard> = ({plane}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const [isPlaneEditDrawerOpen, setIsPlaneEditDrawerOpen] = useState(false);
  const cardImage = plane.imageFiles.find(x => x !== undefined)?.url;

  const openPlaneScheduleInNewTab = () => {
    window.open(`${pathFromRoute(Route.schedule)}?planeId=${plane.id}&planeRegNumber=${plane.registrationNumber}`, "_blank");
  }

  return (
    <PlaneCardStyled>
      <CardTop>
        <CardImage src={cardImage || noPhotoImage} alt={"Plane photo"}/>
        <CardInfoBlock>
          <div><b>{plane.planeType.name}</b></div>
          <div>{plane.planeType.planeCategory.name}</div>
        </CardInfoBlock>

        {!isMobile && <CustomLinkButton
          icon={<CalendarIcon/>}
          onClick={() => openPlaneScheduleInNewTab()}
          color={"#448EF7"}
          style={{flexGrow: 1, justifyContent: "flex-end"}}
        >
          {intl.formatMessage(PlaneCardMessages.planeSchedule)}
        </CustomLinkButton>}
      </CardTop>

      <CardBottom>
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.airport)}
                        value={DisplayAirport(plane.baseAirport)} icon={<PlaneIcon/>} size={"small"}
                        background={"#FAFAFA"}/>
          </Col>

          <Col span={12}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.registrationNumber)}
                        value={plane.registrationNumber} icon={<DocumentIcon/>} size={"small"} background={"#FAFAFA"}/>
          </Col>

          <Col span={12}>
            <DetailItem label={intl.formatMessage(CommonDetailsMessages.manufactureYear)}
                        value={formatDate(plane.manufactureDate, offset, "year")} icon={<CalendarBlackIcon/>}
                        size={"small"} background={"#FAFAFA"}/>
          </Col>
        </Row>
        <CardBottomButtons>
          {isMobile && <CustomButton type={"primary"} ghost={true}
                                     onClick={() => openPlaneScheduleInNewTab()}>{intl.formatMessage(PlaneCardMessages.planeSchedule)}</CustomButton>}
          <CustomButton type={"primary"}
                        onClick={() => setIsPlaneEditDrawerOpen(true)}>{intl.formatMessage(PlaneCardMessages.toPlane)}</CustomButton>
        </CardBottomButtons>
      </CardBottom>

      {isPlaneEditDrawerOpen &&
        <PlaneEditDrawer isOpen={isPlaneEditDrawerOpen} setIsOpen={setIsPlaneEditDrawerOpen} plane={plane}/>}
    </PlaneCardStyled>
  );
};
