import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CommunicationWebsocketMessage} from "../../../proto/generated/api_entities_pb";

export interface IRemoveRequestPayload {
  id: number,
}

export interface ISetSessionIdPayload {
  sessionId: string,
}

export interface IWebsocketInitialState {
  isConnected: boolean;
  isPinging: boolean;
  wasDisconnected: boolean;
  isReconnecting: boolean;
  isManualReconnecting: boolean;
  requests: CommunicationWebsocketMessage[];
}

const websocketInitialState: IWebsocketInitialState = {
  isConnected: false,
  isPinging: false,
  isReconnecting: false,
  wasDisconnected: false,
  isManualReconnecting: false,
  requests: []
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: websocketInitialState,
  reducers: {
    setIsConnected(state, {payload}: PayloadAction<boolean>) {
      state.isConnected = payload;
    },
    setIsPinging(state, {payload}: PayloadAction<boolean>) {
      state.isPinging = payload;
    },
    setIsReconnecting(state, {payload}: PayloadAction<boolean>) {
      state.isReconnecting = payload;
    },
    addRequest(state, {payload}: PayloadAction<CommunicationWebsocketMessage>) {
      state.requests.push(payload);
    },
    removeRequest(state, {payload}: PayloadAction<IRemoveRequestPayload>) {
      state.requests = state.requests.filter((req: CommunicationWebsocketMessage) => req.getMessageid() !== payload.id);
    },
  },
});

export const {
  setIsConnected,
  setIsReconnecting,
  addRequest,
  removeRequest,
} = websocketSlice.actions;

export default websocketSlice.reducer;
