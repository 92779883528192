import React from 'react';
import {Button} from "antd";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {ReactComponent as Logo} from '../../../../media/icons/logo.svg';
import {useNavigate} from "react-router";
import {Route} from "../../../../config/acl/routes/Route";
import {pathFromRoute} from "../../../../utils/helpers/acl/pathFromRoute";
import {headerSharedLinks} from "../headerSharedLinks";
import {useIntl} from "react-intl";

const HeaderDesktopLeftStyled = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;

  a {
    display: flex;
  }
`;



export const HeaderDesktopLeft = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <HeaderDesktopLeftStyled>
      <Link to={pathFromRoute(Route.home)}>
        <Logo/>
      </Link>
      {headerSharedLinks.map(el =>
        <Button key={el.path} type={'text'} size={'middle'} onClick={() => navigate(el.path)} >
          <Link to={el.path}>{intl.formatMessage(el.title)} </Link>
        </Button>)
      }
    </HeaderDesktopLeftStyled>
  );
};

