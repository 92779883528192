import React, {FC} from 'react';
import {Col, FormListFieldData, Row} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
import {CustomDatePicker} from '../../../../../components/UI/AntCustom/CustomDatePicker';
import {fetchAirports} from "../../../../../utils/helpers/selectDataFetchers/fetchAirports";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import styled from "styled-components";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const TripItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const TripItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

const TripItemSeparator = styled.div`
  width: 100%;
  height: 0;
  border: 1px dashed #D9D9D9;
  margin: 8px 0 16px 0;
`;


const TripItemMessages = defineMessages({
  title: {
    id: "tripItem.title",
    defaultMessage: "Flight",
  },
})

interface TripItemProps {
  field?: FormListFieldData;
  onRemoveClick?: () => void;
  routeNumber: number;
}

export const TripItem: FC<TripItemProps> = ({field, onRemoveClick, routeNumber}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  return (
    <>
      <TripItemWrapper>
        <TripItemTitle>
          <div>{intl.formatMessage(TripItemMessages.title)} {routeNumber}</div>
          {routeNumber > 2 && <MinusCircleOutlined onClick={onRemoveClick}/>}
        </TripItemTitle>

        <Row gutter={8}>
          <Col span={isMobile ? 24 : 7}>
            <CustomFieldInput
              {...field}
              label={intl.formatMessage(CommonFormMessages.fromAirportLabel)}
              name={field ? [field.name, 'airportFromId'] : 'airportFromId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
              <DebounceSelect
                fetchOptions={fetchAirports}
                placeholder={intl.formatMessage(CommonFormMessages.fromAirportPlaceholder)}
                showSearch={true}
              />
            </CustomFieldInput>
          </Col>
          <Col span={isMobile ? 24 : 7}>
            <CustomFieldInput
              {...field}
              label={intl.formatMessage(CommonFormMessages.toAirportLabel)}
              name={field ? [field.name, 'airportToId'] : 'airportToId'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
              <DebounceSelect
                fetchOptions={fetchAirports}
                placeholder={intl.formatMessage(CommonFormMessages.toAirportPlaceholder)}
                showSearch={true}
              />
            </CustomFieldInput>
          </Col>
          <Col span={isMobile ? 16 : 7}>
            <CustomFieldInput
              {...field}
              label={intl.formatMessage(CommonFormMessages.dateLabel)}
              name={field ? [field.name, 'departureDate'] : 'departureDate'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
              <CustomDatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm"
                placeholder={intl.formatMessage(CommonFormMessages.datePlaceholder)}
                disabledDate={isDateLessTanOneMinuteAgo}
                inputReadOnly={true} placement={"bottomLeft"}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>
          <Col span={isMobile ? 8 : 3}>
            <CustomFieldInput
              {...field}
              label={intl.formatMessage(CommonFormMessages.paxLabel)}
              name={field ? [field.name, 'pax'] : 'pax'}
              rules={[
                {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                {min: 1, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinPax)},
                {max: 100, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMaxPax)}
              ]}>
              <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                 style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
        </Row>
      </TripItemWrapper>

      <TripItemSeparator/>
    </>
  );
};
