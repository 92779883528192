import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const ChartererAdminMessage = defineMessage({
  id: "chartererAdmin.name",
  defaultMessage: "Administrator",
})

export const chartererAdmin: RoleDescriptor<Role.chartererAdmin> = {
  role: Role.chartererAdmin,
  name: ChartererAdminMessage,
  backendIdentifier: 'charterer_admin',
  homeRoute: Route.profile,
  navigation: {
    profile: [
      Route.requests,
      Route.requestsFromClients,
    ],
    sidebar: [
      Route.newTripRequest,
      Route.requests,
      Route.searchEmptyLegs,
      Route.profile,
      Route.requestsFromClients,
    ]
  },
}