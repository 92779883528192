import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Input, message, Modal, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {PrivacyPolicyModal} from "../../../../components/businessCommon/privacyPolicy/PrivacyPolicyModal";
import {CustomLink} from "../../../../components/UI/CustomLink/CustomLink";
import {PhoneRegex} from "../../../../utils/helpers/regexes/phoneRegex";
import {
  clearDoRequestCallback,
  doRequestCallback
} from "../../../../store/reducers/requestCallbackReducer/requestCallbackReducer";
import {IAgency} from "../../../../store/reducers/agencyReducer/agencyReducerTypes";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CustomModal} from "../../../../components/UI/AntCustom/CustomModal";
import {CustomFieldInput} from "../../../../components/UI/AntCustom/CustomFieldInput";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {DetailItem} from "../../../../components/businessCommon/detailItem/DetailItem";
import {CustomInputNumber} from "../../../../components/UI/AntCustom/CustomInputNumber";
import {onFinishTrimmed} from "../../../../utils/helpers/formHelpers/onFinishTrimmed";


const RequestCallbackModalMessages = defineMessages({
  success: {
    id: "requestCallbackModal.success",
    defaultMessage: "Your request has been sent. You will receive a call soon"
  },
  title: {
    id: "requestCallbackModal.title",
    defaultMessage: "Request callback"
  },
  brokerCompany: {
    id: "requestCallbackModal.brokerCompany",
    defaultMessage: "Broker company"
  },
  adminFullName: {
    id: "requestCallbackModal.adminFullName",
    defaultMessage: "Administrator's full name"
  },
  confidencePolicyConfirm1: {
    id: "requestCallbackModal.confidencePolicyConfirm1",
    defaultMessage: "By sending this request I accept the"
  },
  confidencePolicyConfirm2: {
    id: "requestCallbackModal.confidencePolicyConfirm2",
    defaultMessage: "Privacy Policy"
  },
  requestCallback: {
    id: "requestCallbackModal.requestCallback",
    defaultMessage: "Request callback"
  },
})

interface IConnectAgentFormData {
  firstName: string;
  lastName: string;
  middleName: string;
  phone?: number;
  message: string;
}

interface IRequestCallbackModal {
  agency: IAgency,
  isModalVisible: boolean,
  setIsModalVisible: Dispatch<SetStateAction<boolean>>,
}

export const RequestCallbackModal: FC<IRequestCallbackModal> = ({agency, isModalVisible, setIsModalVisible}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.requestCallback.doRequestCallback);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearDoRequestCallback());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal();
      if (errorMessage)
        message.error(errorMessage)
    }
  }, [errorMessage, isSuccess, isLoading]);

  const renderSuccessModal = () => {
    Modal.success({
      content: intl.formatMessage(RequestCallbackModalMessages.success),
      centered: true,
      onOk: () => setIsModalVisible(false)
    });
  }

  const onFinish = (values: IConnectAgentFormData) => {
    dispatch(doRequestCallback({
      agencyId: agency.companyId,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      phone: values.phone!,
      message: values.message,
    }))
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: IConnectAgentFormData = {
    firstName: '',
    lastName: '',
    middleName: '',
    phone: undefined,
    message: '',
  }

  return (
    <CustomModal
      title={intl.formatMessage(RequestCallbackModalMessages.title)}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      footer={false}
      centered={true}
    >
      <>
        <Row align={'middle'} gutter={[8, 8]} style={{paddingBottom: 24}}>
          <Col>
            <DetailItem label={intl.formatMessage(RequestCallbackModalMessages.brokerCompany)}
                        value={agency.companyName}/>
          </Col>
          <Col>
            <DetailItem label={intl.formatMessage(RequestCallbackModalMessages.adminFullName)}
                        value={`${agency.admin.lastName} ${agency.admin.firstName} ${agency.admin.middleName}`}/>
          </Col>
        </Row>
        <Form form={form}
              onFinish={(values) => onFinishTrimmed(values, onFinish)}
              onFinishFailed={onFinishFailed}
              layout={'vertical'}
              initialValues={initialValues}
        >
          <Row gutter={8}>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.lastNameLabel)}
                name={'lastName'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
              >
                <Input placeholder={intl.formatMessage(CommonFormMessages.lastNamePlaceholder)}/>
              </CustomFieldInput>
            </Col>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.nameLabel)}
                name={'firstName'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}>
                <Input placeholder={intl.formatMessage(CommonFormMessages.namePlaceholder)}/>
              </CustomFieldInput>
            </Col>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.middleNameLabel)}
                name={'middleName'}>
                <Input placeholder={intl.formatMessage(CommonFormMessages.middleNamePlaceholder)}/>
              </CustomFieldInput>
            </Col>
            <Col span={12}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.phoneLabel)}
                name={'phone'}
                rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}, {
                  pattern: PhoneRegex.pattern,
                  message: intl.formatMessage(CommonFormMessages.ruleIncorrectPhone)
                }]}>
                <CustomInputNumber addonBefore={"+"}
                                   placeholder={intl.formatMessage(CommonFormMessages.phonePlaceholder)}
                                   controls={false} style={{display: "flex", flexGrow: 1}}/>
              </CustomFieldInput>
            </Col>
            <Col span={24}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.commentLabel)}
                name={'message'}>
                <Input.TextArea style={{resize: "none"}}
                                placeholder={intl.formatMessage(CommonFormMessages.commentPlaceholder)}/>
              </CustomFieldInput>
            </Col>
            <Col>
              {intl.formatMessage(RequestCallbackModalMessages.confidencePolicyConfirm1)} <CustomLink
              onClick={() => setIsPolicyModalOpen(true)}>{intl.formatMessage(RequestCallbackModalMessages.confidencePolicyConfirm2)}</CustomLink>
            </Col>
          </Row>
          <CustomFieldInput style={{marginTop: 20}}>
            <CustomButton
              block
              type={'primary'}
              htmlType={'submit'}
            >
              {intl.formatMessage(RequestCallbackModalMessages.requestCallback)}
            </CustomButton>
          </CustomFieldInput>
        </Form>
        <PrivacyPolicyModal isModalVisible={isPolicyModalOpen} setIsModalVisible={setIsPolicyModalOpen}/>
      </>
    </CustomModal>
  );
};

