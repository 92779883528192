import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {defineMessages, useIntl} from "react-intl";
import styled from "styled-components";
import {Col, Form, FormListFieldData, Row} from "antd";
import {CalculateSwitcher} from "../../../../../components/businessCommon/calculateButton/CalculateSwitcher";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {VerticalDivider} from "../../../../../components/UI/VerticalDivider/VerticalDivider";
import {DisplayAirport} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {
  IFreighterOrderRoute
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {ReactComponent as RouteMiniArrowIcon} from "../../../../../media/icons/route_mini_arrow.svg";
import {ReactComponent as PaxIcon} from "../../../../../media/icons/pax.svg";
import {ReactComponent as FuelIcon} from "../../../../../media/icons/fuel.svg";
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {DisplayFlyDuration} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyDuration";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import {CalculateFlight} from "../../../../../utils/helpers/formHelpers/CalculateFlight";
import {EditTechnicalStopModal} from "./EditTechnicalStopModal";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div {
    :nth-child(1) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const RouteItemCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  & > div {
    :nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      row-gap: 7px;

      & > div {
        :nth-child(1) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #808080;
        }

        :nth-child(2) {
          display: flex;
          flex-direction: row;
          column-gap: 13px;

          & > div {
            display: flex;
            align-items: center;
            column-gap: 4px;
          }
        }
      }
    }

    :first-child, :last-child {

      & > div {
        color: #000000;
        letter-spacing: -0.01em;

        :nth-child(1) {
          font-weight: 400;
          font-size: 10px;
          line-height: 22px;
          opacity: 0.5;
        }

        :nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        :nth-child(3) {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          opacity: 0.5;
        }
      }
    }
  }
`;

const RouteItemCardHeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & > div {
    :nth-child(1) {
      display: flex;
      flex-direction: row;
      column-gap: 12px;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 8px;
      margin-bottom: 8px;

      & > div {
        :first-child, :last-child {
          flex-grow: 1;
          
          & > div {
            color: #000000;
            letter-spacing: -0.01em;

            :nth-child(1) {
              font-weight: 400;
              font-size: 10px;
              line-height: 22px;
              opacity: 0.5;
            }

            :nth-child(2) {
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
            }

            :nth-child(3) {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              opacity: 0.5;
            }
          }
        }
      }
    }

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      row-gap: 7px;

      & > div {
        :nth-child(1) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #808080;
        }

        :nth-child(2) {
          display: flex;
          flex-direction: row;
          column-gap: 13px;

          & > div {
            padding: 4px 10px;
            border: 1px solid #D9D9D9;
            border-radius: 3px;
            display: flex;
            align-items: center;
            column-gap: 4px;
          }
        }
      }
    }
  }
`;

const RouteItemCard = styled.div`
  padding: 8px 15px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  & > hr {
    border: none;
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    margin: 0;
  }

  & > div {
    ${VerticalDivider} {
      margin-top: auto;
      height: 56px;
    }
  }
`;

const TechnicalStopButton = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  color: #1890FF;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.7;
  }
`;


const MakeOfferRouteItemMessages = defineMessages({
  title: {
    id: "makeOfferRouteItem.title",
    defaultMessage: "Route",
  },
  from: {
    id: "makeOfferRouteItem.from",
    defaultMessage: "From",
  },
  to: {
    id: "makeOfferRouteItem.to",
    defaultMessage: "To",
  },
})


interface IMakeOfferRouteItem {
  field: FormListFieldData;
  routeNumber: number;
  turnOffPriceAuto: () => void;
  setIsPriceAutoBlocked: Dispatch<SetStateAction<boolean>>;
}

export const MakeOfferRouteItem: FC<IMakeOfferRouteItem> = ({
                                                              field,
                                                              routeNumber,
                                                              turnOffPriceAuto,
                                                              setIsPriceAutoBlocked
                                                            }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [totalDuration, setTotalDuration] = useState<{ hours: number, minutes: number }>({hours: 0, minutes: 0});
  const [isAutoFlyRouteCalcEnabled, setIsAutoFlyRouteEnabled] = useState<boolean>(false);
  const [isTechStopModalOpen, setIsTechStopModalOpen] = useState<boolean>(false);
  const [arrivalDate, setArrivalDate] = useState<Date | undefined>();
  const form = useFormInstance();
  const orderRoute: IFreighterOrderRoute | undefined = Form.useWatch(['routes', field.name, 'orderRoute'], form);
  const planeId: number | undefined = Form.useWatch(['planeId'], form);
  const flyHours: number | undefined = Form.useWatch(['routes', field.name, 'flyHours'], form);
  const flyMinutes: number | undefined = Form.useWatch(['routes', field.name, 'flyMinutes'], form);
  const distance: number | undefined = Form.useWatch(['routes', field.name, 'distance'], form);
  const techStopHours: number | undefined = Form.useWatch(['routes', field.name, 'techStopHours'], form);
  const techStopMinutes: number | undefined = Form.useWatch(['routes', field.name, 'techStopMinutes'], form);

  const turnOffAutoFlyRoute = () => setIsAutoFlyRouteEnabled(false);


  useEffect(() => {
    const totalMinutes = (flyHours ?? 0) * 60
      + (flyMinutes ?? 0)
      + (techStopHours ?? 0) * 60
      + (techStopMinutes ?? 0);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    setTotalDuration({hours, minutes});

    if (orderRoute)
      setArrivalDate(new Date(orderRoute.departureDate.getTime() + totalMinutes * 60000));
  }, [flyHours, flyMinutes, techStopHours, techStopMinutes])


  useEffect(() => {
    const total = (flyHours ?? 0) + (flyMinutes ?? 0);
    turnOffPriceAuto();
    setIsPriceAutoBlocked(total <= 0);
  }, [flyHours, flyMinutes, distance])


  const onCalculateFlight = async () => {
    if (isAutoFlyRouteCalcEnabled) {
      setIsAutoFlyRouteEnabled(false)
      return;
    }

    if (planeId && orderRoute) {
      const {
        hours,
        minutes,
        distance,
        techStopHours,
        techStopMinutes,
        isAutoCalculationApplied,
      } = await CalculateFlight(orderRoute.airportFrom.id, orderRoute.airportTo.id, planeId, intl)

      const fields = form.getFieldsValue();
      const {routes} = fields;
      Object.assign(routes[field.key], {
        flyHours: hours,
        flyMinutes: minutes,
        distance: distance,
        techStopHours: techStopHours,
        techStopMinutes: techStopMinutes,
      });
      form.setFieldsValue({routes})
      setIsAutoFlyRouteEnabled(isAutoCalculationApplied)
    }
  }

  const duration = DisplayFlyDuration(totalDuration.hours, totalDuration.minutes);

  const routeHeader = orderRoute && (!isMobile ? <RouteItemCardHeader>
      <div>
        <div>{intl.formatMessage(MakeOfferRouteItemMessages.from)}</div>
        <div>{DisplayAirport(orderRoute.airportFrom)}</div>
        <div>{formatDate(orderRoute.departureDate, orderRoute.airportFrom.timezone.offset, "datetime_short_year")}</div>
      </div>

      <VerticalDivider/>

      <div>
        <div>{duration}</div>
        <div>
          <div><PaxIcon/>{orderRoute.pax}</div>
          <TechnicalStopButton onClick={() => setIsTechStopModalOpen(true)}>
            <FuelIcon/>
            <div>{(!!techStopHours || !!techStopMinutes) ? "1" : "+"}</div>
          </TechnicalStopButton>
        </div>
      </div>

      <VerticalDivider/>

      <div>
        <div>{intl.formatMessage(MakeOfferRouteItemMessages.to)}</div>
        <div>{DisplayAirport(orderRoute.airportTo)}</div>
        <div>{arrivalDate && formatDate(arrivalDate, orderRoute.airportTo.timezone.offset, "datetime_short_year")}</div>
      </div>
    </RouteItemCardHeader>
    : <RouteItemCardHeaderMobile>
      <div>
        <div>
          <div>{intl.formatMessage(MakeOfferRouteItemMessages.from)}</div>
          <div>{DisplayAirport(orderRoute.airportFrom)}</div>
          <div>{formatDate(orderRoute.departureDate, orderRoute.airportFrom.timezone.offset, "datetime_short_year")}</div>
        </div>

        <RouteMiniArrowIcon />

        <div>
          <div>{intl.formatMessage(MakeOfferRouteItemMessages.to)}</div>
          <div>{DisplayAirport(orderRoute.airportTo)}</div>
          <div>{arrivalDate && formatDate(arrivalDate, orderRoute.airportTo.timezone.offset, "datetime_short_year")}</div>
        </div>
      </div>

      <div>
        <div>{duration}</div>
        <div>
          <div><PaxIcon/>{orderRoute.pax}</div>
          <TechnicalStopButton onClick={() => setIsTechStopModalOpen(true)}>
            <FuelIcon/>
            <div>{(!!techStopHours || !!techStopMinutes) ? "1" : "+"}</div>
          </TechnicalStopButton>
        </div>
      </div>
    </RouteItemCardHeaderMobile>);

  return (
    <Wrapper>
      <Title>
        <div>{intl.formatMessage(MakeOfferRouteItemMessages.title)} {routeNumber}</div>
        <CalculateSwitcher
          size={'small'}
          disabled={false}
          isAutoCalculationApplied={isAutoFlyRouteCalcEnabled}
          setIsAutoCalculationApplied={setIsAutoFlyRouteEnabled}
          onClick={onCalculateFlight}
        />
      </Title>

      <RouteItemCard>
        {routeHeader}

        <hr/>

        <div>
          <Row gutter={8} wrap={false}>
            <Col span={8}>
              <CustomFieldInput
                {...field}
                label={intl.formatMessage(CommonFormMessages.flyHoursLabel)}
                name={[field.name, 'flyHours']}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
                  {max: 99, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
                ]}
              >
                <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.hour)}
                                   maxLength={2}
                                   precision={0}
                                   onChange={turnOffAutoFlyRoute}/>
              </CustomFieldInput>
            </Col>

            <Col span={8}>
              <CustomFieldInput
                {...field}
                label={intl.formatMessage(CommonFormMessages.flyMinutesLabel)}
                name={[field.name, 'flyMinutes']}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
                  {max: 59, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
                ]}
              >
                <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.minute)}
                                   maxLength={2}
                                   precision={0}
                                   onChange={turnOffAutoFlyRoute}/>
              </CustomFieldInput>
            </Col>

            <Col span={8}>
              <CustomFieldInput
                {...field}
                label={intl.formatMessage(CommonFormMessages.distanceLabel)}
                name={[field.name, 'distance']}
                rules={[
                  {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
                  {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
                ]}
              >
                <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.km)}
                                   onChange={turnOffAutoFlyRoute}/>
              </CustomFieldInput>
            </Col>
          </Row>
        </div>
      </RouteItemCard>

      {isTechStopModalOpen &&
        <EditTechnicalStopModal isModalVisible={isTechStopModalOpen} setIsModalVisible={setIsTechStopModalOpen}
                                field={field}/>}
    </Wrapper>
  );
};

