import React, {useEffect, useState} from 'react';
import {CustomCard} from "../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../components/UI/PageWrapper/PageWrapper";
import {Typography} from "antd";
import {AgencyCard} from "./components/AgencyCard";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {Loader} from "../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../components/UI/AntCustom/CustomResult";
import {InitialPage} from "../../../utils/constans/pagination/pagination";
import {clearAgencyList, getAgencyList} from "../../../store/reducers/agencyReducer/agencyReducer";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../intl/commonMessages/CommonFormMessages";
import {CustomButton} from "../../../components/UI/AntCustom/CustomButton";
import styled from "styled-components";
import {CustomSearch} from "../../../components/UI/AntCustom/CustomSearch";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import ym from "react-yandex-metrika";
import {metricsGoals} from "../../../utils/constans/metrics/goals";

const AgencyList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  gap: 16px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  }
`;


const AgentsPageMessages = defineMessages({
  title: {
    id: "agentsPage.title",
    defaultMessage: "Broker list",
  },
  description: {
    id: "agentsPage.description",
    defaultMessage: "Submit a callback request to contact your broker.",
  }
})

export const AgentsPage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const {
    items,
    isLoading,
    totalCount
  } = useAppSelector(state => state.agency.itemsObject)
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);

  useEffect(() => {
    dispatch(getAgencyList({
      query: searchQuery,
      page: currentPage,
    }));

    metricsGoals.visitAgentsPage();

    return () => {
      dispatch(clearAgencyList());
    }
  }, [])

  const loadMore = () => {
    dispatch(getAgencyList({
      query: searchQuery,
      page: currentPage + 1,
    }));

    setCurrentPage(prev => prev + 1);
  }

  const search = () => {
    dispatch(getAgencyList({
      query: searchQuery,
      page: InitialPage,
    }));
    setCurrentPage(InitialPage)
  }

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(AgentsPageMessages.title)} width={'max'} isContainerCard={true}>
        <Typography>
          {intl.formatMessage(AgentsPageMessages.description)}
        </Typography>
        <CustomSearch
          placeholder={intl.formatMessage(CommonFormMessages.searchPlaceholder)}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onSearch={search}
        />

        {items.length === 0
          ? (isLoading ? <Loader/> : <CustomResult title={intl.formatMessage(CommonFormMessages.noData)}/>)
          : <AgencyList>
            {items.map(agency => <AgencyCard key={agency.companyId} agency={agency}/>)}
          </AgencyList>
        }

        {items.length > 0 && totalCount > items.length &&
          <CustomButton
            loading={isLoading}
            type={'primary'}
            onClick={loadMore}
          >
            {intl.formatMessage(CommonFormMessages.showMore)}
          </CustomButton>}
      </CustomCard>
    </PageWrapper>

  );
};

