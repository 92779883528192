import {FlyApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";
import {
  FlyApiFlyCostRequest,
  FlyApiFlyCostResponse,
  FlyApiFlyRouteDetailRequest,
  FlyApiFlyRouteDetailResponse
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";

const client = new FlyApiServiceClient(getProxyUrl(), null, null);

export class FlyApi {
  static async getFlyRouteDetail(req: FlyApiFlyRouteDetailRequest): Promise<FlyApiFlyRouteDetailResponse> {
    return new Promise((resolve, reject) => {
      API.call<FlyApiFlyRouteDetailRequest, FlyApiFlyRouteDetailResponse>(client, client.flyRouteDetail, req, resolve, reject)
    })
  }

  static async getFlyCost(req: FlyApiFlyCostRequest): Promise<FlyApiFlyCostResponse> {
    return new Promise((resolve, reject) => {
      API.call<FlyApiFlyCostRequest, FlyApiFlyCostResponse>(client, client.flyCost, req, resolve, reject)
    })
  }
}