import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {groupFreighter} from "../../../../../../config/acl/roles/groups";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {
  CustomLoadedFileWrapper,
  CustomLoadedFileWrapperTitle,
  CustomUpload
} from "../../../../../../components/UI/AntCustom/CustomUpload";
import {ReactComponent as PdfIcon} from "../../../../../../media/icons/pdf.svg";
import {onPdfFilePreview} from "../../../../../../utils/helpers/fileHelpers/onPdfFilePreview";
import Button from "antd/es/button/button";
import {RcFile, UploadFile} from "antd/es/upload";
import {CustomButton} from "../../../../../../components/UI/AntCustom/CustomButton";
import {FileAcceptTypes} from "../../../../../../utils/constans/files/fileAcceptTypes";
import {
  CompanyWarningStatusEnum,
  isCompanyOnLoadDocuments, isCompanyOnModeration,
  useCompanyWarningStatus
} from "../../hooks/useCompanyWarningStatus";
import {clearLoadCertificates, loadCertificates} from "../../../../../../store/reducers/companyReducer/companyReducer";
import {Role} from "../../../../../../config/acl/roles/Role";
import {renderSuccessModal} from "../../../../../../components/UI/StatusModals/renderSuccessModal";
import {message} from "antd";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../../intl/commonMessages/CommonButtonMessages";
import {mediaScreenSizes} from "../../../../../../utils/constans/styles/screenSizes";

const LicensesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

const LicensesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 25px;
  row-gap: 8px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
  }
`;

const WarningInfo = styled.div<{ color: string, status: CompanyWarningStatusEnum }>`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${props => props.color};
  margin: ${props => (isCompanyOnLoadDocuments(props.status) && "0 0 8px 0")
          || (isCompanyOnModeration(props.status) && "16px 0 0 0")};

  font-weight: ${props => (isCompanyOnLoadDocuments(props.status) && "500")
          || (isCompanyOnModeration(props.status) && "400")};
`;

const LoadDocumentsButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;


const ProfileCompanyLicensesMessages = defineMessages({
  successSend: {
    id: "profileCompanyLicenses.successSend",
    defaultMessage: "Documents have been successfully sent for moderation. Please, wait until your account is approved by the platform administrator.",
  },
  warningLoadDocs: {
    id: "profileCompanyLicenses.warningLoadDocs",
    defaultMessage: "Upload documents to pass moderation",
  },
  operatorCertificate: {
    id: "profileCompanyLicenses.operatorCertificate",
    defaultMessage: "AOC",
  },
  registrationCertificate: {
    id: "profileCompanyLicenses.registrationCertificate",
    defaultMessage: "Certificate of incorporation",
  },
  warningWaitApprove: {
    id: "profileCompanyLicenses.warningWaitApprove",
    defaultMessage: "Thank you. We will check your documents in 2 days.",
  },
  loadDocs: {
    id: "profileCompanyLicenses.loadDocs",
    defaultMessage: "Send documents for moderation",
  },
})

type ProfileFileType = "operator" | "registration";

export const ProfileCompanyLicenses = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {role} = useAppSelector(state => state.user.oneItemObject.item);
  const company = useAppSelector(state => state.company.oneItemObject.item);
  const [operatorCertificateFile, setOperatorCertificateFile] = useState<UploadFile | undefined>(company.operatorCard);
  const [registrationCertificateFile, setRegistrationCertificateFile] = useState<UploadFile | undefined>(company.registrationCertificate);
  const {companyWarningStatus, companyWarningStatusColor} = useCompanyWarningStatus();
  const {isLoading, isSuccess, errorMessage} = useAppSelector(state => state.company.loadCertificates);

  useEffect(() => {
    setOperatorCertificateFile(company.operatorCard);
    setRegistrationCertificateFile(company.registrationCertificate);

    return () => {
      dispatch(clearLoadCertificates());
    }
  }, [company.registrationCertificate, company.operatorCard])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess)
        renderSuccessModal(intl.formatMessage(ProfileCompanyLicensesMessages.successSend));
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])

  const showLoadDocumentsButton = isCompanyOnLoadDocuments(companyWarningStatus) &&
    ((role === Role.freighterAdminNotApproved && operatorCertificateFile && registrationCertificateFile)
      || (role === Role.chartererAdminNotApproved && registrationCertificateFile));

  const onFileLoad = (file: RcFile, type: ProfileFileType) => {
    if (type === "operator")
      setOperatorCertificateFile(file);
    else if (type === "registration")
      setRegistrationCertificateFile(file);
  }

  const onFileRemove = (type: ProfileFileType) => {
    if (type === "operator")
      setOperatorCertificateFile(undefined);
    else if (type === "registration")
      setRegistrationCertificateFile(undefined);
  }

  const onCertificatesUpload = () => {
    if (registrationCertificateFile) {
      dispatch(loadCertificates({
        company: company,
        registrationCertificate: registrationCertificateFile,
        operatorCertificate: operatorCertificateFile,
      }))
    }
  }

  return (
    <LicensesWrapper>
      {isCompanyOnLoadDocuments(companyWarningStatus) &&
        <WarningInfo color={companyWarningStatusColor} status={companyWarningStatus}>
          {intl.formatMessage(ProfileCompanyLicensesMessages.warningLoadDocs)}
        </WarningInfo>}

      <LicensesList>
        {groupFreighter.includes(role) &&
          <CustomLoadedFileWrapper style={{minWidth: 235}}>
            <CustomLoadedFileWrapperTitle>{intl.formatMessage(ProfileCompanyLicensesMessages.operatorCertificate)}</CustomLoadedFileWrapperTitle>
            <CustomUpload
              accept={FileAcceptTypes.DOCUMENT}
              beforeUpload={(file) => onFileLoad(file, "operator")}
              fileList={operatorCertificateFile ? [operatorCertificateFile] : []}
              maxCount={1}
              onPreview={onPdfFilePreview}
              onRemove={() => onFileRemove("operator")}
            >
              {isCompanyOnLoadDocuments(companyWarningStatus) &&
                <Button icon={<PdfIcon/>} type={"dashed"}>
                  <div>{operatorCertificateFile ? intl.formatMessage(CommonButtonMessages.replace) : intl.formatMessage(CommonButtonMessages.upload)}</div>
                </Button>}
            </CustomUpload>
          </CustomLoadedFileWrapper>
        }

        <CustomLoadedFileWrapper style={{minWidth: 235}}>
          <CustomLoadedFileWrapperTitle>{intl.formatMessage(ProfileCompanyLicensesMessages.registrationCertificate)}</CustomLoadedFileWrapperTitle>
          <CustomUpload
            accept={FileAcceptTypes.DOCUMENT}
            beforeUpload={(file) => onFileLoad(file, "registration")}
            fileList={registrationCertificateFile ? [registrationCertificateFile] : []}
            maxCount={1}
            onPreview={onPdfFilePreview}
            onRemove={() => onFileRemove("registration")}
          >
            {isCompanyOnLoadDocuments(companyWarningStatus) &&
              <CustomButton icon={<PdfIcon/>} type={"dashed"}>
                <div>{registrationCertificateFile ? intl.formatMessage(CommonButtonMessages.replace) : intl.formatMessage(CommonButtonMessages.upload)}</div>
              </CustomButton>}
          </CustomUpload>
        </CustomLoadedFileWrapper>
      </LicensesList>

      {isCompanyOnModeration(companyWarningStatus) &&
        <WarningInfo color={companyWarningStatusColor} status={companyWarningStatus}>
          {intl.formatMessage(ProfileCompanyLicensesMessages.warningWaitApprove)}
        </WarningInfo>}

      {showLoadDocumentsButton && <LoadDocumentsButtonWrapper>
        <CustomButton onClick={onCertificatesUpload} type={"primary"}>
          {intl.formatMessage(ProfileCompanyLicensesMessages.loadDocs)}
        </CustomButton>
      </LoadDocumentsButtonWrapper>}
    </LicensesWrapper>
  );
};
