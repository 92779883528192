import {DebounceSelectOptionProps} from "../../../components/UI/AntCustom/DebounceSelect";
import {EmptyRequest} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {IPlane, IPlaneShort} from "../../../store/reducers/fleetReducer/fleetReducerTypes";

export async function fetchOwnPlanes(search: string): Promise<DebounceSelectOptionProps[]> {
  const req = new EmptyRequest()

  const planeShortList = await API.getPlaneShortList(req);

  return planeShortList.getPlanesList().map(plane => {
    return {
      key: plane.getPlaneid(),
      label: plane.getRegistrationnumber(),
      value: plane.getPlaneid(),
    }
  })
}

export const getInitialOwnPlaneForSelect = (plane: IPlaneShort | IPlane): DebounceSelectOptionProps => {
  return {
    key: plane.id,
    value: plane.id,
    label: plane.registrationNumber,
  }
}
