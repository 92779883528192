import {PayloadAction} from "@reduxjs/toolkit";
import {
  AirportEntity, BookingApiGetAirportRequest, BookingApiGetAirportResponse,
  BookingApiOrderRequest, BookingApiOrderResponse, CompanyEntity,
  OrderEntity, OrderRequestEntity,
  OrderRouteEntity, PlaneCategoryEntity,
} from "../../../proto/generated/api_entities_pb";
import {
  multiLegRequest, multiLegRequestResult,
  oneWayRequest, oneWayRequestResult, roundTripRequest, roundTripRequestResult
} from "../../reducers/newRequestReducer/newRequestReducer";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {
  IMultiLegRequestPayload,
  IOneWayRequestPayload,
  IRoundTripRequestPayload, RequestTypeEnum
} from "../../reducers/newRequestReducer/newRequestReducerTypes";
import {metricsGoals} from "../../../utils/constans/metrics/goals";


function* oneWayRequestSaga({payload}: PayloadAction<IOneWayRequestPayload>) {
  const orderRoute: OrderRouteEntity = yield call(getOrderRouteEntity, {
    airportFromId: payload.airportFromId,
    airportToId: payload.airportToId,
    departureDate: payload.departureDate,
    pax: payload.pax,
    companionsAllowed: payload.companionsAllowed,
    planeCategoryIds: payload.planeCategoryIds,
    comment: payload.comment,
    companyIds: payload.companyIds,
  })

  const planeIdsCategoryEntity: PlaneCategoryEntity[] = payload.planeCategoryIds.map<PlaneCategoryEntity>(p => {
      return new PlaneCategoryEntity()
        .setPlanecategoryid(p)
    }
  )

  const orderRequests = payload.companyIds.map(id => new OrderRequestEntity().setFlycompany(new CompanyEntity().setCompanyid(id)));

  const req = new BookingApiOrderRequest()
    .setOrder(new OrderEntity()
      .setOrderroutesList([orderRoute])
      .setPlanecategoriesList(planeIdsCategoryEntity)
      .setCompanionsallowed(payload.companionsAllowed)
      .setType(RequestTypeEnum.ONE_WAY)
      .setComment(payload.comment)
      .setOrderrequestsList(orderRequests)
    )
    .setWelcomemessage(payload.comment);

  try {
    const res: BookingApiOrderResponse =
      yield call(API.orderCreate, req);

    if (res.getResultresponse()) {
      yield put(oneWayRequestResult({}));
      metricsGoals.orderCreated();
    } else {
      yield put(oneWayRequestResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage())
      }))
    }
  } catch {
  }
}


function* roundTripRequestSaga({payload}: PayloadAction<IRoundTripRequestPayload>) {
  const orderRouteThere: OrderRouteEntity = yield call(getOrderRouteEntity, {
    airportFromId: payload.airportFromId,
    airportToId: payload.airportToId,
    departureDate: payload.departureDateFrom,
    pax: payload.paxFrom,
    companionsAllowed: payload.companionsAllowed,
    planeCategoryIds: payload.planeCategoryIds,
    comment: payload.comment,
    companyIds: payload.companyIds,
  })

  //reverse airports because of round trip specifics
  const orderRouteBack: OrderRouteEntity = yield call(getOrderRouteEntity, {
    airportFromId: payload.airportToId,
    airportToId: payload.airportFromId,
    departureDate: payload.departureDateTo,
    pax: payload.paxTo,
    companionsAllowed: payload.companionsAllowed,
    planeCategoryIds: payload.planeCategoryIds,
    comment: payload.comment,
    companyIds: payload.companyIds,
  })

  const planeIdsCategoryEntity: PlaneCategoryEntity[] = payload.planeCategoryIds.map<PlaneCategoryEntity>(p => {
      return new PlaneCategoryEntity()
        .setPlanecategoryid(p)
    }
  )

  const orderRequests = payload.companyIds.map(id => new OrderRequestEntity().setFlycompany(new CompanyEntity().setCompanyid(id)));

  const req = new BookingApiOrderRequest()
    .setOrder(new OrderEntity()
      .setOrderroutesList([orderRouteThere, orderRouteBack])
      .setPlanecategoriesList(planeIdsCategoryEntity)
      .setCompanionsallowed(payload.companionsAllowed)
      .setType(RequestTypeEnum.ROUND_TRIP)
      .setComment(payload.comment)
      .setOrderrequestsList(orderRequests)
    )
    .setWelcomemessage(payload.comment);

  try {
    const res: BookingApiOrderResponse =
      yield call(API.orderCreate, req);

    if (res.getResultresponse()) {
      yield put(roundTripRequestResult({}));
      metricsGoals.orderCreated();
    } else {
      yield put(roundTripRequestResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage())
      }))
    }
  } catch {
  }
}


function* multiLegRequestSaga({payload}: PayloadAction<IMultiLegRequestPayload>) {
  const orderRoutes: OrderRouteEntity[] = yield all(payload.routes.map(route => call(getOrderRouteEntity, {
    airportFromId: route.airportFromId,
    airportToId: route.airportToId,
    departureDate: route.departureDate,
    pax: route.pax,
    companionsAllowed: payload.companionsAllowed,
    planeCategoryIds: payload.planeCategoryIds,
    comment: payload.comment,
    companyIds: payload.companyIds,
  })));

  const planeIdsCategoryEntity: PlaneCategoryEntity[] = payload.planeCategoryIds.map<PlaneCategoryEntity>(p => {
      return new PlaneCategoryEntity()
        .setPlanecategoryid(p)
    }
  )

  const orderRequests = payload.companyIds.map(id => new OrderRequestEntity().setFlycompany(new CompanyEntity().setCompanyid(id)));

  const req = new BookingApiOrderRequest()
    .setOrder(new OrderEntity()
      .setOrderroutesList(orderRoutes)
      .setPlanecategoriesList(planeIdsCategoryEntity)
      .setCompanionsallowed(payload.companionsAllowed)
      .setType(RequestTypeEnum.MULTI_LEG)
      .setComment(payload.comment)
      .setOrderrequestsList(orderRequests)
    )
    .setWelcomemessage(payload.comment);

  try {
    const res: BookingApiOrderResponse =
      yield call(API.orderCreate, req);

    if (res.getResultresponse()) {
      yield put(multiLegRequestResult({}));
      metricsGoals.orderCreated();
    } else {
      yield put(multiLegRequestResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage())
      }))
    }
  } catch {
  }
}


interface IGetOrderRouteEntity {
  airportFromId: number;
  airportToId: number;
  departureDate: Date;
  pax: number;
  planeCategoryIds: number[];
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

function* getOrderRouteEntity(orderRoute: IGetOrderRouteEntity) {
  const airportFromResponse: BookingApiGetAirportResponse = yield call(
    API.getAirportById,
    new BookingApiGetAirportRequest().setAirportid(orderRoute.airportFromId)
  );

  const airportFrom = airportFromResponse.getAirport();
  if (!airportFrom)
    throw new Error("No airport with given id found");

  return new OrderRouteEntity()
    .setPax(orderRoute.pax)
    .setAirportfrom(airportFrom)
    .setAirportto(
      new AirportEntity().setAirportid(orderRoute.airportToId)
    )
    .setDeparturedate(getLocalDateAsISOString(orderRoute.departureDate))
}

export function* newRequestSaga() {
  yield all([
    takeLatest(oneWayRequest.type, oneWayRequestSaga),
    takeLatest(roundTripRequest.type, roundTripRequestSaga),
    takeLatest(multiLegRequest.type, multiLegRequestSaga),
  ])
}

