import {defineMessages} from "react-intl";

export const CommonUnitMessages = defineMessages({
  km: {
    id: "app.units.km",
    defaultMessage: "km",
  },
  people: {
    id: "app.units.people",
    defaultMessage: "pers",
  },
  hour: {
    id: "app.units.hour",
    defaultMessage: "h",
  },
  minute: {
    id: "app.units.minute",
    defaultMessage: "min",
  },
  flyHours: {
    id: "app.units.flyHours",
    defaultMessage: "F/H",
  },

  //PREFIXES
  from: {
    id: "app.units.from",
    defaultMessage: "From",
  },
  to: {
    id: "app.units.to",
    defaultMessage: "Till",
  },
})