import React, {FC, ReactNode} from 'react';
import styled from "styled-components";
import {Collapse} from "antd";
import {CollapseProps} from "antd/es";
import {ReactComponent as ExpandIcon} from "../../../media/icons/expand.svg";
import {ReactComponent as CollapseIcon} from "../../../media/icons/collapse.svg";

const CollapseStyled = styled(Collapse)<{ borderRadius: 'top' | 'bottom' | 'all' | 'none', size: 'm' | 'l' }>`
  border-radius: ${props => (props.borderRadius === 'top' && "10px 10px 0 0")
          || (props.borderRadius === 'bottom' && "0 0 10px 10px")
          || (props.borderRadius === 'all' && "10px 10px 10px 10px")
          || (props.borderRadius === 'none' && "0")};

  .ant-collapse-item, .ant-collapse-content, .ant-collapse-content-active {
    border-radius: ${props => (props.borderRadius === 'top' && "0 !important")
            || (props.borderRadius === 'bottom' && "0 0 10px 10px !important")
            || (props.borderRadius === 'all' && "0 0 10px 10px !important")
            || (props.borderRadius === 'none' && "0 !important")};
  }

  .ant-collapse-content, .ant-collapse-content-active {
    background: #FAFAFA;
  }
  
  .ant-collapse-header {
    padding: ${props => (props.size === 'm' && "12px 40px 12px 16px !important") 
                     || (props.size === 'l' && "16px 40px 16px 28px !important")};
    
    font-weight: ${props => (props.size === 'm' && "400")
            || (props.size === 'l' && "600")};
  }
  
  .ant-collapse-header-text {
    width: 100%;
  }
`;

interface ICustomCollapse extends CollapseProps {
  borderRadius?: 'top' | 'bottom' | 'all' | 'none';
  size?: 'm' | 'l'
  children: ReactNode;
}

export const CustomCollapse: FC<ICustomCollapse> = ({children, borderRadius = 'bottom', size = 'm', ...props}) => {
  return (
    <CollapseStyled borderRadius={borderRadius} size={size} expandIconPosition={'end'}
                    expandIcon={(p => p.isActive ? <CollapseIcon/> : <ExpandIcon/>)} {...props}>
      {children}
    </CollapseStyled>
  );
};