import React, {useEffect, useState} from 'react';
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import ReactApexChart from "react-apexcharts";
import apexcharts, {ApexOptions} from "apexcharts";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {GenerateScheduleChartSeries, getPlanesWithEventsCount} from "./helpers/generateScheduleChart";
import {
  formatDate,
} from "../../../../utils/helpers/dateHelpers/dateHelpers";
import {
  clearScheduleResult,
  getSchedule,
} from "../../../../store/reducers/scheduleReducer/scheduleReducer";
import ButtonGroup from "antd/es/button/button-group";
import {ScheduleCreateModal} from "./components/ScheduleCreateModal";
import {ScheduleEditModal} from "./components/ScheduleEditModal";
import {ScheduleType} from "../../../../proto/generated/api_entities_pb";
import {screenSizes} from "../../../../utils/constans/styles/screenSizes";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {useSearchParams} from "react-router-dom";
import {IPlaneEvent} from "../../../../store/reducers/scheduleReducer/scheduleReducerTypes";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {DebounceSelect} from "../../../../components/UI/AntCustom/DebounceSelect";
import {fetchOwnPlanes, getInitialOwnPlaneForSelect} from "../../../../utils/helpers/selectDataFetchers/fetchOwnPlanes";
import {
  getRecentUTCMondayWithWeekOffset,
  getUTCDateWithDayOffset
} from "../../../../utils/helpers/dateHelpers/calculateArrivalDate";
import {IPlaneShort} from "../../../../store/reducers/fleetReducer/fleetReducerTypes";
import isNumeric from "antd/es/_util/isNumeric";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import styled from "styled-components";
import {CustomFieldInput} from "../../../../components/UI/AntCustom/CustomFieldInput";
import {DisplayAirport, DisplayRoute} from "../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {defineMessages, useIntl} from "react-intl";
import {CommonDetailsMessages} from "../../../../intl/commonMessages/CommonDetailsMessages";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";

const CreateEventButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const SchedulePageMessages = defineMessages({
  title: {
    id: "schedulePage.title",
    defaultMessage: "Aircraft fleet schedule",
  },
  chosenDay: {
    id: "schedulePage.chosenDay",
    defaultMessage: "Chosen day",
  },
  noEvents: {
    id: "schedulePage.noEvents",
    defaultMessage: "No events found",
  },
  addEvent: {
    id: "schedulePage.addEvent",
    defaultMessage: "Add to schedule",
  },
})

export const SchedulePage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {width} = useWindowSize();
  const {items, isLoading, totalCount} = useAppSelector(state => state.schedule.itemsObject);

  const [searchParams] = useSearchParams();
  const planeIdFromParams = searchParams.get('planeId');
  const planeRegNumberFromParams = searchParams.get('planeRegNumber');
  const defaultSelectedPlane: IPlaneShort | undefined = isNumeric(planeIdFromParams) && planeRegNumberFromParams ? {
    id: Number(planeIdFromParams),
    registrationNumber: planeRegNumberFromParams,
  } : undefined;

  const [selectedPlaneIds, setSelectedPlaneIds] = useState<number[]>(defaultSelectedPlane ? [defaultSelectedPlane.id] : []);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [dataSeries, setDataSeries] = useState<ApexAxisChartSeries>([]);
  const [timeOffset, setTimeOffset] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<Date>(getRecentUTCMondayWithWeekOffset(timeOffset));
  const [dateTo, setDateTo] = useState<Date>(getRecentUTCMondayWithWeekOffset(timeOffset + 1));
  const [lastSelectedEvent, setLastSelectedEvent] = useState<IPlaneEvent | undefined>(undefined);
  const [chartHeight, setChartHeight] = useState(140);
  const chartId = "schedule_chart";

  const offsetMode = width <= screenSizes.mobile ? "days" : "weeks";

  useEffect(() => {
    dispatch(getSchedule({
      page: InitialPage,
      dateFrom: dateFrom,
      dateTo: dateTo,
      planeIds: selectedPlaneIds,
    }))

    return () => {
      dispatch(clearScheduleResult())
    }
  }, [selectedPlaneIds, dateFrom, dateTo])

  useEffect(() => {
    const newSeries = GenerateScheduleChartSeries(items, intl);
    const planesWithEventsCount = getPlanesWithEventsCount(items);
    setDataSeries(newSeries);
    setChartHeight(100 + planesWithEventsCount * 40);
  }, [items])

  const options: ApexOptions = {
    chart: {
      id: chartId,
      type: 'rangeBar',
      events: {
        dataPointMouseEnter: function (event) {
          event.path[0].style.cursor = "pointer";
        },
        dataPointSelection(e: any, chart?: any, options?: any) {
          const selectedPlaneEvent: IPlaneEvent = options.w.config.series[options.seriesIndex].data[options.dataPointIndex].meta.planeEvent;
          setLastSelectedEvent(selectedPlaneEvent);
          setIsEditModalVisible(true);
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    grid: {
      show: true,
      borderColor: '#cfd7da',
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        rangeBarGroupRows: true,
        dataLabels: {
          position: 'bottom'
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#313131']
      },
      formatter: function (val, opt) {
        return `` //TODO add airports?
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'between',
      min: getRecentUTCMondayWithWeekOffset(timeOffset).getTime(),
      max: getRecentUTCMondayWithWeekOffset(timeOffset + 1, true).getTime(),
      labels: {
        format: "ddd",
      }
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const planeEvent: IPlaneEvent = w.config.series[seriesIndex].data[dataPointIndex].meta.planeEvent;

        return `
          <div style="padding: 10px 10px 5px 10px;display: grid;grid-template-columns: repeat(2, min-content);column-gap: 10px;">
            ${planeEvent.eventType === ScheduleType.CUSTOM ? `
                <div><b>${intl.formatMessage(CommonDetailsMessages.event)}</b></div><div>${planeEvent.eventName}</div>
                <div><b>${intl.formatMessage(CommonDetailsMessages.airport)}</b></div><div>${planeEvent.airportFrom && DisplayAirport(planeEvent.airportFrom)}</div>
            ` : ``}
            
            ${planeEvent.eventType === ScheduleType.EMPTY_LEG ? `<div><b>${intl.formatMessage(CommonDetailsMessages.event)}</b></div><div>${intl.formatMessage(CommonFormMessages.eventTypeEmptyLegRadio)}</div>` : ``}
            ${planeEvent.eventType === ScheduleType.ORDER_REQUEST ? `<div><b>${intl.formatMessage(CommonDetailsMessages.event)}</b></div><div>${intl.formatMessage(CommonFormMessages.eventTypeOrderRequestRadio)}</div>` : ``}
           
            ${planeEvent.eventType !== ScheduleType.CUSTOM
          ? `<div><b>${intl.formatMessage(CommonDetailsMessages.route)}</b></div>
                 <div>${planeEvent.airportFrom && planeEvent.airportTo && DisplayRoute([planeEvent.airportFrom, planeEvent.airportTo])}</div>`
          : ''
        }
            
            <div><b>${intl.formatMessage(CommonDetailsMessages.period)}</b></div><div>${formatDate(planeEvent.dateFrom, 0)} (UTC) — ${formatDate(planeEvent.dateTo, 0)} (UTC)</div>
          </div>
        `
      },
    },
    responsive: [{
      breakpoint: screenSizes.mobile,
      options: {
        xaxis: {
          type: 'datetime',
          tickPlacement: 'between',
          min: getUTCDateWithDayOffset(timeOffset).getTime(),
          max: getUTCDateWithDayOffset(timeOffset + 1, true).getTime(),
        },
      }
    }]
  };

  const onClickMinus = (mode: 'weeks' | 'days') => {
    const dateFrom = mode === 'weeks' ? getRecentUTCMondayWithWeekOffset(timeOffset - 1) : getUTCDateWithDayOffset(timeOffset - 1);
    const dateTo = mode === 'weeks' ? getRecentUTCMondayWithWeekOffset(timeOffset, true) : getUTCDateWithDayOffset(timeOffset, true);
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    apexcharts.exec(chartId, 'zoomX', dateFrom.getTime(), dateTo.getTime());
    setTimeOffset(prev => prev - 1);
  }

  const onClickPlus = (mode: 'weeks' | 'days') => {
    const dateFrom = mode === 'weeks' ? getRecentUTCMondayWithWeekOffset(timeOffset + 1) : getUTCDateWithDayOffset(timeOffset + 1);
    const dateTo = mode === 'weeks' ? getRecentUTCMondayWithWeekOffset(timeOffset + 2, true) : getUTCDateWithDayOffset(timeOffset + 2, true);
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    apexcharts.exec(chartId, 'zoomX', dateFrom.getTime(), dateTo.getTime());
    setTimeOffset(prev => prev + 1);
  }

  return (
    <PageWrapper>
      <CustomCard width={"max"} title={intl.formatMessage(SchedulePageMessages.title)} isContainerCard={true}>
        <div style={{display: "flex", justifyContent: "space-between", gap: 16, flexWrap: "wrap"}}>
          <CustomFieldInput>
            <DebounceSelect
              mode={"multiple"}
              fetchOptions={fetchOwnPlanes}
              placeholder={intl.formatMessage(CommonFormMessages.planePlaceholder)}
              showSearch={true}
              allowClear={true}
              style={{width: 261}}
              defaultValue={defaultSelectedPlane ? [getInitialOwnPlaneForSelect(defaultSelectedPlane)] : []}
              onChange={(values) => {
                setSelectedPlaneIds(values as unknown as number[])
              }}
            />
          </CustomFieldInput>

          <ButtonGroup style={{columnGap: 16, display: "flex", justifyContent: "flex-end"}}>
            <CustomButton onClick={() => onClickMinus(offsetMode)}>{offsetMode === 'weeks' ? `- ${intl.formatMessage(CommonDetailsMessages.week)}` : `- ${intl.formatMessage(CommonDetailsMessages.day)}`}</CustomButton>
            <CustomButton onClick={() => onClickPlus(offsetMode)}>{offsetMode === 'weeks' ? `+ ${intl.formatMessage(CommonDetailsMessages.week)}` : `+ ${intl.formatMessage(CommonDetailsMessages.day)}`}</CustomButton>
          </ButtonGroup>
        </div>
        {width <= screenSizes.mobile
          ? <div>{intl.formatMessage(SchedulePageMessages.chosenDay)} <b>{formatDate(getUTCDateWithDayOffset(timeOffset), 0, "date")} (UTC)</b></div>
          : <div><b>{formatDate(dateFrom, 0, "date")} (UTC) — {formatDate(dateTo, 0, "date")} (UTC)</b></div>
        }

        {items.length > 0
          ? <ReactApexChart type={"rangeBar"} options={options} series={dataSeries} height={chartHeight}/>
          : <div>{intl.formatMessage(SchedulePageMessages.noEvents)}</div>
        }
        <CreateEventButtonWrapper>
          <CustomButton type={'primary'} style={{maxWidth: 400, width: "100%"}} onClick={() => setIsCreateModalVisible(true)}>
            {intl.formatMessage(SchedulePageMessages.addEvent)}
          </CustomButton>
        </CreateEventButtonWrapper>
      </CustomCard>

      {isCreateModalVisible &&
        <ScheduleCreateModal isModalVisible={isCreateModalVisible} setIsModalVisible={setIsCreateModalVisible}/>}
      {isEditModalVisible && <ScheduleEditModal planeEvent={lastSelectedEvent!} isModalVisible={isEditModalVisible}
                                                setIsModalVisible={setIsEditModalVisible}/>}
    </PageWrapper>
  );
};
