import React, {FC, useState} from 'react';
import {Card} from "antd";
import styled from "styled-components";
import {UserStatus, useUserStatusName} from "../../../../utils/constans/statuses/userStatuses";
import {HandleUserRegistrationModal} from "./HandleUserRegistrationModal";
import {roles} from "../../../../config/acl/roles";
import {IRegistration} from "../../../../store/reducers/registrationsReducer/registrationsReducerTypes";
import {RegistrationLicensesModal} from "./RegistrationLicensesModal";
import {useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";
import {CommonUserMessages} from "../../../../intl/commonMessages/CommonUserMessages";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const ButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

interface IRegistrationCard {
  user: IRegistration;
}

export const RegistrationCard: FC<IRegistrationCard> = ({user}) => {
  const intl = useIntl();
  const userStatusName = useUserStatusName(user.userStatus);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [isLicensesModalOpen, setIsLicensesModalOpen] = useState<boolean>(false);

  return (
    <>
      <CustomCard width={"max"}>
        <Card.Meta
          title={`${user.lastName} ${user.firstName} ${user.middleName}`}
          description={
            <CardDescription>
              <div>{intl.formatMessage(CommonUserMessages.email)}: {user.email}</div>
              <div>{intl.formatMessage(CommonUserMessages.role)}: {intl.formatMessage(roles[user.companyRole].name)}</div>
              <div>{intl.formatMessage(CommonUserMessages.company)}: {user.company.name}</div>
              <div>{intl.formatMessage(CommonUserMessages.status)}: {userStatusName}</div>
              <CustomButton onClick={() => setIsLicensesModalOpen(true)}>{intl.formatMessage(CommonUserMessages.licensesAndFiles)}</CustomButton>
              <ButtonGroupStyled>
                {user.userStatus === UserStatus.REGISTERED &&
                  <>
                    <CustomButton danger onClick={() => setIsRejectModalOpen(true)} style={{width: "100%"}}>{intl.formatMessage(CommonButtonMessages.reject)}</CustomButton>
                    <CustomButton type={'primary'} onClick={() => setIsConfirmModalOpen(true)}
                            style={{width: "100%"}}>{intl.formatMessage(CommonButtonMessages.confirm)}</CustomButton>
                  </>
                }
              </ButtonGroupStyled>
              <RegistrationLicensesModal isVisible={isLicensesModalOpen} setIsVisible={setIsLicensesModalOpen}
                                         operatorCard={user.company.operatorCard}
                                         registrationCertificate={user.company.registrationCertificate}/>
            </CardDescription>
          }
        />
      </CustomCard>

      {isConfirmModalOpen &&
        <HandleUserRegistrationModal user={user}
                                     statusId={UserStatus.CONFIRMED}
                                     isVisible={isConfirmModalOpen}
                                     setIsVisible={setIsConfirmModalOpen}/>
      }

      {isRejectModalOpen &&
        <HandleUserRegistrationModal user={user}
                                     statusId={UserStatus.REJECTED}
                                     isVisible={isRejectModalOpen}
                                     setIsVisible={setIsRejectModalOpen}/>
      }
    </>
  );
};

