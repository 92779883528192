import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {
  IArchiveEmptyLegPayload,
  IChangeEmptyLegRespondStatusPayload,
  ICreateEmptyLegPayload,
  IEditEmptyLegPayload,
  IEmptyLegsInitialState,
  IGetEmptyLegListPayload, ISetArchiveEmptyLegPayloadResult, ISetChangeEmptyLegRespondStatusResultPayload,
  ISetCreateEmptyLegResultPayload,
  ISetEditEmptyLegResultPayload,
  ISetEmptyLegListResultPayload
} from "./emptyLegsReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearCreate,
  initCommonClearDelete,
  initCommonClearEdit,
  initCommonClearList,
  initCommonCreate,
  initCommonCreateResult,
  initCommonDelete,
  initCommonDeleteResult,
  initCommonEdit,
  initCommonEditResult,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {mapEmptyLegEntityToIEmptyLeg} from "./emptyLegsMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";


const emptyLegsInitialState: IEmptyLegsInitialState = {
  itemsObject: {
    items: [],
    totalCount: 0,
    isLoading: true,
  },
  create: {
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  },
  edit: {
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  },
  delete: {
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  },
  changeEmptyLegRespondStatus: {
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  },
};

const emptyLegsSlice = createSlice({
  name: 'emptyLegs',
  initialState: emptyLegsInitialState,
  reducers: {
    getEmptyLegList(state, {payload}: PayloadAction<IGetEmptyLegListPayload>) {
      initCommonGetList(state, payload);
    },
    clearEmptyLegList(state) {
      initCommonClearList(state);
    },
    setEmptyLegListResult(state, {payload}: PayloadAction<ISetEmptyLegListResultPayload>) {
      initCommonSetListResult(state, payload, mapEmptyLegEntityToIEmptyLeg);
    },

    createEmptyLeg(state, {payload}: PayloadAction<ICreateEmptyLegPayload>) {
      initCommonCreate(state, payload);
    },
    clearCreateEmptyLeg(state) {
      initCommonClearCreate(state);
    },
    setCreateEmptyLegResult(state, {payload}: PayloadAction<ISetCreateEmptyLegResultPayload>) {
      initCommonCreateResult(state, payload, mapEmptyLegEntityToIEmptyLeg);
    },

    editEmptyLeg(state, {payload}: PayloadAction<IEditEmptyLegPayload>) {
      initCommonEdit(state, payload);
    },
    clearEditEmptyLeg(state) {
      initCommonClearEdit(state);
    },
    setEditEmptyLegResult(state, {payload}: PayloadAction<ISetEditEmptyLegResultPayload>) {
      initCommonEditResult(state, payload, mapEmptyLegEntityToIEmptyLeg);
    },

    archiveEmptyLeg(state, {payload}: PayloadAction<IArchiveEmptyLegPayload>) {
      initCommonDelete(state, payload);
    },
    clearArchiveEmptyLeg(state) {
      initCommonClearDelete(state);
    },
    setArchiveEmptyLegResult(state, {payload}: PayloadAction<ISetArchiveEmptyLegPayloadResult>) {
      initCommonDeleteResult(state, payload);
    },

    changeEmptyLegRespondStatus(state, {payload}: PayloadAction<IChangeEmptyLegRespondStatusPayload>) {
      initCommonAction(state, nameof<IEmptyLegsInitialState>("changeEmptyLegRespondStatus"));
    },
    clearChangeEmptyLegRespondStatus(state) {
      initCommonClearAction(state, nameof<IEmptyLegsInitialState>("changeEmptyLegRespondStatus"));
    },
    setChangeEmptyLegRespondStatusResult(state, {payload}: PayloadAction<ISetChangeEmptyLegRespondStatusResultPayload>) {
      if (payload.emptyLegOrderRoute) {
        const orderRouteId = payload.emptyLegOrderRoute.getOrderrouteid();
        const emptyLegId = payload.emptyLegOrderRoute.getEmptylegid();
        const orderRouteEmptyLegStatus = payload.emptyLegOrderRoute.getStatus() as EmptyLegOrderRouteStatusEnum;

        state.itemsObject.items = state.itemsObject.items.map(el => {
          if (el.id !== emptyLegId) return el;

          return {
            ...el, orderRoutes: el.orderRoutes.map(or => {
              if (or.orderRouteId !== orderRouteId) return or;

              return {...or, status: orderRouteEmptyLegStatus as EmptyLegOrderRouteStatusEnum || EmptyLegOrderRouteStatusEnum.ACTIVE}
            })
          }
        })
      }

      initCommonActionResult(state, payload, nameof<IEmptyLegsInitialState>("changeEmptyLegRespondStatus"))
    },
  },
});

export const {
  getEmptyLegList,
  clearEmptyLegList,
  setEmptyLegListResult,

  createEmptyLeg,
  clearCreateEmptyLeg,
  setCreateEmptyLegResult,

  editEmptyLeg,
  clearEditEmptyLeg,
  setEditEmptyLegResult,

  archiveEmptyLeg,
  clearArchiveEmptyLeg,
  setArchiveEmptyLegResult,

  changeEmptyLegRespondStatus,
  clearChangeEmptyLegRespondStatus,
  setChangeEmptyLegRespondStatusResult,
} =
  emptyLegsSlice.actions;
export default emptyLegsSlice.reducer;