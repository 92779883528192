import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";

export interface IEmptyLegRespondStatusesInfo {
  active: number;
  approved: number;
  declined: number;
  withdrawn: number;
}

export const defaultEmptyLegRespondStatusesInfo: IEmptyLegRespondStatusesInfo = {
  active: 0,
  approved: 0,
  declined: 0,
  withdrawn: 0,
}

export interface ICharterOfferStatusesInfo {
  notOffered: number;
  offered: number;
  declined: number;
}

export const defaultCharterOfferStatusesInfo: ICharterOfferStatusesInfo = {
  notOffered: 0,
  offered: 0,
  declined: 0,
}

export const generateEmptyLegRespondStatusesInfo = (statuses: EmptyLegOrderRouteStatusEnum[]): IEmptyLegRespondStatusesInfo => {
  let info: IEmptyLegRespondStatusesInfo = {
    approved: 0,
    declined: 0,
    active: 0,
    withdrawn: 0,
  }

  return statuses.reduce((acc: IEmptyLegRespondStatusesInfo, status: EmptyLegOrderRouteStatusEnum) => {
    switch (status) {
      case EmptyLegOrderRouteStatusEnum.APPROVED:
        ++acc.approved;
        break;
      case EmptyLegOrderRouteStatusEnum.DECLINED:
        ++acc.declined;
        break;
      case EmptyLegOrderRouteStatusEnum.ACTIVE:
        ++acc.active;
        break;
      case EmptyLegOrderRouteStatusEnum.WITHDRAWN:
        ++acc.withdrawn;
        break;
      default:
        break;
    }

    return acc;
  }, info);
}

export const generateCharterOffersStatusesInfo = (statuses: OrderRequestStatusEnum[]): ICharterOfferStatusesInfo => {
  let info: ICharterOfferStatusesInfo = {
    notOffered: 0,
    offered: 0,
    declined: 0,
  }

  return statuses.reduce((acc: ICharterOfferStatusesInfo, status: OrderRequestStatusEnum) => {
    switch (status) {
      case OrderRequestStatusEnum.NOT_OFFERED:
        ++acc.notOffered;
        break;
      case OrderRequestStatusEnum.OFFERED:
        ++acc.offered;
        break;
      case OrderRequestStatusEnum.DECLINED:
        ++acc.declined;
        break;
      default:
        break;
    }

    return acc;
  }, info);
}