/**
 * @fileoverview gRPC-Web generated client stub for protobuf
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.18.1
// source: api_entities.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.protobuf = require('./api_entities_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.GreeterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.GreeterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.HelloRequest,
 *   !proto.protobuf.HelloReply>}
 */
const methodDescriptor_Greeter_SayHello = new grpc.web.MethodDescriptor(
  '/protobuf.Greeter/SayHello',
  grpc.web.MethodType.UNARY,
  proto.protobuf.HelloRequest,
  proto.protobuf.HelloReply,
  /**
   * @param {!proto.protobuf.HelloRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.HelloReply.deserializeBinary
);


/**
 * @param {!proto.protobuf.HelloRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.HelloReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.HelloReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.GreeterClient.prototype.sayHello =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.Greeter/SayHello',
      request,
      metadata || {},
      methodDescriptor_Greeter_SayHello,
      callback);
};


/**
 * @param {!proto.protobuf.HelloRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.HelloReply>}
 *     Promise that resolves to the response
 */
proto.protobuf.GreeterPromiseClient.prototype.sayHello =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.Greeter/SayHello',
      request,
      metadata || {},
      methodDescriptor_Greeter_SayHello);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.AuthApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.AuthApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.AuthApiRequest,
 *   !proto.protobuf.AuthApiResponse>}
 */
const methodDescriptor_AuthApiService_Auth = new grpc.web.MethodDescriptor(
  '/protobuf.AuthApiService/Auth',
  grpc.web.MethodType.UNARY,
  proto.protobuf.AuthApiRequest,
  proto.protobuf.AuthApiResponse,
  /**
   * @param {!proto.protobuf.AuthApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.AuthApiResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.AuthApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.AuthApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.AuthApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.AuthApiServiceClient.prototype.auth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.AuthApiService/Auth',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_Auth,
      callback);
};


/**
 * @param {!proto.protobuf.AuthApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.AuthApiResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.AuthApiServicePromiseClient.prototype.auth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.AuthApiService/Auth',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_Auth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.AuthApiRegistrationRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_AuthApiService_Registration = new grpc.web.MethodDescriptor(
  '/protobuf.AuthApiService/Registration',
  grpc.web.MethodType.UNARY,
  proto.protobuf.AuthApiRegistrationRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.AuthApiRegistrationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.AuthApiRegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.AuthApiServiceClient.prototype.registration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.AuthApiService/Registration',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_Registration,
      callback);
};


/**
 * @param {!proto.protobuf.AuthApiRegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.AuthApiServicePromiseClient.prototype.registration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.AuthApiService/Registration',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_Registration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.AuthApiForgotPasswordRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_AuthApiService_ForgotPassword = new grpc.web.MethodDescriptor(
  '/protobuf.AuthApiService/ForgotPassword',
  grpc.web.MethodType.UNARY,
  proto.protobuf.AuthApiForgotPasswordRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.AuthApiForgotPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.AuthApiServiceClient.prototype.forgotPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPassword,
      callback);
};


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.AuthApiServicePromiseClient.prototype.forgotPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.AuthApiForgotPasswordCheckCodeRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_AuthApiService_ForgotPasswordCheckCode = new grpc.web.MethodDescriptor(
  '/protobuf.AuthApiService/ForgotPasswordCheckCode',
  grpc.web.MethodType.UNARY,
  proto.protobuf.AuthApiForgotPasswordCheckCodeRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.AuthApiServiceClient.prototype.forgotPasswordCheckCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPasswordCheckCode',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPasswordCheckCode,
      callback);
};


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordCheckCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.AuthApiServicePromiseClient.prototype.forgotPasswordCheckCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPasswordCheckCode',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPasswordCheckCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.AuthApiForgotPasswordSetupRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_AuthApiService_ForgotPasswordSetup = new grpc.web.MethodDescriptor(
  '/protobuf.AuthApiService/ForgotPasswordSetup',
  grpc.web.MethodType.UNARY,
  proto.protobuf.AuthApiForgotPasswordSetupRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.AuthApiServiceClient.prototype.forgotPasswordSetup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPasswordSetup',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPasswordSetup,
      callback);
};


/**
 * @param {!proto.protobuf.AuthApiForgotPasswordSetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.AuthApiServicePromiseClient.prototype.forgotPasswordSetup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.AuthApiService/ForgotPasswordSetup',
      request,
      metadata || {},
      methodDescriptor_AuthApiService_ForgotPasswordSetup);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.BillingApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.BillingApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.BillingApiSubscriptionPricesResponse>}
 */
const methodDescriptor_BillingApiService_GetSubscriptionPrices = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/GetSubscriptionPrices',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.BillingApiSubscriptionPricesResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiSubscriptionPricesResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiSubscriptionPricesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiSubscriptionPricesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.getSubscriptionPrices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/GetSubscriptionPrices',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetSubscriptionPrices,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiSubscriptionPricesResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.getSubscriptionPrices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/GetSubscriptionPrices',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetSubscriptionPrices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.BillingApiTariffResponse>}
 */
const methodDescriptor_BillingApiService_GetTariff = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/GetTariff',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.BillingApiTariffResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.getTariff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/GetTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetTariff,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.getTariff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/GetTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetTariff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTariffRequest,
 *   !proto.protobuf.BillingApiTariffResponse>}
 */
const methodDescriptor_BillingApiService_CreateTariff = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/CreateTariff',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTariffRequest,
  proto.protobuf.BillingApiTariffResponse,
  /**
   * @param {!proto.protobuf.BillingApiTariffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTariffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.createTariff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateTariff,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTariffRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.createTariff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateTariff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTariffRequest,
 *   !proto.protobuf.BillingApiTariffResponse>}
 */
const methodDescriptor_BillingApiService_UpdateTariff = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/UpdateTariff',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTariffRequest,
  proto.protobuf.BillingApiTariffResponse,
  /**
   * @param {!proto.protobuf.BillingApiTariffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTariffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.updateTariff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateTariff,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTariffRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.updateTariff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateTariff',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateTariff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTransactionRequest,
 *   !proto.protobuf.BillingApiTransactionResponse>}
 */
const methodDescriptor_BillingApiService_UpdateCompanyBalance = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/UpdateCompanyBalance',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTransactionRequest,
  proto.protobuf.BillingApiTransactionResponse,
  /**
   * @param {!proto.protobuf.BillingApiTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.updateCompanyBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateCompanyBalance',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateCompanyBalance,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.updateCompanyBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateCompanyBalance',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateCompanyBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.BillingApiTariffPackageListResponse>}
 */
const methodDescriptor_BillingApiService_TariffPackageList = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/TariffPackageList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.BillingApiTariffPackageListResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffPackageListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffPackageListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffPackageListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.tariffPackageList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/TariffPackageList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_TariffPackageList,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffPackageListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.tariffPackageList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/TariffPackageList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_TariffPackageList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTariffPackageRequest,
 *   !proto.protobuf.BillingApiTariffPackageResponse>}
 */
const methodDescriptor_BillingApiService_CreateTariffPackage = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/CreateTariffPackage',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTariffPackageRequest,
  proto.protobuf.BillingApiTariffPackageResponse,
  /**
   * @param {!proto.protobuf.BillingApiTariffPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffPackageResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.createTariffPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateTariffPackage,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffPackageResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.createTariffPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateTariffPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTariffPackageRequest,
 *   !proto.protobuf.BillingApiTariffPackageResponse>}
 */
const methodDescriptor_BillingApiService_UpdateTariffPackage = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/UpdateTariffPackage',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTariffPackageRequest,
  proto.protobuf.BillingApiTariffPackageResponse,
  /**
   * @param {!proto.protobuf.BillingApiTariffPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffPackageResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.updateTariffPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateTariffPackage,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffPackageResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.updateTariffPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/UpdateTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_UpdateTariffPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiTariffPackageRequest,
 *   !proto.protobuf.BillingApiTariffPackageResponse>}
 */
const methodDescriptor_BillingApiService_DeleteTariffPackage = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/DeleteTariffPackage',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiTariffPackageRequest,
  proto.protobuf.BillingApiTariffPackageResponse,
  /**
   * @param {!proto.protobuf.BillingApiTariffPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiTariffPackageResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiTariffPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiTariffPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.deleteTariffPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/DeleteTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_DeleteTariffPackage,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiTariffPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiTariffPackageResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.deleteTariffPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/DeleteTariffPackage',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_DeleteTariffPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiInvoiceRequest,
 *   !proto.protobuf.BillingApiInvoiceResponse>}
 */
const methodDescriptor_BillingApiService_CreateInvoice = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/CreateInvoice',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiInvoiceRequest,
  proto.protobuf.BillingApiInvoiceResponse,
  /**
   * @param {!proto.protobuf.BillingApiInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.createInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateInvoice,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.createInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CreateInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiInvoiceRequest,
 *   !proto.protobuf.BillingApiInvoiceResponse>}
 */
const methodDescriptor_BillingApiService_ApplyInvoice = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/ApplyInvoice',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiInvoiceRequest,
  proto.protobuf.BillingApiInvoiceResponse,
  /**
   * @param {!proto.protobuf.BillingApiInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.applyInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/ApplyInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_ApplyInvoice,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.applyInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/ApplyInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_ApplyInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiCurrencyListRequest,
 *   !proto.protobuf.BillingApiCurrencyListResponse>}
 */
const methodDescriptor_BillingApiService_CurrencyList = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/CurrencyList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiCurrencyListRequest,
  proto.protobuf.BillingApiCurrencyListResponse,
  /**
   * @param {!proto.protobuf.BillingApiCurrencyListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiCurrencyListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiCurrencyListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiCurrencyListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiCurrencyListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.currencyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/CurrencyList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CurrencyList,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiCurrencyListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiCurrencyListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.currencyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/CurrencyList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_CurrencyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BillingApiGetInvoiceListRequest,
 *   !proto.protobuf.BillingApiGetInvoiceListResponse>}
 */
const methodDescriptor_BillingApiService_GetInvoiceList = new grpc.web.MethodDescriptor(
  '/protobuf.BillingApiService/GetInvoiceList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BillingApiGetInvoiceListRequest,
  proto.protobuf.BillingApiGetInvoiceListResponse,
  /**
   * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BillingApiGetInvoiceListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BillingApiGetInvoiceListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BillingApiGetInvoiceListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BillingApiServiceClient.prototype.getInvoiceList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BillingApiService/GetInvoiceList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetInvoiceList,
      callback);
};


/**
 * @param {!proto.protobuf.BillingApiGetInvoiceListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BillingApiGetInvoiceListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BillingApiServicePromiseClient.prototype.getInvoiceList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BillingApiService/GetInvoiceList',
      request,
      metadata || {},
      methodDescriptor_BillingApiService_GetInvoiceList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.UserAccountApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.UserAccountApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiAgentListRequest,
 *   !proto.protobuf.UserAccountApiAgentListResponse>}
 */
const methodDescriptor_UserAccountApiService_GetAgencyList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/GetAgencyList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiAgentListRequest,
  proto.protobuf.UserAccountApiAgentListResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiAgentListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiAgentListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiAgentListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiAgentListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiAgentListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.getAgencyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetAgencyList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetAgencyList,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiAgentListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiAgentListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.getAgencyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetAgencyList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetAgencyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiFlyCompanyListRequest,
 *   !proto.protobuf.UserAccountApiFlyCompanyListResponse>}
 */
const methodDescriptor_UserAccountApiService_GetFlyCompanyList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/GetFlyCompanyList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiFlyCompanyListRequest,
  proto.protobuf.UserAccountApiFlyCompanyListResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiFlyCompanyListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiFlyCompanyListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiFlyCompanyListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.getFlyCompanyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetFlyCompanyList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetFlyCompanyList,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiFlyCompanyListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiFlyCompanyListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.getFlyCompanyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetFlyCompanyList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetFlyCompanyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiManagerListRequest,
 *   !proto.protobuf.UserAccountApiManagerListResponse>}
 */
const methodDescriptor_UserAccountApiService_GetAgencyManagerList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/GetAgencyManagerList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiManagerListRequest,
  proto.protobuf.UserAccountApiManagerListResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiManagerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiManagerListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiManagerListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiManagerListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.getAgencyManagerList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetAgencyManagerList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetAgencyManagerList,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiManagerListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.getAgencyManagerList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetAgencyManagerList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetAgencyManagerList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiManagerListRequest,
 *   !proto.protobuf.UserAccountApiManagerListResponse>}
 */
const methodDescriptor_UserAccountApiService_GetFreighterManagerList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/GetFreighterManagerList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiManagerListRequest,
  proto.protobuf.UserAccountApiManagerListResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiManagerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiManagerListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiManagerListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiManagerListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.getFreighterManagerList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetFreighterManagerList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetFreighterManagerList,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiManagerListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiManagerListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.getFreighterManagerList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/GetFreighterManagerList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_GetFreighterManagerList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiCreateManagerRequest,
 *   !proto.protobuf.UserAccountApiUserResponse>}
 */
const methodDescriptor_UserAccountApiService_CreateManager = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/CreateManager',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiCreateManagerRequest,
  proto.protobuf.UserAccountApiUserResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.createManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/CreateManager',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_CreateManager,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiCreateManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.createManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/CreateManager',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_CreateManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiRemoveManagerRequest,
 *   !proto.protobuf.UserAccountApiUserResponse>}
 */
const methodDescriptor_UserAccountApiService_RemoveManager = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/RemoveManager',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiRemoveManagerRequest,
  proto.protobuf.UserAccountApiUserResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.removeManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/RemoveManager',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_RemoveManager,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiRemoveManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.removeManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/RemoveManager',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_RemoveManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiUserListRequest,
 *   !proto.protobuf.UserAccountApiUserListResponse>}
 */
const methodDescriptor_UserAccountApiService_UserList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiUserListRequest,
  proto.protobuf.UserAccountApiUserListResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiUserListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiUserListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserList,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiUserListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiUserUpdateRequest,
 *   !proto.protobuf.UserAccountApiUserResponse>}
 */
const methodDescriptor_UserAccountApiService_UserUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiUserUpdateRequest,
  proto.protobuf.UserAccountApiUserResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserUpdate',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiUserUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserUpdate',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.UserAccountApiUserResponse>}
 */
const methodDescriptor_UserAccountApiService_UserInfo = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserInfo',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.UserAccountApiUserResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserInfo',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserInfo,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserInfo',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiChangeUserStatusRequest,
 *   !proto.protobuf.UserAccountApiUserResponse>}
 */
const methodDescriptor_UserAccountApiService_UserChangeStatus = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserChangeStatus',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiChangeUserStatusRequest,
  proto.protobuf.UserAccountApiUserResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userChangeStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserChangeStatus',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserChangeStatus,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiChangeUserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userChangeStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserChangeStatus',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserChangeStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.UserAccountApiUserStatusListResponse>}
 */
const methodDescriptor_UserAccountApiService_UserStatusList = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserStatusList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.UserAccountApiUserStatusListResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiUserStatusListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiUserStatusListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiUserStatusListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userStatusList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserStatusList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserStatusList,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiUserStatusListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userStatusList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserStatusList',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserStatusList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiChangePasswordRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_UserAccountApiService_UserChangePassword = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UserChangePassword',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiChangePasswordRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.userChangePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserChangePassword,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.userChangePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UserChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UserChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.UserAccountApiCompanyRequest,
 *   !proto.protobuf.UserAccountApiCompanyResponse>}
 */
const methodDescriptor_UserAccountApiService_UpdateCompany = new grpc.web.MethodDescriptor(
  '/protobuf.UserAccountApiService/UpdateCompany',
  grpc.web.MethodType.UNARY,
  proto.protobuf.UserAccountApiCompanyRequest,
  proto.protobuf.UserAccountApiCompanyResponse,
  /**
   * @param {!proto.protobuf.UserAccountApiCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.UserAccountApiCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.UserAccountApiCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.UserAccountApiCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.UserAccountApiCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.UserAccountApiServiceClient.prototype.updateCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UpdateCompany',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UpdateCompany,
      callback);
};


/**
 * @param {!proto.protobuf.UserAccountApiCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.UserAccountApiCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.UserAccountApiServicePromiseClient.prototype.updateCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.UserAccountApiService/UpdateCompany',
      request,
      metadata || {},
      methodDescriptor_UserAccountApiService_UpdateCompany);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.CommunicationApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.CommunicationApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.CommunicationApiRequestCallbackRequest,
 *   !proto.protobuf.CommunicationApiRequestCallbackResponse>}
 */
const methodDescriptor_CommunicationApiService_RequestCallback = new grpc.web.MethodDescriptor(
  '/protobuf.CommunicationApiService/RequestCallback',
  grpc.web.MethodType.UNARY,
  proto.protobuf.CommunicationApiRequestCallbackRequest,
  proto.protobuf.CommunicationApiRequestCallbackResponse,
  /**
   * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.CommunicationApiRequestCallbackResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.CommunicationApiRequestCallbackResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.CommunicationApiRequestCallbackResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.CommunicationApiServiceClient.prototype.requestCallback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.CommunicationApiService/RequestCallback',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_RequestCallback,
      callback);
};


/**
 * @param {!proto.protobuf.CommunicationApiRequestCallbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.CommunicationApiRequestCallbackResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.CommunicationApiServicePromiseClient.prototype.requestCallback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.CommunicationApiService/RequestCallback',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_RequestCallback);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.CommunicationApiRequestCallbackListRequest,
 *   !proto.protobuf.CommunicationApiRequestCallbackListResponse>}
 */
const methodDescriptor_CommunicationApiService_RequestCallbackList = new grpc.web.MethodDescriptor(
  '/protobuf.CommunicationApiService/RequestCallbackList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.CommunicationApiRequestCallbackListRequest,
  proto.protobuf.CommunicationApiRequestCallbackListResponse,
  /**
   * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.CommunicationApiRequestCallbackListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.CommunicationApiRequestCallbackListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.CommunicationApiRequestCallbackListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.CommunicationApiServiceClient.prototype.requestCallbackList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.CommunicationApiService/RequestCallbackList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_RequestCallbackList,
      callback);
};


/**
 * @param {!proto.protobuf.CommunicationApiRequestCallbackListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.CommunicationApiRequestCallbackListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.CommunicationApiServicePromiseClient.prototype.requestCallbackList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.CommunicationApiService/RequestCallbackList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_RequestCallbackList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.CommunicationApiChatListRequest,
 *   !proto.protobuf.CommunicationApiChatListResponse>}
 */
const methodDescriptor_CommunicationApiService_ChatList = new grpc.web.MethodDescriptor(
  '/protobuf.CommunicationApiService/ChatList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.CommunicationApiChatListRequest,
  proto.protobuf.CommunicationApiChatListResponse,
  /**
   * @param {!proto.protobuf.CommunicationApiChatListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.CommunicationApiChatListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.CommunicationApiChatListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.CommunicationApiChatListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.CommunicationApiChatListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.CommunicationApiServiceClient.prototype.chatList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatList,
      callback);
};


/**
 * @param {!proto.protobuf.CommunicationApiChatListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.CommunicationApiChatListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.CommunicationApiServicePromiseClient.prototype.chatList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.CommunicationApiChatMessageListRequest,
 *   !proto.protobuf.CommunicationApiChatMessageListResponse>}
 */
const methodDescriptor_CommunicationApiService_ChatMessageList = new grpc.web.MethodDescriptor(
  '/protobuf.CommunicationApiService/ChatMessageList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.CommunicationApiChatMessageListRequest,
  proto.protobuf.CommunicationApiChatMessageListResponse,
  /**
   * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.CommunicationApiChatMessageListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.CommunicationApiChatMessageListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.CommunicationApiChatMessageListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.CommunicationApiServiceClient.prototype.chatMessageList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatMessageList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatMessageList,
      callback);
};


/**
 * @param {!proto.protobuf.CommunicationApiChatMessageListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.CommunicationApiChatMessageListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.CommunicationApiServicePromiseClient.prototype.chatMessageList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatMessageList',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatMessageList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.CommunicationApiChatMessageSendRequest,
 *   !proto.protobuf.CommunicationApiChatMessageSendResponse>}
 */
const methodDescriptor_CommunicationApiService_ChatMessageSend = new grpc.web.MethodDescriptor(
  '/protobuf.CommunicationApiService/ChatMessageSend',
  grpc.web.MethodType.UNARY,
  proto.protobuf.CommunicationApiChatMessageSendRequest,
  proto.protobuf.CommunicationApiChatMessageSendResponse,
  /**
   * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.CommunicationApiChatMessageSendResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.CommunicationApiChatMessageSendResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.CommunicationApiChatMessageSendResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.CommunicationApiServiceClient.prototype.chatMessageSend =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatMessageSend',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatMessageSend,
      callback);
};


/**
 * @param {!proto.protobuf.CommunicationApiChatMessageSendRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.CommunicationApiChatMessageSendResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.CommunicationApiServicePromiseClient.prototype.chatMessageSend =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.CommunicationApiService/ChatMessageSend',
      request,
      metadata || {},
      methodDescriptor_CommunicationApiService_ChatMessageSend);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.BookingApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.BookingApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiGetAirportRequest,
 *   !proto.protobuf.BookingApiGetAirportResponse>}
 */
const methodDescriptor_BookingApiService_Airport = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/Airport',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiGetAirportRequest,
  proto.protobuf.BookingApiGetAirportResponse,
  /**
   * @param {!proto.protobuf.BookingApiGetAirportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiGetAirportResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiGetAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiGetAirportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiGetAirportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.airport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/Airport',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_Airport,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiGetAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiGetAirportResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.airport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/Airport',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_Airport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiAirportListRequest,
 *   !proto.protobuf.BookingApiAirportListResponse>}
 */
const methodDescriptor_BookingApiService_AirportList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/AirportList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiAirportListRequest,
  proto.protobuf.BookingApiAirportListResponse,
  /**
   * @param {!proto.protobuf.BookingApiAirportListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiAirportListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiAirportListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiAirportListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiAirportListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.airportList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/AirportList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_AirportList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiAirportListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiAirportListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.airportList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/AirportList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_AirportList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiAirportShortListRequest,
 *   !proto.protobuf.BookingApiAirportShortListResponse>}
 */
const methodDescriptor_BookingApiService_AirportShortList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/AirportShortList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiAirportShortListRequest,
  proto.protobuf.BookingApiAirportShortListResponse,
  /**
   * @param {!proto.protobuf.BookingApiAirportShortListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiAirportShortListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiAirportShortListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiAirportShortListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiAirportShortListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.airportShortList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/AirportShortList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_AirportShortList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiAirportShortListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiAirportShortListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.airportShortList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/AirportShortList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_AirportShortList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegListRequest,
 *   !proto.protobuf.BookingApiEmptyLegListResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegListRequest,
  proto.protobuf.BookingApiEmptyLegListResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegRequest,
 *   !proto.protobuf.BookingApiEmptyLegResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegCreate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegRequest,
  proto.protobuf.BookingApiEmptyLegResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegCreate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegRequest,
 *   !proto.protobuf.BookingApiEmptyLegResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegRequest,
  proto.protobuf.BookingApiEmptyLegResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegRequest,
 *   !proto.protobuf.BookingApiEmptyLegResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegDelete = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegRequest,
  proto.protobuf.BookingApiEmptyLegResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegDelete,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegRequest,
 *   !proto.protobuf.BookingApiEmptyLegResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegArchive = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegArchive',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegRequest,
  proto.protobuf.BookingApiEmptyLegResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegArchive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegArchive,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegArchive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegArchive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegOrderRouteRequest,
 *   !proto.protobuf.BookingApiEmptyLegOrderRouteResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegOrderRouteUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegOrderRouteUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegOrderRouteRequest,
  proto.protobuf.BookingApiEmptyLegOrderRouteResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegOrderRouteResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegOrderRouteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegOrderRouteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegOrderRouteUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegOrderRouteUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegOrderRouteUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegOrderRouteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegOrderRouteResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegOrderRouteUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegOrderRouteUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegOrderRouteUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiEmptyLegStatusListRequest,
 *   !proto.protobuf.BookingApiEmptyLegStatusListResponse>}
 */
const methodDescriptor_BookingApiService_EmptyLegStatusList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/EmptyLegStatusList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiEmptyLegStatusListRequest,
  proto.protobuf.BookingApiEmptyLegStatusListResponse,
  /**
   * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiEmptyLegStatusListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiEmptyLegStatusListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiEmptyLegStatusListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.emptyLegStatusList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegStatusList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiEmptyLegStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiEmptyLegStatusListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.emptyLegStatusList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/EmptyLegStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_EmptyLegStatusList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderListRequest,
 *   !proto.protobuf.BookingApiOrderListResponse>}
 */
const methodDescriptor_BookingApiService_OrderList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderListRequest,
  proto.protobuf.BookingApiOrderListResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequest,
 *   !proto.protobuf.BookingApiOrderResponse>}
 */
const methodDescriptor_BookingApiService_OrderCreate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequest,
  proto.protobuf.BookingApiOrderResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderCreate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequest,
 *   !proto.protobuf.BookingApiOrderResponse>}
 */
const methodDescriptor_BookingApiService_OrderDelete = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequest,
  proto.protobuf.BookingApiOrderResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderDelete,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequest,
 *   !proto.protobuf.BookingApiOrderResponse>}
 */
const methodDescriptor_BookingApiService_OrderArchive = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderArchive',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequest,
  proto.protobuf.BookingApiOrderResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderArchive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderArchive,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderArchive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderArchive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderStatusListRequest,
 *   !proto.protobuf.BookingApiOrderStatusListResponse>}
 */
const methodDescriptor_BookingApiService_OrderStatusList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderStatusList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderStatusListRequest,
  proto.protobuf.BookingApiOrderStatusListResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderStatusListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderStatusListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderStatusListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderStatusListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderStatusList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderStatusList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderStatusListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderStatusList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderStatusList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestListRequest,
 *   !proto.protobuf.BookingApiOrderRequestListResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestListRequest,
  proto.protobuf.BookingApiOrderRequestListResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestRequest,
 *   !proto.protobuf.BookingApiOrderRequestResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestCreate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestRequest,
  proto.protobuf.BookingApiOrderRequestResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestCreate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestCreate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestRequest,
 *   !proto.protobuf.BookingApiOrderRequestResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestRequest,
  proto.protobuf.BookingApiOrderRequestResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestRequest,
 *   !proto.protobuf.BookingApiOrderRequestResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestDelete = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestRequest,
  proto.protobuf.BookingApiOrderRequestResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestDelete,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestDelete',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestRequest,
 *   !proto.protobuf.BookingApiOrderRequestResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestArchive = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestArchive',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestRequest,
  proto.protobuf.BookingApiOrderRequestResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestArchive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestArchive,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestArchive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestArchive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRequestStatusListRequest,
 *   !proto.protobuf.BookingApiOrderRequestStatusListResponse>}
 */
const methodDescriptor_BookingApiService_OrderRequestStatusList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRequestStatusList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRequestStatusListRequest,
  proto.protobuf.BookingApiOrderRequestStatusListResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRequestStatusListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRequestStatusListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRequestStatusListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRequestStatusList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestStatusList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRequestStatusListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRequestStatusListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRequestStatusList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRequestStatusList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRequestStatusList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRouteListRequest,
 *   !proto.protobuf.BookingApiOrderRouteListResponse>}
 */
const methodDescriptor_BookingApiService_OrderRouteList = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRouteList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRouteListRequest,
  proto.protobuf.BookingApiOrderRouteListResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRouteListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRouteListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRouteListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRouteListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRouteListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRouteList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRouteList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRouteList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRouteListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRouteListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRouteList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRouteList',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRouteList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiOrderRouteRequest,
 *   !proto.protobuf.BookingApiOrderRouteResponse>}
 */
const methodDescriptor_BookingApiService_OrderRouteArchive = new grpc.web.MethodDescriptor(
  '/protobuf.BookingApiService/OrderRouteArchive',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiOrderRouteRequest,
  proto.protobuf.BookingApiOrderRouteResponse,
  /**
   * @param {!proto.protobuf.BookingApiOrderRouteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiOrderRouteResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiOrderRouteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiOrderRouteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiOrderRouteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.BookingApiServiceClient.prototype.orderRouteArchive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRouteArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRouteArchive,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiOrderRouteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiOrderRouteResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.BookingApiServicePromiseClient.prototype.orderRouteArchive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.BookingApiService/OrderRouteArchive',
      request,
      metadata || {},
      methodDescriptor_BookingApiService_OrderRouteArchive);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.FlyApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.FlyApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.FlyApiFlyRouteDetailRequest,
 *   !proto.protobuf.FlyApiFlyRouteDetailResponse>}
 */
const methodDescriptor_FlyApiService_FlyRouteDetail = new grpc.web.MethodDescriptor(
  '/protobuf.FlyApiService/FlyRouteDetail',
  grpc.web.MethodType.UNARY,
  proto.protobuf.FlyApiFlyRouteDetailRequest,
  proto.protobuf.FlyApiFlyRouteDetailResponse,
  /**
   * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.FlyApiFlyRouteDetailResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.FlyApiFlyRouteDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.FlyApiFlyRouteDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.FlyApiServiceClient.prototype.flyRouteDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.FlyApiService/FlyRouteDetail',
      request,
      metadata || {},
      methodDescriptor_FlyApiService_FlyRouteDetail,
      callback);
};


/**
 * @param {!proto.protobuf.FlyApiFlyRouteDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.FlyApiFlyRouteDetailResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.FlyApiServicePromiseClient.prototype.flyRouteDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.FlyApiService/FlyRouteDetail',
      request,
      metadata || {},
      methodDescriptor_FlyApiService_FlyRouteDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.FlyApiFlyCostRequest,
 *   !proto.protobuf.FlyApiFlyCostResponse>}
 */
const methodDescriptor_FlyApiService_FlyCost = new grpc.web.MethodDescriptor(
  '/protobuf.FlyApiService/FlyCost',
  grpc.web.MethodType.UNARY,
  proto.protobuf.FlyApiFlyCostRequest,
  proto.protobuf.FlyApiFlyCostResponse,
  /**
   * @param {!proto.protobuf.FlyApiFlyCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.FlyApiFlyCostResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.FlyApiFlyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.FlyApiFlyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.FlyApiFlyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.FlyApiServiceClient.prototype.flyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.FlyApiService/FlyCost',
      request,
      metadata || {},
      methodDescriptor_FlyApiService_FlyCost,
      callback);
};


/**
 * @param {!proto.protobuf.FlyApiFlyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.FlyApiFlyCostResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.FlyApiServicePromiseClient.prototype.flyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.FlyApiService/FlyCost',
      request,
      metadata || {},
      methodDescriptor_FlyApiService_FlyCost);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.PlaneApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.PlaneApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneCategoryListRequest,
 *   !proto.protobuf.BookingApiPlaneCategoryListResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneCategoryList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneCategoryList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneCategoryListRequest,
  proto.protobuf.BookingApiPlaneCategoryListResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneCategoryListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneCategoryListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneCategoryListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeCategoryList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneCategoryList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneCategoryList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneCategoryListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneCategoryListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeCategoryList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneCategoryList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneCategoryList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneTypeListRequest,
 *   !proto.protobuf.BookingApiPlaneTypeListResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneTypeList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneTypeList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneTypeListRequest,
  proto.protobuf.BookingApiPlaneTypeListResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneTypeListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneTypeListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneTypeListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeTypeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneTypeListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeTypeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.BookingApiPlaneTypeShortListResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneTypeShortList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneTypeShortList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.BookingApiPlaneTypeShortListResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneTypeShortListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneTypeShortListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneTypeShortListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeTypeShortList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeShortList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeShortList,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneTypeShortListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeTypeShortList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeShortList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeShortList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneTypeRequest,
 *   !proto.protobuf.BookingApiPlaneTypeResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneTypeCreate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneTypeCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneTypeRequest,
  proto.protobuf.BookingApiPlaneTypeResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeTypeCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeCreate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeCreate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneTypeResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeTypeCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeCreate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneTypeRequest,
 *   !proto.protobuf.BookingApiPlaneTypeResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneTypeUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneTypeUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneTypeRequest,
  proto.protobuf.BookingApiPlaneTypeResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeTypeUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneTypeResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeTypeUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneTypeRequest,
 *   !proto.protobuf.BookingApiPlaneTypeResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneTypeDelete = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneTypeDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneTypeRequest,
  proto.protobuf.BookingApiPlaneTypeResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneTypeResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeTypeDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeDelete,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneTypeResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeTypeDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneTypeDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneTypeDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneRequest,
 *   !proto.protobuf.BookingApiPlaneResponse>}
 */
const methodDescriptor_PlaneApiService_Plane = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/Plane',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneRequest,
  proto.protobuf.BookingApiPlaneResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.plane =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/Plane',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_Plane,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.plane =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/Plane',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_Plane);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneListRequest,
 *   !proto.protobuf.BookingApiPlaneListResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneListRequest,
  proto.protobuf.BookingApiPlaneListResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneList,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.EmptyRequest,
 *   !proto.protobuf.BookingApiPlaneShortListResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneShortList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneShortList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.EmptyRequest,
  proto.protobuf.BookingApiPlaneShortListResponse,
  /**
   * @param {!proto.protobuf.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneShortListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneShortListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneShortListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeShortList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneShortList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneShortList,
      callback);
};


/**
 * @param {!proto.protobuf.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneShortListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeShortList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneShortList',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneShortList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneRequest,
 *   !proto.protobuf.BookingApiPlaneResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneCreate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneRequest,
  proto.protobuf.BookingApiPlaneResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneCreate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneCreate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneCreate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneRequest,
 *   !proto.protobuf.BookingApiPlaneResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneRequest,
  proto.protobuf.BookingApiPlaneResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.BookingApiPlaneRequest,
 *   !proto.protobuf.BookingApiPlaneResponse>}
 */
const methodDescriptor_PlaneApiService_PlaneDelete = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneApiService/PlaneDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.BookingApiPlaneRequest,
  proto.protobuf.BookingApiPlaneResponse,
  /**
   * @param {!proto.protobuf.BookingApiPlaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.BookingApiPlaneResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.BookingApiPlaneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.BookingApiPlaneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneApiServiceClient.prototype.planeDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneDelete,
      callback);
};


/**
 * @param {!proto.protobuf.BookingApiPlaneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.BookingApiPlaneResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneApiServicePromiseClient.prototype.planeDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneApiService/PlaneDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneApiService_PlaneDelete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.PlaneCalculatorApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest,
 *   !proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_GetFlyHourPrice = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/GetFlyHourPrice',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest,
  proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.getFlyHourPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/GetFlyHourPrice',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_GetFlyHourPrice,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiFlyHourPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiFlyHourPriceResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.getFlyHourPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/GetFlyHourPrice',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_GetFlyHourPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingTypeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeList',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeList,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingTypeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeList',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeDelete = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingTypeDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeDelete,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingTypeDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingTypeUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingTypeResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingTypeUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingTypeUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingTypeUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryDelete = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingSubCategoryDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryDelete,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingSubCategoryDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryDelete',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingSubCategoryUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingSubCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingSubCategoryUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingSubCategoryUpdate',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingSubCategoryUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest,
 *   !proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse>}
 */
const methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingList = new grpc.web.MethodDescriptor(
  '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest,
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse,
  /**
   * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.PlaneCalculatorApiServiceClient.prototype.planeCalculatorSettingList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingList',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingList,
      callback);
};


/**
 * @param {!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.PlaneCalculatorApiPlaneCalculatorSettingListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.PlaneCalculatorApiServicePromiseClient.prototype.planeCalculatorSettingList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.PlaneCalculatorApiService/PlaneCalculatorSettingList',
      request,
      metadata || {},
      methodDescriptor_PlaneCalculatorApiService_PlaneCalculatorSettingList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.FileUploaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.FileUploaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.FileApiUploadRequest,
 *   !proto.protobuf.FileApiUploadResponse>}
 */
const methodDescriptor_FileUploader_UnaryUploadFile = new grpc.web.MethodDescriptor(
  '/protobuf.FileUploader/UnaryUploadFile',
  grpc.web.MethodType.UNARY,
  proto.protobuf.FileApiUploadRequest,
  proto.protobuf.FileApiUploadResponse,
  /**
   * @param {!proto.protobuf.FileApiUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.FileApiUploadResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.FileApiUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.FileApiUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.FileApiUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.FileUploaderClient.prototype.unaryUploadFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.FileUploader/UnaryUploadFile',
      request,
      metadata || {},
      methodDescriptor_FileUploader_UnaryUploadFile,
      callback);
};


/**
 * @param {!proto.protobuf.FileApiUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.FileApiUploadResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.FileUploaderPromiseClient.prototype.unaryUploadFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.FileUploader/UnaryUploadFile',
      request,
      metadata || {},
      methodDescriptor_FileUploader_UnaryUploadFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.FileApiUploadRequest,
 *   !proto.protobuf.FileApiUploadResponse>}
 */
const methodDescriptor_FileUploader_FileDelete = new grpc.web.MethodDescriptor(
  '/protobuf.FileUploader/FileDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.FileApiUploadRequest,
  proto.protobuf.FileApiUploadResponse,
  /**
   * @param {!proto.protobuf.FileApiUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.FileApiUploadResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.FileApiUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.FileApiUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.FileApiUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.FileUploaderClient.prototype.fileDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.FileUploader/FileDelete',
      request,
      metadata || {},
      methodDescriptor_FileUploader_FileDelete,
      callback);
};


/**
 * @param {!proto.protobuf.FileApiUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.FileApiUploadResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.FileUploaderPromiseClient.prototype.fileDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.FileUploader/FileDelete',
      request,
      metadata || {},
      methodDescriptor_FileUploader_FileDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.FileApiFileListRequest,
 *   !proto.protobuf.FileApiFileListResponse>}
 */
const methodDescriptor_FileUploader_FileList = new grpc.web.MethodDescriptor(
  '/protobuf.FileUploader/FileList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.FileApiFileListRequest,
  proto.protobuf.FileApiFileListResponse,
  /**
   * @param {!proto.protobuf.FileApiFileListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.FileApiFileListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.FileApiFileListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.FileApiFileListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.FileApiFileListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.FileUploaderClient.prototype.fileList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.FileUploader/FileList',
      request,
      metadata || {},
      methodDescriptor_FileUploader_FileList,
      callback);
};


/**
 * @param {!proto.protobuf.FileApiFileListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.FileApiFileListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.FileUploaderPromiseClient.prototype.fileList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.FileUploader/FileList',
      request,
      metadata || {},
      methodDescriptor_FileUploader_FileList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.ScheduleApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.ScheduleApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.ScheduleApiScheduleListRequest,
 *   !proto.protobuf.ScheduleApiScheduleListResponse>}
 */
const methodDescriptor_ScheduleApiService_ScheduleList = new grpc.web.MethodDescriptor(
  '/protobuf.ScheduleApiService/ScheduleList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.ScheduleApiScheduleListRequest,
  proto.protobuf.ScheduleApiScheduleListResponse,
  /**
   * @param {!proto.protobuf.ScheduleApiScheduleListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ScheduleApiScheduleListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.ScheduleApiScheduleListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ScheduleApiScheduleListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ScheduleApiScheduleListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.ScheduleApiServiceClient.prototype.scheduleList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleList',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleList,
      callback);
};


/**
 * @param {!proto.protobuf.ScheduleApiScheduleListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ScheduleApiScheduleListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.ScheduleApiServicePromiseClient.prototype.scheduleList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleList',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.ScheduleApiScheduleRequest,
 *   !proto.protobuf.ScheduleApiScheduleResponse>}
 */
const methodDescriptor_ScheduleApiService_ScheduleCreate = new grpc.web.MethodDescriptor(
  '/protobuf.ScheduleApiService/ScheduleCreate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.ScheduleApiScheduleRequest,
  proto.protobuf.ScheduleApiScheduleResponse,
  /**
   * @param {!proto.protobuf.ScheduleApiScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ScheduleApiScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ScheduleApiScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ScheduleApiScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.ScheduleApiServiceClient.prototype.scheduleCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleCreate',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleCreate,
      callback);
};


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ScheduleApiScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.ScheduleApiServicePromiseClient.prototype.scheduleCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleCreate',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.ScheduleApiScheduleRequest,
 *   !proto.protobuf.ScheduleApiScheduleResponse>}
 */
const methodDescriptor_ScheduleApiService_ScheduleUpdate = new grpc.web.MethodDescriptor(
  '/protobuf.ScheduleApiService/ScheduleUpdate',
  grpc.web.MethodType.UNARY,
  proto.protobuf.ScheduleApiScheduleRequest,
  proto.protobuf.ScheduleApiScheduleResponse,
  /**
   * @param {!proto.protobuf.ScheduleApiScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ScheduleApiScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ScheduleApiScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ScheduleApiScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.ScheduleApiServiceClient.prototype.scheduleUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleUpdate',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleUpdate,
      callback);
};


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ScheduleApiScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.ScheduleApiServicePromiseClient.prototype.scheduleUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleUpdate',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.ScheduleApiScheduleRequest,
 *   !proto.protobuf.ScheduleApiScheduleResponse>}
 */
const methodDescriptor_ScheduleApiService_ScheduleDelete = new grpc.web.MethodDescriptor(
  '/protobuf.ScheduleApiService/ScheduleDelete',
  grpc.web.MethodType.UNARY,
  proto.protobuf.ScheduleApiScheduleRequest,
  proto.protobuf.ScheduleApiScheduleResponse,
  /**
   * @param {!proto.protobuf.ScheduleApiScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ScheduleApiScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ScheduleApiScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ScheduleApiScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.ScheduleApiServiceClient.prototype.scheduleDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleDelete',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleDelete,
      callback);
};


/**
 * @param {!proto.protobuf.ScheduleApiScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ScheduleApiScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.ScheduleApiServicePromiseClient.prototype.scheduleDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.ScheduleApiService/ScheduleDelete',
      request,
      metadata || {},
      methodDescriptor_ScheduleApiService_ScheduleDelete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.TimezoneApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.TimezoneApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.TimezoneApiTimezoneListRequest,
 *   !proto.protobuf.TimezoneApiTimezoneListResponse>}
 */
const methodDescriptor_TimezoneApiService_TimezoneList = new grpc.web.MethodDescriptor(
  '/protobuf.TimezoneApiService/TimezoneList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.TimezoneApiTimezoneListRequest,
  proto.protobuf.TimezoneApiTimezoneListResponse,
  /**
   * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.TimezoneApiTimezoneListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.TimezoneApiTimezoneListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.TimezoneApiTimezoneListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.TimezoneApiServiceClient.prototype.timezoneList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.TimezoneApiService/TimezoneList',
      request,
      metadata || {},
      methodDescriptor_TimezoneApiService_TimezoneList,
      callback);
};


/**
 * @param {!proto.protobuf.TimezoneApiTimezoneListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.TimezoneApiTimezoneListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.TimezoneApiServicePromiseClient.prototype.timezoneList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.TimezoneApiService/TimezoneList',
      request,
      metadata || {},
      methodDescriptor_TimezoneApiService_TimezoneList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.NotificationApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protobuf.NotificationApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.NotificationApiNotificationListRequest,
 *   !proto.protobuf.NotificationApiNotificationListResponse>}
 */
const methodDescriptor_NotificationApiService_NotificationList = new grpc.web.MethodDescriptor(
  '/protobuf.NotificationApiService/NotificationList',
  grpc.web.MethodType.UNARY,
  proto.protobuf.NotificationApiNotificationListRequest,
  proto.protobuf.NotificationApiNotificationListResponse,
  /**
   * @param {!proto.protobuf.NotificationApiNotificationListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.NotificationApiNotificationListResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.NotificationApiNotificationListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.NotificationApiNotificationListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.NotificationApiNotificationListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.NotificationApiServiceClient.prototype.notificationList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.NotificationApiService/NotificationList',
      request,
      metadata || {},
      methodDescriptor_NotificationApiService_NotificationList,
      callback);
};


/**
 * @param {!proto.protobuf.NotificationApiNotificationListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.NotificationApiNotificationListResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.NotificationApiServicePromiseClient.prototype.notificationList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.NotificationApiService/NotificationList',
      request,
      metadata || {},
      methodDescriptor_NotificationApiService_NotificationList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protobuf.NotificationApiReadNotificationsRequest,
 *   !proto.protobuf.ResultResponse>}
 */
const methodDescriptor_NotificationApiService_ReadNotifications = new grpc.web.MethodDescriptor(
  '/protobuf.NotificationApiService/ReadNotifications',
  grpc.web.MethodType.UNARY,
  proto.protobuf.NotificationApiReadNotificationsRequest,
  proto.protobuf.ResultResponse,
  /**
   * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protobuf.ResultResponse.deserializeBinary
);


/**
 * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protobuf.ResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protobuf.ResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protobuf.NotificationApiServiceClient.prototype.readNotifications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protobuf.NotificationApiService/ReadNotifications',
      request,
      metadata || {},
      methodDescriptor_NotificationApiService_ReadNotifications,
      callback);
};


/**
 * @param {!proto.protobuf.NotificationApiReadNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protobuf.ResultResponse>}
 *     Promise that resolves to the response
 */
proto.protobuf.NotificationApiServicePromiseClient.prototype.readNotifications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protobuf.NotificationApiService/ReadNotifications',
      request,
      metadata || {},
      methodDescriptor_NotificationApiService_ReadNotifications);
};


module.exports = proto.protobuf;

