import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Button, Form, Input, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {clearChatMessages, getChatMessages, IChat, sendMessage} from "../../../store/reducers/chatReducer/chatReducer";
import {CustomCard} from "../../UI/AntCustom/CustomCard";
import {SendOutlined} from "@ant-design/icons";
import {ChatMyMessage} from "./ChatMyMessage";
import {ChatStrangerMessage} from "./ChatStrangerMessage";
import {useIsMobile} from "../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";
import {Loader} from "../../UI/Loader/Loader";
import {CustomModal} from "../../UI/AntCustom/CustomModal";
import {defineMessages, useIntl} from "react-intl";
import styled from "styled-components";
import {readNotifications} from "../../../store/reducers/notificationsReducer/notificationsReducer";

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 16px;
`;

const LoaderWrapper = styled.div`
  max-width: inherit;
  overflow: hidden;
`;

const CustomChatModal = styled(CustomModal)`
  @media(max-width: ${mediaScreenSizes.mobile}) {
    max-width: none !important;
    width: 100vw !important;
    height: 100vh;
    height: var(--app-height);
    margin: 0;

    .ant-modal-content {
      border-radius: 0;
      height: 100vh;
      height: var(--app-height);
    }
    
    .ant-modal-body {
      padding: 10px;
    }
  }
`;

const ChatModalMessages = defineMessages({
  yourMessage: {
    id: "chatModal.yourMessage",
    defaultMessage: "Your message",
  }
})

interface IChatModal {
  chat: IChat;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const ChatModal: FC<IChatModal> = ({
                                            chat,
                                            isVisible,
                                            setIsVisible,
                                          }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const {
    chatMessages,
    isChatMessagesLoading,
    isMessageSendLoading
  } = useAppSelector(state => state.chat);
  const myCompanyId = useAppSelector(state => state.company.oneItemObject.item.id)
  const [newMessageText, setNewMessageText] = useState<string>('');
  const chatName = chat.fromUser.company.companyId !== myCompanyId ? chat.fromUser.company.companyName : chat.toUser.company.companyName;
  const allUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications).filter(n => n.entityId === chat.chatId);

  useEffect(() => {
    dispatch(getChatMessages({
      chatId: chat.chatId,
    }))

    return () => {
      dispatch(clearChatMessages());
    }
  }, []);

  useEffect(() => {
    if (allUnreadNotifications.length > 0)
      dispatch(readNotifications({notificationIds: allUnreadNotifications.map(n => n.id)}))
  }, [allUnreadNotifications.length])

  const isSendButtonDisabled = useMemo(() => {
    return newMessageText.replace(new RegExp(" ", "g"), '') === "";
  }, [newMessageText]);

  const onMessageSend = () => {
    dispatch(sendMessage({
      chatId: chat.chatId,
      orderId: chat.orderId,
      toUserId: chat.toUser.userId,
      messageText: newMessageText.trim(),
    }));

    setNewMessageText('');
  }

  return (
    <CustomChatModal
      visible={isVisible}
      title={chatName}
      centered
      footer={false}
      onCancel={() => setIsVisible(false)}
      afterClose={() => dispatch(clearChatMessages())}
      bodyStyle={{
        paddingTop: 0,
      }}
    >
      <CustomCard width={'max'}
                  noBottomBorderRadius={true}
                  borderless={true}
                  boxShadow={false}
                  bodyStyle={{
                    padding: 0,
                    boxShadow: "none",
                  }}
                  style={{
                    height: isMobile ? 'calc(100vh - 127px)' : '400px',
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflowX: "hidden",
                    padding: "16px 0",
                    overflow: "auto",
                    borderRadius: 0,
                  }}>
        {isChatMessagesLoading
          ? <LoaderWrapper><Loader/></LoaderWrapper>
          : <MessagesWrapper>
            {chatMessages.map(cm =>
              cm.fromUser.company.companyId === myCompanyId
                ? <ChatMyMessage key={cm.chatMessageId} messageText={cm.messageText} sendDate={cm.sendDate}/>
                : <ChatStrangerMessage key={cm.chatMessageId} messageText={cm.messageText} sendDate={cm.sendDate}/>)}
          </MessagesWrapper>
        }
      </CustomCard>
      <Form onFinish={() => onMessageSend()}>
        <Row wrap={false}>

          <Input placeholder={intl.formatMessage(ChatModalMessages.yourMessage)}
                 value={newMessageText}
                 onChange={(e) => setNewMessageText(e.target.value)}
                 autoFocus
          />
          <Button type={'primary'} htmlType={'submit'} loading={isMessageSendLoading}
                  disabled={isSendButtonDisabled}><SendOutlined/></Button>
        </Row>
      </Form>

    </CustomChatModal>
  );
};
