import {ChatEntity} from "../../../proto/generated/api_entities_pb";
import {IChat} from "./chatReducer";

export const mapChatEntityToIChat = (item: ChatEntity): IChat => {
  const fromUser = item.getFromuser();
  if (!fromUser)
    throw new Error("Null fromUser in received ChatEntity");

  const toUser = item.getTouser();
  if (!toUser)
    throw new Error("Null toUser in received ChatEntity");

  const fromCompany = item.getFromcompany();
  if (!fromCompany)
    throw new Error("Null fromCompany in received ChatEntity");

  const toCompany = item.getTocompany();
  if (!toCompany)
    throw new Error("Null toCompany in received ChatEntity");

  return {
    chatId: item.getChatid(),
    orderId: item.getOrderid(),
    fromUser: {
      userId: fromUser.getUserid(),
      firstName: fromUser.getFirstname(),
      lastName: fromUser.getLastname(),
      middleName: fromUser.getMiddlename(),
      company: {
        companyId: fromCompany.getCompanyid(),
        companyName: fromCompany.getCompanyname(),
      }
    },
    toUser: {
      userId: toUser.getUserid(),
      firstName: toUser.getFirstname(),
      lastName: toUser.getLastname(),
      middleName: toUser.getMiddlename(),
      company: {
        companyId: toCompany.getCompanyid(),
        companyName: toCompany.getCompanyname(),
      }
    },
  }
}