import React, {FC, useEffect} from 'react';
import {useAppSelector} from "../store/store";
import {RouteDescriptor} from "../config/acl/routes/RouteDescriptor";
import {Navigate} from "react-router-dom";
import {roles} from "../config/acl/roles";
import {Route} from "../config/acl/routes/Route";
import {pathFromRoute} from "../utils/helpers/acl/pathFromRoute";
import {useIntl} from "react-intl";

type Props = {
  route: RouteDescriptor<any>
}

export const ACL: FC<Props> = ({route}) => {
  const intl = useIntl();
  const {role} = useAppSelector(state => state.auth);
  const Page = route.render;

  useEffect(() => {
    document.title = intl.formatMessage(route.title);
  }, [route]);

  return route.allowRoles.some(r => r === role)
    ? <Page/>
    : route.redirectToHomeRoles.some(r => r === role)
      ? <Navigate to={pathFromRoute(roles[role].homeRoute)} replace={true}/>
      : <Navigate to={pathFromRoute(Route.notFound)} replace={true}/>
  ;
};
