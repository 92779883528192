import React from 'react';
import styled from "styled-components";
import {PlaneCard} from "./PlaneCard";
import {useAppSelector} from "../../../../../store/store";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const PlaneListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  gap: 20px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
    gap: 8px;
  }
`;

export const PlaneList = () => {
  const {items} = useAppSelector(state => state.fleet.itemsObject);

  return (
    <PlaneListStyled>
      {items.map(p => <PlaneCard key={p.id} plane={p} />)}
    </PlaneListStyled>
  );
};
