import {CommonState, RemoteActionState} from "../highOrderReducers/commonReducer";

export enum RequestTypeEnum {
  ONE_WAY = 1,
  ROUND_TRIP = 2,
  MULTI_LEG = 3,
}

export interface IOneWayRequestPayload {
  airportFromId: number;
  airportToId: number;
  departureDate: Date;
  pax: number;
  planeCategoryIds: number[];
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

export interface IRoundTripRequestPayload {
  airportFromId: number;
  airportToId: number;
  departureDateFrom: Date;
  paxFrom: number;
  departureDateTo: Date;
  paxTo: number;
  planeCategoryIds: number[];
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

type MultiLegRequestItem = {
  airportFromId: number;
  airportToId: number;
  departureDate: Date;
  pax: number;
}

export interface IMultiLegRequestPayload {
  routes: MultiLegRequestItem[];
  planeCategoryIds: number[];
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

export interface INewRequestInitialState extends CommonState<unknown>{
  oneWayRequest: RemoteActionState,
  roundTripRequest: RemoteActionState,
  multiLegRequest: RemoteActionState,
}