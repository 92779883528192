import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  IArchiveOrderPayload,
  IChangeEmptyLegOrderRouteWithdrawPayload,
  IChartererRequestsInitialState, IGetChartererOrderListPayload, ISetArchiveOrderResultPayload,
  ISetChangeEmptyLegOrderRouteWithdrawResultPayload, ISetChartererOrderListResultPayload,
} from "./chartererRequestsReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction, initCommonClearDelete,
  initCommonClearList, initCommonDelete, initCommonDeleteResult,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";
import {EmptyLegOrderRouteStatusEnum} from "../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {mapOrderEntityToIChartererOrder} from "./chartererRequestsMapper";


const chartererRequestsInitialState: IChartererRequestsInitialState = {
  itemsObject: {
    items: [],
    totalCount: 0,
    isLoading: true,
  },
  changeEmptyLegOrderRouteWithdraw: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const chartererRequestsSlice = createSlice({
    name: 'chartererRequests',
    initialState: chartererRequestsInitialState,
    reducers: {
      getChartererOrderList(state, {payload}: PayloadAction<IGetChartererOrderListPayload>) {
        initCommonGetList(state, payload);
      },
      clearChartererOrderList(state) {
        initCommonClearList(state);
      },
      setChartererOrderListResult(state, {payload}: PayloadAction<ISetChartererOrderListResultPayload>) {
        initCommonSetListResult(state, payload, mapOrderEntityToIChartererOrder)
      },

      changeEmptyLegOrderRouteWithdraw(state, {payload}: PayloadAction<IChangeEmptyLegOrderRouteWithdrawPayload>) {
        initCommonAction(state, nameof<IChartererRequestsInitialState>("changeEmptyLegOrderRouteWithdraw"));
      },
      clearChangeEmptyLegOrderRouteWithdraw(state) {
        initCommonClearAction(state, nameof<IChartererRequestsInitialState>("changeEmptyLegOrderRouteWithdraw"));
      },
      setChangeEmptyLegOrderRouteWithdrawResult(state, {payload}: PayloadAction<ISetChangeEmptyLegOrderRouteWithdrawResultPayload>) {
        if (payload.emptyLegOrderRoute) {
          const {emptyLegOrderRoute} = payload;
          const updatedOrderRouteId = emptyLegOrderRoute.getOrderrouteid();
          const updatedEmptyLegId = emptyLegOrderRoute.getEmptylegid();
          const updatedEmptyLegOrderRouteStatus = emptyLegOrderRoute.getStatus() as EmptyLegOrderRouteStatusEnum;

          state.itemsObject.items = state.itemsObject.items.map(order => {
            return order.orderRoutes.some(orderRoute => orderRoute.id === updatedOrderRouteId)
              ? {
                ...order, nestedEmptyLegs: order.nestedEmptyLegs.map(nestedEmptyLeg => nestedEmptyLeg.emptyLegId !== updatedEmptyLegId ? nestedEmptyLeg : {
                  ...nestedEmptyLeg, status: updatedEmptyLegOrderRouteStatus
                })
              } : order
          } );
        }

        initCommonActionResult(state, payload, nameof<IChartererRequestsInitialState>("changeEmptyLegOrderRouteWithdraw"));
      },

      archiveOrder(state, {payload}: PayloadAction<IArchiveOrderPayload>) {
        initCommonDelete(state, payload);
      },
      clearArchiveOrder(state) {
        initCommonClearDelete(state);
      },
      setArchiveOrderResult(state, {payload}: PayloadAction<ISetArchiveOrderResultPayload>) {
        initCommonDeleteResult(state, payload);
      },
    }
  }
);

export const {
  getChartererOrderList,
  clearChartererOrderList,
  setChartererOrderListResult,

  changeEmptyLegOrderRouteWithdraw,
  clearChangeEmptyLegOrderRouteWithdraw,
  setChangeEmptyLegOrderRouteWithdrawResult,

  archiveOrder,
  clearArchiveOrder,
  setArchiveOrderResult
} =
  chartererRequestsSlice.actions;
export default chartererRequestsSlice.reducer;