import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import styled from "styled-components";
import {UploadFile} from "antd/es/upload";
import {PlanePhotos} from "./PlanePhotos";
import {IPlane} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {PlaneEditForm} from "./PlaneEditForm";
import {CustomDrawer} from "../../../../../components/UI/AntCustom/CustomDrawer";
import {FlyHourPriceContextProvider} from "./flyHourPriceCalculator/context/FlyHourPriceContextProvider";
import {FlyHourPriceCalculatorDrawer} from "./flyHourPriceCalculator/FlyHourPriceCalculatorDrawer";
import {
  FlyHourPriceDefaultCategoryEnum, FlyHourPriceDefaultSettingTypeEnum,
  FlyHourPriceDefaultSubcategoryEnum
} from "./flyHourPriceCalculator/types/flyHourPriceSettingEnums";
import {Form} from "antd";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const DrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const FormSection = styled.div`
  width: 380px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    width: 100%;
  }
`;

interface IPlaneEditDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  plane: IPlane;
}

export const PlaneEditDrawer: FC<IPlaneEditDrawer> = ({isOpen, setIsOpen, plane}) => {
  const [planeEditForm] = Form.useForm();
  const [isCalculatorOpen, setIsCalculatorOpen] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<UploadFile[]>(plane.imageFiles);

  const flyHoursCount = useMemo(() => plane.calculatorCategories
    .find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_BASE)?.subcategories
    .find(sc => sc.keyName === FlyHourPriceDefaultSubcategoryEnum.SUB_CATEGORY_BASE)?.settingTypes
    .find(st => st.keyName === FlyHourPriceDefaultSettingTypeEnum.PARAMETER_BASE_FLY_HOURS)?.setting
    .value ?? 0, []);

  const constantCategory = useMemo(() => plane.calculatorCategories.find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_CONSTANT), []);
  const changeableCategory = useMemo(() => plane.calculatorCategories.find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_CHANGEABLE), []);
  const marginCategory = useMemo(() => plane.calculatorCategories.find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_MARGIN), []);
  const coefficientCategory = useMemo(() => plane.calculatorCategories.find(c => c.keyName === FlyHourPriceDefaultCategoryEnum.CATEGORY_COEFFICIENT), []);

  return (
    <FlyHourPriceContextProvider
      initialFlyHoursCount={flyHoursCount}
      initialFlyHourPrice={plane.flyHourPrice}
      initialCurrency={plane.flyHourCurrency}
      initialConstantCategory={constantCategory}
      initialChangeableCategory={changeableCategory}
      initialMarginCategory={marginCategory}
      initialCoefficientCategory={coefficientCategory}
      isCalculatorOpen={isCalculatorOpen}
    >
      {<CustomDrawer visible={isOpen && !isCalculatorOpen} onClose={() => setIsOpen(false)}>
        <DrawerContent>
          <PlanePhotos imageFiles={imageFiles} edit={{setImageFiles: setImageFiles}}/>

          <FormSection>
            <PlaneEditForm form={planeEditForm} closeDrawer={() => setIsOpen(false)} imageFiles={imageFiles}
                           plane={plane} setIsCalculatorOpen={setIsCalculatorOpen}/>
          </FormSection>
        </DrawerContent>
      </CustomDrawer>}

      {isCalculatorOpen && <FlyHourPriceCalculatorDrawer isOpen={isCalculatorOpen} setIsOpen={setIsCalculatorOpen} />}
    </FlyHourPriceContextProvider>
  );
};