import {
  BookingApiAirportListRequest,
  BookingApiAirportListResponse,
  BookingApiEmptyLegListRequest,
  BookingApiEmptyLegListResponse,
  BookingApiEmptyLegOrderRouteRequest,
  BookingApiEmptyLegOrderRouteResponse,
  BookingApiEmptyLegRequest,
  BookingApiEmptyLegResponse,
  BookingApiGetAirportRequest,
  BookingApiGetAirportResponse,
  BookingApiOrderListRequest,
  BookingApiOrderListResponse,
  BookingApiOrderRequest,
  BookingApiOrderRequestListRequest,
  BookingApiOrderRequestListResponse,
  BookingApiOrderRequestRequest,
  BookingApiOrderRequestResponse,
  BookingApiOrderResponse,
  BookingApiOrderRouteListRequest,
  BookingApiOrderRouteListResponse, BookingApiOrderRouteRequest, BookingApiOrderRouteResponse,
} from "../../proto/generated/api_entities_pb";
import {API} from "../API";
import {BookingApiServiceClient} from "../../proto/generated/api_entities_grpc_web_pb";
import {getProxyUrl} from "../helpers/getProxyUrl";

const client = new BookingApiServiceClient(getProxyUrl(), null, null);

export class BookingApi {
  static async getAirportList(req: BookingApiAirportListRequest): Promise<BookingApiAirportListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiAirportListRequest, BookingApiAirportListResponse>(client, client.airportList, req, resolve, reject)
    })
  }

  static async getAirportById(req: BookingApiGetAirportRequest): Promise<BookingApiGetAirportResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiGetAirportRequest, BookingApiGetAirportResponse>(client, client.airport, req, resolve, reject)
    })
  }

  static async orderCreate(req: BookingApiOrderRequest): Promise<BookingApiOrderResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequest, BookingApiOrderResponse>(client, client.orderCreate, req, resolve, reject)
    });
  }

  static async getEmptyLegList(req: BookingApiEmptyLegListRequest): Promise<BookingApiEmptyLegListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegListRequest, BookingApiEmptyLegListResponse>(client, client.emptyLegList, req, resolve, reject)
    });
  }

  static async createEmptyLeg(req: BookingApiEmptyLegRequest): Promise<BookingApiEmptyLegResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegRequest, BookingApiEmptyLegResponse>(client, client.emptyLegCreate, req, resolve, reject)
    });
  }

  static async editEmptyLeg(req: BookingApiEmptyLegRequest): Promise<BookingApiEmptyLegResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegRequest, BookingApiEmptyLegResponse>(client, client.emptyLegUpdate, req, resolve, reject)
    });
  }

  static async archiveEmptyLeg(req: BookingApiEmptyLegRequest): Promise<BookingApiEmptyLegResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegRequest, BookingApiEmptyLegResponse>(client, client.emptyLegArchive, req, resolve, reject)
    });
  }

  static async deleteEmptyLeg(req: BookingApiEmptyLegRequest): Promise<BookingApiEmptyLegResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegRequest, BookingApiEmptyLegResponse>(client, client.emptyLegDelete, req, resolve, reject)
    });
  }

  static async getOrderList(req: BookingApiOrderListRequest): Promise<BookingApiOrderListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderListRequest, BookingApiOrderListResponse>(client, client.orderList, req, resolve, reject)
    });
  }

  static async orderArchive(req: BookingApiOrderRequest): Promise<BookingApiOrderResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequest, BookingApiOrderResponse>(client, client.orderArchive, req, resolve, reject)
    });
  }

  static async getOrderRouteList(req: BookingApiOrderRouteListRequest): Promise<BookingApiOrderRouteListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRouteListRequest, BookingApiOrderRouteListResponse>(client, client.orderRouteList, req, resolve, reject)
    });
  }

  static async orderRouteArchive(req: BookingApiOrderRouteRequest): Promise<BookingApiOrderRouteResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRouteRequest, BookingApiOrderRouteResponse>(client, client.orderRouteArchive, req, resolve, reject)
    });
  }

  static async orderRequestCreate(req: BookingApiOrderRequestRequest): Promise<BookingApiOrderRequestResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequestRequest, BookingApiOrderRequestResponse>(client, client.orderRequestCreate, req, resolve, reject)
    });
  }

  // Обновление статуса OrderRequest
  static async orderRequestUpdate(req: BookingApiOrderRequestRequest): Promise<BookingApiOrderRequestResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequestRequest, BookingApiOrderRequestResponse>(client, client.orderRequestUpdate, req, resolve, reject)
    });
  }

  static async orderRequestList(req: BookingApiOrderRequestListRequest): Promise<BookingApiOrderRequestListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequestListRequest, BookingApiOrderRequestListResponse>(client, client.orderRequestList, req, resolve, reject)
    });
  }

  static async orderRequestArchive(req: BookingApiOrderRequestRequest): Promise<BookingApiOrderRequestResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderRequestRequest, BookingApiOrderRequestResponse>(client, client.orderRequestArchive, req, resolve, reject)
    });
  }

  static async orderList(req: BookingApiOrderListRequest): Promise<BookingApiOrderListResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiOrderListRequest, BookingApiOrderListResponse>(client, client.orderList, req, resolve, reject)
    });
  }

  // Обновление статуса связки EmptyLeg и OrderRoute
  static async emptyLegOrderRouteUpdate(req: BookingApiEmptyLegOrderRouteRequest): Promise<BookingApiEmptyLegOrderRouteResponse> {
    return new Promise((resolve, reject) => {
      API.call<BookingApiEmptyLegOrderRouteRequest, BookingApiEmptyLegOrderRouteResponse>(client, client.emptyLegOrderRouteUpdate, req, resolve, reject)
    });
  }
}