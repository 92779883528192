import React, {FC} from "react";
import {
  FlightDrawerFooterContent,
  FlightDrawerFooterContentWrapper,
  FlightDrawerFooterPrice,
  FlightDrawerFooterPriceLabel,
  FlightDrawerFooterPriceValue,
  FlightDrawerFooterStatus,
  FlightDrawerFooterStatusAndAction
} from "./FlightDrawerFooterStyledComponents";
import {defineMessages, useIntl} from "react-intl";
import {
  useOrderRequestStatusName
} from "../../../../utils/constans/statuses/orderRequestStatuses";
import {
  IFreighterOrderRequest
} from "../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {DisplayFlyPrice} from "../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";


const FlightDrawerOrderRequestOrderRouteFooterMessages = defineMessages({
  price: {
    id: "flightDrawerOrderRequestOrderRouteFooter.price",
    defaultMessage: "Price"
  }
})

interface IFlightDrawerOrderRequestOrderRouteFooter {
  orderRequest: IFreighterOrderRequest;
}

export const FlightDrawerOrderRequestOrderRouteFooter: FC<IFlightDrawerOrderRequestOrderRouteFooter> = ({
                                                                                                          orderRequest,
                                                                                                        }) => {
  const intl = useIntl();
  const orderRequestStatusName = useOrderRequestStatusName(orderRequest.status);

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      <FlightDrawerFooterStatusAndAction>
        <FlightDrawerFooterPrice>
          <FlightDrawerFooterPriceLabel>{intl.formatMessage(FlightDrawerOrderRequestOrderRouteFooterMessages.price)}</FlightDrawerFooterPriceLabel>
          {orderRequest.price && orderRequest.currency &&
            <FlightDrawerFooterPriceValue>{DisplayFlyPrice(orderRequest.price, orderRequest.currency)}</FlightDrawerFooterPriceValue>}
        </FlightDrawerFooterPrice>
      </FlightDrawerFooterStatusAndAction>

      <FlightDrawerFooterStatus status={orderRequest.status}>
        {orderRequestStatusName}
      </FlightDrawerFooterStatus>
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
};