import React, {Dispatch, FC, SetStateAction} from "react";
import {defineMessages, useIntl} from "react-intl";
import {CustomModal} from "../../../../../components/UI/AntCustom/CustomModal";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {Col, Form, FormListFieldData, message, Row} from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {CommonUnitMessages} from "../../../../../intl/commonMessages/CommonUnitMessages";
import {onFinishTrimmed} from "../../../../../utils/helpers/formHelpers/onFinishTrimmed";

const EditTechnicalStopModalMessages = defineMessages({
  title: {
    id: "editTechnicalStopModal.title",
    defaultMessage: "Technical stop",
  }
});


interface IEditTechnicalStopFormData {
  hours: number;
  minutes: number;
}

interface IEditTechnicalStopModal {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  field: FormListFieldData;
}

export const EditTechnicalStopModal: FC<IEditTechnicalStopModal> = ({isModalVisible, setIsModalVisible, field}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const [techStopForm] = Form.useForm();

  const hours = form.getFieldValue(["routes", field.name, "techStopHours"]);
  const minutes = form.getFieldValue(["routes", field.name, "techStopMinutes"]);


  const onTechnicalStopSave = (values: IEditTechnicalStopFormData) => {
    const {hours, minutes} = values;
    setTechStop(hours, minutes);
  }


  const setTechStop = (hours?: number, minutes?: number) => {
    const fields = form.getFieldsValue();
    const {routes} = fields;
    Object.assign(routes[field.key], {
      techStopHours: hours ?? 0,
      techStopMinutes: minutes ?? 0,
    });
    form.setFieldsValue({routes})

    setIsModalVisible(false);
  }


  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }


  const initialValues: Partial<IEditTechnicalStopFormData> = {
    hours: hours !== 0 ? hours : undefined,
    minutes: minutes !== 0 ? minutes : undefined,
  }


  return <CustomModal
    title={intl.formatMessage(EditTechnicalStopModalMessages.title)}
    visible={isModalVisible}
    onCancel={() => setIsModalVisible(false)}
    centered
    footer={null}
  >
    <Form
      form={techStopForm}
      layout={'vertical'}
      onFinish={(values) => onFinishTrimmed(values, onTechnicalStopSave)}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      <Row gutter={8}>
        <Col span={12}>
          <CustomFieldInput
            name={'hours'}
            label={intl.formatMessage(CommonFormMessages.hoursLabel)}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
              {max: 99, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
            ]}
          >
            <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.hour)}
                               maxLength={2}
                               precision={0}/>
          </CustomFieldInput>
        </Col>

        <Col span={12}>
          <CustomFieldInput
            name={'minutes'}
            label={intl.formatMessage(CommonFormMessages.minutesLabel)}
            rules={[
              {required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)},
              {min: 0, type: "number", message: intl.formatMessage(CommonFormMessages.ruleMinimumZero)},
              {max: 59, type: "number", message: intl.formatMessage(CommonFormMessages.ruleHugeNumber)},
            ]}
          >
            <CustomInputNumber placeholder={"__"} addonAfter={intl.formatMessage(CommonUnitMessages.minute)}
                               maxLength={2}
                               precision={0}/>
          </CustomFieldInput>
        </Col>
      </Row>

      <CustomButton htmlType={'submit'} type={'primary'} style={{width: "100%"}}>
        {intl.formatMessage(CommonButtonMessages.save)}
      </CustomButton>

      {hours !== 0 && minutes !== 0 && <CustomButton danger style={{width: "100%", marginTop: 8}} onClick={() => setTechStop()}>
        {intl.formatMessage(CommonButtonMessages.delete)}
      </CustomButton>}
    </Form>
  </CustomModal>
}