import {
  PlaneCalculatorApiFlyHourPriceRequest,
  PlaneCalculatorSettingEntity,
} from "../../../../../../../proto/generated/api_entities_pb";
import {API} from "../../../../../../../API/API";
import {message} from "antd";
import {IFlyHourCalculatorCategory} from "../../../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {Logger} from "../../../../../../../utils/logger/Logger";
import {Dispatch, SetStateAction} from "react";
import {
  mapIFlyHourCalculatorCategoriesToPlaneCalculatorSettingEntities
} from "../../../../../../../store/reducers/fleetReducer/fleetMappers";


interface GetFlyHourPriceCalculationProps {
  price: number;
  settings: PlaneCalculatorSettingEntity[];
}

export const getFlyHourPriceCalculation = async (flyHoursCount: number, categories: IFlyHourCalculatorCategory[], previousSettings: PlaneCalculatorSettingEntity[], previousPrice: number = 0, setIsLoading?: Dispatch<SetStateAction<boolean>>): Promise<GetFlyHourPriceCalculationProps> => {
  if (flyHoursCount) {
    setIsLoading && setIsLoading(true);

    const settingEntities = mapIFlyHourCalculatorCategoriesToPlaneCalculatorSettingEntities(flyHoursCount, categories);

    const req = new PlaneCalculatorApiFlyHourPriceRequest().setPlanecalculatorsettingentitiesList(settingEntities);

    const res = await API.getFlyHourPrice(req);

    setIsLoading && setIsLoading(false);

    const resultResponse = res.getResultresponse();
    if (resultResponse?.getIssuccessful())
      return {price: res.getPrice(), settings: settingEntities}

    message.error('Error while calculation')
  } else {
    Logger.error('Fly hours count was not set');
  }

  return {price: previousPrice, settings: previousSettings};
}