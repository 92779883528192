import React, {FC, useMemo, useState} from 'react';
import styled from "styled-components";
import BrokerAvatar from "../../../../../media/images/default_avatar.png";
import {
  EmptyLegOrderRouteStatusColorMap,
  EmptyLegOrderRouteStatusEnum, useEmptyLegOrderRouteStatusName,
} from "../../../../../utils/constans/statuses/emptyLegOrderRouteStatuses";
import {ChatModal} from "../../../../../components/businessCommon/chat/ChatModal";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {changeEmptyLegRespondStatus} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {IEmptyLeg, IEmptyLegOrder} from "../../../../../store/reducers/emptyLegsReducer/emptyLegsReducerTypes";
import {useChat} from "../../../../../components/businessCommon/chat/useChat";
import {ReactComponent as ClockIcon} from "../../../../../media/icons/clock.svg";
import {ReactComponent as AcceptIcon} from "../../../../../media/icons/accept.svg";
import {ReactComponent as DeclineIcon} from "../../../../../media/icons/decline.svg";
import {ReactComponent as CaretRightIcon} from "../../../../../media/icons/caret_right.svg";
import {ReactComponent as ReturnIcon} from "../../../../../media/icons/return.svg";
import {DetailItem} from "../../../../../components/businessCommon/detailItem/DetailItem";
import {StatusShield} from "../../../../../components/businessCommon/respondsInfo/RespondsInfoBlock";
import {CustomLinkButton} from "../../../../../components/UI/Buttons/CustomLinkButton";
import {
  EmptyLegRespondBrokerDrawer
} from "../../../../../components/businessCommon/brokerDrawer/wrappers/EmptyLegRespondBrokerDrawer";
import {DisplayManagerFullName} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {Logger} from "../../../../../utils/logger/Logger";
import {ChatButton} from "../../../../../components/businessCommon/chat/ChatButton";
import {readNotifications} from "../../../../../store/reducers/notificationsReducer/notificationsReducer";
import {NotificationType} from "../../../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";

const RespondCard = styled.div<{ hasNotifications: boolean }>`
  background: #FFFFFF;
  padding: 16px 16px 24px 16px;
  border-radius: 10px;
  border: 2px solid ${props => props.hasNotifications ? "#1890FF" : "#D9D9D9"};
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    padding: 16px 8px 24px 8px;
    border: 1px solid ${props => props.hasNotifications ? "#1890FF" : "#D9D9D9"};
  }
`;

const RespondHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const BrokerInfoAndImage = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  img {
    width: 44px;
    height: 44px;
  }
`;

const BrokerInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  div {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #438EF7;
    transition: 0.2s ease;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`;

const RespondDetails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;

const RespondDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    :first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    :last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 3px;

      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #040404;
    }
  }
`;

const RespondDetailItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  
  & > div {
    flex-grow: 1;
  }
`;

const RespondActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`

const ActionButton = styled.button<{ buttonType: 'approve' | 'decline' | 'default' }>`
  border: none;
  border-radius: 10px;
  outline: none;
  background: ${props => (props.buttonType === 'approve' && "#63A070")
          || (props.buttonType === 'decline' && "#C05353")
          || (props.buttonType === 'default' && "#1890FF")};
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`;

const RespondActionsLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 5px;
`;

const RespondStatusLeft = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;


const EmptyLegRespondCardMessages = defineMessages({
  respond: {
    id: "emptyLegRespondCard.respond",
    defaultMessage: "Respond",
  }
})

interface IEmptyLegRespondCard {
  indexNumber: number,
  emptyLegOrder: IEmptyLegOrder;
  emptyLeg: IEmptyLeg;
}

export const EmptyLegRespondCard: FC<IEmptyLegRespondCard> = ({
                                                                indexNumber,
                                                                emptyLegOrder,
                                                                emptyLeg,
                                                              }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const emptyLegOrderRouteStatusName = useEmptyLegOrderRouteStatusName(emptyLegOrder.status);
  const userId = useAppSelector(state => state.user.oneItemObject.item.id);
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const [isBrokerDrawerOpen, setIsBrokerDrawerOpen] = useState<boolean>(false);
  const [isChatOpened, setIsChatOpened] = useState<boolean>(false);
  const chat = useChat(userId, emptyLegOrder.manager.id, emptyLegOrder.orderId);
  const allUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)

  const hasNotifications = useMemo(() => {
    const unreadNotifications = allUnreadNotifications.filter(n => n.metadata.emptyLegId === emptyLeg.id
      && n.metadata.emptyLegOrderRouteId === emptyLegOrder.emptyLegOrderRouteId);
    if (unreadNotifications.length > 0) {
      dispatch(readNotifications({
        notificationIds: unreadNotifications.filter(n => n.type !== NotificationType.TYPE_INCOME_MESSAGE).map(n => n.id)
      }));
    }
    return unreadNotifications.length > 0;
  }, []);

  const unreadChatNotifications = useMemo(() => allUnreadNotifications.filter(n => n.entityId === chat?.chatId
    && n.metadata.emptyLegId === emptyLeg.id
    && n.metadata.emptyLegOrderRouteId === emptyLegOrder.emptyLegOrderRouteId), [allUnreadNotifications]);

  if (!chat)
    Logger.error(`No chat found for: userId (account owner): ${userId}, agencyManagerId: ${emptyLegOrder.manager.id} orderId: ${emptyLegOrder.orderId}`)

  const onChatOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsChatOpened(true);
  }

  const changeStatus = (status: EmptyLegOrderRouteStatusEnum) => {
    dispatch(changeEmptyLegRespondStatus({
      emptyLegId: emptyLeg.id,
      orderRouteId: emptyLegOrder.orderRouteId,
      status: status,
    }))
  }

  const renderRespondActions = () => {
    const statusShield = <StatusShield backgroundColor={EmptyLegOrderRouteStatusColorMap[emptyLegOrder.status]}>
      {emptyLegOrderRouteStatusName}
    </StatusShield>

    if (emptyLegOrder.status === EmptyLegOrderRouteStatusEnum.ACTIVE)
      return <RespondActionsLeft>
        {emptyLeg.isArchived && statusShield}
        {!emptyLeg.isArchived && <>
          <ActionButton buttonType={"decline"}
                        onClick={() => changeStatus(EmptyLegOrderRouteStatusEnum.DECLINED)}><DeclineIcon/></ActionButton>
          <ActionButton buttonType={"approve"}
                        onClick={() => changeStatus(EmptyLegOrderRouteStatusEnum.APPROVED)}><AcceptIcon/></ActionButton>
        </>}
      </RespondActionsLeft>
    else {
      return <RespondStatusLeft>
        {statusShield}
        {!emptyLeg.isArchived && emptyLegOrder.status !== EmptyLegOrderRouteStatusEnum.WITHDRAWN &&
          <CustomLinkButton icon={<ReturnIcon/>} onClick={() => changeStatus(EmptyLegOrderRouteStatusEnum.ACTIVE)}>
            {intl.formatMessage(CommonButtonMessages.return)}
          </CustomLinkButton>}
      </RespondStatusLeft>
    }
  }

  return (
    <>
      <RespondCard hasNotifications={hasNotifications}>
        <RespondHeader>
          <BrokerInfoAndImage>
            <img src={emptyLegOrder.company.avatar?.url || BrokerAvatar} alt={"Broker"}/>

            <BrokerInfo>
              <div>{DisplayManagerFullName(emptyLegOrder.manager)}</div>
              <span onClick={() => setIsBrokerDrawerOpen(true)}>{emptyLegOrder.company.name}</span>
            </BrokerInfo>
          </BrokerInfoAndImage>

          <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen} />
        </RespondHeader>

        <RespondDetails>
          <RespondDetailsHeader>
            <div>{intl.formatMessage(EmptyLegRespondCardMessages.respond)} №{indexNumber}</div>
            <div>
              <ClockIcon/>
              <div>{formatDate(emptyLegOrder.createdDate, offset, "datetime_short_year")}</div>
            </div>
          </RespondDetailsHeader>

          <RespondDetailItems>
            <DetailItem size={"small"} label={intl.formatMessage(CommonDetailsMessages.departureDate)}
                        value={`${formatDate(emptyLegOrder.departureDate, emptyLegOrder.airportFrom.timezone.offset, "date")}`}/>
            <DetailItem size={"small"} label={intl.formatMessage(CommonDetailsMessages.departureTime)}
                        value={`${formatDate(emptyLegOrder.departureDate, emptyLegOrder.airportFrom.timezone.offset, "time")}`}/>
            <DetailItem size={"small"} label={intl.formatMessage(CommonDetailsMessages.pax)} value={`${emptyLegOrder.pax}`}/>
          </RespondDetailItems>
        </RespondDetails>

        <RespondActions>
          {renderRespondActions()}
          <ActionButton buttonType={"default"}
                        onClick={() => setIsBrokerDrawerOpen(true)}><CaretRightIcon/></ActionButton>
        </RespondActions>
      </RespondCard>

      {isBrokerDrawerOpen &&
        <EmptyLegRespondBrokerDrawer isOpen={isBrokerDrawerOpen} setIsOpen={setIsBrokerDrawerOpen}
                                     emptyLeg={emptyLeg} emptyLegOrder={emptyLegOrder} openChat={() => setIsChatOpened(true)}/>}

      {isChatOpened && chat && emptyLegOrder.manager.id &&
        <ChatModal chat={chat}
                   isVisible={isChatOpened}
                   setIsVisible={setIsChatOpened}
        />}
    </>
  );
};
