import {CompanyEntity, FileEntity} from "../../../proto/generated/api_entities_pb";
import {ICompany} from "./companyReducerTypes";
import {CompanyStatus} from "../../../utils/constans/statuses/companyStatuses";
import {UploadFile} from "antd/es/upload";
import {getNginxHost} from "../../../API/helpers/getProxyUrl";

export const mapCompanyEntityToICompany = (item: CompanyEntity): ICompany => {
  const avatar = item.getAvatar()
  let avatarMapped: UploadFile | undefined;
  if (avatar?.getFileid()) {
    avatarMapped = {
      uid: avatar.getFileid(),
      name: avatar.getName(),
      fileName: avatar.getName(),
      url: getNginxHost() + avatar.getFilepath(),
    }
  }

  const operatorCard = item.getOperatorcard();
  let operatorCardMapped: UploadFile | undefined;
  if (operatorCard?.getFileid()) {
    operatorCardMapped = {
      uid: operatorCard.getFileid(),
      name: operatorCard.getName(),
      fileName: operatorCard.getName(),
      url: operatorCard.getFilepath(),
    }
  }

  const registrationCertificate = item.getRegistrationcertificate();
  let registrationCertificateMapped: UploadFile | undefined;
  if (registrationCertificate?.getFileid()) {
    registrationCertificateMapped = {
      uid: registrationCertificate.getFileid(),
      name: registrationCertificate.getName(),
      fileName: registrationCertificate.getName(),
      url: registrationCertificate.getFilepath(),
    }
  }

  const currency = item.getCurrency();
  if (!currency)
    throw new Error("Null CurrencyEntity (currency) in received CompanyEntity");

  return {
    id: item.getCompanyid(),
    name: item.getCompanyname(),
    companyStatus: item.getCompanystatusid() as CompanyStatus,
    phone: item.getPhone() && item.getPhone() !== 0 ? item.getPhone() : undefined,
    email: item.getEmail(),
    website: item.getWebsite(),
    description: item.getDescription(),
    address: item.getAddress(),
    avatar: avatarMapped,
    operatorCard: operatorCardMapped,
    registrationCertificate: registrationCertificateMapped,
    currency: {
      id: currency.getCurrencyid(),
      name: currency.getName(),
    },
  }
}


export const mapICompanyToCompanyEntity = (item: ICompany): CompanyEntity => {
  const avatar = item.avatar;
  let avatarMapped: FileEntity | undefined;
  if (avatar && avatar.url) {
    avatarMapped = new FileEntity()
      .setFileid(avatar.uid)
      .setName(avatar.name)
      .setFilepath(avatar.url)
  }

  const operatorCard = item.operatorCard;
  let operatorCardMapped: FileEntity | undefined;
  if (operatorCard && operatorCard.url) {
    operatorCardMapped = new FileEntity()
      .setFileid(operatorCard.uid)
      .setName(operatorCard.name)
      .setFilepath(operatorCard.url)
  }

  const registrationCertificate = item.registrationCertificate;
  let registrationCertificateMapped: FileEntity | undefined;
  if (registrationCertificate && registrationCertificate.url) {
    registrationCertificateMapped = new FileEntity()
      .setFileid(registrationCertificate.uid)
      .setName(registrationCertificate.name)
      .setFilepath(registrationCertificate.url)
  }

  const updatedCompany = new CompanyEntity()
    .setCompanyid(item.id)
    .setCompanyname(item.name)
    .setCompanystatusid(item.companyStatus)

  item.phone && updatedCompany.setPhone(item.phone);
  item.email && updatedCompany.setEmail(item.email);
  item.website && updatedCompany.setWebsite(item.website);
  item.description && updatedCompany.setDescription(item.description);
  item.address && updatedCompany.setAddress(item.address);
  avatarMapped && updatedCompany.setAvatar(avatarMapped);
  operatorCardMapped && updatedCompany.setOperatorcard(operatorCardMapped);
  registrationCertificateMapped && updatedCompany.setRegistrationcertificate(registrationCertificateMapped);

  return updatedCompany;
}