const getEnvironmentVariable = (key: string): string | never => {
  const env = process.env[key];

  if (!env) {
    throw new Error(`environment variable "${key}" is empty`);
  }

  return env;
}

export const getProxyUrl = () => getEnvironmentVariable('REACT_APP_PROXY_API_URL');
export const getNginxHost = () => {
  const envNginx = getEnvironmentVariable('REACT_APP_NGINX_HOST')

  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-restricted-globals
    return `${location.protocol}//${window.location.hostname}/${envNginx}/`
  } else {
    return envNginx;
  }
}