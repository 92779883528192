import React, {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
import {
  clearCreateOrder, createOrder,
} from "../../../../../store/reducers/searchReducer/searchReducer";
import {Col, Form, message, Row} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {IGetEmptyLegListFormData} from "../SearchPage";
import {useNavigate} from "react-router";
import {pathFromRoute} from "../../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../../config/acl/routes/Route";
import {
  IOrderCreateOrderRoute,
  ISearchedEmptyLeg
} from "../../../../../store/reducers/searchReducer/searchReducerTypes";
import {CustomDrawer} from "../../../../../components/UI/AntCustom/CustomDrawer";
import styled from "styled-components";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {CustomDatePicker} from "../../../../../components/UI/AntCustom/CustomDatePicker";
import {isDateLessTanOneMinuteAgo} from "../../../../../utils/helpers/dateHelpers/scheduleDateHelpers";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {paxRules} from "../../../../../utils/rules/paxRules";
import TextArea from "antd/es/input/TextArea";
import {ReactComponent as ArrowIcon} from "../../../../../media/icons/route_arrow.svg";
import {CreateOrderEmptyLegCard} from "./CreateOrderEmptyLegCard";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {Dayjs} from "dayjs";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {DisplayAirport} from "../../../../../utils/helpers/textDisplayHelpers/DisplayAirport";
import {getChatList} from "../../../../../store/reducers/chatReducer/chatReducer";
import {CustomInputNumber} from "../../../../../components/UI/AntCustom/CustomInputNumber";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";


const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 24px;
`;

const SelectedEmptyLegs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const SelectedEmptyLegsTitle = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  row-gap: 8px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SelectedEmptyLegsRoute = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const SearchedEmptyLegs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  column-gap: 10px;
`;


const CreateOrderDrawerMessages = defineMessages({
  title: {
    id: "createOrderDrawer.title",
    defaultMessage: "Fill out the necessary fields to add them to responds",
  },
  createSuccess: {
    id: "createOrderDrawer.createSuccess",
    defaultMessage: "The request has been successfully created and added to \"My requests\" section.",
  },
  yourResponds: {
    id: "createOrderDrawer.yourResponds",
    defaultMessage: "Your responds",
  },
})

interface ICreateOrderFormData {
  departureDate: Dayjs;
  pax: number;
  welcomeMessage: string,
}

interface ICreateOrderDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  orderRouteDetails: IGetEmptyLegListFormData;
  selectedEmptyLegs: ISearchedEmptyLeg[];
  setSelectedEmptyLegs: Dispatch<SetStateAction<ISearchedEmptyLeg[]>>;
}

export const CreateOrderDrawer: FC<ICreateOrderDrawer> = ({
                                                            isOpen,
                                                            setIsOpen,
                                                            orderRouteDetails,
                                                            selectedEmptyLegs,
                                                            setSelectedEmptyLegs,
                                                          }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.search.createOrder);
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      dispatch(clearCreateOrder());
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        dispatch(getChatList());
        renderSuccessModal(intl.formatMessage(CreateOrderDrawerMessages.createSuccess), () => {
          navigate(pathFromRoute(Route.requests), {replace: true});
          setIsOpen(false);
        });
      }
      if (errorMessage)
        message.error(errorMessage);
    }
  }, [isSuccess, errorMessage, isLoading])


  const onFinish = (values: ICreateOrderFormData) => {
    const order: IOrderCreateOrderRoute = {
      airportFromId: orderRouteDetails.airportFromId,
      airportToId: orderRouteDetails.airportToId,
      departureDate: values.departureDate.toDate(),
      pax: values.pax,
      selectedEmptyLegIds: selectedEmptyLegs.map(el => el.emptyLegId),
    }

    dispatch(createOrder({
      orderCreateOrderRoutes: [order],
      planeCategoryIds: orderRouteDetails.planeCategoryIds ?? [],
      welcomeMessage: values.welcomeMessage
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const airportFrom = useMemo(() => selectedEmptyLegs.find(el => el)?.airportFrom, []);
  const airportTo = useMemo(() => selectedEmptyLegs.find(el => el)?.airportTo, []);

  const footer = <FooterWrapper>
    <CustomButton onClick={() => setIsOpen(false)}
                  style={{flexGrow: 1}}>{intl.formatMessage(CommonButtonMessages.cancel)}</CustomButton>
    <CustomButton onClick={form.submit} type={"primary"} style={{flexGrow: 1}}
                  disabled={selectedEmptyLegs.length === 0}>{intl.formatMessage(CommonButtonMessages.doRespond)}</CustomButton>
  </FooterWrapper>

  const initialValues: Partial<ICreateOrderFormData> = {
    departureDate: orderRouteDetails.departureDate,
    pax: orderRouteDetails.pax,
    welcomeMessage: undefined,
  }

  return (
    <CustomDrawer visible={isOpen} onClose={() => setIsOpen(false)} footer={footer} drawerStyle={{maxWidth: 556}}>
      <Title>{intl.formatMessage(CreateOrderDrawerMessages.title)}</Title>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={"vertical"}
      >
        <Row gutter={8}>
          <Col span={18}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.departureDateLabel)}
              name={'departureDate'}
              rules={[{required: true, message: intl.formatMessage(CommonFormMessages.ruleRequired)}]}
            >
              <CustomDatePicker
                showTime={true}
                format="YYYY-MM-DD HH:mm"
                placeholder={intl.formatMessage(CommonFormMessages.departureDatePlaceholder)}
                disabledDate={isDateLessTanOneMinuteAgo}
                inputReadOnly={true} placement={"bottomLeft"}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              />
            </CustomFieldInput>
          </Col>
          <Col span={6}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.paxLabel)}
              name={'pax'}
              rules={paxRules}>
              <CustomInputNumber controls={true} placeholder={intl.formatMessage(CommonFormMessages.paxPlaceholder)}
                                 style={{width: "100%"}}/>
            </CustomFieldInput>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex={1}>
            <CustomFieldInput
              label={intl.formatMessage(CommonFormMessages.welcomeMessageLabel)}
              name={'welcomeMessage'}
            >
              <TextArea placeholder={intl.formatMessage(CommonFormMessages.welcomeMessagePlaceholder)}/>
            </CustomFieldInput>
          </Col>
        </Row>
      </Form>

      <SelectedEmptyLegs>
        <SelectedEmptyLegsTitle>
          <div>{intl.formatMessage(CreateOrderDrawerMessages.yourResponds)} ({selectedEmptyLegs.length})</div>
          {airportFrom && airportTo && <SelectedEmptyLegsRoute>
            <div>{DisplayAirport(airportFrom)}</div>
            <ArrowIcon/>
            <div>{DisplayAirport(airportTo)}</div>
          </SelectedEmptyLegsRoute>}
        </SelectedEmptyLegsTitle>
      </SelectedEmptyLegs>

      <SearchedEmptyLegs>
        {selectedEmptyLegs.map(el => <CreateOrderEmptyLegCard key={el.emptyLegId}
                                                              selectedEmptyLeg={el}
                                                              setSelectedEmptyLegs={setSelectedEmptyLegs}
        />)}
      </SearchedEmptyLegs>
    </CustomDrawer>
  );
};
