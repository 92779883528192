import React, {FC, ReactNode} from 'react';
import {Form, FormItemProps} from "antd/es";
import styled from "styled-components";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const FormItemStyled = styled(Form.Item)`
  margin: 0 0 16px 0;

  .ant-input {
    background: transparent;
    border-radius: 5px !important;
    padding: 12px !important;
  }

  .ant-input-number-input-wrap, .ant-select-selector {
    border-radius: 5px !important;
    height: 46px !important;
    padding: 12px 12px !important;
  }

  .ant-form-item-label {
    height: 17px;
    box-sizing: content-box;
    line-height: 17px;
    text-overflow: ellipsis;

    label {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      
      div, span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
      }
    }
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-select-selection-search-input,
  .ant-input-number-input {
    height: 22px !important;
    line-height: 22px !important;
    padding-left: 0;
  }

  .ant-input-number, .ant-input-number-in-form-item {
    background: transparent;
    height: 46px;
    border-radius: 5px;
  }

  .ant-form-item-explain-error {
    line-height: 14px;
    margin-top: 5px;
    font-size: 12px;
  }

  .ant-input-number-group-addon {
    background: #FFFFFF;
  }

  .ant-input-number-wrapper, .ant-input-number-group {
    div {
      :first-child {
        border-radius: 5px 0 0 5px !important;

      }

      :last-child {
        border-radius: 0 5px 5px 0 !important;
      }
    }
  }

  .ant-input-affix-wrapper, .ant-input-password {
    padding: 0 11px 0 0;
    border-radius: 5px;
  }

  .ant-picker, .ant-picker-range {
    background: transparent;
    border-radius: 5px !important;
    padding: 12px !important;
    flex-grow: 1;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-input-number-group-wrapper, .ant-input-number-group-wrapper-status-error {
    flex-grow: 1;
  }

  .ant-select-selector {
    background-color: transparent !important;
    height: unset !important;

    ::after {
      margin: 0;
      line-height: 22px !important;
    }
  }

  .ant-select-selection-overflow {
    gap: 5px;
  }

  .ant-select-selection-overflow-item {
    max-height: 22px;
  }

  .ant-select-selection-item {
    margin: 0;
    border: none;
    padding: 0 5px;
    border-radius: 5px;
  }

  .ant-select-disabled {
    opacity: 0.7;

    .ant-select-selector {
      background: #FFFFFF !important;
    }
  }

  .ant-select-multiple .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }

  .ant-input-number-disabled {
    opacity: 0.7;
    background-color: #FFFFFF !important;
  }
  
  .ant-form-item-extra {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    padding-bottom: 8px;
    line-height: 17px;
  }
  
  .ant-input-number-affix-wrapper {
    border-radius: 5px !important;
  }
  
  
  //segmented
  .ant-segmented, .ant-segmented-block {
    flex-grow: 1;
    height: 48px;
    border-radius: 5px;
  }
  
  .ant-segmented-item, .ant-segmented-item-selected {
    border-radius: 5px;
  }
  
  .ant-segmented-item-label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ant-checkbox {
    border-radius: 2px;
  }
  .ant-checkbox-inner {
    background-color: transparent;
  }
  .ant-checkbox-checked {
    background-color: #1890ff;
  }
  
  .ant-picker-panel {
    @media(max-width: ${mediaScreenSizes.mobile}) {
      left: -32px;
      transform: scale(0.7);
    }
  }
`;

interface ICustomFieldInput extends FormItemProps {
  labelAdditionalMargin?: boolean;
  children: ReactNode;
}

export const CustomFieldInput: FC<ICustomFieldInput> = ({labelAdditionalMargin = false, children, ...props}) => {
  return (
    <FormItemStyled
      {...props}
    >
      {children}
    </FormItemStyled>
  );
};
