import React, {useEffect, useState} from 'react';
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {ChartererOrderList} from "./components/ChartererOrderList";
import {Badge, message, Tabs} from "antd";
import {
  OrderTypeEnum
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import {ChartererOrderFilterModal} from "./components/ChartererOrderFilterModal";
import {
  clearArchiveOrder,
  clearChangeEmptyLegOrderRouteWithdraw, clearChartererOrderList, getChartererOrderList,
} from "../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducer";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {ReactComponent as FilterIcon} from "../../../../media/icons/filter.svg";
import {renderSuccessModal} from "../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {useSearchParams} from "react-router-dom";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {CustomSelect} from "../../../../components/UI/AntCustom/CustomSelect";
import isNumeric from "antd/es/_util/isNumeric";
import {useIsMobile} from "../../../../hooks/useWindowSize";


const MyRequestsPageMessages = defineMessages({
  title: {
    id: "myRequestsPage.title",
    defaultMessage: "My requests",
  },
  active: {
    id: "myRequestsPage.active",
    defaultMessage: "Active",
  },
  archival: {
    id: "myRequestsPage.archival",
    defaultMessage: "Archive",
  },
  respondStatusChanged: {
    id: "myRequestsPage.respondStatusChanged",
    defaultMessage: "Offer status has been successfully updated.",
  },
  requestStatusChanged: {
    id: "myRequestsPage.requestStatusChanged",
    defaultMessage: "Request status has been successfully updated.",
  },
  archiveSuccess: {
    id: "myRequestsPage.archiveSuccess",
    defaultMessage: "Request has been successfully archived.",
  },
  all: {
    id: "myRequestsPage.all",
    defaultMessage: "All",
  }
})

export interface IChartererOrderRouteFilters {
  airportFromId?: number;
  airportToId?: number;
  onlyApproved?: boolean;
}

enum RequestFromFreightersTabKeysEnum {
  ACTIVE = 'active',
  ARCHIVAL = 'archival',
}

export const MyRequestsPage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const archivedParams = searchParams.get('archived');
  const orderIdFromParams = isNumeric(searchParams.get('orderId')) ? Number(searchParams.get('orderId')) : undefined;
  const [activeTabKey, setActiveTabKey] = useState<RequestFromFreightersTabKeysEnum>(archivedParams ? RequestFromFreightersTabKeysEnum.ARCHIVAL : RequestFromFreightersTabKeysEnum.ACTIVE);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IChartererOrderRouteFilters>({});
  const [orderFilterTypes, setOrderFilterTypes] = useState<OrderTypeEnum[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const {items, totalCount, isLoading} = useAppSelector(state => state.chartererRequests.itemsObject)
  const [orderRoutesCount, setOrderRoutesCount] = useState<number>(0);
  const {
    isLoading: isChangeEmptyLegOrderRouteWithdrawLoading,
    isSuccess: isChangeEmptyLegOrderRouteWithdrawSuccess,
    errorMessage: changeEmptyLegOrderRouteWithdrawErrorMessage,
  } = useAppSelector(state => state.chartererRequests.changeEmptyLegOrderRouteWithdraw);
  const {
    isLoading: isArchiveOrderRouteLoading,
    isSuccess: isArchiveOrderRouteSuccess,
    errorMessage: archiveOrderRouteErrorMessage,
  } = useAppSelector(state => state.chartererRequests.delete);


  useEffect(() => {
    return () => {
      dispatch(clearChartererOrderList());
      dispatch(clearChangeEmptyLegOrderRouteWithdraw());
      dispatch(clearArchiveOrder());
    }
  }, [])

  useEffect(() => {
    setCurrentPage(InitialPage)

    dispatch(getChartererOrderList({
      page: InitialPage,
      types: orderFilterTypes,
      airportFromId: filters.airportFromId,
      airportToId: filters.airportToId,
      onlyApproved: filters.onlyApproved,
      showArchived: activeTabKey === RequestFromFreightersTabKeysEnum.ARCHIVAL,
    }))
  }, [filters, orderFilterTypes, activeTabKey])

  useEffect(() => {
    if (activeTabKey === RequestFromFreightersTabKeysEnum.ACTIVE && !isLoading)
      setOrderRoutesCount(totalCount);
  }, [items, isLoading])

  useEffect(() => {
    if (!isChangeEmptyLegOrderRouteWithdrawLoading) {
      if (isChangeEmptyLegOrderRouteWithdrawSuccess)
        renderSuccessModal(intl.formatMessage(MyRequestsPageMessages.requestStatusChanged));
      if (changeEmptyLegOrderRouteWithdrawErrorMessage)
        message.error(changeEmptyLegOrderRouteWithdrawErrorMessage);
    }
  }, [isChangeEmptyLegOrderRouteWithdrawLoading, isChangeEmptyLegOrderRouteWithdrawSuccess, changeEmptyLegOrderRouteWithdrawErrorMessage])

  useEffect(() => {
    if (!isArchiveOrderRouteLoading) {
      if (isArchiveOrderRouteSuccess)
        renderSuccessModal(intl.formatMessage(MyRequestsPageMessages.archiveSuccess));
      if (archiveOrderRouteErrorMessage)
        message.error(archiveOrderRouteErrorMessage);
    }
  }, [isArchiveOrderRouteLoading, isArchiveOrderRouteSuccess, archiveOrderRouteErrorMessage])

  const loadMore = () => {
    dispatch(getChartererOrderList({
      page: currentPage + 1,
      types: orderFilterTypes,
      airportFromId: filters.airportFromId,
      airportToId: filters.airportToId,
      onlyApproved: filters.onlyApproved,
      showArchived: activeTabKey === RequestFromFreightersTabKeysEnum.ARCHIVAL,
    }));

    setCurrentPage(prev => prev + 1);
  }

  const tabList = [
    {
      key: RequestFromFreightersTabKeysEnum.ACTIVE,
      label: `${intl.formatMessage(MyRequestsPageMessages.active)} (${orderRoutesCount})`,
      children: <ChartererOrderList/>
    },
    {
      key: RequestFromFreightersTabKeysEnum.ARCHIVAL,
      label: intl.formatMessage(MyRequestsPageMessages.archival),
      children: <ChartererOrderList/>
    },
  ];

  const extra = <>
    <CustomSelect mode={"tags"} defaultValue={[]}
                  placement={'bottomRight'}
                  style={{minWidth: isMobile ? 88 : 120, width: isMobile ? 88 : "unset"}}
                  showArrow={true}
                  allowClear={true}
                  dropdownMatchSelectWidth={false}
                  onChange={(types) => setOrderFilterTypes(types as OrderTypeEnum[])} placeholder={"All"} options={[
      {label: "One way", value: OrderTypeEnum.ONE_WAY},
      {label: "Round Trip", value: OrderTypeEnum.ROUND_TRIP},
      {label: "Multi Leg", value: OrderTypeEnum.MULTI_LEG},
      {label: "Empty Leg", value: OrderTypeEnum.EMPTY_LEG},
    ]}/>
    <Badge count={Object.values(filters).filter(v => v).length}>
      <CustomButton onClick={() => setIsFiltersModalOpen(true)} style={{marginLeft: 8}} heightSize={'m'}>
        <FilterIcon/>
      </CustomButton>
    </Badge>
  </>

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(MyRequestsPageMessages.title)} width={'max'} isContainerCard={true}
                  extra={isMobile ? extra : null}
                  onTabChange={(key: string) => {
                    setActiveTabKey(key as RequestFromFreightersTabKeysEnum)
                  }}>
        <Tabs tabBarExtraContent={isMobile ? null : extra} activeKey={activeTabKey} onTabClick={(key: string) => {
          setActiveTabKey(key as RequestFromFreightersTabKeysEnum)
        }}>
          {tabList.map(t => <Tabs.TabPane key={t.key} tab={t.label}>{t.children}</Tabs.TabPane>)}
        </Tabs>

        {items.length > 0 && totalCount > items.length &&
          <CustomButton
            loading={isLoading}
            type={'primary'}
            onClick={loadMore}
          >
            {intl.formatMessage(CommonFormMessages.showMore)}
          </CustomButton>}
      </CustomCard>

      {isFiltersModalOpen &&
        <ChartererOrderFilterModal isModalVisible={isFiltersModalOpen} setIsModalVisible={setIsFiltersModalOpen}
                                   filters={filters} setFilters={setFilters}/>}
    </PageWrapper>
  );
};

