import {Logger} from "../../logger/Logger";

//TODO localization
export const getErrorMessageByCode = (code?: string) => {
  switch (code) {
    case 'AUTH_ERROR_IDENTIFIER_REFRESH_TOKEN_NOT_FOUND':
      return 'Refresh token is missing.';
    case 'AUTH_ERROR_IDENTIFIER_PASSWORD_NOT_MATCH':
      return 'Incorrect credentials.';
    case 'AUTH_ERROR_IDENTIFIER_USER_NOT_FOUND':
      return 'Incorrect credentials.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_DEFAULT':
      return 'Unexpected account error.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_CREATE_ERROR':
      return 'Unexpected account error.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_EMAIL_NOT_VALID':
      return 'Incorrect email.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_FLY_COMPANY_ID_INVALID':
      return 'Incorrect freighter company.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_AGENCY_COMPANY_ID_INVALID':
      return 'Incorrect charterer company.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_TARIFF_ID_INVALID':
      return 'Incorrect tariff.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_FIO_INVALID':
      return 'Incorrect full name.';
    case 'AUTH_ERROR_IDENTIFIER_REGISTRATION_REQUIREMENT_FIELD':
      return 'Required fields not filled.';
    case 'AUTH_ERROR_IDENTIFIER_REGISTRATION_TYPE_MISMATCH_FIELD':
      return 'Type mismatch of filled fields.';
    case 'AUTH_ERROR_IDENTIFIER_REGISTRATION_EMAIL_VALIDATION':
      return 'Type mismatch of filled fields.';
    case 'AUTH_ERROR_IDENTIFIER_REGISTRATION_PASSWORD_VALIDATION':
      return 'Incorrect password.';
    case 'AUTH_ERROR_IDENTIFIER_REGISTRATION_ROLE_VALIDATION':
      return 'Incorrect role.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_REQUIRED_MISSED':
      return 'All required fields are not filled.';
    case 'BOOKING_ERROR_IDENTIFIER_EMPTY_LEG_PLANE_BUSY':
      return 'Aircraft is busy on the indicated dates.';
    case 'PLANE_ERROR_IDENTIFIER_UNABLE_TO_DELETE':
      return 'Unable to delete an aircraft with existing flights.';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND':
      return 'Link expired';
    case 'USER_ACCOUNT_ERROR_IDENTIFIER_OLD_PASSWORD_NOT_VALID':
      return 'Invalid old password';
    default:
      Logger.error(`Unknown error. Error code: ${code}`)
      return 'Unknown error.'
  }
}
