import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  getNotificationList,
  readNotifications,
  setNotificationListResult, setUnreadOnlyNotificationsListResult
} from "../../reducers/notificationsReducer/notificationsReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  NotificationApiNotificationListRequest,
  NotificationApiNotificationListResponse,
  NotificationApiReadNotificationsRequest,
  PageModel,
} from "../../../proto/generated/api_entities_pb";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {API} from "../../../API/API";
import {
  IGetNotificationListPayload,
  IReadNotificationsPayload
} from "../../reducers/notificationsReducer/notificationsReducerTypes";
import {InitialPage} from "../../../utils/constans/pagination/pagination";


function* getNotificationListSaga({payload}: PayloadAction<IGetNotificationListPayload>) {
  const notificationListForNotificationCentreReq = new NotificationApiNotificationListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.Notifications)
    )

  const onlyUnreadNotificationsListReq = new NotificationApiNotificationListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(InitialPage)
    )
    .setIsread(false);

  try {
    const notificationListForNotificationCentreRes: NotificationApiNotificationListResponse = yield call(API.getNotificationList, notificationListForNotificationCentreReq);
    const onlyUnreadNotificationsListRes: NotificationApiNotificationListResponse = yield call(API.getNotificationList, onlyUnreadNotificationsListReq);

    yield put(setNotificationListResult({
      items: notificationListForNotificationCentreRes.getNotificationsList(),
      totalCount: notificationListForNotificationCentreRes.getNotificationsList().length,
    }));
    yield put(setUnreadOnlyNotificationsListResult({
      items: onlyUnreadNotificationsListRes.getNotificationsList(),
      totalCount: onlyUnreadNotificationsListRes.getNotificationsList().length,
    }));
  } catch {}
}


function* readNotificationsSaga({payload}: PayloadAction<IReadNotificationsPayload>) {
  const req = new NotificationApiReadNotificationsRequest();

  if (payload.notificationIds)
    req.setNotificationidsList(payload.notificationIds);

  try {
    yield call(API.readNotifications, req);
  } catch {}
}


export function* notificationsSaga() {
  yield all([
    takeLatest(getNotificationList.type, getNotificationListSaga),
    takeLatest(readNotifications.type, readNotificationsSaga),
  ])
}