import React from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {Button, Dropdown, Menu, MenuProps, Typography} from "antd";
import {lk} from "../../../../config/acl/routes/lk/lk";
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import {logout} from "../../../../store/reducers/authReducer/authReducer";
import {login} from "../../../../config/acl/routes/all/auth/login";
import {register} from "../../../../config/acl/routes/all/auth/register";
import {getItem, LogoutMessage, MenuItem} from "../../../../hooks/useGenerateMenu";
import {NotificationBell} from "../../../notifications/notificationsCentre/NotificationBell";
import {useIntl} from "react-intl";
import {CustomButton} from "../../AntCustom/CustomButton";
import {groupApproved} from "../../../../config/acl/roles/groups";

const HeaderDesktopRightStyled = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
`;

const LkButtonIcons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

interface BurgerItem {
  key: string;
  label: string;
}


export const HeaderDesktopRight = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {isAuthenticated, role} = useAppSelector(state => state.auth);
  const {firstName, lastName, middleName} = useAppSelector(state => state.user.oneItemObject.item)

  const authenticatedBurgerItems: (MenuItem & BurgerItem)[] = [
    getItem(intl.formatMessage(lk.title), lk.path) as BurgerItem,
    getItem(intl.formatMessage(LogoutMessage), "logout") as BurgerItem,
  ]

  const notAuthenticatedBurgerItems: (MenuItem & BurgerItem)[] = [
    getItem(intl.formatMessage(login.title), login.path) as BurgerItem,
    getItem(intl.formatMessage(register.title), register.path) as BurgerItem,
  ]

  const onMenuItemClick: MenuProps['onClick'] = ({key}) => {
    if (key !== "logout")
      navigate(key);
    else
      dispatch(logout());
  };

  return (
    <HeaderDesktopRightStyled>
      <>
        {
          isAuthenticated
            ?
            <>
              {groupApproved.includes(role) && <NotificationBell />}
              <Typography.Text style={{marginLeft: 13}}>
                {lastName} {firstName && `${firstName.slice(0, 1)}.`} {middleName && (middleName?.slice(0, 1) + ".")}
              </Typography.Text>
              <Dropdown overlay={<Menu items={authenticatedBurgerItems} onClick={onMenuItemClick}/>}
                        placement="bottomRight"
                        trigger={["click"]}>
                <Button icon={<LkButtonIcons><UserOutlined/><DownOutlined/></LkButtonIcons>} shape={"round"}
                        size={"middle"} type={'primary'}/>
              </Dropdown>
            </>
            :
            notAuthenticatedBurgerItems.map((el, index) => {
                return <CustomButton heightSize={'m'}
                  key={el.key}
                  type="primary"
                  onClick={() => navigate(el.key as string)}
                  size={"middle"}
                  ghost={index === 0}
                >
                  {el.label}
                </CustomButton>
              }
            )
        }
      </>
    </HeaderDesktopRightStyled>
  );
};

