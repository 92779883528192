import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  createPlane, deletePlane, editPlane,
  getPlaneList,
  setCreatePlaneResult, setDeletePlaneResult, setEditPlaneResult,
  setPlaneListResult
} from "../../reducers/fleetReducer/fleetReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  ICreatePlanePayload, IDeletePlanePayload,
  IEditPlanePayload,
  IGetPlaneListPayload
} from "../../reducers/fleetReducer/fleetReducerTypes";
import {UploadFileResponseType} from "../../../utils/helpers/fileHelpers/fileTypes";
import {getFileInfoAfterUpload} from "../../../utils/helpers/fileHelpers/getFileInfoAfterUpload";
import {
  AirportEntity,
  BookingApiPlaneListRequest,
  BookingApiPlaneListResponse,
  BookingApiPlaneRequest,
  BookingApiPlaneResponse,
  CurrencyEntity,
  FileEntity,
  PageModel,
  PlaneEntity,
  PlaneTypeEntity
} from "../../../proto/generated/api_entities_pb";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {Logger} from "../../../utils/logger/Logger";
import {getErrorMessage} from "../../../utils/errorHelpers/getCommonErrorMessage";
import {getLocalDateAsISOString} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {metricsGoals} from "../../../utils/constans/metrics/goals";


function* getPlaneListSaga({payload}: PayloadAction<IGetPlaneListPayload>) {
  const req = new BookingApiPlaneListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.Planes)
      //TODO add query
    )
    .setQuery(payload.query);

  try {
    const res: BookingApiPlaneListResponse = yield call(API.getPlaneList, req);

    yield put(setPlaneListResult({
      items: res.getPlanesList(),
      totalCount: res.getTotalcount(),
    }));
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}


function* createPlaneSaga({payload}: PayloadAction<ICreatePlanePayload>) {
  try {
    const imageUploadedFiles: UploadFileResponseType[] = [];

    payload.imageFiles?.forEach(async (f) => {
      const uploadedFile = await getFileInfoAfterUpload(f)
      imageUploadedFiles.push(uploadedFile);
    })

    const insuranceFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.insuranceFile);
    const certificateFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.certificateFile);

    const planeEntity = new PlaneEntity()
      .setPlanetype(new PlaneTypeEntity().setPlanetypeid(payload.planeTypeId))
      .setBaseairport(new AirportEntity().setAirportid(payload.baseAirportId))
      .setRegistrationnumber(payload.registrationNumber)
      .setManufacturedate(getLocalDateAsISOString(payload.manufactureDate))
      .setMaxpassengers(payload.maxPassengers)
      .setMaxdistance(payload.maxDistance)
      .setIsanimals(payload.isAnimals)
      .setIscargo(payload.isCargo)
      .setIssmoking(payload.isSmoking)
      .setIsambulance(payload.isAmbulance)
      .setInsurance(
        new FileEntity()
          .setFileid(insuranceFile.fileId)
          .setName(insuranceFile.fileName)
          .setFilepath(insuranceFile.filePath)
      )
      .setCertificate(
        new FileEntity()
          .setFileid(certificateFile.fileId)
          .setName(certificateFile.fileName)
          .setFilepath(certificateFile.filePath)
      )
      .setImagesList(
        imageUploadedFiles.map(imageFile => new FileEntity()
          .setFileid(imageFile.fileId)
          .setName(imageFile.fileName)
          .setFilepath(imageFile.filePath)
        )
      )
      .setFlyhourprice(payload.flyHourPrice)
      .setCurrency(new CurrencyEntity().setCurrencyid(payload.flyHourCurrencyId))
      .setPlanecalculatorsettingsList(payload.settingEntities);

    if (payload.renovationDate)
      planeEntity.setRenovationdate(getLocalDateAsISOString(payload.renovationDate))

    if (payload.description)
      planeEntity.setDescription(payload.description)

    const req = new BookingApiPlaneRequest()
      .setPlane(
        planeEntity
      )

    const res: BookingApiPlaneResponse = yield call(API.planeCreate, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setCreatePlaneResult({
        item: res.getPlane(),
      }));

      metricsGoals.planeCreated();
    } else {
      yield put(setCreatePlaneResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}


function* editPlaneSaga({payload}: PayloadAction<IEditPlanePayload>) {
  try {
    const imageUploadedFiles: UploadFileResponseType[] = [];
    for (const image of payload.imageFiles) {
      const uploadedFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, image)
      imageUploadedFiles.push(uploadedFile);
    }

    const insuranceFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.insuranceFile);
    const certificateFile: UploadFileResponseType = yield call(getFileInfoAfterUpload, payload.certificateFile);

    const planeEntity = new PlaneEntity()
      .setPlaneid(payload.id)
      .setPlanetype(new PlaneTypeEntity().setPlanetypeid(payload.planeTypeId))
      .setBaseairport(new AirportEntity().setAirportid(payload.baseAirportId))
      .setRegistrationnumber(payload.registrationNumber)
      .setManufacturedate(getLocalDateAsISOString(payload.manufactureDate))
      .setMaxpassengers(payload.maxPassengers)
      .setMaxdistance(payload.maxDistance)
      .setIsanimals(payload.isAnimals)
      .setIscargo(payload.isCargo)
      .setIssmoking(payload.isSmoking)
      .setIsambulance(payload.isAmbulance)
      .setInsurance(
        new FileEntity()
          .setFileid(insuranceFile.fileId)
          .setName(insuranceFile.fileName)
          .setFilepath(insuranceFile.filePath)
      )
      .setCertificate(
        new FileEntity()
          .setFileid(certificateFile.fileId)
          .setName(certificateFile.fileName)
          .setFilepath(certificateFile.filePath)
      )
      .setImagesList(
        imageUploadedFiles.map(imageFile => new FileEntity()
          .setFileid(imageFile.fileId)
          .setName(imageFile.fileName)
          .setFilepath(imageFile.filePath)
        )
      )
      .setFlyhourprice(payload.flyHourPrice)
      .setCurrency(new CurrencyEntity().setCurrencyid(payload.flyHourCurrencyId))
      .setPlanecalculatorsettingsList(payload.settingEntities);

    if (payload.renovationDate)
      planeEntity.setRenovationdate(getLocalDateAsISOString(payload.renovationDate))

    if (payload.description)
      planeEntity.setDescription(payload.description)

    const req = new BookingApiPlaneRequest()
      .setPlane(
        planeEntity
      )

    const res: BookingApiPlaneResponse = yield call(API.planeUpdate, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setEditPlaneResult({
        item: res.getPlane(),
      }));
    } else {
      yield put(setEditPlaneResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}


function* deletePlaneSaga({payload}: PayloadAction<IDeletePlanePayload>) {
  const req = new BookingApiPlaneRequest()
    .setPlane(
      new PlaneEntity()
        .setPlaneid(payload.itemId)
    );

  try {
    const res: BookingApiPlaneResponse = yield call(API.planeDelete, req);

    if (res.getResultresponse()?.getIssuccessful()) {
      yield put(setDeletePlaneResult({
        itemId: payload.itemId,
      }));
    } else {
      yield put(setDeletePlaneResult({
        errorMessage: getErrorMessageByCode(res.getResultresponse()?.getErrormessage()),
      }));
    }
  } catch (e) {
    Logger.error(getErrorMessage(e))
  }
}


export function* fleetSaga() {
  yield all([
    takeLatest(getPlaneList.type, getPlaneListSaga),
    takeLatest(createPlane.type, createPlaneSaga),
    takeLatest(editPlane.type, editPlaneSaga),
    takeLatest(deletePlane.type, deletePlaneSaga),
  ])
}