import { Rule } from "antd/lib/form";

//TODO localization
export const paxRules: Rule[] = [
  {
    required: true,
    message: "Required field"
  },
  {
    type: "number",
    message: "Enter a number"
  },
  {
    min: 1,
    type: "number",
    message: "Minimum 1 passenger"
  },
  {
    max: 100,
    type: "number",
    message: "Maximum 100 passengers"
  }];

//TODO localization
export const paxRulesNotRequired: Rule[] = [
  {
    type: "number",
    message: "Enter a number"
  },
  {
    min: 1,
    type: "number",
    message: "Minimum 1 passenger"
  },
  {
    max: 100,
    type: "number",
    message: "Maximum 100 passengers"
  }];