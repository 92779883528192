import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {Col, Form, message, Row} from "antd";
import {CompanyMultiSelector, CompanyMultiSelectorMessages, CompanySelectorOption} from "./CompanyMultiSelector";
import {CommonFormMessages} from "../../../../../intl/commonMessages/CommonFormMessages";
import {CustomFieldInput} from "../../../../../components/UI/AntCustom/CustomFieldInput";
import {DebounceSelect} from "../../../../../components/UI/AntCustom/DebounceSelect";
import {fetchPlaneCategories} from "../../../../../utils/helpers/selectDataFetchers/fetchPlaneCategories";
import TextArea from "antd/es/input/TextArea";
import {CustomSwitch} from "../../../../../components/UI/AntCustom/CustomSwitch";
import {CommonDetailsMessages} from "../../../../../intl/commonMessages/CommonDetailsMessages";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {NewTripRequestPageMessages} from "../NewTripRequestPage";
import styled from "styled-components";
import {Dayjs} from "dayjs";
import {RecursivePartial} from "../../../../../utils/helpers/tsHelpers/reqursivePartialType";
import {TripItem} from "./TripItem";
import {PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";
import {
  clearMultiLegRequest,
  multiLegRequest,
} from "../../../../../store/reducers/newRequestReducer/newRequestReducer";
import {renderSuccessModal} from "../../../../../components/UI/StatusModals/renderSuccessModal";
import {pathFromRoute} from "../../../../../utils/helpers/acl/pathFromRoute";
import {Route} from "../../../../../config/acl/routes/Route";
import {getChatList} from "../../../../../store/reducers/chatReducer/chatReducer";
import {useIsMobile} from "../../../../../hooks/useWindowSize";

const MultiLegContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 14px;
`;

type MultiLegRouteType = {
  airportFromId: number;
  airportToId: number;
  departureDate: Dayjs;
  pax: number;
}

interface IMultiLegFormData {
  routes: MultiLegRouteType[];
  planeCategoryIds: number[],
  companionsAllowed: boolean;
  comment: string;
  companyIds: number[];
}

export const MultiLegForm = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [companyList, setCompanyList] = useState<CompanySelectorOption[]>([]);
  const {
    isLoading,
    isSuccess,
    errorMessage,
  } = useAppSelector(state => state.newRequest.multiLegRequest);

  useEffect(() => {
    return () => {
      dispatch(clearMultiLegRequest())
    }
  })

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        dispatch(getChatList());
        renderSuccessModal(intl.formatMessage(NewTripRequestPageMessages.publishSuccess), () => navigate(pathFromRoute(Route.requests), {replace: true}));
      }
      if (errorMessage)
        message.error(errorMessage)
    }
  }, [isSuccess, isLoading, errorMessage])

  const onFinish = (values: IMultiLegFormData) => {
    if (companyList.length === 0) {
      message.error(intl.formatMessage(CompanyMultiSelectorMessages.error));
      return;
    }

    dispatch(multiLegRequest({
      routes: values.routes.map(r => {
        return {
          airportFromId: r.airportFromId,
          airportToId: r.airportToId,
          departureDate: r.departureDate.toDate(),
          pax: r.pax,
        }
      }),
      planeCategoryIds: values.planeCategoryIds,
      companionsAllowed: values.companionsAllowed,
      comment: values.comment,
      companyIds: companyList.map(c => c.id),
    }));
  }

  const onFinishFailed = () => {
    message.error(intl.formatMessage(CommonFormMessages.incorrect))
  }

  const initialValues: RecursivePartial<IMultiLegFormData> = {
    routes: [
      {
        airportFromId: undefined,
        airportToId: undefined,
        departureDate: undefined,
        pax: undefined,
      },
      {
        airportFromId: undefined,
        airportToId: undefined,
        departureDate: undefined,
        pax: undefined,
      }
    ],
    planeCategoryIds: [],
    companionsAllowed: false,
    comment: undefined,
    companyIds: [],
  }

  const companyMultiSelector = <CompanyMultiSelector selectedCompanies={companyList} setSelectedCompanies={setCompanyList}/>

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      autoComplete="off"
      layout={'vertical'}
      form={form}
    >
      <MultiLegContentWrapper>
        <div style={{flexGrow: 1}}>
          <Form.List name="routes">
            {(fields, {add, remove}) => (
              <>
                {fields.map((field, index) =>
                  <TripItem key={field.key} field={field} onRemoveClick={() => remove(field.name)}
                            routeNumber={index + 1}/>
                )}
                {fields.length < 10 && <CustomFieldInput>
                  <CustomButton type={"primary"} ghost onClick={() => add()} block icon={<PlusOutlined/>} style={{marginBottom: 8}}>
                    {intl.formatMessage(NewTripRequestPageMessages.addFlight)}
                  </CustomButton>
                </CustomFieldInput>}
              </>
            )}
          </Form.List>

          {isMobile && companyMultiSelector}

          <Row gutter={8}>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.planeCategoryLabel)}
                name={'planeCategoryIds'}
              >
                <DebounceSelect
                  fetchOptions={fetchPlaneCategories}
                  placeholder={intl.formatMessage(CommonFormMessages.allPlaceholder)}
                  mode={'multiple'}
                  brightPlaceholder={true}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <CustomFieldInput
                label={intl.formatMessage(CommonFormMessages.commentLabel)}
                name={'comment'}>
                <TextArea
                  allowClear={true}
                  autoSize={{minRows: 3}}
                  placeholder={intl.formatMessage(CommonFormMessages.commentPlaceholder)}
                />
              </CustomFieldInput>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col>
              <CustomFieldInput
                name={'companionsAllowed'}
                valuePropName={'checked'}
              >
                <CustomSwitch label={intl.formatMessage(CommonDetailsMessages.companionsAllowed)}/>
              </CustomFieldInput>
            </Col>
          </Row>
        </div>

        {!isMobile && companyMultiSelector}
      </MultiLegContentWrapper>

      <Col span={24}>
        <CustomFieldInput>
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={false}
            style={{width: "100%"}}
          >
            {intl.formatMessage(NewTripRequestPageMessages.createRequest)}
          </CustomButton>
        </CustomFieldInput>
      </Col>
    </Form>
  );
};
