import {RoleDescriptor} from "../../RoleDescriptor";
import {Route} from "../../../routes/Route";
import {Role} from "../../Role";
import {defineMessage} from "react-intl";

const FreighterServiceManagerMessage = defineMessage({
  id: "freighterServiceManager.name",
  defaultMessage: "Operator, service manager",
})

export const freighterServiceManager: RoleDescriptor<Role.freighterServiceManager> = {
  role: Role.freighterServiceManager,
  name: FreighterServiceManagerMessage,
  backendIdentifier: 'freighter_service',
  homeRoute: Route.profile,
  navigation: {
    profile: [
      Route.fleet,
      Route.emptyLegs,
      Route.schedule,
    ],
    sidebar: [
      Route.emptyLegs,
      Route.fleet,
      Route.schedule,
      Route.profile,
    ]
  },
}