import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  ICreateOrderPayload,
  IGetFreighterEmptyLegsPayload,
  ISearchInitialState, ISetCreateOrderResultPayload,
  ISetFreighterEmptyLegsResultPayload
} from "./searchReducerTypes";
import {
  initCommonAction, initCommonActionResult, initCommonClearAction,
  initCommonClearList,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {mapEmptyLegEntityToIFreighterEmptyLeg} from "./searchMappers";
import {nameof} from "../../../utils/helpers/tsHelpers/nameof";
import {store} from "../../store";
import {getChatList} from "../chatReducer/chatReducer";

const searchInitialState: ISearchInitialState = {
  itemsObject: {
    items: [],
    totalCount: 0,
    isLoading: false,
  },
  createOrder: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const searchSlice = createSlice({
    name: 'search',
    initialState: searchInitialState,
    reducers: {
      getFreighterEmptyLegs(state, {payload}: PayloadAction<IGetFreighterEmptyLegsPayload>) {
        initCommonGetList(state, payload);
      },
      clearFreighterEmptyLegs(state) {
        initCommonClearList(state);
        state.itemsObject.isLoading = false;
      },
      setFreighterEmptyLegsResult(state, {payload}: PayloadAction<ISetFreighterEmptyLegsResultPayload>) {
        initCommonSetListResult(state, payload, mapEmptyLegEntityToIFreighterEmptyLeg);
      },

      createOrder(state, {payload}: PayloadAction<ICreateOrderPayload>) {
        initCommonAction(state, nameof<ISearchInitialState>("createOrder"))
      },
      clearCreateOrder(state) {
        initCommonClearAction(state, nameof<ISearchInitialState>("createOrder"))
      },
      setCreateOrderResult(state, {payload}: PayloadAction<ISetCreateOrderResultPayload>) {
        initCommonActionResult(state, payload, nameof<ISearchInitialState>("createOrder"))
      }
    }
  }
);

export const {
  getFreighterEmptyLegs,
  clearFreighterEmptyLegs,
  setFreighterEmptyLegsResult,

  createOrder,
  clearCreateOrder,
  setCreateOrderResult,
} =
  searchSlice.actions;
export default searchSlice.reducer;