import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {SetNewPasswordPage} from "../../../../../pages/General/SetNewPassword/SetNewPasswordPage";
import {Role} from "../../../roles/Role";
import {groupNotGuest} from "../../../roles/groups";
import {defineMessage} from "react-intl";

const SetNewPasswordRouteTitleMessage = defineMessage({
  id: "setNewPasswordRoute.title",
  defaultMessage: "Reset password",
})

export const setNewPassword: RouteDescriptor<Route.setNewPassword> = {
  route: Route.setNewPassword,
  render: SetNewPasswordPage,
  title: SetNewPasswordRouteTitleMessage,
  path: '/set-new-password',
  allowRoles: [
    Role.guest,
  ],
  redirectToHomeRoles: [
    ...groupNotGuest,
  ],
  showInSidebar: false,
}