import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {Role} from "../../../roles/Role";
import {SchedulePage} from "../../../../../pages/Lk/OperatorsOnly/Schedule/SchedulePage";
import {PageGroupMessages, PageGroups} from "../../pageGroups";
import {defineMessage} from "react-intl";

const ScheduleRouteTitleMessage = defineMessage({
  id: "scheduleRoute.title",
  defaultMessage: "Schedule",
})

export const schedule: RouteDescriptor<Route.schedule> = {
  route: Route.schedule,
  render: SchedulePage,
  title: ScheduleRouteTitleMessage,
  path: '/lk/schedule',
  allowRoles: [
    Role.freighterAdmin,
    Role.freighterServiceManager,
    Role.freighterClientManager,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
  group: PageGroups.FLEET,
  groupTitle: PageGroupMessages.fleet,
}