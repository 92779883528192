import ym from "react-yandex-metrika";

export const metricsGoals = {
  visitAgentsPage: () => ym('reachGoal','visitAgentsPage'),
  openRegisterPageAfterVisitHome: () => ym('reachGoal','openRegisterPageAfterVisitHome'),
  registrationSuccess: () => ym('reachGoal','registrationSuccess'),
  operatorRegistered: () => ym('reachGoal','operatorRegistered'),
  brokerRegistered: () => ym('reachGoal','brokerRegistered'),
  operatorLoggedIn: () => ym('reachGoal','operatorLoggedIn'),
  brokerLoggedIn: () => ym('reachGoal','brokerLoggedIn'),
  planeCreated: () => ym('reachGoal','planeCreated'),
  registerFormTouched: () => ym('reachGoal','registerFormTouched'),
  registerFormRequestSent: () => ym('reachGoal','registerFormRequestSent'),
  orderRequestMakeOffer: () => ym('reachGoal','orderRequestMakeOffer'),
  orderCreated: () => ym('reachGoal','orderCreated'),
  searchEmptyLegs: () => ym('reachGoal','searchEmptyLegs'),
  createEmptyLegOrder: () => ym('reachGoal','createEmptyLegOrder'),
}