import {PayloadAction} from "@reduxjs/toolkit";
import {
  CommunicationApiRequestCallbackListRequest, CommunicationApiRequestCallbackListResponse,
  CommunicationApiRequestCallbackRequest,
  CommunicationApiRequestCallbackResponse, PageModel,
  RequestCallbackEntity
} from "../../../proto/generated/api_entities_pb";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {API} from "../../../API/API";
import {getErrorMessageByCode} from "../../../utils/constans/errorCodes/errorCodes";
import {PageModelLimits} from "../../../utils/constans/pagination/pageModelLimits";
import {
  doRequestCallback,
  getRequestCallbackList, setDoRequestCallbackResult,
  setRequestCallbackListResult,
} from "../../reducers/requestCallbackReducer/requestCallbackReducer";
import {
  IGetClientListPayload,
  IRequestCallbackPayload
} from "../../reducers/requestCallbackReducer/requestCallbackReducerTypes";

function* doRequestCallbackSaga({payload}: PayloadAction<IRequestCallbackPayload>) {
  const requestCallbackReq = new CommunicationApiRequestCallbackRequest()
    .setRequestcallback(
      new RequestCallbackEntity()
        .setAgencyid(payload.agencyId)
        .setFirstname(payload.firstName)
        .setLastname(payload.lastName)
        .setMiddlename(payload.middleName)
        .setPhone(payload.phone)
        .setMessage(payload.message)
    );

  try {
    const requestCallbackRes: CommunicationApiRequestCallbackResponse = yield call(API.requestCallback, requestCallbackReq);

    if (requestCallbackRes.getResultresponse()?.getIssuccessful()) {
      yield put(setDoRequestCallbackResult({}));
    } else {
      yield put(setDoRequestCallbackResult({
        errorMessage: getErrorMessageByCode(requestCallbackRes.getResultresponse()?.getErrormessage())
      }))
    }
  } catch {}
}

function* getRequestCallbackListSaga({payload}: PayloadAction<IGetClientListPayload>) {
  const getClientListReq = new CommunicationApiRequestCallbackListRequest()
    .setPagemodel(
      new PageModel()
        .setPage(payload.page)
        .setLimit(PageModelLimits.ClientList)
    )

  try {
    const getClientListRes: CommunicationApiRequestCallbackListResponse = yield call(API.requestCallbackList, getClientListReq);

    yield put(setRequestCallbackListResult({
      items: getClientListRes.getRequestcallbacksList(),
      totalCount: getClientListRes.getTotalcount(),
    }));
  } catch {}
}

export function* requestCallbackSaga() {
  yield all([
    takeLatest(doRequestCallback.type, doRequestCallbackSaga),
    takeLatest(getRequestCallbackList.type, getRequestCallbackListSaga),
  ])
}