import {NavigationDescriptor} from "../config/acl/roles/RoleDescriptor";
import {roles} from "../config/acl/roles";
import {routes} from "../config/acl/routes";
import {guardIsOverwriteNavigationDescriptor} from "../utils/helpers/acl/getNavigationFromRole";
import {Route} from "../config/acl/routes/Route";
import type {MenuProps} from "antd";
import React from "react";
import {RouteDescriptor} from "../config/acl/routes/RouteDescriptor";
import {PageGroupIconByPageGroup, PageGroups, PageIconByPagePath} from "../config/acl/routes/pageGroups";
import {LogoutOutlined} from "@ant-design/icons";
import {lk} from "../config/acl/routes/lk/lk";
import {headerSharedLinks} from "../components/UI/Header/headerSharedLinks";
import {useAppSelector} from "../store/store";
import {login} from "../config/acl/routes/all/auth/login";
import {register} from "../config/acl/routes/all/auth/register";
import {defineMessage, useIntl} from "react-intl";
import {MenuItemLabel} from "../components/UI/Sidebar/Sidebar";
import {isNotificationForRoute} from "../components/UI/Sidebar/helpers/isNotificationForRoute";
import styled from "styled-components";
import {mediaScreenSizes} from "../utils/constans/styles/screenSizes";

export type MenuItem = Required<MenuProps>['items'][number];

export type ExtendedMenuItem = {
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: ExtendedMenuItem[],
  type?: 'group',
}

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: ExtendedMenuItem[],
  type?: 'group',
): ExtendedMenuItem {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as ExtendedMenuItem;
}

type SidebarRouteDescription = {
  route: Route;
  title: string;
  notificationsCount: number;
  path: string;
  showInSidebar: boolean;
  group?: PageGroups;
  groupTitle?: string;
}

const getRouteFromNavigationDescriptor = (navigationDescriptor: NavigationDescriptor) => {
  let route: Route;
  let routeDescriptor: RouteDescriptor<any>;

  if (guardIsOverwriteNavigationDescriptor(navigationDescriptor)) {
    ({route} = navigationDescriptor);
    routeDescriptor = routes[route];
  } else {
    routeDescriptor = routes[navigationDescriptor];
  }

  return {routeDescriptor};
}

const useNavigationDescriptorListToSidebarRouteDescriptionList = (navigationDescriptorList: NavigationDescriptor[]): SidebarRouteDescription[] => {
  const intl = useIntl();
  const unreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications);
  const sidebarItemListDescription: SidebarRouteDescription[] = [];

  for (let i = 0; i < navigationDescriptorList.length; i++) {
    const {routeDescriptor} = getRouteFromNavigationDescriptor(navigationDescriptorList[i]);
    const onRouteNotificationsCount = unreadNotifications.reduce((acc, cur) => {
      const isForThisRoute = isNotificationForRoute(routeDescriptor.route, cur);
      isForThisRoute && acc++;
      return acc;
    }, 0);
    sidebarItemListDescription.push({
      route: routeDescriptor.route,
      title: intl.formatMessage(routeDescriptor.title),
      notificationsCount: onRouteNotificationsCount,
      path: routeDescriptor.path,
      showInSidebar: routeDescriptor.showInSidebar,
      group: routeDescriptor.group,
      groupTitle: routeDescriptor.groupTitle ? intl.formatMessage(routeDescriptor.groupTitle) : undefined,
    })
  }

  return sidebarItemListDescription;
}

const PageGroupIconWrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
`;

const PageGroupIconWrapperNotification = styled.div`
  display: none !important;
  
  @media(max-width: ${mediaScreenSizes.tablet}) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: #C90000;
    color: #ffffff;
    font-size: 12px;
    border-radius: 100%;
  }
`;

type SidebarGroupType = {
  [key: string]: SidebarRouteDescription[];
}

const groupToMenuItems = (groupTitle: string, group: SidebarRouteDescription[], type: "sidebar" | "mobileBurger", isDetailedMenuCollapsed: boolean) => {
  const item = group.find(item => item);
  const pageGroup = item?.group;

  const notificationsTotalCount = group.reduce((acc, cur) => {
    acc = acc += cur.notificationsCount;
    return acc;
  }, 0);

  const icon = pageGroup !== undefined ? <PageGroupIconWrapper>
    {notificationsTotalCount > 0 && isDetailedMenuCollapsed && <PageGroupIconWrapperNotification>{notificationsTotalCount}</PageGroupIconWrapperNotification>}
    {PageGroupIconByPageGroup.get(pageGroup)}
  </PageGroupIconWrapper> : undefined;


  return getItem(
    <MenuItemLabel title={groupTitle} count={notificationsTotalCount}/>,
    groupTitle,
    icon,
    group.map(r => getItem(r.title, r.path, undefined)),
    type === "mobileBurger" ? "group" : undefined,
  );
}

export const LogoutMessage = defineMessage({
  id: "logout.title",
  defaultMessage: "Log out",
})

export const useGenerateMenu = (type: "sidebar" | "mobileBurger", isDetailedMenuCollapsed: boolean): { menuItems: ExtendedMenuItem[] } => {
  const intl = useIntl();
  const {role, isAuthenticated} = useAppSelector(state => state.auth);

  const sidebarRoutes = useNavigationDescriptorListToSidebarRouteDescriptionList(roles[role].navigation['sidebar']).filter(r => r.showInSidebar);

  let menuItems: ExtendedMenuItem[] = [];
  const pageItems = sidebarRoutes.filter(r => r.groupTitle === undefined).map(r => getItem(<MenuItemLabel
    title={r.title} count={r.notificationsCount}/>, r.path, <PageGroupIconWrapper>
    {r.notificationsCount > 0 && isDetailedMenuCollapsed && <PageGroupIconWrapperNotification>{r.notificationsCount}</PageGroupIconWrapperNotification>}
    {PageIconByPagePath.get(r.path)}
  </PageGroupIconWrapper>));

  const groups = sidebarRoutes.filter(r => r.groupTitle).reduce((acc: SidebarGroupType, cur) => {
    const groupTitle = cur.groupTitle!;
    if (acc.hasOwnProperty(groupTitle))
      acc[groupTitle].push(cur);
    else
      acc[groupTitle] = [cur];
    return acc;
  }, {});

  const pageGroupItems = Object.entries(groups).map(g => groupToMenuItems(g[0], g[1], type, isDetailedMenuCollapsed));

  // in mobile menu we add guest allowed + logout links
  if (type === "mobileBurger") {
    menuItems.push(
      // shared links
      ...headerSharedLinks.map(link => getItem(intl.formatMessage(link.title), link.path)),
    );

    if (isAuthenticated)
      // logout & lk
      // login & register
      menuItems.push(
        getItem(intl.formatMessage(lk.title), lk.path, undefined, [
          ...pageItems,
          ...pageGroupItems,
        ], "group"),
        getItem(intl.formatMessage(LogoutMessage), "logout", <LogoutOutlined/>),
      )
    else
      menuItems.push(
        getItem(intl.formatMessage(login.title), login.path),
        getItem(intl.formatMessage(register.title), register.path)
      )
  } else {
    menuItems.push(
      // lk only
      ...pageItems,
      ...pageGroupItems
    );
  }


  return {menuItems};
}