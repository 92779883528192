import {AirportEntity, CompanyEntity, NotificationEntity} from "../../../proto/generated/api_entities_pb";
import {INotification, INotificationAirport, INotificationCompany, NotificationType} from "./notificationsReducerTypes";
import {mapUserEntityToIUser} from "../userReducer/userMappers";
import {isValidDate, parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";

export const mapNotificationEntityToINotification = (item: NotificationEntity): INotification => {
  const sender = item.getSender();
  if (!sender)
    throw new Error("No UserEntity (sender) in received NotificationEntity")

  const receiver = item.getReceiver();
  if (!receiver)
    throw new Error("No UserEntity (receiver) in received NotificationEntity")

  const createdAtDate = parseStringAsUTCDate(item.getCreatedat());
  if (!isValidDate(createdAtDate))
    throw new Error(`Invalid createdAt in received NotificationEntity, ${item.getCreatedat()}`);

  const metadata = item.getMetadata();
  const requestCompany = metadata?.getRequestcompany();
  const airportFrom = metadata?.getAirportfrom();
  const airportTo = metadata?.getAirportto();
  const airportEntities = metadata?.getAirportsList();
  const airports = airportEntities?.reduce<INotificationAirport[]>((acc, cur, index) => {
    if (index === 0) {
      const firstAirport = cur.getAirportfrom();
      const secondAirport = cur.getAirportto();
      firstAirport && secondAirport
        && acc.push(mapAirportEntityToINotificationAirport(firstAirport))
        && acc.push(mapAirportEntityToINotificationAirport(secondAirport));
    } else {
      const anotherAirport = cur.getAirportto();
      anotherAirport && acc.push(mapAirportEntityToINotificationAirport(anotherAirport));
    }
    return acc;
  }, [])

  return {
    id: item.getNotificationid(),
    type: item.getType() as NotificationType,
    entityId: item.getEntityid(),
    isRead: item.getIsread(),
    sender: sender.getUserid() !== 0 ? mapUserEntityToIUser(sender) : undefined,
    receiver: mapUserEntityToIUser(receiver),
    dateCreated: createdAtDate,
    metadata: {
      airports: airports,
      airportFrom: airportFrom ? mapAirportEntityToINotificationAirport(airportFrom) : undefined,
      airportTo: airportTo ? mapAirportEntityToINotificationAirport(airportTo) : undefined,
      company: requestCompany ? mapCompanyEntityToINotificationCompany(requestCompany) : undefined,
      emptyLegId: metadata?.getEmptyleg()?.getEmptylegid(),
      emptyLegOrderRouteId: metadata?.getEmptylegorderroute()?.getEmptylegorderrouteid(),
      orderRouteId: metadata?.getOrderroute()?.getOrderrouteid(),
      orderRequestId: metadata?.getOrderrequest()?.getOrderrequestid(),
      orderId: metadata?.getOrder()?.getOrderid(),
    },
  }
}


export const mapAirportEntityToINotificationAirport = (item: AirportEntity): INotificationAirport => {
  return {
    id: item.getAirportid(),
    icaoCode: item.getIcaocode(),
    iataCode: item.getIatacode(),
  }
}


export const mapCompanyEntityToINotificationCompany = (item: CompanyEntity): INotificationCompany => {
  return {
    id: item.getCompanyid(),
    name: item.getCompanyname(),
  }
}