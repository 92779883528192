import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {mapAirportEntityToIAirport} from "../fleetReducer/fleetMappers";
import {parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {
  ICreateEventPayload, IDeleteEventPayload, IEditEventPayload,
  IGetSchedulePayload,
  IScheduleInitialState, ISetCreateEventResultPayload, ISetDeleteEventResultPayload, ISetEditEventResultPayload,
  ISetScheduleResultPayload
} from "./scheduleReducerTypes";
import {
  initCommonClearCreate,
  initCommonClearDelete,
  initCommonClearEdit,
  initCommonClearList,
  initCommonCreate,
  initCommonCreateResult,
  initCommonDelete,
  initCommonDeleteResult,
  initCommonEdit,
  initCommonEditResult,
  initCommonGetList,
  initCommonSetListResult
} from "../highOrderReducers/commonReducer";
import {mapScheduleEntityToIPlaneEvent} from "./scheduleMappers";

const scheduleInitialState: IScheduleInitialState = {
  itemsObject: {
    items: [],
    isLoading: true,
    totalCount: 0,
  },
  create: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  edit: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
  }
}

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: scheduleInitialState,
  reducers: {
    getSchedule(state, {payload}: PayloadAction<IGetSchedulePayload>) {
      initCommonGetList(state, payload)
    },
    clearScheduleResult(state) {
      initCommonClearList(state);
    },
    setScheduleResult(state, {payload}: PayloadAction<ISetScheduleResultPayload>) {
      initCommonSetListResult(state, payload, mapScheduleEntityToIPlaneEvent)
    },

    createEvent(state, {payload}: PayloadAction<ICreateEventPayload>) {
      initCommonCreate(state, payload);
    },
    clearCreateEvent(state) {
      initCommonClearCreate(state);
    },
    setCreateEventResult(state, {payload}: PayloadAction<ISetCreateEventResultPayload>) {
      initCommonCreateResult(state, payload, mapScheduleEntityToIPlaneEvent);
    },

    editEvent(state, {payload}: PayloadAction<IEditEventPayload>) {
      initCommonEdit(state, payload)
    },
    clearEditEvent(state) {
      initCommonClearEdit(state);
    },
    setEditEventResult(state, {payload}: PayloadAction<ISetEditEventResultPayload>) {
      initCommonEditResult(state, payload, mapScheduleEntityToIPlaneEvent);
    },

    deleteEvent(state, {payload}: PayloadAction<IDeleteEventPayload>) {
      initCommonDelete(state, payload)
    },
    clearDeleteEvent(state) {
      initCommonClearDelete(state);
    },
    setDeleteEventResult(state, {payload}: PayloadAction<ISetDeleteEventResultPayload>) {
      initCommonDeleteResult(state, payload)
    }
  },
});

export const {
  getSchedule,
  setScheduleResult,
  clearScheduleResult,

  createEvent,
  setCreateEventResult,
  clearCreateEvent,

  editEvent,
  setEditEventResult,
  clearEditEvent,

  deleteEvent,
  setDeleteEventResult,
  clearDeleteEvent,
} = scheduleSlice.actions;
export default scheduleSlice.reducer;