import React, {FC, ReactNode} from 'react';
import styled from "styled-components";
import {FONTS} from "../../../../utils/constans/styles/fonts";

const SerialNumber = styled.div`
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 16px;
  line-height: 24px;
  color: #1890FF;
`;

const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const InfoCardTitle = styled.div`
  font-family: ${FONTS.TEXT_MEDIUM_FUTURA};
  font-size: 25px;
  line-height: 25px;
  color: #000000;
`;

const InfoCardDescription = styled.div`
  font-family: ${FONTS.TEXT_REGULAR_INTER};
  font-size: 14px;
  line-height: 21px;
  color: #242527;
`;

interface IHomeInfoCardStyled {
  maxWidth: string;
  minHeight: string;
}

const HomeInfoCardStyled = styled.div<IHomeInfoCardStyled>`
  display: flex;
  flex-direction: column;
  row-gap: 86px;
  background: #F3F5F7;
  border: 1px solid #C6C2C0;
  padding: 32px 24px 32px 24px;
  max-width: ${props => props.maxWidth};
  min-height: ${props => props.minHeight};
  transition: 0.2s ease;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
  
  :hover {
    background: #00529D;
    box-shadow: 10px 19px 70px rgba(0, 39, 74, 0.46);
    border: 1px solid #00529D;

    ${SerialNumber} {
      color: #EEF1F4;
    }
    
    ${InfoCardTitle}, ${InfoCardDescription} {
      color: #F7F3F0;
    }
  }
`;

interface IHomeInfoCard {
  serialNumber: number | ReactNode;
  title: string;
  description?: string;
  maxWidth?: string;
  minHeight?: string;
  [key: string]: any;
}

export const HomeInfoCard: FC<IHomeInfoCard> = ({serialNumber, title, description, maxWidth="none", minHeight="none", ...otherProps}) => {
  return (
    <HomeInfoCardStyled maxWidth={maxWidth} minHeight={minHeight} {...otherProps}>
      <SerialNumber>{serialNumber}</SerialNumber>
      <TitleAndDescription>
        <InfoCardTitle>{title}</InfoCardTitle>
        {description && <InfoCardDescription>{description}</InfoCardDescription>}
      </TitleAndDescription>
    </HomeInfoCardStyled>
  );
};
