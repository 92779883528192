import React, {FC} from "react";
import {
  FlightDrawerFooterContent,
  FlightDrawerFooterContentWrapper,
} from "./FlightDrawerFooterStyledComponents";
import {CustomButton} from "../../../UI/AntCustom/CustomButton";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../intl/commonMessages/CommonButtonMessages";


const FlightDrawerEmptyLegFooterMessages = defineMessages({
  archiveEmptyLeg: {
    id: "flightDrawerEmptyLegFooter.archiveEmptyLeg",
    defaultMessage: "Archive",
  }
})

interface IFlightDrawerEmptyLegFooter {
  onEdit: () => void;
  onArchive: () => void;
}

export const FlightDrawerEmptyLegFooter: FC<IFlightDrawerEmptyLegFooter> = ({
                                                                              onEdit,
                                                                              onArchive,
                                                                            }) => {
  const intl = useIntl();

  return <FlightDrawerFooterContentWrapper>
    <FlightDrawerFooterContent>
      <CustomButton onClick={onArchive} danger={true} style={{flexGrow: 1}}>{intl.formatMessage(FlightDrawerEmptyLegFooterMessages.archiveEmptyLeg)}</CustomButton>
      <CustomButton onClick={onEdit} type={"primary"} style={{flexGrow: 1}}>{intl.formatMessage(CommonButtonMessages.edit)}</CustomButton>
    </FlightDrawerFooterContent>
  </FlightDrawerFooterContentWrapper>
};