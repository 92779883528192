import {RoleDescriptor} from "../../RoleDescriptor";
import {Role} from "../../Role";
import {Route} from "../../../routes/Route";
import {defineMessage} from "react-intl";

const ChartererAdminNotApprovedMessage = defineMessage({
  id: "chartererAdminNotApproved.name",
  defaultMessage: "Broker (upload licenses)",
})

export const chartererAdminNotApproved: RoleDescriptor<Role.chartererAdminNotApproved> = {
  role: Role.chartererAdminNotApproved,
  name: ChartererAdminNotApprovedMessage,
  backendIdentifier: 'non_approved_charterer_admin',
  homeRoute: Route.profile,
  navigation: {
    profile: [
    ],
    sidebar: [
      Route.profile,
    ]
  },
}