export enum Role {
  unknown,

  guest,
  admin,

  freighterAdmin,
  freighterAdminNotApproved,
  freighterServiceManager,
  freighterClientManager,

  chartererAdminNotApproved,
  chartererAdmin,
  chartererManager,
}