import React, {FC, useContext} from 'react';
import styled from "styled-components";
import {ReactComponent as CaretRightIcon} from "../../../media/icons/caret_right.svg";
import {getNotificationIconByType} from "../helpers/getNotificationIconByType";
import {INotification, NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {formatDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {getNotificationText} from "../helpers/getNotificationText";
import {useIntl} from "react-intl";
import {getNotificationMetadata} from "../helpers/getNotificationMetadata";
import {getNotificationCallback} from "../helpers/getNotificationRedirect";
import {readNotifications} from "../../../store/reducers/notificationsReducer/notificationsReducer";
import {ChatModal} from "../../businessCommon/chat/ChatModal";
import {useChat} from "../../businessCommon/chat/useChat";
import {NotificationsContext} from "../context/NotificationsProvider";

const NotificationEventWrapper = styled.div<{ isRead: boolean; }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 16px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid ${props => props.isRead ? "#D9D9D9" : "#1890FF"};
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  margin-bottom: 8px;

  :hover {
    opacity: 0.7;
  }
`;

const NotificationIcon = styled.img`
  display: flex;
  height: 20px;
  width: 20px;
  object-fit: contain;
`;

const NotificationEventDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const NotificationEventTime = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
`;

const NotificationText = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const NotificationMetadataText = styled.div`
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

const ToEventBlock = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  svg path {
    fill: #000000;
  }
`;

interface INotificationEvent {
  notification: INotification;
}

export const NotificationEvent: FC<INotificationEvent> = ({notification}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {setIsChatFromNotificationEventOpened, setChatFromNotificationEvent} = useContext(NotificationsContext);
  const timezoneOffset = useAppSelector(state => state.user.oneItemObject.item.timezone.offset);
  const userId = useAppSelector(state => state.user.oneItemObject.item.id);
  const chat = useChat(userId, notification.sender?.id ?? 0, notification.metadata.orderId ?? 0);

  const onNotificationClick = () => {
    if (notification.type === NotificationType.TYPE_INCOME_MESSAGE && chat && notification.metadata.orderId && notification.sender?.id) {
      setIsChatFromNotificationEventOpened(true);
      setChatFromNotificationEvent(<ChatModal chat={chat}
                                              isVisible={true}
                                              setIsVisible={setIsChatFromNotificationEventOpened}/>);
    } else {
      const redirectCallback = getNotificationCallback(notification);
      redirectCallback()
      dispatch(readNotifications({notificationIds: [notification.id]}))
    }
  }

  return (
    <NotificationEventWrapper isRead={notification.isRead} onClick={onNotificationClick}>
      <NotificationIcon src={getNotificationIconByType(notification.type)} alt={"Notification image"}/>

      <NotificationEventDataWrapper>
        <NotificationEventTime>
          {formatDate(notification.dateCreated, timezoneOffset, "time")}
        </NotificationEventTime>

        <NotificationText>
          {getNotificationText(notification, intl)}
        </NotificationText>

        <NotificationMetadataText>
          {getNotificationMetadata(notification)}
        </NotificationMetadataText>
      </NotificationEventDataWrapper>

      <ToEventBlock>
        <CaretRightIcon/>
      </ToEventBlock>
    </NotificationEventWrapper>
  );
};
