import {OrderRequestEntity} from "../../../proto/generated/api_entities_pb";
import {
  IFreighterOrderFromOrderRequest,
  IFreighterOrderRequest,
  IFreighterOrderRouteOrderRequest
} from "./freighterRequestsReducerTypes";
import {isValidDate, parseStringAsUTCDate} from "../../../utils/helpers/dateHelpers/dateHelpers";
import {OrderRequestStatusEnum} from "../../../utils/constans/statuses/orderRequestStatuses";
import {
  mapAirportEntityToIAirport,
  mapPlaneCategoryEntityToIPlaneCategory,
  mapPlaneEntityToIPlane
} from "../fleetReducer/fleetMappers";
import {mapUserEntityToIUser} from "../userReducer/userMappers";
import {mapCompanyEntityToICompany} from "../companyReducer/companyMappers";
import {mapUserEntityToIManager} from "../personnelManagementReducer/personnelManagementMappers";
import {calculateArrivalDate} from "../../../utils/helpers/dateHelpers/calculateArrivalDate";
import {OrderTypeEnum} from "../chartererRequestsReducer/chartererRequestsReducerTypes";


export const mapOrderRequestEntityToIFreighterOrderRequest = (item: OrderRequestEntity): IFreighterOrderRequest => {
  const company = item.getFlycompany();
  if (!company)
    throw new Error("Null CompanyEntity (flyCompany) in received OrderRequestEntity");

  const user = item.getFreighter();
  if (!user)
    throw new Error("Null UserEntity (freighter) in received OrderRequestEntity");

  const createdAtDate = parseStringAsUTCDate(item.getCreatedat())
  if (!isValidDate(createdAtDate))
    throw new Error(`Invalid createdAtDate in received OrderRequestEntity, ${item.getCreatedat()}`);

  const plane = item.getPlane();
  //TODO ask backend to receive undefined or existing plane
  const isPlaneOffered = !!plane && plane.getPlaneid() !== 0;

  const currency = item.getCurrency();
  if (isPlaneOffered && !currency)
    throw new Error("Null CurrencyEntity (currency) in received OrderRequestEntity");

  const orderFromOrderRequest = item.getOrder();
  if (!orderFromOrderRequest)
    throw new Error("Null OrderEntity (order) in received OrderRequestEntity");

  const planeCategories = orderFromOrderRequest.getPlanecategoriesList().map(mapPlaneCategoryEntityToIPlaneCategory);

  const orderUser = orderFromOrderRequest.getUser();
  if (!orderUser)
    throw new Error("Null UserEntity (user) in received OrderRequestEntity > OrderEntity");

  const orderCompany = orderUser.getCompany();
  if (!orderCompany)
    throw new Error("Null CompanyEntity (company) in received OrderRequestEntity > OrderEntity > UserEntity");

  const orderCreatedAtDate = parseStringAsUTCDate(orderFromOrderRequest.getCreatedat())
  if (!isValidDate(orderCreatedAtDate))
    throw new Error(`Invalid createdAt in received OrderRequestEntity, ${orderFromOrderRequest.getCreatedat()}`);

  let orderRouteOrderRequests: IFreighterOrderRouteOrderRequest[] = item.getOrderrouteorderrequestsList().map(orderRouteOrderRequest => {
    const orderRoute = orderFromOrderRequest.getOrderroutesList().find(orderRoute => orderRoute.getOrderrouteid() === orderRouteOrderRequest.getOrderroute()?.getOrderrouteid());
    if (!orderRoute)
      throw new Error("Null OrderRouteEntity (orderRoute) in received OrderRequestEntity");

    const orderRouteAirportFrom = orderRoute.getAirportfrom();
    if (!orderRouteAirportFrom)
      throw new Error("Null AirportEntity (airportFrom) in received OrderRequestEntity > OrderRouteEntity");
    const orderRouteAirportFromMapped = mapAirportEntityToIAirport(orderRouteAirportFrom);

    const orderRouteAirportTo = orderRoute.getAirportto();
    if (!orderRouteAirportTo)
      throw new Error("Null AirportEntity (airportTo) in received OrderRequestEntity > OrderRouteEntity");
    const orderRouteAirportToMapped = mapAirportEntityToIAirport(orderRouteAirportTo);

    const departureDate = parseStringAsUTCDate(orderRoute.getDeparturedate())
    if (!isValidDate(departureDate))
      throw new Error(`Invalid departureDate in received OrderRequestEntity, ${orderRoute.getDeparturedate()}`);

    const flyHours = Math.floor(orderRouteOrderRequest.getFlyduration() / 60);
    const flyMinutes = orderRouteOrderRequest.getFlyduration() % 60;

    const arrivalDate = calculateArrivalDate(orderRouteAirportFromMapped, orderRouteAirportToMapped, departureDate, orderRouteOrderRequest.getFlyduration() + orderRouteOrderRequest.getTechnicalstopduration());
    if (isPlaneOffered && !isValidDate(arrivalDate))
      throw new Error(`Invalid arrivalDate`);

    return {
      orderRouteOrderRequestId: orderRouteOrderRequest.getOrderrouteorderrequestid(),
      orderRequestId: item.getOrderrequestid(),
      orderRouteId: orderRoute.getOrderrouteid(),
      flyHours: flyHours,
      flyMinutes: flyMinutes,
      flyDistance: orderRouteOrderRequest.getFlydistance(),
      technicalStopDuration: orderRouteOrderRequest.getTechnicalstopduration(),
      technicalStopCount: orderRouteOrderRequest.getTechnicalstopcount(),
      arrivalDate: arrivalDate,
      orderRoute: {
        id: orderRoute.getOrderrouteid(),
        airportFrom: orderRouteAirportFromMapped,
        airportTo: orderRouteAirportToMapped,
        departureDate: departureDate,
        pax: orderRoute.getPax(),
      }
    }
  })

  if (orderRouteOrderRequests.length === 0) {
    orderRouteOrderRequests = orderFromOrderRequest.getOrderroutesList().map(orderRoute => {
      const orderRouteAirportFrom = orderRoute.getAirportfrom();
      if (!orderRouteAirportFrom)
        throw new Error("Null AirportEntity (airportFrom) in received OrderRequestEntity > OrderEntity > OrderRouteEntity");
      const orderRouteAirportFromMapped = mapAirportEntityToIAirport(orderRouteAirportFrom);

      const orderRouteAirportTo = orderRoute.getAirportto();
      if (!orderRouteAirportTo)
        throw new Error("Null AirportEntity (airportTo) in received OrderRequestEntity > OrderEntity > OrderRouteEntity");
      const orderRouteAirportToMapped = mapAirportEntityToIAirport(orderRouteAirportTo);

      const departureDate = parseStringAsUTCDate(orderRoute.getDeparturedate())
      if (!isValidDate(departureDate))
        throw new Error(`Invalid departureDate in received OrderRequestEntity > OrderEntity > OrderRouteEntity, ${orderRoute.getDeparturedate()}`);

      return {
        orderRouteOrderRequestId: undefined,
        orderRequestId: item.getOrderrequestid(),
        orderRouteId: orderRoute.getOrderrouteid(),
        flyHours: undefined,
        flyMinutes: undefined,
        flyDistance: undefined,
        technicalStopDuration: undefined,
        technicalStopCount: undefined,
        arrivalDate: undefined,
        orderRoute: {
          id: orderRoute.getOrderrouteid(),
          airportFrom: orderRouteAirportFromMapped,
          airportTo: orderRouteAirportToMapped,
          departureDate: departureDate,
          pax: orderRoute.getPax(),
        }
      }
    })
  }

  const order: IFreighterOrderFromOrderRequest = {
    orderId: orderFromOrderRequest.getOrderid(),
    type: orderFromOrderRequest.getType() as OrderTypeEnum,
    status: orderFromOrderRequest.getOrderstatusid(),
    manager: mapUserEntityToIManager(orderUser),
    company: mapCompanyEntityToICompany(orderCompany),
    createdDate: orderCreatedAtDate,
    planeCategories: planeCategories,
    companionsAllowed: orderFromOrderRequest.getCompanionsallowed(),
    comment: orderFromOrderRequest.getComment(),
    isArchived: orderFromOrderRequest.getArchived(),
  }

  return {
    id: item.getOrderrequestid(),
    user: mapUserEntityToIUser(user),
    company: mapCompanyEntityToICompany(company),
    status: item.getOrderrequeststatusid() as OrderRequestStatusEnum,
    plane: isPlaneOffered ? mapPlaneEntityToIPlane(plane!) : undefined,
    price: isPlaneOffered ? item.getPrice() : undefined,
    currency: isPlaneOffered && currency ? {
      id: currency.getCurrencyid(),
      name: currency.getName(),
    } : undefined,
    dateCreated: createdAtDate,
    isArchived: item.getArchived(),
    isPlaneOffered: isPlaneOffered,
    order: order,
    orderRouteOrderRequests: orderRouteOrderRequests,
  }
}