import React, {FC, useMemo, useState} from 'react';
import {formatDate} from "../../../../../utils/helpers/dateHelpers/dateHelpers";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {useChat} from "../../../../../components/businessCommon/chat/useChat";
import styled from "styled-components";
import defaultAvatar from "../../../../../media/images/default_avatar.png";
import {ReactComponent as TrashIcon} from "../../../../../media/icons/delete.svg";
import {ReactComponent as CaretRight} from "../../../../../media/icons/caret_right.svg";
import {
  OrderRequestBrokerDrawer
} from "../../../../../components/businessCommon/brokerDrawer/wrappers/OrderRequestBrokerDrawer";
import {
  OrderRequestOrderRouteFlightDrawer
} from "../../../../../components/businessCommon/flightDrawer/wrappers/OrderRequestOrderRouteFlightDrawer";
import {ChatModal} from "../../../../../components/businessCommon/chat/ChatModal";
import {ArchiveOrderRequestModal} from "./ArchiveOrderRequestModal";
import {DisplayManagerFullName} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFullName";
import {defineMessages, useIntl} from "react-intl";
import {CommonButtonMessages} from "../../../../../intl/commonMessages/CommonButtonMessages";
import {MakeOfferDrawer} from "./MakeOfferDrawer";
import {Logger} from "../../../../../utils/logger/Logger";
import {ChatButton} from "../../../../../components/businessCommon/chat/ChatButton";
import {
  IFreighterOrderRequest
} from "../../../../../store/reducers/freighterRequestsReducer/freighterRequestsReducerTypes";
import {VerticalDivider} from "../../../../../components/UI/VerticalDivider/VerticalDivider";
import {FlyRouteMini} from "../../../../../components/businessCommon/flyRoute/FlyRouteMini";
import {IAirport} from "../../../../../store/reducers/fleetReducer/fleetReducerTypes";
import {OrderTypeShield} from "../../../../../components/businessCommon/orderType/OrderTypeShield";
import {CustomButton} from "../../../../../components/UI/AntCustom/CustomButton";
import {OrderStatusEnum} from "../../../../../store/reducers/chartererRequestsReducer/chartererRequestsReducerTypes";
import planeDefaultImage from "../../../../../media/images/no_photo.jpg";
import {DisplayFlyPrice} from "../../../../../utils/helpers/textDisplayHelpers/DisplayFlyPrice";
import {readNotifications} from "../../../../../store/reducers/notificationsReducer/notificationsReducer";
import {NotificationType} from "../../../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {useIsMobile} from "../../../../../hooks/useWindowSize";
import {mediaScreenSizes} from "../../../../../utils/constans/styles/screenSizes";
import {CustomLinkButton} from "../../../../../components/UI/Buttons/CustomLinkButton";

const OrderRequestCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  flex-wrap: nowrap;
`;

const OrderRequestCardWrapperMobile = styled.div`
  background: #fafafa;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 13px 16px 24px 16px;
  display: flex;
  flex-direction: column;
`;

const OrderRequestCardOrderTypeAndRouteMobile = styled.div<{ isOrderDeclined: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 16px 0;

  div:not(:last-child) {
    opacity: ${props => props.isOrderDeclined ? 0.3 : 1};
  }
`;

const OrderRequestCardBroker = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 13px 10px 14px 16px;
  width: 309px;
  min-width: 309px;
`;

const OrderRequestBrokerInfo = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  & > img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
  }

  & > div {
    display: flex;
    flex-direction: column;

    div {
      :nth-child(1) {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 2px;
        color: #040404;
      }

      :nth-child(2) {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        letter-spacing: -0.01em;
      }

      :nth-child(3) {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #1890FF;
        cursor: pointer;
      }
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    & > img {
      width: 44px;
      height: 44px;
      border-radius: 100%;
      object-fit: cover;
    }

    padding-bottom: 8px;
    border-bottom: 1px solid #D9D9D9;
  }
`;

const OrderRequestCardOrder = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 16px 16px 17px 16px;
  flex-grow: 1;
`;

const OrderRequestOrderType = styled.div<{ isOrderDeclined: boolean }>`
  display: flex;
  align-items: center;
  width: 115px;
  opacity: ${props => props.isOrderDeclined ? 0.3 : 1};
`;

const OrderRequestOrderRoutes = styled.div<{ isOrderDeclined?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  align-items: flex-start;
  justify-content: center;
  row-gap: 7px;
  flex-grow: 1;

  & > div {
    :nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #808080;
    }
  }

  @media (max-width: ${mediaScreenSizes.mobile}) {
    padding: 0;
  }
`;

const OrderRequestOfferSection = styled.div`
  width: 253px;
  min-width: 253px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const DeclinedShield = styled.div`
  padding: 4px 24px;
  background: #E7C4C4;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 286px;
  border-radius: 23px;

  @media (max-width: ${mediaScreenSizes.mobile}) {
    position: absolute;
    right: 0;
    top: 16px;
  }
`;

const OfferDetails = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  flex-grow: 1;

  & > svg > path {
    fill: #448EF7;
  }

  & > div {
    padding: 3px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;

    img {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 3px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      row-gap: 2px;

      & > div {
        :nth-child(1) {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }

        :nth-child(2) {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #808080;
        }
      }
    }
  }
`;


const OfferDetailsMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border: 1px solid #1890FF;
  border-radius: 5px;

  & > svg > path {
    fill: #1890FF;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;

    & > img {
      border-radius: 100%;
      width: 44px;
      height: 44px;
      object-fit: cover;
    }

    & > div > div {
      :nth-child(1) {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      :nth-child(2) {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #808080;
      }
    }
  }
`;


const OrderRequestCardMessages = defineMessages({
  offer: {
    id: "orderRequestCardMessages.offer",
    defaultMessage: "Offer",
  },
  makeOffer: {
    id: "orderRequestCardMessages.makeOffer",
    defaultMessage: "Make offer",
  },
  orderRouteArchivedByBroker: {
    id: "orderRequestCardMessages.orderRouteArchivedByBroker",
    defaultMessage: "Declined",
  },
})


interface IOrderRequestCard {
  orderRequest: IFreighterOrderRequest;
}

export const OrderRequestCard: FC<IOrderRequestCard> = ({orderRequest}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const {offset} = useAppSelector(state => state.user.oneItemObject.item.timezone);
  const userId = useAppSelector(state => state.user.oneItemObject.item.id)
  const [isChatOpened, setIsChatOpened] = useState<boolean>(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [isMakeOfferDrawerOpen, setIsMakeOfferDrawerOpen] = useState<boolean>(false);
  const [isBrokerDrawerOrderRequestOpen, setIsBrokerDrawerOrderRequestOpen] = useState<boolean>(false);
  const [isFlightDrawerOrderRequestOpen, setIsFlightDrawerOrderRequestOpen] = useState<boolean>(false);
  const chat = useChat(userId, orderRequest.order.manager.id, orderRequest.order.orderId);
  const allUnreadNotifications = useAppSelector(state => state.notifications.unreadOnlyNotifications)

  //TODO ask about order request notifications reading
  // const hasNotifications = useMemo(() => {
  //   const unreadNotifications = allUnreadNotifications.filter(n => n.metadata.orderRequestId === orderRequest.id);
  //   if (unreadNotifications.length > 0) {
  //     dispatch(readNotifications({
  //       notificationIds: unreadNotifications.filter(n => n.type !== NotificationType.TYPE_INCOME_MESSAGE).map(n => n.id)
  //     }));
  //   }
  //   return unreadNotifications.length > 0;
  // }, []);

  const unreadChatNotifications = useMemo(() => allUnreadNotifications.filter(n => n.entityId === chat?.chatId
    && n.metadata.orderRequestId === orderRequest.id), [allUnreadNotifications]);

  if (!chat)
    Logger.error(`No chat found for: userId (account owner): ${userId}, agencyManagerId: ${orderRequest.order.manager.id}, orderId: ${orderRequest.order.orderId}`)

  const onChatOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsChatOpened(true);
  }

  const allOrderAirports: IAirport[] = useMemo(() => orderRequest.orderRouteOrderRequests.reduce((acc, cur, index) => {
    if (index === 0)
      acc.push(cur.orderRoute.airportFrom);

    acc.push(cur.orderRoute.airportTo);
    return acc;
  }, [] as IAirport[]), [orderRequest.orderRouteOrderRequests])

  const planeImage = orderRequest.plane?.imageFiles.find(x => x !== undefined)?.url || planeDefaultImage;

  const isOrderDeclinedByBroker = orderRequest.order.isArchived && !orderRequest.isArchived;

  const firstRoute = orderRequest.orderRouteOrderRequests[0].orderRoute;
  const lastRoute = orderRequest.orderRouteOrderRequests.slice(-1)[0].orderRoute;
  const isOnlyRoute = firstRoute.id === lastRoute.id;

  const offerButtons = !orderRequest.isPlaneOffered && <>
    <CustomButton type={'primary'} ghost heightSize={'m'} style={{flexGrow: 1}}
                  onClick={() => setIsBrokerDrawerOrderRequestOpen(true)}>
      {intl.formatMessage(CommonButtonMessages.look)}
    </CustomButton>
    <CustomButton type={'primary'} disabled={isOrderDeclinedByBroker || orderRequest.isArchived} heightSize={'m'}
                  style={{flexGrow: 1, marginTop: isMobile ? 8 : 0}}
                  onClick={() => setIsMakeOfferDrawerOpen(true)}>
      {intl.formatMessage(OrderRequestCardMessages.offer)}
    </CustomButton>
  </>

  const offerDetails = orderRequest.isPlaneOffered && orderRequest.plane && orderRequest.price && orderRequest.currency &&
    (isMobile ? <OfferDetailsMobile onClick={() => setIsFlightDrawerOrderRequestOpen(true)}>
        <div>
          <img src={planeImage} alt="Plane photo"/>
          <div>
            <div>{orderRequest.plane.planeType.name}</div>
            <div>{DisplayFlyPrice(orderRequest.price, orderRequest.currency)}</div>
          </div>
        </div>
        <CaretRight/>
      </OfferDetailsMobile>
      : <OfferDetails onClick={() => setIsFlightDrawerOrderRequestOpen(true)}>
        <div>
          <img src={planeImage} alt="Plane photo"/>
          <div>
            <div>{orderRequest.plane.planeType.name}</div>
            <div>{DisplayFlyPrice(orderRequest.price, orderRequest.currency)}</div>
          </div>
        </div>
        <CaretRight/>
      </OfferDetails>);


  const allModals = <>
    {isArchiveModalOpen &&
      <ArchiveOrderRequestModal orderRequestId={orderRequest.id} isModalVisible={isArchiveModalOpen}
                                setIsModalVisible={setIsArchiveModalOpen}/>}

    {isBrokerDrawerOrderRequestOpen &&
      <OrderRequestBrokerDrawer orderRequest={orderRequest}
                                isOpen={isBrokerDrawerOrderRequestOpen}
                                setIsOpen={setIsBrokerDrawerOrderRequestOpen}
                                openChat={() => setIsChatOpened(true)}
                                openArchive={() => setIsArchiveModalOpen(true)}
                                openMakeOffer={() => setIsMakeOfferDrawerOpen(true)}/>}

    {isFlightDrawerOrderRequestOpen && orderRequest.isPlaneOffered &&
      <OrderRequestOrderRouteFlightDrawer orderRequest={orderRequest} isOpen={isFlightDrawerOrderRequestOpen}
                                          setIsOpen={setIsFlightDrawerOrderRequestOpen}
                                          openChat={() => setIsChatOpened(true)}/>}

    {isChatOpened && chat &&
      <ChatModal chat={chat}
                 isVisible={isChatOpened} setIsVisible={setIsChatOpened}
      />}

    {isMakeOfferDrawerOpen && <MakeOfferDrawer orderRequest={orderRequest}
                                               isOpen={isMakeOfferDrawerOpen}
                                               setIsOpen={setIsMakeOfferDrawerOpen}/>}
  </>

  if (isMobile) {
    return <OrderRequestCardWrapperMobile>
      <OrderRequestBrokerInfo>
        <img src={orderRequest.order.company.avatar?.url || defaultAvatar} alt="avatar"/>

        <div>
          <div>{formatDate(orderRequest.order.createdDate, offset, "date")}</div>
          <div>{DisplayManagerFullName(orderRequest.order.manager)}</div>
          <div onClick={() => setIsBrokerDrawerOrderRequestOpen(true)}>{orderRequest.order.company.name}</div>
        </div>

        <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen}
                    style={{marginLeft: "auto"}}/>
      </OrderRequestBrokerInfo>

      <OrderRequestCardOrderTypeAndRouteMobile isOrderDeclined={isOrderDeclinedByBroker}>
        <OrderTypeShield type={orderRequest.order.type}/>
        <OrderRequestOrderRoutes>
          <div>
            {formatDate(firstRoute.departureDate, firstRoute.airportFrom.timezone.offset, isOnlyRoute ? "datetime" : "date")}
            {!isOnlyRoute && ` – ${formatDate(lastRoute.departureDate, lastRoute.airportFrom.timezone.offset, "date")}`}
          </div>
          <FlyRouteMini airports={allOrderAirports} onMoreClick={() => setIsBrokerDrawerOrderRequestOpen(true)}/>

          {isOrderDeclinedByBroker &&
            <DeclinedShield>{intl.formatMessage(OrderRequestCardMessages.orderRouteArchivedByBroker)}</DeclinedShield>}
        </OrderRequestOrderRoutes>
      </OrderRequestCardOrderTypeAndRouteMobile>

      {offerButtons}
      {offerDetails}

      {!orderRequest.isArchived && <CustomLinkButton icon={<TrashIcon/>} onClick={() => setIsArchiveModalOpen(true)}
                                                     style={{paddingTop: 16}}>{intl.formatMessage(CommonButtonMessages.archive)}</CustomLinkButton>}

      {allModals}
    </OrderRequestCardWrapperMobile>
  }

  return (
    <OrderRequestCardWrapper>
      <OrderRequestCardBroker>
        <OrderRequestBrokerInfo>
          <img src={orderRequest.order.company.avatar?.url || defaultAvatar} alt="avatar"/>

          <div>
            <div>{formatDate(orderRequest.order.createdDate, offset, "date")}</div>
            <div>{DisplayManagerFullName(orderRequest.order.manager)}</div>
            <div onClick={() => setIsBrokerDrawerOrderRequestOpen(true)}>{orderRequest.order.company.name}</div>
          </div>
        </OrderRequestBrokerInfo>

        <ChatButton hasNewMessages={unreadChatNotifications.length > 0} onClick={onChatOpen}/>
      </OrderRequestCardBroker>

      <OrderRequestCardOrder>
        <OrderRequestOrderType isOrderDeclined={isOrderDeclinedByBroker}>
          <OrderTypeShield type={orderRequest.order.type}/>
        </OrderRequestOrderType>

        <VerticalDivider/>

        <OrderRequestOrderRoutes isOrderDeclined={isOrderDeclinedByBroker}>
          <div>
            {formatDate(firstRoute.departureDate, firstRoute.airportFrom.timezone.offset, isOnlyRoute ? "datetime" : "date")}
            {!isOnlyRoute && ` – ${formatDate(lastRoute.departureDate, lastRoute.airportFrom.timezone.offset, "date")}`}
          </div>
          <FlyRouteMini airports={allOrderAirports} onMoreClick={() => setIsBrokerDrawerOrderRequestOpen(true)}/>
        </OrderRequestOrderRoutes>

        <OrderRequestOfferSection>
          {!orderRequest.isArchived && <CustomButton heightSize={'m'} style={{width: 45, background: "#FAFAFA"}} icon={<TrashIcon/>}
                        onClick={() => setIsArchiveModalOpen(true)}/>}
          {offerButtons}
          {offerDetails}
        </OrderRequestOfferSection>

        {isOrderDeclinedByBroker &&
          <DeclinedShield>{intl.formatMessage(OrderRequestCardMessages.orderRouteArchivedByBroker)}</DeclinedShield>}
      </OrderRequestCardOrder>

      {allModals}
    </OrderRequestCardWrapper>
  );
};
