import React, {useMemo} from "react";
import {routes} from "../config/acl/routes";
import {ACL} from "./ACL";
import {Route as ReactRoute, Navigate, Routes} from "react-router-dom";
import {Route} from "../config/acl/routes/Route";
import {lk} from "../config/acl/routes/lk/lk";
import {mediaScreenSizes} from "../utils/constans/styles/screenSizes";
import styled from "styled-components";

const LkRoutesWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  // for sidebar space
  padding-left: 256px;
  box-sizing: border-box;
  max-width: 100vw;
  
  @media (max-width: ${mediaScreenSizes.tablet}) {
    // tablet has mini sidebar
    padding-left: 77px;
  }
  
  @media (max-width: ${mediaScreenSizes.mobile}) {
    // mobile has no sidebar so we don't need extra space
    padding-left: 0;
  }
`;

export const LkRoutes = () => {
  const lkRoutes = useMemo(() => Object
    .values(routes)
    .filter(r => r.path.startsWith('/lk') && r.path !== lk.path)
    .map(route => (
      <ReactRoute key={route.route} path={route.path.replace('/lk/', '')} element={<ACL route={route}/>}/>
    )), []);

  return (
    <LkRoutesWrapper>
      <Routes>
        {lkRoutes}

        <ReactRoute path={'*'} element={<Navigate to={routes[Route.notFound].path} replace={true}/>}/>
      </Routes>
    </LkRoutesWrapper>

  );
};