import {INotification, NotificationType} from "../../../store/reducers/notificationsReducer/notificationsReducerTypes";
import {Logger} from "../../../utils/logger/Logger";
import {defineMessages, IntlShape} from "react-intl";


const NotificationTextMessages = defineMessages({
  // Chats
  incomeMessage: {
    id: "notificationText.incomeMessage",
    defaultMessage: "sent you a private message",
  },
  // Empty legs
  emptyLegArchivedExpired: {
    id: "notificationText.emptyLegArchivedExpired",
    defaultMessage: "Your Empty Leg has been moved archived at expiration",
  },
  emptyLegOrderRouteApproved: {
    id: "notificationText.emptyLegOrderRouteApproved",
    defaultMessage: "Empty Leg offer accepted",
  },
  emptyLegOrderRouteCreated: {
    id: "notificationText.emptyLegOrderRouteCreated",
    defaultMessage: "New Empty Leg offer received",
  },
  emptyLegOrderRouteDeclined: {
    id: "notificationText.emptyLegOrderRouteDeclined",
    defaultMessage: "Empty Leg offer rejected",
  },
  emptyLegOrderRouteInProcessAgain: {
    id: "notificationText.emptyLegOrderRouteInProcessAgain",
    defaultMessage: "Your Empty Leg offer is being reviewed",
  },
  emptyLegOrderRouteWithdrawn: {
    id: "notificationText.emptyLegOrderRouteWithdrawn",
    defaultMessage: "has withdrawn your Empty Leg offer",
  },
  emptyLegUpdated: {
    id: "notificationText.emptyLegUpdated",
    defaultMessage: "The requested Empty Leg has been changed",
  },
  // Order routes
  orderRouteArchived: {
    id: "notificationText.orderRouteArchivedExpired",
    defaultMessage: "moved the request to the archive",
  },
  orderRouteArchivedExpired: {
    id: "notificationText.orderRouteArchivedExpired",
    defaultMessage: "Your request has been moved to the archive due to the expiration date",
  },
  // Order requests
  orderRequestCreated: {
    id: "notificationText.orderRequestCreated",
    defaultMessage: "has requested a charter from you",
  },
  orderRequestArchived: {
    id: "notificationText.orderRequestArchived",
    defaultMessage: "denied your request",
  },
  orderRequestArchivedExpired: {
    id: "notificationText.orderRequestArchivedExpired",
    defaultMessage: "— the request was moved to the archive after the expiration date",
  },
  orderRequestOffered: {
    id: "notificationText.orderRequestOffered",
    defaultMessage: "has provided a quote for your request",
  },
})

// TYPE_ORDER_REQUEST_OFFERED

export const getNotificationText = (notification: INotification, intl: IntlShape): string => {
  const {type} = notification;
  const companyNamePrefix = (notification.metadata.company?.name ?? "") + " ";

  switch (type) {
    case NotificationType.TYPE_INCOME_MESSAGE:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.incomeMessage);

    case NotificationType.TYPE_EMPTY_LEG_ARCHIVED_EXPIRED:
      return intl.formatMessage(NotificationTextMessages.emptyLegArchivedExpired);
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_APPROVED:
      return intl.formatMessage(NotificationTextMessages.emptyLegOrderRouteApproved);
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_CREATED:
      return intl.formatMessage(NotificationTextMessages.emptyLegOrderRouteCreated);
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_DECLINED:
      return intl.formatMessage(NotificationTextMessages.emptyLegOrderRouteDeclined);
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_IN_PROCESS_AGAIN:
      return intl.formatMessage(NotificationTextMessages.emptyLegOrderRouteInProcessAgain);
    case NotificationType.TYPE_EMPTY_LEG_ORDER_ROUTE_WITHDRAWN:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.emptyLegOrderRouteWithdrawn);
    case NotificationType.TYPE_EMPTY_LEG_UPDATED:
      return intl.formatMessage(NotificationTextMessages.emptyLegUpdated);

    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.orderRouteArchived);
    case NotificationType.TYPE_ORDER_ROUTE_ARCHIVED_EXPIRED:
      return intl.formatMessage(NotificationTextMessages.orderRequestArchivedExpired);

    case NotificationType.TYPE_ORDER_REQUEST_CREATED:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.orderRequestCreated);
    case NotificationType.TYPE_ORDER_REQUEST_ARCHIVED:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.orderRequestArchived);
    case NotificationType.TYPE_ORDER_REQUEST_EXPIRED:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.orderRequestArchivedExpired);
    case NotificationType.TYPE_ORDER_REQUEST_OFFERED:
      return companyNamePrefix + intl.formatMessage(NotificationTextMessages.orderRequestOffered);
    default:
      Logger.error("No text matches given notification type was found")
      return "";
  }
}