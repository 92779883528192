import {RouteDescriptor} from "../../RouteDescriptor";
import {Route} from "../../Route";
import {RequestFromAgentsPage} from "../../../../../pages/Lk/OperatorsOnly/RequestFromAgents/RequestFromAgentsPage";
import {Role} from "../../../roles/Role";
import {defineMessage} from "react-intl";

const RequestsFromAgentsRouteTitleMessage = defineMessage({
  id: "requestsFromAgentsRoute.title",
  defaultMessage: "My offers",
})

export const requestsFromAgents: RouteDescriptor<Route.requestsFromAgents> = {
  route: Route.requestsFromAgents,
  render: RequestFromAgentsPage,
  title: RequestsFromAgentsRouteTitleMessage,
  path: '/lk/requests-from-agents',
  allowRoles: [
    Role.freighterAdmin,
    Role.freighterClientManager,
  ],
  redirectToHomeRoles: [
  ],
  showInSidebar: true,
}