import {IUser} from "./userReducerTypes";
import {Role} from "../../../config/acl/roles/Role";
import {UserStatus} from "../../../utils/constans/statuses/userStatuses";

export const generateEmptyUser = (): IUser => {
  return {
    id: 0,
    role: Role.unknown,
    userStatus: UserStatus.UNKNOWN,
    firstName: '',
    lastName: '',
    middleName: undefined,
    email: '',
    phone: 0,
    timezone: {
      id: 0,
      name: '',
      offset: 0,
    },
  }
}