import React, {FC, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {ReactComponent as CloseBigIcon} from "../../../media/icons/close_big.svg";
import {NotificationsContext} from "../context/NotificationsProvider";
import {NotificationGroup} from "./NotificationGroup";
import {defineMessages, useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {getNotificationGroups, INotificationGroups} from "../helpers/getNotificationGroups";
import {
  clearNotificationList,
  getNotificationList
} from "../../../store/reducers/notificationsReducer/notificationsReducer";
import {InitialPage} from "../../../utils/constans/pagination/pagination";
import {Loader} from "../../UI/Loader/Loader";
import {groupApproved} from "../../../config/acl/roles/groups";
import {mediaScreenSizes} from "../../../utils/constans/styles/screenSizes";

const NotificationsWindowWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background: none;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    z-index: 999;
  }
`;

const NotificationsWindowStyled = styled.div<{isHideAnimation: boolean}>`
  position: fixed;
  top: 75px;
  right: 17px;
  height: calc(100vh - 92px);
  width: 100%;
  max-width: 353px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 33px rgba(0, 0, 0, 0.11);
  z-index: 999;
  display: flex;
  flex-direction: column;
  animation: 0.2s ease showNotificationCentre forwards;
  
  @keyframes showNotificationCentre {
    from {
      right: -100px;
      opacity: 0;
    }
    to {
      right: 17px;
      opacity: 1;
    }
  }

  @keyframes hideNotificationCentre {
    from {
      right: 17px;
      opacity: 1;
    }
    to {
      right: -100px;
      opacity: 0;
    }
  }
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    background: #F3F5F7;
    top: 67px;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - 67px);
    height: calc(var(--app-height) - 67px);
    border-radius: 0;
    box-shadow: none;
  }
`;

const NotificationsWindowHeader = styled.div`
  height: 54px;
  min-height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 0 16px;
`;

const CloseNotificationsWindowButton = styled.div`
  cursor: pointer;
  transition: opacity 0.2s ease;
  
  :hover {
    opacity: 0.7;
  }
`;

const NotificationsWindowBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-height: calc(100vh - 147px);
  overflow-y: scroll;
  margin-top: 16px;
  padding: 0 24px 24px 16px;

  @media(max-width: ${mediaScreenSizes.mobile}) {
    max-height: calc(100vh - 114px);
  }
`;


const NotificationsWindowMessages = defineMessages({
  title: {
    id: "notificationsWindow.title",
    defaultMessage: "Notifications",
  },
  noNotifications: {
    id: "notificationsWindow.noNotifications",
    defaultMessage: "You haven't received any notifications yet.",
  },
})

interface INotificationsWindow {
}

export const NotificationsWindow: FC<INotificationsWindow> = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const timezoneOffset = useAppSelector(state => state.user.oneItemObject.item.timezone.offset)
  const {isAuthenticated, role} = useAppSelector(state => state.auth);
  const {items: notifications, isLoading} = useAppSelector(state => state.notifications.itemsObject);
  const {isWindowOpen, setIsWindowOpen, isChatFromNotificationEventOpened, chatFromNotificationEvent} = useContext(NotificationsContext);
  const [notificationGroups, setNotificationGroups] = useState<INotificationGroups>({unread: [], old: {}});
  const [isHideAnimation, setIsHideAnimation] = useState<boolean>(false);

  useEffect(() => {
    groupApproved.includes(role) && dispatch(getNotificationList({page: InitialPage}))

    return () => {
      dispatch(clearNotificationList());
    }
  }, [isAuthenticated])

  useEffect(() => {
    const newGroups = getNotificationGroups(notifications, timezoneOffset, intl);
    setNotificationGroups(newGroups);
  }, [notifications])

  const beforeClose = () => {
    setIsHideAnimation(true);
    setTimeout(() => {
      setIsWindowOpen(false)
    }, 0)
  }

  if (!isWindowOpen) {
    return null;
  }

  return (
    <NotificationsWindowWrapper onClick={beforeClose}>
      <NotificationsWindowStyled onClick={(e) => e.stopPropagation()} isHideAnimation={isHideAnimation}>
        <NotificationsWindowHeader>
          <div>{intl.formatMessage(NotificationsWindowMessages.title)}</div>
          <CloseNotificationsWindowButton onClick={beforeClose}>
            <CloseBigIcon />
          </CloseNotificationsWindowButton>
        </NotificationsWindowHeader>

        <NotificationsWindowBody>
          {isLoading && <Loader />}

          {!isLoading && notifications.length === 0 && intl.formatMessage(NotificationsWindowMessages.noNotifications)}

          {notificationGroups.unread.length > 0 && <NotificationGroup notifications={notificationGroups.unread} unread={true} />}

          {Object.entries(notificationGroups.old).map((group) => {
            const dateText = group[0]; const notifications = group[1];
            return <NotificationGroup key={dateText} notifications={notifications} unread={false} dateText={dateText} />
          })}
        </NotificationsWindowBody>

        {isChatFromNotificationEventOpened && chatFromNotificationEvent}
      </NotificationsWindowStyled>
    </NotificationsWindowWrapper>
  );
};
