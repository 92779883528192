import React, {FC, ReactNode, useState} from 'react';
import {ICompany} from "../../../../store/reducers/companyReducer/companyReducerTypes";
import styled from "styled-components";
import {ReactComponent as PhoneIcon} from "../../../../media/icons/phone.svg";
import {ReactComponent as EmailIcon} from "../../../../media/icons/email.svg";
import {ReactComponent as LocationIcon} from "../../../../media/icons/location.svg";
import {ReactComponent as WebsiteIcon} from "../../../../media/icons/website.svg";
import {ReactComponent as ExpandIcon} from "../../../../media/icons/expand.svg";
import {ReactComponent as CollapseIcon} from "../../../../media/icons/collapse.svg";
import avatarDefaultImage from "../../../../media/images/default_avatar.png";
import {CustomLoadedFileWrapper, CustomLoadedFileWrapperTitle, CustomUpload} from "../../../UI/AntCustom/CustomUpload";
import {defineMessages, useIntl} from "react-intl";

const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
`;

const Title = styled.div<{isOpened: boolean}>`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 16px 20px;
  border-bottom: ${props => props.isOpened ? "1px solid #D9D9D9" : "none"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  cursor: pointer;
  
  & > div {
    :nth-child(1) {
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      align-items: center;
      
      & > div {}
      :nth-child(2) {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
`;

const Content = styled.div`
  padding: 16px 20px 32px 16px;
`;

const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 22px;

  img {
    border-radius: 100%;
    width: 49px;
    height: 49px;
    object-fit: cover;
  }

  div {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }
`;

const ContentSubtitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 16px;
`;

const ContentDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6B6B6B;
  margin-top: 5px;
`;

const ContentDescriptionContactItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 8px;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 24px;
`;


const BrokerDrawerCompanyMessages = defineMessages({
  title: {
    id: "brokerDrawerCompany.title",
    defaultMessage: "Company details",
  },
  subtitle: {
    id: "brokerDrawerCompany.subtitle",
    defaultMessage: "About",
  },
  registrationCertificate: {
    id: "brokerDrawerCompany.regCertificate",
    defaultMessage: "Certificate of incorporation",
  }
})

interface IContactItem {
  icon: ReactNode;
  value: string | ReactNode;
}

interface IBrokerDrawerCompany {
  company: ICompany;
}

export const BrokerDrawerCompany: FC<IBrokerDrawerCompany> = ({company}) => {
  const intl = useIntl();
  const [isCollapseOpened, setIsCollapseOpened] = useState<boolean>(false);

  const companyContactItems: IContactItem[] = [
    {icon: <PhoneIcon/>, value: company.phone ? `+${company.phone}` : undefined},
    {icon: <EmailIcon/>, value: company.email},
    {icon: <LocationIcon/>, value: company.address},
    {
      icon: <WebsiteIcon/>,
      value: company.website ?
        <a href={`//${company.website}`} rel={'noreferrer'} target={'_blank'}>{company.website}</a> : undefined
    },
  ];

  return (
    <Wrapper>
      <Title isOpened={isCollapseOpened} onClick={() => setIsCollapseOpened(prev => !prev)}>
        <div>
          <div>{intl.formatMessage(BrokerDrawerCompanyMessages.title)}</div>
          <div>{company.name}</div>
        </div>
        {isCollapseOpened ? <CollapseIcon /> : <ExpandIcon />}
      </Title>
      {isCollapseOpened && <Content>
        <ContentTitle>
          <img src={company.avatar?.url || avatarDefaultImage} alt={`Avatar`}/>
          <div>{company.name}</div>
        </ContentTitle>
        {company.description && <>
          <ContentSubtitle>{intl.formatMessage(BrokerDrawerCompanyMessages.subtitle)}</ContentSubtitle>
          <ContentDescription>{company.description}</ContentDescription>
        </>}

        {companyContactItems.filter(item => !!item.value).map((company, idx) => <ContentDescriptionContactItem
          key={idx}>
          {company.icon}
          <div>{company.value}</div>
        </ContentDescriptionContactItem>)}

        {company.registrationCertificate && <FileWrapper>
          <CustomLoadedFileWrapperTitle>{intl.formatMessage(BrokerDrawerCompanyMessages.registrationCertificate)}</CustomLoadedFileWrapperTitle>
          <CustomLoadedFileWrapper>
            <CustomUpload fileList={[company.registrationCertificate]}/>
          </CustomLoadedFileWrapper>
        </FileWrapper>}
      </Content>}
    </Wrapper>
  );
};
