import React, {useEffect, useState} from 'react';
import {PageWrapper} from "../../../../components/UI/PageWrapper/PageWrapper";
import {CustomCard} from "../../../../components/UI/AntCustom/CustomCard";
import {message, Tabs} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {Loader} from "../../../../components/UI/Loader/Loader";
import {CustomResult} from "../../../../components/UI/AntCustom/CustomResult";
import {EmptyLegCard} from "./components/EmptyLegCard";
import {
  clearChangeEmptyLegRespondStatus,
  clearEmptyLegList,
  getEmptyLegList,
} from "../../../../store/reducers/emptyLegsReducer/emptyLegsReducer";
import {AddEmptyLegModal} from "./components/AddEmptyLegModal";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {renderSuccessModal} from "../../../../components/UI/StatusModals/renderSuccessModal";
import {defineMessages, useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {useSearchParams} from "react-router-dom";
import isNumeric from "antd/es/_util/isNumeric";
import {ReactComponent as CloseIcon} from "../../../../media/icons/close.svg";
import {useIsMobile} from "../../../../hooks/useWindowSize";
import {CustomTabs} from "../../../../components/UI/AntCustom/CustomTabs";


const MyEmptyLegsPageMessages = defineMessages({
  active: {
    id: "myEmptyLegsPage.active",
    defaultMessage: "Active",
  },
  archival: {
    id: "myEmptyLegsPage.archival",
    defaultMessage: "Archive",
  },
  title: {
    id: "myEmptyLegsPage.title",
    defaultMessage: "Empty Legs",
  },
  changeStatusSuccess: {
    id: "myEmptyLegsPage.changeStatusSuccess",
    defaultMessage: "Empty Leg respond status has been successfully updated.",
  },
  create: {
    id: "myEmptyLegsPage.create",
    defaultMessage: "Create Empty Leg",
  },
})

export enum EmptyLegsTabKeysEnum {
  ACTIVE = 'active',
  ARCHIVAL = 'archival',
}

export const MyEmptyLegsPage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const archivedParams = searchParams.get('archived');
  const emptyLegIdFromParams = isNumeric(searchParams.get('emptyLegId')) ? Number(searchParams.get('emptyLegId')) : undefined;
  const [activeTabKey, setActiveTabKey] = useState<EmptyLegsTabKeysEnum>(archivedParams ? EmptyLegsTabKeysEnum.ARCHIVAL : EmptyLegsTabKeysEnum.ACTIVE);
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const [isAddEmptyLegModalOpen, setIsAddEmptyLegModalOpen] = useState<boolean>(false);
  const [emptyLegsCount, setEmptyLegsCount] = useState<number>(0);
  const {
    items,
    totalCount,
    isLoading,
  } = useAppSelector(state => state.emptyLegs.itemsObject);
  const {
    isSuccess: isChangeStatusSuccess,
    isLoading: isChangeStatusLoading,
    errorMessage: changeStatusErrorMessage,
  } = useAppSelector(state => state.emptyLegs.changeEmptyLegRespondStatus);

  const tabList = [
    {key: EmptyLegsTabKeysEnum.ACTIVE, label: `${intl.formatMessage(MyEmptyLegsPageMessages.active)} (${emptyLegsCount})`},
    {key: EmptyLegsTabKeysEnum.ARCHIVAL, label: intl.formatMessage(MyEmptyLegsPageMessages.archival)},
  ];

  useEffect(() => {
    setCurrentPage(InitialPage);

    dispatch(getEmptyLegList({
      query: "",
      page: InitialPage,
      isArchival: activeTabKey === EmptyLegsTabKeysEnum.ARCHIVAL,
      emptyLegIds: emptyLegIdFromParams ? [emptyLegIdFromParams] : undefined,
    }));

    return () => {
      dispatch(clearEmptyLegList());
      dispatch(clearChangeEmptyLegRespondStatus());
    }
  }, [activeTabKey, emptyLegIdFromParams]);

  useEffect(() => {
    if (activeTabKey === EmptyLegsTabKeysEnum.ACTIVE && !isLoading)
      setEmptyLegsCount(totalCount);
  }, [items, isLoading])

  useEffect(() => {
    if (!isChangeStatusLoading) {
      if (isChangeStatusSuccess)
        renderSuccessModal(intl.formatMessage(MyEmptyLegsPageMessages.changeStatusSuccess));
      if (changeStatusErrorMessage)
        message.error(changeStatusErrorMessage);
    }
  }, [isChangeStatusSuccess, changeStatusErrorMessage, isChangeStatusLoading])

  const loadMore = () => {
    dispatch(getEmptyLegList({
      query: "",
      page: currentPage + 1,
      isArchival: activeTabKey === EmptyLegsTabKeysEnum.ARCHIVAL
    }));

    setCurrentPage(prev => prev + 1);
  }

  const removeIdFromSearchParams = () => {
    if (searchParams.has('emptyLegId')) {
      searchParams.delete('emptyLegId')
      setSearchParams(searchParams);
    }
  }

  const createButton = <CustomButton
    heightSize={isMobile ? 's' : 'm'}
    type={'primary'}
    style={{width: "max-content", alignSelf: "flex-end"}}
    onClick={() => setIsAddEmptyLegModalOpen(true)}
  >
    +{intl.formatMessage(MyEmptyLegsPageMessages.create)}
  </CustomButton>

  return (
    <PageWrapper>
      <CustomCard title={intl.formatMessage(MyEmptyLegsPageMessages.title)} width={'max'} isContainerCard={true}
                  onTabChange={(key: string) => {
                    setActiveTabKey(key as EmptyLegsTabKeysEnum)
                  }} extra={isMobile && createButton}>
        <CustomTabs tabBarExtraContent={!isMobile && createButton} activeKey={activeTabKey} onTabClick={(key: string) => {
          setActiveTabKey(key as EmptyLegsTabKeysEnum)
        }} style={isMobile ? {marginBottom: 0} : {}}>
          {tabList.map(t => <Tabs.TabPane key={t.key} tab={t.label}></Tabs.TabPane>)}
        </CustomTabs>

        {emptyLegIdFromParams && <CustomButton icon={<CloseIcon />} onClick={removeIdFromSearchParams}>{intl.formatMessage(CommonFormMessages.showAll)}</CustomButton>}

        {items.length === 0
          ? (isLoading ? <Loader/> : <CustomResult title={intl.formatMessage(CommonFormMessages.noData)}/>)
          : items.map((el) => {
            return <EmptyLegCard key={el.id} emptyLeg={el}/>
          })
        }

        {items.length > 0 && totalCount > items.length &&
          <CustomButton
            loading={isLoading}
            type={'primary'}
            onClick={loadMore}
          >
            {intl.formatMessage(CommonFormMessages.showMore)}
          </CustomButton>}
      </CustomCard>

      {isAddEmptyLegModalOpen &&
        <AddEmptyLegModal isModalVisible={isAddEmptyLegModalOpen} setIsModalVisible={setIsAddEmptyLegModalOpen}
                          setActiveTabKey={setActiveTabKey}/>
      }
    </PageWrapper>
  );
};

