import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {Loader} from "../../../../components/UI/Loader/Loader";
import {RegistrationCard} from "./RegistrationCard";
import {InitialPage} from "../../../../utils/constans/pagination/pagination";
import {
  clearRegistrationsList,
  getRegistrationsList
} from "../../../../store/reducers/registrationsReducer/registrationsReducer";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {CommonFormMessages} from "../../../../intl/commonMessages/CommonFormMessages";
import {CustomButton} from "../../../../components/UI/AntCustom/CustomButton";
import {CustomSearch} from "../../../../components/UI/AntCustom/CustomSearch";
import {mediaScreenSizes} from "../../../../utils/constans/styles/screenSizes";

const RegistrationList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  
  @media(max-width: ${mediaScreenSizes.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  }
`;

interface IUsersTabPane {
  userStatuses: number[];
}

export const UsersTabPane: FC<IUsersTabPane> = ({userStatuses}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(InitialPage);
  const {
    isLoading,
    items,
    totalCount
  } = useAppSelector(state => state.registrations.itemsObject);

  useEffect(() => {
    dispatch(getRegistrationsList({
      query: searchQuery,
      page: InitialPage,
      userStatuses: userStatuses,
    }));

    setCurrentPage(InitialPage);

    return () => {
      dispatch(clearRegistrationsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatuses])

  const search = () => {
    dispatch(getRegistrationsList({
      query: searchQuery,
      page: InitialPage,
      userStatuses: userStatuses
    }));

    setCurrentPage(InitialPage);
  }

  const loadMore = () => {
    dispatch(getRegistrationsList({
      query: searchQuery,
      page: currentPage + 1,
      userStatuses: userStatuses
    }));

    setCurrentPage(prev => prev + 1);
  }

  return (
    <>
      <CustomSearch
        placeholder={intl.formatMessage(CommonFormMessages.searchPlaceholder)}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        loading={isLoading}
        onSearch={search}
      />

      {items.length === 0 && isLoading ? <Loader/> :
        <RegistrationList>
          {items.map(r => <RegistrationCard
            key={r.id}
            user={r}
          />)}
        </RegistrationList>
      }

      {items.length > 0 && totalCount > items.length &&
        <CustomButton
          loading={isLoading}
          type={"primary"}
          onClick={loadMore}>
          {intl.formatMessage(CommonFormMessages.showMore)}
        </CustomButton>}
    </>
  );
};
